import * as React from 'react'
import { DefaultLightboxProps, PlasmicLightbox } from './plasmic/solace_components/PlasmicLightbox'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import styled from 'styled-components'
import NetworkImage from 'components/NetworkImage'
import * as GQL from 'generated/graphql'
import format from 'date-fns/format'
import { parseISO } from 'date-fns'

export const StyledLightboxActiveImage = styled(NetworkImage)`
  object-fit: contain;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  display: inline-block;
`

const StyledThumbImage = styled(NetworkImage)<{ active: boolean }>`
  object-fit: contain;
  min-width: 0;
  min-height: 0;
  display: inline-block;
  height: 100%;
  cursor: pointer;

  ${props =>
    !props.active &&
    `
    opacity: 0.5;
  `}
  :hover {
    opacity: 1;
  }
`

interface LightboxProps extends DefaultLightboxProps {
  order: GQL.CylinderGroupOrderNode
  customerName?: React.ReactNode
  uploadedBy?: React.ReactNode
  date?: React.ReactNode
  activeImage?: any
  onRequestClose: () => void
}

function Lightbox_(props: LightboxProps, ref: HTMLElementRefOf<'div'>) {
  const { order, onRequestClose, ...plasmicProps } = props
  const [imageIndex, setImageIndex] = React.useState(0)

  const images = order.inStops?.map((stop, index) => stop?.images?.map((image, n) => image?.url && image)).flat() as GQL.StopImageNode[]

  const moveActiveImage = (forward: boolean) => {
    if (forward) {
      if (imageIndex < images.length - 1) {
        setImageIndex(imageIndex + 1)
      } else {
        setImageIndex(0)
      }
    } else if (imageIndex < 1) {
      setImageIndex(images.length - 1)
    } else {
      setImageIndex(imageIndex - 1)
    }
  }

  return (
    <PlasmicLightbox
      root={{ ref }}
      btnNext={{
        props: {
          onClick: () => moveActiveImage(true),
        },
      }}
      btnPrevious={{
        props: {
          onClick: () => moveActiveImage(false),
        },
      }}
      btnClose={{
        props: {
          onClick: onRequestClose,
        },
      }}
      customerName={order.cylinderGroup.customer.name}
      uploadedBy={images[imageIndex]?.createdBy.fullName || 'unknown'}
      date={images[imageIndex] ? format(parseISO(images[imageIndex].createdAt), 'EEEE HH:mm, dd. LLLL yyyy') : 'unknown'}
      activeImage={<StyledLightboxActiveImage url={images[imageIndex]?.url as string} />}
      thumbnails={images.map((image, index) => (
        <StyledThumbImage active={index === imageIndex} key={index} url={image?.url as string} onClick={() => setImageIndex(index)} />
      ))}
      {...plasmicProps}
    />
  )
}

const Lightbox = React.forwardRef(Lightbox_)
export default Lightbox
