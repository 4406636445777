import React from 'react'
import { useIntl } from 'react-intl'

import Modal from './Modal'
import ModalConfirm from 'plasmic/ModalConfirm'

interface IModalProps {
  isOpen: boolean
  onRequestClose: () => void
  title: string
  description: string
  abortTitle?: string
  confirmTitle?: string
  contentStyle?: object
  overlayStyle?: object
  onConfirm: () => void
  onAbort?: () => void
  color?: 'red' | 'green' | 'blue'
}

const ConfirmModal: React.FC<IModalProps> = ({
  isOpen,
  onRequestClose,
  onConfirm,
  onAbort,
  title,
  description,
  abortTitle,
  confirmTitle,
  contentStyle = {},
  overlayStyle = {},
  color = 'red',
  ...props
}) => {
  const intl = useIntl()
  const t = intl.formatMessage

  return (
    <Modal
      isOpen={isOpen}
      stripped
      onRequestClose={onRequestClose}
      overlayStyle={overlayStyle}
      contentStyle={{ ...contentStyle, maxWidth: '500px' }}
      {...props}
    >
      <ModalConfirm
        title={title}
        description={description}
        btnConfirm={{
          label: confirmTitle ? confirmTitle : t({ id: 'common.confirm' }),
          onClick: () => onConfirm(),
        }}
        btnCancel={{
          label: abortTitle ? abortTitle : t({ id: 'common.cancel' }),
          onClick: () => onRequestClose(),
        }}
        btnClose={{
          onClick: () => (onAbort ? onAbort() : {}),
        }}
        btnColor={color}
      />
    </Modal>
  )
}

export default ConfirmModal
