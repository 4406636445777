// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type SensorIconsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function SensorIconsvgIcon(props: SensorIconsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 213.063 195.495"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M212.907 78.844l-.512 1.959-.846 1.937-1.782 2.472-26.362 28.255-2.828 3.229-2.65 3.384-3.985 6.012-2.138 3.852-1.893 3.941-1.669 4.119-1.448 4.231-11.289 36.939-.623 1.625-.868 1.537-1.247 1.603-1.559 1.403-2.65 1.536-4.453 1.804-4.542 1.58-4.609 1.381-4.654 1.158-4.698.98-4.742.734-4.788.535-4.787.334-4.809.111-4.81-.111-4.787-.334-4.765-.535-4.742-.734-4.721-.98-4.653-1.158-4.609-1.381-4.543-1.58-4.453-1.804-1.536-.779-1.448-1.024-1.469-1.425-1.225-1.693-1.247-2.783-11.311-36.939-1.38-4.075-1.603-3.985-3.207-6.458-2.271-3.762-2.471-3.608-2.739-3.518-2.917-3.362-26.34-28.255-1.091-1.358-.913-1.515-.757-1.892-.446-2.049L0 75.348l.69-4.765.891-4.72 1.113-4.676 1.314-4.609 1.514-4.565 1.737-4.475 1.915-4.409 2.115-4.319 2.315-4.209 2.494-4.119 2.65-3.986 2.85-3.852 3.028-3.74 3.184-3.607 3.34-3.452 3.496-3.295 3.651-3.117 3.763-2.984 1.47-.935 1.603-.735 1.959-.556L49.163 0l3.051.312 37.674 8.683 4.186.847 4.275.601 7.192.445 4.386-.067 4.386-.356 4.409-.601 4.364-.869L160.738.312l1.714-.29L164.234 0l2.004.289 2.004.646 2.649 1.514 3.785 2.984 3.63 3.117 3.495 3.295 3.34 3.452 3.207 3.607 3.006 3.74 2.85 3.852 2.694 3.986 2.494 4.119 2.293 4.209 2.115 4.319 1.937 4.409 1.715 4.475 1.514 4.565 1.314 4.609 1.113 4.676.891 4.72.69 4.765.089 1.737z"
        }
      ></path>
    </svg>
  );
}

export default SensorIconsvgIcon;
/* prettier-ignore-end */
