import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import Modal, { ModalProps } from 'components/Modal/Modal'
import FormField from 'components/FormField'
import Label from 'components/Label'
import { useForm, Controller } from 'react-hook-form'
import Form from 'components/Form'
import Button from 'components/Button'
import Margin from 'components/Margin'
import { format } from 'date-fns'
import * as GQL from 'generated/graphql'
import SelectDriver from './SelectDriver'

interface FormData {
  driver: string
}

interface ChangeDriverModalProps extends ModalProps {
  date: Date
  drivers: GQL.DriverNode[]
  route: GQL.RouteNode
  submit: (data: FormData) => void
}

const ChangeDriverModal: React.FC<ChangeDriverModalProps> = ({ date, route, drivers, submit, onRequestClose, ...props }) => {
  const intl = useIntl()
  const t = intl.formatMessage

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<FormData>()

  function onSubmit(data: FormData) {
    submit(data)

    onRequestClose()
  }

  return (
    <Modal
      title={format(date, 'EEEE, dd. LLLL') + ' (' + route.driver?.user.fullName + ')'}
      onRequestClose={onRequestClose}
      {...props}
      contentStyle={{
        width: 500,
        backgroundColor: '#242c48',
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField>
          <Label>Available drivers</Label>
          <Controller
            render={({ field: { value } }) => (
              <SelectDriver
                defaultValue={value}
                drivers={drivers}
                onChange={newDriver => {
                  setValue('driver', newDriver)
                }}
                error={errors?.driver?.message as string}
                listProps={{ left: 0 }}
                height='40px'
              />
            )}
            name='driver'
            defaultValue={route?.driver?.id}
            control={control}
            rules={{
              required: {
                value: true,
                message: t({ id: 'common.vehicle' }),
              },
            }}
          />
        </FormField>
        <Margin top='1rem'>
          <Button color='green' width='100%' borderRadius='0px'>
            <FormattedMessage id='common.save-changes' />
          </Button>
        </Margin>
      </Form>
    </Modal>
  )
}

export default ChangeDriverModal
