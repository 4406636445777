// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type GasBottleFilledsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function GasBottleFilledsvgIcon(props: GasBottleFilledsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 20.577 36.675"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M15.049 36.675H5.528a1.336 1.336 0 01-.774-2.426c-2.259-.731-3.642-2.692-3.886-5.625a1.19 1.19 0 01-.005-.111v-6.498a1.9 1.9 0 010-3.182v-6.497c0-.04.002-.08.006-.12.247-2.976 1.673-4.964 4.039-5.659V2.673h-.687c-.737 0-1.337-.6-1.337-1.337S3.484 0 4.221 0h12.134c.736 0 1.336.6 1.336 1.337s-.6 1.337-1.336 1.337h-.687V6.55c2.546.72 4.044 2.839 4.044 5.787v6.497a1.897 1.897 0 010 3.184v6.497c0 2.892-1.47 4.98-3.886 5.74a1.337 1.337 0 01-.777 2.42zM11.662 6.281h1.334V2.673H7.58v3.608h1.334v-1.36c0-.737.6-1.337 1.337-1.337h.075c.737 0 1.337.6 1.337 1.337l-.001 1.36z"
        }
      ></path>
    </svg>
  );
}

export default GasBottleFilledsvgIcon;
/* prettier-ignore-end */
