export default async function clearCache() {
  if ('caches' in window) {
    console.log('found cache!')
    await caches
      .keys()
      .then(names => {
        names.forEach(async name => {
          await caches.delete(name)
        })
      })
      .then(() => {
        console.log('cache cleared')
        window.location.reload()
      })
      .catch(err => {
        console.log(err)
      })
  } else {
    console.log('no cache found')
  }
}
