import React from 'react'
import styled from 'styled-components'

import { overloadIcon } from 'util/components'
import Loader from 'components/Loader/Loader'

interface IInputErrorProps {
  offsetIcon?: boolean
}

const InputError = styled.div<IInputErrorProps>`
  margin-top: 0.5rem;
  font-size: 12px;
  color: ${props => props.theme.colors.red};
  grid-column: 1 / span 2;

  ${props =>
    props.offsetIcon
      ? `
    margin-left: 48px;
  `
      : ''}
`

const InputWrapper = styled.div<{ hasIcon: boolean }>`
  display: block;
  position: relative;

  ${props =>
    props.hasIcon &&
    `
    display: grid;
    grid-template-columns: 3rem 1fr;
  `}
`

const StyledInput = styled.input<IInputProps>`
  font-size: 16px;
  border-radius: 4px;
  outline: none;
  width: 100%;
  height: 100%;
  color: #ffffff;
  font-weight: 400;
  padding: 14px 10px;
  background: #181d36;
  box-sizing: border-box;
  font-family: inherit;

  ${props =>
    !!props.error
      ? `
    color: ${props.theme.colors.red};
    border-color: ${props.theme.colors.red};
  `
      : ''}

  transition: border 0.1s ease-in-out;
  border: 1px solid #ffffff1a;
  :hover {
    background: #111830;
  }
  :focus {
    box-shadow: 0px 0px 2px 0px #2c97de;
    border-color: #2c97de;
  }

  ::placeholder {
    color: #ffffff80;
  }

  ${props =>
    props.removeNumberSpins &&
    `
    /* Chrome, Safari, Edge, Opera */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    

    /* Firefox */
    &[type=number] {
      -moz-appearance:textfield;
    }
  `}

  ${props =>
    props.error &&
    `
      border-color: ${props.theme.colors.red} !important;
  `}
`

const Suffix = styled.div`
  position: absolute;
  right: 1rem;
  top: 9px;
`

const OutsideIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export interface IInputProps {
  id?: string
  name?: string
  className?: string
  type?: string
  error?: string | undefined
  outsideIcon?: string | JSX.Element | null
  forceOutsideIconSpacing?: boolean
  suffix?: string
  removeNumberSpins?: boolean

  focusColor?: string
  color?: string

  borderColor?: string
  borderRadius?: string

  background?: string

  height?: string
  width?: string
  padding?: string

  fontSize?: string

  value?: string | number | undefined
  defaultValue?: string | number | undefined

  autoFocus?: boolean

  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => any
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void

  placeholder?: string
  min?: number | string
  ref?: any
  register?: any
  readOnly?: boolean
  loading?: boolean
  autocomplete?: string
}

const Input: React.FC<IInputProps> = React.forwardRef(
  (
    {
      id,
      name,
      className,
      error,
      outsideIcon,
      forceOutsideIconSpacing,
      suffix,
      removeNumberSpins,
      placeholder,
      defaultValue,
      value,
      onChange,
      onBlur,
      onKeyDown,
      min,
      register,
      loading,
      ...props
    },
    ref
  ) => {
    const renderIconSpace = !!forceOutsideIconSpacing || !!outsideIcon

    return (
      <InputWrapper className={className} hasIcon={renderIconSpace}>
        {!!loading ? (
          <Loader size={16} color='gray8' />
        ) : (
          <>
            <OutsideIconWrapper>{outsideIcon && overloadIcon(outsideIcon)}</OutsideIconWrapper>
            <StyledInput
              id={id}
              name={name}
              removeNumberSpins={removeNumberSpins}
              onChange={onChange}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              autoComplete={props.autocomplete || 'off'}
              value={value}
              defaultValue={defaultValue}
              placeholder={placeholder}
              error={error}
              ref={ref}
              {...props}
            />
            {error && <InputError offsetIcon={!!outsideIcon}>{error}</InputError>}
            {suffix && <Suffix>{suffix}</Suffix>}
          </>
        )}
      </InputWrapper>
    )
  }
)

Input.defaultProps = {
  type: 'text',
  removeNumberSpins: true,
}

export default Input
