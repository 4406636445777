// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type OptimizeOnsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function OptimizeOnsvgIcon(props: OptimizeOnsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 25 25"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M23.9 4.032L20.968 1.1a2.255 2.255 0 00-1.599-.663c-.579 0-1.158.221-1.599.663L1.1 17.77a2.26 2.26 0 000 3.199L4.032 23.9c.442.442 1.021.663 1.599.663s1.158-.221 1.599-.663L23.9 7.23a2.26 2.26 0 000-3.198zM5.631 22.301l-2.932-2.932 10.55-10.552 2.933 2.933L5.631 22.301zm12.15-12.15l-2.933-2.933 4.518-4.519h.002L22.3 5.631l-4.519 4.52zm3.012 3.857l-1.256 2.513-2.513 1.257 2.513 1.257 1.256 2.513 1.256-2.513 2.513-1.257-2.513-1.257-1.256-2.513zm-9.801-9.047l.754-1.508 1.508-.754-1.508-.754-.754-1.508-.754 1.508-1.508.754 1.508.754.754 1.508zM4.207 7.976l1.256-2.513 2.513-1.257L5.463 2.95 4.207.437 2.951 2.95.437 4.207 2.95 5.464l1.257 2.512z"
        }
      ></path>
    </svg>
  );
}

export default OptimizeOnsvgIcon;
/* prettier-ignore-end */
