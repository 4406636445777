export type CalculationEntryEvent = 'REFILLING' | 'DEPLETING'

export interface CalculationEntry {
  timestamp: number
  value?: number
  prediction?: number
  events: CalculationEntryEvent[]
}

export interface Calculation {
  id: string
  sensorSerialNumber: string
  entries: CalculationEntry[]
}

export interface SimplifiedCalculationEntry {
  timestamp: number
  value: number
}

export interface SimplifiedCalculation {
  id: string
  sensorSerialNumber: string
  entries: SimplifiedCalculationEntry[]
}

export interface SensorCalculationService {
  fetch: (sensorSerialNumber: string, calculationId: string) => Promise<Calculation>
  fetchSimplified: (sensorSerialNumber: string, calculationId: string) => Promise<SimplifiedCalculation>
}

export class NoopSensorCalculationService {
  async fetch(sensorSerialNumber: string, calculationId: string): Promise<Calculation> {
    throw Error('Cannot use not operational sensor calculation service')
  }
  async fetchSimplified(sensorSerialNumber: string, calculationId: string): Promise<SimplifiedCalculation> {
    throw Error('Cannot use not operational sensor calculation service')
  }
}

export class AwsSensorCalculationService {
  private calculationBucketUrl
  private environment = process.env.REACT_APP_ENVIRONMENT === 'prod' ? 'prod' : 'staging'

  constructor(calculationBucketUrl: string) {
    this.calculationBucketUrl = calculationBucketUrl
  }

  async fetch(sensorSerialNumber: string, calculationId: string): Promise<Calculation> {
    const calculationResponse = await fetch(`${this.calculationBucketUrl}/${sensorSerialNumber}_${calculationId}_${this.environment}.json`)
    const calculationResponseContent: CalculationResponseContent = await calculationResponse.json()

    return {
      id: calculationResponseContent.id,
      sensorSerialNumber: calculationResponseContent.sensor_serial_number,
      entries: calculationResponseContent.entries.map(entry => ({
        timestamp: entry.timestamp,
        value: entry.value !== null ? entry.value : undefined,
        prediction: entry.prediction !== null ? entry.prediction : undefined,
        events: entry.events,
      })),
    }
  }

  async fetchSimplified(sensorSerialNumber: string, calculationId: string): Promise<SimplifiedCalculation> {
    const calculationResponse = await fetch(`${this.calculationBucketUrl}/${sensorSerialNumber}_${calculationId}_${this.environment}_simplified.json`)
    const calculationResponseContent: SimplifiedCalculationResponseContent = await calculationResponse.json()

    return {
      id: calculationResponseContent.id,
      sensorSerialNumber: calculationResponseContent.sensor_serial_number,
      entries: calculationResponseContent.entries.map(entry => ({
        timestamp: entry.timestamp,
        value: entry.value,
      })),
    }
  }
}

interface CalculationResponseContent {
  id: string
  sensor_serial_number: string
  entries: {
    timestamp: number
    value?: number
    prediction?: number
    events: Array<'REFILLING' | 'DEPLETING'>
  }[]
}

interface SimplifiedCalculationResponseContent {
  id: string
  sensor_serial_number: string
  entries: {
    timestamp: number
    value: number
  }[]
}
