// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type FlipViewsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function FlipViewsvgIcon(props: FlipViewsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 45 45"}
      xmlSpace={"preserve"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M21.748 11.223v22.554a.752.752 0 001.504 0V11.223a.752.752 0 00-1.504 0zm-3.189 22.461a2.254 2.254 0 001.165-1.962v-17.78a2.255 2.255 0 00-3.504-1.879L2.433 21.242a2.256 2.256 0 00.053 3.759l13.788 8.638a2.253 2.253 0 002.285.045zm7.905.016a2.255 2.255 0 002.255-.06l13.795-8.593a2.255 2.255 0 00.053-3.759l-13.765-9.209a2.256 2.256 0 00-3.504 1.88v17.78a2.257 2.257 0 001.166 1.961zm10.472-14.389l4.676 3.112h-14.81v-3.007h9.773a.753.753 0 00.361-.105zm-.293 7.623h-9.841v-3.007h14.667l-4.826 3.007zm-8.691 5.428a.748.748 0 01-.398.135.742.742 0 01-.368-.098.752.752 0 01-.391-.654v-3.308h7.458l-6.301 3.925zm-.752-19.08a.754.754 0 01.752 0l6.894 4.631h-8.044v-3.954a.755.755 0 01.398-.662v-.015z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default FlipViewsvgIcon;
/* prettier-ignore-end */
