import styled from 'styled-components'
import Tippy, { TippyProps } from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

interface ITooltipProps extends TippyProps {
  background?: string
  $style?: any
}

const Tooltip = styled(Tippy)<ITooltipProps>`
  background: ${props => (props.background ? props.background : props.theme.colors.primary)};
  box-shadow: 0 6px 15px 0 ${props => props.theme.colors.primaryDarker};
  padding: 6px 15px;
  border-radius: 10px;
  white-space: normal;
  max-width: 500px !important;
  font-size: 12px;
  display: flex;
  width: auto;
  justify-content: center;
  ${props => {
    return { ...props.$style }
  }}
`

export default Tooltip
