/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/jsx-pascal-case */
import PlasmicWorkday from 'plasmic/Workday'
import React, { useState, useEffect } from 'react'
import * as GQL from 'generated/graphql'
import Loader from 'components/Loader'
import { useApolloClient } from '@apollo/client'
import { time } from 'lib/time'
import { displayToast } from 'util/toasts'

const Workday: React.FC<{
  day: 'M' | 'T' | 'W' | 'F' | 'S'
  data: GQL.DriverStandardWorkhourNode | undefined | null
  driver: GQL.DriverNode
  dow: number
}> = ({ day, data, driver, dow }) => {
  const [startTime, setStartTime] = useState(data?.startTime || '')
  const [endTime, setEndTime] = useState(data?.endTime || '')
  const active = data ? true : false

  const client = useApolloClient()

  useEffect(() => {
    setStartTime(data?.startTime || '')
  }, [data?.startTime, setStartTime])

  useEffect(() => {
    setEndTime(data?.endTime || '')
  }, [data?.endTime, setEndTime])

  const [createOrPatchDriverWorkhours, { loading: createOrPatchLoading }] = GQL.useCreateOrPatchDriverWorkhours({
    onCompleted({ createOrPatchDriverWorkhours }) {
      setStartTime(createOrPatchDriverWorkhours?.workhour?.startTime || '')
      setEndTime(createOrPatchDriverWorkhours?.workhour?.endTime || '')
    },
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(driver),
        fields: {
          standardWorkhours(sw, { readField }) {
            const workhourRef = cache.writeFragment({
              id: data?.createOrPatchDriverWorkhours?.workhour?.id,
              fragment: GQL.StandardWorkhours,
              data: data?.createOrPatchDriverWorkhours?.workhour,
            })

            if (sw.some((standardWorkhour: GQL.StandardWorkhours) => readField('id', standardWorkhour) === data?.createOrPatchDriverWorkhours?.workhour?.id)) {
              return sw
            }
            return [...sw, workhourRef]
          },
        },
      })
    },
  })

  const [deleteDriverWorkhours, { loading: deleteLoading }] = GQL.useDeleteDriverWorkhours({
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(driver),
        fields: {
          standardWorkhours(value, { readField }) {
            return value.filter((entry: GQL.DriverStandardWorkhourNode) => readField('id', entry) !== data?.deleteDriverWorkhours?.deletedId)
          },
        },
      })
    },
  })

  const updateTime = () => {
    if (startTime > endTime) {
      displayToast('End time is before start time. Please correct it!')
      return
    }
    if (/^\d\d(:\d\d){1,2}$/.test(startTime) && /^\d\d(:\d\d){1,2}$/.test(endTime)) {
      createOrPatchDriverWorkhours({
        variables: {
          dow: dow,
          start: startTime,
          end: endTime,
          driver: driver.id,
        },
      })
    } else {
      displayToast('Wrong time format!')
    }
  }

  const OnOffTime = (off: boolean) => {
    if (off) {
      deleteDriverWorkhours({ variables: { id: data?.id ?? '' } })
    } else {
      createOrPatchDriverWorkhours({
        variables: {
          dow: dow,
          start: '08:00',
          end: '16:00',
          driver: driver.id,
        },
      })
    }
  }

  const copyToAll = () => {
    if (/^\d\d(:\d\d){1,2}$/.test(startTime) && /^\d\d(:\d\d){1,2}$/.test(endTime)) {
      // Clean the cache first to visible view the changes made
      for (let i = 0; i < 7; i++) {
        createOrPatchDriverWorkhours({
          variables: {
            dow: i,
            start: startTime,
            end: endTime,
            driver: driver.id,
          },
        })
      }
      client.cache.modify({
        id: client.cache.identify(driver),
        fields: {
          standardWorkhours(_, { DELETE }) {
            return DELETE
          },
        },
      })
    }
  }

  return (
    <PlasmicWorkday
      inactive={!active}
      data-testid='workday'
      copyToAll={{ onClick: () => copyToAll() }}
      button={{ onClick: () => OnOffTime(active) }}
      day={createOrPatchLoading || deleteLoading ? <Loader size={20} color='white' /> : day}
      inputStart={{
        step: 60,
        onBlur: () => updateTime(),
        undefinedInput: {
          value: time(startTime).format('HH:mm'),
          onChange: (e: any) => {
            if (e.target.value > endTime) {
              displayToast('End time is before start time!')
              return
            }
            setStartTime(e.target.value)
          },
        },
      }}
      inputEnd={{
        // fieldValue:  time(endTime).format('HH:mm'),
        onBlur: () => updateTime(),
        undefinedInput: {
          value: time(endTime).format('HH:mm'),
          onChange: (e: any) => {
            if (startTime > e.target.value) {
              displayToast('End time is before start time!')
              return
            }
            setEndTime(e.target.value)
          },
        },
      }}
    />
  )
}

export default Workday
