// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { omit } from 'lodash'
import { PlasmicProductCountRow, DefaultProductCountRowProps } from './plasmic/solace_components/PlasmicProductCountRow'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import * as GQL from 'generated/graphql'
import { EditStock } from 'modules/inventory'
import ActionDropdownLine from './ActionDropdownLine'
import { useClickOutside } from 'util/hooks'
import { useNavigate } from 'react-router-dom'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface ProductCountRowProps extends Omit<DefaultProductCountRowProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultProductCountRowProps altogether and have
// total control over the props for your component.
export interface ProductCountRowProps extends DefaultProductCountRowProps {
  inventory: GQL.InventoryNode
  quantity?: number | null
  setEditStockInfo: (info: EditStock) => void
}

function ProductCountRow_(props: ProductCountRowProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicProductCountRow to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicProductCountRow are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all ProductCountRowProps here, but feel free
  // to do whatever works for you.
  const [moreActions, setMoreActions] = React.useState(false)
  const moreActionsRef = React.useRef(null)
  const navigate = useNavigate()

  const handleEditStock = React.useCallback(
    (inventory?: GQL.InventoryNode | null, stockState: GQL.InventoryStockState = GQL.InventoryStockState.NotEmpty, currentQuantity?: number | null) => {
      if (!inventory || currentQuantity === null || currentQuantity === undefined) return
      props.setEditStockInfo({ inventory: inventory, stockState: stockState, currentQuantity: currentQuantity })
    },
    [props.setEditStockInfo]
  )

  useClickOutside(moreActionsRef, () => {
    setMoreActions(false)
  })

  return (
    <PlasmicProductCountRow
      root={{ ref }}
      image={props.inventory.product?.image?.image}
      title={props.inventory.product?.displayName}
      count={props.quantity?.toString()}
      btnMoreActions={{
        props: {
          'data-testid': 'more-actions-total',
          onClick: () => setMoreActions(true),
          open: moreActions,
          ref: moreActions ? moreActionsRef : null,
          actionDropdown: (
            <React.Fragment>
              <ActionDropdownLine
                children='Adjust quantity'
                onClick={() => {
                  if (!props.inventory) return
                  handleEditStock(props.inventory, GQL.InventoryStockState.NotEmpty, props.quantity)
                }}
              />
              <ActionDropdownLine
                children='See product inventory'
                onClick={() => {
                  navigate('/inventory/overview')
                }}
              />
              <ActionDropdownLine
                children='See product settings'
                onClick={() => {
                  navigate('/settings/products')
                }}
              />
            </React.Fragment>
          ),
        },
      }}
      {...omit(props, 'inventory', 'quantity', 'setEditStockInfo')}
    />
  )
}

const ProductCountRow = React.forwardRef(ProductCountRow_)
export default ProductCountRow
