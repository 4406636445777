import React from 'react'
import styled from 'styled-components'
import Loader from './Loader'

interface ICornerLoaderProps {
  position?: string
  size?: number
  text?: string
  topAdjust?: string
}

const CornerPosition = styled.div<ICornerLoaderProps>`
  position: ${props => props.position};
  display: inline-block;
  right: 2em;
  z-index: 9999;
  top: ${props => `calc(${props.theme.layout.headerHeight} + 20px - ${props.topAdjust || '0px'})`};
`

const CornerLoader: React.FC<ICornerLoaderProps> = ({ size, position, topAdjust, text, ...props }) => {
  return (
    <CornerPosition position={position} topAdjust={topAdjust}>
      <Loader size={size} color='white' {...props} />
      <span>{text}</span>
    </CornerPosition>
  )
}

CornerLoader.defaultProps = {
  position: 'absolute',
  size: 25,
}

export default CornerLoader
