import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { CycleNode, CylinderGroupNode } from 'generated/graphql'
import { Calculation } from 'services/sensorCalculation'
import { displayToast } from 'util/toasts'
import ChartComponent from 'plasmic/ChartComponent'
import SensorCalculationServiceContext from 'context/SensorCalculationServiceContext'
import ChartEmptyState from 'plasmic/ChartEmptyState'

interface Props {
  sensorSerialNumber: string
  calculationId: string
  activeCycle: CycleNode
  cylinderGroup: CylinderGroupNode
}

const Chart: React.FC<Props> = props => {
  const [hasError, setHasError] = useState(false)

  const intl = useIntl()
  const t = intl.formatMessage

  const sensorCalculationService = useContext(SensorCalculationServiceContext)

  const [calculation, setCalculation] = useState<Calculation>()

  useEffect(() => {
    const fetchCalculation = async () => {
      try {
        const calculation = await sensorCalculationService.fetch(props.sensorSerialNumber, props.calculationId)

        setCalculation(calculation)
      } catch (error: any) {
        setCalculation(undefined)
        displayToast(t({ id: 'customers.toasts.error-sensor-calculation-fetch' }), 'error')
      }
    }

    fetchCalculation()
  }, [props.sensorSerialNumber, props.calculationId, sensorCalculationService, t])

  return (
    <>
      {calculation && !hasError ? (
        <ChartComponent
          id={new Date().getTime()}
          calculation={calculation}
          activeCycle={props.activeCycle}
          cylinderGroup={props.cylinderGroup}
          setHasError={setHasError}
        />
      ) : (
        <ChartEmptyState
          error={hasError}
          title={hasError ? t({ id: 'customers.chart.error-state.title' }) : t({ id: 'customers.chart.empty-state.title' })}
          subtitle={hasError ? t({ id: 'customers.chart.error-state.subtitle' }) : t({ id: 'customers.chart.connect-sensor-subtitle' })}
        />
      )}
    </>
  )
}

export default Chart
