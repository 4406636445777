import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { startOfWeek, endOfWeek, format } from 'date-fns'
import DriverVehiclesPanel from './DriverVehiclesPanel'
import DriverDatesPanel from './DriverDatesPanel'
import CornerLoader from 'components/Loader/CornerLoader'
import * as GQL from 'generated/graphql'
import { useAppContext, useDebounce } from 'util/hooks'
/* Tutorials temporarily disabled
import Tour from 'components/Tour/Tour'
import steps from './DatesTourSteps'*/

const Content = styled.div`
  display: grid;
  height: calc(100% - 37px);
  grid-template-rows: auto 80px;
  height: calc(100% - 37px);
`

const Layout = styled.div`
  display: grid;
  grid-template-columns: 500px 40px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'drivervehicles separator dates' 'bottombar bottombar bottombar';
  height: 100%;
`

const VerticalBar = styled.div`
  justify-self: center;
  width: 2px;
  height: calc(100% + 1rem);
  margin-top: -1rem;
  background: #111830;
  content: '';
`

interface DatesProps {}

const Dates: React.FC<DatesProps> = () => {
  const [startDate, setStartDate] = useState<Date>(startOfWeek(new Date(), { weekStartsOn: 1 }))
  const { appContext } = useAppContext()

  const currentDepot = appContext.depot?.id ?? null

  const { data: dataDrivers } = GQL.useAllDriversDeliveriesDatesPage()

  const { data: dataRoutes } = GQL.useAllRoutesDeliveriesDatesPage({
    variables: {
      first: 50,
      dateGte: format(startDate, 'yyyy-MM-dd'),
    },
  })

  //const startDate = startOfWeek(new Date(), { weekStartsOn: 1 })
  const endDate = endOfWeek(startDate, { weekStartsOn: 1 })

  const { loading, data } = GQL.useRoutes({
    notifyOnNetworkStatusChange: true,
    variables: {
      startDate: format(useDebounce(startDate, 250), 'yyyy-MM-dd'),
      days: 6,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  })

  const routes = data?.routes || []
  const driverIds = routes.map(r => r?.driverId!)

  const drivers = useMemo(() => {
    return (
      dataDrivers?.allDriversDeliveriesDatesPage?.edges
        .filter(
          d =>
            (driverIds.includes(d?.node?.id || '0') || d?.node?.defaultDepot?.id === currentDepot) &&
            d?.node?.active &&
            !d?.node?.deleted &&
            !d?.node?.user.isDeleted
        )
        .map(edge => edge?.node as GQL.DriverNode)
        .sort((a, b) => ('' + a.user.fullName).localeCompare('' + b.user.fullName)) || []
    )
  }, [currentDepot, dataDrivers?.allDriversDeliveriesDatesPage?.edges, driverIds])

  const currentRoutes = useMemo(() => {
    return dataRoutes?.allRoutesDeliveriesDatesPage?.edges.map(edge => edge?.node as GQL.RouteNode) || []
  }, [dataRoutes?.allRoutesDeliveriesDatesPage?.edges])

  return (
    <Content>
      <Layout>
        {loading && <CornerLoader topAdjust='-80px' size={50} />}
        <DriverVehiclesPanel drivers={drivers} />
        <VerticalBar />
        <DriverDatesPanel
          currentRoutes={currentRoutes}
          routes={routes as GQL.DriverExtendedNode[]}
          drivers={drivers}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
        />
      </Layout>
    </Content>
  )

  /* To re-enable tutorials, add the following after </Layout>:
  {!loadingDrivers && !loading && drivers.length < 1 && <Tour steps={steps} />} */
}
export default Dates
