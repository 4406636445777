import React, { useState, useEffect, useCallback } from 'react'
import styled, { keyframes } from 'styled-components'
import { overloadColorProp } from 'util/style'
import { useWindowResized } from 'util/hooks'
import progressOverlay from 'assets/fonticons/progress-bar-2.svg'
import Tooltip from 'components/Tooltip/Tooltip'

const BarBasis = styled.div<{ height: number; width: number | string }>`
  border-radius: ${props => props.height / 2}px;
  width: ${props => props.width};
  height: ${props => props.height - 4}px;
`

const ProgressBackground = styled(BarBasis)<{
  justifyContent: string
  width: number | string
  height: number
  backgroundColor?: string
}>`
  background: ${props => overloadColorProp(props, 'backgroundColor')};
  display: flex;
  box-sizing: border-box;
  justify-content: ${props => props.justifyContent};
  align-items: center;
  position: relative;
  width: 100%;
  height: ${props => props.height}px;
  border: 2px solid ${props => overloadColorProp(props, 'backgroundColor')};
  border-radius: ${props => props.height / 2}px;
  overflow: hidden;
`

const translate = keyframes`
  from{
    backgronud-position-x: 0;
  }
  to{
    background-position-x: 52px;
  }
`

const ProgressBar = styled(BarBasis)<{
  striped?: boolean
  animate?: boolean
  width: number | string
  height: number
  color?: string
}>`
  background: ${props => `
    ${overloadColorProp(props)} 
    ${props.striped ? `url(${progressOverlay})` : ''}
  `};
  background-repeat: repeat;
  animation: ${translate} ${props => (props.animate ? '5s' : '0s')} linear infinite;
`

const Wrapper = styled.div<{ width?: string | number }>`
  position: relative;
  width: ${props => props.width};
  display: flex;
`

const ProgressLabel = styled.label`
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
`

interface ProgressProps {
  fillPercent: number
  growDirection?: 'right' | 'center' | 'left'
  color?: string
  striped?: boolean
  thickness?: number
  height?: number
  width?: number | string
  backgroundColor?: string
  animate?: boolean
  label?: string | React.ReactNode
  tooltip?: string
}

const Progress: React.FC<ProgressProps> = ({
  fillPercent,
  growDirection,
  color,
  striped,
  height = 30,
  width,
  backgroundColor = '#111830',
  animate,
  label,
  tooltip,
}) => {
  const progressBackgroundJustifyContent = growDirection === 'right' ? 'flex-end' : growDirection === 'center' ? 'center' : 'flex-start'

  const [maxWidth, setMaxWidth] = useState(Infinity)
  const [calculatedWidth, setCalculatedWidth] = useState(0)

  const setProgressWidth = useCallback(() => {
    const progressWrapper = document.getElementById('progress-wrapper')

    setMaxWidth(progressWrapper?.scrollWidth || Infinity)
    setCalculatedWidth((progressWrapper?.scrollWidth || 0) * fillPercent)
  }, [fillPercent])

  useEffect(() => {
    setProgressWidth()
  }, [setProgressWidth])

  useWindowResized(setProgressWidth)

  return (
    <Tooltip content={tooltip} placement='top' trigger='mouseenter' disabled={!tooltip} arrow={false}>
      <Wrapper id='progress-wrapper' width={width}>
        <ProgressBackground justifyContent={progressBackgroundJustifyContent} backgroundColor={backgroundColor} height={height} width='100%'>
          <ProgressLabel>{label}</ProgressLabel>
          <ProgressBar color={color} height={height} width={100 * (calculatedWidth / maxWidth) + '%'} striped={striped} animate={animate} />
        </ProgressBackground>
      </Wrapper>
    </Tooltip>
  )
}

export default Progress
