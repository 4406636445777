import * as GQL from 'generated/graphql'

export function routeReadyForDispatch(route: GQL.RouteInfo) {
  return route.driver !== null && route.depot !== null && route.vehicle !== null && route.stops && route.stops?.length > 0
}

export function getRouteIsNotReadyError(route: GQL.RouteInfo) {
  if (route.driver === null) {
    return 'routing.dispatch.errors.no-driver'
  } else if (route.depot === null) {
    return 'routing.dispatch.errors.no-depot'
  } else if (route.vehicle === null) {
    return 'routing.dispatch.errors.no-vehicle'
  } else if (route.stops?.length === 0) {
    return 'routing.dispatch.errors.no-stops'
  } else {
    return 'routing.dispatch.errors.generic'
  }
}

export function getErrorMessageIdFromOptimiseError(errorMessage: string): string {
  if (errorMessage.includes('Unfound route(s)')) {
    return 'routing.errors.optimize-error.unfound-route'
  } else if (errorMessage.includes('time_window')) {
    return 'routing.errors.optimize-error.time-window'
  }
  return 'routing.errors.optimize-error.default'
}

export const getDateOnly = (date: Date) => new Date(date.getFullYear(), date.getMonth(), date.getDate())

export const calculateDriverTimeEffort = (route: GQL.RouteNode): number => {
  const routeStartDate = new Date(route.startTime)
  const routeDow = routeStartDate.getDay()

  const workHoursForDay = route.driver?.standardWorkhours?.find(workhour => workhour?.dow === routeDow)
  if (!workHoursForDay) {
    return 100
  }

  const workStart = new Date(routeStartDate.toDateString() + ' ' + workHoursForDay.startTime).getTime()
  const workEnd = new Date(routeStartDate.toDateString() + ' ' + workHoursForDay.endTime).getTime()
  const totalWorkMinutes = (workEnd - workStart) / 1000 / 60

  const routeStart = new Date(route.startTime).getTime()
  const routeEnd = new Date(route.endTime).getTime()
  const routeMinutes = (routeEnd - routeStart) / 1000 / 60

  return Math.round((routeMinutes / totalWorkMinutes) * 100)
}
