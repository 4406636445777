import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#212843',
    },
    secondary: {
      main: '#1f2641',
    },
  },
  props: {
    MuiSelect: {
      color: 'primary',
    },
    MuiButton: {
      variant: 'contained',
      color: 'secondary',
    },
    MuiInput: {
      color: 'primary',
      margin: 'dense',
      disableUnderline: true,
    },
    MuiPaper: {
      elevation: 0,
    },
  },
  overrides: {
    MuiMenuItem: {
      root: {
        color: 'rgba(255,255,255,0.3)',
        fontSize: '14px',
        fontWeight: 'normal',
        paddingLeft: '1rem',
        '&:hover': {
          color: '#2c97de',
          background: 'rgb(33, 40, 67)',
        },
      },
    },
    MuiPaper: {
      root: {
        background: 'none',
        backgroundColor: 'none',
      },
    },
    MuiMenu: {
      list: {
        backgroundColor: '#181d36',
        color: 'rgba(255,255,255,0.7)',
      },
    },
    MuiPopover: {
      root: {},
      paper: {
        background: '#111830',
        padding: '1em',
        color: '#fff',
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiInputBase: {
      root: {
        background: '#181d36',
        borderColor: 'rgba(255,255,255,0.2)',
        width: '100%',
        border: '1px solid rgba(255,255,255,0.2)',
      },
      input: {},
    },
    MuiSelect: {
      root: {
        width: '100%',
        color: 'rgba(255,255,255,0.7)',
      },
      icon: {
        color: 'rgba(255,255,255,0.7)',
      },
    },
  },
})

export default theme
