/**
 * Tries to parse json, and if the passed in string is not
 * legal json, it returns the empty object
 */
export function safeParseJson(jsonString) {
  try {
    const parsed = JSON.parse(jsonString)
    return parsed
  } catch {
    return {}
  }
}

export function grapheneIdToInt(id) {
  return atob(id).replace(/^\D+/g, '')
}
