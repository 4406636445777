// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type OrderStatus03SvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function OrderStatus03SvgIcon(props: OrderStatus03SvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 122.566 121.709"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M98.819 9.443h-3.11V3.747a3.746 3.746 0 10-7.494 0v5.696H34.361V3.747a3.75 3.75 0 00-7.498 0v5.696h-3.11C10.655 9.443 0 20.098 0 33.195v64.762c0 13.097 10.655 23.752 23.752 23.752h75.062c13.096 0 23.751-10.655 23.751-23.752V33.195c.005-13.097-10.65-23.752-23.746-23.752zm-6.856 42.21L54.824 88.789a5.266 5.266 0 01-3.734 1.547 5.267 5.267 0 01-3.734-1.547L30.604 72.038a5.28 5.28 0 010-7.468 5.28 5.28 0 017.468 0l13.019 13.017 33.404-33.402a5.28 5.28 0 017.468 0 5.28 5.28 0 010 7.468z"
        }
      ></path>
    </svg>
  );
}

export default OrderStatus03SvgIcon;
/* prettier-ignore-end */
