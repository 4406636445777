import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import Modal from 'components/Modal/Modal'
import ModalConfirm from 'plasmic/ModalConfirm'

interface Props {
  data?: any
  isOpen: boolean
  onRequestClose: () => void
  onSubmit: (id: string) => void
}

const DeleteDistributorModal: React.FC<Props> = props => {
  const intl = useIntl()
  const t = intl.formatMessage

  const [distributorTagData, setDistributorTagData] = useState({
    name: props.data?.name || '',
    id: props.data?.id || '',
  })

  const handleDeleteDistributorTag = () => {
    setDistributorTagData({ ...distributorTagData })
    props.onSubmit(props?.data?.id)
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={() => props.onRequestClose()}
      stripped
      overlayStyle={{ alignItems: 'flex-start', overflow: 'auto', padding: '2rem 0rem' }}
    >
      <ModalConfirm
        title={t({ id: 'settings.tags.delete' }) + ': ' + props?.data?.name}
        description={t({ id: 'settings.tags.delete.description' })}
        btnConfirm={{
          props: {
            onClick: () => handleDeleteDistributorTag(),
          },
        }}
        btnColor={'red'}
        btnCancel={{
          props: {
            onClick: () => props.onRequestClose(),
          },
        }}
        btnClose={{
          props: {
            onClick: () => props.onRequestClose(),
          },
        }}
      />
    </Modal>
  )
}

export default DeleteDistributorModal
