// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import * as p from '@plasmicapp/react-web'
import { PlasmicModalAdjustStock, DefaultModalAdjustStockProps } from './plasmic/solace_components/PlasmicModalAdjustStock'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import ButtonFill from './ButtonFill'
import InputField from './InputField'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface ModalAdjustStockProps extends Omit<DefaultModalAdjustStockProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultModalAdjustStockProps altogether and have
// total control over the props for your component.
export interface ModalAdjustStockProps extends DefaultModalAdjustStockProps {
  btnClose?: p.Flex<'button'>
  btnCancel?: p.Flex<typeof ButtonFill>
  btnSave?: p.Flex<typeof ButtonFill>
  inputCurrentQuantity?: p.Flex<typeof InputField>
  inputNewQuantity?: p.Flex<typeof InputField>
  inputDescription?: p.Flex<'textarea'>
  selectReasonDiv?: p.Flex<'div'>
  selectDepotDiv?: p.Flex<'div'>
  labelDepot?: p.Flex<'div'>
}

function ModalAdjustStock_(props: ModalAdjustStockProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicModalAdjustStock to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicModalAdjustStock are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all ModalAdjustStockProps here, but feel free
  // to do whatever works for you.

  return <PlasmicModalAdjustStock root={{ ref }} {...props} />
}

const ModalAdjustStock = React.forwardRef(ModalAdjustStock_)
export default ModalAdjustStock
