import { useIntl } from 'react-intl'
import * as GQL from 'generated/graphql'
import styled from 'styled-components'
import { format } from 'date-fns'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import SettingsContentWrapper from 'plasmic/SettingsContentWrapper'

const VersionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`

const ReactMarkdownStyled = styled(ReactMarkdown)`
  line-height: 1.3;
  margin: 0;
  padding: 0;
  margin-left: 1em;
  margin-bottom: 1em;
  white-space: normal;
  ul {
    margin: 0;
    padding-inline-start: 25px;
  }
  li {
    margin: 0;
  }
  li::marker {
  }
  h3 {
    margin: 0;
    margin-top: 0.8em;
  }
  p {
    margin: 0;
  }
  blockquote {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: 20px;
    margin-inline-end: 40px;
  }
`

const Version = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid rgba(33, 40, 67, 1);
  width: 450px;
  border-radius: 10px;
  .title {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
    .version {
      margin-right: 10px;
      padding: 5px;
      border: 1px solid #3a4677;
      background-color: rgba(33, 40, 67, 1);
      border-radius: 5px;
    }
    .date {
      color: #ffffffd8;
      font-weight: 600;
    }
  }
`

export default function SettingsChangelog() {
  const { data: dataVersions } = GQL.useGetAppVersions({
    variables: {
      first: 50,
    },
  })

  const intl = useIntl()
  const t = intl.formatMessage

  return (
    <SettingsContentWrapper
      settingsTitle={t({ id: 'common.change-log' })}
      headerButtons={<></>}
      content={
        <VersionWrapper>
          {dataVersions?.getAppVersions?.edges.map(version => (
            <Version>
              <div className='title'>
                <div className='version'>{version?.node?.version}</div>
                <div className='date'>{format(new Date(version?.node?.createdAt || '0'), 'dd. MMMM, yyyy')}</div>
              </div>
              <div className='text'>
                <ReactMarkdownStyled remarkPlugins={[remarkGfm]}>{version?.node?.info || 'no info'}</ReactMarkdownStyled>
              </div>
            </Version>
          ))}
        </VersionWrapper>
      }
    />
  )
}
