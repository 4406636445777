// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type FlamesvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function FlamesvgIcon(props: FlamesvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 112 150"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M56 .437C23.291 29.95.077 64.782.077 87.976c0 36.262 22.995 61.588 55.923 61.588s55.923-25.325 55.923-61.588c0-23.287-23.366-58.163-55.923-87.539zm0 130.486c-20.557 0-37.282-15.405-37.282-34.337 0-12.853 7.526-20.828 10.002-23.231a2.33 2.33 0 013.976 1.649v11.651c0 9.009 7.302 16.311 16.311 16.311s16.311-7.302 16.311-16.311c0-20.971-32.808-18.865-11.485-47.863.842-1.145 2.179-1.212 3.082-.923.472.154 1.567.652 1.567 1.975 0 9.772 7.296 16.011 15.02 22.611 9.722 8.313 19.777 16.905 19.777 34.139.003 18.924-16.722 34.329-37.279 34.329z"
        }
      ></path>
    </svg>
  );
}

export default FlamesvgIcon;
/* prettier-ignore-end */
