import styled from 'styled-components'
import { useIntl } from 'react-intl'
import Margin from 'components/Margin'
import PlussvgIcon from 'plasmic/plasmic/solace_components/icons/PlasmicIcon__Plussvg'
import ButtonLight from 'plasmic/ButtonLight'

const Wrapper = styled.div`
  padding: 1rem 2rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  left: 0;
`

const Title = styled.h2`
  color: white;
  font-weight: 600;
  margin: 0;
`

const ButtonsWrapper = styled.div`
  display: flex;
`

interface Props {
  setCreateOrderModalOpen: (open: boolean) => void
}

export default function OrdersHeader(props: Props) {
  const intl = useIntl()
  const t = intl.formatMessage

  return (
    <Wrapper>
      <Title>{t({ id: 'common.orders' })}</Title>
      <ButtonsWrapper>
        <Margin right='1rem'>
          <ButtonLight
            iconLeft={true}
            color={'green'}
            slim={true}
            label={'Create new order'}
            icon={<PlussvgIcon />}
            onClick={() => props.setCreateOrderModalOpen(true)}
          />
        </Margin>
      </ButtonsWrapper>
    </Wrapper>
  )
}
