// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ScRegistersvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ScRegistersvgIcon(props: ScRegistersvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 42 42.002"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <path
          d={
            "M32.99 34.974h-3.023a1.99 1.99 0 00-1.983 1.982l.001 3.024a1.988 1.988 0 001.982 1.982h3.023a1.989 1.989 0 001.982-1.982l.001-3.022a1.99 1.99 0 00-1.983-1.984zm6.985-6.987h-3.022c-1.09 0-1.982.892-1.982 1.981l.001 3.024c0 1.09.891 1.982 1.982 1.982h3.021a1.989 1.989 0 001.983-1.982v-3.023a1.99 1.99 0 00-1.983-1.982zm-28.637.695l-1.635-.001a1.988 1.988 0 00-1.982 1.981v1.634c0 1.091.892 1.983 1.982 1.982h1.635a1.987 1.987 0 001.982-1.982v-1.634a1.986 1.986 0 00-1.982-1.98z"
          }
        ></path>

        <path
          d={
            "M39.976 14.015h-3.021a1.988 1.988 0 01-1.983-1.983V9.01c0-1.09-.892-1.982-1.982-1.982l-3.023-.001a1.99 1.99 0 00-1.982 1.983v3.022c0 1.09-.892 1.983-1.982 1.982l-3.023.001a1.99 1.99 0 01-1.982-1.983V9.01a1.987 1.987 0 00-1.982-1.982l-3.021.002a1.986 1.986 0 00-1.982 1.981v5.005l-.001 4.753v.251c0 1.09-.892 1.983-1.982 1.982H2.022A1.99 1.99 0 00.04 22.984l.001 5.004v11.991a1.987 1.987 0 001.981 1.982h5.004l6.986-.001h5.004a1.985 1.985 0 001.982-1.981v-3.023c0-1.09.891-1.982 1.982-1.982h3.023c1.09 0 1.983-.892 1.982-1.982l.001-3.022c0-1.09-.892-1.982-1.983-1.982h-3.022a1.99 1.99 0 01-1.983-1.983v-3.022A1.99 1.99 0 0122.981 21h3.023a1.99 1.99 0 011.983 1.982v3.023a1.988 1.988 0 001.982 1.982h3.022c1.09 0 1.982-.892 1.982-1.981v-3.023a1.986 1.986 0 011.982-1.981l3.022.001a1.989 1.989 0 001.982-1.982v-3.023a1.991 1.991 0 00-1.983-1.983zM16.243 35.172a1.989 1.989 0 01-1.982 1.982l-7.432-.001a1.988 1.988 0 01-1.982-1.981V27.74a1.989 1.989 0 011.982-1.982h7.432a1.987 1.987 0 011.982 1.982v7.432zm20.71-28.143h3.022c1.09 0 1.983-.892 1.982-1.981l-.001-3.024A1.988 1.988 0 0039.974.042h-3.021a1.987 1.987 0 00-1.982 1.982l.001 3.024c0 1.088.891 1.981 1.981 1.981z"
          }
        ></path>

        <path
          d={
            "M22.981 7.03h3.023a1.987 1.987 0 001.982-1.982l.001-3.022A1.99 1.99 0 0026.004.043h-3.021A1.988 1.988 0 0021 2.025l-.001 3.022a1.99 1.99 0 001.982 1.983zM2.023 14.015l3.023.001c1.09 0 1.982-.892 1.982-1.983V9.011c0-1.091.891-1.982 1.982-1.982l3.023.001a1.989 1.989 0 001.981-1.983V2.026c0-1.09-.892-1.982-1.982-1.982H2.024A1.988 1.988 0 00.042 2.027v10.009c0 1.087.892 1.979 1.981 1.979z"
          }
        ></path>
      </g>
    </svg>
  );
}

export default ScRegistersvgIcon;
/* prettier-ignore-end */
