import React, { useState } from 'react'

import * as GQL from 'generated/graphql'
import Modal from 'components/Modal/Modal'
import { displayToast } from 'util/toasts'
import { EditStock } from '../types'
import Loader from 'components/Loader'
import ModalEditCount from 'plasmic/ModalEditCount'

interface Props {
  isOpen: boolean
  onClose: () => void
  editStockInfo: EditStock
}

const EditStockModal: React.FC<Props> = props => {
  const { inventory, stockState, currentQuantity } = props.editStockInfo
  const [newQuantity, setNewQuantity] = useState('0')
  const [description, setDescription] = useState('')

  const [patchInventory, { loading: patchInventoryLoading }] = GQL.usePatchInventory({
    onCompleted: data => {
      if (!data.patchInventory?.ok) {
        return displayToast('Failed to update stock', 'error')
      }
      displayToast('Stock updated successfully', 'success')
      return props.onClose()
    },
    onError: error => {
      displayToast('Failed to update stock', 'error')
    },
  })

  const handleOnSubmit = () => {
    if (patchInventoryLoading) return
    patchInventory({
      variables: {
        inventoryId: inventory.id,
        input: {
          stock: {
            state: stockState,
            amount: parseInt(newQuantity),
          },
          reason: GQL.InventoryAdjustmentReason.ManualCorrection,
          description: description,
          total: props.editStockInfo.adjustTotal,
        },
      },
    })
  }

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.onClose} stripped>
      <ModalEditCount
        inputNewQuantity={{
          undefinedInput: {
            value: newQuantity,
            name: 'newQuantity',
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setNewQuantity(event.target.value),
          },
        }}
        inputDescription={{
          props: { value: description, name: 'description', onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => setDescription(event.target.value) },
        }}
        inputCurrentQuantity={{ undefinedInput: { value: currentQuantity?.toString() } }}
        btnClose={{ onClick: props.onClose }}
        btnCancel={{ onClick: props.onClose }}
        btnSave={{ onClick: handleOnSubmit, label: patchInventoryLoading ? <Loader size={32} color='white' /> : 'Save' }}
      />
    </Modal>
  )
}

export { EditStockModal }
