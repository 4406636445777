// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type StatusIconOutsideAreasvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function StatusIconOutsideAreasvgIcon(
  props: StatusIconOutsideAreasvgIconProps
) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 41 49"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M20.5.35c-11 0-20 9-20 20 0 5.3 2.1 10.1 5.4 13.6l14.6 14.7 14.6-14.6c3.3-3.6 5.4-8.4 5.4-13.7 0-11-9-20-20-20zm0 36c-8.837 0-16-7.163-16-16s7.163-16 16-16 16 7.163 16 16-7.163 16-16 16z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M11.675 13.969h11.79c.842 0 1.49.713 1.49 1.49v1.36h3.11c.13 0 .324.065.389.194l2.527 3.498c.065.065.065.13.065.194v3.239c0 .648-.583 1.23-1.231 1.23h-.195a2.157 2.157 0 01-2.073 1.556 2.157 2.157 0 01-2.073-1.555h-8.227c-.259.907-1.101 1.555-2.073 1.555s-1.814-.648-2.073-1.555h-1.49c-.842 0-1.49-.713-1.49-1.49v-8.227c-.001-.776.712-1.49 1.554-1.49zm5.701 3.822c0 .259.259.453.518.453h4.664a.442.442 0 00.453-.453.442.442 0 00-.453-.453h-4.729a.442.442 0 00-.453.453zm7.968.907v2.332c0 .259.194.453.453.453h3.304c.13 0 .194 0 .259-.065a.504.504 0 00.13-.648l-1.62-2.332c-.065-.13-.259-.194-.389-.194h-1.685c-.322 0-.452.195-.452.454zm-9.653.778c-.259 0-.453.259-.453.518s.259.453.518.453h6.802a.442.442 0 00.453-.453.442.442 0 00-.453-.453h-6.867c.065-.065 0-.065 0-.065zm-.518 4.016a1.16 1.16 0 00-1.166 1.166 1.16 1.16 0 001.166 1.166 1.16 1.16 0 001.166-1.166 1.16 1.16 0 00-1.166-1.166zm11.272 1.166c0 .648.518 1.166 1.166 1.166s1.166-.518 1.166-1.166c0-.648-.518-1.166-1.166-1.166s-1.166.518-1.166 1.166z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default StatusIconOutsideAreasvgIcon;
/* prettier-ignore-end */
