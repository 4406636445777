import { FunctionComponent, RefObject, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

import { StopNode } from 'generated/graphql'
import TableRow, { TableRowProps } from 'plasmic/TableRow'

interface Props extends TableRowProps {
  stop: StopNode
  type: string
  onRowHover: (ref: RefObject<HTMLDivElement>, item: any, monitor: any) => void
  onRowDrop: (item: any, monitor: any) => void
}

const DraggableTableRow: FunctionComponent<Props> = props => {
  const ref = useRef<HTMLDivElement>(null)

  const [collected, drag] = useDrag<any, any, any>(() => ({
    type: props.type,
    item: { index: props.stop.id, ...props.stop },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  // eslint-disable-next-line
  const [_, drop] = useDrop<any, any, any>(() => ({
    accept: props.type,
    hover: (item, monitor) => props.onRowHover(ref, item, monitor),
    drop: (item, monitor) => props.onRowDrop(item, monitor),
  }))

  // Apply drag and drop effect
  drop(drag(ref))

  return collected.isDragging ? (
    <div ref={ref} style={{ opacity: 0 }}>
      <TableRow {...props} />
    </div>
  ) : (
    <div ref={ref} {...collected}>
      <TableRow {...props} />
    </div>
  )
}

export default DraggableTableRow
