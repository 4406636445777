import Table from './Table'
import TableBody from './TableBody'
import TableCell from './TableCell'
import TableHead from './TableHead'
import TableHeaderCell from './TableHeaderCell'
import TableRow from './TableRow'

Table.Body = TableBody
Table.Cell = TableCell
Table.Head = TableHead
Table.HeaderCell = TableHeaderCell
Table.Row = TableRow

export default Table
