import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { overloadColorProp } from 'util/style'
import { PopoverProps } from '@material-ui/core'
import * as GQL from 'generated/graphql'
import { $enum } from 'ts-enum-util'
import { ORDER_METHOD_COLORS, ORDER_METHOD_TRANSLATIONS } from '../consts'

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    background: #252c48;
    min-width: 150px;
    animation: none;
  }
  .MuiList-padding {
    padding: 0;
  }
`

interface IStyledMenuItemProps {
  color?: string
}

const StyledMenuItem = styled(MenuItem)<IStyledMenuItemProps>`
  font-weight: 500;
  text-transform: none;
  font-family: 'Proxima Nova', sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  color: #ffffff;
  background: ${props => overloadColorProp(props)};
  text-align: center;
  justify-content: center;
  :hover {
    background: ${props => overloadColorProp(props)};
    -webkit-filter: brightness(1.1);
    filter: brightness(1.1);
    color: #ffffff;
    text-shadow: 1px 1px 3px black;
  }
`

interface ICustomerOrderMethodMenuProps {
  anchorElement: PopoverProps['anchorEl']
  currentMethod: GQL.CustomerOrderMethod | null
  onClose: () => void
  callback: ((method: GQL.CustomerOrderMethod) => void) | null
}

export default function CustomerOrderMethodMenu({ anchorElement, currentMethod, onClose, callback }: ICustomerOrderMethodMenuProps) {
  const intl = useIntl()
  const t = intl.formatMessage

  function handleMethodClick(method: GQL.CustomerOrderMethod) {
    if (callback) {
      callback(method)
    }
  }

  return (
    <StyledMenu
      id='simple-menu'
      anchorEl={anchorElement}
      keepMounted
      open={!!anchorElement}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      getContentAnchorEl={null}
      transitionDuration={100}
    >
      {$enum(GQL.CustomerOrderMethod)
        .getValues()
        .filter(method => method !== currentMethod)
        .map(method => (
          <StyledMenuItem color={ORDER_METHOD_COLORS[method]} onClick={() => handleMethodClick(method)} key={method}>
            {t({ id: ORDER_METHOD_TRANSLATIONS[method] })}
          </StyledMenuItem>
        ))}
    </StyledMenu>
  )
}
