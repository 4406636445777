// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ZeroWeightsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ZeroWeightsvgIcon(props: ZeroWeightsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 41.843 40"}
      xmlSpace={"preserve"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M38.795 33.905H3.048a3.047 3.047 0 100 6.095h35.748a3.048 3.048 0 10-.001-6.095zm-20.03-4.12c.281.281.618.504.991.659.372.154.767.235 1.165.235s.793-.081 1.165-.235c.373-.155.71-.378.991-.659L34.021 18.84a3.048 3.048 0 00-4.31-4.31l-5.743 5.743V3.048a3.048 3.048 0 10-6.095 0v17.227l-5.743-5.743a3.048 3.048 0 00-4.31 4.31l10.945 10.943z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ZeroWeightsvgIcon;
/* prettier-ignore-end */
