import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import * as GQL from 'generated/graphql'
import CustomerNotifications from '../components/CustomerNotifications'
import MessageEditor from '../components/MessageEditor'
import SettingsContentWrapper from 'plasmic/SettingsContentWrapper'

export interface IDrawerState {
  resource: GQL.NotificationResourceNode
  customNotification?: GQL.CustomNotificationNode
}

const SettingsCustomerNotifications: React.FC = () => {
  const intl = useIntl()
  const t = intl.formatMessage

  const [drawerState, setDrawerState] = useState<IDrawerState | null>(null)

  return (
    <SettingsContentWrapper
      settingsTitle={t({ id: 'settings.customer-notifications' })}
      headerButtons={<></>}
      showSidebar={!!drawerState}
      sidebarContent={<MessageEditor eventTitle={drawerState?.resource?.title} setDrawerState={setDrawerState} drawerState={drawerState} />}
      content={<CustomerNotifications setDrawerState={setDrawerState} />}
    />
  )
}

export default SettingsCustomerNotifications
