import { useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import * as GQL from 'generated/graphql'
import { useAppContext, useDebounce } from 'util/hooks'
import FlexTableWrapper from 'plasmic/FlexTableWrapper'
import FlexTable from 'plasmic/FlexTable'
import TableHeadTitle from 'plasmic/TableHeadTitle'
import TableRow from 'plasmic/TableRow'
import CellCustomer from 'plasmic/CellCustomer'
import CellAddress from 'plasmic/CellAddress'
import ContactSignupsModal from './components/ContactSignupsModal'
import CellEmail from 'plasmic/CellEmail'
import CellPhoneNumber from 'plasmic/CellPhoneNumber'
import CellCustomerType from 'plasmic/CellCustomerType'
import CellPendingOrder from 'plasmic/CellPendingOrder'
import CellReview from 'plasmic/CellReview'
import ContactSignupsHeader from './components/ContactSignupsHeader'
import ViewContactSignupOrderModal from './components/ViewContactSignupOrderModal'
import Loader from 'components/Loader'
import ScrollIndicator from '../../plasmic/ScrollIndicator'
import CornerLoader from 'components/Loader/CornerLoader'
import { format, parseISO } from 'date-fns'
import CellDateOrdered from 'plasmic/CellDateOrdered'

const NoOrder = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10em;
`

const ContactSignups = () => {
  const contentWrapperTop = document.getElementById('content-wrapper-top')

  const { appContext } = useAppContext()
  const distributor = appContext.distributor!

  const [search, setSearch] = useState('')
  const [selectedSignup, setSelectedSignup] = useState<GQL.CustomerContactSignupNode | null>(null)
  const [selectedReviewableOrders, setSelectedReviewableOrders] = useState<GQL.Maybe<GQL.ReviewableCylinderGroupOrderNode>[] | null>(null)
  const [loadingMoreContactSignups, setLoadingMoreContactSignups] = useState(false)

  const debouncedSearch = useDebounce(search, 500)

  const { data: dataContactSignups, loading: loadingContactSignups, fetchMore } = GQL.useAllCustomerContactSignups()
  const contactSignups = useMemo(
    () => dataContactSignups?.allCustomerContactSignups?.edges.map(edge => edge?.node as GQL.CustomerContactSignupNode) || [],
    [dataContactSignups]
  )

  useEffect(() => {
    setLoadingMoreContactSignups(true)
    fetchMore({
      variables: {
        search: debouncedSearch,
      },
      updateQuery(prev: any, { fetchMoreResult }: { fetchMoreResult: any }) {
        if (!fetchMoreResult) return prev
        return {
          ...fetchMoreResult,
        } as GQL.AllCustomerContactSignups
      },
    }).finally(() => setLoadingMoreContactSignups(false))
  }, [fetchMore, debouncedSearch, distributor])

  const loading = useMemo(() => loadingContactSignups || loadingMoreContactSignups, [loadingContactSignups, loadingMoreContactSignups])

  const handleReviewableOrder = (signup: GQL.CustomerContactSignupNode) => {
    if (!signup.orders || signup.orders.length < 1) return setSelectedReviewableOrders(null)
    return setSelectedReviewableOrders(signup.orders)
  }

  return (
    <>
      {/* Fix for https://smartcylinders.atlassian.net/browse/SW-324 */}
      {contentWrapperTop && createPortal(<ContactSignupsHeader search={search} onSearchChanged={setSearch} />, contentWrapperTop)}
      {loading && <CornerLoader size={65} topAdjust={'-30px'} />}
      <FlexTableWrapper
        tables={
          <FlexTable
            noSorting
            noCheckbox
            visibleColumns={['address', 'email', 'phoneNumber', 'dateOrdered', 'customerType', 'pendingOrder', 'review']}
            headDateOrderedTitle='Signup date'
            children={<TableHeadTitle title='New contact signups' count={null} />}
            rows={
              loadingContactSignups ? (
                <LoaderContainer>
                  <Loader size={64} color='grey' />
                </LoaderContainer>
              ) : (
                <>
                  {contactSignups.length > 0 ? (
                    contactSignups?.map(signup => (
                      <TableRow
                        data-testid='customer-self-signup'
                        key={signup.id}
                        noCheckbox
                        sticky={<CellCustomer name={signup.name} onClick={() => setSelectedSignup(signup)} />}
                        scrollCells={
                          <>
                            <CellAddress
                              address={signup.address?.firstLine}
                              googleMapsLink={
                                'https://www.google.com/maps/search/?api=1&query=' +
                                parseFloat(signup?.address?.latitude) +
                                ',' +
                                parseFloat(signup?.address?.longitude)
                              }
                            />
                            <CellEmail content={signup.email} />
                            <CellPhoneNumber content={signup.phone} />
                            <CellDateOrdered content={format(parseISO(signup.createdAt), 'dd. MMM - HH:mm')} />
                            <CellCustomerType content={signup.customerDomainType} />
                            {signup.orders && signup.orders.length > 0 ? (
                              <CellPendingOrder data-testid='reviewable-order' onClick={() => handleReviewableOrder(signup)} />
                            ) : (
                              <CellPendingOrder data-testid='reviewable-order' content={<NoOrder>No order</NoOrder>} />
                            )}
                            <CellReview data-testid='review-customer' onClick={() => setSelectedSignup(signup)} />
                          </>
                        }
                      />
                    ))
                  ) : (
                    <ScrollIndicator fullyLoaded={true} tableRow={true} />
                  )}
                </>
              )
            }
          />
        }
      />
      {!!selectedSignup && <ContactSignupsModal contact={selectedSignup} isOpen={!!selectedSignup} onRequestClose={() => setSelectedSignup(null)} />}
      {!!selectedReviewableOrders && (
        <ViewContactSignupOrderModal
          orders={selectedReviewableOrders}
          isOpen={!!selectedReviewableOrders}
          onRequestClose={() => setSelectedReviewableOrders(null)}
        />
      )}
    </>
  )
}

export default ContactSignups
