// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type DropdownSeparatorsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function DropdownSeparatorsvgIcon(props: DropdownSeparatorsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 16.994 54.27"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path d={"M3.357 0H0l13.636 27.135L0 54.27h3.358l13.636-27.135z"}></path>
    </svg>
  );
}

export default DropdownSeparatorsvgIcon;
/* prettier-ignore-end */
