import ModalViewOrder from 'plasmic/ModalViewOrder'
import * as GQL from 'generated/graphql'
import Modal from 'components/Modal/Modal'
import OrderInfoExtended from 'plasmic/OrderInfoExtended'
import NoteElement from 'plasmic/NoteElement'

interface Props {
  isOpen: boolean
  order: GQL.CylinderGroupOrderNode
  onRequestClose: () => void
}

const ViewOrderModal = ({ isOpen, order, onRequestClose }: Props) => {
  return (
    <Modal
      stripped
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentStyle={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      overlayStyle={{ alignItems: 'flex-start', overflow: 'auto', padding: '2rem 0rem' }}
    >
      <ModalViewOrder
        btnClose={{ onClick: () => onRequestClose() }}
        orderCard={
          <OrderInfoExtended
            order={order}
            deliveryComment={order.comment}
            hasOrderNote={order.notes ? order.notes.length > 0 : false}
            orderNotes={order.notes?.map(note => <NoteElement key={note?.id} content={note?.content} />)}
            initiallyOpened
          />
        }
      />
    </Modal>
  )
}

export default ViewOrderModal
