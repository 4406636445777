import React from 'react'
import ReactModal from 'react-modal'
import { useTheme } from 'styled-components'
import { useIntl } from 'react-intl'

import ModalUpload from 'plasmic/ModalUpload'
import { getModalStyle } from 'components/Modal/Modal'
import { processCSV, processXLSX } from '../util'
import * as GQL from 'generated/graphql'
import { displayToast } from 'util/toasts'

interface Props {
  isOpen: boolean
  onRequestClose: () => void
}

const ImportCustomersModal: React.FC<Props> = ({ isOpen, onRequestClose }) => {
  const intl = useIntl()
  const t = intl.formatMessage

  const theme = useTheme()

  const [importCustomers] = GQL.useImportCustomers({
    onError: () => {
      displayToast(t({ id: 'customers.toasts.import.error-parsing' }))
    },
  })

  const validTypes = ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
  const onUpload = async (file: File) => {
    if (!validTypes.includes(file.type)) {
      displayToast(t({ id: 'customers.toasts.import.wrong-filetype' }), 'error')
      return
    }

    if (file.size > 4 * 1024 * 1024) {
      displayToast(t({ id: 'customers.toasts.import.too-big-size' }), 'error')
      return
    }

    if (file.type === 'text/csv') {
      const rows = await processCSV(file)
      importCustomers({
        variables: {
          input: rows,
        },
      })
      displayToast(t({ id: 'customers.toasts.import.sent-successfully' }), 'success')
    } else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel') {
      const rows = await processXLSX(file)
      importCustomers({
        variables: {
          input: rows,
        },
      })
      displayToast(t({ id: 'customers.toasts.import.sent-successfully' }), 'success')
    } else {
      displayToast(t({ id: 'customers.toasts.import.error-parsing' }), 'error')
    }

    onRequestClose()
  }

  const handleDownload = () => {
    const downloadUrl = '/Customer_Import_Template.xlsx'
    const link = document.createElement('a')
    link.href = downloadUrl
    link.download = 'Customer_Import_Template.xlsx'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose} style={getModalStyle({ height: 'auto', overflowY: 'auto' }, {}, false, theme, true)}>
      <ModalUpload
        onClose={onRequestClose}
        btnDownloadTemplate={{
          onClick: () => handleDownload(),
        }}
        onUpload={onUpload}
        validFileTypes={validTypes}
      />
    </ReactModal>
  )
}

export default ImportCustomersModal
