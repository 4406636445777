import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import * as GQL from 'generated/graphql'
import { EMAIL_REGEX } from 'modules/users/consts'
import ModalCreateNewUser from 'plasmic/ModalCreateNewUser'
import PhoneInput from 'components/Input/PhoneInput'
import { TutorialInfoIcon } from 'components/Tooltip/TutorialTooltip'
import Loader from 'components/Loader'

interface IUserFormProps {
  user?: GQL.UserNode
  onSubmit: (data: UserFormData) => void
  onClose: () => void
  loading?: boolean
}

export interface UserFormData {
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
}

const UserForm: React.FC<IUserFormProps> = ({ user, onSubmit, onClose, loading = false }) => {
  const [userData, setUserData] = useState({
    email: user?.email || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    phoneNumber: user?.phoneNumber || '',
  })
  const [formErrors, setFormErrors] = useState({
    firstName: (user?.firstName && user?.firstName?.length < 3) || false,
    lastName: (user?.lastName && user?.lastName?.length < 3) || false,
    email: (user?.email && !EMAIL_REGEX.test(user?.email)) || false,
    isPhoneNumberInvalid: user?.phoneNumber || false,
    showErrors: false,
  })

  const intl = useIntl()
  const t = intl.formatMessage

  const handleSubmit = () => {
    setFormErrors({ ...formErrors, showErrors: false })
    if (formErrors.firstName || formErrors.lastName || formErrors.email) {
      setFormErrors({ ...formErrors, showErrors: true })
      return
    }
    onSubmit({ ...userData })
  }

  return (
    <ModalCreateNewUser
      title={user ? t({ id: 'settings.edit-user' }) : t({ id: 'settings.create-user' })}
      btnClose={{
        props: {
          onClick: () => onClose(),
        },
      }}
      inputFirstName={{
        props: {
          alert: userData.firstName.length < 3,
          error: formErrors.showErrors && formErrors.firstName,
          fieldValue: userData.firstName,
          undefinedInput: {
            name: 'firstName',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setUserData({ ...userData, firstName: e.target.value })
              if (e.target.value.length < 3) {
                setFormErrors({ ...formErrors, firstName: true })
              } else {
                setFormErrors({ ...formErrors, firstName: false })
              }
            },
          },
          tooltip: <TutorialInfoIcon content={t({ id: 'tooltips.settings.user-form.first-name' })} />,
        },
      }}
      inputLastName={{
        props: {
          alert: userData.lastName.length < 3,
          error: formErrors.showErrors && formErrors.lastName,
          fieldValue: userData.lastName,
          undefinedInput: {
            name: 'lastName',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setUserData({ ...userData, lastName: e.target.value })
              if (e.target.value.length < 3) {
                setFormErrors({ ...formErrors, lastName: true })
              } else {
                setFormErrors({ ...formErrors, lastName: false })
              }
            },
          },
          tooltip: <TutorialInfoIcon content={t({ id: 'tooltips.settings.user-form.last-name' })} />,
        },
      }}
      inputEmail={{
        props: {
          alert: !EMAIL_REGEX.test(userData.email),
          error: formErrors.showErrors && formErrors.email,
          fieldValue: userData.email,
          undefinedInput: {
            name: 'email',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setUserData({ ...userData, email: e.target.value })
              if (!EMAIL_REGEX.test(e.target.value)) {
                setFormErrors({ ...formErrors, email: true })
              } else {
                setFormErrors({ ...formErrors, email: false })
              }
            },
          },
          tooltip: <TutorialInfoIcon content={t({ id: 'tooltips.settings.user-form.email' })} />,
        },
      }}
      inputPhoneNumber={{
        props: {
          divPhoneNumber: (
            <PhoneInput
              name='phone'
              defaultValue={userData.phoneNumber}
              height='48px'
              error={false} // optional
              onPhoneNumberChange={(...all) => {
                setUserData({ ...userData, phoneNumber: all[3].replace(/\s|-/g, '') })
                if (!all[0]) {
                  setFormErrors({ ...formErrors, isPhoneNumberInvalid: true })
                } else {
                  setFormErrors({ ...formErrors, isPhoneNumberInvalid: false })
                }
              }}
              onSelectFlag={(...all) => {
                setUserData({ ...userData, phoneNumber: all[2].replace(/\s|-/g, '') })
                if (!all[3]) {
                  setFormErrors({ ...formErrors, isPhoneNumberInvalid: true })
                } else {
                  setFormErrors({ ...formErrors, isPhoneNumberInvalid: false })
                }
              }}
            />
          ),
          alert: !userData.phoneNumber || (userData.phoneNumber && formErrors.isPhoneNumberInvalid),
          tooltip: <TutorialInfoIcon content={t({ id: 'tooltips.settings.user-form.phone-number' })} />,
        },
      }}
      btnCreateNewUser={{
        props: {
          label: loading ? <Loader color='white' /> : <FormattedMessage id={user ? 'settings.edit-user' : 'settings.create-user'} />,
          root: {
            onClick: handleSubmit,
          },
        },
      }}
    />
  )
}

export default UserForm
