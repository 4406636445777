import { IAppContext } from 'context/AppContext'

export const enumToStringFormatted = (string: string) => {
  return string.split('_').join(' ').capitalizeFirst()
}

export const paymentsEnabled = (appContext: IAppContext) => {
  return !!(appContext.distributor?.paymentSetting?.stripe?.payoutsEnabled || appContext.distributor?.paymentSetting?.visma)
}

export const stripePaymentsEnabled = (appContext: IAppContext) => {
  return !!appContext.distributor?.paymentSetting?.stripe?.payoutsEnabled
}
