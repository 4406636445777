import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Time, time } from 'lib/time'
import Input from 'components/Input/Input'
import ClickAwayListener from 'react-click-away-listener'

const Layout = styled.div`
  position: absolute;
  top: 100%;
  background: #252c48;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  z-index: 9000;

  display: grid;
  grid-template-columns: auto 10px auto;
  grid-template-rows: auto auto;
  align-items: center;
  justify-items: center;
`

const Submit = styled.button`
  grid-column: 1 / span 3;
  background: #252c48;
  margin-top: 8px;
  filter: brightness(1.1);
  color: unset;

  border: none;
  outline: none;

  height: 32px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  :hover {
    color: ${props => props.theme.colors.green};
    filter: brightness(1.2);
  }
`

interface TimeSelectDialogProps {
  startValue: Time
  endValue: Time
  onChange: (startTime: Time, endTime: Time) => void
}

const TimeSelectDialog: React.FC<TimeSelectDialogProps> = ({ startValue, endValue, onChange }) => {
  const [startInputValue, setStartInputValue] = useState(startValue.format())
  const [endInputValue, setEndInputValue] = useState(endValue.format())

  useEffect(() => {
    setStartInputValue(startValue.format())
  }, [startValue])

  useEffect(() => {
    setEndInputValue(endValue.format())
  }, [endValue])

  function handleStartBlur() {
    const startValue = time(startInputValue)

    if (startValue.isValid()) {
      setStartInputValue(startValue.format())
    }
  }

  function handleEndBlur() {
    const endValue = time(endInputValue)

    if (endValue.isValid()) {
      setEndInputValue(endValue.format())
    }
  }

  function handleSubmit() {
    onChange(time(startInputValue), time(endInputValue))
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <ClickAwayListener onClickAway={handleSubmit}>
      <Layout>
        <Input autoFocus value={startInputValue} onBlur={handleStartBlur} onChange={e => setStartInputValue(e.target.value)} onKeyDown={handleKeyDown} />
        <span>:</span>
        <Input value={endInputValue} onBlur={handleEndBlur} onChange={e => setEndInputValue(e.target.value)} onKeyDown={handleKeyDown} />
        <Submit tabIndex={4} onClick={handleSubmit}>
          <i className='fa fa-check'></i>
        </Submit>
      </Layout>
    </ClickAwayListener>
  )
}

export default TimeSelectDialog
