// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ToastRefreshsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ToastRefreshsvgIcon(props: ToastRefreshsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 32.7 32.7"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M16.4 0C7.3 0 0 7.3 0 16.4c0 9 7.3 16.4 16.4 16.4 9 0 16.4-7.3 16.4-16.4C32.7 7.3 25.4 0 16.4 0zm8.2 16.4c0 4.7-3.8 8.6-8.6 8.6s-8.6-3.8-8.6-8.6V16c.1-2.2 1-4.1 2.5-5.7.5-.5 1.4-.5 1.8 0 .3.3.4.6.4.9 0 .3-.1.7-.4.9-1.6 1.6-2.2 4-1.4 6.1l.1.2c.9 2.3 3.1 3.9 5.6 3.9 3.3 0 6-2.7 6-6 0-1.6-.6-3.1-1.7-4.2v1.4c0 .7-.6 1.3-1.3 1.3-.7 0-1.3-.6-1.3-1.3V9c0-.7.6-1.3 1.3-1.3h4.3c.7 0 1.3.6 1.3 1.3 0 .7-.6 1.3-1.3 1.3h-1.2c1.6 1.6 2.5 3.8 2.5 6.1z"
        }
      ></path>
    </svg>
  );
}

export default ToastRefreshsvgIcon;
/* prettier-ignore-end */
