import React from 'react'
import styled from 'styled-components'
import Input, { IInputProps } from 'components/Input/Input'
import Loader from 'components/Loader/Loader'
import { overloadColorProp } from 'util/style'

interface IWrapperStyle {
  open: boolean
  searchable?: boolean
  onClick: () => any
  border?: string
  borderRadius?: string
  background?: string
  shadow?: string | number
  height?: string
}

interface ISelectMainInputProps extends IInputProps {
  open: boolean
  setOpen: any
  labelOfSelected: any
  placeholder?: string
  searchable?: boolean
  onSearchChanged?: any
  width?: string

  border?: string
  borderRadius?: string

  shadow?: string | number
  outsideIcon?: string | JSX.Element
  forceOutsideIconSpacing?: boolean
  ref?: any
  error?: string
  loading?: boolean
}

const Wrapper = styled.div<IWrapperStyle>`
  position: relative;
  /*display: inline-block;*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 1;

  box-sizing: border-box;
  cursor: pointer;
  text-align: left;
  align-items: center;
  padding: 0 0.5em;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 2px;

  ${props =>
    props.background &&
    `
    background: ${overloadColorProp(props, 'background')};
  `}

  ${props =>
    props.height &&
    `
    height: ${props.height};
  `}

  ${props =>
    props.searchable &&
    `
    padding: 0;
    border: none;
  `}

  input {
    cursor: pointer;
    position: relative;
  }
`

const LabelRenderer = styled.div<{ hasOutsideIcon: boolean }>`
  color: white;
  font-size: 16px;
  padding: 0.5em;
  min-height: 20px;
`
interface ISelectIconStyle {
  open?: boolean
}

const SelectIcon = styled.span<ISelectIconStyle>`
  transform: ${props => (props.open ? 'rotate(90deg)' : 'rotate(0deg)')};
  transform-origin: 50% calc(50% + 2px) 0;
  transition:
    transform 0.1s ease-in-out,
    top 0.1s ease-in-out;
`

const SelectMainInput = React.forwardRef(
  (
    {
      open,
      setOpen,

      labelOfSelected,
      placeholder,
      searchable,
      onSearchChanged,

      width,
      height,
      border,
      borderRadius,
      shadow,
      outsideIcon,
      loading,
      background,
      ...props
    }: ISelectMainInputProps,
    ref
  ) => {
    return (
      <Wrapper
        open={open}
        onClick={() => !searchable && setOpen(!open)}
        border={border}
        borderRadius={borderRadius}
        shadow={shadow}
        background={background}
        searchable={searchable}
        height={height}
      >
        <div style={{ display: 'none' }}>
          <Input readOnly borderRadius={borderRadius} width={width} outsideIcon={outsideIcon} ref={ref} {...props} />
        </div>
        <LabelRenderer hasOutsideIcon={!!outsideIcon}>{loading ? <Loader size={16} color='gray8' /> : labelOfSelected}</LabelRenderer>
        <SelectIcon open={open}>
          <i className='fa fa-sort-down'></i>
        </SelectIcon>
      </Wrapper>
    )
  }
)

export default SelectMainInput
