// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type VehiclePickupsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function VehiclePickupsvgIcon(props: VehiclePickupsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 19.737 9.87"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M19.16 2.381L17.356.577A1.974 1.974 0 0015.959 0h-1.65a.986.986 0 00-.987.987v4.441H.987A.986.986 0 000 6.415v.857C0 8.53.885 9.68 2.131 9.847a2.46 2.46 0 002.31-.978 2.465 2.465 0 002.631.916A2.443 2.443 0 008.75 8.218c.15-.456.166-.9.085-1.307h5.033c-.083.407-.068.851.086 1.308a2.436 2.436 0 001.715 1.576 2.473 2.473 0 003.035-2.886h.54a.495.495 0 00.493-.493V3.778c0-.528-.207-1.027-.577-1.397zM2.467 8.385c-.543 0-.987-.444-.987-.987s.444-.987.987-.987.987.444.987.987-.444.987-.987.987zm3.948 0c-.543 0-.987-.444-.987-.987s.444-.987.987-.987.987.444.987.987-.445.987-.987.987zm9.868 0c-.543 0-.987-.444-.987-.987s.444-.987.987-.987.987.444.987.987-.444.987-.987.987zm-1.48-5.428v-1.48h1.156a.49.49 0 01.348.145l1.335 1.335h-2.839z"
        }
      ></path>
    </svg>
  );
}

export default VehiclePickupsvgIcon;
/* prettier-ignore-end */
