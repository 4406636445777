import styled from 'styled-components'
import { overloadColorProp } from 'util/style'

interface ICircleProps {
  textColor?: string
  color: string
  size: number
  inline?: boolean
  border?: string
}

const Circle = styled.div<ICircleProps>`
  color: ${props => (props.textColor ? props.textColor : 'white')};
  background-color: ${overloadColorProp};
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  justify-content: center;
  align-items: center;

  ${props =>
    props.border &&
    `
    border: ${props.border};
  `}
  border-radius: ${props => props.size}px;
`
Circle.defaultProps = {
  color: 'primary',
  size: 35,
}

export default Circle
