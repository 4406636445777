import * as React from 'react'
import { getLoginToken } from 'util/auth'

interface NetworkImageProps {
  url?: string
  className?: string
  onClick?: () => any
}

const NetworkImage: React.FC<NetworkImageProps> = props => {
  // Components refs
  const img: React.Ref<HTMLImageElement> = React.createRef()
  const token = getLoginToken()
  const { url } = props

  // Load data
  React.useEffect(() => {
    if (url) {
      fetch(url, {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
          'system-origin': 'Solace',
        },
      })
        .then(res => res.blob())
        .then(blob => {
          const objectURL = URL.createObjectURL(blob)
          if (img.current != null) img.current.src = objectURL
          return null
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [token, url, img])

  return <img src={''} alt={'Loading...'} ref={img} {...props} />
}

export default NetworkImage
