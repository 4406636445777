// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { useDrop } from 'react-dnd'
import _ from 'lodash'

import { PlasmicRouteDatePickerItem, DefaultRouteDatePickerItemProps } from './plasmic/solace_components/PlasmicRouteDatePickerItem'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

export interface RouteDatePickerItemProps extends DefaultRouteDatePickerItemProps {
  accept: string[]
  onItemDrop: (item: any, monitor: any) => void
  onClick: () => void
  capacityFill?: any
  capacityDiv?: any
}

function RouteDatePickerItem_(props: RouteDatePickerItemProps, ref: HTMLElementRefOf<'div'>) {
  const [{ isOver, canDrop }, drop] = useDrop<any, any, any>({
    accept: props.accept,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: props.onItemDrop,
  })

  return <PlasmicRouteDatePickerItem root={{ ref: drop }} {..._.omit(props, ['accept', 'onItemDrop'])} dragOver={!!(isOver && canDrop)} />
}

const RouteDatePickerItem = React.forwardRef(RouteDatePickerItem_)
export default RouteDatePickerItem
