// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type VisaOutlinesvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function VisaOutlinesvgIcon(props: VisaOutlinesvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      data-name={"Payment Icons"}
      viewBox={"0 0 38 38"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M33 8a4 4 0 014 4v14a4 4 0 01-4 4H5a4 4 0 01-4-4V12a4 4 0 014-4h28m0-1H5a5 5 0 00-5 5v14a5 5 0 005 5h28a5 5 0 005-5V12a5 5 0 00-5-5z"
        }
      ></path>

      <path
        d={
          "M15.76 15.56l-2.87 6.89H11L9.61 17a.75.75 0 00-.42-.61 7.69 7.69 0 00-1.74-.59v-.2h3a.84.84 0 01.82.71l.74 4 1.84-4.69zm7.33 4.64c0-1.81-2.5-1.91-2.48-2.73 0-.24.24-.51.75-.57a3.32 3.32 0 011.75.3l.31-1.46a4.93 4.93 0 00-1.66-.3c-1.75 0-3 .93-3 2.28 0 1 .88 1.54 1.55 1.87s.92.56.92.86c0 .46-.55.66-1.06.67a3.66 3.66 0 01-1.82-.43L18 22.2a5.41 5.41 0 002 .36c1.86 0 3.07-.92 3.08-2.36m4.62 2.25h1.63l-1.42-6.89h-1.5a.82.82 0 00-.76.51L23 22.45h1.86l.36-1h2.27zm-2-2.44l.94-2.58.56 2.57zm-7.44-4.45l-1.46 6.89h-1.74l1.46-6.89z"
        }
      ></path>
    </svg>
  );
}

export default VisaOutlinesvgIcon;
/* prettier-ignore-end */
