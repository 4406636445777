import styled from 'styled-components'

export interface Props {
  today?: boolean
  selected?: boolean
}

const DateCard = styled.div<Props>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  margin: 1px;
  flex: 1;
  box-sizing: border-box;
  cursor: pointer;
`

export default DateCard
