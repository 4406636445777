export type IconType = 'regular' | 'solid' | 'light' | 'brand' | 'dic'

const iconTypeMapping = {
  solid: 'fas',
  light: 'fal',
  brand: 'fab',
  dic: 'dic',
  regular: 'far',
}

export const getIconTypeCode = (iconType?: IconType) => {
  if (!iconType) {
    return 'far'
  }

  const iconTypeCode = iconTypeMapping[iconType]
  if (!iconTypeCode) {
    return 'far'
  }
  return iconTypeCode
}
