import * as GQL from 'generated/graphql'

const formatInventoryAdjustmentReason = (reason: GQL.InventoryAdjustmentReason | undefined | null) => {
  if (!reason) return '-'
  return reason.replace('_', ' ').capitalizeFirst()
}

const formatDiscrepancyAction = (action: GQL.DiscrepancyAction | undefined | null) => {
  if (!action) return '-'
  const _action = action.replace('_', '-')
  return _action.replaceAll('_', ' ').capitalizeFirst()
}

export { formatInventoryAdjustmentReason, formatDiscrepancyAction }
