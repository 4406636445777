import * as GQL from 'generated/graphql'

const NOTE_SEVERITY_LOW: number = 1
const NOTE_SEVERITY_NORMAL: number = 2
const NOTE_SEVERITY_HIGH: number = 3
const NOTE_SEVERITY_CRITICAL: number = 4

export const NOTE_SEVERITY_COLORS = {
  [NOTE_SEVERITY_LOW.toString()]: '#00d4a6',
  [NOTE_SEVERITY_NORMAL.toString()]: '#fff',
  [NOTE_SEVERITY_HIGH.toString()]: '#fdab3d',
  [NOTE_SEVERITY_CRITICAL.toString()]: '#ed0033',
}

export const ORDER_METHOD_COLORS = {
  [GQL.CustomerOrderMethod.App]: 'blue',
  [GQL.CustomerOrderMethod.Auto]: 'green',
  [GQL.CustomerOrderMethod.Phone]: '#383C4E',
}

export const ORDER_METHOD_TRANSLATIONS = {
  [GQL.CustomerOrderMethod.App]: 'customers.order-type-app',
  [GQL.CustomerOrderMethod.Auto]: 'customers.order-type-automatic',
  [GQL.CustomerOrderMethod.Phone]: 'customers.order-type-manual',
}

export const GAS_TYPE_TRANSLATIONS = {
  [GQL.CylinderGroupGasType.Co2]: 'common.co2',
  [GQL.CylinderGroupGasType.Propane]: 'common.propane',
}

export const CUSTOMER_ID_SORT_KEY = 'customerId'
export const FIRST_POSSIBLE_DELIVERY_SORT_KEY = 'firstPossibleDelivery'
export const PRICE_SORT_KEY = 'price'
export const PHONE_NUMBER_SORT_KEY = 'phoneNumber'
export const ORDERED_BY_SORT_KEY = 'orderedBy'
export const PRODUCT_SORT_KEY = 'product'
export const ADDRESS_SORT_KEY = 'address'
export const CYLINDER_SETUP_SORT_KEY = 'cylinderSetup'
export const ESTIMATED_EMPTY_SORT_KEY = 'estimatedEmpty'
export const STOCK_SORT_KEY = 'stock'
export const ORDER_METHOD_SORT_KEY = 'orderMethod'
export const TAGS_SORT_KEY = 'tagsCount'

export const DAYS_NOT_REPORTING_THRESHOLD = 3

export const CYLINDER_COLORS = {
  gray: 'gray',
  red: 'red',
  blue: 'blue',
  orange: 'orange',
  green: 'green',
  freezeout: 'freezeout',
}

export const WEEKDAYS = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
}

export interface SIDE_REMAINING_UNIT {
  value: string
  unit: string
  property: string
  factor: number
}

export const SIDE_UNIT_PROPS = {
  kilograms: {
    value: GQL.DistributorRefillThresholdUnit.Kilogram,
    unit: 'kg',
    property: 'gas weight',
    factor: 1,
  },
  liters: {
    value: GQL.DistributorRefillThresholdUnit.Liter,
    unit: 'L',
    property: 'liters',
    factor: 1.818,
  },
  percent: {
    value: GQL.DistributorRefillThresholdUnit.Percent,
    unit: '%',
    property: 'percentage',
    factor: 1,
  },
  days: {
    value: GQL.DistributorRefillThresholdUnit.Days,
    unit: 'd',
    property: 'days left',
    factor: 1,
  },
}
