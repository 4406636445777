import { DocumentNode } from '@apollo/client'
import { Edge, Options, QueryFilter } from './interfaces'
import { ApolloCacheServiceUtilities } from './utils'

/**
 * ApolloCacheService is a utility class for interacting with Apollo Client's cache.
 * It provides methods to read and manipulaate cache data in a controlled manner.
 * @param client - The Apollo Client instance to interact with its cache.
 */
class ApolloCacheService extends ApolloCacheServiceUtilities {
  /**
   * Modifies queries cache related to a specific edge by adding, updating (if exists) or removing (if `applyFilters` provided) query edges based on filters.
   * @param edge - The edge to be added or updated in the cache.
   * @param queryVariables - An array of query filters to determine which queries to modify.
   * @param options - Optional parameters for additional configurations.
   */
  public modifyEdgeQueriesCache(edge: Edge, queryVariables: QueryFilter[], options?: Options) {
    this.processQueries(edge, queryVariables, this.addOrUpdateEdge, options)
  }

  /**
   * Removes a specified edge from all relevant queries in the cache.
   * @param removedEdge - The edge to be removed from the cache.
   * @param queryVariables - An array of query filters to determine which queries to modify.
   * @param options - Optional parameters for additional configurations.
   */
  public removeEdgeFromQueries(removedEdge: Edge, queryVariables: QueryFilter[], options?: Options) {
    this.processQueries(removedEdge, queryVariables, this.removeEdge, options)
  }

  /**
   * Updates a fragment in the Apollo cache. If the fragment already exists, it's overwritten with the new data.
   * @param node - The data node associated with the fragment.
   * @param fragment - The GraphQL fragment document.
   * @param fragmentName - The name of the fragment.
   */
  public updateFragment(node: any, fragment: DocumentNode, fragmentName: string) {
    const existingFragment = this.client.cache.readFragment({
      id: this.client.cache.identify({ id: node?.id, __typename: node?.__typename }),
      fragment: fragment,
      fragmentName: fragmentName,
    })

    if (existingFragment) {
      this.client.cache.writeFragment({
        id: this.client.cache.identify({ id: node?.id, __typename: node?.__typename }),
        fragment: fragment,
        fragmentName: fragmentName,
        data: node,
      })
    }
  }
}

export default ApolloCacheService
