import React, { useState } from 'react'
import styled from 'styled-components'

import Collapse from './Collapse'

const Bar = styled.div`
  height: 50px;
  background: ${props => props.theme.colors.primaryDark};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  box-sizing: border-box;
  color: ${props => props.theme.colors.gray8};
  font-size: 14px;
  display: grid;
  grid-template-columns: 25px 1fr;

  cursor: pointer;
  user-select: none;

  text-transform: uppercase;
`

interface IIconWrapperProps {
  open?: boolean
}

const IconWrapper = styled.div<IIconWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  transition: transform 0.2s ease-in-out;
  transform: ${props => (!props.open ? 'rotate(-90deg)' : '')};
  font-size: 14px;
  padding-top: 4px;
`

interface ICollapseWithBarProps {
  label?: string | JSX.Element | Element
  children: React.ReactNode
  initialOpen: boolean
  open?: boolean
  onClick?: () => void
}

function CollapseWithBar(props: ICollapseWithBarProps) {
  const [open, setOpen] = useState(props.initialOpen)

  return (
    <Collapse closedHeight={50} open={props.open !== undefined ? props.open : open}>
      <Bar onClick={() => (props.onClick ? props.onClick() : setOpen(!open))}>
        <>
          <IconWrapper open={props.open !== undefined ? props.open : open}>
            <i className='fa fa-caret-down'></i>
          </IconWrapper>
          {props.label}
        </>
      </Bar>
      {props.children}
    </Collapse>
  )
}

CollapseWithBar.defaultProps = {
  initialOpen: true,
}

export default CollapseWithBar
