import { ApolloClient } from '@apollo/client'

import { CustomerDrawerTab } from './CustomerContext'
import * as GQL from 'generated/graphql'
import CacheConfigs from 'util/cacheConfig'

export const handlePreFetchCustomerInfo = (
  contextCustomers: GQL.CustomerNode[],
  currentCustomerID: string,
  tab: CustomerDrawerTab,
  apolloClient: ApolloClient<any>
) => {
  const nextCustomer = getNextCustomerContext(contextCustomers, currentCustomerID)
  const previousCustomer = getPreviousCustomerContext(contextCustomers, currentCustomerID)

  // first we fetch next customer
  if (nextCustomer) {
    // this query has to be fetched regardless of the current tab
    apolloClient.query({
      ...CacheConfigs.CACHE_FIRST_ONCE,
      query: GQL.AllCylinderGroupsDocument,
      variables: {
        customer: nextCustomer.id,
      },
    })

    if (tab === CustomerDrawerTab.CONTACTS) {
      // pre-fetching next customer contacts
      apolloClient.query({
        ...CacheConfigs.CACHE_FIRST_ONCE,
        query: GQL.AllCustomerContactsDocument,
        variables: {
          customer: nextCustomer.id,
        },
      })
    } else if (tab === CustomerDrawerTab.NOTES && nextCustomer?.cylinderGroups) {
      // pre-fetching next customer notes
      apolloClient.query({
        ...CacheConfigs.CACHE_FIRST_ONCE,
        query: GQL.AllCylinderGroupNotesDocument,
        variables: {
          cylinderGroup: nextCustomer.cylinderGroups[0]?.id,
        },
      })
    }
  }

  // we already fetched next customer, no need to fetch it again
  if (nextCustomer?.id === previousCustomer?.id) {
    return
  }

  if (previousCustomer) {
    // this query has to be fetched regardless of the current tab
    apolloClient.query({
      ...CacheConfigs.CACHE_FIRST_ONCE,
      query: GQL.AllCylinderGroupsDocument,
      variables: {
        customer: previousCustomer.id,
      },
    })

    // pre-fetching previous customer contacts
    if (tab === CustomerDrawerTab.CONTACTS) {
      apolloClient.query({
        ...CacheConfigs.CACHE_FIRST_ONCE,
        query: GQL.AllCustomerContactsDocument,
        variables: {
          customer: previousCustomer.id,
        },
      })
    } else if (tab === CustomerDrawerTab.NOTES && previousCustomer?.cylinderGroups) {
      // pre-fetching previous customer notes
      apolloClient.query({
        ...CacheConfigs.CACHE_FIRST_ONCE,
        query: GQL.AllCylinderGroupNotesDocument,
        variables: {
          cylinderGroup: previousCustomer.cylinderGroups[0]?.id,
        },
      })
    }
  }
}

export const getNextCustomerContext = (customers: GQL.CustomerNode[], currentCustomerID: string) => {
  const index = customers?.findIndex(customer => customer.id === currentCustomerID)
  if (index === undefined || index < 0 || !customers) return
  if (index >= customers.length - 1) return customers[0]
  return customers[index + 1]
}

export const getPreviousCustomerContext = (customers: GQL.CustomerNode[], currentCustomerID: string) => {
  const index = customers?.findIndex(customer => customer.id === currentCustomerID)
  if (index === undefined || index < 0 || !customers) return
  if (index < 1) return customers[customers.length - 1]
  return customers[index - 1]
}
