import React, { useEffect } from 'react'
import { GoogleMap, GoogleMapProps, Marker } from '@react-google-maps/api'
import mapTheme from 'components/GoogleMap/theme.json'

export interface IMarker {
  longitude: number
  latitude: number
  icon?: string
}

export interface IPolyline {
  path: google.maps.LatLngLiteral[] | google.maps.LatLng[] | undefined
  options?: google.maps.PolylineOptions
}

interface IMapProps extends GoogleMapProps {
  theme?: any
  markers?: IMarker[]
  containerStyle?: IDynamicStyleProps
  mapStyle?: IDynamicStyleProps
  autoFit?: boolean
  polylines?: IPolyline[]
  children?: React.ReactNode
  onChangeCenter?: any
}

const Map: React.FC<IMapProps> = ({ mapStyle, containerStyle, theme, options, markers, autoFit, polylines, children, ...props }) => {
  const [savedMap, saveMap] = React.useState<any>(null)

  useEffect(() => {
    if (savedMap && markers && markers.length > 0) {
      const bounds = new google.maps.LatLngBounds()
      if (markers.length > 1) {
        markers?.forEach(marker =>
          bounds.extend({
            lat: parseFloat(marker.latitude.toString()),
            lng: parseFloat(marker.longitude.toString()),
          })
        )
        savedMap.fitBounds(bounds)
        savedMap.panToBounds(bounds)
      }
      if (markers.length === 1) {
        const marker = markers.find(() => true)
        savedMap.setCenter({ lat: marker?.latitude, lng: marker?.longitude })
        savedMap.setZoom(14)
      }
    }
  }, [markers, savedMap])

  return (
    <div style={mapStyle}>
      <GoogleMap
        onLoad={map => {
          saveMap(map)
          map.setCenter({ lat: 30.595901893270668, lng: -39.87550592883013 })
          map.setZoom(2)
        }}
        zoom={2}
        mapContainerStyle={containerStyle}
        options={{
          fullscreenControl: true,
          backgroundColor: 'rgb(20, 25, 38)',
          mapTypeControl: false,
          zoomControl: true,
          streetViewControl: false,
          styles: theme,
          ...options,
        }}
        {...props}
      >
        {markers?.map((marker, i) => (
          <Marker
            key={i}
            position={{
              lat: marker.latitude,
              lng: marker.longitude,
            }}
            icon={marker.icon ? { url: marker.icon, scaledSize: new google.maps.Size(30, 50) } : undefined}
          />
        ))}
        {children}
      </GoogleMap>
    </div>
  )
}

interface IDynamicStyleProps {
  [key: string]: any
}

Map.defaultProps = {
  containerStyle: { height: '100%', width: '100%' },
  mapStyle: { height: '100%' },
  theme: mapTheme,
}

export default Map
