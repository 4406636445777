import React, { useState } from 'react'
import styled from 'styled-components'
import { Time } from 'lib/time'
import { padStart } from 'lodash'
import TimeSelectDialog from './TimeSelectDialog'

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
`

const Input = styled.div`
  background-color: #343b53;
  border-radius: 4px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: white;
`
const Separator = styled.div`
  height: 1px;
  width: 70%;
  background-color: ${props => props.theme.colors.gray1};
  align-self: center;
  justify-self: center;
`

interface TimeSelectProps {
  startValue: Time
  endValue: Time
  onChange: (startValue: Time, endValue: Time) => void
  open?: boolean
}

interface ISelectIconStyle {
  open?: boolean
}

const SelectIcon = styled.span<ISelectIconStyle>`
  position: relative;
  top: -2px;
  left: 5px;

  transform: ${props => (props.open ? 'rotate(90deg)' : 'rotate(0deg)')};
  transform-origin: 50% calc(50% + 2px) 0;
  transition:
    transform 0.1s ease-in-out,
    top 0.1s ease-in-out;
`

const TimeRangeSelect: React.FC<TimeSelectProps> = ({ startValue, endValue, onChange, open = false }) => {
  const [dialogOpen, setDialogOpen] = useState(false)

  const startHours = startValue.hours().toString()
  const startMinutes = startValue.minutes().toString()

  const endHours = endValue.hours().toString()
  const endMinutes = endValue.minutes().toString()

  return (
    <Layout>
      {!open && (
        <>
          <Input onClick={() => setDialogOpen(!dialogOpen)}>
            {padStart(startHours, 2, '0')}:{padStart(startMinutes, 2, '0')}
            <SelectIcon open={dialogOpen}>
              <i className='fa fa-sort-down'></i>
            </SelectIcon>
          </Input>
          <Separator />
          <Input onClick={() => setDialogOpen(!dialogOpen)}>
            {padStart(endHours, 2, '0')}:{padStart(endMinutes, 2, '0')}
            <SelectIcon open={dialogOpen}>
              <i className='fa fa-sort-down'></i>
            </SelectIcon>
          </Input>
        </>
      )}
      {(dialogOpen || open) && (
        <TimeSelectDialog
          startValue={startValue}
          endValue={endValue}
          onChange={(startValue, endValue) => {
            onChange(startValue, endValue)
            setDialogOpen(false)
          }}
        />
      )}
    </Layout>
  )
}

export default TimeRangeSelect
