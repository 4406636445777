// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'

import * as GQL from 'generated/graphql'
import { PlasmicCustomerAssetsTable, DefaultCustomerAssetsTableProps } from './plasmic/solace_components/PlasmicCustomerAssetsTable'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import Modal from 'components/Modal/Modal'
import { omit } from 'lodash'
import CustomerAssetsRow from './CustomerAssetsRow'
import { CenteredLoader } from 'components/Loader/LoaderWrappers'
import Loader from 'components/Loader'
import { displayToast } from 'util/toasts'
import ScrollIndicator from './ScrollIndicator'
import { NoHits } from 'modules/orders/util'
import LabelStatusChip from './LabelStatusChip'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface CustomerAssetsTableProps extends Omit<DefaultCustomerAssetsTableProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultCustomerAssetsTableProps altogether and have
// total control over the props for your component.
export interface CustomerAssetsTableProps extends DefaultCustomerAssetsTableProps {
  isOpen: boolean
  onClose: () => void
}

interface InputLocation {
  cylinderGroupId: string
  product: GQL.ProductNode | undefined
}

interface InputStock {
  state: GQL.InventoryStockState
  amount: number
}

export interface InputInventoryEntry {
  location: InputLocation
  stock: InputStock
}

type InputInventory = { [key: string]: { isChecked: boolean; input: InputInventoryEntry } }

function CustomerAssetsTable_(props: CustomerAssetsTableProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicCustomerAssetsTable to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicCustomerAssetsTable are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all CustomerAssetsTableProps here, but feel free
  // to do whatever works for you.
  const PAGE_SIZE = 16

  const [inputs, setInputs] = React.useState<InputInventory>({})
  const [allChecked, setAllChecked] = React.useState(false)
  const [loadingFetchMore, setLoadingFetchMore] = React.useState(false)

  const {
    data: dataSuggestedCustomers,
    loading: suggestedCustomersLoading,
    fetchMore: fetchMoreCustomers,
  } = GQL.useAllCustomerInventorySuggestions({ variables: { first: PAGE_SIZE } })
  const customers = React.useMemo(
    () => dataSuggestedCustomers?.allCustomerInventorySuggestions?.edges.map(edge => edge?.node as GQL.CylinderGroupNode) || [],
    [dataSuggestedCustomers]
  )

  const { data: dataProducts, loading: productsLoading } = GQL.useAllProducts({ variables: { service: false } })
  const products = React.useMemo(() => dataProducts?.availableProducts?.edges.map(edge => edge?.node as GQL.ProductNode) || [], [dataProducts])

  const pageInfo = React.useMemo(
    () => dataSuggestedCustomers?.allCustomerInventorySuggestions?.pageInfo || { hasNextPage: false, endCursor: '' },
    [dataSuggestedCustomers]
  )

  const [bulkAddInventory, { loading: bulkAddInventoryLoading }] = GQL.useBulkAddInventory({
    refetchQueries: ['AllInventories', 'AllCustomerInventorySuggestions', 'AllProductInventories'],
    onCompleted: data => {
      if (!data.bulkAddInventory?.ok) {
        return displayToast('Failed to update stock', 'error')
      }
      displayToast('Stock updated successfully', 'success')
    },
    onError: error => {
      displayToast('Failed to update stock', 'error')
    },
  })

  const handleEntryUpdate = (key: string, isChecked: boolean, input: InputInventoryEntry) => {
    setInputs(prev => ({ ...prev, [key]: { isChecked: isChecked, input: input } }))
  }

  const handleBulkSubmit = () => {
    if (bulkAddInventoryLoading || Object.values(inputs).filter(item => item.isChecked).length === 0) return
    bulkAddInventory({
      variables: {
        input: Object.values(inputs)
          .filter(item => item.isChecked && !!item.input.location.product)
          .map(item => ({
            location: { cylinderGroupId: item.input.location.cylinderGroupId, productId: item.input.location.product!.id },
            stock: item.input.stock,
          })),
      },
    })
  }

  const handleAllChecked = (allChecked: boolean) => {
    const _inputs: InputInventory = {}
    for (let [key, input] of Object.entries(inputs)) {
      input.isChecked = allChecked
      _inputs[key] = input
    }
    setAllChecked(allChecked)
    setInputs(_inputs)
  }

  const getSuggestedProduct = (cylinderGroup: GQL.CylinderGroupNode) => {
    const orderProduct = cylinderGroup?.orders?.at(-0)?.products?.at(0)?.product
    if (!cylinderGroup.product && !orderProduct) return undefined
    if (!cylinderGroup.product) return orderProduct
    return cylinderGroup.product
  }

  const getSuggestedAmount = (cylinderGroup: GQL.CylinderGroupNode) => {
    const order = cylinderGroup?.orders?.at(-0)
    const hasValidSides = cylinderGroup.cylinderSides?.some(side => side?.cylinderCount !== undefined)
    if (!hasValidSides && !order) return undefined
    if (!hasValidSides) return order?.numberOfCylinders
    return cylinderGroup.cylinderSides?.map(side => side?.cylinderCount || 0)?.reduce((a, b) => a + b, 0)
  }

  const onLoadMoreClick = React.useCallback(() => {
    if (customers.length >= (dataSuggestedCustomers?.allCustomerInventorySuggestions?.totalCount || 0) || loadingFetchMore) return
    setLoadingFetchMore(true)
    fetchMoreCustomers({
      variables: {
        after: pageInfo.endCursor,
        first: PAGE_SIZE,
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) return prev
        return {
          ...fetchMoreResult,
          allCustomerInventorySuggestions: {
            ...fetchMoreResult.allCustomerInventorySuggestions,
            edges: [...(prev.allCustomerInventorySuggestions?.edges || []), ...fetchMoreResult?.allCustomerInventorySuggestions?.edges!],
          },
        } as GQL.AllCustomerInventorySuggestions
      },
    }).finally(() => setLoadingFetchMore(false))
  }, [fetchMoreCustomers, customers.length, dataSuggestedCustomers?.allCustomerInventorySuggestions?.totalCount, pageInfo.endCursor, loadingFetchMore])

  React.useEffect(() => {
    const _inputs: InputInventory = {}
    for (let cylinderGroup of customers) {
      _inputs[cylinderGroup.id] = {
        isChecked: false,
        input: {
          location: { cylinderGroupId: cylinderGroup.id, product: getSuggestedProduct(cylinderGroup) },
          stock: { amount: getSuggestedAmount(cylinderGroup) || 0, state: GQL.InventoryStockState.NotEmpty },
        } as InputInventoryEntry,
      }
    }
    setInputs(_inputs)
  }, [customers])

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.onClose} stripped>
      <PlasmicCustomerAssetsTable
        rows={
          (suggestedCustomersLoading || productsLoading) && customers.length === 0 ? (
            <CenteredLoader>
              <Loader color='white' size={32} />
            </CenteredLoader>
          ) : (
            <>
              {!customers || customers.length === 0 ? (
                <NoHits>
                  <LabelStatusChip icon='check' title='All customers have assets configured' />
                </NoHits>
              ) : (
                customers?.map(cylinderGroup => (
                  <CustomerAssetsRow
                    key={cylinderGroup.id}
                    keyValue={cylinderGroup.id}
                    isChecked={inputs[cylinderGroup.id]?.isChecked}
                    entry={inputs[cylinderGroup.id]?.input}
                    handleEntryUpdate={handleEntryUpdate}
                    products={products}
                    suggestion={{
                      product: getSuggestedProduct(cylinderGroup),
                      quantity: getSuggestedAmount(cylinderGroup),
                    }}
                    customerName={cylinderGroup.customer.name}
                    customerId={cylinderGroup.customer.customerIdentifier}
                    cylinderGroupId={cylinderGroup.id}
                  />
                ))
              )}
              {!!customers && customers.length >= 1 && (
                <ScrollIndicator
                  loaded={customers.length.toString()}
                  total={dataSuggestedCustomers?.allCustomerInventorySuggestions?.totalCount?.toString()}
                  btnLoadMore={{ ...(loadingFetchMore && { children: <Loader color='white' /> }), onClick: onLoadMoreClick }}
                />
              )}
            </>
          )
        }
        btnSaveChecked={{
          props: {
            onClick: handleBulkSubmit,
            label: bulkAddInventoryLoading ? <Loader size={32} color='white' /> : 'Save checked',
          },
        }}
        checkboxHeader={{
          props: {
            defaultChecked: allChecked,
            onChange: event => handleAllChecked(event),
          },
        }}
        btnUncheckAll={{
          props: {
            onClick: () => handleAllChecked(false),
          },
        }}
        btnClose={{
          onClick: props.onClose,
        }}
        root={{ ref }}
        {...omit(props, 'isOpen', 'onClose')}
      />
    </Modal>
  )
}

const CustomerAssetsTable = React.forwardRef(CustomerAssetsTable_)
export default CustomerAssetsTable
