import * as React from 'react'
import { format } from 'date-fns'
import { useIntl } from 'react-intl'
import { useApolloClient } from '@apollo/client'

import * as GQL from 'generated/graphql'
import { PlasmicCylinder } from './plasmic/solace_components/PlasmicCylinder'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import { getUrl } from 'util/env'
import { displayToast } from 'util/toasts'
import { getSensorStatus, updateSensorCacheOnPatch } from 'modules/customers/util'
import './Cylinder.css'

interface Side {
  monitored: boolean
  depleting: boolean
  sensors: GQL.SensorNode[]
  activeCycleRemainingPercentage: number
  cylinderColor?: CylinderColor
}

interface Props {
  keyValue: number
  side: Side
  cylinderGroup?: GQL.CylinderGroupNode | null
  activeCycle?: GQL.CycleNode | null
}

export enum CylinderColor {
  GRAY = 'gray',
  RED = 'red',
  BLUE = 'blue',
  ORANGE = 'orange',
  GREEN = 'green',
  PURPLE = 'purple',
}

const COLORS = [
  { threshold: 10, color: CylinderColor.RED },
  { threshold: 20, color: CylinderColor.ORANGE },
  { threshold: 100, color: CylinderColor.BLUE },
]

function Cylinder_(
  {
    cylinderGroup = null,
    keyValue = 0,
    side = {
      activeCycleRemainingPercentage: 100,
      cylinderColor: undefined,
      monitored: false,
      depleting: false,
      sensors: [],
    },
    activeCycle = null,
    ...props
  }: Props,
  ref: HTMLElementRefOf<'div'>
) {
  const intl = useIntl()
  const t = intl.formatMessage

  const client = useApolloClient()

  const [patchSensor, { loading: patchSensorLoading }] = GQL.usePatchSensor({
    onCompleted: response => {
      if (response.patchSensor?.ok) {
        displayToast(t({ id: 'customers.cylinders.sensor.state.success.generic' }), 'success')
      } else {
        displayToast(t({ id: 'customers.cylinders.sensor.state.error.generic' }))
      }
    },
    onError: () => {
      displayToast(t({ id: 'customers.cylinders.sensor.state.error.generic' }))
    },
    update: () => {
      if (cylinderGroup && sensor) {
        updateSensorCacheOnPatch(sensor, client, cylinderGroup)
      }
    },
  })

  const color: CylinderColor | undefined = side.cylinderColor
    ? side.cylinderColor
    : side.monitored
      ? COLORS.find(({ threshold }) => side.activeCycleRemainingPercentage < threshold)?.color
      : CylinderColor.GRAY

  const sensor = React.useMemo(() => {
    return side.monitored && side.sensors ? (side.sensors[keyValue] as GQL.SensorNode) : null
  }, [side])

  const sensorModel = React.useMemo(() => {
    if (sensor?.model) {
      return sensor.model.name
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase()
        .slice(0, 4) as 'a910' | 'a901' | 'a900'
    }
    return undefined
  }, [sensor])

  const handleChangeSensorState = () => {
    if (sensor) {
      const toState = sensor.status === GQL.SensorStatus.Off ? GQL.SensorStatus.On : GQL.SensorStatus.Off
      patchSensor({
        variables: {
          id: sensor.id,
          input: {
            status: toState,
          },
        },
      })
    } else {
      displayToast(t({ id: 'customers.cylinders.sensor.state.error.no-sensor' }))
    }
  }

  return (
    <PlasmicCylinder
      root={{ ref }}
      {...props}
      sensorInstalled={side.monitored ? keyValue === 0 : false}
      fill={{
        props: {
          style: { height: `${side.activeCycleRemainingPercentage}%` },
        },
      }}
      color={color}
      depleting={side.depleting}
      sensor={{
        sensorCard: {
          sensorId: sensor ? sensor.serialNumber : null,
          sensorActivatedDate: sensor && !!sensor?.installedAt ? format(new Date(sensor.installedAt), 'd. LLL yyyy') : 'No installation date',
          model: sensorModel ? sensorModel : '',
          sensorModel: sensor?.model?.name,
          sensorStatus: sensor ? getSensorStatus(sensor, activeCycle) : 'Disconnected',
          btnDeactivateSensor: {
            onClick: () => {
              sensor && sensor.serialNumber
                ? window.open(
                    `https://register.${getUrl()}/sensors?id=${sensor.serialNumber}`,
                    'MyNewWindow',
                    'menubar=0,status=0,toolbar=yes,scrollbars=yes,resizable=yes,width=500,height=800'
                  )
                : null
            },
          },
          btnMoveSensor: {
            onClick: () => {
              sensor && sensor.serialNumber
                ? window.open(`https://register.${getUrl()}/sensors?id=${sensor.serialNumber}`, 'MyNewWindow', 'menubar=0,status=0,width=500,height=800')
                : null
            },
          },
          btnTurnOffSensor: {
            loading: patchSensorLoading,
            disabled: patchSensorLoading,
            title: sensor?.status === GQL.SensorStatus.Off ? t({ id: 'common.set-turned-on' }) : t({ id: 'common.set-turned-off' }),
            onClick: () => handleChangeSensorState(),
          },
        },
      }}
    />
  )
}
const Cylinder = React.forwardRef(Cylinder_)
export default Cylinder
