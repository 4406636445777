import { useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

import * as GQL from 'generated/graphql'
import { displayToast } from 'util/toasts'
import ToggleContactLine from 'plasmic/ToggleContactLine'
import ContactCard from 'plasmic/ContactCard'
import ContentContactsPriv from 'plasmic/ContentContactsPriv'
import Loader from 'components/Loader/Loader'
import { CenteredLoader } from 'components/Loader/LoaderWrappers'
import ConfirmModal from 'components/Modal/ConfirmModal'
import { TutorialInfoIcon } from '../../../components/Tooltip/TutorialTooltip'
import CustomerContactModal from '../components/CustomerContactModal'

import 'react-intl-tel-input/dist/main.css'
import ActionDropdownLine from 'plasmic/ActionDropdownLine'
import { paymentEnabled } from 'modules/distributors/utils'
import { useAppContext, useClickOutside } from 'util/hooks'

const SelectContainer = styled.div`
  margin-left: 6px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: 10px;
  padding: 6px;
  box-sizing: border-box;
  background: #181d36;
  gap: 6px;
`
interface ISelectProps {
  selected: boolean
}

const Select = styled.div<ISelectProps>`
  flex-grow: 1;
  font-size: 16px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  ${props =>
    props.selected &&
    `
    background-color: #00d4a6;
    color: #fff;
  `}
  :hover {
    background-color: ${props => (props.selected ? 'rgba(0,212,166, 0.8)' : 'rgb(255 255 255 / 10%)')};
  }
`

interface INotfProps {
  contact?: GQL.CustomerContactNode
  resource: GQL.NotificationResourceNode
  showWithParams?: boolean
}

const Notf = ({ contact, resource }: INotfProps) => {
  const [createNotification, createNotificationResult] = GQL.useCreateCustomerContactNotification({
    refetchQueries: ['AllCustomerContacts'],
    onError: err => displayToast(err.message, 'error'),
  })
  const [deleteNotification, deleteNotificationResult] = GQL.useDeleteCustomerContactNotification({
    refetchQueries: ['AllCustomerContacts'],
    onError: err => displayToast(err.message, 'error'),
  })

  const isLoading = createNotificationResult.loading || deleteNotificationResult.loading

  const toggleNotification = (
    notification: GQL.CustomerContactNotificationNode | undefined | null,
    data: any | null = null,
    service: GQL.CustomerContactNotificationService
  ) => {
    if (!!!notification) {
      createNotification({
        variables: {
          input: {
            contact: contact?.id!,
            resource: resource.id,
            service: service,
            data: data,
          },
        },
      })
    } else {
      deleteNotification({
        variables: {
          id: notification?.id!,
        },
      })
    }
  }

  if (resource.params?.length !== 0)
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', position: 'relative', gap: '1em' }}>
        {resource.params?.map(p => {
          return (
            <>
              <div style={{ display: 'flex', flex: '1 1', alignItems: 'center', justifyContent: 'flex-start', alignSelf: 'stretch', width: '100%' }}>
                <div style={{ minWidth: '40px', textAlign: 'left', fontSize: '12px' }}>SMS</div>
                <SelectContainer>
                  {_.range(p?.dataConfig?.min, p?.dataConfig?.max, p?.dataConfig?.step).map(c => {
                    const existing = contact?.notifications?.find(n => {
                      return (
                        n?.service === GQL.CustomerContactNotificationService.Sms &&
                        n?.resource.id === resource.id &&
                        n?.data &&
                        p?.name! in n?.data &&
                        n?.data[p?.name!] === c
                      )
                    })
                    return (
                      <Select
                        key={c}
                        selected={!!existing}
                        onClick={() => {
                          if (isLoading) return
                          toggleNotification(existing, { [p?.name!]: c }, GQL.CustomerContactNotificationService.Sms)
                        }}
                      >
                        {c} {p?.dataConfig?.postfix}
                      </Select>
                    )
                  })}
                </SelectContainer>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ minWidth: '40px', textAlign: 'left', fontSize: '12px' }}>EMAIL</div>
                <SelectContainer>
                  {_.range(p?.dataConfig?.min, p?.dataConfig?.max, p?.dataConfig?.step).map(c => {
                    const existing = contact?.notifications?.find(n => {
                      return (
                        n?.service === GQL.CustomerContactNotificationService.Email &&
                        n?.resource.id === resource.id &&
                        n?.data &&
                        p?.name! in n?.data &&
                        n?.data[p?.name!] === c
                      )
                    })
                    return (
                      <Select
                        key={c}
                        selected={!!existing}
                        onClick={() => {
                          if (isLoading) return
                          toggleNotification(existing, { [p?.name!]: c }, GQL.CustomerContactNotificationService.Email)
                        }}
                      >
                        {c} {p?.dataConfig?.postfix}
                      </Select>
                    )
                  })}
                </SelectContainer>
              </div>
            </>
          )
        })}
      </div>
    )
  return (
    <ToggleContactLine
      title={resource.title || ''}
      subtitle={resource.description || ''}
      toggleRefilledEmail={{
        isChecked:
          (contact?.notifications?.find(n => n?.resource.id === resource.id && n?.service === GQL.CustomerContactNotificationService.Email) ?? false) !== false,
        onChange: () =>
          toggleNotification(
            contact?.notifications?.find(n => n?.resource.id === resource.id && n?.service === GQL.CustomerContactNotificationService.Email),
            null,
            GQL.CustomerContactNotificationService.Email
          ),
      }}
      toggleRefilledSms={{
        isChecked:
          (contact?.notifications?.find(n => n?.resource.id === resource.id && n?.service === GQL.CustomerContactNotificationService.Sms) ?? false) !== false,
        onChange: () =>
          toggleNotification(
            contact?.notifications?.find(n => n?.resource.id === resource.id && n?.service === GQL.CustomerContactNotificationService.Sms),
            null,
            GQL.CustomerContactNotificationService.Sms
          ),
      }}
    />
  )
}

interface IContactProps {
  contact: GQL.CustomerContactNode
  onCustomerContactEdit: (customer: GQL.CustomerContactNode) => void
}

const Contact = ({ contact, onCustomerContactEdit }: IContactProps) => {
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState<boolean>(false)
  const [moreActions, setMoreActions] = useState(false)
  const { data: resourceData } = GQL.useAllCustomerNotificationResources()
  const resources = resourceData?.allCustomerNotificationResources || []

  const intl = useIntl()
  const t = intl.formatMessage
  const ref = useRef(null)

  const { appContext } = useAppContext()

  useClickOutside(
    ref,
    () => {
      setMoreActions(false)
    },
    false,
    true
  )

  const [patchContact] = GQL.usePatchCustomerContact({
    refetchQueries: ['AllCustomerContacts'],
    onCompleted: () => {
      displayToast('Successfully updated contact.', 'success')
    },
  })

  const deleteContact = () => {
    if (contact?.id) {
      patchContact({
        variables: {
          id: contact?.id,
          input: {
            isDeleted: true,
            phone: null,
            email: null,
          },
        },
      })
    }
  }

  return (
    <>
      <ConfirmModal
        isOpen={confirmDeleteModalOpen}
        onRequestClose={() => setConfirmDeleteModalOpen(false)}
        onConfirm={() => {
          setConfirmDeleteModalOpen(false)
          deleteContact()
        }}
        onAbort={() => {
          setConfirmDeleteModalOpen(false)
        }}
        title={t({ id: 'customers.contact.delete' })}
        description={`Are you sure you want to delete ${contact.name} ${contact.position ? contact.position : ''} as a contact of this customer?`}
      />
      <ContactCard
        data-testid='customer-contact'
        invoiceReceiver={contact.invoiceReceiver}
        name={contact.name}
        phoneNumber={contact.phone}
        email={contact.email}
        position={contact.position}
        noSensor={!contact.customer?.cylinderGroups?.some(cg => cg?.sensors && cg.sensors.length > 0)}
        toggles={
          <>
            {contact &&
              resources.filter(r => r?.params?.length === 0).map(r => <Notf key={r?.id} contact={contact} resource={r as GQL.NotificationResourceNode} />)}
          </>
        }
        percentageTrigger={
          <>
            {contact &&
              resources.filter(r => r?.params?.length !== 0).map(r => <Notf key={r?.id} contact={contact} resource={r as GQL.NotificationResourceNode} />)}
          </>
        }
        moreActions={{
          open: moreActions,
          ref: moreActions ? ref : null,
          onClick: () => setMoreActions(prev => !prev),
          actionDropdown: (
            <>
              <ActionDropdownLine
                children={t({ id: 'common.edit' })}
                onClick={() => {
                  onCustomerContactEdit(contact)
                }}
              />
              <ActionDropdownLine
                children={
                  paymentEnabled(appContext) ? t({ id: 'customers.contact.set-invoice-receiver' }) : t({ id: 'customers.contact.set-receipt-receiver' })
                }
                onClick={() => {
                  patchContact({
                    variables: {
                      id: contact.id,
                      input: {
                        invoiceReceiver: true,
                      },
                    },
                  })
                }}
              />
              <ActionDropdownLine
                children={t({ id: 'common.delete' })}
                onClick={() => {
                  setConfirmDeleteModalOpen(true)
                }}
              />
            </>
          ),
        }}
      />
    </>
  )
}

interface ContactsProps {
  cylinderGroup: GQL.CylinderGroupNode
}

const Contacts = ({ cylinderGroup }: ContactsProps) => {
  const intl = useIntl()
  const t = intl.formatMessage

  const [openCreateContactModal, setOpenCreateContactModal] = useState<boolean>(false)
  const [editCustomerContact, setEditCustomerContact] = useState<GQL.CustomerContactNode | undefined>(undefined)
  const { data, loading } = GQL.useAllCustomerContacts({
    notifyOnNetworkStatusChange: true,
    variables: {
      customer: cylinderGroup.customer.id,
    },
  })

  const contacts = useMemo(() => {
    return data?.allCustomerContacts?.edges.map(e => e?.node as GQL.CustomerContactNode) || []
  }, [data])

  return (
    <>
      {openCreateContactModal && <CustomerContactModal onClose={() => setOpenCreateContactModal(false)} customer={cylinderGroup.customer.id} />}
      {!!editCustomerContact && (
        <CustomerContactModal onClose={() => setEditCustomerContact(undefined)} customer={cylinderGroup.customer.id} contact={editCustomerContact} />
      )}
      <ContentContactsPriv
        helpText={<TutorialInfoIcon content={t({ id: 'tooltips.customers.drawer.tabs.contacts' })} />}
        cardsContent={
          <>
            {contacts.length > 0 ? (
              contacts.map(c => {
                return <Contact key={c.id} contact={c} onCustomerContactEdit={customer => setEditCustomerContact(customer)} />
              })
            ) : loading ? (
              <CenteredLoader>
                <Loader size={52} color='white' />
              </CenteredLoader>
            ) : (
              <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>No contacts</div>
            )}
          </>
        }
        buttonRoundIcon={{
          onClick: (e: any) => {
            e.preventDefault()
            e.stopPropagation()
            setOpenCreateContactModal(true)
          },
        }}
      />
    </>
  )
}
export default Contacts
