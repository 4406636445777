import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import _ from 'lodash'

import * as GQL from 'generated/graphql'
import ManageEmailNotifications from 'plasmic/ManageEmailNotifications'
import { CenteredLoader } from 'components/Loader/LoaderWrappers'
import Loader from 'components/Loader'
import EmailNotificationRow from 'plasmic/EmailNotificationRow'

export default function Unsubscribe() {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  const [loadUserNotifications, { data, loading }] = GQL.useUnsubscribeNotificationsLazyQuery({
    onCompleted: response => {
      if (!response.unsubscribeNotifications?.ok) {
        navigate('/')
      }
    },
    onError: error => {
      navigate('/')
    },
  })

  const customerNotificationResources = (data?.unsubscribeNotifications?.customerNotifications as GQL.NotificationResourceNode[]) || []
  const customerContact = data?.unsubscribeNotifications?.customerContact

  const [createNotification] = GQL.useCreateCustomerContactNotificationUnsubscribe({
    update(cache, { data }) {
      cache.modify({
        id: cache.identify({ id: customerContact?.id, __typename: 'CustomerContactNode' }),
        fields: {
          notifications(existing) {
            return [...existing, data?.createCustomerContactNotificationUnsubscribe?.customerContactNotification]
          },
        },
      })
    },
  })

  const [deleteNotification] = GQL.useDeleteCustomerContactNotificationUnsubscribe({
    update(cache, { data }, { variables }) {
      cache.modify({
        id: cache.identify({ id: customerContact?.id, __typename: 'CustomerContactNode' }),
        fields: {
          notifications(existing, { readField }) {
            return existing.filter((notification: any) => variables?.id !== readField('id', notification))
          },
        },
      })
    },
  })

  const [deleteAllNotifications] = GQL.useDeleteAllCustomerContactNotificationUnsubscribe({
    update(cache) {
      cache.modify({
        id: cache.identify({ id: customerContact?.id, __typename: 'CustomerContactNode' }),
        fields: {
          notifications(existing, { DELETE }) {
            return DELETE
          },
        },
      })
    },
  })

  const toggleNotificationThreshold = (
    notification: GQL.CustomerContactNotificationNode | any,
    resource: GQL.NotificationResourceNode,
    service: GQL.CustomerContactNotificationService,
    data: any
  ) => {
    if (notification) {
      deleteNotification({
        variables: {
          id: notification?.id!,
          token: token!,
        },
      })
    } else {
      createNotification({
        variables: {
          input: {
            token: token!,
            resource: resource.id,
            service: service,
            data: data,
          },
        },
      })
    }
  }

  const generateNotificationThresholds = () => {
    return customerNotificationResources.reduce((reduced, resource) => {
      for (const param of resource.params || []) {
        const thresholds = _.range(param?.dataConfig?.min, param?.dataConfig?.max, param?.dataConfig?.step)

        return {
          ...reduced,
          ...thresholds.reduce((reduced, threshold) => {
            const existingNotification = customerContact?.notifications?.find(contactNotification => {
              return (
                contactNotification?.resource.id === resource.id &&
                contactNotification?.service === GQL.CustomerContactNotificationService.Email &&
                contactNotification?.data &&
                param?.name! in contactNotification?.data &&
                contactNotification?.data[param?.name!] === threshold
              )
            })

            return {
              ...reduced,
              [`tab${threshold}`]: {
                inactive: existingNotification ? false : true,
                onClick: () =>
                  toggleNotificationThreshold(existingNotification, resource, GQL.CustomerContactNotificationService.Email, {
                    [param?.name!]: threshold,
                  }),
              },
            }
          }, {}),
        }
      }

      return reduced
    }, {})
  }

  useEffect(() => {
    if (!token) {
      navigate('/')
    } else {
      loadUserNotifications({
        variables: {
          token: token,
        },
      })
    }
  }, [token, loadUserNotifications, navigate])

  const generatedThresholds = generateNotificationThresholds()

  return (
    <>
      {!data || loading ? (
        <CenteredLoader sizeAuto>
          <Loader color='gray6' size={50} />
        </CenteredLoader>
      ) : (
        <ManageEmailNotifications
          showThresholds={!generatedThresholds || Object.keys(generatedThresholds).length > 0}
          email={data?.unsubscribeNotifications?.customerContact?.email}
          btnUnsubscribeAll={{
            props: {
              onClick: () => {
                deleteAllNotifications({
                  variables: {
                    token: token!,
                  },
                })
              },
            },
          }}
          thresholdSelector={{
            props: {
              ...generatedThresholds,
            },
          }}
          notifications={customerNotificationResources
            ?.filter(res => res.name.toLowerCase() !== 'nrn:custom:depletion_threshold' || res?.title?.toLowerCase() !== 'depletion threshold')
            .map(resource => (
              <EmailNotificationRow
                key={resource?.id + '_' + resource?.name}
                title={resource.title}
                toggle={{
                  onChange: (e: any) => {
                    if (e) {
                      createNotification({
                        variables: {
                          input: {
                            token: token!,
                            resource: resource?.id!,
                            service: GQL.CustomerContactNotificationService.Email,
                          },
                        },
                      })
                    } else {
                      deleteNotification({
                        variables: {
                          id: customerContact?.notifications?.find(
                            n => n?.service === GQL.CustomerContactNotificationService.Email && n?.resource.id === resource?.id
                          )?.id!,
                          token: token!,
                        },
                      })
                    }
                  },
                  isChecked:
                    (data.unsubscribeNotifications?.customerContact?.notifications?.find(
                      n => n?.resource.id === resource.id && n?.service === GQL.CustomerContactNotificationService.Email
                    ) ?? false) !== false,
                }}
              />
            ))}
        />
      )}
    </>
  )
}
