// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicMandatoryFieldsPanel, DefaultMandatoryFieldsPanelProps } from './plasmic/solace_components/PlasmicMandatoryFieldsPanel'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface MandatoryFieldsPanelProps extends Omit<DefaultMandatoryFieldsPanelProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultMandatoryFieldsPanelProps altogether and have
// total control over the props for your component.
export interface MandatoryFieldsPanelProps extends DefaultMandatoryFieldsPanelProps {}

export enum MandatoryField {
  CUSTOMER_NAME,
  PHONE_NUMBER,
  ADDRESS,
  PRODUCT,
  DEPOT,
  GAS_WEIGHT,
  GAS_TYPE,
  CUSTOMER_APP_VISIBILITY,
}

export interface MandatoryFieldObject {
  visible: boolean
  key: MandatoryField
  field: string
  valid: boolean
  id: string
}

function MandatoryFieldsPanel_(props: MandatoryFieldsPanelProps, ref: HTMLElementRefOf<'div'>) {
  const { mandatoryFieldsObject, ...plasmicProps } = props

  return (
    <PlasmicMandatoryFieldsPanel
      root={{ ref }}
      {...plasmicProps}
      mandatoryFieldsObject={mandatoryFieldsObject.filter((field: MandatoryFieldObject) => field.visible)}
    />
  )
}

const MandatoryFieldsPanel = React.forwardRef(MandatoryFieldsPanel_)
export default MandatoryFieldsPanel
