import React from 'react'
import { useIntl } from 'react-intl'

import Modal from 'components/Modal/Modal'
import UserForm, { UserFormData } from 'modules/users/components/UserForm'
import * as GQL from 'generated/graphql'
import { displayToast } from 'util/toasts'

interface EditUserModalProps {
  open: boolean
  onClose: () => void
  user?: GQL.UserNode
}

const EditUserModal: React.FC<EditUserModalProps> = ({ user, open, onClose }) => {
  const intl = useIntl()
  const t = intl.formatMessage

  const [patchUser, { loading }] = GQL.usePatchUser({
    refetchQueries: ['Users'],
    onCompleted: () => {
      displayToast(t({ id: 'edit-user.toasts.success' }), 'success')
      onClose()
    },
    onError: () => {
      displayToast(t({ id: 'edit-user.toasts.error' }), 'error')
    },
  })

  function handleSubmit(data: UserFormData) {
    patchUser({
      variables: {
        id: user?.id!,
        input: {
          ...data,
        },
      },
    })
  }

  return (
    <Modal isOpen={open} onRequestClose={onClose} stripped overlayStyle={{ alignItems: 'center', overflow: 'auto', padding: '2rem 0rem' }}>
      <UserForm onSubmit={handleSubmit} loading={loading} user={user} onClose={onClose} />
    </Modal>
  )
}

export default EditUserModal
