import React, { useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import * as GQL from 'generated/graphql'
import { PriceCategoryModal } from '../components/PriceCategoryModal'
import { displayToast } from 'util/toasts'
import { useAppContext, useClickOutside } from 'util/hooks'
import { CurrencyInput } from 'components/Input/CurrencyInput'
import SettingsContentWrapper from 'plasmic/SettingsContentWrapper'
import PricingListTable from 'plasmic/PricingListTable'
import PriceListCellHead from 'plasmic/PriceListCellHead'
import PriceListRow from 'plasmic/PriceListRow'
import PriceListCell from 'plasmic/PriceListCell'
import ActionDropdownLine from 'plasmic/ActionDropdownLine'
import LabelStatusChip from 'plasmic/LabelStatusChip'
import ButtonFill from 'plasmic/ButtonFill'
import ClearCellsvgIcon from 'plasmic/plasmic/solace_components/icons/PlasmicIcon__ClearCellsvg'
import TutorialTooltip from 'components/Tooltip/TutorialTooltip'
import { CenteredLoader } from 'components/Loader/LoaderWrappers'
import Loader from 'components/Loader'

enum ChangeStatus {
  CHANGED = 'changed',
  PUBLISHED = 'published',
}

export default function SettingsPricingLists() {
  const [productPrices, setProductPrices] = useState<GQL.ProductPriceNode[]>()
  const [editProductCategory, setEditProductCategory] = useState<GQL.PriceCategoryNode>()
  const [pricingListOpen, setPricingListOpen] = useState(false)
  const [actionsOpen, setActionsOpen] = useState('')

  const { appContext, setAppContext } = useAppContext()

  const intl = useIntl()
  const t = intl.formatMessage

  const moreActionsHeaderRef = useRef<HTMLElement>(null)
  useClickOutside(moreActionsHeaderRef, () => setActionsOpen(''))

  const { data, loading: allProductsLoading } = GQL.useAllProducts({
    onCompleted: response => {
      const availableProductPrices = response?.availableProducts?.edges.map(edge => edge?.node?.productPrices).flat()
      setProductPrices(availableProductPrices as GQL.ProductPriceNode[])
    },
  })

  const [bulkCreatePatchProductPrices, { loading }] = GQL.useBulkCreateProductPrices({
    refetchQueries: ['AllProducts'],
    onCompleted: response => {
      if (response.bulkCreateProductPrices?.ok) {
        displayToast(t({ id: 'settings.pricing-lists.bulk-update.success' }), 'success')
        return
      }
      displayToast(t({ id: 'settings.pricing-lists.bulk-update.error' }))
    },
    onError: () => {
      displayToast(t({ id: 'settings.pricing-lists.bulk-update.error' }))
    },
  })

  const [deletePriceCategory] = GQL.useDeletePriceCategory({
    onError: () => {
      displayToast(t({ id: 'settings.pricing-lists.delete.error' }))
    },
  })

  const allProducts = useMemo(() => {
    const availableProducts = data?.availableProducts?.edges || []
    return availableProducts.map(product => product?.node as GQL.ProductNode)
  }, [data])

  const distributorCategories = useMemo(() => {
    return appContext?.distributor?.priceCategories?.map(category => category as GQL.PriceCategoryNode)
  }, [appContext.distributor])

  const changesStatus: ChangeStatus = useMemo(() => {
    const startingProductPrices = allProducts.map(product => product.productPrices).flat() as GQL.ProductPriceNode[]
    if (startingProductPrices.length !== productPrices?.length) {
      return ChangeStatus.CHANGED
    }

    const differences = productPrices?.filter(startingPrice => {
      const matchingPrice = startingProductPrices?.some(currentPrice => {
        return (
          startingPrice.product.id === currentPrice.product.id &&
          startingPrice.category.id === currentPrice.category.id &&
          startingPrice.price.toString() !== currentPrice.price.toString()
        )
      })

      return !!matchingPrice
    })

    return differences.length > 0 ? ChangeStatus.CHANGED : ChangeStatus.PUBLISHED
  }, [allProducts, productPrices])

  const getProductCategoryColor = (product: GQL.ProductNode, category: GQL.PriceCategoryNode) => {
    const startingProductPrices = allProducts.map(product => product.productPrices).flat() as GQL.ProductPriceNode[]
    const currentProductPrice = productPrices?.find(productPrice => productPrice.product.id === product.id && productPrice.category.id === category.id)
    const startingProductPrice = startingProductPrices.find(startingPrice => {
      return startingPrice.product.id === product.id && startingPrice.category.id === category.id
    })

    if (currentProductPrice !== startingProductPrice) {
      return 'orange'
    } else if (category.default) {
      return 'blue'
    } else if (!startingProductPrice && !currentProductPrice) {
      return 'gray'
    } else {
      return 'white'
    }
  }

  const getProductCategoryPrice = (product: GQL.ProductNode, category: GQL.PriceCategoryNode) => {
    return (
      productPrices?.find(productPrice => productPrice.product.id === product.id && productPrice.category.id === category.id)?.price ||
      productPrices?.find(productPrice => productPrice.category.default === true && product.id === productPrice.product.id)?.price ||
      0
    )
  }

  const updatePricing = (event: React.ChangeEvent<HTMLInputElement>, product: GQL.ProductNode, category: GQL.PriceCategoryNode) => {
    if (!productPrices) {
      return
    }
    const existsIndex = productPrices.findIndex(priceList => priceList.product.id === product.id && priceList.category.id === category.id)
    const updatedPriceList = [...productPrices]
    if (existsIndex !== -1) {
      updatedPriceList[existsIndex] = {
        ...updatedPriceList[existsIndex],
        price: Math.abs(parseFloat(event.target.value || '0')).toString(),
      }
    } else {
      updatedPriceList.push({
        price: Math.abs(parseFloat(event.target.value || '0')).toString(),
        product: product,
        category: category,
        active: true,
        id: '',
        createdAt: '',
      })
    }
    setProductPrices(updatedPriceList)
  }

  const discardChanges = () => {
    const productPrices = allProducts.map(product => product.productPrices).flat() as GQL.ProductPriceNode[]
    setProductPrices(productPrices)
  }

  const revertProductPrice = (product: GQL.ProductNode, category: GQL.PriceCategoryNode) => {
    const basePrice =
      productPrices?.find(productPrice => productPrice?.category.default && productPrice.product.id === product.id && productPrice.category.id === category.id)
        ?.price || 0
    const existsIndex = productPrices?.findIndex(productPrice => productPrice.product.id === product.id && productPrice.category.id === category.id) || -1

    if (existsIndex !== -1 && productPrices) {
      const updatedPriceList = [...productPrices]
      updatedPriceList[existsIndex] = {
        ...updatedPriceList[existsIndex],
        price: basePrice,
      }
      setProductPrices(updatedPriceList)
    }
  }

  const handleSubmit = () => {
    if (!productPrices || changesStatus !== ChangeStatus.CHANGED) {
      displayToast(t({ id: 'settings.pricing-lists.bulk-update.error.no-pricing-lists' }), 'error')
      return
    }
    bulkCreatePatchProductPrices({
      variables: {
        input: {
          productPrices: productPrices.map(priceList => {
            return { active: priceList.active, price: priceList.price.toString(), category: priceList.category.id, product: priceList.product.id }
          }),
        },
      },
    })
  }

  return (
    <SettingsContentWrapper
      settingsTitle={t({ id: 'settings.pricing-lists' })}
      headerButtons={
        <ButtonFill
          data-testid='createPricingListsBtn'
          rounded
          color={'blue'}
          label={t({ id: 'settings.pricing-lists.create' })}
          onClick={() => setPricingListOpen(true)}
        />
      }
      showChangesBar
      settingChangesBar={{
        status: changesStatus,
        btnDiscardChanges: {
          props: {
            onClick: () => discardChanges(),
          },
        },
        btnPublishChanges: {
          props: {
            loading: loading,
            disabled: loading,
            onClick: () => handleSubmit(),
          },
        },
      }}
      content={
        <>
          <PricingListTable
            headerCells={distributorCategories
              ?.sort((a, b) => {
                if (a.default) {
                  return -1
                } else if (b.default) {
                  return 1
                } else {
                  return 0
                }
              })
              .map((category, index) => (
                <PriceListCellHead
                  key={index + 'distributor-category'}
                  title={category.name}
                  moreActions={{
                    props: {
                      name: 'moreActions',
                      ref: category.id === actionsOpen ? moreActionsHeaderRef : null,
                      open: category.id === actionsOpen,
                      actionDropdown: (
                        <>
                          <ActionDropdownLine
                            children={t({ id: 'common.edit' })}
                            onClick={() => {
                              setPricingListOpen(true)
                              setEditProductCategory(category)
                            }}
                          />
                          <ActionDropdownLine
                            children={t({ id: 'common.delete' })}
                            onClick={() =>
                              deletePriceCategory({
                                variables: {
                                  id: category.id,
                                },
                                onCompleted: response => {
                                  if (response.deletePriceCategory?.ok) {
                                    displayToast(t({ id: 'settings.pricing-lists.delete.success' }), 'success')
                                    if (appContext.distributor) {
                                      setAppContext({
                                        ...appContext,
                                        distributor: {
                                          ...appContext.distributor,
                                          priceCategories: appContext?.distributor?.priceCategories?.filter(priceCategory => priceCategory?.id !== category.id),
                                        } as GQL.DistributorNode,
                                      })
                                    }
                                  } else {
                                    displayToast(t({ id: 'settings.pricing-lists.delete.error' }))
                                  }
                                },
                              })
                            }
                          />
                        </>
                      ),
                      onClick: () => (actionsOpen ? setActionsOpen('') : setActionsOpen(category.id)),
                    },
                  }}
                  color={category.default ? 'blue' : undefined}
                />
              ))}
            rows={
              <>
                {allProductsLoading && (
                  <CenteredLoader sizeAuto>
                    <Loader color='gray6' size={50} />
                  </CenteredLoader>
                )}
                {!allProductsLoading && allProducts.length === 0 && (
                  <div style={{ display: 'flex', padding: '16px', justifyContent: 'center' }}>
                    <LabelStatusChip icon={'info'} title={t({ id: 'common.no-products' })} />
                  </div>
                )}
                {!allProductsLoading &&
                  allProducts?.map((product, index) => (
                    <PriceListRow
                      key={index + product.id}
                      productName={product?.displayName}
                      cells={distributorCategories?.map((category, index) => (
                        <PriceListCell
                          key={index + category.id}
                          inputDiv={
                            <CurrencyInput
                              classNames='inputPriceField'
                              editClassNames='inputPriceFieldEdit'
                              currency={appContext?.distributor?.defaultCurrency || 'USD'}
                              value={getProductCategoryPrice(product, category).toString()}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => updatePricing(event, product, category)}
                            />
                          }
                          color={getProductCategoryColor(product, category)}
                          btnClear={{
                            onClick: () => revertProductPrice(product, category),
                            children: (
                              <TutorialTooltip content='Set to default'>
                                <div>
                                  <ClearCellsvgIcon width={'18px'} height={'18px'} />
                                </div>
                              </TutorialTooltip>
                            ),
                          }}
                        />
                      ))}
                      noImage={!product?.image}
                      productImage={product?.image?.image}
                    />
                  ))}
              </>
            }
          />
          {pricingListOpen && (
            <PriceCategoryModal
              pricingCategories={distributorCategories}
              priceCategory={editProductCategory}
              onClose={() => {
                setPricingListOpen(false)
                setEditProductCategory(undefined)
              }}
            />
          )}
        </>
      }
    />
  )
}
