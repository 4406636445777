import * as GQL from 'generated/graphql'

export const THRESHOLD_UNIT_SYMBOLS = {
  [GQL.DistributorRefillThresholdUnit.Days]: 'd',
  [GQL.DistributorRefillThresholdUnit.Kilogram]: 'kg',
  [GQL.DistributorRefillThresholdUnit.Percent]: '%',
  [GQL.DistributorRefillThresholdUnit.Liter]: 'L',
} as const

export const THRESHOLD_UNIT_PLASMIC_NAME = {
  [GQL.DistributorRefillThresholdUnit.Days]: 'typeDays',
  [GQL.DistributorRefillThresholdUnit.Kilogram]: 'typeGasWeight',
  [GQL.DistributorRefillThresholdUnit.Percent]: 'typePercentage',
  [GQL.DistributorRefillThresholdUnit.Liter]: 'typeLiters',
} as const

export const PRODUCT_STATUS_PLASMIC_NAME = {
  [GQL.ProductStatus.Active]: 'active',
  [GQL.ProductStatus.Inactive]: 'inactive',
} as const

export const PICKER_COLORS = ['#ed0033', '#ea6a00', '#fcb900', '#008e5f', '#00a8a8', '#0d4f9e', '#9e2883', '#59187a', '#b7babc', '#181d36']
export const MAX_ALLOWED_SIZE_MB = 1
export const MIN_IMAGE_SIZE_PX = 300
export const VALID_FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png']

export const STRIPE_DASHBOARD_URL = '`https://dashboard.stripe.com`'
