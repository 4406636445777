import { useState } from 'react'
import { format, parse, startOfDay, addDays, subDays, getDate, isSameDay } from 'date-fns'
import range from 'lodash/range'
import styled from 'styled-components'

import { ReactComponent as VehicleIcon } from 'assets/vehicle_general.svg'
import * as GQL from 'generated/graphql'
import Text from 'components/Text'
import { hexWithAlpha } from 'util/style'
import ScIcon from 'components/Icon/ScIcon'
import Tooltip from 'components/Tooltip'
import DroppableDateCard from './DroppableDateCard'
import DateCard from './DateCard'

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 110px;
`

interface IArrowCardProps {
  left?: boolean
}

const ArrowCard = styled.div<IArrowCardProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 79px;
  width: 50px;
  margin: 1px;
  box-sizing: border-box;

  border: 1px solid ${props => props.theme.colors.primary}80;
  background: ${props => props.theme.colors.primary}80;
  cursor: pointer;

  :hover {
    background: ${props => hexWithAlpha(props.theme.colors.primary, 0.6)};
    border: 1px solid ${props => hexWithAlpha(props.theme.colors.blue, 0.7)};
  }

  ${props =>
    props.left &&
    `
    transform: rotate(180deg);
  `}
`
interface ILoad {
  percent: number
}
const Load = styled.div<ILoad>`
  position: relative;
  height: 50px;
  width: 100%;
  background: ${props => props.theme.colors.primary}80;

  &:after {
    content: '';
    position: absolute;
    background: #2c97de;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    height: ${props => props.percent}%;
  }
`

interface ICars {
  cars: number
  overview: boolean
}
const Cars = styled.div<ICars>`
  height: 12px;
  min-height: 12px;
  width: 100%;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 0;
  background: ${props => (props.cars === 0 ? (props.overview ? 'rgb(29,34,61)' : '#181d36') : props.theme.colors.primary + '80')};
  box-sizing: border-box;
  padding: 4px;

  div {
    flex: 1;
    display: flex;
    align-items: center;
    color: #fff;

    svg {
      padding-top: 2px;
      margin-left: 4px;
    }
  }
`

const TodayExtra = styled.div`
  position: absolute;
  left: -1px;
  right: -1px;
  top: -18px;
  height: 18px;
  border: 1px solid ${props => props.theme.colors.primary}80;
  background: ${props => props.theme.colors.primary}80;
  border-bottom: none;
  text-align: center;
  box-sizing: border-box;
  line-height: 1;
`

interface IDateCardProps {
  today?: boolean
  selected?: boolean
}

const Content = styled.div<IDateCardProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 70px;
  padding: 0.5em 0;
  box-sizing: border-box;
  width: 100%;
  background: ${props => props.theme.colors.primary}80;
  border-top: 2px solid rgb(24, 29, 54);
  border-bottom: 2px solid rgb(24, 29, 54);
  ${props =>
    props.selected &&
    `
    border: 1px solid ${props.theme.colors.blue};
  `}
  :hover {
    background: ${props => hexWithAlpha(props.theme.colors.primary, 0.6)};
    border: 1px solid ${props => hexWithAlpha(props.theme.colors.blue, props.selected ? 1.5 : 0.7)};
  }
  ${props =>
    props.today &&
    `:before {
    content: '';
    position: absolute;
    bottom: 20px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${props.theme.colors.green};`}}
`

interface IFlatCalendarProps {
  initialFocalPoint: Date
  noArrows?: boolean
  overview?: boolean
  numDaysToShow: number
  selectedDate?: Date
  onSelectedDateChanged: (selectedDate: Date) => any
  onFocalChange?: (date: Date) => any
  width?: string
  renderToday?: boolean
  renderTomorrow?: boolean
  routes: GQL.RouteInfo[]
  droppable?: boolean
  dropAccepts?: string[]
  onDrop?: (day: Date, item: any) => void
}

const FlatCalendar = ({
  initialFocalPoint = new Date(),
  numDaysToShow,
  selectedDate,
  onSelectedDateChanged,
  onFocalChange,
  noArrows = false,
  routes,
  overview = false,
  droppable,
  dropAccepts,
  onDrop,
}: IFlatCalendarProps) => {
  const [focalPoint, setFocalPoint] = useState(startOfDay(initialFocalPoint))

  const days = range(numDaysToShow).map(i => addDays(focalPoint, i))

  const changeFocalPoint = (point: Date) => {
    if (onFocalChange) {
      onFocalChange(point)
    }
    setFocalPoint(point)
    onSelectedDateChanged(point)
  }

  return (
    <Wrapper>
      {!noArrows && (
        <ArrowCard left onClick={() => changeFocalPoint(subDays(focalPoint, numDaysToShow))}>
          <ScIcon icon='sc-chevron-right' />
        </ArrowCard>
      )}
      {days.map(day => {
        const today = isSameDay(day, new Date())
        const isSelected = selectedDate && isSameDay(day, selectedDate)

        const cylinderCount = routes
          .filter(r => isSameDay(new Date(r.date), day))
          .map(r => r?.stops?.filter(s => s?.order).map(s => s?.order?.numberOfCylinders as number))
          .flat()
          .reduce((a, b) => (a ?? 0) + (b ?? 0), 0)
        const cars = routes.filter(r => isSameDay(new Date(r.date), day)).length
        const hasRoutesToday = routes.some(route => isSameDay(new Date(route.date), day))

        const timeDiff = routes
          .filter(r => isSameDay(new Date(r.date), day))
          .map(route => {
            const driverStart = parse(route.driverStartTime, 'HH:mm:ss', new Date()).getTime()
            const driverEnd = parse(route.driverEndTime, 'HH:mm:ss', new Date()).getTime()

            const routeStart = new Date(route.startTime).getTime()
            const routeEnd = new Date(route.endTime).getTime()

            const driverDiff = Math.round((driverEnd - driverStart) / 1000 / 60)
            const routeDiff = Math.round((routeEnd - routeStart) / 1000 / 60)

            return [routeDiff, driverDiff]
          })

        const percentLoad = timeDiff.map(t => Math.round((t[0] / t[1]) * 100)).reduce((acc, cur) => (acc === 0 ? cur : acc + cur / 2), 0)
        const timeSpentDec = timeDiff.map(t => t[0]).reduce((acc, cur) => acc + cur, 0) / 60
        const timeSpent = [
          Math.floor(timeSpentDec).toString().padStart(2, '0'),
          Math.floor((timeSpentDec - Math.floor(timeSpentDec)) * 60)
            .toString()
            .padStart(2, '0'),
        ]

        const dateCardContent = (
          <>
            <Tooltip arrow={false} content={'Load: ' + cylinderCount + ' cylinders / ' + timeSpent[0] + ':' + timeSpent[1] + ' hours'}>
              <Load percent={percentLoad} />
            </Tooltip>
            {today && (
              <TodayExtra>
                <Text fontSize='8px' fontWeight={600} color='green' textTransform='uppercase'>
                  Today
                </Text>
              </TodayExtra>
            )}
            <Tooltip delay={[250, 0]} arrow={true} content={format(day, 'dd MMMM, yyyy')}>
              <Content today={today} selected={isSelected}>
                <Text fontSize='12px' textTransform='uppercase' color='rgba(255,255,255,0.5)'>
                  {format(day, 'EEE')}
                </Text>
                <Text fontSize='1.5rem' color='white'>
                  {getDate(day)}
                </Text>
              </Content>
            </Tooltip>

            <Cars cars={cars} overview={overview}>
              {cars > 0 && (
                <div>
                  {cars} <VehicleIcon />
                </div>
              )}
            </Cars>
          </>
        )

        return droppable && dropAccepts && onDrop ? (
          <DroppableDateCard
            key={format(day, 'yyyymmdd')}
            accept={dropAccepts}
            today={today}
            selected={isSelected}
            onItemDrop={item => onDrop(day, item)}
            onClick={() => onSelectedDateChanged(day)}
            data-testid={hasRoutesToday ? 'route' : undefined}
          >
            {dateCardContent}
          </DroppableDateCard>
        ) : (
          <DateCard
            key={format(day, 'yyyymmdd')}
            today={today}
            selected={isSelected}
            onClick={() => onSelectedDateChanged(day)}
            data-testid={hasRoutesToday ? 'route' : undefined}
          >
            {dateCardContent}
          </DateCard>
        )
      })}
      {!noArrows && (
        <ArrowCard onClick={() => changeFocalPoint(addDays(focalPoint, numDaysToShow))}>
          <ScIcon icon='sc-chevron-right' />
        </ArrowCard>
      )}
    </Wrapper>
  )
}

FlatCalendar.defaultProps = {
  initialFocalPoint: new Date(),
  numDaysToShow: 24,
  highlightedDates: [],
  onSelectedDateChanged: () => null,
}

export default FlatCalendar
