import React, { useState } from 'react'
import styled from 'styled-components'
import { Map } from 'immutable'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { format, startOfWeek } from 'date-fns'
import { eachDayOfInterval, addDays, subDays } from 'date-fns'

import DriverDatesPanelTile from './DriverDatesPanelTile'
import DriverDatesPanelDateTile from './DriverDatesPanelDateTile'
import * as GQL from 'generated/graphql'
import Truck from 'components/icons/Truck'
import SimpleClock from 'components/icons/SimpleClock'
import MapMarker from 'components/icons/MapMarker'
import { useAppContext } from 'util/hooks'
import TutorialTooltip from 'components/Tooltip/TutorialTooltip'

const TruckIcon = styled(Truck)`
  width: 15px;
  height: 15px;
  fill: ${props => props.theme.colors.lightBlue};
`

interface LayoutProps {
  numberOfDrivers?: number
}

const Layout = styled.div<LayoutProps>`
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 5px;
  width: 100%;

  padding: 2.5px 0;
  position: relative;

  &:hover > div {
    opacity: 1;
  }
`

const Row = styled.div`
  display: contents;
`

const WeekBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 1em;
`

const WeekArrow = styled.div`
  background: #1c213b;
  padding: 1em;
  border-radius: 4px;
  cursor: pointer;
  :hover {
    filter: brightness(1.1);
  }
  :active {
    filter: brightness(0.9);
  }
`

const Week = styled.div`
  background: #1c213b;
  border-radius: 4px;
  padding: 1em;
  flex: 1;
  margin-right: 0.5em;
  margin-left: 0.5em;

  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
  :hover {
    filter: brightness(1.1);
  }
  :active {
    filter: brightness(0.9);
  }
`

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    background: #252c48;
  }
`

const StyledMenuItem = styled(MenuItem)`
  color: #ffffff;
  font-family: 'Proxima Nova';
  font-size: 13px;
  font-weight: 400;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }

  *:first-child {
    margin-right: 0.5em;
  }
`

const Wrapper = styled.div`
  padding-right: 30px;
`

interface DriverDatesPanelProps {
  routes: GQL.DriverExtendedNode[]
  currentRoutes: GQL.RouteNode[]
  startDate: Date
  setStartDate: (date: Date) => void
  endDate: Date
  drivers: GQL.DriverNode[]
}

const DriverDatesPanel: React.FC<DriverDatesPanelProps> = ({ routes, currentRoutes, startDate, setStartDate, endDate, drivers }) => {
  const { appContext } = useAppContext()
  const currentDepot = appContext.depot?.id ?? null

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [cb, setCb] = useState<((action: string) => void) | null>(null)

  const [patchDriverException] = GQL.usePatchDriverExceptions({
    refetchQueries: ['Routes' /*'CurrentOrderStats'*/],
  })

  const [createDriverException] = GQL.useCreateDriverExceptions({
    refetchQueries: ['Routes' /*'CurrentOrderStats'*/],
  })

  function removeRoute(route: GQL.DriverExtendedNode) {
    if (route.exceptionId) {
      patchDriverException({
        variables: {
          id: route.exceptionId.toString(),
          input: {
            vacated: true,
          },
        },
      })
    } else {
      createDriverException({
        variables: {
          input: {
            driver: route.driverId!.toString(),
            onDate: route.routeOnDate,
            vacated: true,
          },
        },
      })
    }
  }

  function addRoute(driver: GQL.DriverNode, date: Date, route?: GQL.DriverExtendedNode) {
    if (route && route.exceptionId) {
      patchDriverException({
        variables: {
          id: route.exceptionId.toString(),
          input: {
            vacated: false,
          },
        },
      })
    } else {
      createDriverException({
        variables: {
          input: {
            driver: driver.id,
            onDate: format(date, 'yyyy-MM-dd'),
            startTime: driver.standardStartTime || '08:00',
            endTime: driver.standardEndTime || '16:00',
            vacated: false,
            defaultDepot: currentDepot,
          },
        },
      })
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleAction = (action: string) => {
    if (cb) {
      cb(action)
      setCb(null)
    }

    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>, callback: any) => {
    setCb(() => callback)
    setAnchorEl(event.currentTarget)
  }

  const routeLookup = routes.reduce((acc, route) => {
    return acc.setIn([route.user?.id, route.routeOnDate], route)
  }, Map<string, Map<string, GQL.DriverExtendedNode>>())

  const dates = eachDayOfInterval({
    start: startDate,
    end: endDate,
  })

  return (
    <Wrapper>
      <StyledMenu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
      >
        <StyledMenuItem onClick={() => handleAction('vehicle')}>
          <TruckIcon /> Change vehicle
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleAction('workhours')}>
          <SimpleClock width='15px' height='15px' fill={'#9300e9'} /> Change workhours
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleAction('depot')}>
          <MapMarker width='15px' height='15px' fill={'#00d4a6'} /> Change depot
        </StyledMenuItem>
      </StyledMenu>

      <WeekBar>
        <WeekArrow onClick={() => setStartDate(subDays(startDate, 7))}>
          <i className='fas fa-chevron-left'></i>
        </WeekArrow>
        <TutorialTooltip content='Click to go back to current week'>
          <Week onClick={() => setStartDate(startOfWeek(new Date(), { weekStartsOn: 1 }))}>{format(startDate, "LLLL'' yy '- Week' w")}</Week>
        </TutorialTooltip>
        <WeekArrow onClick={() => setStartDate(addDays(startDate, 7))}>
          <i className='fas fa-chevron-right'></i>
        </WeekArrow>
      </WeekBar>

      <Layout>
        <Row>
          {dates.map(date => {
            return <DriverDatesPanelDateTile key={date.toString()} date={date} />
          })}
        </Row>
        {drivers.map(driver => {
          return (
            <Row key={driver.id}>
              {dates.map(date => {
                const route = routeLookup.getIn([driver.user.id, format(date, 'yyyy-MM-dd')]) as GQL.DriverExtendedNode
                const hasRoute = !!route

                return (
                  <DriverDatesPanelTile
                    key={date.getTime()}
                    handleMenu={handleClick}
                    date={date}
                    route={route}
                    driver={driver}
                    currentRoutes={currentRoutes}
                    onClick={() => {
                      if (hasRoute) {
                        if (route.routeVacated) {
                          addRoute(driver, date, route)
                        } else {
                          removeRoute(route)
                        }
                      } else {
                        addRoute(driver, date)
                        return
                      }
                    }}
                  />
                )
              })}
            </Row>
          )
        })}
      </Layout>
    </Wrapper>
  )
}

export default DriverDatesPanel
