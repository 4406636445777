import * as React from 'react'

import * as GQL from 'generated/graphql'
import { getVersion, isLocal } from 'util/env'
import clearCache from 'util/misc'
import CacheConfigs from 'util/cacheConfig'

import './Navbar.css'
import { PlasmicNavbar } from './plasmic/solace_components/PlasmicNavbar'

const BREAK_POINT = 970

const Navbar = (props: any, ref: any) => {
  const [mobile, setMobile] = React.useState(window.innerWidth < BREAK_POINT)

  const { data: dataVersions } = GQL.useGetAppVersions({ ...CacheConfigs.ACCURATE_FREQUENT })

  const isLatest = React.useMemo(() => {
    if (isLocal()) {
      return true
    }
    return dataVersions?.getLatestAppVersion?.version
      ? getVersion() === dataVersions?.getLatestAppVersion?.version || getVersion() === '1337'
        ? true
        : false
      : false
  }, [dataVersions])

  React.useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth < BREAK_POINT)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <PlasmicNavbar
      root={{ ref }}
      {...props}
      mobile={mobile}
      update={!isLatest}
      btnReload={{
        onClick: () => clearCache().then(() => window.location.reload()),
      }}
    />
  )
}

export default React.forwardRef(Navbar)
