import React from 'react'
import Select, { ISelectProps } from 'components/Select/Select'
import * as GQL from 'generated/graphql'

interface ISelectDriver extends Omit<ISelectProps, 'options'> {
  drivers?: GQL.DriverNode[]
}

const SelectDriver: React.FC<ISelectDriver> = ({ value, drivers, onChange, ...props }) => {
  let allDrivers: GQL.DriverNode[] = []

  const { data } = GQL.useDrivers()

  if (!drivers) {
    allDrivers = data?.allDrivers?.edges.map(edge => edge?.node as GQL.DriverNode) || []
  }

  const useDrivers = drivers || allDrivers

  const options = useDrivers.map(driver => ({
    value: driver.id!,
    label: driver.user.fullName!,
  }))

  return <Select options={options} value={value} onChange={onChange} {...props} />
}

export default SelectDriver
