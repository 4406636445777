import * as GQL from 'generated/graphql'

export const MOVE_ROUTE_DISALLOWED_STATUSES = [
  GQL.RouteStatus.DispatchedButUpdated,
  GQL.RouteStatus.Dispatched,
  GQL.RouteStatus.Started,
  GQL.RouteStatus.Completed,
  GQL.RouteStatus.Cancelled,
]

export enum ORDER_ROUTE_CATEGORY {
  PLANNED = 'planned',
  UNPLANNED = 'unplanned',
}
