// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ApplepayOutlinesvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ApplepayOutlinesvgIcon(props: ApplepayOutlinesvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      data-name={"Payment Icons"}
      viewBox={"0 0 38 38"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M33 8a4 4 0 014 4v14a4 4 0 01-4 4H5a4 4 0 01-4-4V12a4 4 0 014-4h28m0-1H5a5 5 0 00-5 5v14a5 5 0 005 5h28a5 5 0 005-5V12a5 5 0 00-5-5z"
        }
      ></path>

      <path
        d={
          "M11.66 15.47a1.39 1.39 0 01-1.14.54 1.64 1.64 0 01.41-1.18 1.69 1.69 0 011.13-.58 1.72 1.72 0 01-.4 1.22m.39.63c-.63 0-1.16.36-1.46.36s-.76-.34-1.26-.33a1.85 1.85 0 00-1.58.95 4 4 0 00.48 3.85c.32.47.71 1 1.21 1s.67-.31 1.25-.31.75.31 1.26.3.85-.47 1.17-.94a4 4 0 00.53-1.08 1.7 1.7 0 01-1-1.55 1.74 1.74 0 01.82-1.45 1.78 1.78 0 00-1.4-.76m3.63-1.31v7h1.1v-2.41h1.51a2.23 2.23 0 002.35-2.32 2.2 2.2 0 00-2.31-2.31zm1.1.92H18a1.32 1.32 0 011.49 1.4 1.32 1.32 0 01-1.5 1.4h-1.21zm5.86 6.18a1.79 1.79 0 001.61-.9v.84h1v-3.5c0-1-.81-1.68-2.07-1.68a1.84 1.84 0 00-2 1.58h1a1 1 0 011-.72c.67 0 1 .31 1 .89v.39l-1.37.08c-1.27.08-2 .6-2 1.5a1.55 1.55 0 001.81 1.48zm.3-.84c-.59 0-1-.28-1-.71s.36-.7 1-.74l1.22-.08v.4a1.19 1.19 0 01-1.24 1.09zm3.7 2.7c1.07 0 1.57-.41 2-1.64l1.92-5.39h-1.12l-1.29 4.16-1.29-4.16h-1.17l1.86 5.13-.1.31a.88.88 0 01-.92.74h-.32v.84a2.63 2.63 0 00.41-.03z"
        }
      ></path>
    </svg>
  );
}

export default ApplepayOutlinesvgIcon;
/* prettier-ignore-end */
