// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ScResearchCentersvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ScResearchCentersvgIcon(props: ScResearchCentersvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 35 36"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M27.916 26.784c2.409-2.882 3.72-6.511 3.72-10.346 0-4.261-1.624-8.265-4.567-11.283-2.951-3.021-6.878-4.69-11.055-4.69S7.91 2.126 4.959 5.153C2.017 8.173.392 12.175.392 16.436s1.624 8.265 4.567 11.283c2.95 3.027 6.878 4.689 11.055 4.689 3.736 0 7.266-1.335 10.087-3.774l6.283 6.504c.251.267.595.397.93.397.327 0 .648-.122.899-.366a1.29 1.29 0 00.031-1.829l-6.328-6.556zm-21.105-.87c-5.1-5.222-5.1-13.723 0-18.947 2.463-2.524 5.726-3.911 9.203-3.911s6.74 1.387 9.203 3.911c5.1 5.222 5.1 13.723 0 18.947-2.463 2.516-5.734 3.903-9.203 3.903s-6.74-1.387-9.203-3.903z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ScResearchCentersvgIcon;
/* prettier-ignore-end */
