import styled from 'styled-components'
import { Link } from 'react-router-dom'

interface IStyledLinkProps {
  width?: string
  height?: string
}

const StyledLink = styled(Link)<IStyledLinkProps>`
  width: ${props => props.width};
  height: ${props => props.height};
`

export default StyledLink
