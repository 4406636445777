import React, { useState } from 'react'
import client from 'apollo'
import { ApolloProvider } from '@apollo/client'
import { CookiesProvider } from 'react-cookie'
import { BrowserRouter as Router } from 'react-router-dom'
import { PlasmicRootProvider } from '@plasmicapp/react-web'
import { ThemeProvider } from 'styled-components'
import { ThemeProvider as MUIThemeProvider, StylesProvider } from '@material-ui/core/styles'
import { ModalProvider } from 'react-modal-hook'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { isLocal } from 'util/env'
import { AwsSensorCalculationService } from 'services/sensorCalculation'
import SensorCalculationServiceContext from 'context/SensorCalculationServiceContext'
import Bootstrap from './Bootstrap'
import IntlContextProvider from './IntlContextProvider'
import theme from '../themes/theme'
import muiTheme from '../themes/mui-theme'
import ActiveModulesContext from 'plasmic/plasmic/solace_components/PlasmicGlobalVariant__ActiveModules'

const sensorCalculationService = new AwsSensorCalculationService(
  isLocal() ? 'http://localhost:9000/calculations' : 'https://lambda-calc-graphs.s3.eu-west-1.amazonaws.com'
)

const Root = () => {
  const [paymentEnabled, setPaymentEnabled] = useState(false)

  return (
    <DndProvider backend={HTML5Backend}>
      <PlasmicRootProvider>
        <ActiveModulesContext.Provider value={paymentEnabled ? 'payment' : undefined}>
          <CookiesProvider>
            <ApolloProvider client={client}>
              <IntlContextProvider>
                <StylesProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    <MUIThemeProvider theme={muiTheme}>
                      <Router>
                        <SensorCalculationServiceContext.Provider value={sensorCalculationService}>
                          <ModalProvider>
                            <Bootstrap setPaymentEnabled={setPaymentEnabled} />
                          </ModalProvider>
                        </SensorCalculationServiceContext.Provider>
                      </Router>
                    </MUIThemeProvider>
                  </ThemeProvider>
                </StylesProvider>
              </IntlContextProvider>
            </ApolloProvider>
          </CookiesProvider>
        </ActiveModulesContext.Provider>
      </PlasmicRootProvider>
    </DndProvider>
  )
}

export default Root
