// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ModeLightsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ModeLightsvgIcon(props: ModeLightsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 468.73 468.73"}
      xmlSpace={"preserve"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <circle cx={"235.939"} cy={"34.458"} r={"34.458"}></circle>

        <circle cx={"94.122"} cy={"91.897"} r={"34.458"}></circle>

        <circle cx={"34.458"} cy={"232.792"} r={"34.458"}></circle>

        <circle cx={"91.897"} cy={"374.608"} r={"34.458"}></circle>

        <circle cx={"232.792"} cy={"434.272"} r={"34.458"}></circle>

        <circle cx={"374.608"} cy={"376.833"} r={"34.458"}></circle>

        <circle cx={"434.272"} cy={"235.939"} r={"34.458"}></circle>

        <circle cx={"376.833"} cy={"94.122"} r={"34.458"}></circle>

        <circle cx={"234.365"} cy={"234.365"} r={"140.002"}></circle>
      </g>
    </svg>
  );
}

export default ModeLightsvgIcon;
/* prettier-ignore-end */
