import { format, parseISO, startOfYear } from 'date-fns'

const nameFormat = {
  J: 'LLLLL',
  Jan: 'LLL',
  January: 'LLLL',
}

export const getMonthNameFromNumber = (month: number, frmt: 'J' | 'Jan' | 'January' = 'Jan') => {
  let curDate = startOfYear(new Date())
  curDate.setMonth(month - 1)
  return format(curDate, nameFormat[frmt])
}

export const parseISONullSafe = (argument?: string, defaultReturn?: Date): Date | undefined => {
  return argument ? parseISO(argument) : defaultReturn
}
