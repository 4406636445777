import React from 'react'
import styled from 'styled-components'
import Select from 'components/Select'
import { ISelectProps } from 'components/Select/Select'
import ScIcon from 'components/Icon/ScIcon'
import * as GQL from 'generated/graphql'

const VehicleItem = styled.div<{ isSelected: boolean }>`
  text-align: left;
  display: flex;
  align-items: center;

  padding: 0.5em;
  cursor: pointer;

  color: white;

  ${props =>
    !props.isSelected &&
    `
    &:hover {
      color: ${props.theme.colors.blue};
    }
  `}
`

const VehicleIcon = styled.span`
  margin-right: 0.5rem;
  width: 24px;
`

const vehicleIconLookup = {
  TRUCK: 'sc-vehicle-truck',
  VAN: 'sc-vehicle-van',
  PICKUP: 'sc-vehicle-pickup',
}

interface ISelectVehicle extends Omit<ISelectProps, 'options'> {
  vehicles?: GQL.VehicleNode[]
}

const SelectVehicle: React.FC<ISelectVehicle> = ({ defaultValue, onChange, vehicles, ...props }) => {
  let allVehicles: GQL.VehicleNode[] = []

  const { data } = GQL.useAllVehicles()

  allVehicles = data?.allVehicles?.edges.map(edge => edge?.node as GQL.VehicleNode) || []

  const useVehicles = vehicles || allVehicles

  const options = useVehicles.map(vehicle => ({
    value: vehicle.id,
    label: vehicle.name + (vehicle.defaultForDrivers?.length! > 0 ? ' (' + vehicle.defaultForDrivers?.find(el => true)?.user.fullName + ')' : ''),
    render: (isSelected: boolean) => (
      <VehicleItem isSelected={isSelected}>
        <VehicleIcon>
          <ScIcon size='12px' icon={vehicleIconLookup[vehicle.vehicleType]} />
        </VehicleIcon>
        {isSelected ? (
          vehicle.name
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {vehicle.name}
            {vehicle.defaultForDrivers?.length! > 0 && <span>{vehicle.defaultForDrivers?.find(el => true)?.user.fullName}</span>}
          </div>
        )}
      </VehicleItem>
    ),
  }))

  return <Select options={options} defaultValue={defaultValue} onChange={onChange} {...props} />
}

export default SelectVehicle
