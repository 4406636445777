import styled from 'styled-components'

interface ITableProps {
  fontSize?: string
  width?: string
  fullWidth?: boolean
}

const Table = styled.table<ITableProps>`
  border-collapse: collapse;

  width: ${props => (props.fullWidth ? '100%' : props.width)};
  font-size: ${props => props.fontSize};
`

Table.defaultProps = {
  fontSize: '14px',
  width: 'auto',
}

export default Table
