// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type MastercardOutlinesvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function MastercardOutlinesvgIcon(props: MastercardOutlinesvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      data-name={"Payment Icons"}
      viewBox={"0 0 38 38"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M33 8a4 4 0 014 4v14a4 4 0 01-4 4H5a4 4 0 01-4-4V12a4 4 0 014-4h28m0-1H5a5 5 0 00-5 5v14a5 5 0 005 5h28a5 5 0 005-5V12a5 5 0 00-5-5z"
        }
      ></path>

      <path
        d={"M18.11 15.08a4.75 4.75 0 100 7.84 5.93 5.93 0 010-7.84z"}
      ></path>

      <circle cx={"22.56"} cy={"19"} r={"4.75"}></circle>
    </svg>
  );
}

export default MastercardOutlinesvgIcon;
/* prettier-ignore-end */
