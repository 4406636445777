// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import { useRef, useState } from 'react'
import { format } from 'date-fns'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

import * as GQL from 'generated/graphql'
import { PlasmicRouteHead, DefaultRouteHeadProps } from './plasmic/solace_components/PlasmicRouteHead'
import { useClickOutside } from 'util/hooks'
import { displayToast } from 'util/toasts'
import { getWeekdayNumber } from 'components/DatePicker/utils'
import ConfirmModal from 'components/Modal/ConfirmModal'
import ChangeDriverModal from 'modules/deliveries/components/ChangeDriverModal'
import MoveRouteModal from 'modules/deliveries/components/MoveRouteModal'
import Loader from 'components/Loader'
import ActionDropdownLine from './ActionDropdownLine'
import { MOVE_ROUTE_DISALLOWED_STATUSES } from 'modules/deliveries/consts'

const ProgressContainer = styled.div<{ progress: number }>`
  /* border: 1px solid rgb(16, 126, 116); */
  border-radius: 4px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  height: 30px;
  position: relative;

  &:before {
    content: '';
    width: ${props => props.progress}%;
    background: rgb(16, 126, 116);
    display: block;
    font-style: normal;
    padding: 0.5em;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: ${props => (props.progress > 99 ? '3px' : '0px')};
    border-bottom-right-radius: ${props => (props.progress > 99 ? '3px' : '0px')};
    box-sizing: border-box;
    overflow: visible;
    white-space: nowrap;
    /* transform: scale(1.06) */
  }
  span {
    position: absolute;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
`

interface RouteHeadProps extends DefaultRouteHeadProps {
  route: GQL.RouteNode
  routes: GQL.RouteNode[]
  drivers: GQL.DriverNode[]

  cellLocked?: any
  moreActions?: any
}

function RouteHead_(props: RouteHeadProps, ref: HTMLElementRefOf<'div'>) {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date(props.route.date) || new Date())
  const [moreOpen, setMoreOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [completeOpen, setCompleteOpen] = useState(false)
  const [moveRouteModalOpen, setMoveRouteModalOpen] = useState(false)
  const [changeDriverOpen, setChangeDriverOpen] = useState(false)

  const intl = useIntl()
  const t = intl.formatMessage

  const moreRef = useRef(null)
  useClickOutside(moreRef, () => setMoreOpen(false), true, true)

  const [patchRoute, { loading: patchLoading }] = GQL.usePatchRoute({
    refetchQueries: ['AllScheduledRoutes', 'RoutingStats'],
    onError: ({ message }) => {
      displayToast(message)
    },
  })

  const [moveRoute] = GQL.useMoveRoute({
    refetchQueries: ['AllScheduledRoutes', 'RoutingStats'],
    variables: {
      id: props.route.id,
      newDate: format(selectedDate, 'yyyy-MM-dd'),
    },
    onCompleted: response => {
      if (response.moveRoute?.route?.date === format(selectedDate, 'yyyy-MM-dd')) {
        displayToast(t({ id: 'routes.move-route.success-message' }), 'success')
      } else {
        if (response.moveRoute?.error) {
          displayToast(response.moveRoute.error)
        } else {
          displayToast(t({ id: 'routes.move-route.error-message' }))
        }
      }
    },
    onError: () => {
      displayToast(t({ id: 'routes.move-route.error-message' }))
    },
  })

  const [completeRouteAndStops] = GQL.useCompleteRouteAndStops({
    refetchQueries: ['AllScheduledRoutes', 'RoutingStats'],
    variables: {
      id: props.route.id,
    },
    onCompleted: () => {
      displayToast(t({ id: 'routes.route.mark-completed.success-message' }), 'success')
      setCompleteOpen(false)
    },
    onError: () => {
      displayToast(t({ id: 'routes.route.mark-completed.error-message' }))
    },
  })

  const [deleteRoute] = GQL.useDeleteRoute({
    update(cache, { data }) {
      cache.modify({
        fields: {
          allRoutes(ref, { readField }) {
            return {
              ...ref,
              edges: ref?.edges?.filter((edge: GQL.RouteNodeEdge) => readField('id', edge?.node!) !== data?.deleteRoute?.deletedId) || [], //data?.planRoutes?.routes?.map(r => ({ node: r!, __typename: 'RouteNodeEdge' }))
            }
          },
          allScheduledRoutes(ref, { readField }) {
            return {
              ...ref,
              edges: ref?.edges?.filter((edge: GQL.RouteNodeEdge) => readField('id', edge?.node!) !== data?.deleteRoute?.deletedId) || [], //data?.planRoutes?.routes?.map(r => ({ node: r!, __typename: 'RouteNodeEdge' }))
            }
          },
          allCylinderGroupOrders(_, { DELETE }) {
            return DELETE
          },
          allUnassignedRoutes(_, { DELETE }) {
            return DELETE
          },
        },
      })
    },
    onCompleted: () => {
      displayToast(t({ id: 'routes.delete-route.success-message' }), 'success')
      setDeleteOpen(false)
    },
    onError: () => {
      displayToast(t({ id: 'routes.delete-route.error-message' }))
      setDeleteOpen(false)
    },
  })

  const [changeDriverOnRoute, { loading: changeDriverLoading }] = GQL.useChangeDriverOnRoute({
    update: (cache, { data }) => {
      cache.writeFragment({
        id: cache.identify({ id: props.route.id, __typename: 'RouteNode' }),
        fragment: GQL.RouteInfo,
        fragmentName: 'RouteInfo',
        data: data?.changeDriverOnRoute?.route,
      })
      cache.writeFragment({
        id: cache.identify({ id: props.route.id, __typename: 'RouteNode' }),
        fragment: GQL.ScheduledRoute,
        fragmentName: 'ScheduledRoute',
        data: data?.changeDriverOnRoute?.route,
      })
    },
    onError: () => {
      displayToast(t({ id: 'routes.change-route-driver.error-message' }))
    },
  })

  function handleRouteDelete(id: string) {
    deleteRoute({ variables: { id } })
  }

  const changeDriver = (driver: string) => {
    changeDriverOnRoute({
      variables: {
        route: props.route.id,
        driver: driver,
      },
    })
    //Set exception on new driver based on route data
    //Set exception on existing driver to vacated?
    //Set new driver on route
  }

  const excludeDrivers = props.routes.map(r => r.driver?.id).filter(d => d !== props.route.driver?.id)
  const useDrivers = props.drivers.filter(d => !excludeDrivers.includes(d.id) && d.active)

  const routeClosedDays = props.route.stops
    ? (
        [
          ...new Set(props.route.stops.map(stop => stop?.customer?.standardOpeningHours).flatMap(openingHours => openingHours?.map(hours => hours?.weekday))),
        ] as GQL.CustomerStandardOpeningHoursWeekday[]
      ).map(weekday => getWeekdayNumber(weekday))
    : []

  return (
    <>
      <ConfirmModal
        isOpen={deleteOpen}
        onRequestClose={() => setDeleteOpen(false)}
        onConfirm={() => {
          handleRouteDelete(props.route.id)
        }}
        onAbort={() => {
          setDeleteOpen(false)
        }}
        title={t({ id: 'common.delete-route' })}
        description={t({ id: 'routes.delete-route-modal.body' })}
      />
      <ConfirmModal
        isOpen={completeOpen}
        onRequestClose={() => setCompleteOpen(false)}
        onConfirm={() => {
          completeRouteAndStops()
        }}
        onAbort={() => {
          setCompleteOpen(false)
        }}
        title={t({ id: 'routes.route.mark-completed' })}
        description={t({ id: 'routes.route.mark-completed.info' })}
      />
      <ChangeDriverModal
        isOpen={changeDriverOpen}
        onRequestClose={() => setChangeDriverOpen(false)}
        route={props.route}
        drivers={useDrivers}
        date={new Date(props.route.date)}
        submit={data => {
          changeDriver(data.driver)
        }}
      />
      {moveRouteModalOpen && (
        <MoveRouteModal
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          onSubmitChangeDate={() => moveRoute()}
          closedDays={routeClosedDays}
          onClose={() => setMoveRouteModalOpen(false)}
        />
      )}
      <PlasmicRouteHead
        root={{ ref }}
        {..._.omit(props, ['route', 'routes', 'drivers'])}
        driver={changeDriverLoading ? <Loader color='gray' /> : props.route.driver?.user.fullName}
        vehicle={props.route.vehicle?.name}
        capacity={props.route.vehicle?.capacity}
        depot={props.route.depot?.name}
        depotReload={props.route.stops?.filter(s => s?.stopType === GQL.StopStopType.Pickup).length}
        status={props.route.status}
        timeSlot={
          <ProgressContainer progress={props.route.driverTimePercentageEffort || 0}>
            <span>{props.route.driverTimePercentageEffort || 0} %</span>
          </ProgressContainer>
        }
        capacitySlot={
          <ProgressContainer progress={props.route.percentageLoad || 0}>
            <span>{props.route.percentageLoad || 0} %</span>
          </ProgressContainer>
        }
        cellLocked={
          patchLoading ? (
            <Loader color='gray' />
          ) : (
            {
              locked: props.route.locked,
              onClick: () => {
                patchRoute({
                  variables: { id: props.route.id, input: { locked: !props.route.locked } },
                })
              },
            }
          )
        }
        moreActions={{
          ref: moreRef,
          'data-testid': 'button-route-more-actions',
          btnMoreActions: {
            onClick: () => setMoreOpen(oldState => !oldState),
          },
          open: moreOpen,
          actionDropdown: (
            <>
              <Link
                data-testid='button-print-route'
                onClick={() => {
                  setMoreOpen(false)
                }}
                to={`/route/pdf/${props.route.id}`}
                target='_blank'
                style={{ width: '100%', height: '100%' }}
              >
                <ActionDropdownLine style={{ width: '100%', height: '100%' }}>Print</ActionDropdownLine>
              </Link>
              {![GQL.RouteStatus.Completed, GQL.RouteStatus.Cancelled].includes(props.route.status) && (
                <>
                  <ActionDropdownLine
                    data-testid='button-change-driver'
                    onClick={() => {
                      setChangeDriverOpen(true)
                      setMoreOpen(false)
                    }}
                  >
                    {t({ id: 'common.change-driver' })}
                  </ActionDropdownLine>
                  {!props.route.locked && !MOVE_ROUTE_DISALLOWED_STATUSES.includes(props.route.status) && (
                    <ActionDropdownLine
                      data-testid='button-move-route'
                      onClick={() => {
                        setMoveRouteModalOpen(true)
                        setMoreOpen(false)
                      }}
                    >
                      {t({ id: 'common.move-route' })}
                    </ActionDropdownLine>
                  )}
                  <ActionDropdownLine
                    data-testid='button-cancel-route'
                    onClick={() => {
                      setDeleteOpen(true)
                      setMoreOpen(false)
                    }}
                  >
                    {t({ id: 'common.delete-route' })}
                  </ActionDropdownLine>
                  <ActionDropdownLine
                    data-testid='button-complete-route'
                    onClick={() => {
                      setCompleteOpen(true)
                      setMoreOpen(false)
                    }}
                  >
                    {t({ id: 'routes.route.mark-completed' })}
                  </ActionDropdownLine>
                </>
              )}
            </>
          ),
        }}
      />
    </>
  )
}

const RouteHead = React.forwardRef(RouteHead_)
export default RouteHead
