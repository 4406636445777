// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type CalEditCapacitysvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function CalEditCapacitysvgIcon(props: CalEditCapacitysvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 7.026 19.27"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M5.297 2.965V1.59A.796.796 0 005.242 0H1.783a.796.796 0 00-.054 1.589v1.375A1.788 1.788 0 000 4.748v11.75c0 .677.378 1.283.988 1.59v.88c0 .166.135.301.302.301h4.446a.302.302 0 00.302-.301v-.88c.61-.306.988-.912.988-1.59V4.748c0-.965-.771-1.754-1.729-1.783z"
        }
      ></path>
    </svg>
  );
}

export default CalEditCapacitysvgIcon;
/* prettier-ignore-end */
