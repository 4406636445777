// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type IconIdsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function IconIdsvgIcon(props: IconIdsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 364 381"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M157.816 3.313c19.567-3.731 39.662-3.742 59.332-.599 10.464 1.672 20.882 3.195 31.208 6.788 16.843 5.86 32.597 14.563 46.846 25.257 23.776 17.842 42.55 41.392 54.361 68.686 14.394 33.262 15.746 67.77 13.347 103.482a521.419 521.419 0 01-6.78 55.806c-2.931 16.577-4.48 41.911-14.071 55.944-8.539 12.494-26.991 13.791-35.574.25-9.118-14.386 3.293-39.029 6.376-54.176 7.553-37.106 13.848-79.745 6.652-117.317C306.955 81.857 244.268 36.35 178.228 42.756c-19.514 1.893-34.598 9.808-52.315 16.985-15.457 6.262-34.558-3.642-30.977-22.444 3.068-16.112 26.729-23 40.163-27.834 7.486-2.693 15.064-4.691 22.717-6.15z"
        }
      ></path>

      <path
        d={
          "M288.113 179.995c-.082 16.904 2.233 44.823-6.487 59.849-7.253 12.499-25.426 14.372-34.61 2.929-9.284-11.568-2.738-31.968-1.876-45.292 1.171-18.109 2.965-36.976-6.874-53.168-14.391-23.683-45.084-32.794-70.025-21.008-35.56 16.804-29.678 53.291-33.897 86.092-5.014 38.987-17.989 77.373-38.576 110.943-7.912 12.901-22.803 23.073-36.275 9.799-14.593-14.379.21-30.735 7.382-44.43 17.923-34.223 26.1-71.065 27.685-109.455 1.374-33.281 13.171-63.03 41.123-82.836 28.492-20.189 66.831-23.107 98.198-7.849 37.165 18.077 54.232 54.5 54.232 94.426z"
        }
      ></path>

      <path
        d={
          "M212.271 185.149c-1.609 47.699-12.608 94.814-32.601 138.167-6.869 14.895-17.836 47.528-35.974 51.214-22.816 4.637-28.582-19.275-20.26-35.094 22.005-41.829 39.321-82.306 44.872-129.841 1.962-16.802-4.463-48.469 15.304-56.316 23.46-9.314 28.659 15.119 28.659 31.87zM81.115 97.419c.723 9.176-8.17 20.023-11.385 28.381a130.096 130.096 0 00-7.649 30.44c-2.771 22.03-1.604 43.723-6.859 65.555-3.662 15.215-9.547 45.594-25.534 52.764-15.806 7.087-31.707-6.918-29.308-23.273 1.255-8.552 6.954-17.18 9.767-25.348 3.745-10.878 6.145-22.126 7.5-33.542 2.489-20.979 1.261-41.701 6.505-62.392 3.88-15.31 10.493-40.966 24.24-50.427 14.064-9.681 32.749 1.062 32.723 17.842zm142.11 283.15c-33.343-1.609-15.857-36.098-8.879-52.367 5.42-12.638 10.042-25.561 14.213-38.661 3.455-10.85 6.466-22.01 19.643-24.075 9.589-1.502 19.707 4.096 23.078 13.291 3.633 9.909-2.156 22.221-5.221 31.658-4.428 13.631-9.614 26.985-15.443 40.077-6.899 15.494-10.544 25.117-27.391 30.077z"
        }
      ></path>
    </svg>
  );
}

export default IconIdsvgIcon;
/* prettier-ignore-end */
