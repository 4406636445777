import React, { useState } from 'react'
import { LanguageContext } from 'context/LanguageContext'
import { IntlProvider } from 'react-intl'
import messages from 'locale/messages'
import { getPreferredLanguage, setPreferredLanguage } from 'locale/util'

export default function IntlContextProvider({ children }) {
  const [language, setLanguage] = useState(() => getPreferredLanguage())

  // Check that the language is valid. If not, fallback to english
  let finalMessages = messages[language]
  if (!finalMessages) {
    finalMessages = messages['en']
  }

  function handleSetLanguage(language) {
    setPreferredLanguage(language)
    setLanguage(language)
  }

  return (
    <LanguageContext.Provider value={[language, handleSetLanguage]}>
      <IntlProvider locale={language} messages={finalMessages}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  )
}
