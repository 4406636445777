// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicModalCommunication, DefaultModalCommunicationProps } from './plasmic/solace_components/PlasmicModalCommunication'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

import * as GQL from 'generated/graphql'
import { omit } from 'lodash'
import PhoneSmsBubble from './PhoneSmsBubble'
import CommunicationRowCustomer from './CommunicationRowCustomer'
import CommunicationRowContact from './CommunicationRowContact'
import ScrollIndicator from './ScrollIndicator'
import { CenteredLoader } from 'components/Loader/LoaderWrappers'
import Loader from 'components/Loader'
import { NoHits } from 'modules/orders/util'
import LabelStatusChip from './LabelStatusChip'
import EmailPreview from './EmailPreview'
import { useAppContext } from 'util/hooks'
import { isLocal } from 'util/env'
import { displayToast } from 'util/toasts'
import { useIntl } from 'react-intl'
// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface ModalCommunicationProps extends Omit<DefaultModalCommunicationProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultModalCommunicationProps altogether and have
// total control over the props for your component.
export interface ModalCommunicationProps extends DefaultModalCommunicationProps {
  customers: GQL.CustomerNode[]
  isLoading: boolean
  totalCount: number
  loadingFetchMore: boolean
  onLoadMoreClick: () => void
  setCustomerIds: React.Dispatch<React.SetStateAction<Set<string>>>
}

type Tab = 'sms' | 'email'

function ModalCommunication_(props: ModalCommunicationProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicModalCommunication to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicModalCommunication are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all ModalCommunicationProps here, but feel free
  // to do whatever works for you.
  const MAX_CHARACTERS_SMS = 160

  const intl = useIntl()
  const t = intl.formatMessage
  const [selectedTab, setSelectedTab] = React.useState<Tab>('sms')
  const [selectedReceipients, setSelectedReceipients] = React.useState<GQL.CustomerContactNode[]>([])
  const [emailTitle, setEmailTitle] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [smsCost] = React.useState(0)
  const { appContext } = useAppContext()
  const distributor = appContext.distributor
  const characterCount = React.useMemo(() => message.length, [message])

  const footerInfo = React.useMemo(() => {
    if (!distributor) return 'Failed to load footer preview.'
    return `${distributor.fullName}, ${distributor.address}`
  }, [distributor])

  const loginLink = React.useMemo(() => {
    if (!distributor) return '#'
    if (isLocal()) {
      return `http://${distributor.appHost}:3002/login`
    }
    return `https://${distributor.appHost}/login`
  }, [distributor])

  const allCustomerContacts = React.useMemo(() => {
    return props.customers.flatMap(customer => customer.contacts).filter(contact => !!contact) as GQL.CustomerContactNode[]
  }, [props.customers])

  const handleRecipientChange = (contact: GQL.CustomerContactNode) => {
    if (selectedReceipients.includes(contact)) return setSelectedReceipients(prev => prev.filter(_contact => _contact.id !== contact.id))
    return setSelectedReceipients(prev => [...prev, contact])
  }

  const handleSelectAll = React.useCallback(() => {
    setSelectedReceipients(allCustomerContacts)
  }, [setSelectedReceipients, allCustomerContacts])

  const handleSelectInvoiceRecipients = React.useCallback(() => {
    setSelectedReceipients(allCustomerContacts.filter(contact => contact.invoiceReceiver))
  }, [setSelectedReceipients, allCustomerContacts])

  const handleDeselectAll = () => {
    setSelectedReceipients([])
  }

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target
    event.target.style.height = ''
    event.target.style.height = event.target.scrollHeight + 2 + 'px'
    setMessage(value)
  }

  const handleSendSms = () => {
    if (!message) return
    if (selectedReceipients.length === 0) return displayToast(t({ id: 'mass-communication.bulk-messaging.missing-recipients' }), 'warning')
    console.warn('Send SMS button not implemented')
  }
  const handleSendEmail = () => {
    if (!message || !emailTitle) return
    if (selectedReceipients.length === 0) return displayToast(t({ id: 'mass-communication.bulk-messaging.missing-recipients' }), 'warning')
    console.warn('Send email button not implemented')
  }

  const handleRemoveCustomer = (customerId: string) => {
    props.setCustomerIds(prev => {
      prev.delete(customerId)
      return new Set([...prev])
    })
  }

  return (
    <PlasmicModalCommunication
      root={{ ref }}
      {...omit(props, 'customers', 'isLoading', 'totalCount', 'loadingFetchMore', 'onLoadMoreClick', 'setCustomerIds')}
      tab={selectedTab}
      tabSms={{ tabOnClick: () => setSelectedTab('sms') }}
      tabEmail={{ tabOnClick: () => setSelectedTab('email') }}
      characterCount={characterCount > 0 ? `${characterCount}` : '0'}
      maxCharacters={MAX_CHARACTERS_SMS}
      recipientCount={selectedReceipients.length > 0 ? selectedReceipients.length : '0'}
      cost={`${smsCost} EUR`}
      phoneComponent={{
        messages: <PhoneSmsBubble style={{ display: message.length <= 0 ? 'none' : 'block' }} labelSmsMessage={message} />,
      }}
      emailContent={
        selectedTab === 'email' ? (
          <EmailPreview
            title={emailTitle}
            message={message}
            logoSrc={distributor?.theme?.logo || 'https://s3-eu-west-1.amazonaws.com/uploaded-static/header-logo-01.jpg'}
            colorPrimary={distributor?.theme?.colorPrimary || '#FA741D'}
            loginLink={loginLink}
            unsubscribeLink='#'
            footerInfo={footerInfo}
          />
        ) : null
      }
      selectAll={{
        onClick: handleSelectAll,
        active: false,
      }}
      selectOnlyInvoiceReceivers={{
        onClick: handleSelectInvoiceRecipients,
        active: false,
      }}
      btnDeselectAll={{
        onClick: handleDeselectAll,
      }}
      btnSendSms={{
        onClick: handleSendSms,
        loading: false,
      }}
      btnSendEmail={{
        onClick: handleSendEmail,
        loading: false,
      }}
      inputEmailTitle={{
        undefinedInput: {
          value: emailTitle,
          name: 'emailTitle',
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => setEmailTitle(event.target.value),
        },
      }}
      inputTextArea={{
        value: message,
        name: 'message',
        onChange: handleTextAreaChange,
        maxLength: selectedTab === 'sms' ? MAX_CHARACTERS_SMS : 3000,
      }}
      customerRows={
        !props.isLoading && props.customers.length > 0 ? (
          [
            props.customers.map(customer => (
              <CommunicationRowCustomer
                key={customer.id}
                btnRemove={{
                  props: {
                    onClick: () => handleRemoveCustomer(customer.id),
                  },
                }}
                name={customer.name}
                contactRows={
                  !!customer.contacts && customer.contacts.length > 0 ? (
                    [
                      customer.contacts.map(
                        contact =>
                          !!contact && (
                            <CommunicationRowContact
                              key={contact.id}
                              name={contact.name}
                              phoneNumber={contact.phone}
                              email={contact.email}
                              invoiceReceiver={contact.invoiceReceiver}
                              checkbox={{
                                props: {
                                  isChecked: selectedReceipients.includes(contact),
                                  onChange: () => handleRecipientChange(contact),
                                  name: 'selectRecipientCheckbox',
                                },
                              }}
                            />
                          )
                      ),
                    ]
                  ) : (
                    <NoHits>
                      <LabelStatusChip icon='error' title='Customer has no contacts' />
                    </NoHits>
                  )
                }
              />
            )),
            <ScrollIndicator
              key='scroll-indicator'
              loaded={props.customers.length.toString()}
              total={props.totalCount?.toString()}
              btnLoadMore={{ ...(props.loadingFetchMore && { children: <Loader color='white' /> }), onClick: props.onLoadMoreClick }}
              tableRow
            />,
          ]
        ) : props.isLoading ? (
          <CenteredLoader sizeAuto>
            <Loader color='grey' size={64} />
          </CenteredLoader>
        ) : (
          <NoHits>
            <LabelStatusChip icon='error' title='Failed to load customers' />
          </NoHits>
        )
      }
    />
  )
}

const ModalCommunication = React.forwardRef(ModalCommunication_)
export default ModalCommunication
