import { QueryOptions, WatchQueryOptions } from '@apollo/client'

const MINUTE = 60000

const CacheConfigs = {
  DEFAULT: {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    pollInterval: 30 * MINUTE,
  } as const satisfies Partial<WatchQueryOptions>,
  ACCURATE_FREQUENT: {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    pollInterval: 5 * MINUTE,
  } as const satisfies Partial<WatchQueryOptions>,
  ACCURATE_ONCE: {
    fetchPolicy: 'cache-and-network',
    pollInterval: 0,
  } as const satisfies Partial<WatchQueryOptions>,
  CACHE_FIRST_ONCE: {
    fetchPolicy: 'cache-first',
    pollInterval: 0,
  } as const satisfies Partial<QueryOptions>,
} as const

export default CacheConfigs
