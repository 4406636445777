// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import * as p from '@plasmicapp/react-web'

import { PlasmicGeneralSettings, DefaultGeneralSettingsProps } from './plasmic/solace_components/PlasmicGeneralSettings'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import Switch from './Switch'
import InputField from './InputField'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface GeneralSettingsProps extends Omit<DefaultGeneralSettingsProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultGeneralSettingsProps altogether and have
// total control over the props for your component.
export interface GeneralSettingsProps extends DefaultGeneralSettingsProps {
  inputCompanyName?: any
  labelContactPerson?: any
  selectContactPersonDiv?: any
  labelDefaultProduct?: any
  selectDefaultProductDiv?: any
  labelCurrency?: any
  selectCurrencyDiv?: any
  labelRoutingProvider?: any
  selectRoutingProvider?: any
  btnCancel?: any
  btnSaveChanges?: any
  inputDefaultTax?: any
  inputTaxDisplayName?: any
  switchShowTemperatures?: p.Flex<typeof Switch>
  switchIncludeNonSensorLocations?: p.Flex<typeof Switch>
  inputTemperatureThreshold?: p.Flex<typeof InputField>
  iconTooltipTemperature?: p.Flex<'div'>
}

function GeneralSettings_(props: GeneralSettingsProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicGeneralSettings to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicGeneralSettings are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all GeneralSettingsProps here, but feel free
  // to do whatever works for you.

  return <PlasmicGeneralSettings root={{ ref }} {...props} />
}

const GeneralSettings = React.forwardRef(GeneralSettings_)
export default GeneralSettings
