// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type CalEditVehiclesvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function CalEditVehiclesvgIcon(props: CalEditVehiclesvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 22.505 13.8"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M1.622 0h12.665c.892 0 1.622.739 1.622 1.63v1.494l3.308.008a.513.513 0 01.416.208l2.733 3.788a.522.522 0 01.104.176l.008.032a.51.51 0 01.024.216v3.244a1.34 1.34 0 01-1.334 1.334h-.208c-.271.96-1.154 1.67-2.197 1.67s-1.934-.71-2.205-1.67H7.615A2.3 2.3 0 015.41 13.8a2.29 2.29 0 01-2.197-1.67h-1.59A1.634 1.634 0 01.001 10.5V1.63A1.634 1.634 0 011.622 0zm6.153 4.123a.511.511 0 00.535.487h5.02a.511.511 0 10-.003-1.023H8.31a.513.513 0 00-.535.536zm8.526.927v2.493c0 .282.229.51.511.51h3.516a.512.512 0 00.424-.807l-1.774-2.492a.513.513 0 00-.416-.216h-1.75a.514.514 0 00-.511.512zm-10.332.83a.511.511 0 10.048 1.023h7.313a.511.511 0 10-.003-1.023H5.969zm-.559 4.364c-.704 0-1.263.555-1.263 1.263v.008a1.257 1.257 0 001.263 1.263c.696 0 1.256-.548 1.271-1.24v-.031a1.261 1.261 0 00-1.271-1.263zm12.082 1.263a1.268 1.268 0 102.534 0c0-.707-.559-1.263-1.263-1.263a1.26 1.26 0 00-1.271 1.263z"
        }
      ></path>
    </svg>
  );
}

export default CalEditVehiclesvgIcon;
/* prettier-ignore-end */
