// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ToastErrorsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ToastErrorsvgIcon(props: ToastErrorsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 32.727 32.727"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M16.697 0C7.66 0 .333 7.326.333 16.364c0 9.037 7.326 16.364 16.364 16.364 9.037 0 16.364-7.326 16.364-16.364S25.734 0 16.697 0zm6.212 20.511a.528.528 0 010 .748l-1.316 1.316a.528.528 0 01-.748 0l-4.148-4.148-4.148 4.148a.528.528 0 01-.748 0l-1.316-1.316a.528.528 0 010-.748l4.148-4.148-4.148-4.148a.528.528 0 010-.748l1.316-1.316a.528.528 0 01.748 0l4.148 4.148 4.148-4.148a.528.528 0 01.748 0l1.316 1.316a.528.528 0 010 .748l-4.148 4.148 4.148 4.148z"
        }
      ></path>
    </svg>
  );
}

export default ToastErrorsvgIcon;
/* prettier-ignore-end */
