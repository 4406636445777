import { normalizeTuple, heuristicIsATimeString, fullRegexStrict, twoDigitRegex } from './util'
import { format as dateFnsFormat, parseISO } from 'date-fns'

type TimeType = 'hours' | 'hour' | 'minutes' | 'minute' | 'seconds' | 'second' | 'nanoseconds' | 'nanosecond'

function isTime(t: any): t is Time {
  return Time.isTime(t)
}

// A Time object is a simple quadruplet
// [hour, minute, second, nanosecond]
export class Time {
  private _hours: number
  private _minutes: number
  private _seconds: number
  private _nanoseconds: number
  private _valid: boolean

  constructor(hours: number, minutes: number, seconds: number, nanoseconds: number, valid = true) {
    this._hours = hours
    this._minutes = minutes
    this._seconds = seconds
    this._nanoseconds = nanoseconds
    this._valid = valid
  }

  format(str: string | null = null) {
    if (!str) {
      str = 'HH:mm'
    }

    let finalString =
      this._hours.toString().padStart(2, '0') +
      ':' +
      this._minutes.toString().padStart(2, '0') +
      ':' +
      this._seconds.toString().padStart(2, '0') +
      '.' +
      this._nanoseconds.toString().padStart(9, '0')

    // Use date-fns as a helper here
    const isoTime = parseISO(`0000-01-01T${finalString}`)

    return dateFnsFormat(isoTime, str)
  }

  isValid() {
    return this._valid
  }

  static typeStringToIndex(type: TimeType) {
    switch (type) {
      case 'hours':
      case 'hour':
        return 0
      case 'minutes':
      case 'minute':
        return 1
      case 'seconds':
      case 'second':
        return 2
      case 'nanoseconds':
      case 'nanosecond':
        return 3
      default:
        throw Error('Unknown type ' + type)
    }
  }

  add(amount: number, type: TimeType) {
    const array = this.toArray()
    array[Time.typeStringToIndex(type)] += amount

    return Time.fromArray(array)
  }

  sub(amount: number, type: TimeType) {
    const array = this.toArray()
    array[Time.typeStringToIndex(type)] -= amount

    return Time.fromArray(array)
  }

  isAfter(_other: Time) {
    const array = this.toArray()
    const other = time(_other)
    if (!other.isValid()) return true

    const otherArray = other.toArray()

    return array[0] >= otherArray[0] && array[1] >= otherArray[1] && array[2] >= otherArray[2] && array[3] >= otherArray[3]
  }

  isBefore(_other: Time) {
    const array = this.toArray()
    const other = time(_other)
    if (!other.isValid()) return true

    const otherArray = other.toArray()

    return array[0] <= otherArray[0] && array[1] <= otherArray[1] && array[2] <= otherArray[2] && array[3] <= otherArray[3]
  }

  setHours(hours: number) {
    const array = this.toArray()

    array[0] = hours
    return Time.fromArray(array)
  }

  setMinutes(minutes: number) {
    const array = this.toArray()

    array[1] = minutes
    return Time.fromArray(array)
  }

  setSeconds(seconds: number) {
    const array = this.toArray()

    array[2] = seconds
    return Time.fromArray(array)
  }

  setNanoSeconds(nanoSeconds: number) {
    const array = this.toArray()

    array[3] = nanoSeconds
    return Time.fromArray(array)
  }

  hours() {
    return this._hours
  }

  minutes() {
    return this._minutes
  }

  seconds() {
    return this._seconds
  }

  nanoseconds() {
    return this._seconds
  }

  static isTime(time: Time) {
    return time.constructor === Time
  }

  static fromArray(array: (number | string)[]) {
    const normalized = normalizeTuple(array)
    return new Time(normalized[0], normalized[1], normalized[2], normalized[3])
  }

  static fromDate(date: Date) {
    return new Time(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds())
  }

  toArray() {
    return [this._hours, this._minutes, this._seconds, this._nanoseconds]
  }
}

export function time(initialValue: number | string | string[] | Time) {
  const parsed = parseTimeValue(initialValue)
  if (!parsed) {
    return new Time(0, 0, 0, 0, false)
  } else {
    return new Time(parsed[0], parsed[1], parsed[2], parsed[3], true)
  }
}

/**
 * This function attempts to parse a value into it's constituent hours, minutes, seconds and nanoseconds.
 *
 * To do this, it performs the following steps:
 *  * If the value is a Time object, return it.
 *  * If the value is a number, parse it as the number of seconds since midnight.
 *  * If the value is a string:
 *    - If the value is an integer and is 2 digits long, and between 0 and 23, parse it as the number of hours since midnight.
 *    - If the value is an integer and is 4 digits long, and between 0000 and 2359, parse it as HHmm
 *    - If the value is an integer is 6 digits long, and between 000000 and 235959, parse it as HHmmss
 *    - Otherwise, parse it as a special string, using the `parseText` helper.
 *
 * @param {String | Number} value
 * @returns {Array} Tuple with [hours, minutes, seconds, nanoseconds]
 */
function parseTimeValue(value: number | string | string[] | Time): number[] | null {
  if (value === null || value === undefined) return null

  if (isTime(value)) {
    // If the value is a Time object, we simply return the internal values using toArray
    return value.toArray()
  } else if (value instanceof Date) {
    return [value.getHours(), value.getMinutes(), value.getSeconds(), value.getMilliseconds() * 1e6]
  } else if (Array.isArray(value)) {
    // If the value is a tuple, we normalize the tuple
    return normalizeTuple(value)
  } else if (typeof value === 'number' && isFinite(value)) {
    const hours = Math.floor(value / 3600) % 24
    const minutes = Math.floor(value / 60) % 60
    const seconds = Math.floor(value) % 60
    // The toFixed-hack is to ensure we don't get weird rounding errors
    const nanosecond: number = parseFloat((value - Math.floor(value)).toFixed(9)) * 1e9
    return [hours, minutes, seconds, nanosecond]
  } else if (typeof value === 'string') {
    if (heuristicIsATimeString(value)) {
      let match = value.match(fullRegexStrict)
      if (match) {
        let [rest, nanoseconds] = match[0].split('.')
        nanoseconds = nanoseconds.padEnd(9, '0')
        const [hours, minutes, seconds] = rest.split(':')
        return normalizeTuple([hours, minutes, seconds, nanoseconds])
      }

      match = value.match(twoDigitRegex)
      if (match) {
        return normalizeTuple(match)
      }
    }
  }
  return null
}
