import * as React from 'react'
import { PlasmicLiveMonitorData, DefaultLiveMonitorDataProps } from './plasmic/solace_components/PlasmicLiveMonitorData'
import { Flex, HTMLElementRefOf } from '@plasmicapp/react-web'
import Tooltip from 'components/Tooltip'

interface LiveMonitorDataProps extends DefaultLiveMonitorDataProps {
  triggerIcon?: Flex<React.ElementType>
  tooltip?: string
}

function LiveMonitorData_(props: LiveMonitorDataProps, ref: HTMLElementRefOf<'div'>) {
  const { tooltip, triggerIcon, ...plasmicProps } = props
  return (
    <PlasmicLiveMonitorData
      root={{ ref }}
      triggerIcon={
        triggerIcon
          ? triggerIcon
          : {
              render: (triggerIconProps, Component) =>
                tooltip ? (
                  <Tooltip content={tooltip} delay={0} arrow={false} placement='bottom-end'>
                    <Component {...triggerIconProps} />
                  </Tooltip>
                ) : (
                  <Component {...triggerIconProps} />
                ),
            }
      }
      {...plasmicProps}
    />
  )
}

const LiveMonitorData = React.forwardRef(LiveMonitorData_)
export default LiveMonitorData
