// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ArrowDownScrollsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ArrowDownScrollsvgIcon(props: ArrowDownScrollsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 12 12"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M11.6 5.293a.999.999 0 00-1.414 0L7 8.478v-7.37a1 1 0 10-2 0v7.37L1.814 5.293A.999.999 0 10.4 6.707l4.892 4.891a1.01 1.01 0 00.708.294 1.01 1.01 0 00.708-.294L11.6 6.707a1 1 0 000-1.414z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ArrowDownScrollsvgIcon;
/* prettier-ignore-end */
