import React from 'react'
import styled from 'styled-components'
import IntlTelInput, { CountryData } from 'react-intl-tel-input'

import { useCountryCodeFromIp } from 'util/hooks'

import 'react-intl-tel-input/dist/main.css'

interface IWrapperProps {
  height?: string
  width?: string
  focusColor?: string
  borderColor?: string
  error?: string
}

const Wrapper = styled.div<IWrapperProps>`
  width: ${props => (props.width ? props.width : '100%')};

  .intl-tel-input {
    width: ${props => (props.width ? props.width : '100%')};
  }
  .styled-intl-tel-input {
    box-sizing: border-box;
    background: ${props => props.theme.colors.primaryDark};
    border-color: ${props => props.theme.colors.gray2};
    border-radius: 4px;
    width: ${props => (props.width ? props.width : '100%')};
    height: ${props => (props.height ? props.height : '35px')};
    font-size: 16px;
    padding-left: 1rem;
    outline: none;
    font-weight: 400;
    color: #ffffff;
    border: 1px solid ${props => (props.borderColor ? props.borderColor : '#ffffff1a')};
    transition: border 0.3s ease-in-out;
    ${props =>
      !!props.error
        ? `
      color: ${props.theme.colors.red};
      border-color: ${props.theme.colors.red} !important;`
        : ''}
    :focus {
      border: 1px solid ${props => (props.focusColor ? props.focusColor : '#2c97de')};
    }
    ::placeholder {
      color: #ffffff22;
    }
  }
  .intl-tel-input .country-list {
    background: ${props => props.theme.colors.primaryDark};
    border-color: ${props => props.theme.colors.gray2};
    border-radius: 4px !important;
    width: 100% !important;
    font-size: 16px !important;
    padding-left: 0rem !important;
    outline: none !important;
    color: white !important;
    font-weight: 400 !important;
    box-sizing: border-box !important;
    overflow-x: hidden !important;
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
  }
  .intl-tel-input .country-list::-webkit-scrollbar {
    display: none !important;
  }
  .intl-tel-input .country-list .country:hover {
    background: rgba(255, 255, 255, 0.075) !important;
  }
  .intl-tel-input .country-list .country.highlight {
    background: rgba(255, 255, 255, 0.2) !important;
  }
`

const InputError = styled.div`
  /* margin-top: 0.5rem; */
  font-size: 12px;
  color: red;
`

interface IPhoneInputProps {
  id?: string
  width?: string
  height?: string
  focusColor?: string
  borderColor?: string
  placeholder?: string
  error?: any
  ref?: any
  name?: string
  value?: string
  defaultValue?: string
  format?: boolean
  onPhoneNumberBlur?: (
    isValid: boolean,
    value: string,
    seletedCountryData: CountryData,
    fullNumber: string,
    extension: string,
    event: React.FocusEvent<HTMLInputElement, Element>
  ) => void
  onPhoneNumberChange?: (isValid: boolean, value: string, seletedCountryData: CountryData, fullNumber: string, extension: string) => void
  onSelectFlag?: (currentNumber: string, seletedCountryData: CountryData, fullNumber: string, isValid: boolean) => void
  onPhoneNumberChangeRaw?: (e: any) => void
}

const PhoneInput: React.FC<IPhoneInputProps> = React.forwardRef(
  (
    {
      id,
      width,
      height,
      focusColor,
      borderColor,
      placeholder,
      error,
      name,
      value,
      defaultValue,
      format,
      onPhoneNumberBlur,
      onSelectFlag,
      onPhoneNumberChange,
      onPhoneNumberChangeRaw,
      ...props
    },
    ref
  ) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const countryCode = useCountryCodeFromIp()

    return (
      <Wrapper width={width} height={height} focusColor={focusColor} borderColor={borderColor} error={error}>
        <IntlTelInput
          fieldId={id}
          key={countryCode}
          defaultCountry={countryCode}
          fieldName={name}
          inputClassName='styled-intl-tel-input'
          placeholder={placeholder}
          onPhoneNumberBlur={onPhoneNumberBlur}
          onSelectFlag={onSelectFlag}
          onChange={onPhoneNumberChangeRaw}
          onPhoneNumberChange={onPhoneNumberChange}
          preferredCountries={['gb', 'se', 'za', 'fi', 'pt', 'ph', 'au']}
          value={value}
          format={format ? format : false}
          defaultValue={defaultValue}
          ref={ref as any}
        />
        {error && <InputError>{error.message}</InputError>}
      </Wrapper>
    )
  }
)

export default PhoneInput
