import React, { useState } from 'react'
import styled from 'styled-components'
import { animated, useSpring } from 'react-spring'
import useMeasure from 'react-use-measure'

interface IWrapper {
  $overflow: boolean
}

const Wrapper = styled(animated.div)<IWrapper>`
  position: relative;
  width: inherit;
  overflow: ${props => (props.$overflow ? 'visible' : 'hidden')};
`

const InnerWrapper = styled.div`
  position: relative;
  width: inherit;
`

function Collapse({ closedHeight = 0, open = true, children }: { children: any; closedHeight?: number; open?: boolean }) {
  const [ref, { height }] = useMeasure()
  const [rested, setRested] = useState(false)

  const props = useSpring({
    height: open ? height : closedHeight,
    onRest: e => {
      if (open) {
        setRested(true)
      } else {
        setRested(false)
      }
    },
  })

  return (
    <Wrapper style={props} $overflow={open && rested}>
      <InnerWrapper ref={ref}>{children}</InnerWrapper>
    </Wrapper>
  )
}

export default Collapse
