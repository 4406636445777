import React, { useEffect, useRef } from 'react'

export default function DetectClickOutside({ onClickOutside, active, children }) {
  const node = useRef(null)

  function handleClick(e) {
    if (!active) {
      return
    }

    if (node.current && node.current.contains(e.target)) {
      return
    }

    onClickOutside(e)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  })

  return <div ref={node}>{children}</div>
}
