import { useState } from 'react'

interface CurrencyInputProps {
  currency: string
  classNames?: string
  name?: string
  editClassNames?: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
}

export function CurrencyInput(props: CurrencyInputProps) {
  const [isEditing, setIsEditing] = useState(false)

  const toCurrency = (value: string) => {
    const formatter = new Intl.NumberFormat(navigator.language || 'en-GB', {
      style: 'currency',
      currency: props.currency,
      currencyDisplay: 'symbol',
      maximumFractionDigits: 2,
    })

    return formatter.format(parseFloat(value))
  }

  return (
    <>
      {isEditing ? (
        <input
          className={`${props.editClassNames} ${props.classNames}`}
          type='number'
          value={props.value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event)}
          onBlur={() => {
            if (!!props.onBlur) {
              props.onBlur()
            }
            setIsEditing(prev => !prev)
          }}
          name={props.name}
          min={0}
        />
      ) : (
        <input
          className={props.classNames}
          type='text'
          value={props.value ? toCurrency(props.value) : toCurrency('0')}
          onFocus={() => setIsEditing(prev => !prev)}
          name={props.name}
          readOnly
        />
      )}
    </>
  )
}
