// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type LogoutsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function LogoutsvgIcon(props: LogoutsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 512 512"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M255.2 468.6H63.8c-11.7 0-21.3-9.5-21.3-21.3V64.6c0-11.7 9.5-21.3 21.3-21.3h191.4c11.7 0 21.3-9.5 21.3-21.3S267 .7 255.2.7H63.8C28.6.8 0 29.5 0 64.6v382.7c0 35.2 28.6 63.8 63.8 63.8h191.4c11.7 0 21.3-9.5 21.3-21.3-.1-11.7-9.6-21.2-21.3-21.2z"
        }
      ></path>

      <path
        d={
          "M505.7 240.9L376.4 113.3c-8.3-8.2-21.8-8.2-30.1.2-8.2 8.4-8.2 21.8.2 30.1l92.4 91.2H191.4c-11.7 0-21.3 9.5-21.3 21.3s9.5 21.3 21.3 21.3H439l-92.4 91.2c-8.4 8.2-8.5 21.7-.2 30.1 4.2 4.2 9.7 6.3 15.1 6.3s10.8-2 14.9-6.1l129.3-127.6c4.1-4 6.3-9.4 6.3-15.1 0-5.9-2.3-11.3-6.3-15.3z"
        }
      ></path>
    </svg>
  );
}

export default LogoutsvgIcon;
/* prettier-ignore-end */
