import React, { useContext, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import * as GQL from 'generated/graphql'
import HeaderContext from 'context/HeaderContext'
import InventoryOverview from './overview'
import { useIntl } from 'react-intl'
import SubnavButton from 'plasmic/SubnavButton'
import InventoryAdjustmentsHistory from './history/InventoryAdjustmentsHistory'
import { useAppContext } from 'util/hooks'

const Wrapper = styled.div`
  height: 100%;
`

const Inventory: React.FC = () => {
  const OVERVIEW_PATH = '/inventory/overview'
  const HISTORY_PATH = '/inventory/history'
  const intl = useIntl()
  const t = intl.formatMessage
  const { appContext } = useAppContext()
  const headerContext = useContext(HeaderContext)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    headerContext.setIsSubHeaderVisible(true)
    headerContext.setLeftSubHeaderGroup(
      <React.Fragment>
        <SubnavButton active={location.pathname === OVERVIEW_PATH} title={t({ id: 'inventory.overview' })} onClick={() => navigate(OVERVIEW_PATH)} />
        <SubnavButton active={location.pathname === HISTORY_PATH} title={t({ id: 'inventory.history' })} onClick={() => navigate(HISTORY_PATH)} />
      </React.Fragment>
    )
    headerContext.setRightSubHeaderGroup(<></>)

    return () => {
      headerContext.setIsSubHeaderVisible(false)
      headerContext.setLeftSubHeaderGroup(<></>)
      headerContext.setRightSubHeaderGroup(<></>)
    }
    // eslint-disable-next-line
  }, [t, location, navigate])

  if (appContext.distributor?.inventoryAccess === GQL.InventoryAccess.NoAccess) {
    return <Navigate to='/' />
  }

  return (
    <Wrapper>
      <Routes>
        <Route path='/overview' element={<InventoryOverview />} />
        <Route path='/history' element={<InventoryAdjustmentsHistory />} />
        <Route path='/' element={<Navigate to='/inventory/overview' />} />
      </Routes>
    </Wrapper>
  )
}

export default Inventory
