import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useAppContext, useCustomerContext } from 'util/hooks'
import { format, parseISO, isSameYear } from 'date-fns'
import * as GQL from 'generated/graphql'

import EstimatedEmpty from 'components/Table/Cells/EstimatedEmpty'
import StockCell from 'components/Table/Cells/StockCell'
import CellPrice from 'plasmic/CellPrice'
import FlexTable from 'plasmic/FlexTable'
import TableHeadTitle from 'plasmic/TableHeadTitle'
import TableRow from 'plasmic/TableRow'
import CellCustomer from 'plasmic/CellCustomer'
import CellStatus from 'plasmic/CellStatus'
import CellEstimatedEmpty from 'plasmic/CellEstimatedEmpty'
import CellInventory from 'plasmic/CellInventory'
import CellOrderedBy from 'plasmic/CellOrderedBy'
import CellProduct from 'plasmic/CellProduct'
import CellDateOrdered from 'plasmic/CellDateOrdered'
import CellDeliveryWindow from 'plasmic/CellDeliveryWindow'
import LabelStatusChip from 'plasmic/LabelStatusChip'
import { VerifyExchangeStock } from 'modules/inventory'
import { NoHits, ProductLabel, formatPrice, getRowColor } from '../util'
import { getAlerts, getOrderMethodForOrder, getOrderPaymentStatus } from './util'
import CellPayment from 'plasmic/CellPayment'
import CellOrderMethod from 'plasmic/CellOrderMethod'
import { StyledOrderMethodButton } from 'modules/customers/CustomersTable'
import {
  ESTIMATED_EMPTY_SORT_KEY,
  FIRST_POSSIBLE_DELIVERY_SORT_KEY,
  ORDERED_BY_SORT_KEY,
  ORDER_METHOD_COLORS,
  ORDER_METHOD_SORT_KEY,
  ORDER_METHOD_TRANSLATIONS,
  PRICE_SORT_KEY,
} from 'modules/customers/consts'
import { CenteredLoader } from 'components/Loader/LoaderWrappers'
import Loader from 'components/Loader'
import { CustomerDrawerListSource } from 'context/CustomerContext'
import { DATE_ORDERED_SORT_KEY } from './consts'
import ScrollIndicator from 'plasmic/ScrollIndicator'

interface Props {
  cylinderGroupOrders: GQL.CylinderGroupOrderNode[]
  totalCount: number
  loadMore: () => void
  loadingFetchMore: boolean
  setVerifyExchangeInfo: (info: VerifyExchangeStock) => void
  lastMovedRowId: string | undefined
  loading: boolean
  setLastMovedRowId: (lastMovedRowId?: string) => void
  onOrderStatusClick: (event: React.MouseEvent<HTMLButtonElement>, fromElement: GQL.CylinderGroupOrderNode | GQL.CylinderGroupNode) => void
  onSortClick: (sortKey: string) => void
  getSortIcon: (sortKey: string) => 'upActive' | 'downActive' | undefined
}

const OrderPlacedTable: React.FC<Props> = props => {
  const navigate = useNavigate()
  const intl = useIntl()
  const t = intl.formatMessage

  const { appContext } = useAppContext()
  const distributor = appContext.distributor
  const [expanded, setExpanded] = useState(true)

  const { setSource, setCustomersContext } = useCustomerContext()

  let visibleColumns = ['orderedBy', 'product', 'price', 'payment', 'status', 'estimatedEmpty', 'deliveryWindow', 'inventory', 'dateOrdered', 'orderMethod']

  if (!distributor?.stockEnabled) {
    visibleColumns = visibleColumns.filter(column => column !== 'inventory')
  }

  return (
    <>
      <FlexTable
        id='orders-placed-table'
        data-testid='orders-placed-table'
        noCheckbox
        collapsed={!expanded}
        children={
          <TableHeadTitle
            style={{ cursor: 'pointer' }}
            onClick={() => setExpanded(old => !old)}
            color='purple'
            title='Orders Placed'
            count={`(${props.totalCount || props.cylinderGroupOrders.length})`}
          />
        }
        headPrice={{ onClick: () => props.onSortClick(PRICE_SORT_KEY), sorting: props.getSortIcon(PRICE_SORT_KEY) }}
        headEstimatedEmpty={{ onClick: () => props.onSortClick(ESTIMATED_EMPTY_SORT_KEY), sorting: props.getSortIcon(ESTIMATED_EMPTY_SORT_KEY) }}
        headOrderedBy={{ onClick: () => props.onSortClick(ORDERED_BY_SORT_KEY), sorting: props.getSortIcon(ORDERED_BY_SORT_KEY) }}
        headDateOrdered={{ onClick: () => props.onSortClick(DATE_ORDERED_SORT_KEY), sorting: props.getSortIcon(DATE_ORDERED_SORT_KEY) }}
        headDeliveryWindow={{
          onClick: () => props.onSortClick(FIRST_POSSIBLE_DELIVERY_SORT_KEY),
          sorting: props.getSortIcon(FIRST_POSSIBLE_DELIVERY_SORT_KEY),
        }}
        headOrderMethod={{ onClick: () => props.onSortClick(ORDER_METHOD_SORT_KEY), sorting: props.getSortIcon(ORDER_METHOD_SORT_KEY) }}
        headProduct={{ hideSorting: true }}
        headStatus={{ hideSorting: true }}
        headPayment={{ hideSorting: true }}
        rows={
          <>
            {props.cylinderGroupOrders.map(cylinderGroupOrder => (
              <TableRow
                noCheckbox
                data-testid='order-row'
                color={getRowColor(appContext, 'purple', getAlerts(cylinderGroupOrder))}
                excite={cylinderGroupOrder.id === props.lastMovedRowId}
                key={cylinderGroupOrder.id + 'OrderPlaced'}
                sticky={
                  <CellCustomer
                    alerts={getAlerts(cylinderGroupOrder)}
                    temperature={cylinderGroupOrder.cylinderGroup.customer.temperatureGroup?.temperature?.toString()}
                    onClick={() => {
                      setSource(CustomerDrawerListSource.PLACED_ORDERS)
                      setCustomersContext(props.cylinderGroupOrders.map(cgo => cgo.cylinderGroup.customer))
                      navigate(`?customer=${cylinderGroupOrder.cylinderGroup?.customer.id}`)
                    }}
                    name={cylinderGroupOrder.cylinderGroup?.customer.name}
                  />
                }
                scrollCells={
                  <>
                    <CellProduct contentWrapper={<ProductLabel cylinderGroupOrder={cylinderGroupOrder} />} />
                    <CellPrice
                      price={formatPrice(cylinderGroupOrder.totalPrice, cylinderGroupOrder.currency || appContext.distributor?.defaultCurrency || 'USD')}
                    />
                    <CellPayment
                      noInvoice={getOrderPaymentStatus(cylinderGroupOrder) === 'noInvoice' || !cylinderGroupOrder.paymentSetting?.invoiceUrl}
                      paymentStatusChip={{
                        status: getOrderPaymentStatus(cylinderGroupOrder),
                      }}
                      btnInvoice={{
                        onClick: () => {
                          if (cylinderGroupOrder.paymentSetting?.invoiceUrl) {
                            window.open(cylinderGroupOrder.paymentSetting?.invoiceUrl, '_blank')
                          }
                        },
                      }}
                    />
                    <CellStatus fromElement={cylinderGroupOrder} onOrderStatusClick={props.onOrderStatusClick} editable />
                    <CellEstimatedEmpty content={<EstimatedEmpty cylinderGroup={cylinderGroupOrder.cylinderGroup} displayThreshold={false} />} />
                    {visibleColumns.includes('inventory') && <CellInventory content={<StockCell cylinderGroup={cylinderGroupOrder.cylinderGroup} />} />}
                    <CellOrderedBy content={cylinderGroupOrder.orderedBy} title={cylinderGroupOrder.orderedBy} />
                    <CellDateOrdered
                      content={
                        isSameYear(new Date(), new Date(cylinderGroupOrder.createdAt))
                          ? format(parseISO(cylinderGroupOrder.createdAt), 'dd. MMM')
                          : format(parseISO(cylinderGroupOrder.createdAt), 'dd. MMM yy')
                      }
                    />
                    <CellDeliveryWindow cylinderGroupOrder={cylinderGroupOrder} />
                    <CellOrderMethod
                      content={
                        <StyledOrderMethodButton color={ORDER_METHOD_COLORS[getOrderMethodForOrder(cylinderGroupOrder)]}>
                          <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {t({ id: ORDER_METHOD_TRANSLATIONS[getOrderMethodForOrder(cylinderGroupOrder)] })}
                          </div>
                        </StyledOrderMethodButton>
                      }
                    />
                  </>
                }
              />
            ))}
            {!props.loading && props.cylinderGroupOrders.length === 0 && (
              <NoHits>
                <LabelStatusChip icon='check' title='No orders placed' />
              </NoHits>
            )}
            {props.loading && (
              <CenteredLoader sizeAuto>
                <Loader color='gray6' size={50} />
              </CenteredLoader>
            )}
          </>
        }
        visibleColumns={visibleColumns}
      />
      {!props.loading && props.cylinderGroupOrders.length > 0 && (
        <ScrollIndicator
          tableRow
          loaded={props.cylinderGroupOrders.length}
          total={props.totalCount}
          btnLoadMore={{ ...(props.loadingFetchMore && { children: <Loader color='white' /> }), onClick: props.loadMore }}
        />
      )}
    </>
  )
}

export default OrderPlacedTable
