import AlertSettings from 'plasmic/AlertSettings'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import AlertRow from 'plasmic/AlertRow'
import Checkbox from 'plasmic/Checkbox'
import * as GQL from 'generated/graphql'
import FullLoader from 'components/Loader/FullLoader'
import { displayToast } from 'util/toasts'
import { FullLoaderWrapper } from 'components/Loader/LoaderWrappers'
import CacheConfigs from 'util/cacheConfig'
import SettingsContentWrapper from 'plasmic/SettingsContentWrapper'

export default function SettingsAlerts() {
  const [notSaved, setNotSaved] = useState<boolean>(false)
  const [selected, setSelected] = useState('')
  const [listenerId, setListenerId] = useState('')
  const [topics, setTopics] = useState<(string | undefined)[]>([])

  const intl = useIntl()
  const t = intl.formatMessage

  const [createNotificationListener] = GQL.useCreateNotificationListener({
    refetchQueries: ['MyNotificationListeners'],
  })

  const [editNotificationListener] = GQL.useEditNotificationListener({
    refetchQueries: ['MyNotificationListeners'],
    onCompleted: () => {
      // displayToast('Notifications changed successfully!', 'success')
    },
    onError: error =>
      displayToast('Error has occured when updating notifications! We have received notification about the error and will fix as soon as possible.'),
  })
  const { loading: loadingListeners } = GQL.useMyNotificationListeners({
    ...CacheConfigs.ACCURATE_ONCE,
    onError: error => displayToast('Error occured loading notifications! We have received notification about the error and will fix as soon as possible.'),
    onCompleted: data => {
      const haveListener = data.myNotificationListeners?.some(e => e?.id)
      if (!haveListener) {
        createNotificationListener()
      } else {
        setListenerId(data.myNotificationListeners?.find(e => e?.id)?.id || '')
        setSelected(data.myNotificationListeners?.find(e => true)?.backendHandler?.toLowerCase() || '')
        setTopics(data.myNotificationListeners?.find(() => true)?.topics?.map(e => e?.resource?.name) || [])
      }
    },
  })

  // const {data: feedData} = GQL.useAllDistributorNotifications()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveNotifications = () => {
    setNotSaved(false)
    editNotificationListener({
      variables: {
        id: listenerId,
        backend: selected.toUpperCase() as GQL.NotificationBackendHandler,
        topics: [
          ...topics.map(e => {
            return { name: e }
          }),
        ],
      },
    })
  }

  useEffect(() => {
    if (notSaved) {
      saveNotifications()
    }
  }, [notSaved, saveNotifications])

  return (
    <SettingsContentWrapper
      settingsTitle={t({ id: 'settings.alerts' })}
      headerButtons={<></>}
      content={
        <>
          {loadingListeners && (
            <FullLoaderWrapper>
              <FullLoader color='white' />
            </FullLoaderWrapper>
          )}
          <AlertSettings
            notificationToggle={{
              selected,
              sms: {
                props: {
                  onClick: () => {
                    setSelected('sms')
                    setNotSaved(true)
                  },
                },
              },
              email: {
                props: {
                  onClick: () => {
                    setSelected('email')
                    setNotSaved(true)
                  },
                },
              },
              both: { props: { onClick: () => displayToast('Not possible right now! Feature coming soon.', 'warning') } },
            }}
            rows={
              <>
                <AlertRow
                  title=''
                  children='Alert when customer started depleting'
                  checkbox={
                    <Checkbox
                      isChecked={topics.includes('nrn:clients.customer:started_depleting')}
                      onChange={(e: any) => {
                        if (e) {
                          setTopics(prevTopics => [...prevTopics, 'nrn:clients.customer:started_depleting'])
                        } else {
                          setTopics(prevTopics => [...prevTopics.filter(e => e !== 'nrn:clients.customer:started_depleting')])
                        }
                        setNotSaved(true)
                      }}
                    >
                      Started depleting
                    </Checkbox>
                  }
                />
                <AlertRow
                  title=''
                  children='Alert when customer goes empty'
                  checkbox={
                    <Checkbox
                      isChecked={topics.includes('nrn:clients.customer:stopped_depletion')}
                      onChange={(e: any) => {
                        if (e) {
                          setTopics(prevTopics => [...prevTopics, 'nrn:clients.customer:stopped_depletion'])
                        } else {
                          setTopics(prevTopics => [...prevTopics.filter(e => e !== 'nrn:clients.customer:stopped_depletion')])
                        }
                        setNotSaved(true)
                      }}
                    >
                      Empty
                    </Checkbox>
                  }
                />
                <AlertRow
                  title=''
                  children='Alert when customer has ordered refill'
                  checkbox={
                    <Checkbox
                      isChecked={topics.includes('nrn:clients.customer:ordered')}
                      onChange={(e: any) => {
                        if (e) {
                          setTopics(prevTopics => [...prevTopics, 'nrn:clients.customer:ordered'])
                        } else {
                          setTopics(prevTopics => [...prevTopics.filter(e => e !== 'nrn:clients.customer:ordered')])
                        }
                        setNotSaved(true)
                      }}
                    >
                      Ordered
                    </Checkbox>
                  }
                />
                <AlertRow
                  title=''
                  children='Alert when order is confirmed'
                  checkbox={
                    <Checkbox
                      isChecked={topics.includes('nrn:clients.distributor:confirmed_order')}
                      onChange={(e: any) => {
                        if (e) {
                          setTopics(prevTopics => [...prevTopics, 'nrn:clients.distributor:confirmed_order'])
                        } else {
                          setTopics(prevTopics => [...prevTopics.filter(e => e !== 'nrn:clients.distributor:confirmed_order')])
                        }
                        setNotSaved(true)
                      }}
                    >
                      Confirmed
                    </Checkbox>
                  }
                />
                <AlertRow
                  title=''
                  children='Alert when order is delivered'
                  checkbox={
                    <Checkbox
                      isChecked={topics.includes('nrn:clients.distributor:delivered_order')}
                      onChange={(e: any) => {
                        if (e) {
                          setTopics(prevTopics => [...prevTopics, 'nrn:clients.distributor:delivered_order'])
                        } else {
                          setTopics(prevTopics => [...prevTopics.filter(e => e !== 'nrn:clients.distributor:delivered_order')])
                        }
                        setNotSaved(true)
                      }}
                    >
                      Delivered
                    </Checkbox>
                  }
                />
                <AlertRow
                  title=''
                  children='Alert when system is refilled'
                  checkbox={
                    <Checkbox
                      isChecked={topics.includes('nrn:clients.distributor:refilled')}
                      onChange={(e: any) => {
                        if (e) {
                          setTopics(prevTopics => [...prevTopics, 'nrn:clients.distributor:refilled'])
                        } else {
                          setTopics(prevTopics => [...prevTopics.filter(e => e !== 'nrn:clients.distributor:refilled')])
                        }
                        setNotSaved(true)
                      }}
                    >
                      Refilled
                    </Checkbox>
                  }
                />
                <AlertRow
                  title=''
                  children='Alert when sensor failed'
                  checkbox={
                    <Checkbox
                      isChecked={topics.includes('nrn:clients.customer:detected_a_problem_with')}
                      onChange={(e: any) => {
                        if (e) {
                          setTopics(prevTopics => [...prevTopics, 'nrn:clients.customer:detected_a_problem_with'])
                        } else {
                          setTopics(prevTopics => [...prevTopics.filter(e => e !== 'nrn:clients.customer:detected_a_problem_with')])
                        }
                        setNotSaved(true)
                      }}
                    >
                      Sensor Fail
                    </Checkbox>
                  }
                />
                <AlertRow
                  title=''
                  children='Alert when a freezeout occurs'
                  checkbox={
                    <Checkbox
                      isChecked={topics.includes('nrn:clients.customer:detected_a_freezeout')}
                      onChange={(e: any) => {
                        if (e) {
                          setTopics(prevTopics => [...prevTopics, 'nrn:clients.customer:detected_a_freezeout'])
                        } else {
                          setTopics(prevTopics => [...prevTopics.filter(e => e !== 'nrn:clients.customer:detected_a_freezeout')])
                        }
                        setNotSaved(true)
                      }}
                    >
                      Freezeout
                    </Checkbox>
                  }
                />
              </>
            }
          />
        </>
      }
    />
  )
}
