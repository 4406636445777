// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type PenIconsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function PenIconsvgIcon(props: PenIconsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 17 17"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M11.303 2.497l3.2 3.2-8.788 8.788a8.173 8.173 0 01-4.882 2.344.6.6 0 01-.662-.663l.001-.006a8.174 8.174 0 012.344-4.876l8.787-8.787zm5.179-.476L14.98.518a1.2 1.2 0 00-1.697 0L11.87 1.931l3.2 3.2 1.413-1.413a1.202 1.202 0 00-.001-1.697z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default PenIconsvgIcon;
/* prettier-ignore-end */
