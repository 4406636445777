import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import * as GQL from 'generated/graphql'
import AuditTable from 'plasmic/AuditTable'
import { CenteredLoader } from 'components/Loader/LoaderWrappers'
import Loader from 'components/Loader'
import CornerLoader from 'components/Loader/CornerLoader'
import AuditTablePage from 'plasmic/AuditTablePage'
import { AuditLogParser } from './parser'
import { NoHits } from 'modules/orders/util'
import LabelStatusChip from 'plasmic/LabelStatusChip'
import { useDebounce } from 'util/hooks'

const Wrapper = styled.div`
  width: 100%;
`

const PAGE_SIZE = 20

const actionMap = {
  [GQL.LogEntryAction.A_0]: '0',
  [GQL.LogEntryAction.A_1]: '1',
  [GQL.LogEntryAction.A_2]: '2',
  [GQL.LogEntryAction.A_3]: '3',
} as const

const AuditLogs: React.FC = () => {
  const parser = useMemo(() => new AuditLogParser(), [])
  const [loadingFetchMore, setLoadingFetchMore] = useState(false)
  const [actions, setActions] = useState<GQL.LogEntryAction[]>([])
  const [actorIds, setActorIds] = useState<string[]>([])
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const {
    data: dataAuditLogs,
    loading: loadingAuditLogs,
    fetchMore: fetchMoreAuditLogs,
  } = GQL.useAuditLogs({
    variables: {
      first: PAGE_SIZE,
      search: debouncedSearch,
      actors: actorIds.toString(),
      actions: actions.map(action => actionMap[action]),
    },
  })
  const auditLogs = useMemo(() => dataAuditLogs?.auditLogs?.edges.map(edge => edge?.node as GQL.AuditLogEntryNode) || [], [dataAuditLogs])
  const tableAuditData = useMemo(() => parser.parseRows(auditLogs), [parser, auditLogs])

  const onLoadMoreClick = useCallback(() => {
    if (auditLogs.length >= (dataAuditLogs?.auditLogs?.totalCount || 0) || loadingFetchMore) return
    setLoadingFetchMore(true)
    fetchMoreAuditLogs({
      variables: {
        after: dataAuditLogs?.auditLogs?.pageInfo.endCursor || '',
        first: PAGE_SIZE,
        search: debouncedSearch,
        actors: actorIds.toString(),
        actions: actions.map(action => actionMap[action]),
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) return prev
        return {
          ...fetchMoreResult,
          auditLogs: {
            ...fetchMoreResult.auditLogs,
            edges: [...(prev.auditLogs?.edges || []), ...fetchMoreResult?.auditLogs?.edges!],
          },
        } as GQL.AuditLogs
      },
    }).finally(() => setLoadingFetchMore(false))
  }, [
    actions,
    actorIds,
    auditLogs.length,
    dataAuditLogs?.auditLogs?.pageInfo.endCursor,
    dataAuditLogs?.auditLogs?.totalCount,
    debouncedSearch,
    fetchMoreAuditLogs,
    loadingFetchMore,
  ])

  const onClearFilters = useCallback(() => {
    setActions([])
    setActorIds([])
  }, [setActions, setActorIds])

  return (
    <React.Fragment>
      {loadingAuditLogs && <CornerLoader size={48} topAdjust={'-30px'} />}
      <AuditTablePage
        topActions={{
          props: {
            searchField: { fieldValue: search, onChange: (event: ChangeEvent<HTMLInputElement>) => setSearch(event.target.value) },
          },
        }}
        filtersAudit={{
          props: {
            actorIds: actorIds,
            onActorIdsChange: setActorIds,
            actions: actions,
            onActionsChange: setActions,
            onClearFilters: onClearFilters,
          },
        }}
        auditTable={
          <Wrapper>
            <AuditTable tableAuditData={tableAuditData} />
            {!loadingAuditLogs && auditLogs.length === 0 && !search && actions.length === 0 && actorIds.length === 0 && (
              <NoHits>
                <LabelStatusChip icon='check' title='No audit logs' />
              </NoHits>
            )}
            {!loadingAuditLogs && auditLogs.length === 0 && (search || actions.length !== 0 || actorIds.length !== 0) && (
              <NoHits>
                <LabelStatusChip icon='error' title='No hits' />
              </NoHits>
            )}
            {loadingAuditLogs && auditLogs.length === 0 && (
              <CenteredLoader sizeAuto>
                <Loader color='grey' size={64} />
              </CenteredLoader>
            )}
          </Wrapper>
        }
        scrollIndicator={
          auditLogs.length > 0
            ? {
                props: {
                  total: dataAuditLogs?.auditLogs?.totalCount || 0,
                  loaded: auditLogs.length,
                  btnLoadMore: {
                    ...((loadingAuditLogs || loadingFetchMore) && { children: <Loader color='white' /> }),
                    onClick: onLoadMoreClick,
                  },
                },
              }
            : { render: () => null }
        }
      />
    </React.Fragment>
  )
}

export default AuditLogs
