import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useModal } from 'react-modal-hook'

import Icon from 'components/Icon'
import Truck from 'components/icons/Truck'
import SimpleClock from 'components/icons/SimpleClock'
import MapMarker from 'components/icons/MapMarker'
import ChangeVehicleModal from '../components/ChangeVehicleModal'
import ChangeWorkhoursModal from '../components/ChangeWorkhoursModal'
import ChangeDepotModal from '../components/ChangeDepotModal'
import Tooltip from 'components/Tooltip'
import Loader from 'components/Loader'
import * as GQL from 'generated/graphql'
import { useAppContext } from 'util/hooks'
import { displayToast } from 'util/toasts'
import TutorialTooltip from 'components/Tooltip/TutorialTooltip'
import { getDateOnly } from '../util'

const OptionsIcon = styled(Icon)`
  position: absolute;
  right: 0;
  top: 0;
  color: rgba(255, 255, 255, 0.4);
  font-size: 1em;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  visibility: hidden;
  cursor: pointer;
  :hover {
    color: rgba(255, 255, 255, 0.8);
  }
`
const RoutesIcon = styled(Icon)`
  position: absolute;
  left: 0;
  top: 5px;
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.8em;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  visibility: hidden;
  cursor: pointer;
  :hover {
    color: rgba(255, 255, 255, 0.8);
  }
`

const HoverIcon = styled(Icon)`
  visibility: hidden;
  cursor: pointer;
  color: ${props => props.theme.colors.green};
`

interface LayoutProps {
  hasRoute: boolean
  routeVacated: boolean
  readOnly: boolean
}

const Layout = styled.div<LayoutProps>`
  display: flex;
  justify-content: ${props => (props.hasRoute ? 'space-between' : 'center')};
  align-items: center;
  height: 97px;

  color: ${props => (props.hasRoute && !props.routeVacated ? props.theme.colors.green : props.theme.colors.red)};

  ${props =>
    !props.hasRoute &&
    !props.readOnly &&
    `
    background: repeating-linear-gradient(
      -45deg,
      ${props.theme.colors.primaryDark},
      ${props.theme.colors.primaryDark} 5px,
      #1e243f 5px,
      #1e243f 10px
    );
  `}
  font-size: 22px;
  opacity: 0.65;
  :hover {
    opacity: 1;
  }
  :hover ${OptionsIcon}, :hover ${HoverIcon}, :hover ${RoutesIcon} {
    visibility: visible;
  }
  ${props => (props.readOnly ? 'background-color: #12152b;' : 'background-color: #283053;')}
  border-radius: 6px;
  user-select: none;
  flex-direction: column;
`

const BottomIconWrapper = styled.div`
  min-height: 25px;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  svg {
    padding: 3px;
  }
`

const TruckIcon = styled(Truck)`
  cursor: pointer;
  width: 15px;
  height: 15px;
  fill: ${props => props.theme.colors.lightBlue};
`

const StyledIcon = styled(Icon)<{ color?: string }>`
  cursor: pointer;
  color: ${props => props.color};
`

interface DriverDatesPanelTileProps {
  route?: GQL.DriverExtendedNode
  currentRoutes: GQL.RouteNode[]
  driver: GQL.DriverNode
  date: Date
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  handleMenu: (e: React.MouseEvent<HTMLElement>, cb: (action: string) => void) => void
}

const DriverDatesPanelTile = ({ route, currentRoutes, date, driver, handleMenu, onClick }: DriverDatesPanelTileProps) => {
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const { appContext } = useAppContext()

  const currentDepot = appContext.depot?.id ?? null

  const [showVehicleModal, hideVehicleModal] = useModal(
    () => <ChangeVehicleModal isOpen={true} onRequestClose={hideVehicleModal} route={route} date={date} submit={() => setLoading(true)} />,
    [route, date]
  )
  const [showWorkhoursModal, hideWorkhoursModal] = useModal(
    () => <ChangeWorkhoursModal isOpen={true} onRequestClose={hideWorkhoursModal} route={route} date={date} submit={() => setLoading(true)} />,
    [route, date]
  )
  const [showDepotModal, hideDepotModal] = useModal(
    () => <ChangeDepotModal isOpen={true} onRequestClose={hideDepotModal} route={route} date={date} submit={() => setLoading(true)} />,
    [route, date]
  )

  useEffect(() => {
    setLoading(false)
  }, [route])

  const hasRoute = !!route
  const readOnly = !!(getDateOnly(date) < getDateOnly(new Date()) || (driver.defaultDepot?.id !== currentDepot && currentDepot))

  const hasCustomVehicle = route?.defaultVehicle?.id !== route?.routeVehicle?.id
  const hasCustomWorkinghours = route?.standardEndTime !== route?.routeEndTime || route?.standardStartTime !== route?.routeStartTime
  const hasCustomDepot = route?.defaultDepotId !== route?.routeDepotId

  function getCustomMessage(type: string) {
    switch (type) {
      case 'vehicle':
        return (
          (route?.defaultVehicle?.name || '[vehicle not specified for this driver]') +
          ' is usually used for this route. ' +
          route?.routeVehicle?.name +
          ' will be used this day.'
        )
      case 'depot':
        return route?.defaultDepot?.name + ' is usually the depot for this route. ' + route?.routeDepot?.name + ' will be used this day.'
      case 'workhours':
        if (!route?.standardStartTime && !route?.standardEndTime) {
          return 'Route is usually not on this day. This route is spanning from ' + route?.routeStartTime + ' to ' + route?.routeEndTime
        }
        return (
          'Route is usually from ' +
          route?.standardStartTime +
          ' to ' +
          route?.standardEndTime +
          '. This route is spanning from ' +
          route?.routeStartTime +
          ' to ' +
          route?.routeEndTime
        )
    }
  }

  const click = (event: any) => {
    setLoading(true)
    onClick(event)
  }

  const notAllowed = () => {
    displayToast("Can't change routes in the past", 'warning')
  }
  const hasAssignedRoute = () => {
    displayToast('This driver has assigned route! Please first delete assigned route or select other driver for that route.', 'warning')
  }
  const drivingRoutes = currentRoutes.find(el => new Date(el.date + ' 00:00:00').getTime() === date.getTime() && driver.id === el.driver?.id)

  const handleMenuCb = (action: string) => {
    const actions = {
      vehicle: showVehicleModal,
      workhours: showWorkhoursModal,
      depot: showDepotModal,
    } as { [key: string]: () => void }

    actions[action]()
  }

  return (
    <Layout hasRoute={hasRoute} routeVacated={hasRoute && !!route?.routeVacated} readOnly={readOnly}>
      <div style={{ display: 'flex', width: '100%', height: '27px', position: 'relative' }}>
        {hasRoute && !readOnly && <OptionsIcon icon='ellipsis-v' onClick={e => handleMenu(e, handleMenuCb)} />}
        {drivingRoutes && (
          <TutorialTooltip content='Click to see the route.'>
            <RoutesIcon icon='route' onClick={e => navigate('/deliveries/routes', { state: { selectedDay: date } })} />
          </TutorialTooltip>
        )}
      </div>
      {loading ? (
        <Loader color='primaryLighter' />
      ) : hasRoute ? (
        !!route?.routeVacated ? (
          <TutorialTooltip content={<FormattedMessage id='tooltips.deliveries.dates.day.times' />} placement='bottom'>
            <StyledIcon icon={'times'} onClick={readOnly ? notAllowed : click} />
          </TutorialTooltip>
        ) : drivingRoutes ? (
          <TutorialTooltip content={<FormattedMessage id='tooltips.deliveries.dates.day.assigned' />} placement='bottom'>
            <Tooltip content='Has assigned route'>
              <StyledIcon icon={'check'} onClick={readOnly ? notAllowed : hasAssignedRoute} />
            </Tooltip>
          </TutorialTooltip>
        ) : (
          <TutorialTooltip content={<FormattedMessage id='tooltips.deliveries.dates.day.tick' />} placement='bottom'>
            <Tooltip
              content={
                <>
                  Working hours: {route?.routeStartTime} - {route?.routeEndTime}
                </>
              }
              delay={0}
              arrow={false}
            >
              <StyledIcon icon={'check'} onClick={readOnly ? notAllowed : click} color='#2c97de' />
            </Tooltip>
          </TutorialTooltip>
        )
      ) : readOnly ? (
        <div />
      ) : (
        <TutorialTooltip content={<FormattedMessage id='tooltips.deliveries.dates.day.plus' />} placement='bottom'>
          <HoverIcon icon='plus' onClick={readOnly ? notAllowed : click} />
        </TutorialTooltip>
      )}
      {hasRoute && !!!route?.routeVacated ? (
        <BottomIconWrapper>
          {hasCustomVehicle && (
            <Tooltip content={getCustomMessage('vehicle')} delay={0} arrow={false}>
              <span>
                <TruckIcon onClick={readOnly ? notAllowed : showVehicleModal} />
              </span>
            </Tooltip>
          )}
          {hasCustomWorkinghours && (
            <Tooltip content={getCustomMessage('workhours')} delay={0} arrow={false}>
              <span>
                <SimpleClock style={{ cursor: 'pointer' }} width='15px' height='15px' fill={'#9300e9'} onClick={readOnly ? notAllowed : showWorkhoursModal} />
              </span>
            </Tooltip>
          )}
          {hasCustomDepot && (
            <Tooltip content={getCustomMessage('depot')} delay={0} arrow={false}>
              <span>
                <MapMarker style={{ cursor: 'pointer' }} width='15px' height='15px' fill={'#00d4a6'} onClick={readOnly ? notAllowed : showDepotModal} />
              </span>
            </Tooltip>
          )}
        </BottomIconWrapper>
      ) : (
        <div style={{ height: '26px' }}></div>
      )}
    </Layout>
  )
}

export default DriverDatesPanelTile
