import React, { useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as DVIcon } from 'assets/fonticons/drivers_and_vehicles.svg'

import HeaderContext from 'context/HeaderContext'
import SubnavButton from 'plasmic/SubnavButton'
import TutorialTooltip from 'components/Tooltip/TutorialTooltip'
import { default as RoutesComponent } from './Routes/Routes'
import Dates from './Dates/Dates'
import Dispatch from './Dispatch/Dispatch'
import DriversAndVehicles from './DriversAndVehicles/DriversAndVehicles'

const DATES_PATH = '/deliveries/dates'
const ROUTES_PATH = '/deliveries/routes'
const DISPATCH_PATH = '/deliveries/dispatch'
const VEHICLES_PATH = '/deliveries/vehicles'

const Wrapper = styled.div`
  height: 100%;
`

const Page = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main'
    'footer';
  box-sizing: border-box;
`

const Content = styled.div`
  grid-area: main;
  overflow: auto;
`

const Deliveries: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const intl = useIntl()
  const t = intl.formatMessage

  const headerContext = useContext(HeaderContext)

  useEffect(() => {
    headerContext.setIsSubHeaderVisible(true)
    headerContext.setLeftSubHeaderGroup(
      <>
        <TutorialTooltip content={t({ id: 'tooltips.header.deliveries.dates' })}>
          <SubnavButton active={location.pathname === DATES_PATH} title={t({ id: 'common.dates' })} onClick={() => navigate(DATES_PATH)} />
        </TutorialTooltip>
        <TutorialTooltip content={t({ id: 'tooltips.header.deliveries.routes' })}>
          <SubnavButton active={location.pathname === ROUTES_PATH} title={t({ id: 'common.routes' })} onClick={() => navigate(ROUTES_PATH)} />
        </TutorialTooltip>
        <TutorialTooltip content={t({ id: 'tooltips.header.deliveries.dispatch' })}>
          <SubnavButton active={location.pathname === DISPATCH_PATH} title={t({ id: 'common.dispatch' })} onClick={() => navigate(DISPATCH_PATH)} />
        </TutorialTooltip>
      </>
    )
    headerContext.setRightSubHeaderGroup(
      <>
        <SubnavButton
          iconLeft
          active={location.pathname === VEHICLES_PATH}
          title='Drivers and vehicles'
          icon={
            <DVIcon
              style={{
                marginRight: '.5em',
                height: '14px',
                fill: 'rgba(255, 255, 255, .3)',
              }}
            />
          }
          onClick={() => navigate(VEHICLES_PATH)}
        />
      </>
    )

    return () => {
      headerContext.setIsSubHeaderVisible(false)
      headerContext.setLeftSubHeaderGroup(<></>)
      headerContext.setRightSubHeaderGroup(<></>)
    }
    // eslint-disable-next-line
  }, [t, location, navigate])

  return (
    <Wrapper>
      <Page>
        <Content>
          <Routes>
            <Route path='/routes' element={<RoutesComponent />} />
            <Route path='/dates' element={<Dates />} />
            <Route path='/dispatch' element={<Dispatch />} />
            <Route path='/vehicles' element={<DriversAndVehicles />} />
            <Route path='/' element={<Navigate to='/deliveries/dates' />} />
          </Routes>
        </Content>
      </Page>
    </Wrapper>
  )
}

export default Deliveries
