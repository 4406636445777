// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type PlusTsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function PlusTsvgIcon(props: PlusTsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 33.875 33.876"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M19.76 14.115V1.339c0-.738-.6-1.338-1.339-1.339h-2.968c-.738 0-1.338.6-1.339 1.339l.001 12.776-12.776.001c-.738 0-1.338.6-1.339 1.339v2.967c0 .738.6 1.338 1.339 1.339l12.775-.001v12.776c0 .738.6 1.338 1.339 1.339h2.968c.738 0 1.338-.6 1.339-1.339V19.761h12.776c.738 0 1.338-.6 1.339-1.339v-2.968c0-.738-.6-1.338-1.339-1.339H19.76z"
        }
      ></path>
    </svg>
  );
}

export default PlusTsvgIcon;
/* prettier-ignore-end */
