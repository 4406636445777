// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type PaperplanesvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function PaperplanesvgIcon(props: PaperplanesvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 56.001 51.678"}
      xmlSpace={"preserve"}
      height={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M17.106 48.734c0 2.657 1.159 2.944 1.658 2.944h.001c.7 0 1.503-.525 2.318-1.515l6.168-7.476-10.145-6.244v12.291zM53.363.243L24.637 10.815 2.205 19.072c-2.748 1.012-2.956 3.096-.463 4.63l13.688 8.422 28.491-21.581L19.24 34.469l4.238 2.607 14.12 8.689c1.246.768 2.67.268 3.161-1.113l6.566-18.383 8.443-23.644c.74-2.068-.343-3.14-2.405-2.382z"
        }
      ></path>
    </svg>
  );
}

export default PaperplanesvgIcon;
/* prettier-ignore-end */
