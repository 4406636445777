import React from 'react'
import { omit } from 'lodash'
import PlasmicButtonDynamicField, { DefaultButtonDynamicFieldProps } from 'plasmic/plasmic/solace_components/PlasmicButtonDynamicField'
import * as GQL from 'generated/graphql'
import PlasmicDynamicFieldLine from 'plasmic/plasmic/solace_components/PlasmicDynamicFieldLine'
import { createDynamicField } from '../util'

interface Props extends DefaultButtonDynamicFieldProps {
  onFieldInsert: (element: HTMLSpanElement) => void
}

const ButtonDynamicField: React.FC<Props> = props => {
  const { data: dynamicFieldData } = GQL.useAllDynamicFields()
  const dynamicFields = (dynamicFieldData?.allDynamicFields as GQL.CustomNotificationDynamicFieldNode[]) || []

  const insertField = (name: string, key: string) => {
    const element = createDynamicField(name, key)
    props.onFieldInsert(element)
  }

  return (
    <PlasmicButtonDynamicField
      dynamicFields={dynamicFields.map(dynamicField => (
        <PlasmicDynamicFieldLine
          key={dynamicField.id}
          variable={dynamicField.name}
          dataVariable={dynamicField.key}
          example={dynamicField.example}
          onClick={() => insertField(dynamicField.name, dynamicField.key)}
        />
      ))}
      {...omit(props, 'onFieldInsert')}
    />
  )
}

export default ButtonDynamicField
