// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type StatusIconAddressMissingsvgIconProps =
  React.ComponentProps<"svg"> & {
    title?: string;
  };

export function StatusIconAddressMissingsvgIcon(
  props: StatusIconAddressMissingsvgIconProps
) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 41 49"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M20.5.35c-11 0-20 9-20 20 0 5.3 2.1 10.1 5.4 13.6l14.6 14.7 14.6-14.6c3.3-3.6 5.4-8.4 5.4-13.7 0-11-9-20-20-20zm0 36c-8.837 0-16-7.163-16-16s7.163-16 16-16 16 7.163 16 16-7.163 16-16 16z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M19.17 24.452a5.071 5.071 0 01-.099-1.025c0-.667.149-1.304.449-1.913.301-.608.809-1.179 1.525-1.712l1.2-.9c.716-.533 1.075-1.191 1.075-1.975 0-.283-.051-.562-.15-.838a1.96 1.96 0 00-.463-.725c-.208-.208-.471-.375-.787-.5s-.684-.188-1.1-.188c-.45 0-.834.083-1.15.25s-.579.379-.787.637a2.564 2.564 0 00-.463.9c-.1.342-.15.688-.15 1.038 0 .333.033.6.101.8l-3.15-.125c-.05-.184-.083-.371-.1-.562s-.025-.379-.025-.562c0-.667.125-1.321.375-1.963a4.967 4.967 0 011.101-1.7c.482-.492 1.078-.888 1.787-1.188.708-.3 1.529-.45 2.463-.45.966 0 1.816.142 2.55.425s1.35.654 1.85 1.112c.5.458.875.987 1.125 1.587s.375 1.217.375 1.85c0 1-.225 1.854-.675 2.562a6.687 6.687 0 01-1.8 1.862l-1.025.725c-.434.317-.767.671-1 1.062-.233.392-.35.846-.35 1.363v.15H19.17zm-.575 3.625c0-.55.191-1.021.575-1.413.383-.392.85-.587 1.4-.587.55 0 1.021.196 1.412.587s.588.862.588 1.413c0 .55-.196 1.017-.588 1.4s-.862.575-1.412.575a1.903 1.903 0 01-1.4-.575 1.904 1.904 0 01-.575-1.4z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default StatusIconAddressMissingsvgIcon;
/* prettier-ignore-end */
