import * as GQL from 'generated/graphql'

export const getWeekdayNumber = (weekday: GQL.CustomerStandardOpeningHoursWeekday): 0 | 1 | 2 | 3 | 4 | 5 | 6 => {
  if (weekday === GQL.CustomerStandardOpeningHoursWeekday.Sunday) {
    return 0
  } else if (weekday === GQL.CustomerStandardOpeningHoursWeekday.Monday) {
    return 1
  } else if (weekday === GQL.CustomerStandardOpeningHoursWeekday.Tuesday) {
    return 2
  } else if (weekday === GQL.CustomerStandardOpeningHoursWeekday.Wednesday) {
    return 3
  } else if (weekday === GQL.CustomerStandardOpeningHoursWeekday.Thursday) {
    return 4
  } else if (weekday === GQL.CustomerStandardOpeningHoursWeekday.Friday) {
    return 5
  } else {
    return 6
  }
}
