import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import Select from 'react-select'
import _ from 'lodash'

import Modal from 'components/Modal/Modal'
import * as GQL from 'generated/graphql'
import { displayToast } from 'util/toasts'
import ModalDepot from 'plasmic/ModalDepot'
import Loader from 'components/Loader'
import { Control, MenuList, Option, reactSelectStyles } from 'plasmic/StyledReactSelect'
import { isValidAddress } from 'modules/localization/utils'
import AddressAutoComplete from 'components/Input/AddressAutoComplete'

interface OptionType {
  value: string
  label: string
}

interface DepotModalProps {
  isOpen: boolean
  onRequestClose: () => void
  depot?: GQL.DepotNode
}

interface DepotData {
  name: string
  customIdentifier: string
  address: GQL.AddressNode | null
  contactPerson?: OptionType
}

const DepotModal: React.FC<DepotModalProps> = (props: DepotModalProps) => {
  const [depot, setDepot] = useState<DepotData>({
    name: props.depot?.name ? props.depot.name : '',
    customIdentifier: props.depot?.customIdentifier ? props.depot.customIdentifier : '',
    address: props.depot ? props.depot.address : null,
    contactPerson: {
      value: props.depot?.contactPerson ? props.depot.contactPerson.id : '',
      label: props.depot?.contactPerson && props.depot?.contactPerson?.fullName ? props.depot.contactPerson.fullName : '',
    },
  })
  const { data: distributorUsers } = GQL.useUsers({
    onCompleted: response => {
      if (response.allUsers?.edges && response.allUsers?.edges.length > 0 && !depot.contactPerson?.value) {
        setDepot({
          ...depot,
          contactPerson: {
            value: response.allUsers?.edges[0]?.node?.id || '',
            label: response.allUsers?.edges[0]?.node?.fullName || '',
          },
        })
      }
    },
  })
  const intl = useIntl()
  const t = intl.formatMessage

  const [createDepot, { loading: createDepotLoading }] = GQL.useCreateDepot({
    onCompleted: response => {
      if (response?.createDepot?.ok) {
        displayToast(t({ id: 'depots.depot-create-success-message' }), 'success')
        props.onRequestClose()
      }
    },
    onError: error => {
      if (error.message.includes('Depot with that name exists')) {
        displayToast(t({ id: 'depots.depot-create-update.error.exists' }))
      } else {
        displayToast(t({ id: 'depots.depot-create-update.error.generic.create' }))
      }
    },
    update(cache, { data }) {
      const oldData = cache.readQuery({ query: GQL.AllDepotsDocument }) as GQL.AllDepots
      cache.writeQuery({
        query: GQL.AllDepotsDocument,
        data: {
          ...oldData,
          allDepots: {
            ...oldData.allDepots,
            edges: ([...(oldData.allDepots?.edges || []), { node: data?.createDepot?.depot, __typename: 'DepotNode' }] as GQL.DepotNodeEdge[]).sort(
              (a, b) => a?.node?.name?.localeCompare(b?.node?.name!) || 0
            ),
          },
        },
      })
    },
  })

  const [patchDepot, { loading: patchDepotLoading }] = GQL.usePatchDepot({
    onCompleted: () => {
      displayToast(t({ id: 'depots.depot-update-success-message' }), 'success')
      props.onRequestClose()
    },
    onError: error => {
      if (error.message.includes('Depot with that name exists')) {
        displayToast(t({ id: 'depots.depot-create-update.error.exists' }))
      } else {
        displayToast(t({ id: 'depots.depot-create-update.error.generic.update' }))
      }
    },
    update(cache, { data }) {
      const updatedDepot = data?.patchDepot?.depot

      if (updatedDepot) {
        cache.modify({
          fields: {
            allDepots(existingDepotsRefs) {
              const newEdges = existingDepotsRefs?.edges?.map((edgeRef: GQL.DepotNodeEdge) => {
                if (edgeRef?.node?.id === updatedDepot.id) {
                  return {
                    node: updatedDepot,
                    __typename: 'DepotNodeEdge',
                  }
                }
                return edgeRef
              })

              return {
                ...existingDepotsRefs,
                edges: newEdges || [],
              }
            },
          },
        })
      }
    },
  })

  const handleChangeAddress = (suggestion: GQL.GeocodingSuggestionNode) => {
    setDepot(depot => {
      if (suggestion.placeName && suggestion.latitude && suggestion.latitude) {
        return {
          ...depot,
          address: {
            firstLine: suggestion.placeName,
            longitude: suggestion.longitude,
            latitude: suggestion.latitude,
          } as GQL.AddressNode,
        }
      }
      return depot
    })
  }

  function handleSubmit() {
    if (!depot?.name) {
      displayToast(t({ id: 'depots.depot-error-message' }))
      return
    } else if (!depot.address || !isValidAddress(depot.address)) {
      displayToast(t({ id: 'common.address.form.error' }))
      return
    }
    if (props.depot) {
      patchDepot({
        variables: {
          id: props.depot.id,
          input: {
            name: depot.name,
            customIdentifier: depot.customIdentifier,
            address: _.omit(depot.address, ['__typename', 'id']),
            contactPerson: depot.contactPerson ? depot.contactPerson.value : undefined,
          },
        },
      })
    } else {
      createDepot({
        variables: {
          input: {
            name: depot.name,
            customIdentifier: depot.customIdentifier,
            address: _.omit(depot.address, ['__typename', 'id']),
            contactPerson: depot.contactPerson ? depot.contactPerson.value : undefined,
          },
        },
      })
    }
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      stripped
      overlayStyle={{ alignItems: 'flex-start', overflow: 'auto', padding: '2rem 0rem' }}
      contentStyle={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <ModalDepot
        btnClose={{
          props: {
            onClick: () => props.onRequestClose(),
          },
        }}
        title={props.depot ? t({ id: 'depots.edit-depot' }) : t({ id: 'depots.create-depot' })}
        inputName={{
          fieldStatus: true,
          alert: !depot.name,
          undefinedInput: {
            value: depot.name,
            name: 'name',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setDepot({
                ...depot,
                name: e.target.value,
              }),
          },
        }}
        inputId={{
          fieldStatus: true,
          alert: !depot.customIdentifier,
          undefinedInput: {
            value: depot.customIdentifier,
            name: 'customIdentifier',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setDepot({
                ...depot,
                customIdentifier: e.target.value,
              }),
          },
        }}
        addressInputForm={{
          mapDiv: isValidAddress(depot.address) && (
            <div
              onClick={() =>
                parseFloat(depot?.address?.latitude) && parseFloat(depot?.address?.longitude)
                  ? window.open(
                      `https://www.google.com/maps/search/?api=1&query=${parseFloat(depot?.address?.latitude)},${parseFloat(depot?.address?.longitude)}`,
                      '_blank'
                    )
                  : null
              }
              style={{
                cursor: 'pointer',
                width: '100%',
                height: '215px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '1.5rem',
                color: 'rgb(255, 143, 143)',
                backgroundImage: `url('https://maps.googleapis.com/maps/api/staticmap?size=800x300&zoom=15&scale=2&map_id=7c94979620d924a7&key=AIzaSyBtD0cwYlaVLkqfRT1MPc22j0j0vv51wag&markers=size:normal%7Ccolor:red%7C${parseFloat(
                  depot?.address?.latitude
                )},${parseFloat(depot?.address?.longitude)}')`,
                backgroundSize: '100% auto',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            />
          ),
          inputAddressWrapper: {
            alert: !isValidAddress(depot.address),
            title: t({ id: 'depots.depot-address' }),
            noTooltip: true,
            divPhoneNumber: (
              <AddressAutoComplete
                id='input-address'
                name='address'
                placeholder={t({ id: 'depots.depot-address' })}
                value={depot.address?.firstLine ? depot.address.firstLine : ''}
                onSelect={handleChangeAddress}
              />
            ),
          },
          btnConfirm: {
            onClick: () => console.log('Validate address'),
          },
        }}
        inputContactPersonDiv={
          <Select
            id='select-contact-person'
            placeholder={createDepotLoading ? <Loader color='white' /> : t({ id: 'common.select-user' })}
            components={{ Option, Control, MenuList }}
            styles={reactSelectStyles}
            isMulti={false}
            onChange={event => setDepot({ ...depot, contactPerson: event })}
            value={
              {
                value: depot?.contactPerson?.value,
                label: depot?.contactPerson?.label || t({ id: 'common.select-user' }),
              } as OptionType
            }
            options={
              distributorUsers &&
              distributorUsers.allUsers?.edges.map(
                user =>
                  ({
                    value: user?.node?.id,
                    label: user?.node?.fullName,
                  }) as OptionType
              )
            }
          />
        }
        btnCreateDepot={{
          props: {
            loading: patchDepotLoading || createDepotLoading,
            onClick: handleSubmit,
          },
        }}
      />
    </Modal>
  )
}

export default DepotModal
