import * as XLSX from 'xlsx'
import { ApolloClient } from '@apollo/client'

import * as GQL from 'generated/graphql'
import Text from 'components/Text'
import styled from 'styled-components'
import CellProductMultiple from 'plasmic/CellProductMultiple'
import { ACTIVE_ORDER_STATES } from './consts'
import { IAppContext } from 'context/AppContext'
import { paymentsEnabled } from 'util/utils'
import { IntlShape } from 'react-intl'
import { AllOrdersTableColumn } from 'context/TableContext'
import ApolloCacheService from 'util/apollo/service'

export const updateCylinderGroupOrderCacheOnPatch = (modifiedOrder: GQL.CylinderGroupOrderNode, client: ApolloClient<object>) => {
  const cacheService = new ApolloCacheService(client)
  const modifiedOrderEdge = { node: modifiedOrder, __typename: 'CylinderGroupOrderNodeEdge' }
  const modifiedCylinderGroupEdge = { node: modifiedOrder.cylinderGroup, __typename: 'CylinderGroupNodeEdge' }

  // // updating ready to refill table cache
  cacheService.modifyEdgeQueriesCache(modifiedCylinderGroupEdge, [
    {
      queryName: 'allCylinderGroups',
      queryVariables: { readyToRefill: true, pendingRefill: false, awaitingDelivery: false },
      queryDocument: GQL.AllActiveReadyToRefillDocument,
      applyFilters: [edge => edge?.node?.id !== modifiedOrder.cylinderGroup.id],
    },
  ])

  cacheService.modifyEdgeQueriesCache(modifiedOrderEdge, [
    // updating ready to pending refill table cache
    {
      queryName: 'allCylinderGroupOrders',
      queryVariables: { state: 1 },
      queryDocument: GQL.AllActivePendingRefillDocument,
      applyFilters: [edge => edge?.node?.state === GQL.CylinderGroupOrderStates.Created],
    },
    // updating paused refill table cache
    {
      queryName: 'allCylinderGroupOrders',
      queryVariables: { state: 6 },
      queryDocument: GQL.AllActivePausedRefillDocument,
      applyFilters: [edge => edge?.node?.state === GQL.CylinderGroupOrderStates.Paused],
    },
    // updating awaiting delivery table cache
    {
      queryName: 'allCylinderGroupOrders',
      queryVariables: { state: 2 },
      queryDocument: GQL.AllActiveAwaitingDeliveryDocument,
      applyFilters: [edge => edge?.node?.state === GQL.CylinderGroupOrderStates.AwaitingDelivery],
    },
    // updating orders (customer drawer tab) - active
    {
      queryName: 'allCylinderGroupOrders',
      queryVariables: { stateIn: [1, 2, 6], cylinderGroup: modifiedOrderEdge.node.cylinderGroup.id },
      queryDocument: GQL.AllActiveCylinderGroupOrdersDocument,
      applyFilters: [edge => ACTIVE_ORDER_STATES.includes(edge?.node?.state)],
    },
    // updating orders (customer drawer tab) - past
    {
      queryName: 'allCylinderGroupOrders',
      queryVariables: { stateIn: [3, 4], cylinderGroup: modifiedOrderEdge.node.cylinderGroup.id },
      queryDocument: GQL.AllPastCylinderGroupOrdersDocument,
      applyFilters: [edge => !ACTIVE_ORDER_STATES.includes(edge?.node?.state)],
    },
  ])
}

export const updateCylinderGroupOrderCacheOnDelete = (deletedOrder: GQL.CylinderGroupOrderNode, client: ApolloClient<object>) => {
  const cacheService = new ApolloCacheService(client)
  const orderEdge = {
    node: deletedOrder,
    __typename: 'CylinderGroupOrderNodeEdge',
  }

  // remove matching in pending refill table
  cacheService.removeEdgeFromQueries(orderEdge, [
    {
      queryName: 'allCylinderGroupOrders',
      queryVariables: { state: 1 },
      queryDocument: GQL.AllActivePendingRefillDocument,
      applyFilters: [edge => edge?.node?.state === GQL.CylinderGroupOrderStates.Created],
    },
  ])

  cacheService.modifyEdgeQueriesCache(orderEdge, [
    // remove matching in paused refill table
    {
      queryName: 'allCylinderGroupOrders',
      queryVariables: { state: 6 },
      queryDocument: GQL.AllActivePausedRefillDocument,
      applyFilters: [edge => edge?.node?.state === GQL.CylinderGroupOrderStates.Paused],
    },
    // remove matching in awaiting delivery table
    {
      queryName: 'allCylinderGroupOrders',
      queryVariables: { state: 2 },
      queryDocument: GQL.AllActiveAwaitingDeliveryDocument,
      applyFilters: [edge => edge?.node?.state === GQL.CylinderGroupOrderStates.AwaitingDelivery],
    },
    // remove matching in (customer drawer tab) - active
    {
      queryName: 'allCylinderGroupOrders',
      queryVariables: { stateIn: [1, 2, 6], cylinderGroup: orderEdge.node.cylinderGroup.id },
      queryDocument: GQL.AllActiveCylinderGroupOrdersDocument,
      applyFilters: [edge => ACTIVE_ORDER_STATES.includes(edge?.node?.state)],
    },
    // remove matching in (customer drawer tab) - past
    {
      queryName: 'allCylinderGroupOrders',
      queryVariables: { stateIn: [3, 4], cylinderGroup: orderEdge.node.cylinderGroup.id },
      queryDocument: GQL.AllPastCylinderGroupOrdersDocument,
      applyFilters: [edge => !ACTIVE_ORDER_STATES.includes(edge?.node?.state)],
    },
  ])
}

interface ProductLabelProps {
  cylinderGroupOrder: GQL.CylinderGroupOrderNode
}

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
`

const SingleProductContainer = styled.span`
  color: #ffffff80;
  font-size: 14px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 10px;
`

export const ProductLabel: React.FC<ProductLabelProps> = props => {
  const products = props.cylinderGroupOrder?.products as GQL.CylinderGroupOrderProductNode[] | null
  if (!!products && products.length > 1) {
    return (
      <CellProductMultiple
        title={!!products ? products.map(product => product.quantity).reduce((item, acc) => acc + item, 0) : props.cylinderGroupOrder.numberOfCylinders}
        content={
          <List>
            {products.map((product, index) => {
              return (
                <li key={`${index}-${product.quantity}-${product.product?.id}`}>
                  <Text color='white' fontWeight='bold'>
                    {product.quantity}
                  </Text>
                  &nbsp; x {product.product?.displayName}
                </li>
              )
            })}
          </List>
        }
      />
    )
  }
  if (!!products && products.length === 1) {
    return (
      <SingleProductContainer>
        <Text color='white' fontWeight='bold'>
          {props.cylinderGroupOrder?.numberOfCylinders}
        </Text>
        &nbsp; x{' '}
        {products[0].product && products[0].product.displayName
          ? products[0].product.displayName
          : products[0].product
            ? `${products[0].product.weight}kg of ${products[0].product.type.capitalizeFirst()}`
            : 'unknown'}
      </SingleProductContainer>
    )
  }
  return (
    <SingleProductContainer>
      <Text color='white' fontWeight='bold'>
        {props.cylinderGroupOrder?.numberOfCylinders}
      </Text>
      &nbsp; x {parseFloat(props.cylinderGroupOrder?.gasWeight) || parseFloat(props.cylinderGroupOrder?.cylinderGroup?.standardCylinderGasWeight)} kg{' '}
      {(props.cylinderGroupOrder?.gasType || props.cylinderGroupOrder?.cylinderGroup?.gasType || '').toLowerCase()}
    </SingleProductContainer>
  )
}

export const NoHits = styled.div`
  font-size: 1.2rem;
  display: flex;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-align: center;
`

export const getStatusString = (status: GQL.CylinderGroupOrderStates | GQL.CylinderGroupOrderPaymentStates | GQL.PaymentMethods) => {
  return status.replace(/_/g, ' ').capitalizeFirst()
}

// interfaces for exported data
interface Product {
  customId: string // its custom ID not Django generated
  product: string
  quantity: string
  weight: string
  productName: string
  productPrice: number
  totalPrice: number
  taxRate: number
  cylinderDepositPrice: number
  depositTaxRate: number
  depositFee: boolean
  currency: GQL.Currency
}

interface Order {
  customerName: string
  customerID: string
  products: Product[]
  address: string
  status: string
  comment: string
  orderID: string
  orderedAt: string
  deliveredAt: string
  customerType: string
  automatic: boolean
  datePaid: string
  paymentDue: string
  paymentStatus: GQL.CylinderGroupOrderPaymentStates
  paymentMethod: GQL.PaymentMethods
  paymentProvider: GQL.PaymentProviders
  invoiceIdentifier: string
  invoiceUrl: string
  currency: GQL.Currency
  subtotalPrice: number
  tax: number
  totalPrice: number
  createdAt: string
}

// Helper function to flatten the "products" array (for xls and csv only)
const getProductsString = (products: Product[]): string => {
  if (products.length === 0) return ''
  return products.map((product: Product) => `${product.product.capitalizeFirst()} (${parseFloat(product.weight)} kg) x ${product.quantity}`).join(', ')
}

export const generateOrderJSON = (orders: GQL.CylinderGroupOrderNode[]) => {
  const selectedFields = orders.map(order => ({
    orderID: window.atob(order.id).replace(/^\D+/g, ''),
    subtotalPrice: order.subtotalPrice,
    tax: order.tax,
    totalPrice: order.totalPrice,
    currency: order.currency,
    status: order.state ? getStatusString(order.state) : '',
    products: order.products?.map(product => ({
      customId: product?.product?.customId, // its custom ID not Django generated
      product: product?.product?.type,
      productName: product?.product?.displayName,
      weight: product?.product?.weight,
      quantity: product?.quantity,
      productPrice: product?.price,
      cylinderDepositPrice: product?.cylinderDepositPrice,
      depositFee: product?.exchangeCylindersFee,
      totalPrice: product?.totalPrice,
      taxRate: product?.taxRate,
      depositTaxRate: product?.depositTaxRate,
      currency: product?.order?.currency,
    })),
    customerName: order.cylinderGroup.customer.name,
    customerID: window.atob(order.cylinderGroup.customer.id).replace(/^\D+/g, ''),
    customerType: order.cylinderGroup.customer.customerDomainType?.capitalizeFirst() || '',
    address: order.cylinderGroup.customer.address.firstLine,
    automatic: order.automatic,
    datePaid: order.paymentPaid,
    paymentDue: order.paymentDue,
    paymentStatus: order.paymentStatus ? getStatusString(order.paymentStatus) : '',
    paymentMethod: order.paymentSetting && order.paymentSetting.method ? getStatusString(order.paymentSetting.method) : '',
    paymentProvider: order.paymentSetting && order.paymentSetting.provider ? order.paymentSetting.provider?.capitalizeFirst() : '',
    invoiceIdentifier: order.paymentSetting ? order.paymentSetting.invoiceIdentifier : '',
    invoiceUrl: order.paymentSetting ? order.paymentSetting.invoiceUrl : '',
    comment: order.comment,
    createdAt: order.createdAt,
    confirmedAt: order.confirmedAt,
    orderedAt: order.createdAt,
    deliveredAt: order.deliveredAt || '',
  }))
  return JSON.stringify(selectedFields, null, 2)
}

export function generateOrderCSV(jsonData: Order[]): string {
  // Create the CSV headers for the main table
  const mainTableHeaders =
    'Order ID;Subtotal price;Tax;Total price;Currency;Status;Products;Customer name;Customer ID;Customer type;Address;Automatic;Date paid;Payment due;Payment status;Payment method;Payment provider;Invoice identifier;Invoice url;Comment;Created at;Confirmed at;Ordered at;Delivered at\r\n'

  // Create the CSV rows for the main table (all information about orders)
  const mainTableRows = jsonData
    .map(data => {
      const rowData: Record<string, any> = { ...data }
      rowData.products = getProductsString(data.products)
      rowData.address = `"${rowData.address}"`

      return Object.values(rowData)
        .map((value: any) => (Array.isArray(value) ? value.join(', ') : value))
        .join(';')
    })
    .join('\r\n')

  // Create the CSV headers for the products table (bottom one)
  const productsTableHeaders =
    'Order ID;Product ID;Product;Weight;Quantity;Product Price;Cylinder deposit price;Deposit fee;Total Price; Tax rate (%);Deposit tax rate (%);Currency\r\n'

  // Create the CSV rows for the products table (bottom one)
  const productsTableRows = jsonData
    .map(data => {
      const productRows = data.products.map(product =>
        [
          data.orderID,
          product.customId,
          product.product,
          product.weight,
          product.quantity,
          product.productPrice,
          product.cylinderDepositPrice,
          product.depositFee,
          product.totalPrice,
          product.taxRate,
          product.depositTaxRate,
          product.currency,
        ].join(';')
      )

      return productRows.join('\r\n')
    })
    .join('\r\n\r\n')

  // Combine the main table headers and rows (adding both tables together)
  let csvContent = mainTableHeaders + mainTableRows

  // Add empty lines between the main table and the products table
  csvContent += '\r\n\r\n'

  // Combine the products table headers and rows
  csvContent += productsTableHeaders + productsTableRows

  return csvContent
}

export function generateOrderXLS(jsonData: Order[]) {
  const headers = [
    'Order ID',
    'Subtotal price',
    'Tax',
    'Total price',
    'Currency',
    'Status',
    'Products',
    'Customer name',
    'Customer ID',
    'Customer type',
    'Address',
    'Automatic',
    'Date paid',
    'Payment due',
    'Payment status',
    'Payment method',
    'Payment provider',
    'Invoice identifier',
    'Invoice url',
    'Comment',
    'Created at',
    'Confirmed at',
    'Ordered at',
    'Delivered at',
  ]

  const mainTableData = jsonData.map(order => {
    const rowData: Record<string, any> = { ...order }
    rowData.products = getProductsString(order.products)
    rowData.address = `"${rowData.address}"`

    return Object.values(rowData)
  })

  const productsTableData = jsonData.flatMap(order =>
    order.products.map(product => [
      order.orderID,
      product.customId,
      product.product,
      parseFloat(product.weight),
      product.quantity,
      product.productPrice,
      product.cylinderDepositPrice,
      product.depositFee,
      product.totalPrice,
      product.taxRate,
      product.depositTaxRate,
      order.currency,
    ])
  )

  const worksheet = XLSX.utils.aoa_to_sheet([
    headers,
    ...mainTableData,
    [],
    [],
    [
      'Order ID',
      'Product ID',
      'Product',
      'Weight',
      'Quantity',
      'Product Price',
      'Cylinder deposit price',
      'Deposit fee',
      'Total Price',
      'Tax rate (%)',
      'Deposit tax rate (%)',
      'Currency',
    ],
    ...productsTableData,
  ])

  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders')

  return XLSX.write(workbook, { type: 'buffer', bookType: 'xls' })
}

export const downloadOrderData = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')

  // Set href for created link tag
  link.href = url
  link.setAttribute('download', filename)

  // Append to html link element page
  document.body.appendChild(link)

  // Downloading
  link.click()

  // Clean up and remove the link
  if (link.parentNode) link.parentNode.removeChild(link)
}

export const formatPrice = (price: number, currency: string) => {
  const formatter = new Intl.NumberFormat(navigator.language || 'en-GB', {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
    maximumFractionDigits: 2,
  })

  const formattedPrice = formatter.format(price || 0)
  return formattedPrice.replace(/\.00/, '')
}

export const formatTaxRate = (taxRate: any, distributor?: GQL.DistributorNode | null) => {
  let taxDisplayName = 'TAX'
  if (distributor) {
    taxDisplayName = distributor.defaultTaxDisplayName
  }
  const parsedTax = parseFloat(taxRate)
  const suffix = `% ${taxDisplayName}` // TODO: Add the proper tax abbreviation here
  if (Number.isNaN(parsedTax)) {
    return `0${suffix}`
  }
  return `${parsedTax}${suffix}`
}

export const getRowColor = (
  appContext: IAppContext,
  defaultColor: string,
  alertList: ('contact' | 'payment' | 'deliveryWindow' | 'freeze' | 'freezeout')[]
) => {
  if (paymentsEnabled(appContext) && alertList.filter(alert => alert !== 'freeze' && alert !== 'freezeout').length > 0) return 'pink'
  return defaultColor
}

export const translateOrdersAllColumn = (column: AllOrdersTableColumn, intl: IntlShape): string => {
  const t = intl.formatMessage

  if (column === AllOrdersTableColumn.ADDRESS) {
    return t({ id: 'common.address' })
  } else if (column === AllOrdersTableColumn.CUSTOMER_ID) {
    return t({ id: 'common.customer-id' })
  } else if (column === AllOrdersTableColumn.PRICE) {
    return t({ id: 'common.price' })
  } else if (column === AllOrdersTableColumn.PRODUCT) {
    return t({ id: 'common.product' })
  } else if (column === AllOrdersTableColumn.ORDERED_BY) {
    return t({ id: 'common.ordered_by' })
  } else if (column === AllOrdersTableColumn.DOWNLOAD_ORDER) {
    return t({ id: 'common.download-order' })
  } else if (column === AllOrdersTableColumn.DATE_ORDERED) {
    return t({ id: 'common.order-date' })
  } else if (column === AllOrdersTableColumn.VIEW_IMAGES) {
    return t({ id: 'common.order-view-images' })
  } else if (column === AllOrdersTableColumn.ORDER_ID) {
    return t({ id: 'common.order-id' })
  } else if (column === AllOrdersTableColumn.DATE_DELIVERED) {
    return t({ id: 'common.date-delivered' })
  } else if (column === AllOrdersTableColumn.COMMENT) {
    return t({ id: 'common.comment' })
  } else if (column === AllOrdersTableColumn.STATUS) {
    return t({ id: 'common.status' })
  }

  return column
}
