import React from 'react'
import { ModalProps } from 'components/Modal/Modal'
import * as GQL from 'generated/graphql'
import Lightbox from 'plasmic/Lightbox'
import ReactModal from 'react-modal'

interface ViewImageModalProps extends ModalProps {
  order: GQL.CylinderGroupOrderNode
}

export const common = {
  overlay: {
    zIndex: 9000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
  },
  content: {
    margin: '0 auto',
    left: 'unset',
    right: 'unset',
    top: 'unset',
    bottom: 'unset',
    border: 'none',
    background: 'transparent',
    borderRadius: '0px',
    padding: '0',
    overflow: 'visible',
    width: '100%',
    height: '100%',
  },
}

const ViewImageModal: React.FC<ViewImageModalProps> = ({ order, isOpen, onRequestClose }) => {
  return (
    <ReactModal onRequestClose={onRequestClose} isOpen={isOpen} style={common}>
      <Lightbox order={order} onRequestClose={onRequestClose} />
    </ReactModal>
  )
}

export default ViewImageModal
