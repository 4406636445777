/* eslint-disable react/jsx-pascal-case */
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import * as GQL from 'generated/graphql'
import Vehicles from 'plasmic/Vehicles'
import Drivers from 'plasmic/Drivers'
import DriverSettings from 'plasmic/DriverSettings'
import AddDriverAndOrUserModal from '../components/AddDriverAndOrUserModal'
import Select__Option from 'plasmic/Select__Option'
import VehicleRow from 'plasmic/VehicleRow'
import CornerLoader from 'components/Loader/CornerLoader'
import AddDatesModal from '../components/AddDatesModal'
import { useAppContext, useClickOutside } from 'util/hooks'
import MoreActions from 'plasmic/MoreActions'
import ActionDropdown from 'plasmic/ActionDropdown'
import ActionDropdownLine from 'plasmic/ActionDropdownLine'
import Workday from './Workday'
import VehicleModal from 'modules/settings/components/VehicleModal'
import FullLoader from 'components/Loader/FullLoader'
import ScIcon from 'components/Icon/ScIcon'
import { displayToast } from 'util/toasts'
import DeleteConfirmationModal from '../components/DeleteConfirmationModal'
// import { CallBackProps, EVENTS } from 'react-joyride'
/* Tutorials temporarily disabled
import Tour from 'components/Tour/Tour'
import steps from './DriversAndVehiclesTourSteps'
*/
import { format } from 'date-fns'
import CacheConfigs from 'util/cacheConfig'

const Content = styled.div`
  padding: 2rem 4rem 0;
  input[type='time']::-webkit-calendar-picker-indicator {
    filter: invert(1);
    opacity: 0.7;
  }
`
const DeleteDate = styled.div`
  width: 100%;
  text-align: right;
  position: absolute;
  right: 0;
`

interface IVehicleTempProps {
  create?: boolean
  name?: string
  description?: string
  depot?: string
  capacity?: number
  vehicleType?: string
  id?: string
}
interface IDeleteConfirmationTempProps {
  id?: string
  title?: string
  description?: string
  func?: any
}

const DriversAndVehicles: React.FC = () => {
  const {
    appContext: { depot: currentDepot },
  } = useAppContext()

  // eslint-disable-next-line
  const [_, setTourState] = useState({ run: true, stepIndex: 0 })
  const [createDriverModalOpen, setCreateDriverModalOpen] = useState(false)
  const [addDatesModalOpen, setAddDatesModalOpen] = useState(false)
  const [vehicleModalOpen, setVehicleModalOpen] = useState(false)
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false)
  const [deleteConfirmationTempInfo, setDeleteConfirmationTempInfo] = useState<IDeleteConfirmationTempProps>({})
  const [exceptionsVehicleId, setExceptionsVehicleId] = useState<string>('')
  const [moreActionsId, setVehicleMoreActionsId] = useState<string>('')
  const [vehicleTempInfo, setVehicleTempInfo] = useState<IVehicleTempProps>({ create: true, depot: currentDepot?.id })

  const { data: dataDrivers, loading: driversLoading } = GQL.useAllDrivers(CacheConfigs.ACCURATE_ONCE)
  const { data: dataVehicles, loading: vehiclesLoading } = GQL.useAllVehicles(CacheConfigs.ACCURATE_ONCE)
  const { data: dataDepots } = GQL.useAllDepots()

  const vehiclesMoreActionRef = useRef<any>()

  const [patchVehicle, { loading: patchVehicleLoading }] = GQL.usePatchVehicle({
    onError: error => {
      displayToast('Error occured!')
      error.message && displayToast(error.message)
    },
    update(cache, { data }) {
      if (currentDepot && data?.patchVehicle?.vehicle?.depot.id !== currentDepot?.id) {
        cache.modify({
          fields: {
            allVehicles(vehiclesRefs, { readField }) {
              return {
                ...vehiclesRefs,
                edges: vehiclesRefs.edges.filter((ref: any) => readField('id', ref.node) !== data?.patchVehicle?.vehicle?.id),
              }
            },
          },
        })
      }
    },
  })

  const [patchDriver, { loading: patchDriverLoading }] = GQL.usePatchDriver({
    onError: error => {
      displayToast('Error occured!')
      error.message && displayToast(error.message)
    },
    update(cache, { data }) {
      if (currentDepot && data?.patchDriver?.driver?.defaultDepot?.id !== currentDepot?.id) {
        cache.modify({
          fields: {
            allDrivers(driversRefs, { readField }) {
              return {
                ...driversRefs,
                edges: driversRefs.edges.filter((ref: any) => readField('id', ref.node) !== data?.patchDriver?.driver?.id),
              }
            },
          },
        })
      }
    },
  })
  const [deleteVehicle, { loading: deleteVehicleLoading }] = GQL.useDeleteVehicle({
    onCompleted: () => displayToast('Vehicle deleted successfully!', 'success', { toastId: null }),
    // refetchQueries: ['AllDrivers'],
    onError: error => {
      displayToast('Error occured!')
      error.message && displayToast(error.message)
    },
    update(cache, { data }) {
      const frag: any = cache.readFragment({
        id: cache.identify({ id: data?.deleteVehicle?.deletedId, __typename: 'VehicleNode' }),
        fragment: GQL.VehicleInfo,
        fragmentName: 'VehicleInfo',
      })
      cache.writeFragment({
        id: cache.identify(frag),
        fragment: GQL.VehicleInfo,
        fragmentName: 'VehicleInfo',
        data: { ...frag, deleted: true },
      })
      cache.modify({
        fields: {
          allVehicles(vehicles, { readField }) {
            return {
              ...vehicles,
              edges: vehicles?.edges?.filter((edge: GQL.VehicleNodeEdge) => readField('id', edge?.node!) !== data?.deleteVehicle?.deletedId),
            }
          },
        },
      })
    },
  })
  const [deleteDriver, { loading: deleteDriverLoading }] = GQL.useDeleteDriver({
    onError: error => {
      displayToast('Error occured!')
      error.message && displayToast(error.message)
    },
    onCompleted: () => displayToast('Driver deleted successfully!', 'success', { toastId: null }),
    update(cache, { data }) {
      const frag: any = cache.readFragment({
        id: cache.identify({ id: data?.deleteDriver?.deletedId!, __typename: 'DriverNode' }),
        fragment: GQL.DriverInfo,
        fragmentName: 'DriverInfo',
      })
      cache.writeFragment({
        id: cache.identify(frag),
        fragment: GQL.DriverInfo,
        fragmentName: 'DriverInfo',
        data: { ...frag, deleted: true },
      })
      cache.modify({
        fields: {
          allDrivers(drivers, { readField }) {
            return {
              ...drivers,
              edges: drivers?.edges?.filter((edge: GQL.DriverNodeEdge) => readField('id', edge?.node!) !== data?.deleteDriver?.deletedId) || [],
            }
          },
        },
      })
    },
  })
  const [deleteVehicleException, { loading: deleteVehicleExceptionLoading }] = GQL.useDeleteVehicleException({
    onError: error => {
      displayToast('Error occured!')
      error.message && displayToast(error.message)
    },
    update(cache, { data }) {
      cache.modify({
        // id: cache.identify(data?.deleteVehicleException!),
        fields: {
          allDrivers(driversRefs, { readField }) {
            return {
              ...driversRefs,
            }
          },
        },
      })
    },
    onCompleted: () => displayToast('Vehicle exception deleted successfully!', 'success', { toastId: null }),
  })

  const [createVehicleException, { loading: loadingCreateVehicleException }] = GQL.useCreateVehicleException({
    onError: error => {
      displayToast('Error occured!')
      error.message && displayToast(error.message)
    },
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(data?.createVehicleException?.vehicleExceptions?.vehicle!),
        fields: {
          exceptions(exceptionRefs) {
            const fragRef = cache.writeFragment({
              fragment: GQL.VehicleExceptions,
              data: data?.createVehicleException?.vehicleExceptions,
            })

            return [...exceptionRefs, fragRef]
          },
        },
      })
    },
  })

  const drivers =
    (dataDrivers?.allDrivers?.edges
      .map(edge => edge?.node)
      .filter(
        d => (!currentDepot || !d?.defaultDepot?.id === undefined || d?.defaultDepot?.id === currentDepot?.id) && !d?.deleted && !d?.user.isDeleted
      ) as GQL.DriverNode[]) || []
  const vehicles = (dataVehicles?.allVehicles?.edges.map(edge => edge?.node) as GQL.VehicleNode[]) || []

  const onDateSave = (date: Date) => {
    createVehicleException({
      variables: {
        input: {
          vehicle: exceptionsVehicleId,
          onDate: format(date, 'yyyy-MM-dd'),
        },
      },
    })
    setAddDatesModalOpen(false)
    setExceptionsVehicleId('')
  }

  const onRequestCloseVehicleModal = () => {
    setVehicleModalOpen(false)
    setVehicleTempInfo({ depot: currentDepot?.id })
    if (vehicles.length > 0) {
      setTourState(old => {
        return { run: old.run, stepIndex: 3 }
      })
    } else {
      setTourState({ run: false, stepIndex: 0 })
    }
  }

  const onRequestCloseAddDriverModal = () => {
    setCreateDriverModalOpen(false)
    if (drivers.length > 0) {
      setTourState(old => {
        return { run: old.run, stepIndex: 6 }
      })
    } else {
      setTourState({ run: false, stepIndex: 0 })
    }
  }

  const onRequestCloseDeleteConfirmationModal = () => {
    setDeleteConfirmationModalOpen(false)
    setDeleteConfirmationTempInfo({})
  }

  useClickOutside(vehiclesMoreActionRef, () => setVehicleMoreActionsId(''), true, true)
  const vehicleIconLookup = {
    TRUCK: 'sc-vehicle-truck',
    VAN: 'sc-vehicle-van',
    PICKUP: 'sc-vehicle-pickup',
  }

  // eslint-disable-next-line
  // const tourCallback = (data: CallBackProps) => {
  //   const { index, type } = data
  //   if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
  //     const nextStepIndex = index + 1
  //     setTourState(old => {
  //       return { run: old.run, stepIndex: nextStepIndex }
  //     })
  //   }
  // }

  return (
    <>
      {(patchVehicleLoading || patchDriverLoading || loadingCreateVehicleException) && <CornerLoader size={55} text='Updating!' />}
      {(deleteDriverLoading || deleteVehicleLoading || deleteVehicleExceptionLoading) && <CornerLoader size={55} text='Deleting!' />}
      <Content>
        {/*<Tour hideBackButton steps={steps} stepIndex={tourState.stepIndex} run={tourState.run} cb={tourCallback} name='deliveries_vehicles' />*/}
        <AddDatesModal
          submit={onDateSave}
          title='Add dates'
          isOpen={addDatesModalOpen}
          onRequestClose={() => {
            setAddDatesModalOpen(false)
            setExceptionsVehicleId('')
          }}
          selectedDays={vehicles?.filter(e => e.id === exceptionsVehicleId)[0]?.exceptions?.map(e => new Date(e?.onDate))}
        />
        <DeleteConfirmationModal
          isOpen={deleteConfirmationModalOpen}
          onRequestClose={onRequestCloseDeleteConfirmationModal}
          data={deleteConfirmationTempInfo}
        />
        <AddDriverAndOrUserModal isOpen={createDriverModalOpen} onRequestClose={onRequestCloseAddDriverModal} />
        <VehicleModal isOpen={vehicleModalOpen} onRequestClose={onRequestCloseVehicleModal} data={vehicleTempInfo} depots={dataDepots} />
        {vehiclesLoading || driversLoading ? (
          <FullLoader color='gray6' />
        ) : (
          <>
            <Vehicles
              btnNewVehicle={{
                id: 'btnNewVehicle',
                onClick: () => {
                  setVehicleTempInfo({ create: true, depot: currentDepot?.id })
                  setVehicleModalOpen(true)
                  setTourState(old => {
                    return { run: old.run, stepIndex: 2 }
                  })
                },
              }}
              vehicles={vehicles.map((vehicle: GQL.VehicleNode) => (
                <VehicleRow
                  data-testid='vehicle'
                  key={vehicle.id + 'vehicleRow1'}
                  inactive={!vehicle.active}
                  name={vehicle.name}
                  description={vehicle.registrationNumber}
                  capacity={vehicle.capacity}
                  capacitySuffix='kg'
                  selectVehicleType={{
                    props: {
                      icon: <ScIcon size='12px' color='#696d80' icon={vehicleIconLookup[vehicle.vehicleType]} />,
                      label: 'Select vehicle type',
                      onChange: (e: any) => {
                        patchVehicle({
                          variables: {
                            id: vehicle.id,
                            input: { vehicleType: e },
                          },
                        })
                      },
                      value: vehicle.vehicleType || '',
                    },
                  }}
                  selectDepot={{
                    props: {
                      label: 'select depot',
                      value: vehicle?.depot.id || '',
                      onChange: (e: any) => {
                        patchVehicle({
                          variables: {
                            id: vehicle.id,
                            input: { depot: e },
                          },
                        })
                      },
                      children: dataDepots?.allDepots?.edges.map(depot => (
                        <Select__Option key={depot?.node?.id + 'f4345grtg'} value={depot?.node?.id}>
                          {depot?.node?.name}
                        </Select__Option>
                      )),
                    },
                  }}
                  selectUnavailable={{
                    props: {
                      label: 'select availability',
                      placeholder: vehicle?.exceptions ? vehicle?.exceptions?.length + ' day(s)' : 'never',
                      value: 'no value',
                      onChange: (e: any) => {
                        if (e === 'new_date') {
                          setExceptionsVehicleId(vehicle.id)
                          setAddDatesModalOpen(true)
                        } else {
                          deleteVehicleException({
                            update(cache) {
                              cache.modify({
                                id: cache.identify(vehicle),
                                fields: {
                                  exceptions(exceptionRefs, { readField }) {
                                    return [...exceptionRefs.filter((ref: any) => readField('id', ref) !== e)]
                                  },
                                },
                              })
                            },
                            variables: {
                              id: e,
                            },
                          })
                        }
                      },
                      children: [
                        <Select__Option value={'new_date'} action={true}>
                          Add Date
                        </Select__Option>,
                        vehicle.exceptions?.map((exception: GQL.VehicleExceptionNode | null) => (
                          <Select__Option key={exception?.id + 'das38'} value={exception?.id}>
                            <div style={{ position: 'relative', display: 'flex', minWidth: '120px', cursor: 'pointer' }}>
                              {exception?.onDate} <DeleteDate>❌</DeleteDate>
                            </div>
                          </Select__Option>
                        )),
                      ],
                    },
                  }}
                  btnMoreActions={
                    <MoreActions
                      ref={moreActionsId === vehicle.id ? vehiclesMoreActionRef : null}
                      open={moreActionsId === vehicle.id ? true : false}
                      btnMoreActions={{
                        onClick: () => (moreActionsId === vehicle.id ? setVehicleMoreActionsId('') : setVehicleMoreActionsId(vehicle.id)),
                      }}
                      actionDropdown={
                        <ActionDropdown
                          children={
                            <>
                              <ActionDropdownLine
                                onClick={() => {
                                  setVehicleMoreActionsId('')
                                  patchVehicle({
                                    variables: {
                                      id: vehicle.id,
                                      input: { active: !vehicle.active },
                                    },
                                  })
                                }}
                              >
                                {vehicle.active ? 'Inactivate' : 'Activate'}
                              </ActionDropdownLine>
                              <ActionDropdownLine
                                onClick={() => {
                                  setVehicleMoreActionsId('')
                                  setVehicleModalOpen(true)
                                  setVehicleTempInfo({
                                    create: false,
                                    id: vehicle.id,
                                    name: vehicle.name,
                                    depot: vehicle.depot?.id || undefined,
                                    description: vehicle.registrationNumber || undefined,
                                    capacity: vehicle.capacity || undefined,
                                    vehicleType: vehicle.vehicleType || undefined,
                                  })
                                }}
                              >
                                Edit Vehicle
                              </ActionDropdownLine>
                              <ActionDropdownLine
                                onClick={() => {
                                  setVehicleMoreActionsId('')
                                  setDeleteConfirmationModalOpen(true)
                                  setDeleteConfirmationTempInfo({
                                    title: 'Confirm Deleting Vehicle',
                                    description: vehicle.hasUpcomingRoutes
                                      ? `Vehicle (${vehicle.name}) you are trying to delete is assigned in upcoming routes. Those routes will be cancelled.`
                                      : `Are you sure you want to delete vehicle ${vehicle.name} (${vehicle.registrationNumber})?`,
                                    func: deleteVehicle,
                                    id: vehicle.id,
                                  })
                                }}
                              >
                                Delete Vehicle
                              </ActionDropdownLine>
                            </>
                          }
                        />
                      }
                    />
                  }
                />
              ))}
            />
            <Drivers
              btnNewDriver={{
                id: 'btnNewDriver',
                onClick: () => {
                  setCreateDriverModalOpen(true)
                  setTourState(old => {
                    return { run: old.run, stepIndex: 5 }
                  })
                },
              }}
              drivers={drivers.map((driver, index) => (
                <DriverSettings
                  inactive={!driver.active}
                  id={index === 0 ? 'driver_panel' : ''}
                  data-testid='driver'
                  btnActivate={{
                    onClick: () =>
                      patchDriver({
                        variables: {
                          id: driver.id,
                          input: {
                            active: true,
                          },
                        },
                      }),
                  }}
                  key={driver.id}
                  name={driver.user.fullName}
                  moreActions={
                    <MoreActions
                      ref={moreActionsId === driver.id ? vehiclesMoreActionRef : null}
                      open={moreActionsId === driver.id ? true : false}
                      btnMoreActions={{
                        onClick: () => (moreActionsId === driver.id ? setVehicleMoreActionsId('') : setVehicleMoreActionsId(driver.id)),
                      }}
                      actionDropdown={
                        <ActionDropdown
                          children={
                            <>
                              <ActionDropdownLine
                                onClick={() => {
                                  setVehicleMoreActionsId('')
                                  patchDriver({
                                    variables: {
                                      id: driver.id,
                                      input: {
                                        active: !driver.active,
                                      },
                                    },
                                  })
                                }}
                              >
                                {driver.active ? 'Inactivate' : 'Activate'}
                              </ActionDropdownLine>
                              <ActionDropdownLine
                                onClick={() => {
                                  setVehicleMoreActionsId('')
                                  setDeleteConfirmationModalOpen(true)
                                  setDeleteConfirmationTempInfo({
                                    title: 'Confirm Deleting Driver',
                                    description: driver.hasUpcomingRoutes
                                      ? `Driver (${driver.user.fullName}) you are trying to delete is assigned in upcoming routes. Those routes will be cancelled. \nDo you still want to delete?`
                                      : `Are you sure you want to delete driver ${driver.user.fullName}?`,
                                    func: deleteDriver,
                                    id: driver.id,
                                  })
                                }}
                              >
                                Delete Driver
                              </ActionDropdownLine>
                            </>
                          }
                        />
                      }
                    />
                  }
                  selectVehicle={{
                    props: {
                      label: 'Default vehicle select',
                      value: driver?.defaultVehicle?.id,
                      icon: driver?.defaultVehicle?.vehicleType ? (
                        <ScIcon color='#696d80' size='12px' icon={vehicleIconLookup[driver?.defaultVehicle?.vehicleType]} />
                      ) : (
                        ''
                      ),
                      onChange: (e: any) => {
                        patchDriver({
                          variables: {
                            id: driver.id,
                            input: { defaultVehicle: e },
                          },
                        })
                      },
                      children: [
                        ...vehicles.filter(e => !e.deleted),
                        ...(vehicles?.some(v => v.id === driver.defaultVehicle?.id)
                          ? []
                          : driver.defaultVehicle && !driver.defaultVehicle.deleted
                            ? [driver.defaultVehicle]
                            : []),
                      ].map(vehicle => <Select__Option value={vehicle?.id}>{vehicle?.name}</Select__Option>),
                    },
                  }}
                  selectDepot={{
                    props: {
                      label: 'Default depot select',
                      value: driver.defaultDepot?.id,
                      onChange: (e: any) => {
                        patchDriver({
                          variables: {
                            id: driver.id,
                            input: { defaultDepot: e },
                          },
                        })
                      },
                      children: dataDepots?.allDepots?.edges.map(depot => <Select__Option value={depot?.node?.id}>{depot?.node?.name}</Select__Option>),
                    },
                  }}
                  workdays={
                    <>
                      <Workday day={'M'} data={driver.standardWorkhours?.filter((e: any) => e.dow === 1).find((e: any) => true)} driver={driver} dow={1} />
                      <Workday day={'T'} data={driver.standardWorkhours?.filter((e: any) => e.dow === 2).find((e: any) => true)} driver={driver} dow={2} />
                      <Workday day={'W'} data={driver.standardWorkhours?.filter((e: any) => e.dow === 3).find((e: any) => true)} driver={driver} dow={3} />
                      <Workday day={'T'} data={driver.standardWorkhours?.filter((e: any) => e.dow === 4).find((e: any) => true)} driver={driver} dow={4} />
                      <Workday day={'F'} data={driver.standardWorkhours?.filter((e: any) => e.dow === 5).find((e: any) => true)} driver={driver} dow={5} />
                      <Workday day={'S'} data={driver.standardWorkhours?.filter((e: any) => e.dow === 6).find((e: any) => true)} driver={driver} dow={6} />
                      <Workday day={'S'} data={driver.standardWorkhours?.filter((e: any) => e.dow === 0).find((e: any) => true)} driver={driver} dow={0} />
                    </>
                  }
                />
              ))}
            />
          </>
        )}
      </Content>
    </>
  )
}

export default DriversAndVehicles
