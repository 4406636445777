import React from 'react'

const MapMarker = props => (
  <svg viewBox='0 0 18.2 22' {...props}>
    <path
      d='M18.2,9.1c0-5-4.1-9.1-9.1-9.1C4.1,0,0,4.1,0,9.1c0,2.4,0.9,4.6,2.5,6.2h0L9.1,22l6.7-6.7h0C17.3,13.7,18.2,11.5,18.2,9.1z
	 M9.1,14.6c-3,0-5.5-2.4-5.5-5.5s2.4-5.5,5.5-5.5s5.5,2.4,5.5,5.5S12.1,14.6,9.1,14.6z'
    />
  </svg>
)

export default MapMarker
