import * as GQL from 'generated/graphql'

export const ORDER_SET_STATE_TRANSLATION = {
  [GQL.CylinderGroupOrderStates.Created]: 'orders.set-order-placed',
  [GQL.CylinderGroupOrderStates.AwaitingDelivery]: 'orders.status.awaiting-delivery',
  [GQL.CylinderGroupOrderStates.Delivered]: 'orders.set-delivered',
  [GQL.CylinderGroupOrderStates.Cancelled]: 'orders.status.cancelled',
  [GQL.CylinderGroupOrderStates.OrderRequest]: 'orders.status.order-request',
  [GQL.CylinderGroupOrderStates.Paused]: 'orders.status.verb.pause-order',
}

export const PAUSED_ORDER_SET_STATE_TRANSLATION = {
  [GQL.CylinderGroupOrderStates.Created]: 'orders.status.verb.resume-order',
  [GQL.CylinderGroupOrderStates.Cancelled]: 'orders.status.verb.cancel-order',
  [GQL.CylinderGroupOrderStates.AwaitingDelivery]: undefined,
  [GQL.CylinderGroupOrderStates.Delivered]: undefined,
  [GQL.CylinderGroupOrderStates.OrderRequest]: undefined,
  [GQL.CylinderGroupOrderStates.Paused]: undefined,
}

export const ORDER_SET_STATE_COLOR = {
  [GQL.CylinderGroupOrderStates.Created]: 'violet',
  [GQL.CylinderGroupOrderStates.AwaitingDelivery]: 'blue',
  [GQL.CylinderGroupOrderStates.Delivered]: 'green',
  [GQL.CylinderGroupOrderStates.Cancelled]: 'red',
  [GQL.CylinderGroupOrderStates.OrderRequest]: 'violet',
  [GQL.CylinderGroupOrderStates.Paused]: 'gray',
}

export const ACTIVE_ORDER_STATES = [GQL.CylinderGroupOrderStates.Created, GQL.CylinderGroupOrderStates.AwaitingDelivery, GQL.CylinderGroupOrderStates.Paused]
