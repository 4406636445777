// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type IconMoresvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function IconMoresvgIcon(props: IconMoresvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 30.935 30.935"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <circle cx={"4.583"} cy={"15.467"} r={"3.083"}></circle>

      <circle cx={"15.467"} cy={"15.467"} r={"3.083"}></circle>

      <circle cx={"26.352"} cy={"15.467"} r={"3.083"}></circle>
    </svg>
  );
}

export default IconMoresvgIcon;
/* prettier-ignore-end */
