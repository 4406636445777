import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'

import { useCustomerContext, useKeyDown } from 'util/hooks'
import CustomersDrawerInner from './CustomersDrawerInner'
import CustomerModalWrapper from 'plasmic/CustomerModalWrapper'

const MOUSE_DOWN_EVENT_TYPE = 'mousedown'
const BACKGROUND_DIMMER_ID = 'customer-drawer-background-dimmer'

const BackgroundDimmer = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 1010;
  background-color: rgba(0, 0, 0, 0.45);
`

const Drawer = styled.div`
  height: 90%;
  width: 94%;
  max-width: 1500px;
`

const CustomersDrawer: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const customer = new URLSearchParams(location.search).get('customer')
  const { setSource, customersContext } = useCustomerContext()

  const nextCustomer = useCallback(
    (id: string) => {
      const index = customersContext?.findIndex(customer => customer.id === id)
      if (index === undefined || index < 0 || !customersContext) return

      if (index >= customersContext.length - 1) return navigate(`?customer=${customersContext[0].id}`)

      navigate(`?customer=${customersContext[index + 1].id}`)
    },
    [customersContext, navigate]
  )

  const previousCustomer = useCallback(
    (id: string) => {
      const index = customersContext?.findIndex(customer => customer.id === id)
      if (index === undefined || index < 0 || !customersContext) return
      if (index < 1) return navigate(`?customer=${customersContext[customersContext.length - 1].id}`)
      navigate(`?customer=${customersContext[index - 1].id}`)
    },
    [customersContext, navigate]
  )

  const handleClose = (e: any) => {
    // Workaround for https://smartcylinders.atlassian.net/browse/SW-241
    if (e?.type === MOUSE_DOWN_EVENT_TYPE && e?.target?.id !== BACKGROUND_DIMMER_ID) {
      return
    }
    setSource(undefined)
    navigate({
      pathname: location.pathname,
    })
  }

  useKeyDown(['Escape'], handleClose)
  useKeyDown(['ArrowLeft'], () => customer && previousCustomer(customer))
  useKeyDown(['ArrowRight'], () => customer && nextCustomer(customer))

  return (
    <>
      {customer && (
        <BackgroundDimmer id={BACKGROUND_DIMMER_ID} onMouseDown={handleClose}>
          <Drawer>
            <CustomerModalWrapper
              arrows
              btnNext={{ onClick: () => nextCustomer(customer) }}
              btnPrevious={{ onClick: () => previousCustomer(customer) }}
              customerModalDraft={<CustomersDrawerInner customerId={customer} onClose={handleClose} />}
            />
          </Drawer>
        </BackgroundDimmer>
      )}
    </>
  )
}

export default CustomersDrawer
