import React from 'react'
import Select from 'components/Select'
import { ISelectProps } from 'components/Select/Select'
import * as GQL from 'generated/graphql'

interface ISelectDepot extends Omit<ISelectProps, 'options'> {
  depots?: GQL.DepotNode[]
}

const SelectDepot: React.FC<ISelectDepot> = ({ defaultValue, depots, onChange, ...props }) => {
  let allDepots: GQL.DepotNode[] = []
  const { data } = GQL.useAllDepots()

  allDepots = data?.allDepots?.edges.map(edge => edge?.node as GQL.DepotNode) || []

  const useDepots = depots || allDepots

  const options = useDepots.map(depot => ({
    value: depot.id!,
    label: depot.name!,
  }))

  return <Select options={options} defaultValue={defaultValue} onChange={onChange} {...props} />
}

export default SelectDepot
