import { parseISO } from 'date-fns'
import * as GQL from 'generated/graphql'
import { $enum } from 'ts-enum-util'

export const getAvailableOrderStates = (cylinderGroupOrder: GQL.CylinderGroupOrderNode): GQL.CylinderGroupOrderStates[] => {
  // Can't go from cancelled or delivered to any other state
  if (!cylinderGroupOrder.state) {
    return []
  } else if ([GQL.CylinderGroupOrderStates.Cancelled, GQL.CylinderGroupOrderStates.Delivered].includes(cylinderGroupOrder.state)) {
    return []
  } else if (
    cylinderGroupOrder.paymentSetting?.method === GQL.PaymentMethods.InAdvance &&
    cylinderGroupOrder.paymentStatus === GQL.CylinderGroupOrderPaymentStates.NotPaid
  ) {
    return [GQL.CylinderGroupOrderStates.Cancelled, GQL.CylinderGroupOrderStates.Paused]
  } else if ([GQL.CylinderGroupOrderStates.Paused].includes(cylinderGroupOrder.state)) {
    // If order is paused, only allow to mutate order to Created or Cancelled
    return [GQL.CylinderGroupOrderStates.Created, GQL.CylinderGroupOrderStates.Cancelled]
  } else {
    return $enum(GQL.CylinderGroupOrderStates)
      .getValues()
      .filter(s => s !== cylinderGroupOrder.state)
  }
}

export const getAvailableOrderStatusOptions = (
  cylinderGroupOrder: GQL.CylinderGroupOrderNode
): 'orderPlaced' | 'orderPaused' | 'awaitingDelivery' | 'pendingAdvance' | undefined => {
  if (
    !cylinderGroupOrder.state ||
    cylinderGroupOrder.state === GQL.CylinderGroupOrderStates.Cancelled ||
    cylinderGroupOrder.state === GQL.CylinderGroupOrderStates.Delivered
  ) {
    return undefined
  } else if (
    cylinderGroupOrder.paymentSetting?.method === GQL.PaymentMethods.InAdvance &&
    cylinderGroupOrder.paymentStatus === GQL.CylinderGroupOrderPaymentStates.NotPaid
  ) {
    if (cylinderGroupOrder.state === GQL.CylinderGroupOrderStates.Paused) {
      return 'orderPaused'
    } else {
      return 'pendingAdvance'
    }
  } else if (cylinderGroupOrder.state === GQL.CylinderGroupOrderStates.Created) {
    return 'orderPlaced'
  } else if (cylinderGroupOrder.state === GQL.CylinderGroupOrderStates.AwaitingDelivery) {
    return 'awaitingDelivery'
  } else if (cylinderGroupOrder.state === GQL.CylinderGroupOrderStates.Paused) {
    return 'orderPaused'
  }
  return undefined
}

export const getStateColorLabel = (state?: GQL.CylinderGroupOrderStates): string => {
  return $enum.mapValue(state).with({
    [GQL.CylinderGroupOrderStates.Created]: 'purple',
    [GQL.CylinderGroupOrderStates.AwaitingDelivery]: 'blue',
    [GQL.CylinderGroupOrderStates.Delivered]: 'green',
    [GQL.CylinderGroupOrderStates.Cancelled]: 'red',
    [GQL.CylinderGroupOrderStates.OrderRequest]: 'violet',
    [GQL.CylinderGroupOrderStates.Paused]: 'gray',
    [$enum.handleUndefined]: 'blue',
  })
}

/**
 * Sorts an array of cylinder sides first by priority and then by ID.
 *
 * This function takes an array of `CylinderGroupSideNode` objects and sorts them in ascending order based on their priority.
 * In cases where two sides have the same priority, it then sorts them based on their ID.
 * If the sides array is null or undefined, it returns an empty array.
 *
 * @param {GQL.Maybe<GQL.CylinderGroupSideNode>[]} sides - An array of cylinder side objects, which may be null or undefined.
 *
 * @returns {GQL.CylinderGroupSideNode[]} An array of `CylinderGroupSideNode` objects sorted by priority and then by ID.
 *
 */
export const handlePriorityCylinderSideSorting = (sides: GQL.Maybe<GQL.CylinderGroupSideNode>[]) => {
  return [...(sides || [])].sort((a, b) => {
    if (a?.priority! !== b?.priority!) {
      if (a?.priority! < b?.priority!) {
        return -1
      }
      if (a?.priority! > b?.priority!) {
        return 1
      }
    }
    if (a?.id! < b?.id!) {
      return -1
    }
    if (a?.id! > b?.id!) {
      return 1
    }

    return 0
  }) as GQL.CylinderGroupSideNode[]
}

/**
 * Sorts an array of cylinder sides based on gas state, remaining gas percentage, and estimated empty date.
 *
 * This function evaluates each cylinder side based on its current gas state (Empty or Full),
 * the remaining gas percentage, and the estimated date when it will be empty.
 * It prioritizes Empty states over others, then Full states, followed by remaining gas percentage, and finally the estimated empty date.
 * If the sides array is null or undefined, it returns an empty array.
 *
 * @param {GQL.Maybe<GQL.CylinderGroupSideNode>[]} sides - An array of cylinder side objects, which may be null or undefined.
 *
 * @returns {GQL.CylinderGroupSideNode[]} An array of `CylinderGroupSideNode` objects sorted based on the defined criteria.
 *
 */
export const handleRemainingGasSideSorting = (sides: GQL.Maybe<GQL.CylinderGroupSideNode>[]) => {
  return [...(sides || [])].sort((a, b) => {
    const getEstimatedEmptyDate = (side: GQL.Maybe<GQL.CylinderGroupSideNode>) => {
      return side?.estimatedEmpty ? parseISO(side.estimatedEmpty) : new Date(0)
    }

    const aRemainingGas = getRemainingGas(a?.activeCycle).percentage || 0
    const bRemainingGas = getRemainingGas(b?.activeCycle).percentage || 0

    // Check if either side is Empty, and prioritize it
    if (a?.activeCycle?.state === GQL.CycleState.Empty && b?.activeCycle?.state !== GQL.CycleState.Empty) {
      return -1
    }
    if (b?.activeCycle?.state === GQL.CycleState.Empty && a?.activeCycle?.state !== GQL.CycleState.Empty) {
      return 1
    }

    // Check if either side is FULL, and prioritize it
    if (a?.activeCycle?.state === GQL.CycleState.Full && b?.activeCycle?.state !== GQL.CycleState.Full) {
      return 1
    }
    if (b?.activeCycle?.state === GQL.CycleState.Full && a?.activeCycle?.state !== GQL.CycleState.Full) {
      return -1
    }

    if (aRemainingGas !== bRemainingGas) {
      return aRemainingGas - bRemainingGas
    }

    const aEstimatedEmptyDate = getEstimatedEmptyDate(a)
    const bEstimatedEmptyDate = getEstimatedEmptyDate(b)

    if (aEstimatedEmptyDate.getTime() !== bEstimatedEmptyDate.getTime()) {
      return aEstimatedEmptyDate.getTime() - bEstimatedEmptyDate.getTime()
    }

    return 0 // If everything is equal, don't sort.
  }) as GQL.CylinderGroupSideNode[]
}

/**
 * Retrieves the remaining gas information for a given cycle.
 * NOTE: This function should be identical to the one used in the customer app.
 *
 * @param {GQL.CycleNode | null} [activeCycle] - The cycle object containing gas information. It's an optional parameter.
 *                                               If not provided, or if it's null, the function returns null values.
 *
 * @returns An object containing two properties:
 *   - `weight`: The remaining weight of the gas in the cycle. It's a number or null if not available.
 *   - `percentage`: The remaining percentage of the gas in the cycle. It's a number or null if not available.
 *
 */
export const getRemainingGas = (activeCycle?: GQL.CycleNode | null): { weight: number | null; percentage: number | null } => {
  if (!activeCycle || !activeCycle.remainingGasWeight || !activeCycle.remainingPercentage) {
    return {
      weight: null,
      percentage: null,
    }
  }

  return {
    weight: activeCycle.remainingGasWeight,
    percentage: activeCycle.remainingPercentage,
  }
}
