import React, { useEffect, useState } from 'react'
import { useDebounce } from 'util/hooks'
import styled from 'styled-components'
import Input from './Input'
import { GeocodingSuggestionNode, useGetGeocodingSuggestionsCancellable } from 'generated/graphql'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

interface ISuggestionsProps {
  open?: boolean
  hasOutsideIcon?: boolean
}

const Suggestions = styled.div<ISuggestionsProps>`
  display: ${props => (props.open ? 'block' : 'none')};
  background: ${props => props.theme.colors.primaryDark};
  padding: 1em;
  position: absolute;
  top: 50px;
  left: 0;
  ${props =>
    props.hasOutsideIcon &&
    `
    left: 3rem;
  `}
  border: 1px solid ${props => props.theme.colors.gray2};
  right: 0;
  margin: 0;
  max-height: 45vh;
  overflow-y: auto;

  box-sizing: border-box;
a
  list-style: none;

  z-index: 1000;
`

const Suggestion = styled.div<{}>`
  text-align: left;
  box-sizing: border-box;

  padding: 0.5em;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.blue};
  }
`

interface IAddressAutoComplete {
  id?: string
  value?: string
  name?: string
  placeholder?: string
  onSelect: (value: GeocodingSuggestionNode) => void
  outsideIcon?: JSX.Element | null
  ref?: any
}

const AddressAutoComplete = React.forwardRef(({ id, name, value, onSelect, placeholder, outsideIcon }: IAddressAutoComplete, ref) => {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setSearch(value || '')
  }, [value])

  const { data, loading, error } = useGetGeocodingSuggestionsCancellable({ search: debouncedSearch })

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!open) setOpen(true)
    setSearch(e.target.value)
  }

  function handleSelect(suggestion: GeocodingSuggestionNode | null) {
    if (open) setOpen(false)
    if (suggestion) {
      onSelect(suggestion) // or any other relevant data from the suggestion
      setOpen(false) // Close the suggestions dropdown
    }
  }

  return (
    <Wrapper>
      <Input
        id={id}
        placeholder={placeholder}
        name={name}
        value={search}
        error={error?.message}
        ref={ref}
        outsideIcon={outsideIcon}
        onChange={handleInputChange}
      />
      <Suggestions className='autocomplete-dropdown-container' open={open && !loading && data !== undefined} hasOutsideIcon={!!outsideIcon}>
        {data?.geocodingSuggestions &&
          data.geocodingSuggestions.map((suggestion, index) => (
            <Suggestion key={index} onClick={() => handleSelect(suggestion)}>
              {suggestion?.placeName}
            </Suggestion>
          ))}
      </Suggestions>
    </Wrapper>
  )
})

export default AddressAutoComplete
