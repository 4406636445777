import { useMemo, useState } from 'react'
import styled from 'styled-components'
import Loader from 'components/Loader/Loader'
import * as GQL from 'generated/graphql'
import { format } from 'date-fns'
import { parseISO } from 'date-fns'

import FlexTable from 'plasmic/FlexTable'
import TableRow from 'plasmic/TableRow'
import CellCustomer from 'plasmic/CellCustomer'
import CellAddress from 'plasmic/CellAddress'
import CellEstimatedEmpty from 'plasmic/CellEstimatedEmpty'
import TableHeadTitle from 'plasmic/TableHeadTitle'
import EstimatedEmpty from 'components/Table/Cells/EstimatedEmpty'
import FlexTableWrapper from 'plasmic/FlexTableWrapper'
import CellProduct from 'plasmic/CellProduct'
import CellStatus from 'plasmic/CellStatus'
import CellLastDeliveryDate from 'plasmic/CellLastDeliveryDate'
import CellComment from 'plasmic/CellComment'
import Tooltip from 'components/Tooltip'
import { useNavigate } from 'react-router-dom'
import { ProductLabel } from 'modules/orders/util'
import { useCustomerContext } from 'util/hooks'
import { useIntl } from 'react-intl'
import { CustomerDrawerListSource } from 'context/CustomerContext'
import ScrollIndicator from 'plasmic/ScrollIndicator'
import { CenteredLoader } from 'components/Loader/LoaderWrappers'

const Content = styled.div``

const Comment = styled.div`
  padding: 0;
  margin: 0;
  align-items: center;
  align-content: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
`

export const getStatusString = (status: GQL.CylinderGroupOrderStates) => {
  return status.replace(/_/g, ' ').capitalizeFirst()
}

const PAGE_SIZE = 15

const UnmanagableDeliveries = () => {
  const [loadFetchMore, setLoadFetchMore] = useState(false)

  const {
    data: ordersData,
    loading: loadingOrdersData,
    fetchMore,
  } = GQL.useAllUnassginedRoutes({
    variables: {
      first: PAGE_SIZE,
    },
  })

  const intl = useIntl()
  const t = intl.formatMessage
  const navigate = useNavigate()

  const { setSource, setCustomersContext } = useCustomerContext()

  const translateOrderComment = (orderComment: string) => {
    switch (orderComment) {
      case 'CUSTOMER_ADDRESS_INVALID':
        return t({ id: 'order.comment.customerAddressInvalid' })
      case 'CUSTOMER_ADDRESS_OUT_OF_REACH':
        return t({ id: 'order.comment.customerAddressOutOfReach' })
      case 'CUSTOMER_DEPOT_MISSING':
        return t({ id: 'order.comment.customerDepotMissing' })
      case 'TIME_OR_CAPACITY_CONSTRAINTS':
        return t({ id: 'order.comment.timeOrCapacityConstraints' })
      default:
        return orderComment
    }
  }

  const orders = useMemo(() => {
    return ordersData?.allUnassignedRoutes?.edges.map(edge => edge?.node?.order as GQL.CylinderGroupOrderNode) || []
  }, [ordersData])

  const pageInfo = useMemo(() => {
    return ordersData?.allUnassignedRoutes?.pageInfo
  }, [ordersData])

  return (
    <Content>
      <FlexTableWrapper
        tables={
          <FlexTable
            noCheckbox
            noSorting
            children={<TableHeadTitle title='Unmanagable orders' count={`(${ordersData?.allUnassignedRoutes?.totalCount || orders.length})`} />}
            rows={
              <>
                {loadingOrdersData ? (
                  <CenteredLoader sizeAuto>
                    <Loader color='gray6' size={32} />
                  </CenteredLoader>
                ) : (
                  orders.map((order, num) => (
                    <TableRow
                      noCheckbox
                      key={order.cylinderGroup.id + 'TableRowCustomers' + num}
                      sticky={
                        <CellCustomer
                          onClick={() => {
                            setSource(CustomerDrawerListSource.UNMANAGABLE_DELIVERIES)
                            setCustomersContext(orders?.map(order => order.cylinderGroup.customer) || [])
                            navigate(`?customer=${order.cylinderGroup?.customer.id}`)
                          }}
                          name={order.cylinderGroup?.customer.name}
                        />
                      }
                      scrollCells={
                        <>
                          <CellProduct contentWrapper={<ProductLabel cylinderGroupOrder={order} />} />
                          <CellAddress noHover address={order.cylinderGroup.customer.address.firstLine} />
                          <CellStatus fromElement={order} editable={false} />
                          <Tooltip
                            $style={{ whiteSpace: 'normal', width: '10rem', fontSize: '0.9rem' }}
                            placement='top'
                            trigger='mouseenter'
                            arrow={false}
                            disabled={!!!order.comment}
                            content={translateOrderComment(order.comment)}
                          >
                            <CellComment content={<Comment>{translateOrderComment(order.comment) || '-'}</Comment>} />
                          </Tooltip>
                          <CellLastDeliveryDate content={order.lastPossibleDelivery ? format(parseISO(order?.lastPossibleDelivery), 'yyyy-MM-dd') : '-'} />
                          <CellEstimatedEmpty content={<EstimatedEmpty cylinderGroup={order.cylinderGroup} displayThreshold={false} />} />
                        </>
                      }
                    />
                  ))
                )}
                {!loadingOrdersData && (
                  <ScrollIndicator
                    tableRow
                    loaded={orders.length.toString()}
                    total={(ordersData?.allUnassignedRoutes?.totalCount || orders.length).toString()}
                    btnLoadMore={{
                      ...(loadFetchMore && { children: <Loader color='white' /> }),
                      onClick: () => {
                        if (pageInfo?.hasNextPage && pageInfo?.endCursor) {
                          setLoadFetchMore(true)
                          fetchMore({
                            variables: {
                              first: PAGE_SIZE,
                              after: pageInfo.endCursor,
                            },
                            updateQuery(prev, { fetchMoreResult }) {
                              if (!fetchMoreResult) return prev
                              return {
                                ...fetchMoreResult,
                                allUnassignedRoutes: {
                                  ...fetchMoreResult?.allUnassignedRoutes,
                                  edges: [
                                    ...(prev?.allUnassignedRoutes?.edges ? prev.allUnassignedRoutes.edges : []),
                                    ...(fetchMoreResult?.allUnassignedRoutes?.edges ? fetchMoreResult.allUnassignedRoutes.edges : []),
                                  ],
                                },
                              } as GQL.AllOrdersRouteFooter
                            },
                          }).finally(() => {
                            setLoadFetchMore(false)
                          })
                        }
                      },
                    }}
                  />
                )}
              </>
            }
            visibleColumns={['address', 'product', 'estimatedEmpty', 'status', 'comment', 'lastDeliveryDate']}
          />
        }
      />
    </Content>
  )
}

export default UnmanagableDeliveries
