import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { parseISO, differenceInCalendarDays } from 'date-fns'

import * as GQL from 'generated/graphql'
import Progress from 'components/Progress'
import { getCylinderGroupSideProps, getEstimatedEmptyTooltip, isReporting } from 'modules/customers/util'
import ProgressBarPercent from 'plasmic/ProgressBarPercent'
import Tooltip from 'components/Tooltip'
import { handleRemainingGasSideSorting } from 'modules/hardware/util'

const Cell = styled.div<{ align: string }>`
  display: flex;
  width: 100%;>
  height: 100%;
  justify-content: ${props => props.align};
  align-items: ${props => props.align};
  gap: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
`

interface EstimatedEmptyProps {
  cylinderGroup?: GQL.CylinderGroupNode
  displayThreshold?: boolean
}

const EstimatedEmpty: React.FC<EstimatedEmptyProps> = ({ cylinderGroup, displayThreshold = true }) => {
  const [mouseOver, setMouseOver] = useState(false)

  const sensorSides = useMemo(() => {
    return cylinderGroup && cylinderGroup.cylinderSides ? cylinderGroup.cylinderSides?.filter(side => side?.sensors && side?.sensors?.length > 0) || [] : []
  }, [cylinderGroup])

  const sortedSides = useMemo(() => {
    return cylinderGroup?.cylinderSides ? handleRemainingGasSideSorting(cylinderGroup?.cylinderSides) : []
  }, [cylinderGroup])

  const twoSensorsAbnormalState = useMemo(() => {
    return (
      sensorSides.length > 1 &&
      sensorSides.every(
        side =>
          (side?.activeCycle?.state &&
            [GQL.CycleState.Error, GQL.CycleState.NotInUse, GQL.CycleState.Unknown].includes(side.activeCycle.state as GQL.CycleState)) ||
          (side && !isReporting(side))
      )
    )
  }, [sensorSides])

  const showPercentage = useMemo(() => {
    return !localStorage.getItem('percentageView')
  }, [])

  if (sensorSides.length === 0) {
    return (
      <Cell align='center'>
        <i>No sensor</i>
      </Cell>
    )
  }

  const stock = cylinderGroup?.stock
  const hasStock = stock && (stock.currentEmpty > 0 || stock.currentFull > 0)
  const stockEstimate = stock?.estimatedEmpty ? Math.max(0, differenceInCalendarDays(parseISO(stock?.estimatedEmpty), new Date())) : null
  const stockFill = stockEstimate !== null ? Math.max(0.1, Math.min(stockEstimate / 25, 1)) : 1

  const getProgressLabel = (stockEstimate: number | null): string => {
    if (stockEstimate === null) {
      return 'No estimation'
    }
    if (stockEstimate > 25) {
      return 'Over 25d'
    }
    if (stockEstimate > 1) {
      return `${stockEstimate}d`
    }

    return 'Soon'
  }

  return (
    <Cell onMouseEnter={e => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)} align={sortedSides.length > 1 ? 'center' : 'stretch'}>
      {!mouseOver && hasStock && !twoSensorsAbnormalState ? (
        <Progress
          width='100%'
          color={stockEstimate || stockEstimate === 0 ? 'violet' : 'gray6'}
          fillPercent={stockFill}
          label={getProgressLabel(stockEstimate)}
          height={25}
          growDirection='right'
          animate
          striped
        />
      ) : (
        <>
          {sortedSides.map((side, index) => {
            const props = getCylinderGroupSideProps(side)
            const tooltip = getEstimatedEmptyTooltip(side, props)
            return (
              <Tooltip content={tooltip} placement='top' trigger='mouseenter' disabled={!tooltip} arrow={false}>
                <ProgressBarPercent
                  key={`${side && side?.id ? side.id : index}-sidebarpercent`}
                  side={{ ...props, daysRemaining: props.daysRemaining ? props.daysRemaining : 0, hidePercent: showPercentage ? props.hidePercent : false }}
                  threshold={displayThreshold ? cylinderGroup?.threshold : null}
                />
              </Tooltip>
            )
          })}
        </>
      )}
    </Cell>
  )
}

export default EstimatedEmpty
