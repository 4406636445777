import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactCrop, { makeAspectCrop, centerCrop, convertToPixelCrop, Crop } from 'react-image-crop'
import { ColorResult, TwitterPicker } from 'react-color'
import { useIntl } from 'react-intl'
import Select from 'react-select'

import * as GQL from '../generated/graphql'
import { Control, Option, reactSelectStyles, IndicatorsContainer } from './StyledReactSelect'
import { useAppContext } from 'util/hooks'
import { displayToast } from '../util/toasts'
import { CurrencyInput } from 'components/Input/CurrencyInput'
import { MAX_ALLOWED_SIZE_MB, MIN_IMAGE_SIZE_PX, PICKER_COLORS, VALID_FILE_TYPES } from '../modules/settings/consts'
import Switch from './Switch'
import { PERCENT_REGEX, bakeSvg, fetchProductPreviewJSON, formatProductType, updateSVG } from '../modules/settings/util'
import cylinderPlaceholderFillsvg from './plasmic/solace_components/images/cylinderPlaceholderFillsvg.svg'
import ThumbnailProductImage from './ThumbnailProductImage'
import { DefaultModalProductConfiguratorProps, PlasmicModalProductConfigurator } from './plasmic/solace_components/PlasmicModalProductConfigurator'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import './ModalProductConfigurator.css'
import 'react-image-crop/dist/ReactCrop.css'
import { MandatoryField, MandatoryFieldObject } from './MandatoryFieldsPanel'

interface CustomerVisibilityOptionType {
  value: boolean
  label: string
}
interface DepotOptionType {
  value: string
  label: string
}

interface ProductStatus {
  label: GQL.ProductStatus
  value: GQL.ProductStatus
}

interface ProductType {
  label: string
  value: GQL.ProductType
}

interface ProductData {
  name?: string
  gasWeight: number | null
  gasType: ProductType
  fullWeight?: string
  tareWeight?: string
  height?: number | null
  diameter?: number | null
  regulator?: string
  description?: string
  customId?: string
  exchangeProduct: boolean
  cylinderDepositPrice: string
  inDepots: DepotOptionType[]
  visibleToCustomer: CustomerVisibilityOptionType
  basePrice: string
  status: ProductStatus
  taxRate: string
  depositTaxRate: string
}

export interface ModalProductConfiguratorProps extends DefaultModalProductConfiguratorProps {
  product?: GQL.ProductNode
  onClose: () => void
  onSubmit: () => void
  refetchQueries?: string[]
  triggerSave: boolean
  setTriggerSave: (value: boolean) => void
  mandatoryFields?: MandatoryFieldObject[]
  setMandatoryFields?: React.Dispatch<React.SetStateAction<MandatoryFieldObject[]>>
}

function ModalProductConfigurator_(props: ModalProductConfiguratorProps, ref: HTMLElementRefOf<'div'>) {
  const intl = useIntl()
  const t = intl.formatMessage
  const { appContext } = useAppContext()
  const imageCandidateRef = useRef<HTMLImageElement>(null)

  const [tabActive, setTabActive] = useState<'configure' | 'upload' | 'noImage'>(props.product?.image ? 'upload' : props.product ? 'noImage' : 'configure')
  const [productPreviewExchangeToggle, setProductPreviewExchangeToggle] = useState(true)
  const [product, setProduct] = useState<ProductData>({
    name: props.product?.name ? props.product?.name : '',
    gasWeight: props.product?.weight ? parseFloat(props.product.weight) : null,
    gasType: {
      label: props.product?.type ? formatProductType(props.product.type) : formatProductType(GQL.ProductType.Propane),
      value: props.product?.type ? props.product.type : GQL.ProductType.Propane,
    },
    fullWeight: props.product?.fullWeight ? props.product.fullWeight : '',
    tareWeight: props.product?.tareWeight ? props.product.tareWeight : '',
    height: props.product?.height ? props.product.height : null,
    diameter: props.product?.diameter ? props.product.diameter : null,
    regulator: props.product?.regulator ? props.product.regulator : '',
    description: props.product?.description ? props.product.description : '',
    status: {
      label: props.product?.status ? props.product.status : GQL.ProductStatus.Active,
      value: props.product?.status ? props.product.status : GQL.ProductStatus.Active,
    },
    visibleToCustomer: {
      value: props.product ? props.product.visibleToCustomer : true,
      label: props.product ? (props.product.visibleToCustomer ? t({ id: 'common.visible' }) : t({ id: 'common.invisible' })) : t({ id: 'common.visible' }),
    },
    inDepots: !!props.product
      ? !!props.product.inDepots && props.product.inDepots.length !== 0
        ? props.product.inDepots.map(depot => ({ value: depot?.id || '', label: depot?.name || '' }))
        : []
      : appContext.depot
        ? [{ value: appContext.depot.id, label: appContext.depot.name || '' }]
        : [],
    customId: props.product?.customId ? props.product.customId : '',
    basePrice: props.product?.productPrices ? props.product.productPrices.find(productPrice => productPrice?.category.default === true)?.price : '',
    /* exchangeProduct should be off by default while editing, but on by default while creating a new product */
    exchangeProduct: props.product?.exchangeProduct || (props.product && props.product.id ? false : true),
    cylinderDepositPrice: props.product?.productDepositPrices
      ? props.product.productDepositPrices.find(productDepositPrice => productDepositPrice?.category.default === true)?.price
      : '',
    taxRate: props.product?.taxRate ? props.product.taxRate : appContext.distributor?.defaultTax,
    depositTaxRate: props.product?.depositTaxRate ? props.product.depositTaxRate : appContext.distributor?.defaultTax,
  })
  const [productImages, setProductImages] = useState<string[]>([])
  const [color, setColor] = useState(PICKER_COLORS[0])
  const [activeImageKey, setActiveImageKey] = useState(0)
  const [svgBaked, setSvgBaked] = useState<string>('')
  const [isCropping, setIsCropping] = useState(false)

  const contextDistributor = appContext.distributor
  const { data: dataDepots } = GQL.useAllDepots()
  const depots = useMemo(() => dataDepots?.allDepots?.edges.map(edge => edge?.node as GQL.DepotNode) || [], [dataDepots])

  useEffect(() => {
    // importing SVG Previews for Configure Image Tab from AWS S3 Bucket
    const fetchData = async () => {
      const productImages = await fetchProductPreviewJSON()
      setProductImages(productImages)
      setSvgBaked(bakeSvg(productImages[activeImageKey], color))
    }
    fetchData()
  }, [activeImageKey, color])

  // Image upload
  const [isUploaded, setIsUploaded] = useState(!!(props.product && props.product.image))
  const [uploadedImage, setUploadedImage] = useState<File>()
  const [imageCandidate, setImageCandidate] = useState<File>()
  const [uploadedImagePreview, setUploadedImagePreview] = useState<string>(props.product?.image ? props.product.image.image : cylinderPlaceholderFillsvg)
  const [imageCandidatePreview, setImageCandidatePreview] = useState<string>(cylinderPlaceholderFillsvg)

  const [crop, setCrop] = useState<Crop>()

  // Upload area messages
  const messages = React.useMemo(() => {
    return {
      default: t({ id: 'common.upload.label' }, { maxMB: MAX_ALLOWED_SIZE_MB }),
      drop: t({ id: 'common.upload.drop-file' }),
      wrongFormat: t({ id: 'common.upload.error.wrong-format' }, { allowedFormats: 'JPEG, JPG and PNG' }),
      fileSize: t({ id: 'common.upload.error.size-limit' }, { maxAllowedSize: MAX_ALLOWED_SIZE_MB }),
      uploading: t({ id: 'common.uploading' }),
      aspectRatio: t({ id: 'common.upload.error.aspect-ratio' }, { aspectRatio: '1:1' }),
      imageSize: t({ id: 'common.upload.error.too-small-image' }, { minHeight: MIN_IMAGE_SIZE_PX, minWidth: MIN_IMAGE_SIZE_PX }),
    }
  }, [MAX_ALLOWED_SIZE_MB, MIN_IMAGE_SIZE_PX])

  // Upload area states
  const [labelUpload, setLabelUpload] = useState<string>(messages.default)
  const [dragOver, setDragOver] = useState(false)
  const [notAllowed, setNotAllowed] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const [createProduct, { loading }] = GQL.useCreateProduct({
    refetchQueries: ['AllProducts'],
    onCompleted: data => {
      if (data.createProduct?.ok) {
        displayToast(t({ id: 'settings.products.create.toasts.success' }), 'success')
        props.onSubmit()
        return
      }
      displayToast(t({ id: 'settings.products.create.toasts.error' }), 'error')
      props.onSubmit()
    },
    onError: error => {
      if (error.message.includes('Product with that custom ID exists.')) {
        displayToast(t({ id: 'settings.products.create-update.error.exists' }))
      } else {
        displayToast(t({ id: 'settings.products.create.toasts.error' }))
      }
    },
  })

  const [patchProduct, { loading: patchLoading }] = GQL.usePatchProduct({
    refetchQueries: props.refetchQueries ? [...props.refetchQueries, 'AllProducts'] : ['AllProducts'],
    onCompleted: data => {
      if (data.patchProduct?.ok) {
        displayToast(t({ id: 'settings.products.edit.toasts.success' }), 'success')
        props.onSubmit()
        return
      }
      displayToast(t({ id: 'settings.products.edit.toasts.error' }), 'error')
      props.onSubmit()
    },
    onError: error => {
      if (error.message.includes('Product with that custom ID exists.')) {
        displayToast(t({ id: 'settings.products.create-update.error.exists' }))
      } else {
        displayToast(t({ id: 'settings.products.edit.toasts.error' }))
      }
    },
  })

  // Upload area functions
  const getUploadedImage = () => {
    if (tabActive === 'configure') {
      const blob = updateSVG(productImages[activeImageKey], color)
      return new File([blob], 'product.svg', { type: 'image/svg+xml' })
    } else if (tabActive === 'upload') {
      return uploadedImage
    }

    return undefined
  }

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const checkFileSize = (event: React.SyntheticEvent, file: File) => {
    event.preventDefault()
    const fileSizeInMB = file.size / (1024 * 1024)
    if (fileSizeInMB > MAX_ALLOWED_SIZE_MB) {
      setLabelUpload(messages.fileSize)
    } else {
      handleUploadedFile(file)
      setDragOver(false)
      setLabelUpload(messages.uploading)
    }
  }

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return
    }
    const file = event.target.files[0]
    checkFileSize(event, file)
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDragOver(true)
    const isFileValid = Array.from(event.dataTransfer.items).some(item => VALID_FILE_TYPES.includes(item.type))
    if (!isFileValid) {
      event.dataTransfer.dropEffect = 'none' // Disallow the drop
      setLabelUpload(messages.wrongFormat)
    } else {
      setLabelUpload(messages.drop)
    }
  }

  const handleLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDragOver(false)
    setLabelUpload(messages.default)
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (!event.dataTransfer.files) {
      return
    }
    const file = event.dataTransfer.files[0]
    checkFileSize(event, file)
  }

  const setupCrop = (event: Event) => {
    // @ts-ignore
    const { naturalWidth: width, naturalHeight: height } = event.currentTarget
    setCrop(
      centerCrop(
        makeAspectCrop(
          {
            unit: '%',
            width: 100,
          },
          1,
          width,
          height
        ),
        width,
        height
      )
    )
  }

  const handleUploadedFile = (file: File) => {
    const reader = new FileReader()
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const image = new Image()
      image.src = event.target?.result as string
      image.onload = imageLoadEvent => {
        const { width, height } = image
        const aspectRatio = Math.abs(width / height)
        const tolerance = 0.05 // Tolerate that the image is not a perfect square
        if (Math.abs(aspectRatio - 1) > tolerance) {
          setImageCandidate(file)
          setImageCandidatePreview(event.target?.result as string)
          setIsCropping(true)
          setupCrop(imageLoadEvent)
          setLabelUpload(messages.aspectRatio)
        } else if (width < MIN_IMAGE_SIZE_PX - 5) {
          setLabelUpload(messages.imageSize)
        } else {
          setUploadedImagePreview(event.target?.result as string)
          setUploadedImage(file)
          setIsUploaded(true)
        }
      }
    }
    reader.readAsDataURL(file)
  }

  const handleChangeComplete = (color: ColorResult) => {
    setColor(color.hex)
  }

  const handleSubmit = () => {
    props.setTriggerSave(false)
    if (!product.gasType || !product.gasWeight) {
      displayToast(t({ id: 'settings.products.create.error.no-mandatory-fields' }))
      return
    }
    if (tabActive === 'upload' && !isUploaded) {
      displayToast(t({ id: 'settings.products.create.error.no-uploaded-image' }))
      return
    }

    if (props.product && props.product.id) {
      patchProduct({
        variables: {
          id: props.product.id,
          input: {
            name: product.name,
            weight: product.gasWeight,
            type: product.gasType.value.toLowerCase(),
            description: product.description,
            image: tabActive !== 'noImage' ? getUploadedImage() : null,
            fullWeight: product.fullWeight,
            tareWeight: product.tareWeight,
            diameter: product.diameter,
            height: product.height,
            status: product.status.value,
            regulator: product.regulator,
            visibleToCustomer: product.visibleToCustomer?.value,
            customId: product.customId,
            exchangeProduct: product.exchangeProduct,
            cylinderDepositPrice: product.cylinderDepositPrice ? parseFloat(product.cylinderDepositPrice).toFixed(2) : null,
            basePrice: product.basePrice ? parseFloat(product.basePrice).toFixed(2) : null,
            taxRate: product.taxRate ? parseFloat(product.taxRate).toFixed(2) : null,
            depositTaxRate: product.depositTaxRate ? parseFloat(product.depositTaxRate).toFixed(2) : null,
            inDepots: product.inDepots.map(depot => depot.value),
          },
        },
      })
    } else {
      createProduct({
        variables: {
          input: {
            name: product.name,
            weight: product.gasWeight,
            type: product.gasType.value,
            description: product.description,
            image: tabActive !== 'noImage' ? getUploadedImage() : null,
            fullWeight: product.fullWeight,
            tareWeight: product.tareWeight,
            diameter: product.diameter,
            height: product.height,
            status: product.status.value,
            regulator: product.regulator,
            visibleToCustomer: product.visibleToCustomer?.value,
            customId: product.customId,
            exchangeProduct: product.exchangeProduct,
            cylinderDepositPrice: product.cylinderDepositPrice ? product.cylinderDepositPrice : null,
            basePrice: product.basePrice ? product.basePrice : null,
            taxRate: product.taxRate,
            depositTaxRate: product.depositTaxRate,
            inDepots: product.inDepots.map(depot => depot.value),
          },
        },
      })
    }
  }

  const updateMandatoryFields = useCallback(() => {
    if (!props.setMandatoryFields || !props.mandatoryFields) {
      return
    }

    props.setMandatoryFields(prevMandatoryFields => {
      const newMandatoryFields: MandatoryFieldObject[] = prevMandatoryFields.map(field => {
        if (field.key === MandatoryField.GAS_WEIGHT) {
          return { ...field, valid: !!product.gasWeight }
        } else if (field.key === MandatoryField.GAS_TYPE) {
          return { ...field, valid: !!product.gasType.value }
        } else if (field.key === MandatoryField.CUSTOMER_APP_VISIBILITY) {
          return { ...field, valid: typeof product.visibleToCustomer.value === 'boolean' }
        }
        return field
      })

      return newMandatoryFields
    })
  }, [product])

  useEffect(() => {
    updateMandatoryFields()
  }, [updateMandatoryFields])

  useEffect(() => {
    const disallowedMessages = [messages.wrongFormat, messages.fileSize, messages.uploading, messages.aspectRatio, messages.imageSize]
    setNotAllowed(disallowedMessages.includes(labelUpload))
  }, [labelUpload, messages])

  useEffect(() => {
    if (props.triggerSave) {
      handleSubmit()
      props.setTriggerSave(false)
    }
  }, [props.triggerSave])

  useEffect(() => {
    if (!imageCandidate && isCropping) {
      setIsCropping(false)
      setCrop(undefined)
    }
  }, [imageCandidate, isCropping])

  useEffect(() => {
    if (!!uploadedImage) setImageCandidate(undefined)
  }, [uploadedImage])

  const getProductPrice = () => {
    const formatter = new Intl.NumberFormat(navigator.language || 'en-GB', {
      style: 'currency',
      currency: contextDistributor?.defaultCurrency || GQL.Currency.Usd,
      currencyDisplay: 'symbol',
      maximumFractionDigits: 2,
    })

    const exchangeFee = product.exchangeProduct && !productPreviewExchangeToggle ? parseFloat(product.cylinderDepositPrice) : 0
    const productTax = (parseFloat(product.basePrice) * (parseFloat(product.taxRate) || 0)) / 100
    const depositTax = (exchangeFee * (parseFloat(product.depositTaxRate) || 0)) / 100
    const subtotal = parseFloat(product.basePrice)
    const price = formatter.format(subtotal + productTax)

    if (product.exchangeProduct && !productPreviewExchangeToggle) {
      const depositPrice = formatter.format(exchangeFee + depositTax)
      return `${price} (+${depositPrice} deposit price)`
    }
    return price
  }

  const handleCancelCrop = () => {
    setImageCandidate(undefined)
    setIsCropping(false)
    setIsUploaded(false)
    setCrop(undefined)
    setLabelUpload(messages.default)
  }

  const handleConfirmCrop = async () => {
    const image = imageCandidateRef.current
    if (!image || !imageCandidate || !crop) return
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const completedCrop = convertToPixelCrop(crop, image?.width, image?.height)
    const pixelRatio = window.devicePixelRatio
    const offscreen = new OffscreenCanvas(completedCrop.width * scaleX * pixelRatio, completedCrop.height * scaleY * pixelRatio)
    const context = offscreen.getContext('2d')
    if (!context) {
      return displayToast('Something went wrong when cropping the image.', 'warning')
    }
    context.scale(pixelRatio, pixelRatio)
    context.imageSmoothingQuality = 'high'
    context.translate(-completedCrop.x * scaleX, -completedCrop.y * scaleY)
    context.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight)
    const croppedFile = (await offscreen.convertToBlob({ type: imageCandidate.type, quality: 1 })) as File
    handleCancelCrop()
    handleUploadedFile(croppedFile)
  }

  return (
    <>
      <PlasmicModalProductConfigurator
        root={{ ref }}
        tab={tabActive}
        exchange={product.exchangeProduct}
        title={props.product ? t({ id: 'settings.products.edit' }) : t({ id: 'settings.products.create' })}
        inputWeight={{
          fieldStatus: true,
          alert: !product.gasWeight,
          undefinedInput: {
            id: 'inputGasWeight',
            value: product.gasWeight || '',
            name: 'weight',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setProduct({
                ...product,
                gasWeight: e.target.value ? Math.abs(parseFloat(e.target.value)) : null,
              }),
          },
        }}
        inputFullWeight={{
          undefinedInput: {
            value: product.fullWeight || '',
            name: 'fullWeight',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setProduct({
                ...product,
                fullWeight: e.target.value,
              }),
          },
        }}
        inputProductTitle={{
          undefinedInput: {
            value: product.name || '',
            name: 'displayTitle',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setProduct({
                ...product,
                name: e.target.value,
              }),
          },
        }}
        inputTareWeight={{
          undefinedInput: {
            value: product.tareWeight || '',
            name: 'tareWeight',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setProduct({
                ...product,
                tareWeight: e.target.value,
              }),
          },
        }}
        inputHeight={{
          undefinedInput: {
            value: product.height || '',
            name: 'height',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setProduct({
                ...product,
                height: e.target.value ? Math.abs(parseFloat(e.target.value)) : null,
              }),
          },
        }}
        inputDiameter={{
          undefinedInput: {
            value: product.diameter || '',
            name: 'diameter',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setProduct({
                ...product,
                diameter: e.target.value ? Math.abs(parseFloat(e.target.value)) : null,
              }),
          },
        }}
        inputRegulator={{
          undefinedInput: {
            value: product.regulator || '',
            name: 'regulator',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setProduct({
                ...product,
                regulator: e.target.value,
              }),
          },
        }}
        inputProductId={{
          undefinedInput: {
            value: product.customId || '',
            name: 'customId',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setProduct({
                ...product,
                customId: e.target.value,
              }),
          },
        }}
        inputDescription={{
          undefinedInput: {
            value: product.description || '',
            name: 'description',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setProduct({
                ...product,
                description: e.target.value,
              }),
          },
        }}
        selectTypeDiv={
          <Select
            id='select-gas'
            placeholder='Select gas type...'
            value={product.gasType}
            options={
              Object.entries(GQL.ProductType).map(([key, value]) => {
                return { value: key as GQL.ProductType, label: formatProductType(value) }
              }) ?? []
            }
            onChange={(selectedOption: any) => {
              if (selectedOption) {
                setProduct({
                  ...product,
                  gasType: {
                    value: selectedOption.value,
                    label: formatProductType(selectedOption.label),
                  },
                })
              } else {
                setProduct({
                  ...product,
                  gasType: {
                    label: formatProductType(GQL.ProductType.Propane),
                    value: GQL.ProductType.Propane,
                  },
                })
              }
            }}
            components={{ Option, Control, IndicatorsContainer }}
            styles={reactSelectStyles}
            isSearchable
          />
        }
        cardProduct={{
          open: true,
          noPhoto: tabActive === 'noImage',
          imgProduct: tabActive === 'upload' ? uploadedImagePreview : svgBaked,
          hideInfo: [
            [product.fullWeight, product.gasWeight, product.tareWeight, product.height, product.diameter, product.regulator].every(value => !value) &&
              'hideAll',
            !product.fullWeight && 'fullWeight',
            !product.gasWeight && 'gasWeight',
            !product.tareWeight && 'tareWeight',
            !product.height && 'height',
            !product.diameter && 'diameter',
            !product.regulator && 'regulator',
          ].filter(Boolean) as ('fullWeight' | 'gasWeight' | 'tareWeight' | 'height' | 'diameter' | 'regulator' | 'hideAll')[],
          infoProductTitle: product.name || (product.gasType && product.gasWeight ? `${product.gasType.label} ${product.gasWeight} kg` : 'No title'),
          infoDescription: product.description ? product.description : 'No description',
          infoGasWeight: `${product.gasWeight} kg`,
          infoFullWeight: `${product.fullWeight} kg`,
          infoTareWeight: `${product.tareWeight} kg`,
          infoHeight: `${product.height} mm`,
          infoDiameter: `${product.diameter} mm`,
          infoRegulator: product.regulator,
          price: getProductPrice(),
          showPricing: !!product.basePrice,
          exchange: !!product.exchangeProduct,
          slotExchangeCylindersToggle: (
            <Switch
              isChecked={productPreviewExchangeToggle}
              onChange={checked => setProductPreviewExchangeToggle(checked)}
              whiteToggle={true}
              noLabel={true}
              children={null}
              children2={null}
            />
          ),
        }}
        tabSelectorProductImage={{
          props: {
            selected: tabActive,
            btnConfigure: {
              onClick: () => setTabActive('configure'),
            },
            btnUpload: {
              onClick: () => setTabActive('upload'),
            },
            btnNoImage: {
              onClick: () => setTabActive('noImage'),
            },
          },
        }}
        btnCreateProduct={{
          props: {
            label: props.product ? t({ id: 'settings.products.save' }) : t({ id: 'settings.products.create' }),
            name: 'submitButton',
            loading: loading || patchLoading,
            onClick: () => handleSubmit(),
          },
        }}
        thumbnails={{
          children: productImages.map((product, index) => (
            <ThumbnailProductImage
              key={index}
              active={activeImageKey === index}
              img={<img alt={'Product Preview'} src={`data:image/svg+xml;utf8,${encodeURIComponent(product)}`} />}
              onClick={() => setActiveImageKey(index)}
            />
          )),
        }}
        productImageConfigurator={{
          props: {
            svgPreview: productImages[activeImageKey],
            imageColor: { style: { color: color } },
          },
        }}
        selectVisibilityDiv={
          <Select
            id='select-visibility'
            value={product.visibleToCustomer as CustomerVisibilityOptionType}
            isMulti={false}
            options={[
              { value: true, label: t({ id: 'common.visible' }) },
              { value: false, label: t({ id: 'common.invisible' }) },
            ]}
            onChange={event => {
              setProduct({ ...product, visibleToCustomer: event! })
            }}
            components={{ Option, Control }}
            styles={reactSelectStyles}
          />
        }
        selectChooseDepotsDiv={
          <Select
            placeholder='All depots'
            id='depot-select'
            value={product.inDepots}
            options={depots.map(depot => ({ value: depot.id, label: depot.name }) as DepotOptionType)}
            onChange={event => {
              // @ts-ignore
              setProduct({ ...product, inDepots: event! })
            }}
            components={{ Option, Control }}
            styles={reactSelectStyles}
            isSearchable={true}
            isMulti={true}
          />
        }
        colorSelector={<TwitterPicker color={color} onChangeComplete={handleChangeComplete} colors={PICKER_COLORS} triangle='hide' width='100%' />}
        uploadProductImage={{
          cropping: isCropping,
          btnConfirm: {
            props: {
              onClick: handleConfirmCrop,
            },
          },
          btnCancel: {
            props: {
              onClick: handleCancelCrop,
            },
          },
          uploadedImageDiv: isCropping ? (
            <ReactCrop crop={crop} aspect={1} onChange={(crop, percentCrop) => setCrop(crop)} minHeight={MIN_IMAGE_SIZE_PX} minWidth={MIN_IMAGE_SIZE_PX}>
              <img src={imageCandidatePreview} ref={imageCandidateRef} />
            </ReactCrop>
          ) : undefined,
          uploadedImage: isCropping ? imageCandidatePreview : uploadedImagePreview,
          uploaded: isUploaded,
          btnOpenUploadDialog: {
            props: {
              onClick: () => handleUploadClick(),
              onDragOver: (event: React.DragEvent<HTMLDivElement>) => handleDragOver(event),
              onDrop: (event: React.DragEvent<HTMLDivElement>) => handleDrop(event),
              onDragLeave: (event: React.DragEvent<HTMLDivElement>) => handleLeave(event),
              dropActive: dragOver && !notAllowed,
              notAllowed: notAllowed,
              labelUpload: labelUpload,
            },
          },
          btnRemoveImage: {
            props: {
              onClick: () => {
                setUploadedImage(undefined)
                setIsUploaded(false)
                setLabelUpload(messages.default)
                setUploadedImagePreview(cylinderPlaceholderFillsvg)
              },
            },
          },
        }}
        inputBasePriceDiv={
          <CurrencyInput
            classNames='inputPriceFieldInline'
            currency={appContext?.distributor?.defaultCurrency || GQL.Currency.Usd}
            value={product.basePrice}
            name='price'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setProduct({
                ...product,
                basePrice: event.target.value || '',
              })
            }}
            onBlur={() => {
              if (!product.basePrice) {
                setProduct({
                  ...product,
                  basePrice: props.product?.productPrices
                    ? props.product.productPrices.find(productPrice => productPrice?.category.default === true)?.price
                    : '',
                })
              }
            }}
          />
        }
        inputBasePriceTax={{
          undefinedInput: {
            name: 'priceTax',
            value: product.taxRate,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setProduct({ ...product, taxRate: e.target.value })
            },
            onBlur: () => {
              if (!PERCENT_REGEX.test(product.taxRate)) {
                setProduct({ ...product, taxRate: props.product?.taxRate ? props.product.taxRate : appContext.distributor?.defaultTax })
              }
            },
          },
        }}
        toggleExchange={{
          isChecked: product.exchangeProduct,
          name: 'exchangeProduct',
          onChange: () => {
            !product.exchangeProduct
              ? setProduct({ ...product, exchangeProduct: !product.exchangeProduct })
              : setProduct({ ...product, exchangeProduct: !product.exchangeProduct, cylinderDepositPrice: '' })
          },
        }}
        inputCylinderDepositPriceDiv={
          <CurrencyInput
            name='depositPrice'
            classNames='inputPriceFieldInline'
            currency={appContext?.distributor?.defaultCurrency || GQL.Currency.Usd}
            value={product.cylinderDepositPrice}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setProduct({
                ...product,
                cylinderDepositPrice: event.target.value || '',
              })
            }}
            onBlur={() => {
              if (!product.cylinderDepositPrice) {
                setProduct({
                  ...product,
                  cylinderDepositPrice: props.product?.productDepositPrices
                    ? props.product.productDepositPrices.find(productDepositPrice => productDepositPrice?.category.default === true)?.price
                    : '',
                })
              }
            }}
          />
        }
        inputDepositPriceTax={{
          undefinedInput: {
            name: 'depositPriceTax',
            value: product.depositTaxRate,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setProduct({ ...product, depositTaxRate: e.target.value })
            },
            onBlur: () => {
              if (!PERCENT_REGEX.test(product.depositTaxRate)) {
                setProduct({ ...product, depositTaxRate: props.product?.depositTaxRate ? props.product.depositTaxRate : appContext.distributor?.defaultTax })
              }
            },
          },
        }}
        btnClose={{
          onClick: () => props.onClose(),
        }}
      />
      <input type='file' accept='.jpg, .jpeg, .png' style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileUpload} value={''} />
    </>
  )
}

const ModalProductConfigurator = React.forwardRef(ModalProductConfigurator_)
export default ModalProductConfigurator
