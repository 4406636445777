// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type BottleIconsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function BottleIconsvgIcon(props: BottleIconsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 20.091 55.103"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M15.147 8.478V4.546a2.278 2.278 0 002.118-2.271A2.277 2.277 0 0014.989 0H5.1a2.277 2.277 0 00-2.276 2.274c0 1.203.937 2.19 2.12 2.271v3.932A5.109 5.109 0 000 13.576v33.601c0 1.936 1.08 3.669 2.824 4.546v2.518c0 .476.387.862.863.862h12.715a.863.863 0 00.863-.862v-2.518c1.745-.876 2.825-2.608 2.825-4.546V13.576a5.106 5.106 0 00-4.943-5.098zm-4.396-2.829H9.34a.865.865 0 00-.865.863c0 .422.306.776.707.849v1.114H6.67V4.55h6.748v3.926h-2.512V7.362a.863.863 0 00-.155-1.713zm3.491 43.795H5.849a4.084 4.084 0 01-4.084-4.084V14.617a4.084 4.084 0 014.084-4.084h8.393a4.084 4.084 0 014.084 4.084V45.36a4.084 4.084 0 01-4.084 4.084z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default BottleIconsvgIcon;
/* prettier-ignore-end */
