// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { format, parseISO } from 'date-fns'
import { PlasmicInventoryHistoryRow, DefaultInventoryHistoryRowProps } from './plasmic/solace_components/PlasmicInventoryHistoryRow'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

import * as GQL from 'generated/graphql'
import { formatInventoryAdjustmentReason } from 'modules/inventory/utils'
import { omit } from 'lodash'
// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface InventoryHistoryRowProps extends Omit<DefaultInventoryHistoryRowProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultInventoryHistoryRowProps altogether and have
// total control over the props for your component.
export interface InventoryHistoryRowProps extends DefaultInventoryHistoryRowProps {
  adjustmentNode: GQL.InventoryAdjustmentNode
  selectedInventory: GQL.InventoryNode | null
}

function InventoryHistoryRow_(props: InventoryHistoryRowProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicInventoryHistoryRow to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicInventoryHistoryRow are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all InventoryHistoryRowProps here, but feel free
  // to do whatever works for you.

  const isNegative = React.useMemo(() => {
    if (props.adjustmentNode.toInventory && !props.adjustmentNode.fromInventory) return props.adjustmentNode.amount < 0
    if (props.selectedInventory?.id === props.adjustmentNode.fromInventory?.id) return props.adjustmentNode.amount < 0
    return props.adjustmentNode.amount > 0
  }, [props.adjustmentNode, props.selectedInventory])

  const displayAmount = React.useMemo(() => {
    if (isNegative) return `-${Math.abs(props.adjustmentNode.amount)}`
    return `+${Math.abs(props.adjustmentNode.amount)}`
  }, [isNegative, props.adjustmentNode.amount])

  const stockAfter = React.useMemo(() => {
    if (props.adjustmentNode.toInventory && !props.adjustmentNode.fromInventory) return props.adjustmentNode.amountAfterTo || 0
    if (props.selectedInventory?.id === props.adjustmentNode.fromInventory?.id) return props.adjustmentNode.amountAfterFrom || 0
    return props.adjustmentNode?.amountAfterTo || 0
  }, [props.adjustmentNode, props.selectedInventory])

  const getInventoryName = (inventory?: GQL.InventoryNode | null) => {
    if (!inventory) return '-'
    if (inventory.belongsTo?.__typename === 'CylinderGroupNode') return inventory.belongsTo.customer.name
    return inventory.belongsTo?.name
  }

  return (
    <PlasmicInventoryHistoryRow
      root={{ ref }}
      {...omit(props, 'adjustmentNode', 'selectedInventory')}
      change={isNegative ? 'negative' : 'positive'}
      from={getInventoryName(props.adjustmentNode.fromInventory)}
      to={getInventoryName(props.adjustmentNode.toInventory)}
      date={format(parseISO(props.adjustmentNode?.time), 'dd.MM.yyyy')}
      reason={formatInventoryAdjustmentReason(props.adjustmentNode?.reason)}
      description={props.adjustmentNode?.description}
      adjustment={displayAmount}
      stockAfter={stockAfter.toString()}
    />
  )
}

const InventoryHistoryRow = React.forwardRef(InventoryHistoryRow_)
export default InventoryHistoryRow
