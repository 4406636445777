// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type VehicleTrucksvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function VehicleTrucksvgIcon(props: VehicleTrucksvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 19.737 13.82"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M19.16 6.331l-1.804-1.804a1.975 1.975 0 00-1.397-.577h-1.65a.986.986 0 00-.987.987v4.441H.987a.986.986 0 00-.987.987v.857c0 1.258.885 2.409 2.131 2.575a2.46 2.46 0 002.31-.978 2.465 2.465 0 002.631.916 2.443 2.443 0 001.678-1.567c.151-.456.167-.901.086-1.308h5.033c-.083.407-.068.851.086 1.308a2.436 2.436 0 001.715 1.576 2.473 2.473 0 003.035-2.886h.54a.495.495 0 00.493-.493V7.728a1.975 1.975 0 00-.578-1.397zM2.467 12.336c-.543 0-.987-.444-.987-.987s.444-.987.987-.987.987.444.987.987-.444.987-.987.987zm3.948 0c-.543 0-.987-.444-.987-.987s.444-.987.987-.987.987.444.987.987-.445.987-.987.987zm9.868 0c-.543 0-.987-.444-.987-.987s.444-.987.987-.987.987.444.987.987-.444.987-.987.987zm-1.48-5.428v-1.48h1.156a.49.49 0 01.348.145l1.335 1.335h-2.839zM.987 8.388h10.362a.986.986 0 00.987-.987V.987A.986.986 0 0011.349 0H.987A.986.986 0 000 .987v6.415c0 .545.441.986.987.986zM9.375 1.48h.987v5.428h-.987V1.48zm-2.467 0h.987v5.428h-.987V1.48zm-2.467 0h.987v5.428h-.987V1.48zm-2.467 0h.987v5.428h-.987V1.48z"
        }
      ></path>
    </svg>
  );
}

export default VehicleTrucksvgIcon;
/* prettier-ignore-end */
