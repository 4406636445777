import React, { FunctionComponent, useContext, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useApolloClient } from '@apollo/client'

import * as GQL from 'generated/graphql'
import { UserNode, useMe } from 'generated/graphql'
import { ReactComponent as MoreIcon } from 'assets/more.svg'
import { removeLoginToken } from 'util/auth'
import { useAppContext } from 'util/hooks'
import Navbar from 'plasmic/Navbar'
import HeaderContext from 'context/HeaderContext'
import MoreDropdown from './MoreDropdown'
import AppsDropdown from './AppsDropdown'
import { displayToast } from 'util/toasts'

const ORDERS_PATH = '/orders'
const DELIVERIES_PATH = '/deliveries'
const CUSTOMERS_PATH = '/customers'
const INSIGHT_PATH = '/insight'
const INVENTORY_PATH = '/inventory'

const HeaderWrapper = styled.div`
  z-index: 100;
`

const StyledMoreIcon = styled(MoreIcon)`
  height: 20px;
  width: 20px;
  fill: #ffffff;
  opacity: 0.8;
  cursor: pointer;
`

interface Props {}

const Header: FunctionComponent<Props> = props => {
  const location = useLocation()
  const navigate = useNavigate()
  const apolloClient = useApolloClient()

  const { appContext } = useAppContext()
  const headerContext = useContext(HeaderContext)

  const [logout] = GQL.useLogout()

  const appsBtnRef = useRef<HTMLElement | null>(null)
  const settingsBtnRef = useRef<HTMLElement | null>(null)

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [isAppsDropdownOpen, setIsAppsDropdownOpen] = useState(false)
  const [isMoreDropdownOpen, setIsMoreDropdownOpen] = useState(false)

  const me = useMe().data?.me as UserNode
  const contextUser = appContext.user
  const contextDistributor = appContext.distributor

  const [logoutEverywhere] = GQL.useLogoutOnAllDevices()

  const handleMobileMenuClick = () => {
    setIsMobileMenuOpen(isMobileMenuOpen => !isMobileMenuOpen)
  }

  const handleOrdersLinkClick = () => {
    navigate(ORDERS_PATH)
  }

  const handleDeliveriesLinkClick = () => {
    navigate(DELIVERIES_PATH)
  }

  const handleCustomersLinkClick = () => {
    navigate(CUSTOMERS_PATH)
  }

  const handleInsightLinkClick = () => {
    navigate(INSIGHT_PATH)
  }

  const handleInventoryLinkClick = () => {
    navigate(INVENTORY_PATH)
  }

  const handleChatClick = () => {
    window._chatlio.showOrHide()
  }

  const handleAppsClick = () => {
    setIsAppsDropdownOpen(isAppsDropdownOpen => !isAppsDropdownOpen)
  }

  const handleAppsClose = () => {
    setIsAppsDropdownOpen(false)
  }

  const handleMoreClick = () => {
    setIsMoreDropdownOpen(isMoreDropdownOpen => !isMoreDropdownOpen)
  }

  const handleMoreClose = () => {
    setIsMoreDropdownOpen(false)
  }

  const handleSettingsClick = () => {
    setIsMoreDropdownOpen(false)
    navigate('/settings')
  }

  const handleLogoutClick = () => {
    setIsMoreDropdownOpen(false)
    logout({
      onCompleted: () => {
        apolloClient.clearStore().finally(() => {
          removeLoginToken()
          window.location.reload()
        })
      },
      onError: () => {
        displayToast('Something went wrong while logging out.')
      },
    })
  }

  const handleLogoutEverywhereClick = () => {
    setIsMoreDropdownOpen(false)
    logoutEverywhere({
      onCompleted: data => {
        if (!data.logoutOnAllDevices?.ok || data.logoutOnAllDevices.error) {
          return displayToast('Something went wrong while logging out.')
        }
        apolloClient.clearStore().finally(() => {
          removeLoginToken()
          window.location.reload()
        })
      },
      onError: () => {
        displayToast('Something went wrong while logging out.')
      },
    })
  }

  return (
    <HeaderWrapper>
      <Navbar
        mobileMenuOpen={isMobileMenuOpen}
        linkOrders={{
          active: location.pathname.startsWith(ORDERS_PATH),
          onClick: handleOrdersLinkClick,
        }}
        linkDeliveries={{
          active: location.pathname.startsWith(DELIVERIES_PATH),
          onClick: handleDeliveriesLinkClick,
        }}
        linkCustomers={{
          active: location.pathname.startsWith(CUSTOMERS_PATH),
          onClick: handleCustomersLinkClick,
        }}
        linkInsight={{
          active: location.pathname.startsWith(INSIGHT_PATH),
          onClick: handleInsightLinkClick,
        }}
        linkInventory={{
          active: location.pathname.startsWith(INVENTORY_PATH),
          onClick: handleInventoryLinkClick,
        }}
        btnOpenNav={{
          onClick: handleMobileMenuClick,
        }}
        btnChat={{
          onClick: handleChatClick,
        }}
        btnApps={{
          ref: appsBtnRef,
          onClick: handleAppsClick,
        }}
        btnMore={{
          ref: settingsBtnRef,
          onClick: handleMoreClick,
          icon: <StyledMoreIcon />,
        }}
        hide={appContext.distributor?.inventoryAccess === GQL.InventoryAccess.NoAccess && ['inventory']}
        subNavigation={headerContext.isSubHeaderVisible}
        subNavLeft={headerContext.leftSubHeaderGroup}
        subNavRight={headerContext.rightSubHeaderGroup}
      />
      {me && contextUser && (
        <MoreDropdown
          open={isMoreDropdownOpen}
          me={me}
          contextUser={contextUser}
          anchor={settingsBtnRef}
          onSettingsClick={handleSettingsClick}
          onLogoutClick={handleLogoutClick}
          onLogoutEverywhereClick={handleLogoutEverywhereClick}
          onClose={handleMoreClose}
        />
      )}
      {contextDistributor && <AppsDropdown open={isAppsDropdownOpen} distributor={contextDistributor} anchor={appsBtnRef} onClose={handleAppsClose} />}
    </HeaderWrapper>
  )
}

export default Header
