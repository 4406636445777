import * as React from 'react'
import { PlasmicCellNo, DefaultCellNoProps } from './plasmic/solace_components/PlasmicCellNo'
import { HTMLElementRefOf, Flex } from '@plasmicapp/react-web'
import ButtonArrow from './ButtonArrow'

interface CellNoProps extends DefaultCellNoProps {
  btnDown?: Flex<typeof ButtonArrow>
  btnup?: Flex<typeof ButtonArrow>
}

function CellNo_(props: CellNoProps, ref: HTMLElementRefOf<'div'>) {
  return <PlasmicCellNo root={{ ref }} {...props} />
}

const CellNo = React.forwardRef(CellNo_)
export default CellNo
