// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type MenuLinessvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function MenuLinessvgIcon(props: MenuLinessvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 20 17.167"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M18.5 3h-17a1.5 1.5 0 110-3h17a1.5 1.5 0 110 3zm0 7.083h-17a1.5 1.5 0 110-3h17a1.5 1.5 0 110 3zm0 7.084h-17a1.5 1.5 0 110-3h17a1.5 1.5 0 110 3z"
        }
      ></path>
    </svg>
  );
}

export default MenuLinessvgIcon;
/* prettier-ignore-end */
