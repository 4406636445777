import * as React from 'react'

import * as GQL from 'generated/graphql'
import { PlasmicCylinderComponent } from './plasmic/solace_components/PlasmicCylinderComponent'
import Cylinder, { CylinderColor } from './Cylinder'
import { getThresholdProps } from 'modules/customers/util'
import { SIDE_UNIT_PROPS } from 'modules/customers/consts'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

interface Side {
  cylinderCount: number
  monitored: boolean
  depleting: boolean
  sensors: GQL.SensorNode[]
  daysRemaining: number
  priority: number
  activeCycleRemainingPercentage: number
  cylinderColor?: CylinderColor
  isFreezing: boolean
}

interface Props {
  cylinderGroup?: GQL.CylinderGroupNode | null
  threshold?: GQL.DistributorRefillThresholdNode | null
  side?: Side
  activeCycle?: GQL.CycleNode | null
}

const MAX_VISIBLE_CYLINDERS = 6

function CylinderComponent_(
  {
    cylinderGroup,
    threshold,
    side = {
      depleting: false,
      cylinderCount: 1,
      monitored: false,
      sensors: [],
      daysRemaining: 10,
      priority: 0,
      activeCycleRemainingPercentage: 100,
      isFreezing: false,
    },
    activeCycle,
    ...props
  }: Props,
  ref: HTMLElementRefOf<'div'>
) {
  const thresholdProps = getThresholdProps({ ...SIDE_UNIT_PROPS.percent }, threshold)
  const thresholdInfo = {
    style: { top: `calc(${100 - (threshold ? Math.min(threshold?.threshold, 100) : 0)}% - 8px)` },
    ...thresholdProps.triggerIco,
  }
  const freezoutPosition = side?.isFreezing
    ? {
        style: { top: `calc(${Math.max(0, 100 - side?.activeCycleRemainingPercentage)}% - 8px)` },
      }
    : null

  //Only render 1 cylinder with label if cylinder count is more than maxVisibleCylinders
  const cylinders =
    side.cylinderCount > MAX_VISIBLE_CYLINDERS
      ? [<Cylinder key={0} keyValue={0} side={side} cylinderGroup={cylinderGroup} activeCycle={activeCycle} />]
      : Array.from({ length: side.cylinderCount }, (_, index) => (
          <Cylinder key={index} keyValue={index} side={side} cylinderGroup={cylinderGroup} activeCycle={activeCycle} />
        ))

  return (
    <PlasmicCylinderComponent
      root={{ ref }}
      {...props}
      cylinders={cylinders}
      cylinderCount={side.cylinderCount}
      countVisible={side.cylinderCount > MAX_VISIBLE_CYLINDERS}
      triggerPosition={
        thresholdProps.trigger && side.monitored
          ? side.priority === 1 || side.cylinderCount > MAX_VISIBLE_CYLINDERS
            ? 'left'
            : 'right' //Controls which side the icon will be placed
          : undefined
      }
      triggerLeft={thresholdInfo}
      triggerRight={thresholdInfo}
      // @ts-ignore
      freezeLeft={freezoutPosition}
      // @ts-ignore
      freezeRight={freezoutPosition}
      freezeoutSide={side.isFreezing ? (side.priority === 1 || side.cylinderCount > MAX_VISIBLE_CYLINDERS ? 'left' : 'right') : undefined}
    />
  )
}
const CylinderComponent = React.forwardRef(CylinderComponent_)
export default CylinderComponent
