import React from 'react'
import { useIntl } from 'react-intl'

import Modal from 'components/Modal/Modal'
import UserForm, { UserFormData } from 'modules/users/components/UserForm'
import * as GQL from 'generated/graphql'
import { displayToast } from 'util/toasts'

interface CreateUserModalProps {
  open: boolean
  onClose: () => void
}

const CreateUserModal: React.FC<CreateUserModalProps> = ({ open, onClose }) => {
  const intl = useIntl()
  const t = intl.formatMessage

  const [createUser, { loading }] = GQL.useCreateUser({
    refetchQueries: ['Users'],
    onCompleted: () => {
      displayToast(t({ id: 'create-user.toasts.success' }), 'success')
      onClose()
    },
    onError: error => {
      if (error.message.includes("{'email': ['User with this Email already exists.']}")) {
        displayToast(t({ id: 'settings.users.create.error.exists' }))
      } else {
        displayToast(t({ id: 'create-user.toasts.error' }))
      }
    },
  })

  function handleSubmit(data: UserFormData) {
    createUser({
      variables: data,
    })
  }

  return (
    <Modal isOpen={open} onRequestClose={onClose} stripped overlayStyle={{ alignItems: 'center', overflow: 'auto', padding: '2rem 0rem' }}>
      <UserForm onSubmit={handleSubmit} loading={loading} onClose={onClose} />
    </Modal>
  )
}

export default CreateUserModal
