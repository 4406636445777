import React, { useState, useRef, useEffect } from 'react'

import { PlasmicButtonFilterDropdown } from './plasmic/solace_components/PlasmicButtonFilterDropdown'
import { useClickOutside } from 'util/hooks'

const ButtonFilterDropdown: React.ForwardRefRenderFunction<any, any> = (props, ref) => {
  const dropdownRef = useRef<any>(null)
  const { activeState } = props
  const [open, setOpen] = useState(false)
  const [hasActiveFilter, setHasActiveFilter] = useState(false)

  useClickOutside(dropdownRef, () => setOpen(false))

  useEffect(() => {
    const checkHasActiveFilter = (children: any) => {
      return React.Children.toArray(children).some((child: any) => child.props.filterChecked)
    }

    setHasActiveFilter(checkHasActiveFilter(props?.dropdown?.props?.children ? props.dropdown.props.children : false))
  }, [props.dropdown])

  return (
    <PlasmicButtonFilterDropdown
      root={{ ref: dropdownRef }}
      {...props}
      open={open}
      active={activeState || hasActiveFilter}
      button={{
        onClick: () => setOpen(!open),
      }}
    />
  )
}

export default React.forwardRef(ButtonFilterDropdown)
