import Tippy, { TippyProps } from '@tippyjs/react'

import styled from 'styled-components'
import HelpIconsvgIcon from 'plasmic/plasmic/solace_components/icons/PlasmicIcon__HelpIconsvg'

const Tooltip = styled(Tippy)`
  background: #35416d;
  color: white;
  box-shadow: 0px 4px 16px 0px #111830;
  padding: 8px 14px;
  border-radius: 10px;
  white-space: normal;
  max-width: 250px !important;
  display: flex;
  width: auto;
  justify-content: center;
  font-size: 14px;
  .tippy-arrow {
    color: #35416d;
  }
`

const TooltipInteractive = styled(Tippy)`
  border-radius: 10px;
  display: flex;
  width: auto;
  background: unset;
  width: 300px;
  justify-content: center;
`

interface ITooltipProps extends TippyProps {
  theme?: any
  style?: any
  noMargin?: boolean
  alwaysShow?: boolean
  interactive?: boolean
  children?: any
}

export default function TutorialTooltip(props: ITooltipProps) {
  const { children, ...rest } = props
  return (
    <Tooltip interactiveBorder={0} delay={[100, 0]} touch={['hold', 800]} {...rest} appendTo={props.interactive ? props.appendTo : window.document.body}>
      {props.children}
    </Tooltip>
  )
}

export function TutorialInfoIcon(props: ITooltipProps) {
  const { style, noMargin, alwaysShow, ...rest } = props
  const show = !localStorage.getItem('tutorialInfoIcon')
  if (!show && !alwaysShow) return null

  if (props.interactive) {
    return (
      <TooltipInteractive delay={[100, 0]} {...rest} interactiveBorder={0} touch={['hold', 800]} interactive hideOnClick={false}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'help', ...style, color: '#FFFFFF4D', marginLeft: '5px' }}>
          <HelpIconsvgIcon width='12px' height='12px' />
        </div>
      </TooltipInteractive>
    )
  }

  return (
    <div style={{ marginLeft: noMargin ? '0' : '5px' }}>
      <TutorialTooltip delay={[100, 0]} {...rest}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'help', color: '#FFFFFF4D', ...style }}>
          <HelpIconsvgIcon width='12px' height='12px' />
        </div>
      </TutorialTooltip>
    </div>
  )
}
