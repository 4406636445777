import React, { useContext, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import HeaderContext from 'context/HeaderContext'
import SubnavButton from 'plasmic/SubnavButton'
import Loader from 'components/Loader'
import { CenteredLoader } from 'components/Loader/LoaderWrappers'
import Overview from './Overview'
import AuditLogs from './audit'

const OVERVIEW_PATH = '/insight/overview'
const AUDIT_LOGS_PATH = '/insight/auditlogs'
const REPORT_PATH = '/insight/report'

const Report = React.lazy(() => import(/*webpackChunkName: 'Report' */ './Report'))

const Wrapper = styled.div`
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
`

const Insight: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const headerContext = useContext(HeaderContext)

  useEffect(() => {
    headerContext.setIsSubHeaderVisible(true)
    headerContext.setLeftSubHeaderGroup(
      <>
        <SubnavButton active={location.pathname === OVERVIEW_PATH} title='Overview' onClick={() => navigate(OVERVIEW_PATH)} />
        <SubnavButton active={location.pathname === AUDIT_LOGS_PATH} title='Audit Logs' onClick={() => navigate(AUDIT_LOGS_PATH)} />
        <SubnavButton active={location.pathname === REPORT_PATH} title='Report' onClick={() => navigate(REPORT_PATH)} />
      </>
    )
    headerContext.setRightSubHeaderGroup(<></>)

    return () => {
      headerContext.setIsSubHeaderVisible(false)
      headerContext.setLeftSubHeaderGroup(<></>)
      headerContext.setRightSubHeaderGroup(<></>)
    }
    // eslint-disable-next-line
  }, [location, navigate])

  return (
    <>
      {/* <Wrapper> */}
      <React.Suspense
        fallback={
          <CenteredLoader>
            <Loader color='gray' />
          </CenteredLoader>
        }
      >
        <Routes>
          <Route
            path='/overview'
            element={
              <Wrapper>
                <Overview />
              </Wrapper>
            }
          />
          <Route path='/auditlogs' element={<AuditLogs />} />
          <Route
            path='/report'
            element={
              <Wrapper>
                <Report />
              </Wrapper>
            }
          />
          <Route path='/' element={<Navigate to='/insight/overview' />} />
        </Routes>
      </React.Suspense>
      {/* </Wrapper> */}
    </>
  )
}

export default Insight
