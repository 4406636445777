import React from 'react'
import { useIntl } from 'react-intl'

import ActionDropdownLine from 'plasmic/ActionDropdownLine'
import ActionDropdown from 'plasmic/ActionDropdown'

interface Props {
  onDeleteClick: () => void
}

const NoteActionsDropdown: React.FC<Props> = props => {
  const intl = useIntl()
  const t = intl.formatMessage

  return (
    <ActionDropdown
      children={
        <>
          <ActionDropdownLine onClick={props.onDeleteClick}>{t({ id: 'common.delete' })}</ActionDropdownLine>
        </>
      }
    />
  )
}

export default NoteActionsDropdown
