import React from 'react'
import _ from 'lodash'

import { PlasmicCellChart } from './plasmic/solace_components/PlasmicCellChart'
import { useNavigate } from 'react-router-dom'
import { useCustomerContext } from 'util/hooks'
import { CustomerDrawerTab } from 'context/CustomerContext'

const FULL_WIDTH_STEP = 6
const FULL_WIDTH_HEIGHT = 24

export interface Props {
  numbers: number[]
  customerId: string
  svgWrapper?: any
  className?: string
}

const CellChart: React.ForwardRefRenderFunction<any, Props> = (props, ref) => {
  const navigate = useNavigate()

  const { setTab } = useCustomerContext()

  const handleClick = () => {
    setTab(CustomerDrawerTab.CHART)
    navigate(`?customer=${props.customerId}`)
  }

  const createAreaChartSVG = (key: string, numbers: number[], height: number, step: number, strokeColor = 'currentColor', strokeWidth = 2): string => {
    numbers = _.takeRight(numbers, FULL_WIDTH_HEIGHT)

    if (numbers === undefined) {
      throw new Error('numbers is undefined')
    }

    // Pre-calculate y_pos values
    //const yPosValues = numbers.map((num) => (height - (num / 100) * height) + (strokeWidth / 2)); // Alternative method
    let yPosValues: number[] = []
    numbers.forEach((num, index) => {
      yPosValues[index] = height - (num / 100) * height + strokeWidth / 2
    })

    // Create a list of points for the area chart
    const points: number[][] = []
    let x = -step
    for (let i = 0; i < numbers.length; i += 1) {
      x += step

      points.push([x, yPosValues[i]])

      // If the next number is greater than the current number,
      // add another point at the same y position at the next step,
      // creating a vertical line to the next number
      if (i < numbers.length - 1 && numbers[i] < numbers[i + 1]) {
        points.push([x + step, yPosValues[i]])
      }
    }

    // Add poins to close the area
    points.push([x, height + strokeWidth])
    points.push([0, height + strokeWidth])

    // Create the path for the line and the filled area
    let fillPath,
      linePath = 'M'

    fillPath = linePath += `${points[0][0]},${points[0][1].toFixed(2)} `

    // Skip the two last points for the line
    const linePoints = points.slice(1, -2).map(point => `L${point[0]},${point[1].toFixed(2)}`)
    const fillPoints = points.map(point => `L${point[0]},${point[1].toFixed(2)}`)

    linePath += linePoints.join('')
    fillPath += fillPoints.join('') + 'Z'

    const gradient = `<defs><linearGradient id="gradient${key}" x1="0%" y1="0%" x2="0%" y2="100%"><stop offset="0%" stop-color="currentColor" stop-opacity="0.8" /><stop offset="100%" stop-color="currentColor" stop-opacity="0.0" /></linearGradient></defs>`

    const svg = `<svg height="${height + strokeWidth}" width="${x}">\n
      ${gradient}
      <path d="${fillPath}" fill="url(#gradient${key})"/>\n
      <path d="${linePath}" fill="none" stroke="${strokeColor}" stroke-width="${strokeWidth}"/>\n
    </svg>`

    return svg
  }

  return (
    <PlasmicCellChart
      root={{ ref }}
      className={props.className ? props.className : ''}
      svgWrapper={props.svgWrapper}
      svg={props.numbers.length > 0 ? createAreaChartSVG(props.customerId, props.numbers, FULL_WIDTH_HEIGHT, FULL_WIDTH_STEP) : ''}
      onClick={handleClick}
    />
  )
}

export default React.forwardRef(CellChart)
