import React, { useMemo } from 'react'

import * as GQL from 'generated/graphql'
import Modal from 'components/Modal/Modal'
import 'modules/customers/components/CellTagsCss.css'
import ModalCommunication from 'plasmic/ModalCommunication'

interface Props {
  isOpen: boolean
  onClose: () => void
  customerIds: string[]
  setCustomerIds: React.Dispatch<React.SetStateAction<Set<string>>>
}

const MassCommunicationModal: React.FC<Props> = props => {
  console.log(props.customerIds)
  const { data: dataCustomers, loading: loadingCustomers } = GQL.useAllCustomersByIds({
    variables: { ids: props.customerIds },
    skip: props.customerIds.length === 0,
  })
  const customers = useMemo(() => dataCustomers?.allCustomersByIds?.edges.map(edge => edge?.node as GQL.CustomerNode) || [], [dataCustomers])

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={() => props.onClose()}
      overlayStyle={{ alignItems: 'flex-start', overflow: 'auto', padding: '2rem 0rem' }}
      stripped
    >
      <ModalCommunication
        customers={customers}
        isLoading={loadingCustomers}
        totalCount={dataCustomers?.allCustomersByIds?.totalCount || 0}
        loadingFetchMore={false}
        onLoadMoreClick={() => console.warn('Load more not implemented')}
        setCustomerIds={props.setCustomerIds}
      />
    </Modal>
  )
}

export default MassCommunicationModal
