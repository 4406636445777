import React from 'react'
import ModalConfirm from 'plasmic/ModalConfirm'

import * as GQL from 'generated/graphql'
import { useIntl } from 'react-intl'
import Modal from 'components/Modal/Modal'
import { displayToast } from 'util/toasts'

interface Props {
  isOpen: boolean
  onRequestClose: () => void
  onRequestCloseParent: () => void
  contactSignup: GQL.CustomerContactSignupNode
}

const DeleteContactSignupModal: React.FC<Props> = props => {
  const intl = useIntl()
  const t = intl.formatMessage

  const [rejectContactSignup] = GQL.useDeleteCustomerContactSignupMutation({
    refetchQueries: ['AllCustomerContactSignups'],
    onCompleted: () => {
      displayToast(t({ id: 'customers.toast.contact-signups.delete.success' }), 'success')
    },
    onError: () => {
      displayToast(t({ id: 'customers.toast.contact-signups.delete.error' }), 'error')
    },
  })

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.onRequestClose} stripped>
      <ModalConfirm
        btnClose={{ onClick: props.onRequestClose }}
        btnCancel={{ onClick: props.onRequestClose }}
        title={t({ id: 'customers.contact-signups.delete-modal.title' })}
        description={t({ id: 'customers.contact-signups.delete-modal.body' })}
        btnColor='red'
        btnConfirm={{
          onClick: () => {
            rejectContactSignup({
              variables: {
                id: props.contactSignup.id,
              },
            })
            props.onRequestClose()
            props.onRequestCloseParent()
          },
        }}
      />
    </Modal>
  )
}

export default DeleteContactSignupModal
