import { useState } from 'react'
import { useIntl } from 'react-intl'

import { useCreatePriceCategory } from 'generated/graphql'
import { displayToast } from 'util/toasts'
import * as GQL from 'generated/graphql'
import Modal from 'components/Modal/Modal'
import ModalPriceList from 'plasmic/ModalPriceList'
import { useAppContext } from 'util/hooks'

interface PriceCategoryModalProps {
  pricingCategories?: GQL.PriceCategoryNode[]
  priceCategory?: GQL.PriceCategoryNode
  onClose: () => void
}

export function PriceCategoryModal({ pricingCategories, priceCategory, onClose }: PriceCategoryModalProps) {
  const [category, setCategory] = useState(priceCategory?.name || '')

  const intl = useIntl()
  const t = intl.formatMessage

  const { appContext, setAppContext } = useAppContext()

  const [createPriceCategory, { loading }] = useCreatePriceCategory({
    onCompleted: response => {
      if (response.createPriceCategory?.ok) {
        displayToast(t({ id: 'settings.pricing-lists.create.success' }), 'success')

        if (appContext.distributor) {
          setAppContext({
            ...appContext,
            distributor: {
              ...appContext.distributor,
              priceCategories: [...(appContext.distributor.priceCategories || []), response.createPriceCategory.priceCategory],
            } as GQL.DistributorNode,
          })
        }
        onClose()
      } else {
        displayToast(t({ id: 'settings.pricing-lists.create.error' }))
      }
    },
    onError: () => {
      displayToast(t({ id: 'settings.pricing-lists.create.error' }))
    },
  })

  const [patchPriceCategory, { loading: patchPriceCategoryLoading }] = GQL.usePatchPriceCategory({
    onCompleted: response => {
      if (response.patchPriceCategory?.ok) {
        displayToast(t({ id: 'settings.pricing-lists.update.success' }), 'success')

        if (appContext.distributor) {
          const updatedPriceCategories = appContext?.distributor?.priceCategories?.map(priceCategory => {
            if (priceCategory?.id === response?.patchPriceCategory?.priceCategory?.id) {
              return response?.patchPriceCategory?.priceCategory
            }
            return priceCategory
          })

          setAppContext({
            ...appContext,
            distributor: {
              ...appContext.distributor,
              priceCategories: updatedPriceCategories,
            } as GQL.DistributorNode,
          })
        }
        onClose()
      } else {
        displayToast(t({ id: 'settings.pricing-lists.update.error' }))
      }
    },
    onError: () => {
      displayToast(t({ id: 'settings.pricing-lists.update.error' }))
    },
  })

  const handleSubmit = () => {
    if (category.length < 3) {
      displayToast(t({ id: 'settings.pricing-lists.modal.error.empty' }))
      return
    } else if (pricingCategories && !!pricingCategories.find(priceCategory => priceCategory.name.toLowerCase() === category.toLowerCase())) {
      displayToast(t({ id: 'settings.pricing-lists.modal.error.exists' }))
      return
    }
    if (priceCategory?.id) {
      patchPriceCategory({
        variables: {
          id: priceCategory.id,
          input: {
            name: category,
            active: true,
          },
        },
      })
    } else {
      createPriceCategory({
        variables: {
          input: {
            name: category,
            active: true,
          },
        },
      })
    }
  }

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      stripped
      contentStyle={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      overlayStyle={{ alignItems: 'flex-start', overflow: 'auto', padding: '2rem 0rem' }}
    >
      <ModalPriceList
        title={priceCategory ? t({ id: 'settings.pricing-lists.modal.title.edit' }) : t({ id: 'settings.pricing-lists.modal.title.create' })}
        inputPricingListTitle={{
          fieldStatus: true,
          alert:
            category.length < 3 ||
            (pricingCategories && !!pricingCategories.find(priceCategory => priceCategory.name.toLowerCase() === category.toLowerCase())),
          undefinedInput: {
            name: 'inputPricingListTitle',
            value: category,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCategory(e.target.value),
          },
        }}
        btnClose={{
          onClick: () => onClose(),
        }}
        btnCreatePricingList={{
          label: priceCategory ? t({ id: 'common.edit' }) : t({ id: 'common.create' }),
          onClick: () => handleSubmit(),
          loading: loading || patchPriceCategoryLoading,
        }}
      />
    </Modal>
  )
}
