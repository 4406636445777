import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { format } from 'date-fns'
import Select from 'react-select'

import * as GQL from 'generated/graphql'
import { useAppContext } from 'util/hooks'
import { isLocal, registerSensorAppUrl } from 'util/env'
import C from 'components'
import CylinderGroupHistory from 'modules/hardware/CylinderGroupHistory'
import CylinderGroupSide from '../components/CylinderGroupSide'
import { displayToast } from 'util/toasts'
import { isMonitored } from '../util'
import TutorialTooltip, { TutorialInfoIcon } from 'components/Tooltip/TutorialTooltip'
import { Control, MenuList, Option, reactSelectStyles } from 'plasmic/StyledReactSelect'
import SensorEmptyState from 'plasmic/SensorEmptyState'
import MonitorTab from 'plasmic/MonitorTab'
import ButtonRoundIcon from 'plasmic/ButtonRoundIcon'
import SettingssvgIcon from 'plasmic/plasmic/solace_components/icons/PlasmicIcon__Settingssvg'
import ChecksvgIcon from 'plasmic/plasmic/solace_components/icons/PlasmicIcon__Checksvg'
import CrosssvgIcon from 'plasmic/plasmic/solace_components/icons/PlasmicIcon__Crosssvg'
import { handlePriorityCylinderSideSorting } from 'modules/hardware/util'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 580px;
  align-self: center;
`

const EmptyStateWrapper = styled.div`
  height: 452px;
`

const Stats = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.2em 0;

  div {
    flex: 1;
    text-align: right;
  }
  span {
    flex: 1;
    padding-left: 1em;
    margin-left: 1em;
    color: #fff;
    text-align: left;
    border-left: 1px solid #555;
  }
`

const ADMIN_HISTORY_FIELDS: { [key: string]: string | boolean }[] = [
  { name: 'createdAt', label: 'Created', date: true },
  { name: 'updatedAt', label: 'Last update', date: true },
  { name: 'start', label: 'Start cycle', date: true },
  { name: 'end', label: 'End cycle', date: true },
  { name: 'last', label: 'Last cycle calc', date: true },

  { name: 'div', label: 'State' },
  { name: 'state', label: 'State' },
  { name: 'error', label: 'Error' },

  { name: 'div', label: 'Weight' },
  { name: 'currentWeight', label: 'Weight', number: true },
  { name: 'startWeight', label: 'Start weight', number: true },
  { name: 'lastWeight', label: 'Last weight (end weight)', number: true },
  { name: 'tareWeight', label: 'Tare weight', number: true },

  { name: 'div', label: 'Dates' },
  { name: 'refillDate', label: 'Refill date', date: true },
  { name: 'depletionDate', label: 'Depletion date', date: true },
  { name: 'emptyDate', label: 'Empty date', date: true },
  { name: 'stopDate', label: 'Stop date', date: true },

  { name: 'div', label: 'Depletion' },
  { name: 'estimatedEmpty', label: 'Estimated empty', date: true },
  { name: 'remainingPercentage', label: 'Remaining gas percentage' },
]

interface IMonitorTabProps {
  cylinderGroup: GQL.CylinderGroupNode
}

function CylindersTab({ cylinderGroup }: IMonitorTabProps) {
  const [cylinderGroupInfo, setCylinderGroupInfo] = useState(cylinderGroup)
  const [monitorTemperature, setMonitorTemperature] = useState(!!cylinderGroup.customer.temperatureGroup)
  const [editMode, setEditMode] = useState(false)

  const { appContext } = useAppContext()
  const contextUser = appContext.user

  const intl = useIntl()
  const t = intl.formatMessage

  const { data: productsData } = GQL.useAllProducts({ variables: { service: false } })

  const products = useMemo(() => {
    return productsData?.availableProducts?.edges.map(product => product?.node as GQL.ProductNode) || []
  }, [productsData])

  useEffect(() => {
    // ensures it updates draft cylinder group info after cylinder group was changed (cache updates)
    setCylinderGroupInfo({ ...cylinderGroup })
  }, [cylinderGroup])

  useEffect(() => {
    setMonitorTemperature(!!cylinderGroup.customer.temperatureGroup)
  }, [cylinderGroup.customer.temperatureGroup])

  const sortedSides = useMemo(() => {
    return cylinderGroup?.cylinderSides ? handlePriorityCylinderSideSorting(cylinderGroup.cylinderSides) : []
  }, [cylinderGroup?.cylinderSides])

  const draftSides = useMemo(() => {
    return cylinderGroupInfo?.cylinderSides ? handlePriorityCylinderSideSorting(cylinderGroupInfo.cylinderSides) : []
  }, [cylinderGroupInfo])

  const hasSensorInstallation = useMemo(() => {
    return draftSides.some(side => side && isMonitored(side)) ?? false
  }, [draftSides])

  const sideFlipped = useMemo(() => {
    return sortedSides.some(side => {
      const draftSide = draftSides.find(draftSide => draftSide?.id === side?.id)
      return draftSide?.priority === side?.priority
    })
  }, [sortedSides, draftSides])

  const [patchCylinderGroup, { loading: patchCylinderGroupLoading }] = GQL.usePatchCylinderGroup({
    // TODO: update cache
    onCompleted: response => {
      if (response.patchCylinderGroup?.ok && response.patchCylinderGroup.cylinderGroup) {
        setCylinderGroupInfo(response.patchCylinderGroup.cylinderGroup as GQL.CylinderGroupNode)
        displayToast(t({ id: 'customers.cylinders.setup.success' }), 'success')
      } else {
        displayToast(t({ id: 'customers.cylinders.setup.error' }))
      }
    },
    onError: () => {
      displayToast(t({ id: 'customers.cylinders.setup.error' }))
    },
  })

  const [patchTemperatureMonitoring, { loading: patchTemperatureMonitoringLoading }] = GQL.usePatchCustomerTemperatureMonitoring({
    update: (cache, { data: redoData }) => {
      cache.writeFragment({
        id: cache.identify({ id: cylinderGroup.customer.id, __typename: 'CustomerNode' }),
        fragment: GQL.CustomerInfo,
        fragmentName: 'CustomerInfo',
        data: redoData?.patchCustomerTemperatureMonitoring?.customer,
      })
    },
    onCompleted: response => {
      if (response.patchCustomerTemperatureMonitoring?.ok) {
        displayToast(t({ id: 'customers.cylinders.setup.success' }), 'success')
      } else {
        displayToast(t({ id: 'customers.cylinders.setup.error' }))
      }
    },
    onError: () => {
      displayToast(t({ id: 'customers.cylinders.setup.error' }))
    },
  })

  const handleToggleTemperatureMonitoring = useCallback(() => {
    if (patchTemperatureMonitoringLoading) return
    patchTemperatureMonitoring({
      variables: {
        customerId: cylinderGroup.customer.id,
        monitoringStatus: !monitorTemperature,
      },
    })
  }, [patchTemperatureMonitoringLoading, patchTemperatureMonitoring, cylinderGroup.customer.id, monitorTemperature])

  if (!hasSensorInstallation) {
    return (
      <Wrapper>
        <EmptyStateWrapper>
          <SensorEmptyState
            btnRegisterSensor={{
              onClick: () => window.open(registerSensorAppUrl(), '_blank'),
            }}
          />
        </EmptyStateWrapper>
        <CylinderGroupHistory customer={cylinderGroup.customer} sides={draftSides} />
      </Wrapper>
    )
  }

  const handleChangeMode = (mode: GQL.CylinderGroupMode) => {
    if (mode === GQL.CylinderGroupMode.Parallel || mode === GQL.CylinderGroupMode.Insitu) {
      const sensorSides = sortedSides.filter(side => side?.sensors?.length! > 0)
      if (sensorSides.length > 1) {
        displayToast('You need to remove sensor from one of the sides to switch to this mode!', 'error')
        return
      }
      const monitoredSide = sortedSides.findIndex(side => side && isMonitored(side))
      const monitoredSideIndex = monitoredSide >= 0 ? monitoredSide : 0
      setCylinderGroupInfo({ ...cylinderGroupInfo, cylinderMode: mode, cylinderSides: [sortedSides[monitoredSideIndex]], numberOfSides: 1 })
    } else {
      const hasOtherSide = sortedSides.length > 1
      if (hasOtherSide) {
        setCylinderGroupInfo({ ...cylinderGroupInfo, cylinderMode: mode, cylinderSides: [...sortedSides] })
      } else {
        // since previous mode required to have one side (parallel/insitu) we need additional one to switch to standard (we need to cast it as unknown because it doesnt have ID yet.)
        const additionalSide = {
          cylinderCount: sortedSides[0]?.cylinderCount,
          activeCycle: { state: GQL.CycleState.Unknown } as GQL.CycleNode,
          sensors: [],
          priority: 0,
        } as unknown as GQL.CylinderGroupSideNode
        const currentSide = sortedSides[0] as GQL.CylinderGroupSideNode

        setCylinderGroupInfo({
          ...cylinderGroupInfo,
          cylinderMode: mode,
          numberOfSides: 2,
          cylinderSides: [additionalSide, { ...currentSide, priority: 1 }],
        })
      }
    }
  }

  const handleSave = () => {
    patchCylinderGroup({
      variables: {
        id: cylinderGroup.id,
        input: {
          productId: cylinderGroupInfo?.product?.id || cylinderGroup.product?.id,
          cylinderMode: cylinderGroupInfo.cylinderMode,
          cylinderSides: cylinderGroupInfo?.cylinderSides?.map(cylinderSide => {
            if (cylinderSide?.id) {
              return {
                id: cylinderSide.id,
                cylinderCount: cylinderSide.cylinderCount,
                priority: cylinderSide.priority,
                activeCycle: {
                  state: cylinderSide?.activeCycle?.state || GQL.CycleState.Unknown,
                },
              }
            }
            return {
              cylinderCount: cylinderSide?.cylinderCount || 1,
              priority: cylinderSide?.priority || 0,
              activeCycle: {
                state: cylinderSide?.activeCycle?.state || GQL.CycleState.Unknown,
              },
            }
          }),
        },
      },
    })
    setEditMode(false)
  }

  const handleFlipSetup = () => {
    if (cylinderGroupInfo.cylinderSides && cylinderGroupInfo.cylinderSides.length === 2) {
      const modifiedCylinderGroupSides = draftSides.map((cylinderSide, index) => {
        // previously left-side
        if (index === 0) {
          return { ...cylinderSide, priority: 1 }
        }
        // previously right-side
        return { ...cylinderSide, priority: 0 }
      })
      setCylinderGroupInfo({ ...cylinderGroupInfo, cylinderSides: modifiedCylinderGroupSides as GQL.CylinderGroupSideNode[] })
    } else {
      displayToast(t({ id: 'customers.cylinders-tab.flip-side.error' }))
    }
  }

  return (
    <MonitorTab
      sidebarAccordion={{
        helpText: <TutorialInfoIcon content={t({ id: 'tooltips.customers.drawer.tabs.monitor' })} />,
        headRight: (
          <>
            {!editMode ? (
              <ButtonRoundIcon
                onClick={() => setEditMode(prev => !prev)}
                title={t({ id: 'common.edit' })}
                icon={<SettingssvgIcon style={{ width: '10px' }} />}
              />
            ) : (
              <div style={{ display: 'flex', columnGap: '0.5rem' }}>
                <ButtonRoundIcon
                  onClick={() => {
                    setEditMode(prev => !prev)
                    setCylinderGroupInfo(cylinderGroup)
                  }}
                  title={t({ id: 'common.cancel' })}
                  color='red'
                  icon={<CrosssvgIcon style={{ width: '10px' }} />}
                />
                <ButtonRoundIcon
                  onClick={() => (!patchCylinderGroupLoading ? handleSave() : null)}
                  color='green'
                  title={t({ id: 'common.save' })}
                  icon={<ChecksvgIcon style={{ width: '10px' }} />}
                />
              </div>
            )}
          </>
        ),
      }}
      monitorCylinders={{
        buttonFlip: {
          flip: sideFlipped,
          onClick: () => handleFlipSetup(),
        },
        selectProductDiv: (
          <Select
            id='select-product'
            placeholder={t({ id: 'common.select-product' })}
            components={{ Option, Control, MenuList }}
            styles={reactSelectStyles}
            isMulti={false}
            onChange={event =>
              setCylinderGroupInfo({
                ...cylinderGroupInfo,
                product: event.value as GQL.ProductNode,
                standardCylinderGasWeight: event.value.weight,
                gasType: event.value.type,
              })
            }
            value={{
              value: cylinderGroupInfo?.product || '',
              label: cylinderGroupInfo?.product?.displayName || t({ id: 'common.select-product' }),
            }}
            options={products.map(product => ({
              value: product,
              label: product.displayName,
            }))}
          />
        ),
        parallel:
          cylinderGroupInfo.cylinderMode === GQL.CylinderGroupMode.Parallel ||
          cylinderGroupInfo.cylinderMode === GQL.CylinderGroupMode.Insitu ||
          cylinderGroupInfo.cylinderSides?.length === 1,
        editSetup: editMode,
        tabSelectorSetupType: {
          selected: cylinderGroupInfo.cylinderMode?.toLowerCase(),
          btnStandard: {
            onClick: () => handleChangeMode(GQL.CylinderGroupMode.Standard),
          },
          btnParallel: {
            props: {
              onClick: () => handleChangeMode(GQL.CylinderGroupMode.Parallel),
            },
          },
          btnInsitu: {
            onClick: () => handleChangeMode(GQL.CylinderGroupMode.Insitu),
          },
        },
        left: {
          render: () =>
            draftSides && draftSides[0] ? (
              <CylinderGroupSide
                cylinderGroup={cylinderGroupInfo}
                updateCylinderGroup={setCylinderGroupInfo}
                editMode={editMode}
                side={draftSides[0] as GQL.CylinderGroupSideNode}
                isLeftSide
              />
            ) : (
              <></>
            ),
        },
        right: {
          render: () =>
            draftSides && draftSides.length > 1 && draftSides[1] ? (
              <CylinderGroupSide
                cylinderGroup={cylinderGroupInfo}
                updateCylinderGroup={setCylinderGroupInfo}
                editMode={editMode}
                side={draftSides[1] as GQL.CylinderGroupSideNode}
                isLeftSide={false}
              />
            ) : (
              <></>
            ),
        },
        temperature: appContext.distributor?.monitorTemperature,
        temperatureInfo: {
          monitored: !!cylinderGroup.customer.temperatureGroup,
          temperature: !!cylinderGroup.customer.temperatureGroup?.temperature
            ? cylinderGroup.customer.temperatureGroup?.temperature?.toString()
            : 'No reading available',
          hideSuffix: !cylinderGroup.customer.temperatureGroup?.temperature,
          alert: cylinderGroup.customer.temperatureGroup?.isFrozen,
          toggle: {
            on: monitorTemperature,
            onClick: handleToggleTemperatureMonitoring,
          },
          freezeTooltip: {
            wrap: (content: any) => (
              <TutorialTooltip
                content={t({ id: 'alerts.freezing-temperature' }, { temperature: cylinderGroup.customer.temperatureGroup?.temperature?.toString() })}
              >
                {content}
              </TutorialTooltip>
            ),
          },
        },
      }}
      monitorHistory={<CylinderGroupHistory customer={cylinderGroup.customer} sides={sortedSides} />}
      superUser={contextUser?.isSuperuser}
      superUserAccordion={{
        helpText: <TutorialInfoIcon content={t({ id: 'tooltips.customers.drawer.tabs.monitor.admin' })} />,
        accordionContent: (
          <div style={{ width: '100%' }}>
            {cylinderGroup.cylinderSides?.map(cylinderSide => (
              <div key={cylinderSide?.id + 'history side'}>
                {cylinderSide?.activeCycle?.lastCalculation && (
                  <div style={{ display: 'flex', justifyContent: 'center', padding: '.5em', color: '#fff' }}>
                    <a
                      href={
                        (isLocal() ? 'http://localhost:9000/calculations/' : 'https://lambda-calc-graphs.s3.eu-west-1.amazonaws.com/') +
                        (cylinderSide?.sensors as GQL.SensorNode[])[0]?.serialNumber +
                        '_' +
                        cylinderSide?.activeCycle?.lastCalculation +
                        '_' +
                        (process.env.REACT_APP_ENVIRONMENT === 'prod' ? 'prod' : 'staging') +
                        '_plot'
                      }
                      target='_new'
                    >
                      <C.Button color='blue' width='100%' borderRadius='4px'>
                        Last calculation graph
                      </C.Button>
                    </a>
                  </div>
                )}
                <div style={{ display: 'flex', flexDirection: 'column', padding: '1em' }}>
                  <Stats>
                    <div>Serial</div>
                    <span>{(cylinderSide?.sensors as GQL.SensorNode[])?.find(s => true)?.serialNumber}</span>
                  </Stats>
                  <Stats>
                    <div>IMEI</div>
                    <span>{(cylinderSide?.sensors as GQL.SensorNode[])?.find(s => true)?.imei}</span>
                  </Stats>
                  {ADMIN_HISTORY_FIELDS.map((f, i) =>
                    f.name === 'div' ? (
                      <div
                        key={i}
                        style={{
                          flex: '1',
                          height: '1',
                          paddingBottom: '1em',
                          paddingTop: '1em',
                          marginTop: '1em',
                          borderTop: '1px solid #555',
                          textAlign: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {f.label}
                      </div>
                    ) : (
                      <Stats key={`${i} stats`}>
                        <div>{f.label}</div>
                        <span>
                          {cylinderSide?.activeCycle && cylinderSide.activeCycle[f.name as keyof GQL.CycleNode] !== undefined
                            ? f.date
                              ? format(new Date(cylinderSide.activeCycle[f.name as keyof GQL.CycleNode]), 'd MMMM yyyy (HH:mm)')
                              : f.number && cylinderSide.activeCycle[f.name as keyof GQL.CycleNode]
                                ? parseFloat(cylinderSide.activeCycle[f.name as keyof GQL.CycleNode] as string).toFixed(2)
                                : cylinderSide.activeCycle[f.name as keyof GQL.CycleNode]
                            : 'Not available'}
                        </span>
                      </Stats>
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
        ),
      }}
    />
  )
}

export default CylindersTab
