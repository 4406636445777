// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type InvoiceFillsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function InvoiceFillsvgIcon(props: InvoiceFillsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 18.66 21.977"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M18.66 2.439a1.999 1.999 0 00-1.19-1.86 2 2 0 00-2.16.3c-.14.12-.28.25-.42.39a1.8 1.8 0 01-1.39.72 1.772 1.772 0 01-1.38-.71 3.64 3.64 0 00-5.54 0 1.799 1.799 0 01-1.39.71 1.772 1.772 0 01-1.38-.71l-.46-.4a2.001 2.001 0 00-2.15-.3A2 2 0 000 2.439v17.1a2 2 0 001.2 1.86 2.001 2.001 0 002.15-.3c.14-.12.28-.25.42-.39a1.772 1.772 0 011.42-.72 1.799 1.799 0 011.39.71 3.64 3.64 0 005.54 0 1.772 1.772 0 011.38-.71 1.799 1.799 0 011.39.71c.14.14.28.27.42.39a2 2 0 002.16.3 1.999 1.999 0 001.19-1.86V2.439zm-6.31 12.05h-7a1 1 0 010-2h7a1 1 0 010 2zm1-5h-8a1 1 0 010-2h8a1 1 0 010 2z"
        }
      ></path>
    </svg>
  );
}

export default InvoiceFillsvgIcon;
/* prettier-ignore-end */
