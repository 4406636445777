import styled from 'styled-components'
import TableCell from './TableCell'
import TableHeaderCell from './TableHeaderCell'

interface ITableRowProps {
  striped?: boolean
  width?: string
  noBorders?: boolean
}

const TableRow = styled.tr<ITableRowProps>`
  :nth-of-type(2n + 1) {
    ${props => props.striped && `background: rgba(0, 0, 0, 0.025);`}
  }

  :last-of-type {
    ${TableCell} {
      border-bottom: none;
    }
  }

  width: ${props => props.width};

  ${TableCell} {
    border-bottom: 1px solid ${props => props.theme.colors.veryLightGrayVariant};

    ${props => props.noBorders && `border: none`};
  }

  ${TableHeaderCell} {
    border-bottom: 2px solid ${props => props.theme.colors.veryLightGrayVariant};

    ${props => props.noBorders && `border: none`};
  }
`

export default TableRow
