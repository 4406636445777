// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type NoSensorIconsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function NoSensorIconsvgIcon(props: NoSensorIconsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 213.808 214.123"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M200.298 0l-24.837 24.836-.836-.731-3.828-3.01-2.636-1.507-2.171-.702-2.071-.295-1.852.029-1.733.296-37.452 8.635-4.313.861-4.359.594-4.332.352-4.326.068-7.118-.439-4.224-.591-4.153-.842-37.55-8.649-3.087-.317-2.196.244-2.022.581-1.655.764-1.505.961-3.758 2.98-3.652 3.117-3.495 3.297-3.339 3.449-3.182 3.607-3.027 3.739-2.85 3.854-2.648 3.983-2.492 4.118-2.315 4.208-2.115 4.318-1.912 4.406-1.737 4.477-1.512 4.563-1.314 4.608-1.112 4.674-.891 4.72L0 94.07l.021 3.038.48 2.22.789 1.955.947 1.564 1.11 1.378 26.199 28.103 2.886 3.324 2.707 3.477 2.444 3.565 2.239 3.708 3.175 6.389 1.294 3.216-30.475 30.474 13.511 13.51L213.808 13.51zm11.934 89.256l-.894-4.743-1.114-4.672-1.313-4.607-1.515-4.564-1.714-4.477-1.937-4.406-2.114-4.316-.806-1.478L58.091 198.725l-.243.243.622 1.389 1.327 1.839 1.52 1.466 1.503 1.057 1.569.793 4.454 1.804 4.541 1.58 4.608 1.379 4.651 1.157 4.721.981 4.74.732 4.762.535 4.787.333 4.81.11 4.808-.111 4.786-.334 4.783-.536 4.746-.734 4.693-.981 4.654-1.158 4.606-1.379 4.544-1.583 4.494-1.826 2.637-1.529 1.694-1.52 1.282-1.658.895-1.594.633-1.651 11.226-36.741 1.433-4.184 1.648-4.071 1.871-3.895 2.106-3.799 3.941-5.95 2.62-3.347 2.803-3.2 26.275-28.166 1.77-2.461.921-2.092.523-2.032.158-1.821-.094-1.775z"
        }
      ></path>
    </svg>
  );
}

export default NoSensorIconsvgIcon;
/* prettier-ignore-end */
