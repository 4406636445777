import { IAppContext } from 'context/AppContext'
import * as GQL from 'generated/graphql'

export const getCurrencySymbol = (currency: GQL.Currency | undefined) => {
  if (currency === GQL.Currency.Eur) {
    return '€'
  } else if (currency === GQL.Currency.Gbp) {
    return '£'
  } else if (currency === GQL.Currency.Aud) {
    return '$'
  } else if (currency === GQL.Currency.Nok) {
    return 'NOK'
  } else if (currency === GQL.Currency.Sek) {
    return 'SEK'
  } else if (currency === GQL.Currency.Nzd) {
    return '$'
  }
  return '$'
}

export const paymentEnabled = (appContext: IAppContext) => {
  if (!appContext || !appContext.distributor) return false

  const stripePaymentAlternatives = appContext.distributor.paymentSetting?.stripe?.paymentAlternatives
  const vismaPaymentAlternatives = appContext.distributor.paymentSetting?.visma?.paymentAlternatives

  return !!(
    (appContext.distributor.paymentSetting?.stripe?.payoutsEnabled && !stripePaymentAlternatives) ||
    (appContext.distributor.paymentSetting?.stripe?.payoutsEnabled &&
      (stripePaymentAlternatives?.inAdvance || stripePaymentAlternatives?.onDelivery || stripePaymentAlternatives?.credit)) ||
    vismaPaymentAlternatives?.credit
  )
}
