import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import {
  createChart,
  Time,
  AreaData,
  LineData,
  SeriesMarker,
  SeriesMarkerPosition,
  SeriesMarkerShape,
  AreaSeriesPartialOptions,
  LineSeriesPartialOptions,
  ISeriesApi,
} from 'lightweight-charts'

import { CycleNode, CylinderGroupNode } from 'generated/graphql'
import { Calculation } from 'services/sensorCalculation'
import { PlasmicChartComponent } from './plasmic/solace_components/PlasmicChartComponent'

import './ChartComponent.css'
import { getRemainingGas } from 'modules/hardware/util'

const ChartComponentContainer = styled.div`
  width: 100%;
  height: 100%;
`

const chartConfig = {
  width: 0,
  height: 0,
  rightPriceScale: {
    visible: true,
    scaleMargins: {
      top: 0.2,
      bottom: 0.0,
    },
    borderVisible: false,
  },
  timeScale: {
    borderVisible: false,
  },
  layout: {
    background: {
      color: '#242C48',
    },
    textColor: '#ffffff',
  },
  grid: {
    horzLines: {
      color: 'rgba(255, 255, 255, 0.05)',
    },
    vertLines: {
      color: 'transparent',
    },
  },
  handleScroll: {
    horzTouchDrag: true,
    mouseWheel: true,
    pressedMouseMove: true,
    vertTouchDrag: true,
  },
  handleScale: {
    mouseWheel: true,
    axisDoubleClickReset: true,
  },
}

const seriesAutoscaleInfoProviderOptions = {
  autoscaleInfoProvider: () => ({
    priceRange: {
      minValue: -10,
      maxValue: 90,
    },
  }),
}

const weightsSeriesConfig: AreaSeriesPartialOptions = {
  title: 'Weight',
  topColor: 'rgba(44, 151, 222, 0.56)',
  bottomColor: 'rgba(44, 151, 222, 0.04)',
  lineColor: 'rgba(44, 151, 222, 1)',
  lineWidth: 2,
  lineType: 0,
  ...seriesAutoscaleInfoProviderOptions,
}

const predictionsSeriesConfig: LineSeriesPartialOptions = {
  title: 'Prediction',
  color: 'rgba(253, 171, 61, 1.0)',
  lineWidth: 2,
  lineType: 0,
  lineStyle: 1,
  ...seriesAutoscaleInfoProviderOptions,
}

const refillingEventConfig = {
  text: 'Refilled',
  color: '#00D4A6',
  shape: 'circle' as SeriesMarkerShape,
  position: 'aboveBar' as SeriesMarkerPosition,
}

const depletingEventConfig = {
  text: 'Depletion started',
  color: '#2C97DE',
  shape: 'circle' as SeriesMarkerShape,
  position: 'aboveBar' as SeriesMarkerPosition,
}

interface Props {
  id: number // Workaround for https://smartcylinders.atlassian.net/browse/SW-240
  calculation: Calculation
  activeCycle: CycleNode
  cylinderGroup: CylinderGroupNode
  setHasError: (value: boolean) => void
}

const ChartComponent: React.ForwardRefRenderFunction<any, Props> = (props, ref) => {
  const intl = useIntl()
  const t = intl.formatMessage
  const chartRef = React.useRef(null)

  const transformCalculationToChartData = (
    calculation: Calculation
  ): {
    weights: AreaData[]
    predictions: LineData[]
    markers: SeriesMarker<Time>[]
  } => {
    const weights: AreaData[] = []
    const predictions: LineData[] = []
    const markers: SeriesMarker<Time>[] = []

    for (const entry of calculation.entries) {
      if (entry.value === undefined && entry.prediction !== undefined) {
        continue
      }

      if (entry.value !== undefined) {
        weights.push({
          time: entry.timestamp as Time,
          value: entry.value,
        })
      }

      for (const event of entry.events) {
        switch (event) {
          case 'REFILLING':
            markers.push({
              time: entry.timestamp as Time,
              ...refillingEventConfig,
            })
            break
          case 'DEPLETING':
            markers.push({
              time: entry.timestamp as Time,
              ...depletingEventConfig,
            })
            break
        }
      }
    }

    if (
      weights.length === 0 ||
      props.activeCycle.tareWeight === null ||
      props.activeCycle.tareWeight === undefined ||
      !props.cylinderGroup.standardCylinderGasWeight
    ) {
      return {
        weights,
        predictions,
        markers,
      }
    }

    const now = new Date()
    const nowTimestamp = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours()).getTime()
    const hourFromNowTimestamp = nowTimestamp + 3600000
    const nowTimestampSeconds = Math.floor(Date.now() / 1000)
    const lastEntry = weights[weights.length - 1]

    if (lastEntry && lastEntry.time === nowTimestampSeconds) {
      lastEntry.value = getRemainingGas(props.activeCycle).percentage || 0
    } else {
      weights.push({
        time: nowTimestampSeconds as Time,
        value: getRemainingGas(props.activeCycle).percentage || 0,
      })
    }

    if (!props.activeCycle.estimatedEmpty) {
      return {
        weights,
        predictions,
        markers,
      }
    }

    const estimatedEmpty = new Date(props.activeCycle.estimatedEmpty)
    const estimatedEmptyTimestamp = new Date(
      estimatedEmpty.getFullYear(),
      estimatedEmpty.getMonth(),
      estimatedEmpty.getDate(),
      estimatedEmpty.getHours()
    ).getTime()

    if (estimatedEmptyTimestamp > hourFromNowTimestamp) {
      const lastWeight = weights[weights.length - 1]

      predictions.push({
        time: lastWeight.time,
        value: lastWeight.value,
      })

      const estimatedEmptyTimestampSeconds = estimatedEmptyTimestamp / 1000
      const predictionsTimeDiff = estimatedEmptyTimestampSeconds - (lastWeight.time as number)
      const predictionsTimeDiffHours = predictionsTimeDiff / 3600
      const predictionDecrease = lastWeight.value / predictionsTimeDiffHours

      for (let i = 1; i < predictionsTimeDiffHours; i++) {
        predictions.push({
          time: ((lastWeight.time as number) + 3600 * i) as Time,
          value: lastWeight.value - predictionDecrease * i,
        })
      }

      predictions.push({
        time: estimatedEmptyTimestampSeconds as Time,
        value: 0,
      })
    }

    return {
      weights,
      predictions,
      markers,
    }
  }

  const handleCrosshairMove = (params: any, chartContainer: any, tooltip: any, weightsSeries: ISeriesApi<'Area'>, predictionsSeries: ISeriesApi<'Line'>) => {
    const weightDataPoint = params.seriesData.get(weightsSeries)
    const predictionDataPoint = params.seriesData.get(predictionsSeries)

    if (
      (!weightDataPoint && !predictionDataPoint) ||
      params.point === undefined ||
      params.point.x < 0 ||
      params.point.x > chartContainer!.clientWidth ||
      params.point.y < 0 ||
      params.point.y > chartContainer!.clientHeight
    ) {
      tooltip.style.display = 'none'
      return
    }

    const tooltipWidth = 140
    const tooltipHeight = 80
    const tooltipMargin = 15
    const dataPointValue = weightDataPoint ? weightDataPoint.value : `~ ${Math.round(predictionDataPoint.value)}`
    const dataPointDate = new Date((weightDataPoint?.time || predictionDataPoint?.time) * 1000).toLocaleString(navigator.language, {
      weekday: 'short',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })

    let left = params.point.x + tooltipMargin
    if (left > chartContainer!.clientWidth - tooltipWidth) {
      left = params.point.x - tooltipMargin - tooltipWidth
    }

    let top = params.point.y + tooltipMargin
    if (top > chartContainer!.clientHeight - tooltipHeight) {
      top = params.point.y - tooltipHeight - tooltipMargin
    }

    tooltip.className = 'floating-tooltip'
    tooltip.style.top = top + 'px'
    tooltip.style.left = left + 'px'
    tooltip.style.display = 'block'
    tooltip.innerHTML = `<div style="color: rgba(225, 225, 225, 0.4)">${t({
      id: 'common.percent',
    })}:</div><div class="tilde" style="font-size: 18px; margin: 2px 0px; color: #fff">${dataPointValue} %</div><div style="color: rgba(225, 225, 225, 0.4)">${dataPointDate}</div>`
  }

  useEffect(() => {
    if (chartRef.current === null) {
      return
    }

    if (!props.calculation) {
      return
    }

    const chart = createChart(chartRef.current, chartConfig)

    // Order is important - weightSeries should be on top
    const predictionsSeries = chart.addLineSeries(predictionsSeriesConfig)
    const weightsSeries = chart.addAreaSeries(weightsSeriesConfig)

    const chartContainer = document.querySelector('.chart-container')
    const tooltip = document.createElement('div')

    const { weights, predictions, markers } = transformCalculationToChartData(props.calculation)

    if (props.calculation && weights.length === 0 && predictions.length === 0 && markers.length === 0) {
      props.setHasError(true)
    } else {
      props.setHasError(false)
    }

    chart.applyOptions({
      localization: {
        priceFormatter: (value: number) => (value > 100 ? '' : `${value.toFixed()} %`),
      },
    })

    weightsSeries.setData(weights)
    predictionsSeries.setData(predictions)
    weightsSeries.setMarkers(markers)
    chart.timeScale().fitContent()
    chart.subscribeCrosshairMove(params => handleCrosshairMove(params, chartContainer, tooltip, weightsSeries, predictionsSeries))

    chartContainer?.appendChild(tooltip)

    return () => {
      chart.remove()
      chartContainer?.removeChild(tooltip)
    }
  }, [chartRef, props.id, props.calculation])

  return (
    <PlasmicChartComponent root={{ ref }} style={{ width: '100%', height: '100%' }}>
      <ChartComponentContainer className='chart-container' ref={chartRef} />
    </PlasmicChartComponent>
  )
}

export default React.forwardRef(ChartComponent)
