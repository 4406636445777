// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type SensorFeaturesIconDarkQrsvgIconProps =
  React.ComponentProps<"svg"> & {
    title?: string;
  };

export function SensorFeaturesIconDarkQrsvgIcon(
  props: SensorFeaturesIconDarkQrsvgIconProps
) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 84.6 84.6"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <path
          d={
            "M66.5 70.5h-6.1c-2.2 0-4 1.8-4 4v6.1c0 2.2 1.8 4 4 4h6.1c2.2 0 4-1.8 4-4v-6.1c0-2.2-1.8-4-4-4zm14.1-14.1h-6.1c-2.2 0-4 1.8-4 4v6.1c0 2.2 1.8 4 4 4h6.1c2.2 0 4-1.8 4-4v-6.1c0-2.2-1.8-4-4-4zm0-28.2h-6.1c-2.2 0-4-1.8-4-4v-6.1c0-2.2-1.8-4-4-4h-6.1c-2.2 0-4 1.8-4 4v6.1c0 2.2-1.8 4-4 4h-6.1c-2.2 0-4-1.8-4-4v-6.1c0-2.2-1.8-4-4-4h-6.1c-2.2 0-4 1.8-4 4v20.2c0 2.2-1.8 4-4 4H4c-2.2 0-4 1.8-4 4v34.3c0 2.2 1.8 4 4 4h34.3c2.2 0 4-1.8 4-4v-6.1c0-2.2 1.8-4 4-4h6.1c2.2 0 4-1.8 4-4v-6.1c0-2.2-1.8-4-4-4h-6.1c-2.2 0-4-1.8-4-4v-6.1c0-2.2 1.8-4 4-4h6.1c2.2 0 4 1.8 4 4v6.1c0 2.2 1.8 4 4 4h6.1c2.2 0 4-1.8 4-4v-6.1c0-2.2 1.8-4 4-4h6.1c2.2 0 4-1.8 4-4v-6.1c0-2.2-1.8-4-4-4zM32.7 70.9c0 2.2-1.8 4-4 4h-15c-2.2 0-4-1.8-4-4v-15c0-2.2 1.8-4 4-4h15c2.2 0 4 1.8 4 4v15z"
          }
        ></path>

        <path
          d={
            "M22.8 57.8h-3.3c-2.2 0-4 1.8-4 4v3.3c0 2.2 1.8 4 4 4h3.3c2.2 0 4-1.8 4-4v-3.3c0-2.2-1.8-4-4-4zM80.6 0h-6.1c-2.2 0-4 1.8-4 4v6.1c0 2.2 1.8 4 4 4h6.1c2.2 0 4-1.8 4-4V4c0-2.2-1.8-4-4-4zM46.3 14.1h6.1c2.2 0 4-1.8 4-4V4c0-2.2-1.8-4-4-4h-6.1c-2.2 0-4 1.8-4 4v6.1c0 2.2 1.8 4 4 4zM4 28.2h6.1c2.2 0 4-1.8 4-4v-6.1c0-2.2 1.8-4 4-4h6.1c2.2 0 4-1.8 4-4V4c0-2.2-1.8-4-4-4H4C1.8 0 0 1.8 0 4v20.2c0 2.2 1.8 4 4 4z"
          }
        ></path>
      </g>
    </svg>
  );
}

export default SensorFeaturesIconDarkQrsvgIcon;
/* prettier-ignore-end */
