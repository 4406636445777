// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type OfflinesvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function OfflinesvgIcon(props: OfflinesvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 37.554 40"}
      xmlSpace={"preserve"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <path
          d={
            "M9.73 7.85a2.89 2.89 0 00-4.086-.048c-7.412 7.253-7.541 19.141-.288 26.553s19.141 7.541 26.553.288a18.775 18.775 0 005.644-13.421 18.62 18.62 0 00-5.646-13.42 2.889 2.889 0 00-4.038 4.133 12.88 12.88 0 013.906 9.287c0 7.18-5.82 13-13 13s-13-5.82-13-13a12.88 12.88 0 013.906-9.287A2.887 2.887 0 009.73 7.85z"
          }
        ></path>

        <path
          d={
            "M21.665 17.851V2.892a2.888 2.888 0 10-5.778-.006v14.966a2.888 2.888 0 005.778-.001z"
          }
        ></path>
      </g>
    </svg>
  );
}

export default OfflinesvgIcon;
/* prettier-ignore-end */
