import ReactModal from 'react-modal'
import React, { useEffect } from 'react'

import Lightbox from '../../../plasmic/plasmic/solace_components/PlasmicLightbox'
import { StyledLightboxActiveImage } from '../../../plasmic/Lightbox'
import { CylinderGroupImage } from './CustomerInfo'
import format from 'date-fns/format'
import { parseISO } from 'date-fns'
import { useIntl } from 'react-intl'
import { common } from 'modules/orders/AllOrdersTable/ViewImageModal'

interface ImagesLightboxProps {
  isOpen: boolean
  onClose: () => void
  images: CylinderGroupImage[]
  customerName: string
  activeImageIndex: number
}

export default function ImagesLightbox({ isOpen, onClose, images, customerName, activeImageIndex }: ImagesLightboxProps) {
  const [imageIndex, setImageIndex] = React.useState(activeImageIndex)

  useEffect(() => {
    setImageIndex(activeImageIndex)
  }, [activeImageIndex])

  const intl = useIntl()
  const t = intl.formatMessage

  const moveActiveImage = (forward: boolean) => {
    if (forward) {
      if (imageIndex < images.length - 1) {
        setImageIndex(imageIndex + 1)
      } else {
        setImageIndex(0)
      }
    } else if (imageIndex < 1) {
      setImageIndex(images.length - 1)
    } else {
      setImageIndex(imageIndex - 1)
    }
  }

  return (
    <ReactModal isOpen={isOpen} onRequestClose={() => onClose()} style={common}>
      <Lightbox
        activeImage={<StyledLightboxActiveImage url={images[imageIndex]?.image} />}
        customerName={customerName}
        uploadedBy={images[imageIndex]?.createdBy}
        date={images[imageIndex] ? format(parseISO(images[imageIndex]?.createdAt), 'EEEE HH:mm, dd. LLLL yyyy') : t({ id: 'common.unknown' })}
        btnNext={{
          props: {
            onClick: () => moveActiveImage(true),
          },
        }}
        btnPrevious={{
          props: {
            onClick: () => moveActiveImage(false),
          },
        }}
        btnClose={{
          props: {
            onClick: onClose,
          },
        }}
      />
    </ReactModal>
  )
}
