// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type PlussvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function PlussvgIcon(props: PlussvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 20 19.999"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M17.963 9H11V2.037a1 1 0 00-2 0V9H2.037a1 1 0 000 2H9v6.963a1 1 0 002 0V11h6.963a1 1 0 000-2z"
        }
      ></path>
    </svg>
  );
}

export default PlussvgIcon;
/* prettier-ignore-end */
