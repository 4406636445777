import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import styled, { useTheme } from 'styled-components'
import ConfirmModal from 'components/Modal/ConfirmModal'
import * as GQL from 'generated/graphql'
import { parseISO } from 'date-fns'
import Loader from 'components/Loader'
import { CenteredLoader } from 'components/Loader/LoaderWrappers'
import Tooltip from 'components/Tooltip'
import Text from 'components/Text'
import { getRouteIsNotReadyError } from '../util'
import { displayToast } from 'util/toasts'

interface RouteDispatchProps {
  route: GQL.RouteInfo
  routeIsReady: boolean
}

const StatusButton = styled.div<{ color: any }>`
  width: 100%;
  height: 100%;

  background-color: ${props => props.color};
  color: white;
  font-size: 0.9em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;

  :hover {
    filter: brightness(1.1);
  }
`

const RouteDispatch: React.FC<RouteDispatchProps> = ({ route, routeIsReady }) => {
  const intl = useIntl()
  const t = intl.formatMessage
  const theme = useTheme()
  const [confirmOpen, setConfirmOpen] = useState(false)

  const [dispatchRoute, { loading: dispatchLoading }] = GQL.useDispatchRoute({
    update: (cache, { data }) => {
      cache.writeFragment({
        id: cache.identify({ id: data?.dispatchRoute?.route?.id, __typename: 'RouteNode' }),
        fragment: GQL.RouteInfo,
        fragmentName: 'RouteInfo',
        data: data?.dispatchRoute?.route,
      })
    },
  })

  const [deleteRoute, { loading: deleteLoading }] = GQL.useDeleteRoute({
    update(cache, { data }) {
      cache.modify({
        fields: {
          allRoutes(existingRouteData) {
            const newEdges = existingRouteData.edges.filter((edge: GQL.RouteNodeEdge) => edge?.node?.id !== data?.deleteRoute?.deletedId) || []
            return { ...existingRouteData, edges: newEdges }
          },
          allCylinderGroupOrders(existingRefs, { DELETE }) {
            return DELETE
          },
          allUnassignedRoutes(existingRefs, { DELETE }) {
            return DELETE
          },
        },
      })
    },
    onError: () => {
      displayToast('There was an error deleting the route!')
      setConfirmOpen(false)
    },
  })

  if (dispatchLoading || deleteLoading) {
    return (
      <CenteredLoader sizeAuto>
        <Loader color='white' size={32} />
      </CenteredLoader>
    )
  }
  if (parseISO(route.date) < new Date(new Date().valueOf() - 1000 * 60 * 60 * 24)) {
    return (
      <>
        <ConfirmModal
          isOpen={confirmOpen}
          onRequestClose={() => setConfirmOpen(false)}
          onConfirm={() => {
            setConfirmOpen(false)
            deleteRoute({
              variables: {
                id: route.id,
              },
            })
          }}
          onAbort={() => {
            setConfirmOpen(false)
          }}
          title='Delete route?'
          description='Are you sure that you want to delete this route?'
        />
        <StatusButton color={theme.colors.red} onClick={() => setConfirmOpen(true)}>
          Delete
        </StatusButton>
      </>
    )
  } else if (!routeIsReady) {
    return (
      <Tooltip content={t({ id: getRouteIsNotReadyError(route) })}>
        <Text color={theme.colors.red}>{t({ id: 'routes.dispatch.not-ready' })}</Text>
      </Tooltip>
    )
  } else if (route.status === GQL.RouteStatus.Dispatched || route.status === GQL.RouteStatus.Started) {
    return (
      <StatusButton style={{ cursor: 'auto' }} color={'rgb(0,212,166)'}>
        {t({ id: 'common.sent' })}
      </StatusButton>
    )
  } else if (route.status === GQL.RouteStatus.DispatchedButUpdated) {
    return (
      <StatusButton
        color={theme.colors.orange}
        onClick={() => {
          dispatchRoute({ variables: { route: route.id } })
        }}
      >
        {t({ id: 'dispatch.changes-made-resend' })}
      </StatusButton>
    )
  } else {
    return (
      <>
        <ConfirmModal
          isOpen={confirmOpen}
          onRequestClose={() => setConfirmOpen(false)}
          onConfirm={() => {
            setConfirmOpen(false)
            dispatchRoute({
              variables: {
                route: route.id,
              },
            })
          }}
          onAbort={() => {
            setConfirmOpen(false)
          }}
          title={t({ id: 'dispatch.confirm-modal.title' })}
          description={t({ id: 'dispatch.confirm-modal.text' })}
        />

        <StatusButton color={theme.colors.blue} onClick={() => setConfirmOpen(true)}>
          {t({ id: 'dispatch.send-to-driver' })}
        </StatusButton>
      </>
    )
  }
}

export default RouteDispatch
