// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type IconPricingListsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function IconPricingListsvgIcon(props: IconPricingListsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 22 22"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M13 3a9.7 9.7 0 013.11.5A8.99 8.99 0 003.5 1.872a8.99 8.99 0 000 14.238A9.695 9.695 0 013 13c.009-5.519 4.481-9.991 10-10z"
        }
      ></path>

      <path
        d={
          "M13 4a9 9 0 109 9 9.01 9.01 0 00-9-9zm0 8a2.992 2.992 0 011 5.816V18a1 1 0 01-2 0v-.184A2.996 2.996 0 0110 15a1 1 0 012 0 1 1 0 101-1 2.992 2.992 0 01-1-5.815V8a1 1 0 012 0v.184A2.996 2.996 0 0116 11a1 1 0 01-2 0 1 1 0 10-1 1z"
        }
      ></path>
    </svg>
  );
}

export default IconPricingListsvgIcon;
/* prettier-ignore-end */
