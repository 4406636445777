// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type DepotFillsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function DepotFillsvgIcon(props: DepotFillsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 38.319 33.461"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M.933 33.461A.935.935 0 010 32.528V5.794c0-.421.284-.793.692-.901L18.92.032a.944.944 0 01.482 0l18.225 4.861c.408.109.692.48.692.901v26.734a.935.935 0 01-.933.933H.933zm8.225-1.866h20.006v-7.856H9.158v7.856zm0-9.723h20.006V18.88H9.158v2.992zm0-4.861h20.006v-2.992H9.158v2.992z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default DepotFillsvgIcon;
/* prettier-ignore-end */
