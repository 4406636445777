const SORT_NONE = 0
const SORT_ASCENDING = 1
const SORT_DESCENDING = -1

export function createSortStringFromMapping(mapping) {
  const entries = Object.entries(mapping)
  if (entries.length === 0) {
    return undefined
  }
  return Object.entries(mapping).reduce((acc, [key, value], i) => {
    switch (value) {
      default:
      case SORT_NONE:
        return acc
      case SORT_ASCENDING:
        return acc === '' ? key : acc + ',' + key
      case SORT_DESCENDING:
        return acc === '' ? `-${key}` : acc + `,-${key}`
    }
  }, '')
}
