import React, { useRef } from 'react'
import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'

import MobileLimited from 'plasmic/MobileLimited'
import ContentScrollProvider from './ContentScrollProvider'

import 'react-toastify/dist/ReactToastify.css'

const StyledToastContainer = styled(ToastContainer).attrs({
  autoClose: 4000,
  position: 'top-center',
  icon: false,
  closeButton: false,
  style: {
    width: 'auto',
  },
  hideProgressBar: true,
  closeOnClick: false,
})`
  .Toastify__toast-container {
  }
  .Toastify--animate {
    animation-duration: 0.5s;
  }
  .Toastify__toast {
    cursor: inherit;
    font-family: 'Circular';
    background: rgba(255, 255, 255, 0);
    box-shadow: none;
    padding: 0;
    margin-bottom: 0.75em;
    min-height: 10px;
  }
  .Toastify__toast-body {
    padding: 0;
    margin: 0;
  }
`

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.primaryDark};
  height: 100dvh;
`

const MobileLayout: React.FC = () => {
  const contentRef = useRef(null)

  return (
    <Wrapper id='content-wrapper' ref={contentRef}>
      <span id='content-wrapper-top' />
      <ContentScrollProvider contentRef={contentRef}>
        <MobileLimited />
      </ContentScrollProvider>
      <StyledToastContainer />
    </Wrapper>
  )
}

export default MobileLayout
