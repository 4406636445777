import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { omit } from 'lodash'
import PlasmicMessageEditor, { DefaultMessageEditorProps } from 'plasmic/plasmic/solace_components/PlasmicMessageEditor'
import ButtonDynamicField from './ButtonDynamicField'
import * as GQL from 'generated/graphql'
import { displayToast } from 'util/toasts'
import { createDynamicField } from '../util'
import { animated, useSpring } from 'react-spring'
import { IDrawerState } from '../pages/SettingsCustomerNotifications'

const Wrapper = styled(animated.div)`
  #messageContainer,
  #messageContainer * {
    white-space: pre-wrap;
  }
  .dynamic-field {
    white-space: nowrap !important;
    user-select: none;
    background: ${props => props.theme.colors.blue};
    padding: 0px 10px;
    border-radius: 10px;
  }
`

interface Props extends DefaultMessageEditorProps {
  setDrawerState?: (open: null) => void
  drawerState: IDrawerState | null
}

const MessageEditor: React.FC<Props> = props => {
  const [rangeObject, setRangeObject] = useState<Range>()
  const intl = useIntl()
  const t = intl.formatMessage

  const [open, setOpen] = useState(false)

  const spring = useSpring({
    marginRight: open ? 0 : window.innerWidth > 650 ? -550 : -window.innerWidth,
    onRest: () => {
      if (!open && props.setDrawerState) props.setDrawerState(null)
    },
  })

  const { data: dynamicFieldData, loading: loadingDynamicFields } = GQL.useAllDynamicFields()
  const dynamicFields = dynamicFieldData?.allDynamicFields as GQL.CustomNotificationDynamicFieldNode[]

  const [createCustomNotification] = GQL.useCreateCustomNotification({
    refetchQueries: ['AllCustomNotifications'],
    onCompleted: () => {
      displayToast(t({ id: 'settings.customer-notifications.create.success' }), 'success')
      setOpen(false)
    },
    onError: () => {
      displayToast(t({ id: 'settings.customer-notifications.create.error' }), 'error')
    },
  })

  const [patchCustomNotification] = GQL.usePatchCustomNotification({
    refetchQueries: ['AllCustomNotifications'],
    onCompleted: () => {
      displayToast(t({ id: 'settings.customer-notifications.edit.success' }), 'success')
      setOpen(false)
    },
    onError: () => {
      displayToast(t({ id: 'settings.customer-notifications.edit.error' }), 'error')
    },
  })

  const getDynamicField = (key: string) => {
    return dynamicFields.find(dynamicField => dynamicField.key === key)
  }

  /**
   * Replace dynamic value tokens in the message with corresponding DynamicValue elements.
   * @param message the initial message value obtained from the server.
   * @returns the converted message.
   */
  const addDynamicFields = (message?: string) => {
    if (message) {
      const expression = /{(.*?)}/
      const matches = message.match(new RegExp(expression, 'gm'))
      matches?.forEach(match => {
        const key = match.substring(1, match.length - 1)
        const dynamicField = getDynamicField(key)
        if (!dynamicField) return
        const element = createDynamicField(dynamicField.name, key)
        message = message?.replace(expression, element.outerHTML)
      })
    }
    return message
  }

  /**
   * Parse the message to a submittable format.
   * @returns parsed message
   */
  const parseMessage = () => {
    // The text editor is a content-editable div so the value needs to be retrieved this way
    const editor = document.getElementById('messageContainer')!

    const dynamicFields = editor.getElementsByClassName('dynamic-field')
    for (const dynamicField of dynamicFields) {
      const value = `{${dynamicField.getAttribute('data-dynamic-field')}}`
      dynamicField.innerHTML = value
    }
    const text = editor.innerText
    // Remove zero-width-space characters
    return text.replace(/\u200B/g, '')
  }

  const onSubmit = () => {
    if (!props.drawerState) return
    const message = parseMessage()
    if (props.drawerState.customNotification) {
      patchCustomNotification({
        variables: {
          id: props.drawerState.customNotification.id,
          input: {
            message: message,
          },
        },
      })
    } else {
      createCustomNotification({
        variables: {
          input: {
            message: message,
            resource: props.drawerState.resource.id,
          },
        },
      })
    }
  }

  const onDiscard = () => {
    setOpen(false)
  }

  const onSelect = () => {
    const selectionObject = window.getSelection()
    if (selectionObject !== null && selectionObject.rangeCount > 0) {
      const range = selectionObject.getRangeAt(0)
      setRangeObject(range)
    }
  }

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      // A zero-width-space character is added as a workaround
      // to browsers ignoring trailing linebreaks.
      const lineBreak = document.createTextNode('\n')
      const zeroWidthSpace = document.createTextNode('\u200B')
      rangeObject?.deleteContents()
      rangeObject?.insertNode(lineBreak)
      rangeObject?.collapse(false)
      rangeObject?.insertNode(zeroWidthSpace)
      rangeObject?.setEndAfter(lineBreak)
      rangeObject?.setStartAfter(lineBreak)
    }
  }

  const onFieldInsert = (element: HTMLSpanElement) => {
    if (!rangeObject) return
    rangeObject.insertNode(element)
  }

  useEffect(() => {
    if (!!props.drawerState?.resource) setOpen(true)
  }, [props.drawerState?.resource])

  return (
    <Wrapper style={spring}>
      {!loadingDynamicFields && !!props.drawerState && (
        <PlasmicMessageEditor
          messageContent={addDynamicFields(props.drawerState?.customNotification?.message) || 'Enter message...'}
          messageDiv={{
            props: {
              onSelect: onSelect,
              onKeyPress: onKeyPress,
              onPaste: event => event.preventDefault(),
              onDragOver: event => event.preventDefault(),
              suppressContentEditableWarning: true,
            },
          }}
          buttonDynamicField={{
            render: () => <ButtonDynamicField onFieldInsert={onFieldInsert} />,
          }}
          btnSave={{
            props: {
              onClick: onSubmit,
            },
          }}
          btnDiscard={{
            props: {
              onClick: onDiscard,
            },
          }}
          {...omit(props, 'setDrawerState', 'drawerState')}
        />
      )}
    </Wrapper>
  )
}

export default MessageEditor
