import OpeningHoursComponent from 'plasmic/OpeningHoursComponent'
import * as GQL from 'generated/graphql'
import { CenteredLoader } from 'components/Loader/LoaderWrappers'
import Loader from 'components/Loader'
import styled from 'styled-components'
import { getDefaultOpeningHours } from '../util'

const Wrapper = styled.div`
  position: relative;

  input[type='time']::-webkit-calendar-picker-indicator {
    filter: invert(1);
    opacity: 0.7;
  }
`

export interface ICustomerStandardOpeningHours {
  id?: string
  weekday?: string
  closesAt: string
  opensAt: string
  active?: boolean
}

interface OpeningHoursProps {
  customer: GQL.CustomerNode | undefined
  onChange: (value: boolean) => void
  alwaysOpen: boolean
  setAlwaysOpen: (value: boolean) => void
  openingHours: ICustomerStandardOpeningHours[]
  setOpeningHours: (value: ICustomerStandardOpeningHours[]) => void
}

function OpeningHours({ customer, onChange, openingHours, setOpeningHours, alwaysOpen, setAlwaysOpen }: OpeningHoursProps) {
  if (!customer)
    return (
      <CenteredLoader>
        <Loader color={'white'} />
      </CenteredLoader>
    )

  const changeOpeningHours = ({ day, opensAt, closesAt, active }: { day: string; opensAt?: string; closesAt?: string; active?: boolean }) => {
    onChange(true)
    if (openingHours.find(e => e.weekday === day)) {
      const newOpeningHours = openingHours.map(e => {
        if (e.weekday === day) {
          return { ...e, opensAt: opensAt || e.opensAt, closesAt: closesAt || e.closesAt, active: active === undefined ? e.active : active }
        }
        return e
      })
      setOpeningHours(newOpeningHours)
    } else {
      const defaultHours = getDefaultOpeningHours(openingHours)
      setOpeningHours([
        ...openingHours,
        {
          weekday: day,
          opensAt: opensAt || defaultHours.opensAt,
          closesAt: closesAt || defaultHours.closesAt,
          active: active === undefined ? true : active,
        },
      ])
    }
  }

  return (
    <Wrapper>
      <OpeningHoursComponent
        alwaysAvailable={alwaysOpen}
        always={{
          on: false,
          switchBtn: {
            isChecked: alwaysOpen,
            onChange: (e: any) => {
              onChange(true)
              if (e) {
                setAlwaysOpen(true)
              } else {
                setAlwaysOpen(false)
              }
            },
          },
        }}
        mon={{
          on: openingHours.some(e => e.weekday === 'MONDAY' && e.active),
          switchBtn: {
            isChecked: openingHours.some(e => e.weekday === 'MONDAY' && e.active),
            onChange: (e: any) => {
              if (e) {
                changeOpeningHours({ day: 'MONDAY', active: true })
              } else {
                changeOpeningHours({ day: 'MONDAY', active: false })
              }
            },
          },
          inputToTime: {
            undefinedInput: {
              value: openingHours.find(e => e.weekday === 'MONDAY')?.closesAt,
              onChange: (e: any) => changeOpeningHours({ day: 'MONDAY', closesAt: e.target.value }),
            },
          },
          inputFromTime: {
            undefinedInput: {
              value: openingHours.find(e => e.weekday === 'MONDAY')?.opensAt,
              onChange: (e: any) => changeOpeningHours({ day: 'MONDAY', opensAt: e.target.value }),
            },
          },
        }}
        tue={{
          on: openingHours.some(e => e.weekday === 'TUESDAY' && e.active),
          switchBtn: {
            isChecked: openingHours.some(e => e.weekday === 'TUESDAY' && e.active),
            onChange: (e: any) => {
              if (e) {
                changeOpeningHours({ day: 'TUESDAY', active: true })
              } else {
                changeOpeningHours({ day: 'TUESDAY', active: false })
              }
            },
          },
          inputToTime: {
            undefinedInput: {
              value: openingHours.find(e => e.weekday === 'TUESDAY')?.closesAt,
              onChange: (e: any) => changeOpeningHours({ day: 'TUESDAY', closesAt: e.target.value }),
            },
          },
          inputFromTime: {
            undefinedInput: {
              value: openingHours.find(e => e.weekday === 'TUESDAY')?.opensAt,
              onChange: (e: any) => changeOpeningHours({ day: 'TUESDAY', opensAt: e.target.value }),
            },
          },
        }}
        wed={{
          on: openingHours.some(e => e.weekday === 'WEDNESDAY' && e.active),
          switchBtn: {
            isChecked: openingHours.some(e => e.weekday === 'WEDNESDAY' && e.active),
            onChange: (e: any) => {
              if (e) {
                changeOpeningHours({ day: 'WEDNESDAY', active: true })
              } else {
                changeOpeningHours({ day: 'WEDNESDAY', active: false })
              }
            },
          },
          inputToTime: {
            undefinedInput: {
              value: openingHours.find(e => e.weekday === 'WEDNESDAY')?.closesAt,
              onChange: (e: any) => changeOpeningHours({ day: 'WEDNESDAY', closesAt: e.target.value }),
            },
          },
          inputFromTime: {
            undefinedInput: {
              value: openingHours.find(e => e.weekday === 'WEDNESDAY')?.opensAt,
              onChange: (e: any) => changeOpeningHours({ day: 'WEDNESDAY', opensAt: e.target.value }),
            },
          },
        }}
        thu={{
          on: openingHours.some(e => e.weekday === 'THURSDAY' && e.active),
          switchBtn: {
            isChecked: openingHours.some(e => e.weekday === 'THURSDAY' && e.active),
            onChange: (e: any) => {
              if (e) {
                changeOpeningHours({ day: 'THURSDAY', active: true })
              } else {
                changeOpeningHours({ day: 'THURSDAY', active: false })
              }
            },
          },
          inputToTime: {
            undefinedInput: {
              value: openingHours.find(e => e.weekday === 'THURSDAY')?.closesAt,
              onChange: (e: any) => changeOpeningHours({ day: 'THURSDAY', closesAt: e.target.value }),
            },
          },
          inputFromTime: {
            undefinedInput: {
              value: openingHours.find(e => e.weekday === 'THURSDAY')?.opensAt,
              onChange: (e: any) => changeOpeningHours({ day: 'THURSDAY', opensAt: e.target.value }),
            },
          },
        }}
        fri={{
          on: openingHours.some(e => e.weekday === 'FRIDAY' && e.active),
          switchBtn: {
            isChecked: openingHours.some(e => e.weekday === 'FRIDAY' && e.active),
            onChange: (e: any) => {
              if (e) {
                changeOpeningHours({ day: 'FRIDAY', active: true })
              } else {
                changeOpeningHours({ day: 'FRIDAY', active: false })
              }
            },
          },
          inputToTime: {
            undefinedInput: {
              value: openingHours.find(e => e.weekday === 'FRIDAY')?.closesAt,
              onChange: (e: any) => changeOpeningHours({ day: 'FRIDAY', closesAt: e.target.value }),
            },
          },
          inputFromTime: {
            undefinedInput: {
              value: openingHours.find(e => e.weekday === 'FRIDAY')?.opensAt,
              onChange: (e: any) => changeOpeningHours({ day: 'FRIDAY', opensAt: e.target.value }),
            },
          },
        }}
        sat={{
          on: openingHours.some(e => e.weekday === 'SATURDAY' && e.active),
          switchBtn: {
            isChecked: openingHours.some(e => e.weekday === 'SATURDAY' && e.active),
            onChange: (e: any) => {
              if (e) {
                changeOpeningHours({ day: 'SATURDAY', active: true })
              } else {
                changeOpeningHours({ day: 'SATURDAY', active: false })
              }
            },
          },
          inputToTime: {
            undefinedInput: {
              value: openingHours.find(e => e.weekday === 'SATURDAY')?.closesAt,
              onChange: (e: any) => changeOpeningHours({ day: 'SATURDAY', closesAt: e.target.value }),
            },
          },
          inputFromTime: {
            undefinedInput: {
              value: openingHours.find(e => e.weekday === 'SATURDAY')?.opensAt,
              onChange: (e: any) => changeOpeningHours({ day: 'SATURDAY', opensAt: e.target.value }),
            },
          },
        }}
        sun={{
          on: openingHours.some(e => e.weekday === 'SUNDAY' && e.active),
          switchBtn: {
            isChecked: openingHours.some(e => e.weekday === 'SUNDAY' && e.active),
            onChange: (e: any) => {
              if (e) {
                changeOpeningHours({ day: 'SUNDAY', active: true })
              } else {
                changeOpeningHours({ day: 'SUNDAY', active: false })
              }
            },
          },
          inputToTime: {
            undefinedInput: {
              value: openingHours.find(e => e.weekday === 'SUNDAY')?.closesAt,
              onChange: (e: any) => changeOpeningHours({ day: 'SUNDAY', closesAt: e.target.value }),
            },
          },
          inputFromTime: {
            undefinedInput: {
              value: openingHours.find(e => e.weekday === 'SUNDAY')?.opensAt,
              onChange: (e: any) => changeOpeningHours({ day: 'SUNDAY', opensAt: e.target.value }),
            },
          },
        }}
      />
    </Wrapper>
  )
}

export default OpeningHours
