// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type DropdownChevronsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function DropdownChevronsvgIcon(props: DropdownChevronsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 24 24"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M18.936 10.15l-.446-.446a.27.27 0 00-.383 0l-4.077 4.068-4.075-4.068a.27.27 0 00-.383 0l-.446.446a.27.27 0 000 .383l4.714 4.714a.27.27 0 00.383 0l4.714-4.714a.271.271 0 00-.001-.383z"
        }
      ></path>

      <path opacity={".2"} d={"M0 0h1v24H0z"}></path>
    </svg>
  );
}

export default DropdownChevronsvgIcon;
/* prettier-ignore-end */
