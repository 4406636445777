// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ToastSuccesssvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ToastSuccesssvgIcon(props: ToastSuccesssvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 32.727 32.727"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M16.697 0C7.66 0 .333 7.326.333 16.364c0 9.037 7.326 16.364 16.364 16.364 9.037 0 16.364-7.326 16.364-16.364S25.734 0 16.697 0zm8.598 12.229L14.961 22.563a.547.547 0 01-.774 0l-6.088-6.088a.547.547 0 010-.774L9.39 14.41a.547.547 0 01.774 0l4.41 4.411 8.656-8.656a.547.547 0 01.774 0l1.292 1.29a.548.548 0 01-.001.774z"
        }
      ></path>
    </svg>
  );
}

export default ToastSuccesssvgIcon;
/* prettier-ignore-end */
