import * as React from 'react'
import ManageTagsRow from 'plasmic/ManageTagsRow'
import ActionDropdownLine from 'plasmic/ActionDropdownLine'
import PlasmicManageTags from 'plasmic/plasmic/solace_components/PlasmicManageTags'
import TagModal, { DistributorTagFormData } from '../components/TagModal'
import * as GQL from 'generated/graphql'
import SettingsContentWrapper from 'plasmic/SettingsContentWrapper'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useRef, useState } from 'react'
import MoreActions from 'plasmic/MoreActions'
import ActionDropdown from 'plasmic/ActionDropdown'
import { displayToast } from 'util/toasts'
import { useAppContext } from 'util/hooks'
import DeleteDistributorModal from '../components/DeleteDistributorTagModal'
import ButtonFill from 'plasmic/ButtonFill'

interface DistributorTagTempProps {
  create?: boolean
  name?: string
  id?: string
}

function ManageTags_(props: any, ref: any) {
  const intl = useIntl()
  const t = intl.formatMessage
  const navigate = useNavigate()

  const { appContext } = useAppContext()
  const distributor = appContext.distributor
  const [tagModalOpen, setTagModalOpen] = useState(false)
  const [tagDeleteModalOpen, setTagDeleteModalOpen] = useState(false)
  const [moreActionsId, setTagMoreActionsId] = useState<string>('')
  const [distributorTagTempInfo, setDistributorTagTempInfo] = useState<DistributorTagTempProps>({ create: true })
  const tagMoreActionRef = useRef<any>()
  const { data } = GQL.useAllDistributorTags()

  const distributorTags = data?.allDistributorTags?.edges.map(tag => tag?.node as GQL.DistributorTagNode) || []

  const handleClickViewFilteredCustomers = (tagName: string) => {
    navigate('/customers/overview', {
      state: { initialSelectedTag: tagName },
    })
  }

  const [createDistributorTag, { loading: createDistributorTagLoading }] = GQL.useCreateDistributorTag({
    refetchQueries: ['AllDistributorTags'],
    onCompleted: () => {
      displayToast(t({ id: 'settings.tags.save-new.success' }), 'success')
      setTagModalOpen(false)
    },
    onError: () => {
      displayToast(t({ id: 'settings.tags.save-new.error' }), 'error')
    },
  })

  const [patchDistributorTag, { loading: patchDistributorTagLoading }] = GQL.usePatchDistributorTag({
    refetchQueries: ['AllDistributorTags'],
    onCompleted: () => {
      displayToast(t({ id: 'settings.tags.save-edit.success' }), 'success')
      setTagModalOpen(false)
    },
    onError: () => {
      displayToast(t({ id: 'settings.tags.save-edit.error' }), 'error')
    },
  })

  const deleteDistributorTag = GQL.useDeleteDistributorTag({
    refetchQueries: ['AllDistributorTags'],
    onCompleted: () => {
      displayToast(t({ id: 'settings.tags.delete.success' }), 'success')
      setTagDeleteModalOpen(false)
    },
    onError: () => {
      displayToast(t({ id: 'settings.tags.delete.error' }), 'error')
    },
  })

  function handleSubmit(data: DistributorTagFormData) {
    if (data.id) {
      patchDistributorTag({
        variables: {
          id: data?.id!,
          input: {
            name: data.name,
          },
        },
      })
    } else {
      createDistributorTag({
        variables: {
          input: {
            name: data.name,
            distributor: distributor?.id!,
          },
        },
      })
    }
  }

  function handleDeleteSubmit(id: string) {
    deleteDistributorTag[0]({
      variables: {
        id: id!,
      },
    })
  }

  return (
    <SettingsContentWrapper
      settingsTitle={t({ id: 'common.tags' })}
      headerButtons={
        <ButtonFill
          rounded
          color={'blue'}
          label={t({ id: 'settings.tags.create' })}
          onClick={() => {
            setTagModalOpen(true)
            setDistributorTagTempInfo({
              create: true,
            })
          }}
        />
      }
      content={
        <>
          <PlasmicManageTags
            id='manage-tags-table'
            data-testid='manage-tags-table'
            root={{ ref }}
            {...props}
            tagRows={distributorTags.map(tag => (
              <>
                <ManageTagsRow
                  data-testid='tag-row'
                  tag={{
                    label: tag.name,
                  }}
                  relations={t({ id: 'settings.tags.number-of-customers' }, { number: tag.customerCount })}
                  btnViewFilter={{
                    label: t({ id: 'settings.tags.view-filter' }),
                    onClick: () => handleClickViewFilteredCustomers(tag.name),
                  }}
                  btnMoreActions={
                    <MoreActions
                      ref={moreActionsId === tag.id ? tagMoreActionRef : null}
                      open={moreActionsId === tag.id ? true : false}
                      btnMoreActions={{
                        onClick: () => (moreActionsId === tag.id ? setTagMoreActionsId('') : setTagMoreActionsId(tag.id)),
                      }}
                      actionDropdown={
                        <ActionDropdown
                          children={
                            <>
                              <ActionDropdownLine
                                data-testid='tag-action-dropdown-rename'
                                onClick={() => {
                                  setTagMoreActionsId('')
                                  setTagModalOpen(true)
                                  setDistributorTagTempInfo({
                                    create: false,
                                    id: tag.id,
                                    name: tag.name,
                                  })
                                }}
                              >
                                {t({ id: 'settings.tags.patch' })}
                              </ActionDropdownLine>
                              <ActionDropdownLine
                                onClick={() => {
                                  setTagMoreActionsId('')
                                  setTagDeleteModalOpen(true)
                                  setDistributorTagTempInfo({
                                    create: false,
                                    id: tag.id,
                                    name: tag.name,
                                  })
                                }}
                              >
                                {t({ id: 'settings.tags.delete' })}
                              </ActionDropdownLine>
                            </>
                          }
                        />
                      }
                    />
                  }
                />
              </>
            ))}
          />
          {tagModalOpen && (
            <TagModal
              data={distributorTagTempInfo}
              isOpen={tagModalOpen}
              onSubmit={handleSubmit}
              onRequestClose={() => {
                setTagModalOpen(false)
              }}
              loading={createDistributorTagLoading || patchDistributorTagLoading}
            />
          )}
          {tagDeleteModalOpen && (
            <DeleteDistributorModal
              data={distributorTagTempInfo}
              isOpen={tagDeleteModalOpen}
              onSubmit={handleDeleteSubmit}
              onRequestClose={() => {
                setTagDeleteModalOpen(false)
              }}
            />
          )}
        </>
      }
    />
  )
}

const ManageTags = React.forwardRef(ManageTags_)
export default ManageTags
