// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ArrowReturnsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ArrowReturnsvgIcon(props: ArrowReturnsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 103.501 94.291"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M100.501 18.625H49.503V3.004c0-2.31-2.5-3.753-4.5-2.598L1.5 25.527c-2 1.155-2 4.041 0 5.196l43.503 25.12c2 1.155 4.5-.289 4.5-2.598v-15.62h34.998v37.667H27.668a3 3 0 00-3 3v13a3 3 0 003 3h72.833a3 3 0 003-3V21.625a3 3 0 00-3-3z"
        }
      ></path>
    </svg>
  );
}

export default ArrowReturnsvgIcon;
/* prettier-ignore-end */
