import React, { useContext, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import * as GQL from 'generated/graphql'
import HeaderContext from 'context/HeaderContext'
import SubnavButton from 'plasmic/SubnavButton'
import TutorialTooltip from 'components/Tooltip/TutorialTooltip'
import CustomersOverview from './CustomersOverview'
import ContactSignups from './ContactSignups'

const OVERVIEW_PATH = '/customers/overview'
const SIGNUPS_PATH = '/customers/signups'

export enum Status {
  EmptyCylinders,
  OrderedCylinders,
  AwaitingDelivery,
}

const Wrapper = styled.div`
  height: 100%;
`

const Customers: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const intl = useIntl()
  const t = intl.formatMessage

  const headerContext = useContext(HeaderContext)

  const { data: contactSignupsCountData } = GQL.useAllCustomerContactSignupsCount()
  const contactSignupsCount = useMemo(() => {
    return contactSignupsCountData?.allCustomerContactSignups?.totalCount || 0
  }, [contactSignupsCountData])

  useEffect(() => {
    headerContext.setIsSubHeaderVisible(true)
    headerContext.setLeftSubHeaderGroup(
      <>
        <TutorialTooltip content={t({ id: 'tooltips.header.customers' })}>
          <SubnavButton active={location.pathname === OVERVIEW_PATH} title={t({ id: 'common.customers' })} onClick={() => navigate(OVERVIEW_PATH)} />
        </TutorialTooltip>
        <TutorialTooltip content={t({ id: 'tooltips.header.customer.signups' })}>
          <SubnavButton
            active={location.pathname === SIGNUPS_PATH}
            title={t({ id: 'common.customer.signups' })}
            numberBadge={contactSignupsCount > 0}
            number={{
              number: contactSignupsCount,
            }}
            onClick={() => navigate(SIGNUPS_PATH)}
          />
        </TutorialTooltip>
      </>
    )
    headerContext.setRightSubHeaderGroup(<></>)

    return () => {
      headerContext.setIsSubHeaderVisible(false)
      headerContext.setLeftSubHeaderGroup(<></>)
      headerContext.setRightSubHeaderGroup(<></>)
    }
    // eslint-disable-next-line
  }, [t, location, navigate, contactSignupsCount])

  return (
    <>
      <Wrapper>
        <Routes>
          <Route path='/overview' element={<CustomersOverview />} />
          <Route path='/signups' element={<ContactSignups />} />
          <Route path='/' element={<Navigate to='/customers/overview' />} />
        </Routes>
      </Wrapper>
    </>
  )
}

export default Customers
