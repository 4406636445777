// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type LocationPinsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function LocationPinsvgIcon(props: LocationPinsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 21 21"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M20.346 2.781l-8.309 17.063c-.275.56-.937.815-1.507.55a1.155 1.155 0 01-.632-.8L8.78 13.24a1.252 1.252 0 00-1.005-1.017l-6.334-1.117a1.133 1.133 0 01-.827-.62 1.144 1.144 0 01.523-1.517L18.207.655a1.61 1.61 0 012.132.75c.22.452.207.946.007 1.376z"
        }
      ></path>
    </svg>
  );
}

export default LocationPinsvgIcon;
/* prettier-ignore-end */
