import styled from 'styled-components'
import { FontWeight, TextAlign } from 'types/props'
import { overloadColorProp } from 'util/style'

interface TextProps {
  block?: boolean
  color?: string
  cursor?: string

  fontSize?: string
  fontWeight?: FontWeight

  textAlign?: TextAlign
  textTransform?: string
}

const Text = styled.span<TextProps>`
  display: ${props => (props.block ? 'block' : 'inline')};
  color: ${overloadColorProp};

  font-size: ${props => props.fontSize};
  text-align: ${props => props.textAlign};
  font-weight: ${props => props.fontWeight};
  cursor: ${props => props.cursor};

  text-transform: ${props => props.textTransform};
`

Text.defaultProps = {
  textAlign: 'left',
  fontWeight: 'normal',
  cursor: 'inherit',
}

export default Text
