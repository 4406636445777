import { useState } from 'react'
import { format, parseISO } from 'date-fns'
import { Link } from 'react-router-dom'

import * as GQL from 'generated/graphql'
import { formatPrice, ProductLabel } from '../util'
import { useAppContext, useModalWithData, useTableContext } from 'util/hooks'
import { AllOrdersTableColumn, generateColumnFilterClass } from 'context/TableContext'
import FlexTable from 'plasmic/FlexTable'
import Loader from 'components/Loader'
import TableRow from 'plasmic/TableRow'
import CellCustomer from 'plasmic/CellCustomer'
import CellAddress from 'plasmic/CellAddress'
import TableHeadTitle from 'plasmic/TableHeadTitle'
import CellProduct from 'plasmic/CellProduct'
import CellComment from 'plasmic/CellComment'
import CellOrderedBy from 'plasmic/CellOrderedBy'
import CellCustomerID from 'plasmic/CellCustomerId'
import CellViewImages from 'plasmic/CellViewImages'
import CellDateDelivered from 'plasmic/CellDateDelivered'
import CellDateOrdered from 'plasmic/CellDateOrdered'
import CellDownloadOrder from 'plasmic/CellDownloadOrder'
import CellOrderId from 'plasmic/CellOrderId'
import ViewImageModal from './ViewImageModal'
import ScrollIndicator from '../../../plasmic/ScrollIndicator'
import CellPrice from 'plasmic/CellPrice'
import CellStatus from 'plasmic/CellStatus'
import ViewOrderModal from './ViewOrderModal'

interface AllOrdersTableProps {
  cylinderGroupOrders: GQL.CylinderGroupOrderNode[]
  isLoading: boolean
  allCount: number
  count: number
  loadMore: () => void
}

export default function AllOrdersTable({ cylinderGroupOrders, isLoading, count, allCount, loadMore }: AllOrdersTableProps) {
  const [expanded, setExpanded] = useState(true)
  const [orderOpened, setOrderOpened] = useState<GQL.CylinderGroupOrderNode>()

  const { appContext } = useAppContext()
  const { visibleOrdersAllTableColumns } = useTableContext()

  const [showViewImageModal, hideViewImageModal] = useModalWithData(order => () => <ViewImageModal isOpen onRequestClose={hideViewImageModal} order={order} />)

  return (
    <FlexTable
      noCheckbox
      noSorting
      collapsed={!expanded}
      headCustomerIdParent={{ className: generateColumnFilterClass(AllOrdersTableColumn.CUSTOMER_ID, visibleOrdersAllTableColumns) }}
      headProductParent={{ className: generateColumnFilterClass(AllOrdersTableColumn.PRODUCT, visibleOrdersAllTableColumns) }}
      headAddressParent={{ className: generateColumnFilterClass(AllOrdersTableColumn.ADDRESS, visibleOrdersAllTableColumns) }}
      headPriceParent={{ className: generateColumnFilterClass(AllOrdersTableColumn.PRICE, visibleOrdersAllTableColumns) }}
      headStatusParent={{ className: generateColumnFilterClass(AllOrdersTableColumn.STATUS, visibleOrdersAllTableColumns) }}
      headCommentParent={{ className: generateColumnFilterClass(AllOrdersTableColumn.COMMENT, visibleOrdersAllTableColumns) }}
      headOrderedByParent={{ className: generateColumnFilterClass(AllOrdersTableColumn.ORDERED_BY, visibleOrdersAllTableColumns) }}
      headOrderIdParent={{ className: generateColumnFilterClass(AllOrdersTableColumn.ORDER_ID, visibleOrdersAllTableColumns) }}
      headDateDeliveredParent={{ className: generateColumnFilterClass(AllOrdersTableColumn.DATE_DELIVERED, visibleOrdersAllTableColumns) }}
      headViewImagesParent={{ className: generateColumnFilterClass(AllOrdersTableColumn.VIEW_IMAGES, visibleOrdersAllTableColumns) }}
      headDownloadOrderParent={{ className: generateColumnFilterClass(AllOrdersTableColumn.DOWNLOAD_ORDER, visibleOrdersAllTableColumns) }}
      headDateOrderedParent={{ className: generateColumnFilterClass(AllOrdersTableColumn.DATE_ORDERED, visibleOrdersAllTableColumns) }}
      children={
        <TableHeadTitle style={{ cursor: 'pointer' }} onClick={() => setExpanded(old => !old)} title='Orders' count={' ' + cylinderGroupOrders.length} />
      }
      rows={
        <>
          {!!orderOpened && <ViewOrderModal isOpen={!!orderOpened} onRequestClose={() => setOrderOpened(undefined)} order={orderOpened} />}
          {cylinderGroupOrders.map(cylinderGroupOrder => (
            <TableRow
              noCheckbox
              key={cylinderGroupOrder.id + 'TableRowCustomers'}
              sticky={<CellCustomer onClick={() => setOrderOpened(cylinderGroupOrder)} name={cylinderGroupOrder.cylinderGroup?.customer.name} />}
              scrollCells={
                <>
                  <CellCustomerID
                    content={cylinderGroupOrder.cylinderGroup.customer.customerIdentifier || '-'}
                    className={generateColumnFilterClass(AllOrdersTableColumn.CUSTOMER_ID, visibleOrdersAllTableColumns)}
                  />
                  <CellProduct
                    className={generateColumnFilterClass(AllOrdersTableColumn.PRODUCT, visibleOrdersAllTableColumns)}
                    contentWrapper={<ProductLabel cylinderGroupOrder={cylinderGroupOrder} />}
                  />
                  <CellAddress
                    className={generateColumnFilterClass(AllOrdersTableColumn.ADDRESS, visibleOrdersAllTableColumns)}
                    address={cylinderGroupOrder.cylinderGroup.customer.address.firstLine}
                    googleMapsLink={
                      'https://www.google.com/maps/search/?api=1&query=' +
                      cylinderGroupOrder.cylinderGroup.customer.address.latitude +
                      ',' +
                      cylinderGroupOrder.cylinderGroup.customer.address.longitude
                    }
                  />
                  <CellPrice
                    className={generateColumnFilterClass(AllOrdersTableColumn.PRICE, visibleOrdersAllTableColumns)}
                    price={formatPrice(cylinderGroupOrder.totalPrice, cylinderGroupOrder.currency || appContext.distributor?.defaultCurrency || 'USD')}
                  />
                  <CellStatus fromElement={cylinderGroupOrder} editable={false} />
                  <CellComment
                    className={generateColumnFilterClass(AllOrdersTableColumn.COMMENT, visibleOrdersAllTableColumns)}
                    content={cylinderGroupOrder.comment}
                  />
                  <CellOrderedBy
                    className={generateColumnFilterClass(AllOrdersTableColumn.ORDERED_BY, visibleOrdersAllTableColumns)}
                    content={cylinderGroupOrder.cylinderGroup.customer.name}
                  />
                  <CellOrderId
                    className={generateColumnFilterClass(AllOrdersTableColumn.ORDER_ID, visibleOrdersAllTableColumns)}
                    content={window.atob(cylinderGroupOrder.id).replace(/^\D+/g, '')}
                  />
                  <CellDateOrdered
                    className={generateColumnFilterClass(AllOrdersTableColumn.DATE_ORDERED, visibleOrdersAllTableColumns)}
                    content={format(parseISO(cylinderGroupOrder.createdAt), 'dd. MMM')}
                  />
                  <CellDateDelivered
                    className={generateColumnFilterClass(AllOrdersTableColumn.DATE_DELIVERED, visibleOrdersAllTableColumns)}
                    content={
                      cylinderGroupOrder.state === GQL.CylinderGroupOrderStates.Delivered && cylinderGroupOrder.deliveredAt
                        ? format(parseISO(cylinderGroupOrder.deliveredAt), 'dd. MMM')
                        : ''
                    }
                  />
                  <CellViewImages
                    className={generateColumnFilterClass(AllOrdersTableColumn.VIEW_IMAGES, visibleOrdersAllTableColumns)}
                    content={!((cylinderGroupOrder.inStops?.reduce((acc, i) => acc + (i?.images?.length || 0), 0) || 0) > 0) && ' '}
                    btnDownloadOrder={{
                      onClick: () => {
                        if ((cylinderGroupOrder.inStops?.reduce((acc, i) => acc + (i?.images?.length || 0), 0) || 0) > 0) {
                          showViewImageModal(cylinderGroupOrder)
                        }
                      },
                    }}
                  />
                  <CellDownloadOrder
                    className={generateColumnFilterClass(AllOrdersTableColumn.DOWNLOAD_ORDER, visibleOrdersAllTableColumns)}
                    content={
                      <Link
                        to={{
                          pathname: `/order/pdf/${cylinderGroupOrder.id}`,
                        }}
                        target='_blank'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <CellDownloadOrder style={{ width: '100%', height: '100%' }} />
                      </Link>
                    }
                  />
                </>
              }
            />
          ))}
          {allCount > 0 && (
            <ScrollIndicator
              tableRow
              loaded={count}
              total={allCount}
              btnLoadMore={{ ...(isLoading && { children: <Loader color='white' /> }), onClick: loadMore }}
            />
          )}
        </>
      }
      visibleColumns={Object.values(AllOrdersTableColumn)}
    />
  )
}
