// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ConnectedsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ConnectedsvgIcon(props: ConnectedsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 78.701 78.724"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M3.993 71.642a4 4 0 002.83-1.17l10.79-10.78 1.41 1.41-10.78 10.79a4.002 4.002 0 105.66 5.66l10.78-10.79 1.41 1.41c3.905 3.904 10.235 3.904 14.14 0l6.89-6.89 2.65 2.65a4.002 4.002 0 105.66-5.66l-35-35a4.002 4.002 0 10-5.66 5.66l2.65 2.65-6.89 6.89c-3.904 3.905-3.904 10.235 0 14.14l1.41 1.41-10.77 10.8a4 4 0 002.83 6.83l-.01-.01zm73.54-63.39a4 4 0 00-5.657-.003l-.003.003-10.78 10.78-1.41-1.41 10.78-10.79a4.002 4.002 0 10-5.66-5.66l-10.78 10.79-1.41-1.41c-3.905-3.904-10.235-3.904-14.14 0l-6.89 6.89-2.65-2.65a4.002 4.002 0 10-5.66 5.66l35 35a4.002 4.002 0 105.66-5.66l-2.65-2.65 6.89-6.89c3.904-3.905 3.904-10.235 0-14.14l-1.42-1.42 10.78-10.79a4 4 0 000-5.65z"
        }
      ></path>
    </svg>
  );
}

export default ConnectedsvgIcon;
/* prettier-ignore-end */
