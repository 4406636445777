import { useRef, useState } from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

import Text from 'components/Text'
import Dropdown from 'components/Dropdown/Dropdown'
import DropdownItem from 'components/Dropdown/DropdownItem'
import * as GQL from 'generated/graphql'
import CreateTriggerModal from '../components/CreateTriggerModal'
import EditTriggerModal from '../components/EditTriggerModal'
import ConfirmModal from 'components/Modal/ConfirmModal'
import { THRESHOLD_UNIT_PLASMIC_NAME, THRESHOLD_UNIT_SYMBOLS } from '../consts'
import CacheConfigs from 'util/cacheConfig'
import SettingsContentWrapper from 'plasmic/SettingsContentWrapper'
import AutomatedTriggers from 'plasmic/AutomatedTriggers'
import AutomaticTriggerRow from 'plasmic/AutomatedTriggerRow'
import CornerLoader from 'components/Loader/CornerLoader'
import ButtonFill from 'plasmic/ButtonFill'

const StyledAutomaticTriggerRow = styled(AutomaticTriggerRow)`
  .editBoxButton {
    opacity: 0;
    cursor: pointer;
  }

  :hover .editBoxButton,
  :hover .editBoxButton {
    opacity: 1 !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`

interface IRowItemProps {
  trigger: GQL.DistributorRefillThresholdNode
  onEditThreshold: (node: GQL.DistributorRefillThresholdNode, newThreshold: number) => void
  onEditAlertThreshold: (node: GQL.DistributorRefillThresholdNode, newThreshold: number) => void
  onEditThresholdUnit: (node: GQL.DistributorRefillThresholdNode, unit: GQL.DistributorRefillThresholdUnit) => void
  onEditTrigger: () => void
  onDeleteTrigger: () => void
}

const RowItem = ({ trigger, onEditThreshold, onEditAlertThreshold, onEditThresholdUnit, onEditTrigger, onDeleteTrigger }: IRowItemProps) => {
  const [open, setOpen] = useState(false)
  const [orderValue, setOrderValue] = useState(trigger.threshold)
  const [alertValue, setAlertValue] = useState(trigger.alertThreshold)
  const ref = useRef<HTMLButtonElement>(null)

  return (
    <>
      <StyledAutomaticTriggerRow
        triggerName={trigger.name}
        triggerOrderType={trigger.unit ? THRESHOLD_UNIT_PLASMIC_NAME[trigger.unit] : THRESHOLD_UNIT_PLASMIC_NAME[GQL.DistributorRefillThresholdUnit.Percent]}
        triggerOrderValue={orderValue}
        triggerOrderValueInput={{
          props: {
            helpText: trigger.unit ? THRESHOLD_UNIT_SYMBOLS[trigger.unit] : '',
            onChange: (event: any) => setOrderValue(event.target.value.replace(/\D/, '')),
            onBlur: (event: any) => onEditThreshold(trigger, event.target.value.replace(/\D/, '')),
            type: 'number',
          },
        }}
        triggerAlertValue={alertValue}
        triggerAlertValueInput={{
          props: {
            helpText: trigger.unit ? THRESHOLD_UNIT_SYMBOLS[trigger.unit] : '',
            onChange: (event: any) => setAlertValue(event.target.value.replace(/\D/, '')),
            onBlur: (event: any) => onEditAlertThreshold(trigger, event.target.value.replace(/\D/, '')),
            type: 'number',
          },
        }}
        triggerType={{
          props: {
            label: 'Trigger type',
            onChange: (value: any) => {
              for (var key in THRESHOLD_UNIT_PLASMIC_NAME) {
                if (value === THRESHOLD_UNIT_PLASMIC_NAME[key as GQL.DistributorRefillThresholdUnit]) {
                  onEditThresholdUnit(trigger, key as GQL.DistributorRefillThresholdUnit)
                }
              }
            },
          },
        }}
        rowMoreActions={{
          props: {
            onClick: () => setOpen(true),
            ref: ref,
          },
        }}
      />
      <Dropdown position='top' align='innerEnd' anchor={ref} open={open} onClose={() => setOpen(false)} showCaret={false}>
        <DropdownItem border height='35px' width='155px' onClick={onEditTrigger}>
          <Text fontSize='12px'>Edit trigger</Text>
        </DropdownItem>

        <DropdownItem border height='35px' width='155px' onClick={onDeleteTrigger}>
          <Text fontSize='12px'>Delete trigger</Text>
        </DropdownItem>
      </Dropdown>
    </>
  )
}

export default function SettingsAutomaticTriggers() {
  const intl = useIntl()
  const t = intl.formatMessage

  const [createTriggerOpen, setCreateTriggerOpen] = useState(false)
  const [editTrigger, setEditTrigger] = useState<GQL.DistributorRefillThresholdNode | undefined>(undefined)
  const [deleteTrigger, setDeleteTrigger] = useState<GQL.DistributorRefillThresholdNode | undefined>(undefined)

  const { data, loading } = GQL.useAllDistributorThresholds(CacheConfigs.ACCURATE_ONCE)
  const triggers = data?.allDistributorRefillThresholds?.edges.map(e => e?.node as GQL.DistributorRefillThresholdNode) || []

  const [patchDistributorRefillThreshold, { loading: loadingUpdate }] = GQL.usePatchDistributorRefillThreshold()

  const [deleteDistributorRefillThreshold, { loading: loadingDelete }] = GQL.useDeleteDistributorRefillThreshold({
    refetchQueries: ['AllDistributorThresholds'],
  })

  function updateThreshold(node: GQL.DistributorRefillThresholdNode, newThreshold: number) {
    patchDistributorRefillThreshold({
      variables: {
        id: node.id,
        input: {
          threshold: newThreshold,
        },
      },
    })
  }

  function updateAlertThreshold(node: GQL.DistributorRefillThresholdNode, newThreshold: number) {
    patchDistributorRefillThreshold({
      variables: {
        id: node.id,
        input: {
          alertThreshold: newThreshold,
        },
      },
    })
  }

  function updateThresholdUnit(node: GQL.DistributorRefillThresholdNode, unit: GQL.DistributorRefillThresholdUnit) {
    patchDistributorRefillThreshold({
      variables: {
        id: node.id,
        input: {
          unit: unit,
        },
      },
    })
  }

  function deleteTriggerFunc(node?: GQL.DistributorRefillThresholdNode) {
    if (node) {
      deleteDistributorRefillThreshold({
        variables: {
          id: node.id,
        },
      })
    }
  }

  return (
    <SettingsContentWrapper
      settingsTitle={t({ id: 'settings.automatic-triggers' })}
      info
      paragraphInfo={t({ id: 'settings.automatic-triggers.info' })}
      headerButtons={
        <ButtonFill
          data-testid='createTriggerBtn'
          rounded
          color={'blue'}
          label={t({ id: 'settings.create-trigger' })}
          onClick={() => setCreateTriggerOpen(true)}
        />
      }
      content={
        <>
          {(loading || loadingUpdate || loadingDelete) && <CornerLoader size={50} />}
          <CreateTriggerModal open={createTriggerOpen} onClose={() => setCreateTriggerOpen(false)} />
          <EditTriggerModal trigger={editTrigger} open={!!editTrigger} onClose={() => setEditTrigger(undefined)} />
          <ConfirmModal
            isOpen={!!deleteTrigger}
            onRequestClose={() => setDeleteTrigger(undefined)}
            onConfirm={() => {
              setDeleteTrigger(undefined)
              deleteTriggerFunc(deleteTrigger)
            }}
            onAbort={() => setDeleteTrigger(undefined)}
            title={t({ id: 'triggers.delete-trigger.title' })}
            description={t({ id: 'triggers.delete-trigger.body' })}
          />
          <AutomatedTriggers
            rows={triggers.map(trigger => (
              <RowItem
                key={trigger.id}
                trigger={trigger}
                onEditThreshold={updateThreshold}
                onEditAlertThreshold={updateAlertThreshold}
                onEditThresholdUnit={updateThresholdUnit}
                onEditTrigger={() => setEditTrigger(trigger)}
                onDeleteTrigger={() => setDeleteTrigger(trigger)}
              />
            ))}
          />
        </>
      }
    />
  )
}
