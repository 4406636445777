import { useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'

import ReadyToRefillTable from './ReadyToRefillTable'
import AwaitingDeliveryTable from './AwaitingDeliveryTable'
import OrderPlacedTable from './OrderPlacedTable'
import PausedOrdersTable from './PausedOrdersTable'
import * as GQL from 'generated/graphql'
import { useAppContext, useClickOutside, useCustomerContext } from 'util/hooks'
import FlexTableWrapper from 'plasmic/FlexTableWrapper'
import CornerLoader from 'components/Loader/CornerLoader'
/* Tutorials temporarily disabled
import Tour from 'components/Tour/Tour'
import steps from './OrdersTableTourSteps'*/
import CacheConfigs from 'util/cacheConfig'
import { VerifyExchangeModal, VerifyExchangeStock } from 'modules/inventory'
import OrderStateDropdown from 'plasmic/OrderStateDropdown'
import { getAvailableOrderStatusOptions } from 'modules/hardware/util'
import { displayToast } from 'util/toasts'
import { updateCylinderGroupOrderCacheOnPatch } from '../util'
import { CustomerDrawerListSource } from 'context/CustomerContext'
import { CylinderGroupSorting, handleCylinderGroupSorting, getCylinderGroupSortingIcon } from 'modules/customers/util'
import { createSortStringFromMapping } from 'util/sort'

interface OrdersTableProps {
  setOpenReadyToRefillOrder: (cylinderGroup: GQL.CylinderGroupNode | undefined) => void
}

const PAGE_SIZE_ORDERS_ACTIVE = 15

export default function OrdersTable({ setOpenReadyToRefillOrder }: OrdersTableProps) {
  const tableWrapperRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef(null)
  const location = useLocation()
  const [isFlipped, setIsFlipped] = useState(false)
  const [readyToRefillSorting, setReadyToRefillSorting] = useState<CylinderGroupSorting>({ estimatedEmpty: -1 })
  const [pendingRefillSorting, setPendingRefillSorting] = useState<CylinderGroupSorting>({ estimatedEmpty: -1 })
  const [awaitingRefillSorting, setAwaitingRefillSorting] = useState<CylinderGroupSorting>({ estimatedEmpty: -1 })
  const [pausedRefillSorting, setPausedRefillSorting] = useState<CylinderGroupSorting>({ estimatedEmpty: -1 })

  // fetchMore
  const [loadingFetchMoreReadyToRefill, setLoadingFetchMoreReadyToRefill] = useState(false)
  const [loadingFetchMorePendingRefill, setLoadingFetchMorePendingRefill] = useState(false)
  const [loadingFetchMoreAwaitingDelivery, setLoadingFetchMoreAwaitingDelivery] = useState(false)
  const [loadingFetchMorePausedRefill, setLoadingFetchMorePausedRefill] = useState(false)

  const { source, setSource, setCustomersContext } = useCustomerContext()

  const [dropdownFromElement, setDropdownFromElement] = useState<GQL.CylinderGroupOrderNode | GQL.CylinderGroupNode | undefined>(undefined)
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
  })
  const [selectedState, setSelectedState] = useState<'readyToRefill' | 'orderPlaced' | 'orderPaused' | 'awaitingDelivery' | 'pendingAdvance' | undefined>(
    undefined
  )

  const { appContext } = useAppContext()
  const client = useApolloClient()

  const [verifyExchangeInfo, setVerifyExchangeInfo] = useState<VerifyExchangeStock>()
  const [lastMovedRowId, setLastMovedRowId] = useState<string>()

  const intl = useIntl()
  const t = intl.formatMessage

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])

  const {
    loading: loadingReadyToRefill,
    data: readyToRefillData,
    fetchMore: fetchMoreReadyToRefill,
  } = GQL.useAllActiveReadyToRefillCancellable(
    {
      first: PAGE_SIZE_ORDERS_ACTIVE,
      after: null,
      orderBy: createSortStringFromMapping(readyToRefillSorting),
    },
    [appContext.depot],
    CacheConfigs.ACCURATE_FREQUENT.fetchPolicy,
    CacheConfigs.ACCURATE_FREQUENT.nextFetchPolicy,
    CacheConfigs.ACCURATE_FREQUENT.pollInterval
  )

  const {
    loading: loadingPendingRefill,
    data: pendingRefillData,
    fetchMore: fetchMorePendingRefill,
  } = GQL.useAllActivePendingRefillCancellable(
    {
      first: PAGE_SIZE_ORDERS_ACTIVE,
      after: null,
      orderBy: createSortStringFromMapping(pendingRefillSorting),
    },
    [appContext.depot],
    CacheConfigs.ACCURATE_FREQUENT.fetchPolicy,
    CacheConfigs.ACCURATE_FREQUENT.nextFetchPolicy,
    CacheConfigs.ACCURATE_FREQUENT.pollInterval
  )

  const {
    loading: loadingPausedRefill,
    data: pausedRefillData,
    fetchMore: fetchMorePausedRefill,
  } = GQL.useAllActivePausedRefillCancellable(
    {
      first: PAGE_SIZE_ORDERS_ACTIVE,
      after: null,
      orderBy: createSortStringFromMapping(pausedRefillSorting),
    },
    [appContext.depot],
    CacheConfigs.ACCURATE_FREQUENT.fetchPolicy,
    CacheConfigs.ACCURATE_FREQUENT.nextFetchPolicy,
    CacheConfigs.ACCURATE_FREQUENT.pollInterval
  )

  const {
    loading: loadingAwaitingDelivery,
    data: awaitingDeliveryData,
    fetchMore: fetchMoreAwaitingDelivery,
  } = GQL.useAllActiveAwaitingDeliveryCancellable(
    {
      first: PAGE_SIZE_ORDERS_ACTIVE,
      after: null,
      orderBy: createSortStringFromMapping(awaitingRefillSorting),
    },
    [appContext.depot],
    CacheConfigs.ACCURATE_FREQUENT.fetchPolicy,
    CacheConfigs.ACCURATE_FREQUENT.nextFetchPolicy,
    CacheConfigs.ACCURATE_FREQUENT.pollInterval
  )

  const readyToRefillCylinderGroups = useMemo(
    () =>
      readyToRefillData?.readyToRefillCylinderGroups?.edges
        ? readyToRefillData.readyToRefillCylinderGroups.edges.map(edge => edge?.node as GQL.CylinderGroupNode)
        : [],
    [readyToRefillData]
  )

  const readyToRefillPageInfo = useMemo(() => {
    return readyToRefillData?.readyToRefillCylinderGroups?.pageInfo
  }, [readyToRefillData])

  const awaitingDeliveryCylinderGroups = useMemo(
    () =>
      awaitingDeliveryData?.awaitingDeliveryCylinderGroups?.edges
        ? awaitingDeliveryData.awaitingDeliveryCylinderGroups.edges.map(edge => edge?.node as GQL.CylinderGroupOrderNode)
        : [],
    [awaitingDeliveryData]
  )

  const awaitingDeliveryPageInfo = useMemo(() => {
    return awaitingDeliveryData?.awaitingDeliveryCylinderGroups?.pageInfo
  }, [awaitingDeliveryData])

  const pendingRefillCylinderGroups = useMemo(
    () =>
      pendingRefillData?.pendingRefillCylinderGroups?.edges
        ? pendingRefillData?.pendingRefillCylinderGroups?.edges.map(edge => edge?.node as GQL.CylinderGroupOrderNode)
        : [],
    [pendingRefillData]
  )

  const pendingRefillPageInfo = useMemo(() => {
    return pendingRefillData?.pendingRefillCylinderGroups?.pageInfo
  }, [pendingRefillData])

  const pausedRefillCylinderGroups = useMemo(
    () =>
      pausedRefillData?.pausedRefillCylinderGroups?.edges
        ? pausedRefillData.pausedRefillCylinderGroups.edges.map(edge => edge?.node as GQL.CylinderGroupOrderNode)
        : [],
    [pausedRefillData]
  )

  const pausedRefillPageInfo = useMemo(() => {
    return pausedRefillData?.pausedRefillCylinderGroups?.pageInfo
  }, [pausedRefillData])

  useClickOutside(
    dropdownRef,
    () => {
      setDropdownVisible(false)
      setDropdownFromElement(undefined)
    },
    false,
    true
  )

  const dropdownStyle = {
    position: 'absolute',
    zIndex: 9999,
    translate: '-50%',
    visibility: dropdownVisible ? 'visible' : 'hidden',
    top: `${dropdownPosition.top}px`,
    left: `${dropdownPosition.left}px`,
  }

  const [patchCylinderGroupOrder, { loading: patchOrderLoading }] = GQL.usePatchCylinderGroupOrderLimited({
    update(_, { data }) {
      if (!data?.patchCylinderGroupOrder?.ok || !data.patchCylinderGroupOrder.cylinderGroupOrder) {
        displayToast(t({ id: 'orders.patch-order.error.generic' }), 'error')
        return
      }
      updateCylinderGroupOrderCacheOnPatch(data.patchCylinderGroupOrder.cylinderGroupOrder as GQL.CylinderGroupOrderNode, client)
    },
  })

  const handleOrderStatusClick = (event: React.MouseEvent<HTMLButtonElement>, fromElement: GQL.CylinderGroupOrderNode | GQL.CylinderGroupNode) => {
    if (!tableWrapperRef || !tableWrapperRef.current) {
      return
    }

    const contentWrapperTop = document.getElementById('content-wrapper')
    const offsetTable = tableWrapperRef.current.getBoundingClientRect()
    const buttonElement = event.currentTarget
    const buttonRect = buttonElement.getBoundingClientRect()
    const viewportHeightInPixels = window.innerHeight
    setIsFlipped(viewportHeightInPixels - buttonRect.y - offsetTable.y + buttonRect.height - 14 < 150)

    setDropdownPosition({
      top: buttonRect.y - offsetTable.y + buttonRect.height - 14,
      left: buttonRect.x + (contentWrapperTop ? contentWrapperTop.scrollLeft : 0) + buttonRect.width / 2,
    })

    if (fromElement.__typename === 'CylinderGroupNode') {
      setSelectedState('readyToRefill')
    } else if (fromElement.__typename === 'CylinderGroupOrderNode') {
      setSelectedState(getAvailableOrderStatusOptions(fromElement))
    }
    setDropdownVisible(prev => !prev)
    setDropdownFromElement(fromElement)
  }

  const handleOrderStatusMenuClick = (toState: GQL.CylinderGroupOrderStates) => {
    if (
      dropdownFromElement &&
      dropdownFromElement.__typename === 'CylinderGroupNode' &&
      toState === GQL.CylinderGroupOrderStates.Created &&
      selectedState === 'readyToRefill'
    ) {
      setOpenReadyToRefillOrder(dropdownFromElement)
    } // Add falsy to the check below if the user / distributor doesnt use inventory
    else if (dropdownFromElement && dropdownFromElement.__typename === 'CylinderGroupOrderNode' && toState === GQL.CylinderGroupOrderStates.Delivered) {
      setDropdownVisible(prev => !prev)
      setDropdownFromElement(undefined)
      if (setVerifyExchangeInfo && appContext.distributor?.inventoryAccess !== GQL.InventoryAccess.NoAccess) {
        return setVerifyExchangeInfo({
          callback: () => {
            patchCylinderGroupOrder({
              variables: {
                id: dropdownFromElement.id,
                input: {
                  state: toState,
                },
              },
              onCompleted: () => {
                setLastMovedRowId(dropdownFromElement.id)
              },
            })
          },
          order: dropdownFromElement,
        })
      } else {
        return patchCylinderGroupOrder({
          variables: {
            id: dropdownFromElement.id,
            input: {
              state: toState,
            },
          },
          onCompleted: () => {
            setLastMovedRowId(dropdownFromElement.id)
          },
        })
      }
    } else if (dropdownFromElement && dropdownFromElement.__typename === 'CylinderGroupOrderNode') {
      patchCylinderGroupOrder({
        variables: {
          id: dropdownFromElement.id,
          input: {
            state: toState,
          },
        },
        onCompleted: () => {
          setLastMovedRowId(dropdownFromElement.id)
        },
      })
    } else {
      displayToast(t({ id: 'orders.status.change.error' }))
    }
    setDropdownVisible(prev => !prev)
    setDropdownFromElement(undefined)
  }

  useEffect(() => {
    const customerParam = queryParams.get('customer')
    if (!!customerParam && !source) {
      const isAwaitingDeliveryCustomer = awaitingDeliveryCylinderGroups.some(cgo => cgo.cylinderGroup.customer.id === customerParam)
      if (isAwaitingDeliveryCustomer) {
        setSource(CustomerDrawerListSource.AWAITING_DELIVERY_ORDERS)
        setCustomersContext(awaitingDeliveryCylinderGroups.map(cgo => cgo.cylinderGroup.customer))
      }

      const isPausedRefillCustomer = pausedRefillCylinderGroups.some(cgo => cgo.cylinderGroup.customer.id === customerParam)
      if (isPausedRefillCustomer) {
        setSource(CustomerDrawerListSource.PAUSED_ORDERS)
        setCustomersContext(pausedRefillCylinderGroups.map(cgo => cgo.cylinderGroup.customer))
        return
      }
      const isReadyToRefillCustomer = readyToRefillCylinderGroups.some(cylinderGroup => cylinderGroup.customer.id === customerParam)
      if (isReadyToRefillCustomer) {
        setSource(CustomerDrawerListSource.READY_TO_REFILL)
        setCustomersContext(readyToRefillCylinderGroups.map(cg => cg.customer))
        return
      }
      setSource(CustomerDrawerListSource.PLACED_ORDERS)
      setCustomersContext(pendingRefillCylinderGroups.map(cgo => cgo.cylinderGroup.customer))
    }
  }, [
    awaitingDeliveryCylinderGroups,
    pausedRefillCylinderGroups,
    pendingRefillCylinderGroups,
    queryParams,
    readyToRefillCylinderGroups,
    setCustomersContext,
    setSource,
    source,
  ])

  return (
    <>
      {patchOrderLoading && <CornerLoader size={65} topAdjust={'-30px'} />}
      {/*{!loading && <Tour key='1213' steps={steps} name='orders_overview' />}*/}
      <FlexTableWrapper
        ref={tableWrapperRef}
        tables={
          <>
            <OrderStateDropdown
              data-testid='order-state-toggle'
              flip={isFlipped}
              ref={dropdownRef}
              state={selectedState}
              style={dropdownStyle}
              btnCreateOrder={{
                onClick: () => handleOrderStatusMenuClick(GQL.CylinderGroupOrderStates.Created),
              }}
              btnAwaitingDelivery={{
                onClick: () => handleOrderStatusMenuClick(GQL.CylinderGroupOrderStates.AwaitingDelivery),
              }}
              btnDelivered={{
                onClick: () => handleOrderStatusMenuClick(GQL.CylinderGroupOrderStates.Delivered),
              }}
              btnRemoveScheduledDelivery={{
                onClick: () => handleOrderStatusMenuClick(GQL.CylinderGroupOrderStates.Created),
              }}
              btnResume={{
                onClick: () => handleOrderStatusMenuClick(GQL.CylinderGroupOrderStates.Created),
              }}
              btnPaused={{
                onClick: () => handleOrderStatusMenuClick(GQL.CylinderGroupOrderStates.Paused),
              }}
              btnCancel={{
                onClick: () => handleOrderStatusMenuClick(GQL.CylinderGroupOrderStates.Cancelled),
              }}
            />
            <ReadyToRefillTable
              onOrderStatusClick={handleOrderStatusClick}
              loading={loadingReadyToRefill}
              loadingFetchMore={loadingFetchMoreReadyToRefill}
              cylinderGroups={readyToRefillCylinderGroups}
              loadMore={() => {
                if (readyToRefillCylinderGroups.length >= (readyToRefillData?.readyToRefillCylinderGroups?.totalCount || 0) || loadingReadyToRefill) {
                  return
                } else if (readyToRefillPageInfo?.endCursor) {
                  setLoadingFetchMoreReadyToRefill(true)
                  fetchMoreReadyToRefill({
                    variables: {
                      first: PAGE_SIZE_ORDERS_ACTIVE,
                      after: readyToRefillPageInfo.endCursor,
                      orderBy: createSortStringFromMapping(readyToRefillSorting),
                    },
                    updateQuery(prev, { fetchMoreResult }) {
                      if (!fetchMoreResult) return prev
                      return {
                        ...fetchMoreResult,
                        readyToRefillCylinderGroups: {
                          ...fetchMoreResult?.readyToRefillCylinderGroups,
                          edges: [
                            ...(prev?.readyToRefillCylinderGroups?.edges ? prev.readyToRefillCylinderGroups.edges : []),
                            ...(fetchMoreResult?.readyToRefillCylinderGroups?.edges ? fetchMoreResult.readyToRefillCylinderGroups.edges : []),
                          ],
                        },
                      } as GQL.AllActiveReadyToRefill
                    },
                  }).finally(() => setLoadingFetchMoreReadyToRefill(false))
                } else {
                  displayToast('Something went wrong while fetching more.')
                }
              }}
              totalCount={readyToRefillData?.readyToRefillCylinderGroups?.totalCount || readyToRefillCylinderGroups.length}
              setLastMovedRowId={setLastMovedRowId}
              onSortClick={(sortKey: string) => handleCylinderGroupSorting(sortKey, setReadyToRefillSorting, 'estimatedEmpty')}
              getSortIcon={(sortKey: string) => getCylinderGroupSortingIcon(sortKey, readyToRefillSorting)}
            />
            <OrderPlacedTable
              cylinderGroupOrders={pendingRefillCylinderGroups}
              loadMore={() => {
                if (pendingRefillCylinderGroups.length >= (pendingRefillData?.pendingRefillCylinderGroups?.totalCount || 0) || loadingPendingRefill) {
                  return
                } else if (pendingRefillPageInfo?.endCursor) {
                  setLoadingFetchMorePendingRefill(true)
                  fetchMorePendingRefill({
                    variables: {
                      first: PAGE_SIZE_ORDERS_ACTIVE,
                      after: pendingRefillPageInfo.endCursor,
                      orderBy: createSortStringFromMapping(pendingRefillSorting),
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev
                      return {
                        ...fetchMoreResult,
                        pendingRefillCylinderGroups: {
                          ...fetchMoreResult?.pendingRefillCylinderGroups,
                          edges: [
                            ...(prev?.pendingRefillCylinderGroups?.edges ? prev.pendingRefillCylinderGroups.edges : []),
                            ...(fetchMoreResult?.pendingRefillCylinderGroups?.edges ? fetchMoreResult.pendingRefillCylinderGroups.edges : []),
                          ],
                        },
                      } as GQL.AllActivePendingRefill
                    },
                  }).finally(() => setLoadingFetchMorePendingRefill(false))
                } else {
                  displayToast('Something went wrong while fetching more.')
                }
              }}
              setVerifyExchangeInfo={setVerifyExchangeInfo}
              lastMovedRowId={lastMovedRowId}
              totalCount={pendingRefillData?.pendingRefillCylinderGroups?.totalCount || pendingRefillCylinderGroups.length}
              loading={loadingPendingRefill}
              loadingFetchMore={loadingFetchMorePendingRefill}
              onOrderStatusClick={handleOrderStatusClick}
              setLastMovedRowId={setLastMovedRowId}
              onSortClick={(sortKey: string) => handleCylinderGroupSorting(sortKey, setPendingRefillSorting, 'estimatedEmpty')}
              getSortIcon={(sortKey: string) => getCylinderGroupSortingIcon(sortKey, pendingRefillSorting)}
            />
            <PausedOrdersTable
              cylinderGroupOrders={pausedRefillCylinderGroups}
              loadMore={() => {
                if (pausedRefillCylinderGroups.length >= (pausedRefillData?.pausedRefillCylinderGroups?.totalCount || 0) || loadingPausedRefill) {
                  return
                } else if (pausedRefillPageInfo?.endCursor) {
                  setLoadingFetchMorePausedRefill(true)
                  fetchMorePausedRefill({
                    variables: {
                      first: PAGE_SIZE_ORDERS_ACTIVE,
                      after: pausedRefillPageInfo.endCursor,
                      orderBy: createSortStringFromMapping(pausedRefillSorting),
                    },
                    updateQuery(prev, { fetchMoreResult }) {
                      if (!fetchMoreResult) return prev
                      return {
                        ...fetchMoreResult,
                        pausedRefillCylinderGroups: {
                          ...fetchMoreResult?.pausedRefillCylinderGroups,
                          edges: [
                            ...(prev?.pausedRefillCylinderGroups?.edges ? prev.pausedRefillCylinderGroups.edges : []),
                            ...(fetchMoreResult?.pausedRefillCylinderGroups?.edges ? fetchMoreResult.pausedRefillCylinderGroups.edges : []),
                          ],
                        },
                      } as GQL.AllActivePausedRefill
                    },
                  }).finally(() => setLoadingFetchMorePausedRefill(false))
                } else {
                  displayToast('Something went wrong while fetching more.')
                }
              }}
              lastMovedRowId={lastMovedRowId}
              totalCount={pausedRefillData?.pausedRefillCylinderGroups?.totalCount || pausedRefillCylinderGroups.length}
              loading={loadingPausedRefill}
              loadingFetchMore={loadingFetchMorePausedRefill}
              onOrderStatusClick={handleOrderStatusClick}
              setLastMovedRowId={setLastMovedRowId}
              onSortClick={(sortKey: string) => handleCylinderGroupSorting(sortKey, setPausedRefillSorting, 'estimatedEmpty')}
              getSortIcon={(sortKey: string) => getCylinderGroupSortingIcon(sortKey, pausedRefillSorting)}
            />
            <AwaitingDeliveryTable
              cylinderGroupOrders={awaitingDeliveryCylinderGroups}
              loadMore={() => {
                if (
                  awaitingDeliveryCylinderGroups.length >= (awaitingDeliveryData?.awaitingDeliveryCylinderGroups?.totalCount || 0) ||
                  loadingAwaitingDelivery
                ) {
                  return
                } else if (awaitingDeliveryPageInfo?.endCursor) {
                  setLoadingFetchMoreAwaitingDelivery(true)
                  fetchMoreAwaitingDelivery({
                    variables: {
                      first: PAGE_SIZE_ORDERS_ACTIVE,
                      after: awaitingDeliveryPageInfo.endCursor,
                      orderBy: createSortStringFromMapping(awaitingRefillSorting),
                    },
                    updateQuery(prev, { fetchMoreResult }) {
                      if (!fetchMoreResult) return prev
                      return {
                        ...fetchMoreResult,
                        awaitingDeliveryCylinderGroups: {
                          ...fetchMoreResult?.awaitingDeliveryCylinderGroups,
                          edges: [
                            ...(prev?.awaitingDeliveryCylinderGroups?.edges ? prev.awaitingDeliveryCylinderGroups.edges : []),
                            ...(fetchMoreResult?.awaitingDeliveryCylinderGroups?.edges ? fetchMoreResult.awaitingDeliveryCylinderGroups.edges : []),
                          ],
                        },
                      } as GQL.AllActiveAwaitingDelivery
                    },
                  }).finally(() => setLoadingFetchMoreAwaitingDelivery(false))
                } else {
                  displayToast('Something went wrong while fetching more.')
                }
              }}
              setVerifyExchangeInfo={setVerifyExchangeInfo}
              lastMovedRowId={lastMovedRowId}
              loading={loadingAwaitingDelivery}
              totalCount={awaitingDeliveryData?.awaitingDeliveryCylinderGroups?.totalCount || awaitingDeliveryCylinderGroups.length}
              loadingFetchMore={loadingFetchMoreAwaitingDelivery}
              onOrderStatusClick={handleOrderStatusClick}
              setLastMovedRowId={setLastMovedRowId}
              onSortClick={(sortKey: string) => handleCylinderGroupSorting(sortKey, setAwaitingRefillSorting, 'estimatedEmpty')}
              getSortIcon={(sortKey: string) => getCylinderGroupSortingIcon(sortKey, awaitingRefillSorting)}
            />
          </>
        }
      />
      {!!verifyExchangeInfo && (
        <VerifyExchangeModal isOpen={!!verifyExchangeInfo} onClose={() => setVerifyExchangeInfo(undefined)} verifyExchangeInfo={verifyExchangeInfo} />
      )}
    </>
  )
}
