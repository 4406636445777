import React from 'react'

import * as GQL from 'generated/graphql'
import Modal from 'components/Modal/Modal'
import ModalService from 'plasmic/ModalService'

interface ServiceModalProps {
  service?: GQL.ProductNode
  open: boolean
  onClose: () => void
}

const ServiceModal: React.FC<ServiceModalProps> = props => {
  return (
    <>
      <Modal isOpen={props.open} onRequestClose={props.onClose} stripped overlayStyle={{ alignItems: 'flex-start', overflow: 'auto', padding: '2rem 0rem' }}>
        <ModalService service={props.service} onClose={props.onClose} />
      </Modal>
    </>
  )
}

export default ServiceModal
