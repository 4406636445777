// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicOrderStateDropdown, DefaultOrderStateDropdownProps } from './plasmic/solace_components/PlasmicOrderStateDropdown'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface OrderStateDropdownProps extends Omit<DefaultOrderStateDropdownProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultOrderStateDropdownProps altogether and have
// total control over the props for your component.
interface OrderStateDropdownProps extends DefaultOrderStateDropdownProps {
  btnCreateOrder?: any
  btnAwaitingDelivery?: any
  btnDelivered?: any
  btnRemoveScheduledDelivery?: any
  btnResume?: any
  btnPaused?: any
  btnCancel?: any
  style?: any
}

function OrderStateDropdown_(props: OrderStateDropdownProps, ref: HTMLElementRefOf<'div'>) {
  return <PlasmicOrderStateDropdown root={{ ref }} {...props} />
}

const OrderStateDropdown = React.forwardRef(OrderStateDropdown_)
export default OrderStateDropdown
