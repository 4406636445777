import React, { useEffect, useState } from 'react'

import { CycleNode, CylinderGroupNode } from 'generated/graphql'
import PlasmicChartInfo from 'plasmic/ChartInfo'
import { getRemainingGas } from 'modules/hardware/util'
import { enumToStringFormatted } from 'util/utils'

interface Props {
  activeCycle: CycleNode
  cylinderGroup: CylinderGroupNode
}

interface SensorInfo {
  state?: string
  fullWeight?: number
  gasWeight?: number
  tareWeight?: number
  lastRefill?: Date
  estimatedEmpty?: Date
  percent?: number
  lastTransmission?: Date
  deplation?: Date
  empty?: Date
}

const ChartInfo: React.FC<Props> = props => {
  const [sensorInfo, setSensorInfo] = useState<SensorInfo>()

  useEffect(() => {
    const remainingGas = getRemainingGas(props.activeCycle)

    const state = props.activeCycle.state || undefined
    const fullWeight = remainingGas.weight !== null ? remainingGas.weight : undefined
    const gasWeight = props.activeCycle?.remainingGasWeight ? Math.round(props.activeCycle.remainingGasWeight) : undefined
    const tareWeight = props.activeCycle?.tareWeight ? Math.round(props.activeCycle.tareWeight) : undefined
    const lastRefill = props.activeCycle.refillDate ? new Date(props.activeCycle.refillDate) : undefined
    const estimatedEmpty = props.activeCycle.estimatedEmpty ? new Date(props.activeCycle.estimatedEmpty) : undefined
    const percent = remainingGas.percentage !== null ? remainingGas.percentage : undefined
    const lastTransmission = props.activeCycle.last ? new Date(props.activeCycle.last) : undefined
    const depletion = props.activeCycle.depletionDate ? new Date(props.activeCycle.depletionDate) : undefined
    const empty = props.activeCycle.emptyDate ? new Date(props.activeCycle.emptyDate) : undefined

    setSensorInfo({
      state: state ? enumToStringFormatted(state) : undefined,
      fullWeight: fullWeight,
      gasWeight: gasWeight,
      tareWeight: tareWeight,
      lastRefill: lastRefill,
      estimatedEmpty: estimatedEmpty,
      percent: percent,
      lastTransmission: lastTransmission,
      deplation: depletion,
      empty: empty,
    })
  }, [props.activeCycle, props.cylinderGroup])

  const formatWeight = (weight?: number) => (weight !== undefined ? `${weight} kg` : undefined)

  const formatPercent = (percent?: number) => (percent !== undefined ? `${percent} %` : undefined)

  const formatDateTime = (dateTime?: Date) =>
    dateTime?.toLocaleString(navigator.language, {
      weekday: 'short',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })

  const formatDate = (date?: Date) =>
    date?.toLocaleDateString(navigator.language, {
      weekday: 'short',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })

  return (
    <>
      {sensorInfo && (
        <PlasmicChartInfo
          currentState={sensorInfo.state}
          currentWeight={formatWeight(sensorInfo.fullWeight)}
          gasWeight={formatWeight(sensorInfo.gasWeight)}
          tareWeight={formatWeight(sensorInfo.tareWeight)}
          lastRefillDate={formatDate(sensorInfo.lastRefill)}
          estimatedEmpty={formatDate(sensorInfo.estimatedEmpty)}
          currentPercent={formatPercent(sensorInfo.percent)}
          lastTransmission={formatDateTime(sensorInfo.lastTransmission)}
          depletionDate={formatDate(sensorInfo.deplation)}
          emptyDate={formatDate(sensorInfo.empty)}
        />
      )}
    </>
  )
}

export default ChartInfo
