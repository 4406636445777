import * as GQL from 'generated/graphql'
import { AuditLogDomain } from '../../types'
import { GenericUpdateParser } from '../generic'

class CylinderGroupOrderUpdateParser extends GenericUpdateParser {
  public domains(entry: GQL.AuditLogEntryNode): AuditLogDomain[] {
    if (!entry.objectId) return []
    return [
      this.createDomainItem('-', entry.objectRepr, entry.contentType?.model),
      this.createDomainItem('-', `Order #${this.extractOrderId(entry)}`, entry.contentType?.model),
    ]
  }

  private extractOrderId(entry: GQL.AuditLogEntryNode): string | undefined {
    return window.atob(entry.objectId!).split(':')[1]
  }
}

export { CylinderGroupOrderUpdateParser }
