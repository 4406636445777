// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicFiltersAudit, DefaultFiltersAuditProps } from './plasmic/solace_components/PlasmicFiltersAudit'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

import * as GQL from 'generated/graphql'
import { omit } from 'lodash'
import ButtonFilterDropdownRowCustom from './ButtonFilterDropdownRowCustom'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface FiltersAuditProps extends Omit<DefaultFiltersAuditProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultFiltersAuditProps altogether and have
// total control over the props for your component.
export interface FiltersAuditProps extends DefaultFiltersAuditProps {
  actorIds: string[]
  onActorIdsChange: (actorIds: string[]) => void
  actions: GQL.LogEntryAction[]
  onActionsChange: (actorIds: GQL.LogEntryAction[]) => void
  onClearFilters: () => void
}

const MANAGE_USERS_PATH = '/settings/users'

const actionMap = {
  [GQL.LogEntryAction.A_0]: 'Created',
  [GQL.LogEntryAction.A_1]: 'Updated',
  [GQL.LogEntryAction.A_2]: 'Deleted',
  [GQL.LogEntryAction.A_3]: 'Accessed',
} as const

function FiltersAudit_(props: FiltersAuditProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicFiltersAudit to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicFiltersAudit are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all FiltersAuditProps here, but feel free
  // to do whatever works for you.
  const intl = useIntl()
  const t = intl.formatMessage
  const { data: dataUsers } = GQL.useUsers()
  const users = React.useMemo(() => dataUsers?.allUsers?.edges.map(edge => edge?.node as GQL.UserNode) || [], [dataUsers])
  const navigate = useNavigate()

  const handleActorChange = (userId: string) => {
    if (props.actorIds.includes(userId)) {
      return props.onActorIdsChange(props.actorIds.filter(actorId => actorId !== userId))
    }
    return props.onActorIdsChange([...props.actorIds, userId])
  }

  const handleActionChange = (action: GQL.LogEntryAction) => {
    if (props.actions.includes(action)) {
      return props.onActionsChange(props.actions.filter(_action => _action !== action))
    }
    return props.onActionsChange([...props.actions, action])
  }

  return (
    <PlasmicFiltersAudit
      root={{ ref }}
      {...omit(props, 'actorIds', 'onActorIdsChange', 'onClearFilters', 'actions', 'onActionsChange')}
      filterPerformedBy={{
        props: {
          manageFilterButton: true,
          labelManageFilter: t({ id: 'settings.users.manage' }),
          btnManageFilter: { onClick: () => navigate(MANAGE_USERS_PATH) },
          dropdown: users.map(user => (
            <ButtonFilterDropdownRowCustom
              key={user.id}
              checkbox={{ isChecked: props.actorIds.includes(user.id), onChange: () => handleActorChange(user.id), children: user.fullName }}
              count={0}
            />
          )),
        },
      }}
      filterAction={{
        props: {
          dropdown: Object.entries(GQL.LogEntryAction).map(([key, value]) => (
            <ButtonFilterDropdownRowCustom
              key={key}
              checkbox={{ isChecked: props.actions.includes(value), onChange: () => handleActionChange(value), children: actionMap[value] }}
              count={0}
            />
          )),
        },
      }}
      btnClearFilters={{ onClick: () => props.onClearFilters(), name: 'clearFilters' }}
    />
  )
}

const FiltersAudit = React.forwardRef(FiltersAudit_)
export default FiltersAudit
