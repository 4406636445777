import { ApolloClient, DocumentNode } from '@apollo/client'

import { OperationDefinitionNode } from 'graphql'
import { Edge, FilterFunction, MatchedQuery, MatchedQueryResult, Options, QueryFilter } from './interfaces'

export class ApolloCacheServiceUtilities {
  protected client: ApolloClient<any>
  /**
   * Creates an instance of ApolloCacheService.
   * @param client - The Apollo Client instance to interact with its cache.
   */
  constructor(client: ApolloClient<any>) {
    this.client = client
  }

  /**
   * Determines whether the provided query variables match the filter variables.
   * @param variables - The variables from the query to be matched.
   * @param filterVariables - The filter variables to match against.
   * @returns true if the variables match the filter variables; false otherwise.
   */
  private matchVariables(variables: Record<string, any>, filterVariables: Record<string, any>): boolean {
    for (const key in filterVariables) {
      if (filterVariables.hasOwnProperty(key)) {
        if (variables[key] === filterVariables[key]) {
          return true
        }
      }
    }
    return false
  }

  /**
   * Extracts variables from a cache key string.
   * @param key - The cache key from which to extract variables.
   * @returns An object containing the extracted variables.
   */
  private extractVariables(key: string): Record<string, any> {
    const variablesString = key.match(/\((.*)\)/)?.[1]
    return variablesString ? JSON.parse(variablesString.replace(/(\w+):/g, '"$1":')) : {}
  }

  /**
   * Determines whether a specific edge should be skipped based on the provided filter.
   * @param edge - The edge to check against the filter.
   * @param queryVariables - The filter to be applied.
   * @returns true if the edge should be skipped; false otherwise.
   */
  protected shouldSkip(edge: Edge, queryVariables: QueryFilter): boolean {
    return !!(queryVariables?.if && queryVariables.if(edge))
  }

  /**
   * Determines whether a given filter matches a query by comparing the query name and variables.
   * @param queryName - The name of the query to be matched.
   * @param queryVariables - The variables associated with the query.
   * @param filter - The filter containing criteria to match against the query.
   * @returns true if the filter matches the query name and variables; false otherwise.
   */
  private isFilterMatching(queryName: string, queryVariables: Record<string, any> | undefined, filter: QueryFilter): boolean {
    return (
      filter.queryName === queryName.split('(')[0] &&
      (queryVariables ? !filter.queryVariables || this.matchVariables(queryVariables, filter.queryVariables) : true)
    )
  }

  /**
   * Processes queries in the cache that match given filters and applies updates through a specified function.
   * @param edge - The edge to be processed.
   * @param queryVariables - An array of query filters to apply.
   * @param updateFunction - A function that takes matched query results and the edge, and returns updated edges.
   * @param options - Optional parameters for additional configurations.
   */
  protected processQueries(
    edge: Edge,
    queryVariables: QueryFilter[],
    updateFunction: (queryData: MatchedQueryResult, edge: Edge) => Edge[],
    options?: Options
  ): void {
    queryVariables.forEach(queryVariable => {
      if (queryVariable?.if && this.shouldSkip(edge, queryVariable)) {
        return
      }

      const queries = this.getMatchingQueriesCache(queryVariable)

      queries.forEach(({ variables }) => {
        if (queryVariable.queryDocument) {
          const queryName = this.getQueryName(queryVariable, options)
          const queryCache = this.readQueryCache(queryVariable.queryDocument, variables)

          if (queryCache && queryName in queryCache && queryCache[queryName]) {
            const queryData = queryCache[queryName]
            const updatedEdges = updateFunction(queryData, edge)

            const filteredEdges = queryVariable?.applyFilters ? this.filterEdges(updatedEdges, queryVariable.applyFilters) : updatedEdges
            const updatedQueryData = {
              ...queryData,
              edges: filteredEdges,
              ...(queryData?.edgeCount !== undefined && {
                edgeCount: filteredEdges.length,
              }),
              ...(queryData?.totalCount !== undefined && {
                totalCount: queryData.totalCount + (filteredEdges.length - queryData.edges.length),
              }),
            }

            this.writeQueryCache(queryVariable.queryDocument, queryName, updatedQueryData, variables)
          }
        }
      })
    })
  }

  /**
   * Adds a new edge or updates an existing one in the given query data.
   * @param queryData - The query data containing edges.
   * @param edge - The edge to add or update.
   * @returns An array of edges with the new or updated edge included.
   */
  protected addOrUpdateEdge(queryData: MatchedQueryResult, edge: Edge): Edge[] {
    const exists = queryData.edges.some(_edge => _edge.node.id === edge.node.id && _edge.node.__typename === edge.node.__typename)
    return exists
      ? queryData.edges.map(_edge => (_edge.node.id === edge.node.id && _edge.node.__typename === edge.node.__typename ? edge : _edge))
      : [...queryData.edges, edge]
  }

  /**
   * Removes an edge from the given query data.
   * @param queryData - The query data containing edges.
   * @param edge - The edge to be removed.
   * @returns An array of edges with the specified edge removed.
   */
  protected removeEdge(queryData: MatchedQueryResult, edge: Edge): Edge[] {
    return queryData.edges.filter(_edge => !(_edge.node.id === edge.node.id && _edge.node.__typename === edge.node.__typename))
  }

  /**
   * Retrieves the name of a query based on the provided filter and options.
   * @param queryVariable - The query filter to analyze.
   * @param options - Optional parameters to consider when extracting the query name.
   * @returns The extracted query name.
   */
  protected getQueryName(queryVariable: QueryFilter, options?: Options): string {
    return options?.checkAlias ?? true ? this.extractQueryAlias(queryVariable.queryDocument) || queryVariable.queryName : queryVariable.queryName
  }

  /**
   * Extracts the alias of the main query from a GraphQL document.
   * @param document - The GraphQL document to extract the alias from.
   * @returns The alias of the main query if present; otherwise, undefined.
   */
  protected extractQueryAlias(document: DocumentNode): string | undefined {
    const operationDefinition = document.definitions.find(def => def.kind === 'OperationDefinition') as OperationDefinitionNode

    if (!operationDefinition || operationDefinition.operation !== 'query') {
      return undefined
    }

    const selection = operationDefinition.selectionSet.selections[0]
    if (selection.kind === 'Field' && selection.alias) {
      return selection.alias.value
    }

    return undefined
  }

  /**
   * Filters edges based on the provided array of filter functions.
   * @param edges - The array of edges to filter.
   * @param filters - An array of filter functions to apply.
   * @returns An array of edges that pass all the filters.
   */
  protected filterEdges(edges: Edge[], filters: FilterFunction[]): Edge[] {
    return edges.filter(edge => filters.every(f => f(edge)))
  }

  /**
   * Writes updated query data to the Apollo cache.
   * @param document - The GraphQL document associated with the query.
   * @param queryName - The name of the query.
   * @param data - The updated query data to write to the cache.
   * @param variables - Optional variables associated with the query.
   */
  protected writeQueryCache(document: DocumentNode, queryName: string, data: MatchedQueryResult, variables?: Record<string, any>): void {
    this.client.cache.writeQuery({
      query: document,
      data: {
        [queryName]: data,
      },
      variables,
    })
  }

  /**
   * Retrieves a list of matched queries from the Apollo cache based on the provided filters.
   * @param filter - The filter to apply on the queries.
   * @returns An array of matched queries.
   */
  protected getMatchingQueriesCache(filter: QueryFilter): MatchedQuery[] {
    const { ROOT_QUERY } = this.client.cache.extract()

    if (!ROOT_QUERY) {
      return []
    }

    return Object.entries(ROOT_QUERY)
      .filter(([key, _]) => {
        return this.isFilterMatching(key, this.extractVariables(key), filter)
      })
      .map(([key, value]) => {
        return {
          query: key,
          result: value as MatchedQueryResult,
          variables: this.extractVariables(key),
        }
      })
  }

  /**
   * Reads and returns data from the Apollo cache based on the provided document and variables.
   * @param document - The GraphQL document to read data for.
   * @param variables - Optional variables for the query.
   * @returns The query result from the cache.
   */
  protected readQueryCache(document: DocumentNode, variables?: Record<string, any>) {
    return this.client.cache.readQuery<{ [key: string]: MatchedQueryResult }>({
      query: document,
      variables,
    })
  }
}
