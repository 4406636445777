import React from 'react'
import { useIntl } from 'react-intl'
import { displayToast } from 'util/toasts'
import PlasmicCustomerNotifications, { DefaultCustomerNotificationsProps } from 'plasmic/plasmic/solace_components/PlasmicCustomerNotifications'
import PlasmicNotificationRow from 'plasmic/plasmic/solace_components/PlasmicNotificationRow'
import { omit } from 'lodash'
import * as GQL from 'generated/graphql'
import { IDrawerState } from '../pages/SettingsCustomerNotifications'
import CacheConfigs from 'util/cacheConfig'
import CornerLoader from 'components/Loader/CornerLoader'

interface Props extends DefaultCustomerNotificationsProps {
  setDrawerState: (state: IDrawerState) => void
}

const CustomerNotifications: React.FC<Props> = props => {
  const intl = useIntl()
  const t = intl.formatMessage

  const { data: resourceData, loading: loadingResources } = GQL.useAllCustomerNotificationResources(CacheConfigs.ACCURATE_ONCE)
  const resources = (resourceData?.allCustomerNotificationResources as GQL.NotificationResourceNode[]) || []

  const { data: customNotificationsData, loading: loadingNotifications } = GQL.useAllCustomNotifications(CacheConfigs.ACCURATE_ONCE)
  const customNotifications = (customNotificationsData?.allCustomNotifications as GQL.CustomNotificationNode[]) || []

  const [patchCustomNotification] = GQL.usePatchCustomNotification({
    refetchQueries: ['AllCustomNotifications'],
    onCompleted: () => {
      displayToast(t({ id: 'settings.customer-notifications.edit.success' }), 'success')
    },
    onError: () => {
      displayToast(t({ id: 'settings.customer-notifications.edit.error' }), 'error')
    },
  })

  const getCustomNotification = (resource: GQL.NotificationResourceNode) => {
    return customNotifications.find(value => value.resource.id === resource.id)
  }

  const toggleActive = (event: boolean, resource: GQL.NotificationResourceNode) => {
    const customNotification = getCustomNotification(resource)
    if (!customNotification) return props.setDrawerState({ resource: resource })
    patchCustomNotification({
      variables: {
        id: customNotification.id,
        input: {
          isActive: event,
        },
      },
    })
  }

  return (
    <React.Fragment>
      {(loadingNotifications || loadingResources) && <CornerLoader size={50} />}
      <PlasmicCustomerNotifications
        notifications={resources.map(resource => (
          <PlasmicNotificationRow
            key={resource.id}
            title={resource?.title}
            description={resource?.description}
            checkbox={{
              onChange: event => toggleActive(event, resource),
              isChecked: getCustomNotification(resource)?.isActive || false,
              'aria-label': 'toggleActive',
            }}
            btnEditMessage={{
              props: { onClick: () => props.setDrawerState({ resource: resource, customNotification: getCustomNotification(resource) }) },
            }}
          />
        ))}
        {...omit(props, 'setDrawerState')}
      />
    </React.Fragment>
  )
}

export default CustomerNotifications
