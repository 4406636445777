// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type NoisyDatasvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function NoisyDatasvgIcon(props: NoisyDatasvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 40.608 27.52"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M38.772 11.941a2.06 2.06 0 00-2.268 1.824c-.597 5.495-3.635 9.639-7.065 9.639-3.431 0-6.469-4.145-7.066-9.641-.008-.071-.029-.137-.043-.205.001-.082.007-.162-.002-.246C21.491 5.599 16.798 0 11.17 0 5.545 0 .851 5.598.012 13.31a2.057 2.057 0 001.823 2.269 2.052 2.052 0 002.269-1.823c.598-5.496 3.636-9.64 7.066-9.64 3.431 0 6.469 4.145 7.065 9.641.008.073.028.142.043.212-.001.079-.007.157.002.238.838 7.714 5.531 13.313 11.158 13.313s10.319-5.598 11.157-13.311a2.056 2.056 0 00-1.823-2.268z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default NoisyDatasvgIcon;
/* prettier-ignore-end */
