import * as React from 'react'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

import { PlasmicChartSensorTab, DefaultChartSensorTabProps } from './plasmic/solace_components/PlasmicChartSensorTab'

export interface ChartSensorTabProps extends DefaultChartSensorTabProps {
  onClick: () => void
}

function ChartSensorTab_(props: ChartSensorTabProps, ref: HTMLElementRefOf<'div'>) {
  return <PlasmicChartSensorTab sensor={{ ref }} {...props} />
}

const ChartSensorTab = React.forwardRef(ChartSensorTab_)
export default ChartSensorTab
