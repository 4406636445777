// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type OrderStatus02SvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function OrderStatus02SvgIcon(props: OrderStatus02SvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 113.659 113.699"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M98.25 113.699H15.413C6.898 113.699 0 106.797 0 98.286V46.3c0-4.865 2.296-9.444 6.198-12.355L47.616 3.057a15.413 15.413 0 0118.427 0l41.418 30.888a15.408 15.408 0 016.198 12.355v51.986c0 8.511-6.898 15.413-15.409 15.413z"
        }
      ></path>
    </svg>
  );
}

export default OrderStatus02SvgIcon;
/* prettier-ignore-end */
