import styled from 'styled-components'

const TableHeaderCell = styled.th`
  text-transform: uppercase;
  font-size: 12px;
  color: ${props => props.theme.colors.black};
  height: 60px;
  padding: 0 25px;
  font-weight: 600;

  text-align: ${props => props.textAlign || 'left'};
`

export default TableHeaderCell
