import ReactDOM from 'react-dom'
import React, { useState, useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useClickOutside } from 'util/hooks'

interface IDialogWrapperProps {
  offsets: {
    top: number
    left: number
  }
  open?: boolean
  showCaret?: boolean
  position?: string
}

const DialogWrapper = styled.div<IDialogWrapperProps>`
  position: absolute;
  top: ${props => props.offsets.top}px;
  left: ${props => props.offsets.left}px;

  z-index: 5002;

  display: ${props => (props.open ? 'block' : 'none')};
  box-sizing: border-box;

  grid-auto-flow: row;
  grid-gap: 0.5rem;

  box-sizing: border-box;
  border: none;

  z-index: 5001;

  ${props =>
    props.showCaret &&
    props.position === 'top' &&
    `
    &:after{ 
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: calc(100% - 1px);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid ${props.theme.colors.primaryLight};
      clear: both;
      z-index: 5002;
    }
  `}

  ${props =>
    props.showCaret &&
    props.position === 'bottom' &&
    `
    &:after{ 
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: calc(100% - 1px);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid ${props.theme.colors.primaryLight};
      clear: both;
      z-index: 5002;
    }
  `}
`

const OFFSET = 2

function getVerticalAxisAlignTopOffset(anchor: Element, content: Element, align?: string): number {
  const anchorRect = anchor.getBoundingClientRect()
  const currentRect = content?.getBoundingClientRect() || { left: 0, width: 0 }

  switch (align) {
    case 'innerStart':
      // Simply align the content with the anchors top side
      return anchorRect.top
    case 'outerStart':
      // Align the right side of the content with the anchors left side
      return anchorRect.top - currentRect.height
    case 'innerEnd':
      // Align the right side of the content with the anchors left side.
      // the offset is the difference between the widths.
      return anchorRect.top + (anchorRect.height / 2 - currentRect.height / 2)
    case 'outerEnd':
      // Move all the way to the other side of anchorRect
      return anchorRect.top + anchorRect.height
    default:
    case 'center':
      return anchorRect.top + anchorRect.height / 2
  }
}

function getHorizontalAlignLeftOffset(anchor: Element, content: Element, align?: string): number {
  const anchorRect = anchor.getBoundingClientRect()
  const currentRect = content?.getBoundingClientRect() || { left: 0, width: 0 }

  switch (align) {
    case 'innerStart':
      // Simply align the content with the anchors left side
      return anchorRect.left
    case 'outerStart':
      // Align the right side of the content with the anchors left side
      return anchorRect.left - currentRect.width
    case 'innerEnd':
      // Align the right side of the content with the anchors left side.
      // the offset is the difference between the widths.
      return anchorRect.left + (anchorRect.width - currentRect.width)
    case 'outerEnd':
      // Move all the way to the other side of anchorRect
      return anchorRect.left + anchorRect.width
    default:
    case 'center':
      return anchorRect.left + (anchorRect.width / 2 - currentRect.width / 2)
  }
}

function getTopOffset(anchor: Element, content: Element, position?: string, align?: string): number {
  const anchorRect = anchor.getBoundingClientRect()

  switch (position) {
    case 'right':
      return getVerticalAxisAlignTopOffset(anchor, content, align)
    case 'top':
      // Position at the anchorRect top, then backtrack the entire wrapperRect height
      return anchorRect.top - OFFSET
    case 'left':
      return getVerticalAxisAlignTopOffset(anchor, content, align)
    default:
    case 'bottom':
      // Position below the anchorRect
      return anchorRect.top + anchorRect.height + OFFSET
  }
}

function getLeftOffset(anchor: Element, content: Element, position?: string, align?: string): number {
  const anchorRect = anchor.getBoundingClientRect()

  switch (position) {
    case 'right':
      return anchorRect.left + anchorRect.width + OFFSET
    case 'top':
      return getHorizontalAlignLeftOffset(anchor, content, align)
    case 'left':
      return anchorRect.left - OFFSET
    default:
    case 'bottom':
      return getHorizontalAlignLeftOffset(anchor, content, align)
  }
}

function resolvePosition(anchor: Element, content: Element, preferPosition: string): string {
  const anchorRect = anchor.getBoundingClientRect()

  // We don't have any content. Simply return the preferred position
  if (!content) {
    return preferPosition
  }

  const contentWidth = content.scrollWidth
  const contentHeight = content.scrollHeight

  switch (preferPosition) {
    case 'right':
      // Check if we are overflowing the page
      return anchorRect.left + contentWidth > document.documentElement.clientWidth ? 'left' : 'right'
    case 'left':
      // Check if we are overflowing 0
      return anchorRect.right - contentWidth < 0 ? 'right' : 'left'
    case 'bottom':
      // Check if we are overflowing the page
      return anchorRect.bottom + contentHeight > document.documentElement.clientHeight ? 'top' : 'bottom'
    case 'top':
      // Check if we are overflowing 0
      return anchorRect.left - contentWidth < 0 ? 'bottom' : 'top'
    default:
      throw Error(`${preferPosition} is not a valid preferred position.`)
  }
}

function resolveAlign(resolvedPosition: string): string {
  switch (resolvedPosition) {
    case 'right':
      return 'innerEnd'
    case 'left':
      return 'innerEnd'
    case 'bottom':
      return 'innerStart'
    case 'top':
      return 'innerStart'
    default:
      throw Error(`${resolvedPosition} is not a valid resolvedPosition position.`)
  }
}

interface IDialogProps {
  anchor?: React.MutableRefObject<HTMLElement | null> | string
  children?: React.ReactNode | React.ReactNode[]
  preferPosition?: 'top' | 'left' | 'bottom' | 'right'
  position?: 'top' | 'left' | 'bottom' | 'right'
  align?: 'innerStart' | 'outerStart' | 'innerEnd' | 'outerEnd' | 'center'
  open?: boolean
  showCaret?: boolean
  onClose: (event: MouseEvent | TouchEvent | KeyboardEvent) => void
}

export default function Dialog({ anchor, children, preferPosition = 'right', position, align = 'innerStart', open, showCaret = true, onClose }: IDialogProps) {
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>
  const recalculateOffset = useCallback(() => {
    if (!ref.current) {
      return { top: -5000, left: 0, position: preferPosition }
    }

    let resolvedAnchor
    // Get element either from the DOM or from the current ref
    if (typeof anchor === 'string') {
      resolvedAnchor = document.querySelector(anchor)

      if (!resolvedAnchor) {
        return { top: -5000, left: 0, position: preferPosition }
      }
    } else if (anchor && anchor.current) {
      resolvedAnchor = anchor.current
    } else {
      return { top: -5000, left: 0, position: preferPosition }
    }

    const resolvedPosition = position || resolvePosition(resolvedAnchor, ref.current, preferPosition)

    const resolvedAlign = align || resolveAlign(resolvedPosition)

    return {
      top: getTopOffset(resolvedAnchor, ref.current, resolvedPosition, resolvedAlign),
      left: getLeftOffset(resolvedAnchor, ref.current, resolvedPosition, resolvedAlign),
      position: resolvedPosition,
    }
  }, [anchor, preferPosition, align, position, ref])

  const [offsets, setOffsets] = useState(() => recalculateOffset())

  useEffect(() => {
    if (!open) {
      return
    }

    setOffsets(recalculateOffset())
  }, [recalculateOffset, open])

  useClickOutside(ref, onClose, true, open)

  return ReactDOM.createPortal(
    <DialogWrapper ref={ref} open={open} offsets={offsets} position={offsets.position} showCaret={showCaret}>
      {children}
    </DialogWrapper>,
    document.body
  )
}
