// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ScCustomersvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ScCustomersvgIcon(props: ScCustomersvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 40 41"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M19.991 18.908c5.121 0 9.272-4.151 9.272-9.272S25.112.364 19.991.364s-9.272 4.151-9.272 9.272 4.151 9.272 9.272 9.272zm0-15.441a6.17 6.17 0 11-6.169 6.169 6.205 6.205 0 016.169-6.169zm4.71 18.761h-9.415C7.007 22.235.296 28.944.285 37.223v1.862a1.552 1.552 0 003.104 0v-1.862c.007-6.567 5.331-11.888 11.898-11.891h9.415c6.572-.003 11.903 5.32 11.91 11.891v1.862a1.552 1.552 0 003.104 0v-1.862c-.012-8.284-6.73-14.995-15.015-14.995z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ScCustomersvgIcon;
/* prettier-ignore-end */
