import React from 'react'

const Truck = props => (
  <svg viewBox='0 0 22.5 13.8' {...props}>
    <path
      d='M1.6,0l12.7,0c0.9,0,1.6,0.7,1.6,1.6v1.5l3.3,0c0.2,0,0.3,0.1,0.4,0.2l2.7,3.8c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0
	c0,0.1,0,0.1,0,0.2v3.2c0,0.7-0.6,1.3-1.3,1.3H21c-0.3,1-1.2,1.7-2.2,1.7s-1.9-0.7-2.2-1.7H7.6c-0.3,1-1.2,1.7-2.2,1.7
	c-1,0-1.9-0.7-2.2-1.7H1.6c-0.9,0-1.6-0.7-1.6-1.6l0-8.9C0,0.7,0.7,0,1.6,0L1.6,0z M7.8,4.1c0,0.3,0.3,0.5,0.5,0.5h5c0,0,0,0,0,0
	c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5h-5c0,0,0,0,0,0C8,3.6,7.8,3.8,7.8,4.1z M16.3,5.1v2.5c0,0.3,0.2,0.5,0.5,0.5h3.5
	c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.3-0.5,0.1-0.7L19,4.8c-0.1-0.1-0.3-0.2-0.4-0.2h-1.7c0,0,0,0,0,0C16.5,4.6,16.3,4.8,16.3,5.1z
	 M6,5.9c-0.3,0-0.5,0.3-0.5,0.5S5.7,6.9,6,6.9h7.3c0,0,0,0,0,0c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5H6C6,5.9,6,5.9,6,5.9
	L6,5.9z M5.4,10.2c-0.7,0-1.3,0.6-1.3,1.3v0c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.5,1.3-1.2v0C6.7,10.8,6.1,10.2,5.4,10.2L5.4,10.2z
	 M17.5,11.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3C18.1,10.2,17.5,10.8,17.5,11.5z'
    />
  </svg>
)

export default Truck
