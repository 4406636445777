import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import * as GQL from 'generated/graphql'
import { useAppContext, useCustomerContext } from 'util/hooks'
import { NoHits, formatPrice, getRowColor } from '../util'
import EstimatedEmptyNew from 'components/Table/Cells/EstimatedEmpty'
import FlexTable from 'plasmic/FlexTable'
import StockCell from 'components/Table/Cells/StockCell'
import TableHeadTitle from 'plasmic/TableHeadTitle'
import TableRow from 'plasmic/TableRow'
import CellCustomer from 'plasmic/CellCustomer'
import CellEstimatedEmpty from 'plasmic/CellEstimatedEmpty'
import CellInventory from 'plasmic/CellInventory'
import CellStatus from 'plasmic/CellStatus'
import CellProduct from 'plasmic/CellProduct'
import CellPhoneNumber from 'plasmic/CellPhoneNumber'
import CylinderGroupCylinderSetupCell from 'components/Table/Cells/CylinderGroupCylinderSetupCell'
import LabelStatusChip from 'plasmic/LabelStatusChip'
import CellPrice from 'plasmic/CellPrice'
import CellOrderMethod from 'plasmic/CellOrderMethod'
import {
  ORDER_METHOD_COLORS,
  ORDER_METHOD_TRANSLATIONS,
  PRODUCT_SORT_KEY,
  PHONE_NUMBER_SORT_KEY,
  ORDER_METHOD_SORT_KEY,
  PRICE_SORT_KEY,
  ESTIMATED_EMPTY_SORT_KEY,
} from 'modules/customers/consts'
import { LightningIcon, StyledOrderMethodButton } from 'modules/customers/CustomersTable'
import Tooltip from 'components/Tooltip/Tooltip'
import CellPayment from 'plasmic/CellPayment'
import ScrollIndicator from 'plasmic/ScrollIndicator'
import { getCylinderGroupAlerts } from './util'
import { CenteredLoader } from 'components/Loader/LoaderWrappers'
import Loader from 'components/Loader'
import { CustomerDrawerListSource } from 'context/CustomerContext'

interface IReadyToRefillTableProps {
  cylinderGroups: GQL.CylinderGroupNode[]
  totalCount: number
  loadMore: () => void
  setLastMovedRowId: (lastMovedRowId?: string) => void
  loading: boolean
  loadingFetchMore: boolean
  onOrderStatusClick: (event: React.MouseEvent<HTMLButtonElement>, fromElement: GQL.CylinderGroupOrderNode | GQL.CylinderGroupNode) => void
  onSortClick: (sortKey: string) => void
  getSortIcon: (sortKey: string) => 'upActive' | 'downActive' | undefined
}

export default function ReadyToRefillTable({
  cylinderGroups,
  totalCount,
  loadMore,
  loading,
  loadingFetchMore,
  onOrderStatusClick,
  onSortClick,
  getSortIcon,
}: IReadyToRefillTableProps) {
  const intl = useIntl()

  const t = intl.formatMessage
  const navigate = useNavigate()

  const [expanded, setExpanded] = useState<boolean>(true)
  const { appContext } = useAppContext()
  const distributor = appContext.distributor

  const { setSource, setCustomersContext } = useCustomerContext()

  let visibleColumns = ['status', 'estimatedEmpty', 'inventory', 'phoneNumber', 'product', 'price', 'payment', 'orderMethod']
  if (!distributor?.stockEnabled) {
    visibleColumns = visibleColumns.filter(column => column !== 'inventory')
  }

  return (
    <>
      <FlexTable
        id='ready-to-refill-table'
        data-testid='ready-to-refill-table'
        noCheckbox
        collapsed={!expanded}
        headProduct={{ onClick: () => onSortClick(PRODUCT_SORT_KEY), sorting: getSortIcon(PRODUCT_SORT_KEY) }}
        headPhoneNumber={{ onClick: () => onSortClick(PHONE_NUMBER_SORT_KEY), sorting: getSortIcon(PHONE_NUMBER_SORT_KEY) }}
        headOrderMethod={{ onClick: () => onSortClick(ORDER_METHOD_SORT_KEY), sorting: getSortIcon(ORDER_METHOD_SORT_KEY) }}
        headPrice={{ onClick: () => onSortClick(PRICE_SORT_KEY), sorting: getSortIcon(PRICE_SORT_KEY) }}
        headEstimatedEmpty={{ onClick: () => onSortClick(ESTIMATED_EMPTY_SORT_KEY), sorting: getSortIcon(ESTIMATED_EMPTY_SORT_KEY) }}
        headPayment={{ hideSorting: true }}
        headStatus={{ hideSorting: true }}
        children={
          <TableHeadTitle
            style={{ cursor: 'pointer' }}
            onClick={() => setExpanded(old => !old)}
            color='orange'
            title='Ready to refill'
            count={`(${totalCount || cylinderGroups.length})`}
          />
        }
        rows={
          <>
            {cylinderGroups.map(cylinderGroup => (
              <TableRow
                noCheckbox
                color={getRowColor(appContext, 'orange', getCylinderGroupAlerts(cylinderGroup))}
                data-testid='order-row'
                key={cylinderGroup.id + 'ReadyToRefill'}
                sticky={
                  <CellCustomer
                    alerts={getCylinderGroupAlerts(cylinderGroup)}
                    temperature={cylinderGroup.customer.temperatureGroup?.temperature?.toString()}
                    onClick={() => {
                      setSource(CustomerDrawerListSource.READY_TO_REFILL)
                      setCustomersContext(cylinderGroups.map(cg => cg.customer))
                      navigate(`?customer=${cylinderGroup?.customer.id}`)
                    }}
                    name={cylinderGroup?.customer.name}
                  />
                }
                scrollCells={
                  <>
                    <CellProduct content={<CylinderGroupCylinderSetupCell key={cylinderGroup.id + 'cylinderSetup'} cylinderGroup={cylinderGroup} />} />
                    <CellPrice
                      price={formatPrice(cylinderGroup.estimatedOrderPrice?.price || 0, cylinderGroup.estimatedOrderPrice?.currency || GQL.Currency.Usd)}
                    />
                    <CellPayment content={'-'} />
                    <CellStatus fromElement={cylinderGroup} onOrderStatusClick={onOrderStatusClick} editable />
                    <CellEstimatedEmpty content={<EstimatedEmptyNew cylinderGroup={cylinderGroup} displayThreshold={false} />} />
                    {visibleColumns.includes('inventory') && <CellInventory content={<StockCell cylinderGroup={cylinderGroup} />} />}
                    <CellPhoneNumber content={cylinderGroup.customer.phoneNumber || '-'} />
                    <CellOrderMethod
                      content={
                        <StyledOrderMethodButton color={ORDER_METHOD_COLORS[cylinderGroup.customer.orderMethod || 'APP']}>
                          <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {t({ id: ORDER_METHOD_TRANSLATIONS[cylinderGroup.customer.orderMethod || 'APP'] })}
                          </div>
                          {cylinderGroup.threshold && (
                            <Tooltip
                              content={
                                <span>
                                  Automatic trigger set to: "{cylinderGroup.threshold.name}"<br />
                                  Order will be placed when there is remaining {cylinderGroup.threshold.threshold}
                                  {cylinderGroup.threshold.unit}
                                </span>
                              }
                              delay={0}
                              arrow={false}
                              placement='top'
                            >
                              <LightningIcon />
                            </Tooltip>
                          )}
                        </StyledOrderMethodButton>
                      }
                    />
                  </>
                }
              />
            ))}
            {!loading && cylinderGroups.length === 0 && (
              <NoHits>
                <LabelStatusChip icon='check' title='No ready to refill sites' />
              </NoHits>
            )}
            {loading && (
              <CenteredLoader sizeAuto>
                <Loader color='gray6' size={50} />
              </CenteredLoader>
            )}
          </>
        }
        visibleColumns={visibleColumns}
      />
      {!loading && cylinderGroups.length > 0 && (
        <ScrollIndicator
          tableRow
          loaded={cylinderGroups.length}
          total={totalCount}
          btnLoadMore={{ ...(loadingFetchMore && { children: <Loader color='white' /> }), onClick: loadMore }}
        />
      )}
    </>
  )
}
