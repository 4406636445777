import React from 'react'
import ModalViewOrder from 'plasmic/ModalViewOrder'
import * as GQL from 'generated/graphql'
import Modal from 'components/Modal/Modal'
import OrderInfoExtended from 'plasmic/OrderInfoExtended'

interface Props {
  orders: GQL.Maybe<GQL.ReviewableCylinderGroupOrderNode>[]
  isOpen: boolean
  onRequestClose: () => void
}

const ViewContactSignupOrderModal: React.FC<Props> = props => {
  return (
    <Modal
      stripped
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      overlayStyle={{ alignItems: 'flex-start', overflow: 'auto', padding: '2rem 0rem' }}
    >
      <ModalViewOrder
        btnClose={{ onClick: () => props.onRequestClose() }}
        orderCard={
          <>
            {props.orders.map(order => (
              <OrderInfoExtended data-testid='order-info' order={(order as GQL.ReviewableCylinderGroupOrderNode) || undefined} />
            ))}
          </>
        }
      />
    </Modal>
  )
}

export default ViewContactSignupOrderModal
