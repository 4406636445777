import * as GQL from 'generated/graphql'
import 'react-intl-tel-input/dist/main.css'
import ContentSensorsPriv from 'plasmic/ContentSensorsPriv'
import SensorCard from 'plasmic/SensorCard'
import { format, parseISO } from 'date-fns'
import { getUrl, registerSensorAppUrl } from 'util/env'
import { getSensorStatus, updateSensorCacheOnPatch } from '../util'
import { TutorialInfoIcon } from 'components/Tooltip/TutorialTooltip'
import { useIntl } from 'react-intl'
import SensorEmptyState from '../../../plasmic/SensorEmptyState'
import { displayToast } from 'util/toasts'
import { useApolloClient } from '@apollo/client'

interface ContactsProps {
  cylinderGroup: GQL.CylinderGroupNode
}

const Sensors = ({ cylinderGroup }: ContactsProps) => {
  const intl = useIntl()
  const t = intl.formatMessage

  const client = useApolloClient()

  const [patchSensor, { loading: patchSensorLoading }] = GQL.usePatchSensor({
    onCompleted: response => {
      if (response.patchSensor?.ok) {
        displayToast(t({ id: 'customers.cylinders.sensor.state.success.generic' }), 'success')
      } else {
        displayToast(t({ id: 'customers.cylinders.sensor.state.error.generic' }))
      }
    },
    onError: () => {
      displayToast(t({ id: 'customers.cylinders.sensor.state.error.generic' }))
    },
  })

  const getSensorModel = (sensor: GQL.SensorNode) => {
    if (sensor?.model) {
      return sensor.model.name
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase()
        .slice(0, 4) as 'a910' | 'a901' | 'a900'
    }
    return undefined
  }

  const handleRedirectRegister = () => {
    window.open(registerSensorAppUrl(), '_blank')
  }

  const sensors =
    cylinderGroup.cylinderSides
      ?.filter(e => e?.sensors && e?.sensors.length > 0)
      .map(e => e?.sensors)
      .flat() || []
  return (
    <>
      <ContentSensorsPriv
        helpText={<TutorialInfoIcon content={t({ id: 'tooltips.customers.drawer.tabs.sensors' })} />}
        sensorsComponent={
          <>
            {sensors?.map(sensor => {
              if (!sensor) return <></>
              const sensorSide = cylinderGroup.cylinderSides?.find(s => s?.sensors?.includes(sensor))
              const cycle = sensorSide?.activeCycle
              const installedAt = sensor?.installedAt ? parseISO(sensor.installedAt) : null
              const status = getSensorStatus(sensor, cycle)

              return (
                <SensorCard
                  model={getSensorModel(sensor)}
                  key={sensor.id}
                  sensorId={sensor.serialNumber}
                  sensorModel={sensor.model?.name}
                  sensorActivatedDate={installedAt ? format(installedAt, 'd. LLL yyyy') : 'No installation date'}
                  sensorStatus={status}
                  progressDone
                  btnDeactivateSensor={{
                    onClick: () => {
                      window.open(
                        `https://register.${getUrl()}/sensors?id=${sensor.serialNumber}`,
                        'MyNewWindow',
                        'menubar=0,status=0,toolbar=yes,scrollbars=yes,resizable=yes,width=500,height=800'
                      )
                    },
                  }}
                  btnMoveSensor={{
                    onClick: () => {
                      window.open(`https://register.${getUrl()}/sensors?id=${sensor.serialNumber}`, 'MyNewWindow', 'menubar=0,status=0,width=500,height=800')
                    },
                  }}
                  btnTurnOffSensor={{
                    disabled: patchSensorLoading,
                    title: sensor?.status === GQL.SensorStatus.Off ? t({ id: 'common.set-turned-on' }) : t({ id: 'common.set-turned-off' }),
                    onClick: () => {
                      const toState = sensor.status === GQL.SensorStatus.Off ? GQL.SensorStatus.On : GQL.SensorStatus.Off
                      patchSensor({
                        variables: {
                          id: sensor.id,
                          input: {
                            status: toState,
                          },
                        },
                        update: () => {
                          updateSensorCacheOnPatch(sensor, client, cylinderGroup)
                        },
                      })
                    },
                  }}
                />
              )
            }) || 'No sensors'}
            {sensors.length < 2 && (
              <SensorEmptyState
                secondSensor={sensors.length === 1}
                btnRegisterSensor={{
                  onClick: handleRedirectRegister,
                }}
              />
            )}
          </>
        }
      />
    </>
  )
}
export default Sensors
