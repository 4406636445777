import styled from 'styled-components'

const TableCell = styled.td`
  border: none;
  padding: 0 25px;
  height: 60px;

  ${props => props.theme.layout.mobile} {
    height: 45px;
    padding: 0 15px;
  }
`

export default TableCell
