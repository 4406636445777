import { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'

import DepotModal from '../components/DepotModal'
import * as GQL from 'generated/graphql'
import CacheConfigs from 'util/cacheConfig'
import Depots from 'plasmic/Depots'
import DepotCard from 'plasmic/DepotCard'
import ActionDropdownLine from 'plasmic/ActionDropdownLine'
import { useAppContext, useClickOutside } from 'util/hooks'
import SettingsContentWrapper from 'plasmic/SettingsContentWrapper'
import ButtonFill from 'plasmic/ButtonFill'

export default function SettingsDepots() {
  const [depotModalOpen, setDepotModalOpen] = useState(false)
  const [dropdownDepot, setDropdownDepot] = useState('')
  const [selectedDepot, setSelectedDepot] = useState<GQL.DepotNode>()

  const moreActionsRef = useRef<HTMLElement>(null)
  const client = useApolloClient()
  const { setAppContext } = useAppContext()
  const navigate = useNavigate()

  const intl = useIntl()
  const t = intl.formatMessage

  useClickOutside(moreActionsRef, () => setDropdownDepot(''))

  const { data } = GQL.useAllDepots(CacheConfigs.ACCURATE_ONCE)
  const depots = data?.allDepots?.edges.map(edge => edge?.node as GQL.DepotNode) || []

  const handleNavigateVehiclesDrivers = (reviewedDepot: GQL.DepotNode) => {
    setAppContext(context => ({ ...context, depot: reviewedDepot }))
    localStorage.setItem('depot', reviewedDepot.id)
    client.resetStore().catch(error => {
      console.log('Store reset rejected!', error)
    })

    navigate('/deliveries/vehicles')
  }

  return (
    <SettingsContentWrapper
      settingsTitle={t({ id: 'settings.depots' })}
      headerButtons={<ButtonFill rounded color={'blue'} label={t({ id: 'depots.create-depot' })} onClick={() => setDepotModalOpen(true)} />}
      content={
        <>
          <Depots
            depots={depots?.map(depot => (
              <DepotCard
                data-testid='depot'
                key={depot.id}
                depotTitle={depot.name}
                depotId={depot.customIdentifier || '-'}
                depotAddress={depot.address.firstLine}
                depotVehicles={`${depot.vehicles?.length}` || '0'}
                depotDrivers={`${depot.drivers?.length}` || '0'}
                depotContactPerson={depot.contactPerson?.fullName}
                btnMoreActions={{
                  ref: depot.id === dropdownDepot ? moreActionsRef : null,
                  open: depot.id === dropdownDepot,
                  actionDropdown: (
                    <ActionDropdownLine
                      children={t({ id: 'depots.edit-depot' })}
                      onClick={() => {
                        setDepotModalOpen(true)
                        setSelectedDepot(depot)
                        setDropdownDepot('')
                      }}
                    />
                  ),
                  onClick: () => setDropdownDepot(dropdownDepot ? '' : depot.id),
                }}
                mapDepotDiv={
                  <div
                    onClick={() =>
                      window.open(`https://www.google.com/maps/search/?api=1&query=${depot.address.latitude},${depot.address.longitude}`, '_blank')
                    }
                    style={{
                      cursor: 'pointer',
                      width: '100%',
                      height: '215px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '1.5rem',
                      color: 'rgb(255, 143, 143)',
                      backgroundImage: `url('https://maps.googleapis.com/maps/api/staticmap?size=800x300&zoom=15&scale=2&map_id=7c94979620d924a7&key=AIzaSyBtD0cwYlaVLkqfRT1MPc22j0j0vv51wag&markers=size:normal%7Ccolor:red%7C${depot.address.latitude},${depot.address.longitude}')`,
                      backgroundSize: '100% auto',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                    }}
                  >
                    {(!depot.address.latitude || !depot.address.longitude) && t({ id: 'common.google-maps-preview-error' })}
                  </div>
                }
                btnEditVehiclesDrivers={{
                  props: {
                    onClick: () => handleNavigateVehiclesDrivers(depot),
                  },
                }}
              />
            ))}
          />
          {depotModalOpen && (
            <DepotModal
              isOpen={depotModalOpen}
              onRequestClose={() => {
                setDepotModalOpen(false)
                setSelectedDepot(undefined)
              }}
              depot={selectedDepot}
            />
          )}
        </>
      }
    />
  )
}
