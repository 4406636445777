import { Calendar } from 'react-date-range'
import { useIntl } from 'react-intl'
import { format, getDay } from 'date-fns'

import ModalChangeDate from 'plasmic/ModalChangeDate'
import Modal from 'components/Modal/Modal'
import 'react-date-range/dist/styles.css'
import 'components/DatePicker/CalendarReactTheme.css'
import { AlertMessage } from 'components/DatePicker/DatePickerCalendar'
import circleCrosssvg from 'plasmic/plasmic/solace_components/images/circleCrosssvg.svg'

interface MoveRouteModalProps {
  onClose: () => void
  selectedDate: Date
  setSelectedDate: (date: Date) => void
  onSubmitChangeDate: () => void
  closedDays: (0 | 2 | 1 | 3 | 4 | 5 | 6)[]
}

export default function MoveRouteModal({ selectedDate, setSelectedDate, onSubmitChangeDate, closedDays, onClose }: MoveRouteModalProps) {
  const intl = useIntl()
  const t = intl.formatMessage

  const selectionIncludesClosedDays = () => {
    if (closedDays.length === 0) {
      return false
    }
    return closedDays.includes(getDay(selectedDate))
  }

  const handleCustomDayContent = (date: Date) => {
    if (closedDays?.includes(getDay(date))) {
      const moreInfo = (
        <>
          <div className='date-info-red-dot' />
          <div className='date-tooltip'>{t({ id: 'common.closed-for-delivery' })}</div>
        </>
      )
      return (
        <div>
          {moreInfo && <div className='date-info'>{moreInfo}</div>}
          <span>{format(date, 'd')}</span>
        </div>
      )
    }
  }

  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      stripped
      contentStyle={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      overlayStyle={{ alignItems: 'flex-start', overflow: 'auto', padding: '2rem 0rem' }}
    >
      <ModalChangeDate
        alertTitle={t({ id: 'routes.move-route.info-title' })}
        alertInfo={t({ id: 'routes.move-route.info-body' })}
        btnChangeDate={{
          props: {
            name: 'submitMoveRoute',
            onClick: () => {
              onSubmitChangeDate()
              onClose()
            },
          },
        }}
        calendarDiv={
          <>
            <Calendar
              dayContentRenderer={handleCustomDayContent}
              className='rdrMonthCalendar'
              minDate={new Date()}
              date={selectedDate}
              onChange={date => setSelectedDate(date)}
            />
            {selectionIncludesClosedDays() ? <AlertMessage icon={circleCrosssvg} message={t({ id: 'routes.move-route.alert-closed-days' })} /> : null}
          </>
        }
        btnClose={{
          props: {
            onClick: () => onClose(),
          },
        }}
      />
    </Modal>
  )
}
