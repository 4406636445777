import { toast } from 'react-toastify'
import Toast from 'plasmic/Toast'
import clearCache from './misc'

/**
 * @version v1.1
 * @description a function to show toast messages.
 * @param {String} info a string which is shown on a toast message
 * @param {'error' | 'info' | 'success' | 'warning' | 'refresh' | undefined} type a type of toast to display. If undefined 'error' will be used.
 * @param {Object} options is an object of react-toastify options. Overwritting the toastId to null will make duplicates of same message possible.
 * Other options are: autoClose, closeOnClick, position, style etc.
 * @param {boolean} noClose pass true if you do not want a close button to display in the toast
 */
export const displayToast = (
  info: string,
  type?: 'error' | 'info' | 'success' | 'warning' | 'refresh' | 'confirm' | undefined,
  options?: object,
  noClose?: boolean,
  onCancel?: () => void,
  onConfirm?: () => void
) => {
  toast(
    ({ closeToast }) => (
      <Toast
        btnCloseToast={{
          onClick: closeToast,
          ...(noClose && { style: { visibility: 'hidden' } }),
        }}
        btnRefresh={{
          onClick: () => clearCache().then(() => window.location.reload()),
        }}
        btnCancel={{
          onClick: () => {
            if (onCancel) {
              onCancel()
            }
            if (closeToast) {
              closeToast()
            }
          },
        }}
        btnConfirm={{
          onClick: () => {
            if (onConfirm) {
              onConfirm()
            }
            if (closeToast) {
              closeToast()
            }
          },
        }}
        type={type || 'error'}
        info={info}
      />
    ),
    { toastId: info, ...options, draggable: !noClose }
  )
}
