import React, { SyntheticEvent, RefObject } from 'react'
import styled from 'styled-components'

interface IStyledFormProps {
  width?: string
}

const StyledForm = styled.form<IStyledFormProps>`
  ${props =>
    props.width &&
    `
    width: ${props.width};
  `}
`

interface IFormProps {
  id?: string
  preventDefault?: boolean
  onSubmit: (event: SyntheticEvent) => any
  outerRef?: RefObject<HTMLFormElement>
  width?: string
  autoComplete?: string
  children: React.ReactNode
}

const Form: React.FC<IFormProps> = ({ preventDefault, onSubmit, children, outerRef, width, autoComplete, ...props }) => {
  return (
    <StyledForm
      ref={outerRef}
      onSubmit={event => {
        if (preventDefault) event.preventDefault()
        onSubmit(event)
      }}
      width={width}
      // autoComplete={autoComplete}
      {...props}
    >
      {children}
    </StyledForm>
  )
}

export default Form
