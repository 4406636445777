// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type StatusIconTimeCapacitysvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function StatusIconTimeCapacitysvgIcon(
  props: StatusIconTimeCapacitysvgIconProps
) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 41 49"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <path
          d={
            "M20.5.35c-11 0-20 9-20 20 0 5.3 2.1 10.1 5.4 13.6l14.6 14.7 14.6-14.6c3.3-3.6 5.4-8.4 5.4-13.7 0-11-9-20-20-20zm0 36c-8.837 0-16-7.163-16-16s7.163-16 16-16 16 7.163 16 16-7.163 16-16 16z"
          }
        ></path>

        <path
          d={
            "M21.611 20.47c0-.817 1.046-1.538 1.571-1.962 1.373-1.013 2.354-2.874 2.549-4.965.032-.426-.228-.879-.555-.879h-9.353c-.327 0-.587.452-.556.879.196 2.092 1.178 3.922 2.55 4.966.522.393 1.571 1.129 1.571 1.946 0 .783-1.049 1.511-1.571 1.936-1.373 1.046-2.354 2.789-2.55 4.881-.032.425.228.764.556.764h9.32c.359 0 .62-.334.555-.759-.195-2.092-1.175-3.86-2.549-4.907-.523-.393-1.538-1.083-1.538-1.9z"
          }
        ></path>
      </g>
    </svg>
  );
}

export default StatusIconTimeCapacitysvgIcon;
/* prettier-ignore-end */
