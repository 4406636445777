import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import * as GQL from 'generated/graphql'
import { useAppContext, useClickOutside } from 'util/hooks'
import CornerLoader from 'components/Loader/CornerLoader'
import SettingsContentWrapper from 'plasmic/SettingsContentWrapper'
import ProductCatalogProduct from 'plasmic/ProductCatalogProduct'
import ActionDropdownLine from '../../../plasmic/ActionDropdownLine'
import LabelStatusChip from 'plasmic/LabelStatusChip'
import ProductsSettings from 'plasmic/ProductsSettings'
import ChangeProductStatusModal from '../components/ChangeProductStatusModal'
import ServiceModal from '../components/ServiceModal'
import ChangeProductVisibilityModal from '../components/ChangeProductVisibilityModal'
import ButtonFill from 'plasmic/ButtonFill'
import { formatTaxRate } from 'modules/orders/util'
import ValueChip from 'plasmic/ValueChip'

const SettingsServices: React.FunctionComponent = () => {
  const [actionsOpen, setActionsOpen] = React.useState('')
  const [createServiceOpen, setCreateServiceOpen] = useState(false)
  const [editService, setEditService] = useState<GQL.ProductNode>()
  const [changeServiceStatus, setChangeServiceStatus] = useState<GQL.ProductNode>()
  const [changeServiceVisibility, setChangeServiceVisibility] = useState<GQL.ProductNode>()

  const { appContext } = useAppContext()
  const ref = useRef(null)
  const intl = useIntl()
  const t = intl.formatMessage

  useClickOutside(ref, () => setActionsOpen(''), false, true)

  const { loading, data } = GQL.useAllProducts({ variables: { service: true } })

  const availableServices = useMemo(() => data?.availableProducts?.edges.map(service => service?.node as GQL.ProductNode), [data])
  const unavailableServices = useMemo(() => data?.unavailableProducts?.edges.map(service => service?.node as GQL.ProductNode), [data])

  const getPrice = (price: number) => {
    const formatter = new Intl.NumberFormat(navigator.language || 'en-GB', {
      style: 'currency',
      currency: appContext.distributor?.defaultCurrency || 'USD',
      currencyDisplay: 'symbol',
      maximumFractionDigits: 2,
    })

    return formatter.format(price)
  }

  const getProductStatus = useCallback(
    (product: GQL.ProductNode) => {
      if (product.addAutomatically) return 'autoAdd'
      if (!product.visibleToCustomer) return t({ id: 'common.hidden' }).toLowerCase()
      return t({ id: 'common.visible' }).toLowerCase()
    },
    [t]
  )

  return (
    <React.Fragment>
      <SettingsContentWrapper
        settingsTitle={t({ id: 'settings.services' })}
        headerButtons={<ButtonFill rounded color={'blue'} label={t({ id: 'settings.services.create' })} onClick={() => setCreateServiceOpen(true)} />}
        content={
          <React.Fragment>
            {loading && <CornerLoader size={50} />}
            <ProductsSettings
              productsActive={
                availableServices && availableServices.length > 0 ? (
                  availableServices.map(service => (
                    <ProductCatalogProduct
                      noImage
                      service
                      data-testid='service'
                      key={service.id}
                      title={service.displayName}
                      description={service.description || '-'}
                      labelId={t({ id: 'settings.services.service-id' })}
                      descriptionPreview={service.description}
                      depots={
                        service.inDepots && service.inDepots.length > 0
                          ? service.inDepots.map(depot => <ValueChip key={depot?.id} title={depot?.name} />)
                          : 'All depots'
                      }
                      productId={service.customId || '-'}
                      price={getPrice(service.productPrices?.find(productPrice => productPrice?.category.default === true)?.price || 0)}
                      basePrice={getPrice(service.productPrices?.find(productPrice => productPrice?.category.default === true)?.price || 0)}
                      productStatus={{
                        props: {
                          status: getProductStatus(service),
                        },
                      }}
                      btnMoreActions={{
                        open: actionsOpen === service.id,
                        ref: actionsOpen === service.id ? ref : null,
                        onClick: () => setActionsOpen(service.id === actionsOpen ? '' : service.id),
                        actionDropdown: (
                          <>
                            <ActionDropdownLine children={t({ id: 'settings.services.edit' })} onClick={() => setEditService(service)} />
                            <ActionDropdownLine
                              children={t({ id: 'settings.services.change.status.inactive' })}
                              onClick={() => setChangeServiceStatus(service)}
                            />
                            <ActionDropdownLine
                              children={t({
                                id: service.visibleToCustomer ? 'settings.products.change.visibility.hidden' : 'settings.products.change.visibility.visible',
                              })}
                              onClick={() => setChangeServiceVisibility(service)}
                            />
                          </>
                        ),
                      }}
                      basePriceTax={formatTaxRate(service.taxRate, appContext.distributor)}
                      cylinderDepositPriceTax={service.depositTaxRate}
                    />
                  ))
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <LabelStatusChip title={t({ id: 'common.no-services' })} icon={'info'} />
                  </div>
                )
              }
              productsInactive={
                unavailableServices && unavailableServices.length > 0 ? (
                  unavailableServices.map(service => (
                    <ProductCatalogProduct
                      noImage
                      service
                      data-testid='inactive-service'
                      key={service.id}
                      title={service.displayName}
                      description={service.description}
                      labelId={t({ id: 'settings.services.service-id' })}
                      productId={service.customId || '-'}
                      price={getPrice(service.productPrices?.find(productPrice => productPrice?.category.default === true)?.price || 0)}
                      basePrice={getPrice(service.productPrices?.find(productPrice => productPrice?.category.default === true)?.price || 0)}
                      productStatus={{
                        props: {
                          status: GQL.ProductStatus.Inactive.toLowerCase(),
                        },
                      }}
                      btnMoreActions={{
                        open: actionsOpen === service.id,
                        ref: actionsOpen === service.id ? ref : null,
                        onClick: () => setActionsOpen(service.id === actionsOpen ? '' : service.id),
                        actionDropdown: (
                          <>
                            <ActionDropdownLine children={t({ id: 'settings.services.edit' })} onClick={() => setEditService(service)} />
                            <ActionDropdownLine
                              children={t({ id: 'settings.services.change.status.active' })}
                              onClick={() => setChangeServiceStatus(service)}
                            />
                            <ActionDropdownLine
                              children={t({
                                id: service.visibleToCustomer ? 'settings.products.change.visibility.hidden' : 'settings.products.change.visibility.visible',
                              })}
                              onClick={() => setChangeServiceVisibility(service)}
                            />
                          </>
                        ),
                      }}
                      basePriceTax={formatTaxRate(service.taxRate, appContext.distributor)}
                      cylinderDepositPriceTax={service.depositTaxRate}
                    />
                  ))
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <LabelStatusChip title={t({ id: 'common.no-services' })} icon={'info'} />
                  </div>
                )
              }
              labelInactiveProducts={t({ id: 'common.inactive' })}
            />
          </React.Fragment>
        }
      />
      {createServiceOpen && <ServiceModal open={createServiceOpen} onClose={() => setCreateServiceOpen(false)} />}
      {editService && <ServiceModal open={!!editService} onClose={() => setEditService(undefined)} service={editService} />}
      {changeServiceStatus && (
        <ChangeProductStatusModal open={!!changeServiceStatus} onClose={() => setChangeServiceStatus(undefined)} product={changeServiceStatus} isService />
      )}
      {changeServiceVisibility && (
        <ChangeProductVisibilityModal
          open={!!changeServiceVisibility}
          onClose={() => setChangeServiceVisibility(undefined)}
          product={changeServiceVisibility}
          isService
        />
      )}
    </React.Fragment>
  )
}

export default SettingsServices
