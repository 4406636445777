// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type InvoiceDefaultOutlinesvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function InvoiceDefaultOutlinesvgIcon(
  props: InvoiceDefaultOutlinesvgIconProps
) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 38 38"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M33 7H5a5 5 0 00-5 5v14a5 5 0 005 5h28a5 5 0 005-5V12a5 5 0 00-5-5zm4 19a4 4 0 01-4 4H5a4 4 0 01-4-4V12a4 4 0 014-4h28a4 4 0 014 4v14z"
        }
      ></path>

      <path
        d={
          "M5.344 13.36h1.01v4.743h-1.01zm2.957 1.649l2.261 3.094h.974V13.36h-1.01v2.979L8.329 13.36H7.291v4.743h1.01zm6.765 3.094l1.828-4.743h-1.152l-1.309 3.647-1.315-3.647h-1.152l1.834 4.743zm4.37.085c1.436 0 2.481-1.024 2.481-2.453s-1.046-2.453-2.481-2.453c-1.429 0-2.475 1.024-2.475 2.453s1.045 2.453 2.475 2.453zm0-4.01c.875 0 1.443.668 1.443 1.557 0 .881-.568 1.557-1.443 1.557-.875 0-1.437-.676-1.437-1.557 0-.889.562-1.557 1.437-1.557zm3.188-.818h1.01v4.743h-1.01zm4.243.818c.491 0 .918.306 1.117.689l.867-.427c-.334-.59-.939-1.159-1.984-1.159-1.422 0-2.523.995-2.523 2.453 0 1.458 1.102 2.453 2.523 2.453 1.045 0 1.643-.576 1.984-1.167l-.867-.419c-.199.391-.626.69-1.117.69-.859 0-1.486-.661-1.486-1.557s.627-1.556 1.486-1.556zm2.585 3.925h3.357v-.889h-2.347V16.14h2.297v-.889h-2.297v-1.002h2.347v-.889h-3.357zm-6.925 2.959H5.125a.5.5 0 000 1h17.402a.5.5 0 000-1zm0 2.188H5.125a.5.5 0 000 1h17.402a.5.5 0 000-1zm-4.499 2.188H5.125a.5.5 0 000 1h12.903a.5.5 0 000-1z"
        }
      ></path>
    </svg>
  );
}

export default InvoiceDefaultOutlinesvgIcon;
/* prettier-ignore-end */
