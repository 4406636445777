import { components, OptionProps, ControlProps } from 'react-select'

export const Control = ({ children, ...props }: ControlProps<any>) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        height: '100%',
      }}
    >
      <components.Control {...props}>{children}</components.Control>
    </div>
  )
}
export const Option = (props: OptionProps<any>) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#181d36',
      }}
    >
      <components.Option {...props} />
    </div>
  )
}
export const reactSelectTagStyles = {
  option: (defaultStyles: any, state: { isSelected: any }) => ({
    ...defaultStyles,
    color: state.isSelected ? '#fff' : '#ffffff66',
    backgroundColor: state.isSelected ? 'rgba(225,225,225,0.05)' : 'rgba(225,225,225,0)',
    '&:hover': {
      backgroundColor: 'rgba(225,225,225,0.05)',
    },
  }),
  control: (defaultStyles: any, state: { isFocused: any }) => ({
    ...defaultStyles,
    height: '100%',
    width: '100%',
    backgroundColor: 'transparent',
    padding: '0px',
    boxShadow: 'none',
    borderRadius: '0px',
    borderColor: state.isFocused ? '#2C97DE' : 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: state.isFocused ? '#2C97DE' : 'transparent',
    },
  }),
  menuList: (defaultStyles: any) => ({
    ...defaultStyles,
    padding: 0, // kill the white space around first and last option
    borderRadius: '4px',
  }),
  singleValue: (defaultStyles: any, state: any) => ({
    ...defaultStyles,
    color: '#fff',
  }),
  input: (defaultStyles: any) => ({
    ...defaultStyles,
    color: '#fff',
  }),
  noOptionsMessage: (defaultStyles: any) => ({
    ...defaultStyles,
    backgroundColor: '#181d36',
    padding: '0px;',
  }),
}
