Math.clamp = function (num, min, max) {
  return Math.min(max, Math.max(num, min))
}

Array.range = function (start, stop, step = 1) {
  return Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step)
}

// eslint-disable-next-line no-extend-native
String.prototype.capitalizeFirst = function () {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase()
}

export {}
