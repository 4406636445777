// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import Select from 'react-select'
import { omit } from 'lodash'

import * as GQL from 'generated/graphql'
import { PlasmicCustomerAssetsRow, DefaultCustomerAssetsRowProps } from './plasmic/solace_components/PlasmicCustomerAssetsRow'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import { Control, Option, reactSelectStylesImgSelectCell } from './StyledReactSelect'
import './CustomerAssetsRow.css'
import { displayToast } from 'util/toasts'
import Loader from 'components/Loader'
import { InputInventoryEntry } from './CustomerAssetsTable'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface CustomerAssetsRowProps extends Omit<DefaultCustomerAssetsRowProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultCustomerAssetsRowProps altogether and have
// total control over the props for your component.
export interface CustomerAssetsRowProps extends DefaultCustomerAssetsRowProps {
  suggestion?: Suggestion
  keyValue: string
  isChecked: boolean
  products: GQL.ProductNode[]
  entry: InputInventoryEntry
  handleEntryUpdate: (key: string, isChecked: boolean, inventoryInput: InputInventoryEntry) => void
  cylinderGroupId: string
}

interface Suggestion {
  quantity: number | undefined
  product: GQL.ProductNode | null | undefined
}

function CustomerAssetsRow_(props: CustomerAssetsRowProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicCustomerAssetsRow to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicCustomerAssetsRow are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all CustomerAssetsRowProps here, but feel free
  // to do whatever works for you.
  const [missingInfoState, setMissingInfoState] = React.useState(props.suggestion === null)
  const inputRef = React.useRef<HTMLInputElement>(null)

  const [createInventory, { loading: createInventoryLoading }] = GQL.useAddInventory({
    refetchQueries: ['AllInventories', 'AllCustomerInventorySuggestions', 'AllProductInventories'],
    onCompleted: data => {
      if (!data.addInventory?.ok) {
        return displayToast('Failed to update stock', 'error')
      }
      displayToast('Stock updated successfully', 'success')
    },
    onError: error => {
      displayToast('Failed to update stock', 'error')
    },
  })

  const handleClickInput = () => {
    if (inputRef.current) {
      inputRef.current.select()
    }
  }

  const createInventoryInputEntry = React.useCallback(
    (quantity: number, selectedProduct?: GQL.ProductNode) => {
      return {
        location: {
          cylinderGroupId: props.cylinderGroupId,
          product: selectedProduct,
        },
        stock: {
          amount: quantity,
          state: GQL.InventoryStockState.NotEmpty,
        },
      } as InputInventoryEntry
    },
    [props.cylinderGroupId]
  )

  const handleValueChange = (value: number) => {
    props.handleEntryUpdate(props.cylinderGroupId, props.isChecked, createInventoryInputEntry(value, props.entry?.location?.product))
  }

  const isSuggested = React.useMemo(
    () => props.entry?.stock?.amount === props.suggestion?.quantity && props.entry?.location?.product?.id === props.suggestion?.product?.id,
    [props.entry?.stock?.amount, props.suggestion]
  )

  const handleUseSuggested = () => {
    if (isSuggested || !props.suggestion || !props.suggestion.product || !props.suggestion.quantity) return
    props.handleEntryUpdate(props.cylinderGroupId, props.isChecked, createInventoryInputEntry(props.suggestion.quantity, props.suggestion.product))
  }

  const handleOnSubmit = () => {
    if (createInventoryLoading || !props.entry?.location?.product || !props.cylinderGroupId) return
    createInventory({
      variables: {
        input: {
          productId: props.entry?.location?.product.id,
          cylinderGroupId: props.cylinderGroupId,
        },
        stockInput: {
          state: GQL.InventoryStockState.NotEmpty,
          amount: props.entry?.stock?.amount,
        },
      },
    })
  }

  React.useEffect(() => {
    if (props.entry?.stock?.amount === 0 || !props.entry?.location?.product) {
      setMissingInfoState(true)
      props.handleEntryUpdate(props.cylinderGroupId, false, createInventoryInputEntry(props.entry?.stock?.amount, props.entry?.location?.product))
      return
    }
    setMissingInfoState(false)
  }, [props.entry?.stock?.amount, props.isChecked, props.entry?.location?.product])

  return (
    <PlasmicCustomerAssetsRow
      root={{ ref }}
      {...omit(props, 'suggestion', 'isChecked', 'entry', 'handleEntryUpdate', 'keyValue', 'products', 'cylinderGroupId')}
      noSuggestion={props.suggestion === null || props.suggestion?.quantity === undefined || !props.suggestion.product}
      missingInfo={missingInfoState}
      purpleRow={isSuggested}
      checkbox={{
        isChecked: props.isChecked,
        onChange: isChecked => {
          props.handleEntryUpdate(props.cylinderGroupId, isChecked, createInventoryInputEntry(props.entry?.stock?.amount, props.entry?.location?.product))
        },
        isDisabled: missingInfoState,
      }}
      selectProductDiv={
        <Select
          // @ts-ignore
          showImage={!!props.entry?.location?.product?.image?.image}
          // @ts-ignore
          imagePath={props.entry?.location?.product?.image?.image}
          placeholder='Select a product...'
          options={props.products.map(item => ({ value: item, label: item.displayName || '' }))}
          value={
            props.entry?.location?.product
              ? { value: props.entry?.location?.product, label: props.entry?.location?.product?.displayName }
              : { value: null, label: 'Select a product...' }
          }
          onChange={event =>
            // @ts-ignore
            props.handleEntryUpdate(props.cylinderGroupId, props.isChecked, createInventoryInputEntry(props.entry?.stock?.amount, event.value))
          }
          components={{ Option, Control }}
          styles={reactSelectStylesImgSelectCell}
          isSearchable={true}
        />
      }
      inputQuantityDiv={
        <input
          ref={inputRef}
          onClick={handleClickInput}
          className='assets-row-input'
          type='number'
          value={props.entry?.stock?.amount?.toString()}
          onChange={event => handleValueChange(parseInt(event.target.value))}
        />
      }
      buttonUseSuggested={{
        props: {
          active: isSuggested,
          onClick: () => handleUseSuggested(),
        },
      }}
      add={{
        props: {
          onClick: () =>
            props.handleEntryUpdate(
              props.cylinderGroupId,
              props.isChecked,
              createInventoryInputEntry(Math.max(0, props.entry?.stock?.amount + 1), props.entry?.location?.product)
            ),
        },
      }}
      subtract={{
        props: {
          onClick: () =>
            props.handleEntryUpdate(
              props.cylinderGroupId,
              props.isChecked,
              createInventoryInputEntry(Math.max(0, props.entry?.stock?.amount - 1), props.entry?.location?.product)
            ),
        },
      }}
      btnSave={{
        props: {
          onClick: handleOnSubmit,
          label: createInventoryLoading ? <Loader size={32} color='white' /> : 'Save',
        },
      }}
    />
  )
}

const CustomerAssetsRow = React.forwardRef(CustomerAssetsRow_)
export default CustomerAssetsRow
