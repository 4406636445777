import { StripePaymentAlternativesInput, VismaPaymentAlternativesInput, useCreateStripeAccountLink, usePatchDistributorPaymentSetting } from 'generated/graphql'
import Modal from 'components/Modal/Modal'
import SettingsContentWrapper from 'plasmic/SettingsContentWrapper'
import PlasmicPaymentSettings from 'plasmic/plasmic/solace_components/PlasmicPaymentSettings'
import React, { useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { displayToast } from 'util/toasts'
import { isProd } from 'util/env'
import { useAppContext, useClickOutside } from 'util/hooks'
import * as GQL from 'generated/graphql'
import ConnectionStatus from 'plasmic/ConnectionStatus'
import ActionDropdownLine from 'plasmic/ActionDropdownLine'
import IssueCardWrapper from 'plasmic/IssueCardWrapper'
import { STRIPE_DASHBOARD_URL } from '../consts'
import IssueCard from 'plasmic/IssueCard'
import CacheConfigs from 'util/cacheConfig'
import ModalConfirm from 'plasmic/ModalConfirm'
import CustomerContactModal from 'modules/customers/components/CustomerContactModal'
import ProductModal from '../components/ProductModal'
import { paymentEnabled } from 'modules/distributors/utils'

// const vismaConnectBaseURL = 'https://connect.visma.com/connect'

export default function SettingsPayments() {
  const [isConfirmModal, setIsConfirmModal] = useState(false)
  const [customerContactIssue, setCustomerContactIssue] = useState('')
  const [productIssue, setProductIssue] = useState<GQL.ProductNode>()

  const { appContext, setAppContext } = useAppContext()
  const [leavingApp, setLeavingApp] = useState(false)

  const [moreActionsStripe, setMoreActionsStripe] = useState(false)
  const [moreActionsVisma, setMoreActionsVisma] = useState(false)
  const [stripeAccount, setStripeAccount] = useState<any>({})

  const intl = useIntl()
  const t = intl.formatMessage

  const moreActionsStripeHeaderRef = useRef<HTMLDivElement>(null)
  const moreActionsVismaHeaderRef = useRef<HTMLDivElement>(null)
  useClickOutside(moreActionsStripeHeaderRef, () => {
    setMoreActionsStripe(false)
  })
  useClickOutside(moreActionsVismaHeaderRef, () => {
    setMoreActionsVisma(false)
  })

  const [stripePaymentAlternatives, setStripePaymentAlternatives] = useState<StripePaymentAlternativesInput>({
    inAdvance: appContext.distributor?.paymentSetting?.stripe?.paymentAlternatives?.inAdvance || false,
    onDelivery: appContext.distributor?.paymentSetting?.stripe?.paymentAlternatives?.onDelivery || false,
    credit: appContext.distributor?.paymentSetting?.stripe?.paymentAlternatives?.credit || false,
  })

  const [vismaPaymentAlternatives, setVismaPaymentAlternatives] = useState<VismaPaymentAlternativesInput>({
    credit: appContext.distributor?.paymentSetting?.visma?.paymentAlternatives?.credit || false,
  })

  const { data: allCustomersAutomaticOrderingNoContactsData } = GQL.useAllCustomersAutomaticOrderingNoContacts({
    ...CacheConfigs.ACCURATE_ONCE,
  })

  const { data: allProductsNoPriceData } = GQL.useAllProductsNoPrice({
    ...CacheConfigs.ACCURATE_ONCE,
  })

  const allCustomersAutomaticOrderingNoContacts = useMemo(() => {
    return allCustomersAutomaticOrderingNoContactsData?.allCustomersAutomaticOrderingNoContacts || []
  }, [allCustomersAutomaticOrderingNoContactsData])

  const allProductsNoPrice = useMemo(() => {
    return allProductsNoPriceData?.allProductsNoPrice || []
  }, [allProductsNoPriceData])

  const hasPaymentAlerts = useMemo(() => {
    return allCustomersAutomaticOrderingNoContacts.length > 0 || allProductsNoPrice.length > 0
  }, [allCustomersAutomaticOrderingNoContacts, allProductsNoPrice])

  const paymentSetting = useMemo((): GQL.DistributorPaymentSettingNode | null => {
    return appContext.distributor?.paymentSetting || null
  }, [appContext.distributor])

  const activeProviders = useMemo(() => {
    const providers = []
    if (stripeAccount && stripeAccount['payouts_enabled']) {
      providers.push('stripe')
    }
    if (appContext.distributor?.paymentSetting?.visma?.tenantId) {
      providers.push('visma')
    }
    return providers
  }, [stripeAccount, appContext.distributor])

  // TODO: handle loading
  const { loading: getStripeAccountLoading } = GQL.useGetStripeAccount({
    onCompleted: response => {
      if (response.getStripeAccount) {
        setStripeAccount(response.getStripeAccount)
      } else {
        setStripeAccount({})
      }
    },
    onError: () => {
      setStripeAccount({})
      displayToast(t({ id: 'settings.payments.stripe.error.get-stripe-account' }))
    },
  })

  const [createStripeAccountLink] = useCreateStripeAccountLink({
    onError: err => displayToast(err.message, 'error'),
  })

  const [patchDistributorPaymentSetting] = usePatchDistributorPaymentSetting({
    onCompleted: response => {
      if (response.patchDistributorPaymentSetting && response.patchDistributorPaymentSetting.ok) {
        displayToast(t({ id: 'settings.payments.update-payments-settings.success' }), 'success')
        if (appContext.distributor) {
          setAppContext({
            ...appContext,
            distributor: {
              ...appContext.distributor,
              paymentSetting: response.patchDistributorPaymentSetting?.paymentSetting,
            } as GQL.DistributorNode,
          })
        }
      } else {
        displayToast(t({ id: 'settings.payments.update-payments-settings.error' }))
      }
    },
    onError: () => {
      displayToast(t({ id: 'settings.payments.update-payments-settings.error' }))
    },
  })

  async function btnConnectStripeOnClick() {
    if (leavingApp) {
      return
    }
    setLeavingApp(true)
    let result = await createStripeAccountLink()
    let url = result.data?.createStripeAccountLink?.stripeOnboardingUrl
    window.location.href = url ? url : ''
  }

  async function btnConnectVismaOnClick() {
    if (leavingApp) {
      return
    }
    setLeavingApp(true)

    window.open(`https://apps.visma.com/myapps/isv_smartcylinders${isProd() ? '' : '_test'}`, '_self')
  }

  return (
    <SettingsContentWrapper
      settingsTitle={t({ id: 'settings.payments' })}
      headerButtons={<></>}
      content={
        <>
          {!!customerContactIssue && (
            <CustomerContactModal
              onClose={() => setCustomerContactIssue('')}
              customer={customerContactIssue}
              refetchQueries={['AllCustomersAutomaticOrderingNoContacts']}
            />
          )}
          {!!productIssue && (
            <ProductModal open={!!productIssue} onClose={() => setProductIssue(undefined)} product={productIssue} refetchQueries={['AllProductsNoPrice']} />
          )}
          {isConfirmModal && (
            <Modal
              isOpen={isConfirmModal}
              onRequestClose={() => {
                setIsConfirmModal(false)
              }}
              stripped
              contentStyle={{
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
              overlayStyle={{ alignItems: 'flex-start', overflow: 'auto', padding: '2rem 0rem' }}
            >
              <ModalConfirm
                title={t({ id: 'common.are-you-sure' })}
                description={t({ id: 'settings.payments.alert.confimration-modal.description' })}
                btnConfirm={{
                  label: t({ id: 'common.yes' }),
                  onClick: () => {
                    btnConnectStripeOnClick()
                    setIsConfirmModal(false)
                  },
                }}
                btnCancel={{
                  label: t({ id: 'common.no' }),
                  onClick: () => {
                    setIsConfirmModal(false)
                  },
                }}
                btnClose={{
                  onClick: () => setIsConfirmModal(false),
                }}
                btnColor={'red'}
              />
            </Modal>
          )}

          <PlasmicPaymentSettings
            btnConnectStripe={{ loading: getStripeAccountLoading, onClick: () => (hasPaymentAlerts ? setIsConfirmModal(true) : btnConnectStripeOnClick()) }}
            btnConnectVisma={{ onClick: () => (hasPaymentAlerts ? setIsConfirmModal(true) : btnConnectVismaOnClick()) }}
            hideMissingInformation={
              (!allCustomersAutomaticOrderingNoContacts || allCustomersAutomaticOrderingNoContacts.length === 0) &&
              (!allProductsNoPrice || allProductsNoPrice.length === 0)
            }
            hidePaymentOptions={!paymentEnabled(appContext)}
            issueCardWrappers={
              <>
                {allCustomersAutomaticOrderingNoContacts && allCustomersAutomaticOrderingNoContacts.length > 0 && (
                  <IssueCardWrapper
                    title={t({ id: 'settings.payments.alert.contact-person' })}
                    info={t({ id: 'settings.payments.alert.contact-person.info' })}
                    issues={allCustomersAutomaticOrderingNoContacts.map(customer => (
                      <IssueCard
                        title={customer?.name}
                        moreInfo={customer?.id ? `#${window.atob(customer?.id).replace(/^\D+/g, '')}` : ''}
                        key={`${customer?.id} + issueCardContactPerson`}
                        btnFixIssue={{
                          onClick: () => {
                            if (customer) {
                              setCustomerContactIssue(customer.id)
                            }
                          },
                        }}
                      />
                    ))}
                  />
                )}
                {allProductsNoPrice && allProductsNoPrice.length > 0 && (
                  <IssueCardWrapper
                    title={t({ id: 'settings.payments.alert.product-prices' })}
                    info={t({ id: 'settings.payments.alert.product-prices.info' })}
                    issues={allProductsNoPrice.map(product => (
                      <IssueCard
                        title={product?.displayName}
                        moreInfo={product?.id ? `#${window.atob(product?.id).replace(/^\D+/g, '')}` : ''}
                        key={`${product?.id} + issueCardProducts`}
                        btnFixIssue={{
                          onClick: () => {
                            if (product) {
                              setProductIssue(product as GQL.ProductNode)
                            }
                          },
                        }}
                      />
                    ))}
                  />
                )}
              </>
            }
            paymentOptions={{
              props: {
                activeProviders: activeProviders,
                checkStripeAdvance: {
                  isChecked: stripePaymentAlternatives.inAdvance,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    if (paymentSetting) {
                      const updatedStripeAlternatives = {
                        ...stripePaymentAlternatives,
                        inAdvance: e ? true : false,
                      }
                      setStripePaymentAlternatives(updatedStripeAlternatives)
                      patchDistributorPaymentSetting({
                        variables: {
                          id: paymentSetting.id,
                          input: {
                            stripePaymentAlternatives: updatedStripeAlternatives,
                            vismaPaymentAlternatives: vismaPaymentAlternatives,
                          },
                        },
                      })
                    }
                  },
                },
                checkStripeDelivery: {
                  isChecked: stripePaymentAlternatives.onDelivery,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    if (paymentSetting) {
                      const updatedStripeAlternatives = {
                        ...stripePaymentAlternatives,
                        onDelivery: e ? true : false,
                      }
                      setStripePaymentAlternatives(updatedStripeAlternatives)
                      patchDistributorPaymentSetting({
                        variables: {
                          id: paymentSetting.id,
                          input: {
                            stripePaymentAlternatives: updatedStripeAlternatives,
                            vismaPaymentAlternatives: vismaPaymentAlternatives,
                          },
                        },
                      })
                    }
                  },
                },
                checkStripeCredit: {
                  isChecked: stripePaymentAlternatives.credit,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    if (paymentSetting) {
                      const updatedStripeAlternatives = {
                        ...stripePaymentAlternatives,
                        credit: e ? true : false,
                      }
                      setStripePaymentAlternatives(updatedStripeAlternatives)
                      patchDistributorPaymentSetting({
                        variables: {
                          id: paymentSetting.id,
                          input: {
                            stripePaymentAlternatives: updatedStripeAlternatives,
                            vismaPaymentAlternatives: vismaPaymentAlternatives,
                          },
                        },
                      })
                    }
                  },
                },
                checkVismaCredit: {
                  isChecked: vismaPaymentAlternatives.credit,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    if (paymentSetting) {
                      const updatedVismaAlternatives = {
                        ...vismaPaymentAlternatives,
                        credit: e ? true : false,
                      }
                      setVismaPaymentAlternatives(updatedVismaAlternatives)
                      patchDistributorPaymentSetting({
                        variables: {
                          id: paymentSetting.id,
                          input: {
                            stripePaymentAlternatives: stripePaymentAlternatives,
                            vismaPaymentAlternatives: updatedVismaAlternatives,
                          },
                        },
                      })
                    }
                  },
                },
              },
            }}
            statusDivStripe={
              stripeAccount && stripeAccount['payouts_enabled'] ? (
                <ConnectionStatus title={'Connected since:'} content={appContext.distributor?.paymentSetting?.stripe?.createdAt.split('T')[0]} />
              ) : null
            }
            statusDivVisma={
              appContext.distributor?.paymentSetting?.visma?.tenantId ? (
                <ConnectionStatus title={'Connected since:'} content={appContext.distributor?.paymentSetting?.visma?.createdAt.split('T')[0]} />
              ) : null
            }
            moreActionsStripe={{
              ref: moreActionsStripeHeaderRef ? moreActionsStripeHeaderRef : null,
              open: moreActionsStripe,
              onClick: () => setMoreActionsStripe(prev => !prev),
              actionDropdown: {
                children: (
                  <>
                    {<ActionDropdownLine children={t({ id: 'common.settings' })} onClick={() => window.open(STRIPE_DASHBOARD_URL, '_blank')} />}
                    {/*<ActionDropdownLine children={'Remove connection'} onClick={() => console.log('Remove connection clicked')} />*/}
                  </>
                ),
              },
            }}
            moreActionsVisma={{
              ref: moreActionsVismaHeaderRef ? moreActionsVismaHeaderRef : null,
              open: moreActionsVisma,
              onClick: () => setMoreActionsVisma(prev => !prev),
              actionDropdown: {
                children: (
                  <>
                    {
                      <ActionDropdownLine
                        children={t({ id: 'common.settings' })}
                        onClick={() => window.open(`https://apps.visma.com/myapps/isv_smartcylinders${isProd() ? '' : '_test'}`, '_blank')}
                      />
                    }
                    {/*<ActionDropdownLine children={'Remove connection'} onClick={() => console.log('Remove connection clicked')} />*/}
                  </>
                ),
              },
            }}
          />
        </>
      }
    />
  )
}
