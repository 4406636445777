// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ImportsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ImportsvgIcon(props: ImportsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 215.029 230.479"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path fill={"none"} d={"M158.485 56.59h36.378l-36.378-36.37z"}></path>

      <path
        d={
          "M208.186 43.916L171.113 6.852C166.761 2.495 160.761 0 154.646 0H64.43C51.579.05 41.119 10.534 41.119 23.369v93.828H8.474A8.469 8.469 0 000 125.671a8.475 8.475 0 008.474 8.474h98.176l-12.247 12.247c-3.31 3.306-3.31 8.672 0 11.982a8.447 8.447 0 005.991 2.483 8.447 8.447 0 005.991-2.483l26.708-26.708a8.485 8.485 0 001.833-2.76 8.484 8.484 0 000-6.475 8.485 8.485 0 00-1.833-2.76l-26.708-26.704a8.47 8.47 0 00-11.982 0c-3.31 3.306-3.31 8.672 0 11.982l12.247 12.247H59.49V23.369c0-2.71 2.267-4.994 4.949-4.994h75.676v43.871c0 6.997 5.702 12.69 12.719 12.69h43.833v132.22a4.962 4.962 0 01-4.957 4.953H64.438a4.955 4.955 0 01-4.949-4.953v-49.494a2.12 2.12 0 00-2.118-2.118H43.238a2.12 2.12 0 00-2.118 2.118v49.494c0 12.86 10.46 23.323 23.319 23.323H191.71c12.86 0 23.319-10.464 23.319-23.323V60.433c0-6.144-2.49-12.164-6.843-16.517zM194.863 56.59h-36.377V20.22l36.377 36.37z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ImportsvgIcon;
/* prettier-ignore-end */
