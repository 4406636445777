import * as React from 'react'
import { PlasmicButtonArrow, DefaultButtonArrowProps } from './plasmic/solace_components/PlasmicButtonArrow'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

export interface ButtonArrowProps extends DefaultButtonArrowProps {
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void
}

function ButtonArrow_(props: ButtonArrowProps, ref: HTMLElementRefOf<'div'>) {
  return <PlasmicButtonArrow root={{ ref }} {...props} />
}

const ButtonArrow = React.forwardRef(ButtonArrow_)
export default ButtonArrow
