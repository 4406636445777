/**
 * This function returns props.theme.colors[props.color] if it exists, otherwise
 * props.color.
 *
 * @param {Object} props The props passed to a component.
 */
export function overloadColorProp(props, index = 'color') {
  return props[index] in props.theme.colors ? props.theme.colors[props[index]] : props[index]
}

function validateHex(hex) {
  if (!hex) return null
  if (hex.charAt(0) === '#') hex = hex.substring(1)
  if (hex.length !== 3 && hex.length !== 6) {
    return null
  }

  if (hex.length === 3) hex = hex.split('').reduce((acc, cur) => acc + cur + cur, '')

  if (!/^([a-f0-9]{2}){3}$/.test(hex)) {
    return null
  }

  return hex
}

export function hexWithAlpha(hex, alpha) {
  hex = validateHex(hex)
  if (!hex) return null

  const red = parseInt(hex.substring(0, 2), 16)
  const green = parseInt(hex.substring(2, 4), 16)
  const blue = parseInt(hex.substring(4, 6), 16)

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`
}
