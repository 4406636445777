import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { displayToast } from 'util/toasts'
import * as GQL from 'generated/graphql'
import { useAppContext } from 'util/hooks'
import CornerLoader from 'components/Loader/CornerLoader'
import SettingsContentWrapper from 'plasmic/SettingsContentWrapper'
import PortalSettings from 'plasmic/PortalSettings'
import { TutorialInfoIcon } from 'components/Tooltip/TutorialTooltip'

export default function SettingsPortal() {
  const navigate = useNavigate()

  const intl = useIntl()
  const t = intl.formatMessage

  const { appContext } = useAppContext()
  const user = appContext.user

  const [informationIcon, setInformationIcon] = useState<boolean>(!localStorage.getItem('tutorialInfoIcon'))
  const [percentageView, setPercentageView] = useState<boolean>(!localStorage.getItem('percentageView'))

  const [patchUser, { loading }] = GQL.usePatchUser({
    onCompleted: () => {
      navigate('/')
    },
    onError: () => {
      displayToast(t({ id: 'settings.profile.error.generic' }))
    },
  })

  return (
    <SettingsContentWrapper
      settingsTitle={t({ id: 'settings.portal' })}
      headerButtons={<></>}
      content={
        <>
          {loading && <CornerLoader size={50} />}
          <PortalSettings
            iconTooltip={<TutorialInfoIcon noMargin alwaysShow content='Like this one' />}
            switchResetSkippedTutorials={{
              props: {
                isChecked: false,
                onChange: () =>
                  patchUser({
                    variables: {
                      id: user?.id!,
                      input: {
                        tourStatus: '[]',
                      },
                    },
                  }),
              },
            }}
            switchShowHelpingTooltips={{
              props: {
                isChecked: informationIcon,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e) {
                    localStorage.removeItem('tutorialInfoIcon')
                    setInformationIcon(true)
                  } else {
                    localStorage.setItem('tutorialInfoIcon', 'OFF')
                    setInformationIcon(false)
                  }
                },
              },
            }}
            switchShowPercentage={{
              props: {
                isChecked: percentageView,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e) {
                    localStorage.removeItem('percentageView')
                    setPercentageView(true)
                  } else {
                    localStorage.setItem('percentageView', 'OFF')
                    setPercentageView(false)
                  }
                },
              },
            }}
          />
        </>
      }
    />
  )
}
