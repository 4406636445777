import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import CornerLoader from 'components/Loader/CornerLoader'
import * as GQL from 'generated/graphql'
import { displayToast } from 'util/toasts'
import { useAppContext } from 'util/hooks'
import SettingsContentWrapper from 'plasmic/SettingsContentWrapper'
import PasswordSettings from 'plasmic/PasswordSettings'
import ValidationString from 'plasmic/ValidationString'

export default function SettingsPassword() {
  const [validationErrors, setValidationErrors] = useState<Array<string | null>>([])
  const [formData, setFormData] = useState(() => ({
    oldPassword: '',
    newPassword: '',
    revealOldPassword: false,
    revealNewPassword: false,
    isSubmit: false,
  }))

  const intl = useIntl()
  const t = intl.formatMessage

  const { appContext } = useAppContext()
  const user = appContext.user

  const { data: passwordValidators, loading: validatorLoading } = GQL.usePasswordValidators()

  const [changePassword, { loading }] = GQL.useChangedPasswordMutation({
    onCompleted: data => {
      if (data.changePassword?.ok) {
        displayToast(t({ id: 'settings.update-password-success' }), 'success')
        setFormData({ oldPassword: '', newPassword: '', revealOldPassword: false, revealNewPassword: false, isSubmit: false })
      } else {
        displayToast(data.changePassword?.error || 'Error while changing password!')
      }

      setValidationErrors(data.changePassword?.validationErrors || [])
    },
    onError: error => {
      if (error.message.includes('Invalid old password supplied')) {
        displayToast(t({ id: 'settings.edit-password.error.wrong-password' }))
      } else {
        displayToast(t({ id: 'settings.edit-password.error.generic' }))
        error.message && displayToast(error.message)
      }
    },
  })

  const [forgotPassword, { loading: forgotPasswordLoading }] = GQL.useForgotPassword({
    onCompleted: data => {
      if (data?.forgotPassword?.ok) {
        displayToast(t({ id: 'settings.password.reset-password.success' }), 'success')
      } else {
        displayToast(t({ id: 'settings.password.reset-password.error' }))
      }
    },
    onError: () => {
      displayToast(t({ id: 'settings.password.reset-password.error' }))
    },
  })

  function handleSubmit() {
    setFormData({ ...formData, isSubmit: true })
    changePassword({
      variables: {
        oldPassword: formData.oldPassword,
        newPassword: formData.newPassword,
        user: 'me',
      },
    })
  }

  return (
    <SettingsContentWrapper
      settingsTitle={t({ id: 'common.password' })}
      headerButtons={<></>}
      content={
        <>
          {(loading || validatorLoading) && <CornerLoader />}
          <PasswordSettings
            labelEditPassword={t({ id: 'common.change' })}
            labelResetPassword={t({ id: 'common.reset' })}
            labelResetPasswordInfo={t({ id: 'settings.password.reset-info-label' })}
            edited={formData.oldPassword.length > 0 || formData.newPassword.length > 0}
            validationStringsWrapper={
              <>
                {passwordValidators?.passwordValidators?.map(validationRule => (
                  <ValidationString
                    string={validationRule?.helperText}
                    status={!formData.isSubmit ? undefined : !validationErrors.includes(validationRule?.name || '') ? 'success' : 'error'}
                  />
                ))}
              </>
            }
            inputOldPassword={{
              props: {
                fieldType: formData.revealOldPassword ? 'text' : 'password',
                revealPassword: formData.revealOldPassword,
                btnRevealPassword: {
                  onClick: () => setFormData({ ...formData, revealOldPassword: !formData.revealOldPassword }),
                },
                title: t({ id: 'settings.old-password' }),

                undefinedInput: {
                  value: formData.oldPassword,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setFormData({ ...formData, oldPassword: e.target.value })
                  },
                },
              },
            }}
            inputNewPassword={{
              props: {
                fieldType: formData.revealNewPassword ? 'text' : 'password',
                revealPassword: formData.revealNewPassword,
                btnRevealPassword: {
                  onClick: () => setFormData({ ...formData, revealNewPassword: !formData.revealNewPassword }),
                },
                title: t({ id: 'settings.new-password' }),
                undefinedInput: {
                  value: formData.newPassword,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setFormData({ ...formData, newPassword: e.target.value })
                  },
                },
              },
            }}
            btnCancel={{
              props: {
                label: t({ id: 'common.cancel' }),
                onClick: () => setFormData({ isSubmit: false, oldPassword: '', newPassword: '', revealOldPassword: false, revealNewPassword: false }),
              },
            }}
            btnChangePassword={{
              props: {
                label: t({ id: 'settings.change-password' }),
                onClick: handleSubmit,
              },
            }}
            btnResetPassword={{
              props: {
                label: t({ id: 'settings.password.reset-button-label' }),
                loading: forgotPasswordLoading,
                onClick: () => forgotPassword({ variables: { email: user?.email || '', referer: document.referrer || '' } }),
              },
            }}
          />
        </>
      }
    />
  )
}
