import { useState } from 'react'
import { createPortal } from 'react-dom'

import * as GQL from 'generated/graphql'
import OrdersHeader from './OrdersHeader'
import OrdersTable from './ActiveOrdersTable/OrdersTable'
import { CreateOrderModal } from './components/CreateOrderModal'

export default function ActiveOrders() {
  const contentWrapperTop = document.getElementById('content-wrapper-top')
  const [createOrderModalOpen, setCreateOrderModalOpen] = useState(false)
  const [openReadyToRefillOrder, setOpenReadyToRefillOrder] = useState<GQL.CylinderGroupNode | undefined>(undefined)

  return (
    <>
      {/* Fix for https://smartcylinders.atlassian.net/browse/SW-324 */}
      {contentWrapperTop && createPortal(<OrdersHeader setCreateOrderModalOpen={setCreateOrderModalOpen} />, contentWrapperTop)}
      <OrdersTable setOpenReadyToRefillOrder={setOpenReadyToRefillOrder} />
      {(createOrderModalOpen || !!openReadyToRefillOrder) && (
        <CreateOrderModal
          isOpen={createOrderModalOpen || !!openReadyToRefillOrder}
          readyToRefillCylinderGroup={openReadyToRefillOrder}
          onClose={() => {
            setCreateOrderModalOpen(false)
            setOpenReadyToRefillOrder(undefined)
          }}
        />
      )}
    </>
  )
}
