// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type HelpIconsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function HelpIconsvgIcon(props: HelpIconsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 26 26"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M13 .376C6.028.376.376 6.028.376 13S6.028 25.624 13 25.624 25.624 19.972 25.624 13 19.972.376 13 .376zm-.168 19.013c-.833 0-1.534-.7-1.534-1.533 0-.834.701-1.534 1.534-1.534s1.534.7 1.534 1.534c0 .833-.701 1.533-1.534 1.533zm.814-5.472c0 .304.151.625.341.796l-2.064.605a2.42 2.42 0 01-.625-1.666c0-2.254 2.765-2.519 2.765-3.825 0-.587-.436-1.079-1.477-1.079-.947 0-1.742.474-2.329 1.174L8.722 8.198c1.004-1.174 2.519-1.836 4.147-1.836 2.442 0 3.938 1.23 3.938 2.991.001 2.765-3.161 2.974-3.161 4.564z"
        }
      ></path>
    </svg>
  );
}

export default HelpIconsvgIcon;
/* prettier-ignore-end */
