import React from 'react'
import styled, { DefaultTheme, useTheme } from 'styled-components'
import ReactModal from 'react-modal'

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.h2`
  color: white;
  font-weight: normal;
  margin: 0;
`

const CloseIcon = styled.div`
  font-size: 20px;
  cursor: pointer;
`

export function getModalStyle(contentStyle: object, overlayStyle: object, mobile: boolean, theme: DefaultTheme, stripped?: boolean): object {
  const common = {
    overlay: {
      zIndex: 8000,
      background: 'rgba(0, 0, 0, 0.45)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...overlayStyle,
    },
    content: {
      margin: '0 auto',
      left: 'unset',
      right: 'unset',
      top: 'unset',
      bottom: 'unset',
      background: 'transparent',
      border: 'none',
      borderRadius: '8px',
      padding: stripped ? '0' : '1.5rem',
      overflow: 'visible',
      //overflowY: 'auto',
      ...contentStyle,
    },
  }
  if (mobile) {
    return {
      ...common,
    }
  } else {
    return {
      ...common,
    }
  }
}

export interface ModalProps {
  isOpen: boolean
  onRequestClose: () => void
  title?: string
  contentStyle?: object
  overlayStyle?: object
  stripped?: boolean
  id?: string
  children?: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({ id, isOpen, children, onRequestClose, title, contentStyle = {}, overlayStyle = {}, stripped }) => {
  const theme = useTheme()
  return (
    <ReactModal id={id} isOpen={isOpen} onRequestClose={onRequestClose} style={getModalStyle(contentStyle, overlayStyle, false, theme, stripped)}>
      {!stripped && (
        <TitleBar>
          <Title>{title || ' '}</Title>
          <CloseIcon onClick={onRequestClose}>
            <i className='fal fa-times'></i>
          </CloseIcon>
        </TitleBar>
      )}
      {children}
    </ReactModal>
  )
}

export default Modal
