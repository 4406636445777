import { ApolloClient, ApolloLink, concat, from, InMemoryCache } from '@apollo/client'
import { getLoginToken } from 'util/auth'
import { API_URL } from './util/env'
import { onError } from '@apollo/client/link/error'
import { isLocal } from 'util/env'
import * as Sentry from '@sentry/react'
import { omit } from 'lodash'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import CacheConfigs from 'util/cacheConfig'

// Custom merge function for the "tags" field
const customMergeFunction = (_existing: any, incoming: any) => incoming

const cache = new InMemoryCache({
  typePolicies: {
    CustomerNode: {
      fields: {
        tags: {
          merge: customMergeFunction,
        },
      },
    },
  },
})

const errorLink = onError(({ graphQLErrors, networkError, response, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      if (!isLocal()) {
        Sentry.captureException(new Error('[' + (path || []).join('/') + '] ' + message))
      }
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`, locations)
      return null
    })
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
    Sentry.captureException(new Error('Network error' + networkError))

    return forward(operation)
  }
})

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getLoginToken()

  operation.setContext({
    headers: {
      ...(operation.getContext().dropDistributorContext
        ? omit(operation.getContext().headers, ['context-distributor', 'context-depot'])
        : operation.getContext().headers),
      authorization: token ? `Bearer ${token}` : '',
      'system-origin': 'Solace',
    },
  })

  return forward(operation)
})

const logMiddleware = new ApolloLink((operation, forward) => {
  if (!isLocal()) {
    Sentry.addBreadcrumb({
      category: 'gql',
      message: '[ QUERY ] ' + operation.operationName + ' (' + JSON.stringify(operation.variables) + ')',
      level: 'info' as Sentry.SeverityLevel,
    })
  }

  return forward(operation)
})

const uploadLink = createUploadLink({
  uri: API_URL + '/graphql',
  fetchOptions: {
    credentials: 'same-origin',
  },
})

export const link = from([errorLink, concat(logMiddleware, concat(authMiddleware, uploadLink as any))])

const client = new ApolloClient({
  link: link,
  cache: cache,
  defaultOptions: {
    watchQuery: CacheConfigs.DEFAULT,
    query: CacheConfigs.DEFAULT,
  },
})

export default client
