import * as React from 'react'
import { DateRangePicker, Range } from 'react-date-range'
import { addDays, differenceInCalendarDays, eachDayOfInterval, endOfDay, format, getDay, isSameDay, subDays } from 'date-fns'
import { useIntl } from 'react-intl'

import { DEFAULT_VISIBLE_MONTHS, MOBILE_BREAKPOINT } from './consts'
import alertsvg from 'plasmic/plasmic/solace_components/images/alertsvg.svg'
import TriangleIcon from 'plasmic/plasmic/solace_components/icons/PlasmicIcon__Triangle'
import circleCrosssvg from 'plasmic/plasmic/solace_components/images/circleCrosssvg.svg'
import 'react-date-range/dist/styles.css'
import './CalendarReactTheme.css'

interface AlertMessage {
  icon: string
  message: string
}

export const AlertMessage = ({ icon, message }: AlertMessage) => (
  <div className='calendar-alert-message'>
    <img className='calendar-alert-icon' src={icon} alt='Calendar Icon' />
    <span>{message}</span>
  </div>
)

interface DatePickerProps {
  requestedDate?: Date | null
  emptyDate?: Date | null
  selectedRange: Range[]
  setSelectedRange: React.Dispatch<React.SetStateAction<Range[]>>
  closedDays: number[]
}

function DatePickerCalendar({ requestedDate, emptyDate, selectedRange, setSelectedRange, closedDays }: DatePickerProps) {
  const [months, setMonths] = React.useState(DEFAULT_VISIBLE_MONTHS) // Default number of visible months

  const today = new Date()

  const intl = useIntl()
  const t = intl.formatMessage

  const suggestedRange = emptyDate
    ? {
        startDate: today,
        endDate: subDays(emptyDate, differenceInCalendarDays(emptyDate, today) * 0.2),
        key: 'selection',
      }
    : null

  function customDayContent(day: Date) {
    let moreInfo

    if (emptyDate && isSameDay(day, emptyDate)) {
      moreInfo = (
        <>
          <TriangleIcon color={'#FDAB3D'} className='date-info-icon' />
          <div className='date-tooltip'>{t({ id: 'common.estimated-all-empty' })}</div>
        </>
      )
    } else if (requestedDate && isSameDay(day, requestedDate)) {
      moreInfo = (
        <>
          <div className='date-info-green-dot' />
          <div className='date-tooltip'>{t({ id: 'common.requested-date' })}</div>
        </>
      )
    } else if (closedDays?.includes(getDay(day))) {
      moreInfo = (
        <>
          <div className='date-info-red-dot' />
          <div className='date-tooltip'>{t({ id: 'common.closed-for-delivery' })}</div>
        </>
      )
    } else if (isSameDay(today, day)) {
      moreInfo = <div className='date-tooltip'>{t({ id: 'common.today' })}</div>
    }
    return (
      <div>
        {moreInfo && <div className='date-info'>{moreInfo}</div>}
        <span>{format(day, 'd')}</span>
      </div>
    )
  }
  const selectionOnlyIncludesClosedDays = () => {
    if (closedDays.length === 0) {
      return false
    }
    return (
      selectedRange[0]?.startDate &&
      selectedRange[0]?.endDate &&
      differenceInCalendarDays(selectedRange[0].endDate, selectedRange[0].startDate) <= closedDays.length - 1 &&
      eachDayOfInterval({
        start: selectedRange[0].startDate,
        end: selectedRange[0].endDate,
      }).every(day => closedDays.includes(getDay(day)))
    )
  }
  React.useEffect(() => {
    const handleResize = () => {
      window.innerWidth < MOBILE_BREAKPOINT ? setMonths(1) : setMonths(2)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  React.useEffect(() => {
    const magicButtonElement = document.getElementById('magic-button')
    const parentElement = magicButtonElement?.parentNode as HTMLElement
    parentElement?.classList.add('magic-button')
  }, [])

  return (
    <div style={{ width: '100%', overflow: 'hidden' }}>
      <DateRangePicker
        dayContentRenderer={customDayContent}
        onChange={item => {
          if (item.selection.startDate && item.selection.endDate) {
            setSelectedRange([
              {
                startDate: item.selection.startDate,
                endDate: endOfDay(item.selection.endDate),
                key: 'selection',
              },
            ])
          }
        }}
        moveRangeOnFirstSelection={false}
        editableDateInputs={true}
        months={months}
        minDate={addDays(today, 0)}
        ranges={selectedRange}
        direction='horizontal'
        weekStartsOn={1}
        rangeColors={['#2c97de']}
        inputRanges={[
          {
            label: t({ id: 'customers.orders.date-picker.days-starting-today' }),
            range: function range(value: number) {
              return {
                startDate: today,
                endDate: addDays(today, Math.max(Number(value), 0)),
              }
            },
            getCurrentValue: function getCurrentValue(range: Range) {
              if (!range.startDate || !isSameDay(range.startDate, today)) return '-'
              if (!range.endDate) return '∞'
              return differenceInCalendarDays(range.endDate, today)
            },
          },
        ]}
        //Quick select buttons:
        staticRanges={[
          ...(suggestedRange
            ? [
                {
                  label: t({ id: 'customers.orders.date-picker.magic-button' }),
                  range: () => suggestedRange,
                  isSelected(range: Range) {
                    const definedRange = this.range()
                    if (range.startDate && definedRange.startDate && range.endDate && definedRange.endDate) {
                      return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
                    }
                    return false
                  },
                },
              ]
            : []),
          ...(requestedDate
            ? [
                {
                  label: t({ id: 'common.requested-date' }),
                  range: () => ({
                    startDate: requestedDate,
                    endDate: requestedDate,
                  }),
                  isSelected(range: Range) {
                    const definedRange = this.range()
                    if (range.startDate && definedRange.startDate && range.endDate && definedRange.endDate) {
                      return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
                    }
                    return false
                  },
                },
              ]
            : []),
          {
            label: t({ id: 'common.today' }),
            range: () => ({
              startDate: today,
              endDate: today,
            }),
            isSelected(range: Range) {
              const definedRange = this.range()
              if (range.startDate && definedRange.startDate && range.endDate && definedRange.endDate) {
                return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
              }
              return false
            },
          },
          {
            label: t({ id: 'common.tomorrow' }),
            range: () => ({
              startDate: addDays(today, 1),
              endDate: addDays(today, 1),
            }),
            isSelected(range: Range) {
              const definedRange = this.range()
              if (range.startDate && definedRange.startDate && range.endDate && definedRange.endDate) {
                return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
              }
              return false
            },
          },
          {
            label: t({ id: 'customers.orders.date-picker.two-days-from' }),
            range: () => ({
              startDate: today,
              endDate: addDays(today, 2),
            }),
            isSelected(range: Range) {
              const definedRange = this.range()
              if (range.startDate && definedRange.startDate && range.endDate && definedRange.endDate) {
                return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
              }
              return false
            },
          },
          {
            label: `1 ${t({ id: 'common.week' })}`,
            range: () => ({
              startDate: today,
              endDate: addDays(today, 7),
            }),
            isSelected(range: Range) {
              const definedRange = this.range()
              if (range.startDate && definedRange.startDate && range.endDate && definedRange.endDate) {
                return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
              }
              return false
            },
          },
        ]}
      />
      {emptyDate && selectedRange[0]?.endDate && selectedRange[0].endDate > endOfDay(emptyDate) ? (
        <AlertMessage icon={alertsvg} message={t({ id: 'customers.orders.date-picker.after-estimated-alert' })} />
      ) : null}
      {selectionOnlyIncludesClosedDays() ? (
        <AlertMessage icon={circleCrosssvg} message={t({ id: 'customers.orders.date-picker.delivery-not-open-alert' })} />
      ) : null}
    </div>
  )
}
export default DatePickerCalendar
