import React from 'react'
import styled from 'styled-components'

interface ILayoutProps {
  width?: string
  inline?: boolean
  renderIcon?: boolean
  hasIcon?: boolean
}

const Layout = styled.div<ILayoutProps>`
  display: block;
  width: ${props => props.width};

  margin-top: 0.5rem;
`

interface IFormFieldProps {
  inline?: boolean
  icon?: string | JSX.Element
  iconProps?: string
  forceAllocateIconSpace?: boolean
  children?: React.ReactNode
}

const FormField: React.FC<IFormFieldProps> = ({ inline, children, icon, iconProps, forceAllocateIconSpace, ...props }) => {
  const allocateIconSpace = !!icon || forceAllocateIconSpace
  return (
    <Layout inline={inline} renderIcon={!!icon} hasIcon={allocateIconSpace} {...props}>
      {children}
    </Layout>
  )
}

export default FormField
