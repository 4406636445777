import React, { ChangeEvent, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import * as GQL from 'generated/graphql'

import { CustomerDomainType, CylinderGroupMode, DistributorTagNode, GasTypeEnum } from 'generated/graphql'
import TopActions from 'plasmic/TopActions'
import ButtonLight from 'plasmic/ButtonLight'
import ImportSVG from 'plasmic/plasmic/solace_components/icons/PlasmicIcon__Importsvg'
import Filters from 'plasmic/Filters'
import CreateCustomerModal from './CreateCustomerModal'
import ImportCustomersModal from './ImportCustomersModal'
import { Status } from '../Customers'
import ButtonFilterDropdown from 'plasmic/ButtonFilterDropdown'
import EyeInvisiblesvgIcon from 'plasmic/plasmic/solace_components/icons/PlasmicIcon__EyeInvisiblesvg'
import EyesvgIcon from 'plasmic/plasmic/solace_components/icons/PlasmicIcon__Eyesvg'
import ButtonFilterDropdownRowCustom from 'plasmic/ButtonFilterDropdownRowCustom'
import { useTableContext } from 'util/hooks'
import { CustomerTableColumn } from 'context/TableContext'
import { translateCustomerColumn } from '../util'

const Wrapper = styled.div`
  position: sticky;
  left: 0;
  z-index: 99;
`

interface Props {
  businessCustomersCount: number
  residentCustomersCount: number
  propaneCount: number
  co2Count: number
  standardSetupCount: number
  parallelSetupCount: number
  insituSetupCount: number
  emptyCylindersCount: number
  orderedCylindersCount: number
  awaitingDeliveryCount: number
  search: string
  customerType: CustomerDomainType[]
  gasType: GasTypeEnum[]
  setupStyle: CylinderGroupMode[]
  status: Status[]
  availableFilterTags: DistributorTagNode[]
  selectedFilterTags: string[]
  onSearchChange: (value: string) => void
  onCustomerTypeChange: (customerType: CustomerDomainType[]) => void
  onGasTypeChange: (gasType: GasTypeEnum[]) => void
  onSetupStyleChange: (setupStyle: CylinderGroupMode[]) => void
  onStatusChange: (status: Status[]) => void
  onFilterTagsChange: (selectedFilterTags: string[]) => void
  onClearFilters: () => void
  createCustomerCallback: (customer: GQL.CustomerNode) => void
}

const CustomersHeader: React.FC<Props> = props => {
  const intl = useIntl()
  const t = intl.formatMessage

  const { visibleCustomerTableColumns, setVisibleCustomerTableColumns } = useTableContext()

  const [createCustomerOpen, setCreateCustomerOpen] = useState(false)
  const [importCustomersOpen, setImportCustomersOpen] = useState(false)

  return (
    <Wrapper>
      <CreateCustomerModal isOpen={createCustomerOpen} onClose={() => setCreateCustomerOpen(false)} createCustomerCallback={props.createCustomerCallback} />
      <ImportCustomersModal isOpen={importCustomersOpen} onRequestClose={() => setImportCustomersOpen(false)} />
      <TopActions
        style={{ minHeight: '66px' }}
        searchField={{
          fieldPlaceholder: t({ id: 'customers.search-input.placeholder' }),
          undefinedInput: {
            value: props.search,
            onChange: (e: ChangeEvent<HTMLInputElement>) => props.onSearchChange(e.target.value),
          },
        }}
        buttonsRight={
          <React.Fragment>
            <ButtonLight
              iconLeft
              slim
              color='blue'
              label={t({ id: 'customers.import-customers' })}
              icon={<ImportSVG color='var(--plasmic-token-sc-blue)' />}
              onClick={() => setImportCustomersOpen(true)}
            />
            <ButtonLight iconLeft slim color='green' label={t({ id: 'settings.create-customer' })} onClick={() => setCreateCustomerOpen(true)} />
          </React.Fragment>
        }
      />
      <Filters
        businessCustomersCount={props.businessCustomersCount}
        residentCustomersCount={props.residentCustomersCount}
        propaneCount={props.propaneCount}
        co2Count={props.co2Count}
        standardSetupCount={props.standardSetupCount}
        parallelSetupCount={props.parallelSetupCount}
        insituSetupCount={props.insituSetupCount}
        emptyCylindersCount={props.emptyCylindersCount}
        orderedCylindersCount={props.orderedCylindersCount}
        awaitingDeliveryCount={props.awaitingDeliveryCount}
        customerType={props.customerType}
        gasType={props.gasType}
        setupStyle={props.setupStyle}
        status={props.status}
        availableFilterTags={props.availableFilterTags}
        selectedFilterTags={props.selectedFilterTags}
        onCustomerTypeChange={props.onCustomerTypeChange}
        onGasTypeChange={props.onGasTypeChange}
        onSetupStyleChange={props.onSetupStyleChange}
        onStatusChange={props.onStatusChange}
        onFilterTagsChange={props.onFilterTagsChange}
        onClearFilters={props.onClearFilters}
        hide={
          <ButtonFilterDropdown
            align={'right'}
            title={t({ id: 'common.hide' }).capitalizeFirst()}
            icon={<EyeInvisiblesvgIcon />}
            manageFilterButton
            labelManageFilter={t({ id: 'common.show-all' })}
            manageFilterIcon={<EyesvgIcon />}
            btnManageFilter={{ onClick: () => setVisibleCustomerTableColumns(Object.values(CustomerTableColumn)) }}
            activeState={visibleCustomerTableColumns.length !== Object.values(CustomerTableColumn).length}
            dropdown={
              <>
                {Object.values(CustomerTableColumn).map(column => (
                  <ButtonFilterDropdownRowCustom
                    hideCount
                    key={`${column}column`}
                    checkbox={{
                      type: 'visibility',
                      isChecked: !visibleCustomerTableColumns.includes(column),
                      onChange: (checked: boolean) =>
                        setVisibleCustomerTableColumns(
                          checked ? visibleCustomerTableColumns.filter(el => el !== column) : [...new Set([...visibleCustomerTableColumns, column])]
                        ),
                      children: translateCustomerColumn(column, intl),
                    }}
                  />
                ))}
              </>
            }
          />
        }
      />
    </Wrapper>
  )
}

export default CustomersHeader
