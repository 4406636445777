import { useEffect, useContext, useState, useCallback, useMemo, useRef } from 'react'
import { useModal } from 'react-modal-hook'
import throttle from 'lodash/throttle'

import ScrollContext from 'context/ScrollContext'
import AppContext from 'context/AppContext'
import CustomerContext from 'context/CustomerContext'
import TableContext from 'context/TableContext'

export function useAppContext() {
  const appContext = useContext(AppContext)
  return appContext
}

export function useCustomerContext() {
  const customerContext = useContext(CustomerContext)
  return customerContext
}

export function useTableContext() {
  const tableContext = useContext(TableContext)
  return tableContext
}

/**
 * Detect when we have hit bottom
 * @param {void} callback
 * @param {Number} offsetTrigger How many pixels above the bottom of the list we should be before we consider having hit (rock) bottom.
 * @param {Number} throttleAmount
 */
function useScrollBottom(callback: () => void, offsetTrigger = 100, throttleAmount = 200) {
  const scrollRef = useContext(ScrollContext)

  useEffect(() => {
    const element = scrollRef?.current

    if (!element) {
      return
    }

    function handler(event: Event) {
      const scrollDistance = (event.target as HTMLElement).scrollTop
      const scrollMaxDistance = (event.target as HTMLElement).scrollHeight - (event.target as HTMLElement).clientHeight || Number.EPSILON

      if (scrollMaxDistance - scrollDistance <= offsetTrigger) {
        callback()
      }
    }

    const throttledHandler = throttle(handler, throttleAmount)

    element.addEventListener('scroll', throttledHandler, {
      capture: false,
      passive: true,
    })

    return () => element.removeEventListener('scroll', throttledHandler)
  }, [callback, scrollRef, offsetTrigger, throttleAmount])
}

/**
 * Hook to assist us in implementing infinite scroll.
 *
 * @param {Function} onFetchMore Callback
 * @param {Number} offsetTrigger How many pixels above the bottom of the list we should be before we consider having hit (rock) bottom.
 * @param {Boolean} enabled Used to disable the callbacks when e.g. data is loading
 */
export function useInfiniteScroll(onFetchMore: () => void, offsetTrigger = 200, enabled = true) {
  useScrollBottom(() => {
    if (enabled) {
      onFetchMore()
    }
  }, offsetTrigger)
}

export function useDebounce<T>(value: T, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )

  return debouncedValue
}

export function useWindowResized(handler: (event: UIEvent) => any) {
  useEffect(() => {
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  }, [handler])
}

export function useWindowWidth() {
  const [windowDimensions, setWindowDimensions] = useState(window.innerWidth)
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

export function useClickOutside(
  ref: React.MutableRefObject<HTMLElement | null>,
  callback: (event: MouseEvent | TouchEvent | KeyboardEvent) => void,
  detectEscape: boolean = true,
  enabled: boolean = true,
  ...include: React.MutableRefObject<HTMLElement | null>[]
) {
  const handleMouseDown = useCallback(
    (evt: MouseEvent | TouchEvent) => {
      if (!ref.current) return

      const clicked = evt.target as HTMLElement

      if (ref.current.contains(clicked)) return

      for (const other of include) {
        if (!other.current) continue
        if (other.current.contains(clicked)) return
      }

      if (callback && enabled) {
        callback(evt)
      }
    },
    [ref, callback, enabled, include]
  )

  const handleEscape = useCallback(
    (evt: Event) => {
      if (!detectEscape || !(evt instanceof KeyboardEvent)) return
      callback(evt)
    },
    [detectEscape, callback]
  )
  useKeyDown(['Escape'], handleEscape)

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown)
    document.addEventListener('touchstart', handleMouseDown)

    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
      document.removeEventListener('touchstart', handleMouseDown)
    }
  }, [handleMouseDown])
}

export function useKeyDown(
  keys: string[],
  callback: (event: KeyboardEvent) => void,
  ref: React.MutableRefObject<HTMLElement | null> | HTMLDocument = document
) {
  const handleKeyDown = useCallback(
    (evt: Event) => {
      if (evt instanceof KeyboardEvent) {
        if (!keys.includes(evt.key)) return
        callback(evt)
      }
    },
    [keys, callback]
  )

  useEffect(() => {
    let elmt: HTMLElement | HTMLDocument
    if ('current' in ref && ref.current instanceof HTMLElement) {
      elmt = ref.current
    } else if (ref instanceof HTMLDocument) elmt = ref
    else return

    elmt.addEventListener('keydown', handleKeyDown)

    return () => {
      elmt.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown, ref])
}

export const useModalWithData = (modalFactory: (modalData: any) => React.FunctionComponent) => {
  const [modalData, setModalData] = useState(undefined)
  const modalComponent = useMemo(() => modalFactory(modalData), [modalData, modalFactory])
  const [_showModal, hideModal] = useModal(modalComponent, [modalData])

  const showModal = useCallback(
    (data?: any) => {
      setModalData(data)
      _showModal()
    },
    [_showModal]
  )

  return [showModal, hideModal]
}

export const useCountryCodeFromIp = () => {
  const [countryCode, setCountryCode] = useState<string>('')
  useEffect(() => {
    fetch('https://www.cloudflare.com/cdn-cgi/trace')
      .then(data => data.text())
      .then(response => {
        const match = /^(?:loc)=(.*)$/gm.exec(response)
        if (match != null) {
          setCountryCode(match[1].toLowerCase())
        } else {
          setCountryCode('')
        }
      })
      .catch(err => {
        console.log(err)
        setCountryCode('')
      })
  }, [])

  return countryCode
}
export function useDelayedAction(delay = 2000) {
  const actionTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const actionTriggeredRef = useRef<boolean>(false)

  const startAction = useCallback(
    (action: () => void) => {
      if (!actionTimeoutRef.current && !actionTriggeredRef.current) {
        actionTimeoutRef.current = setTimeout(() => {
          action()
          actionTriggeredRef.current = true
          actionTimeoutRef.current = null
        }, delay)
      }
    },
    [delay]
  )

  const clearAction = useCallback(() => {
    if (actionTimeoutRef.current) {
      clearTimeout(actionTimeoutRef.current)
      actionTimeoutRef.current = null
    }
    actionTriggeredRef.current = false
  }, [])

  return { startAction, clearAction }
}
