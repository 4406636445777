/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import ReactModal from 'react-modal'
import { modalStyle } from 'components/Modal/PlasmicModal'
import ModalConfirm from 'plasmic/ModalConfirm'

interface IDeleteConfirmationTempProps {
  data: {
    id?: string
    title?: string
    description?: string
    func?: any
  }
  isOpen: boolean
  onRequestClose: () => void
}

const DeleteConfirmationModal: React.FC<IDeleteConfirmationTempProps> = ({ isOpen, onRequestClose, data }) => {
  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose} style={modalStyle()}>
      <ModalConfirm
        btnClose={{ onClick: onRequestClose }}
        btnCancel={{ onClick: onRequestClose }}
        title={data.title}
        description={data.description}
        btnColor='red'
        btnConfirm={{
          onClick: () => {
            data.func({
              variables: {
                id: data.id,
              },
            })
            onRequestClose()
          },
        }}
      />
    </ReactModal>
  )
}

export default DeleteConfirmationModal
