// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicIssueCard, DefaultIssueCardProps } from './plasmic/solace_components/PlasmicIssueCard'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface IssueCardProps extends Omit<DefaultIssueCardProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultIssueCardProps altogether and have
// total control over the props for your component.
export interface IssueCardProps extends DefaultIssueCardProps {
  btnFixIssue?: any
  moreInfo?: any
}

function IssueCard_(props: IssueCardProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicIssueCard to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicIssueCard are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all IssueCardProps here, but feel free
  // to do whatever works for you.

  return <PlasmicIssueCard root={{ ref }} {...props} />
}

const IssueCard = React.forwardRef(IssueCard_)
export default IssueCard
