import React, { FunctionComponent } from 'react'
import styled, { Theme, useTheme } from 'styled-components'
import * as GQL from 'generated/graphql'
import { addDays, format, formatDistanceStrict, isBefore, parseISO } from 'date-fns'

import Loader from 'components/Loader'
import HistoryRow from 'plasmic/HistoryRow'
import MonitorHistory from 'plasmic/MonitorHistory'

interface IHeaderVerb {
  color: string
}

const HeaderVerb = styled.div<IHeaderVerb>`
  color: ${props => props.color};
`

const nrnToVerbElement = (nrn: string, theme: Theme) => {
  const verbs = {
    'nrn:clients.customer:stopped_depletion': <HeaderVerb color={theme.colors.red}>Empty</HeaderVerb>,
    'nrn:clients.customer:started_depletion': <HeaderVerb color={theme.colors.lightBlue}>Started depleting</HeaderVerb>,
    'nrn:clients.customer:stopped_depleting': <HeaderVerb color={theme.colors.red}>Empty</HeaderVerb>,
    'nrn:clients.customer:started_depleting': <HeaderVerb color={theme.colors.lightBlue}>Started depleting</HeaderVerb>,
    'nrn:clients.distributor:refilled': <HeaderVerb color={theme.colors.green}>Refilled</HeaderVerb>,
    'nrn:clients.distributor:ordered': <HeaderVerb color={theme.colors.yellow}>Ordered</HeaderVerb>,
    'nrn:clients.user:ordered': <HeaderVerb color={theme.colors.yellow}>Ordered</HeaderVerb>,
    'nrn:clients.distributor:cancelled_order': <HeaderVerb color={theme.colors.red}>Cancelled order</HeaderVerb>,
    'nrn:clients.user:cancelled_order': <HeaderVerb color={theme.colors.red}>Cancelled order</HeaderVerb>,
    'nrn:clients.distributor:delivered_order': <HeaderVerb color={theme.colors.lightBlue}>Delivered</HeaderVerb>,
  } as { [key: string]: React.ReactElement }
  return verbs[nrn]
}
const nrnToVerb = (nrn: string) => {
  const verbs = {
    'nrn:clients.customer:stopped_depletion': 'empty',
    'nrn:clients.customer:started_depletion': 'startedDepletion',
    'nrn:clients.customer:stopped_depleting': 'empty',
    'nrn:clients.customer:started_depleting': 'startedDepletion',
    'nrn:clients.distributor:refilled': undefined, // "refilled",
    'nrn:clients.distributor:ordered': 'ordered',
    'nrn:clients.user:ordered': 'ordered',
    'nrn:clients.distributor:cancelled_order': 'cancelled', // "cancelled",
    'nrn:clients.user:cancelled_order': 'cancelled', // "cancelled",
    'nrn:clients.distributor:delivered_order': 'delivered',
  } as { [key: string]: 'empty' | 'startedDepletion' | 'delivered' | 'ordered' | 'cancelled' | undefined }
  return verbs[nrn]
}

interface Props {
  customer: GQL.CustomerNode
  sides?: GQL.Maybe<GQL.CylinderGroupSideNode>[]
}

const CylinderGroupHistory: FunctionComponent<Props> = props => {
  const [moreLoading, setMoreLoading] = React.useState(false)
  const theme = useTheme()
  const { data, fetchMore } = GQL.useAllCustomerNotifications({
    variables: {
      offset: 0,
      customer: props.customer.id!,
      resources: [
        'nrn:clients.customer:started_depletion',
        'nrn:clients.customer:stopped_depletion',
        'nrn:clients.customer:stopped_depleting',
        'nrn:clients.customer:started_depleting',
        'nrn:clients.distributor:refilled',
        'nrn:clients.distributor:ordered',
        'nrn:clients.user:ordered',
        'nrn:clients.distributor:cancelled_order',
        'nrn:clients.user:cancelled_order',
        'nrn:clients.distributor:delivered_order',
      ],
    },
  })

  const notifications = data?.allCustomerNotifications?.edges.map(e => e?.node) || []
  const pageInfo = data?.allCustomerNotifications?.pageInfo
  const grouped = notifications || []

  const leftGroup = props.sides && props.sides.length >= 2 ? atob(props.sides[0]!.id!).split(':')[1] : undefined
  const rightGroup = props.sides && props.sides.length >= 2 ? atob(props.sides[1]!.id!).split(':')[1] : undefined

  let timeTemp = new Date()

  return (
    <MonitorHistory
      btnLoadMore={{
        notVisible: pageInfo?.hasNextPage ? false : true,
        btnLoadMore: {
          ...(moreLoading && { children: <Loader color='white' /> }),
          onClick: async () => {
            if (moreLoading) return
            setMoreLoading(true)
            await fetchMore({
              variables: { offset: notifications.length },
              updateQuery: (preiovus, { fetchMoreResult }) => {
                return {
                  ...fetchMoreResult,
                  allCustomerNotifications: {
                    ...(fetchMoreResult.allCustomerNotifications as GQL.NotificationNodeConnection),
                    edges: [...(preiovus?.allCustomerNotifications?.edges || []), ...(fetchMoreResult?.allCustomerNotifications?.edges || [])],
                  },
                }
              },
            }).then(() => {
              setMoreLoading(false)
            })
          },
        },
      }}
      rows={grouped.map((n, i) => {
        const sideId = n?.objectText?.match(/[\d]+/g)?.at(0)
        let addTimeRow = null
        if (i === 0) {
          timeTemp = parseISO(n?.createdAt)
        } else {
          if (isBefore(parseISO(n?.createdAt), addDays(timeTemp, -3))) addTimeRow = formatDistanceStrict(parseISO(n?.createdAt), timeTemp)
          timeTemp = parseISO(n?.createdAt)
        }

        return (
          <React.Fragment key={'historyRow' + i}>
            {addTimeRow && <HistoryRow collapseRow daysBetween={addTimeRow} />}
            <HistoryRow
              side={sideId === rightGroup ? 'right' : sideId === leftGroup ? 'left' : 'none'}
              event={nrnToVerb(n?.nrn!)}
              date={format(parseISO(n?.createdAt), 'dd.MM')}
              infoRight={nrnToVerbElement(n?.nrn!, theme)}
              timeRight={format(parseISO(n?.createdAt), 'HH:mm')}
              infoLeft={nrnToVerbElement(n?.nrn!, theme)}
              timeLeft={format(parseISO(n?.createdAt), 'HH:mm')}
            />
          </React.Fragment>
        )
      })}
    />
  )
}

export default CylinderGroupHistory
