import styled from 'styled-components'

/**
 * Centering wrapper, can be used for any component. Top and bottom margins are 1rem.
 */
export const CenteredLoader = styled.div<{ sizeAuto?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: ${props => (props.sizeAuto ? 'auto' : '100%')};
  height: ${props => (props.sizeAuto ? 'auto' : '100%')};
`

export const FullLoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 99;
  /* background-color: white; */
  background-color: #181d366c;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
`
