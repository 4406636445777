import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { format, parseISO } from 'date-fns'
import Select from 'react-select'

import * as GQL from 'generated/graphql'
import Modal from 'components/Modal/Modal'
import ModalVerifyPickup from 'plasmic/ModalVerifyPickup'
import OrderProductRowExtended from 'plasmic/OrderProductRowExtended'
import { Control, MenuList, Option, reactSelectStyles } from 'plasmic/StyledReactSelect'
import VerifyPickupRow from 'plasmic/VerifyPickupRow'
import { useAppContext } from 'util/hooks'
import { displayToast } from 'util/toasts'
import Loader from 'components/Loader'
import { formatPrice, formatTaxRate } from 'modules/orders/util'
import { VerifyExchangeStock } from '../types'
import { formatDiscrepancyAction } from '../utils'

interface Props {
  isOpen: boolean
  onClose: () => void
  verifyExchangeInfo: VerifyExchangeStock
}

interface Option {
  value: GQL.DiscrepancyAction
  label: string
}

const StyledSelect = styled(Select)`
  width: 100%;
  margin: 1rem 0rem;
`

const VerifyExchangeModal: React.FC<Props> = props => {
  const { appContext } = useAppContext()
  const { distributor } = appContext
  const { order, callback } = props.verifyExchangeInfo
  const [quantities, setQuantities] = useState<{ [key: string]: number }>({})
  const [discrepancy, setDiscrepancy] = useState<Option>()

  const { data: dataDepot } = GQL.useAllDepots()

  const depot = useMemo(() => {
    if (appContext.depot) return appContext.depot
    return dataDepot?.allDepots?.edges.map(edge => edge?.node as GQL.DepotNode)[0] || undefined
  }, [appContext, dataDepot])

  const orderProducts = useMemo(() => {
    if (!order.products || order.products.length === 0) return
    return order.products
  }, [order])

  const gasProducts = useMemo(() => {
    return orderProducts?.filter(orderProduct => !!orderProduct?.product?.isGasType) || []
  }, [orderProducts])

  const orderedBy = useMemo(() => {
    if (order?.automatic) return 'Automatic'
    if (order?.orderedByType === 'clients.customercontact' && order?.orderedByContact) return order.orderedByContact?.name
    if (order.orderedByType === 'clients.distributor' && order.addedBy?.fullName) return order.addedBy.fullName
    return '-'
  }, [order])

  const amountToDeliver = useMemo(() => gasProducts.reduce((accumulated, orderProducts) => accumulated + (orderProducts?.quantity || 0), 0), [gasProducts])
  const amountOfDeposits = useMemo(
    () => gasProducts.reduce((accumulated, orderProducts) => accumulated + (orderProducts?.exchangeCylindersFee ? orderProducts.quantity : 0), 0),
    [gasProducts]
  )
  const amountToPickup = useMemo(() => amountToDeliver - amountOfDeposits, [amountOfDeposits, amountToDeliver])

  const getDepositAmount = (orderProduct: GQL.Maybe<GQL.CylinderGroupOrderProductNode> | undefined) => {
    if (!orderProduct) return 0
    return orderProduct?.exchangeCylindersFee ? orderProduct.quantity || 0 : 0
  }

  const [exchangeStock, { loading: exchangeStockLoading }] = GQL.useExchangeStock({
    // refetchQueries: ['AllProductInventories', 'AllInventoryAdjustments', 'CylinderGroupOrders'],
    onError: () => {
      displayToast('Could not verify the stock transfer')
    },
  })

  const getQuantity = useCallback(
    (orderProduct: GQL.Maybe<GQL.CylinderGroupOrderProductNode> | undefined) => {
      if (!orderProduct?.product) return 0
      return quantities[orderProduct.id]
    },
    [quantities]
  )
  const setQuantity = (orderProduct: GQL.Maybe<GQL.CylinderGroupOrderProductNode> | undefined, quantity: number) => {
    if (!orderProduct?.product) return
    setQuantities({ ...quantities, [orderProduct.id]: quantity })
  }

  const getExchangeInput = (
    fromInventory: Partial<GQL.InputAddInventory>,
    toInventory: Partial<GQL.InputAddInventory>,
    orderProduct: GQL.Maybe<GQL.CylinderGroupOrderProductNode>
  ) => {
    return {
      fromInventory: { ...fromInventory, productId: orderProduct?.product?.id || '' },
      toInventory: { ...toInventory, productId: orderProduct?.product?.id || '' },
      received: quantities[orderProduct?.id || ''],
      returned: orderProduct?.quantity || 0,
      fromState: GQL.InventoryStockState.Empty,
      toState: GQL.InventoryStockState.NotEmpty,
      transformState: true,
      reason: GQL.InventoryAdjustmentReason.Sale,
      orderProductId: orderProduct?.id || '',
      discrepancyAction: discrepancy?.value,
    } as GQL.InputExchangeStock
  }

  const handleOnSubmit = () => {
    if (exchangeStockLoading) return
    const fromInventory = { depotId: depot?.id || '' }
    const toInventory = { cylinderGroupId: order.cylinderGroup.id || '' }
    exchangeStock({
      variables: {
        exchanges: order.products?.map(orderProduct => getExchangeInput(fromInventory, toInventory, orderProduct)) || [],
      },
      onCompleted: data => {
        if (!data.exchangeStock?.ok) return displayToast('Could not verify the stock transfer')
        props.onClose()
        if (callback) callback()
      },
    })
  }

  useEffect(() => {
    if (Object.keys(quantities).length > 0) return
    const _quantities: { [key: string]: number } = {}
    gasProducts?.forEach(orderProduct => {
      if (!orderProduct) return
      _quantities[orderProduct.id] = (orderProduct.quantity || 0) - getDepositAmount(orderProduct)
    })
    setQuantities(_quantities)
  }, [quantities, setQuantities, gasProducts])

  useEffect(() => {
    if (!gasProducts.some(orderProduct => getQuantity(orderProduct) !== (orderProduct?.quantity || 0) - getDepositAmount(orderProduct)))
      return setDiscrepancy(undefined)
    if (discrepancy) return
    const orderProduct =
      gasProducts?.find(orderProduct => getQuantity(orderProduct) !== (orderProduct?.quantity || 0) - getDepositAmount(orderProduct)) || undefined
    if (getQuantity(orderProduct) < (orderProduct?.quantity || 0) - getDepositAmount(orderProduct))
      return setDiscrepancy({
        value: GQL.DiscrepancyAction.PostInvoiceDepositFee,
        label: formatDiscrepancyAction(GQL.DiscrepancyAction.PostInvoiceDepositFee),
      } as Option)
    if (getQuantity(orderProduct) > (orderProduct?.quantity || 0) - getDepositAmount(orderProduct))
      return setDiscrepancy({
        value: GQL.DiscrepancyAction.PostInvoiceDepositReturn,
        label: formatDiscrepancyAction(GQL.DiscrepancyAction.PostInvoiceDepositReturn),
      } as Option)
  }, [discrepancy, gasProducts, getQuantity])

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.onClose} overlayStyle={{ overflow: 'auto', alignItems: 'flex-start', padding: '2rem' }} stripped>
      <ModalVerifyPickup
        createOrderSummary={{
          orderDate: order.createdAt ? format(parseISO(order.createdAt), 'dd.MM.yyyy') : '-',
          orderedBy: orderedBy,
          productsToDeliver: amountToDeliver.toString(),
          productsToPickUp: amountToPickup.toString(),
          subtotal: formatPrice(order.subtotalPrice || 0, distributor?.defaultCurrency || 'USD'),
          tax: formatPrice(order.tax || 0, distributor?.defaultCurrency || 'USD'),
          total: formatPrice(order.totalPrice || 0, distributor?.defaultCurrency || 'USD'),
          productRows: orderProducts?.map(orderProduct => (
            <OrderProductRowExtended
              key={orderProduct?.id}
              productImage={orderProduct?.product?.image?.image}
              product={orderProduct?.product?.displayName}
              price={formatPrice(orderProduct?.price || 0, distributor?.defaultCurrency || 'USD')}
              priceTax={`+${formatTaxRate(orderProduct?.taxRate, distributor)}`}
              deposit={formatPrice(orderProduct?.cylinderDepositPrice || 0, distributor?.defaultCurrency || 'USD')}
              depositTax={`+${formatTaxRate(orderProduct?.depositTaxRate)}`}
              total={formatPrice(orderProduct?.totalPrice || 0, distributor?.defaultCurrency || 'USD')}
              quantity={orderProduct?.quantity}
            />
          )),
        }}
        rows={gasProducts.map(orderProduct =>
          orderProduct ? (
            <VerifyPickupRow
              key={orderProduct?.id}
              productImage={orderProduct?.product?.image?.image}
              productTitle={orderProduct?.product?.displayName}
              quantityFromOrder={((orderProduct?.quantity || 0) - getDepositAmount(orderProduct)).toString()}
              quantity={(getQuantity(orderProduct) || 0).toString()}
              btnAdd={{ onClick: () => setQuantity(orderProduct, getQuantity(orderProduct) + 1) }}
              btnSubtract={{
                onClick: () => setQuantity(orderProduct, getQuantity(orderProduct) < 1 ? getQuantity(orderProduct) : getQuantity(orderProduct) - 1),
              }}
            />
          ) : null
        )}
        discrepancy={gasProducts.some(orderProduct => getQuantity(orderProduct) !== (orderProduct?.quantity || 0) - getDepositAmount(orderProduct))}
        selectDiscrepancyActionDiv={
          <StyledSelect
            placeholder={'Select an action to handle the discrepancy...'}
            components={{ Option, Control, MenuList }}
            styles={reactSelectStyles}
            onChange={(event: any) => setDiscrepancy(event)}
            value={discrepancy}
            options={Object.values(GQL.DiscrepancyAction).map(value => ({ value: value, label: formatDiscrepancyAction(value) }) as Option)}
            isMulti={false}
            isSearchable
          />
        }
        btnClose={{ onClick: props.onClose }}
        btnCancel={{ onClick: props.onClose }}
        btnVerify={{ onClick: handleOnSubmit, label: exchangeStockLoading ? <Loader size={32} color='white' /> : 'Verify pickup and transfer assets' }}
      />
    </Modal>
  )
}

export { VerifyExchangeModal }
