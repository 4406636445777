import * as GQL from 'generated/graphql'
import { type AuditTableDataRow, type TableAuditData } from './types'
import Parser, {
  CylinderGroupOrderCreateParser,
  CylinderGroupOrderUpdateParser,
  GenericCreateParser,
  GenericRemoveParser,
  GenericUpdateParser,
} from './parsers'

type ParserMap = { [key: string]: Parser }

class AuditLogParser {
  private readonly orderParserMap: ParserMap = {
    create: new CylinderGroupOrderCreateParser(),
    update: new CylinderGroupOrderUpdateParser(),
  }

  private readonly genericParserMap: ParserMap = {
    create: new GenericCreateParser(),
    update: new GenericUpdateParser(),
    remove: new GenericRemoveParser(),
  }

  public parseRows(entries: GQL.AuditLogEntryNode[]): TableAuditData {
    return entries.map(entry => {
      const parser = this.getParser(entry)
      return this.parseRow(entry, parser)
    })
  }

  private parseRow(entry: GQL.AuditLogEntryNode, parser: Parser): AuditTableDataRow {
    const details = parser.details(entry)
    const domains = parser.domains(entry)

    return {
      action: entry.actionDisplay || '',
      timestamp: entry.timestamp,
      user: entry.actor?.fullName || 'system',
      domain: domains,
      details: details,
    }
  }

  private getParser(entry: GQL.AuditLogEntryNode): Parser {
    if (!!entry.actionDisplay && entry.contentType?.model === 'cylindergrouporder' && Object.keys(this.orderParserMap).includes(entry.actionDisplay)) {
      return this.orderParserMap[entry.actionDisplay]
    }
    return this.genericParserMap[entry.actionDisplay || 'created']
  }
}

export { AuditLogParser }
