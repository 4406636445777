import React, { useCallback, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import * as GQL from 'generated/graphql'
import LabelStatusChip from 'plasmic/LabelStatusChip'
import ScrollIndicator from 'plasmic/ScrollIndicator'
import InventoryCard from 'plasmic/InventoryCard'
import CornerLoader from 'components/Loader/CornerLoader'
import CacheConfigs from 'util/cacheConfig'
import { useDebounce } from 'util/hooks'
import { CenteredLoader } from 'components/Loader/LoaderWrappers'
import Loader from 'components/Loader'
import { NoHits } from 'modules/orders/util'
import { AdjustStock, EditStock, TransferStock } from '../types'
import { AdjustStockModal, EditStockModal, InventoryHeader, TransferStockModal } from '../components'
import CustomerAssetsTable from 'plasmic/CustomerAssetsTable'

const Wrapper = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
`

const StyledScrollIndicator = styled(ScrollIndicator)`
  background-color: unset;
  box-shadow: unset;
`

const InventoryOverview: React.FC = () => {
  const PAGE_SIZE = 16
  const contentWrapperTop = document.getElementById('content-wrapper-top')
  const [loadingFetchMore, setLoadingFetchMore] = useState(false)
  const [adjustStockInfo, setAdjustStockInfo] = useState<AdjustStock>()
  const [transferStockInfo, setTransferStockInfo] = useState<TransferStock>()
  const [editStockInfo, setEditStockInfo] = useState<EditStock>()
  const [customerAssetsOpen, setCustomerAssetsOpen] = useState(false)
  const [gasType, setGasType] = useState<GQL.GasTypeEnum[]>([])
  const [search, setSearch] = useState('')

  const debouncedSearch = useDebounce(search, 500)

  const {
    data: dataProductInventories,
    loading: loadingProductInventories,
    fetchMore: fetchMoreProductInventories,
  } = GQL.useAllProductInventories({
    ...CacheConfigs.ACCURATE_ONCE,
    variables: {
      first: PAGE_SIZE,
      search: debouncedSearch,
      gasType: gasType.map(type => type.toLowerCase()),
    },
  })
  const productInventories = useMemo(
    () => dataProductInventories?.allProductInventories?.edges.map(edge => edge?.node as GQL.ProductInventoriesNode) || [],
    [dataProductInventories]
  )

  const pageInfo = useMemo(() => dataProductInventories?.allProductInventories?.pageInfo || { hasNextPage: false, endCursor: '' }, [dataProductInventories])

  const filtersInfo = useMemo(
    () => (dataProductInventories?.allProductInventories?.filtersInfo as GQL.ProductInventoriesFiltersInfo) || undefined,
    [dataProductInventories]
  )

  const handleClearFilters = () => {
    setGasType([])
  }

  const onLoadMoreClick = useCallback(() => {
    if (productInventories.length >= (dataProductInventories?.allProductInventories?.totalCount || 0) || loadingFetchMore) return
    setLoadingFetchMore(true)
    fetchMoreProductInventories({
      variables: {
        after: pageInfo.endCursor,
        first: PAGE_SIZE,
        search: debouncedSearch,
        gasType: gasType.map(type => type.toLowerCase()),
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) return prev
        return {
          ...fetchMoreResult,
          allProductInventories: {
            ...fetchMoreResult.allProductInventories,
            edges: [...(prev.allProductInventories?.edges || []), ...fetchMoreResult?.allProductInventories?.edges!],
          },
        } as GQL.AllProductInventories
      },
    }).finally(() => setLoadingFetchMore(false))
  }, [
    fetchMoreProductInventories,
    productInventories.length,
    dataProductInventories?.allProductInventories?.totalCount,
    pageInfo.endCursor,
    debouncedSearch,
    gasType,
    loadingFetchMore,
  ])

  return (
    <>
      {/* Fix for https://smartcylinders.atlassian.net/browse/SW-324 */}
      {contentWrapperTop &&
        createPortal(
          <InventoryHeader
            search={search}
            onSearchChange={setSearch}
            propaneCount={filtersInfo?.propaneCount || 0}
            co2Count={filtersInfo?.co2Count || 0}
            gasType={gasType}
            onGasTypeChange={setGasType}
            onClearFilters={handleClearFilters}
            onRightButtonClicked={() => setCustomerAssetsOpen(true)}
          />,
          contentWrapperTop
        )}
      {loadingProductInventories && <CornerLoader size={65} topAdjust={'-30px'} />}
      {loadingProductInventories && productInventories.length === 0 ? (
        <CenteredLoader sizeAuto>
          <Loader color='gray6' size={50} />
        </CenteredLoader>
      ) : productInventories.length > 0 ? (
        <Wrapper>
          {productInventories.map(productInventories => (
            <InventoryCard
              data-testid='inventory-card'
              key={productInventories.id}
              productInventories={productInventories}
              setAdjustStockInfo={setAdjustStockInfo}
              setEditStockInfo={setEditStockInfo}
              setTransferStockInfo={setTransferStockInfo}
            />
          ))}
          <StyledScrollIndicator
            loaded={productInventories.length}
            total={dataProductInventories?.allProductInventories?.totalCount || 0}
            btnLoadMore={{ ...(loadingFetchMore && { children: <Loader color='white' /> }), onClick: onLoadMoreClick }}
          />
        </Wrapper>
      ) : (
        <NoHits>
          <LabelStatusChip icon='check' title='No inventories' />
        </NoHits>
      )}
      {customerAssetsOpen && <CustomerAssetsTable isOpen={customerAssetsOpen} onClose={() => setCustomerAssetsOpen(false)} />}
      {!!adjustStockInfo && <AdjustStockModal isOpen={!!adjustStockInfo} onClose={() => setAdjustStockInfo(undefined)} adjustStockInfo={adjustStockInfo} />}
      {!!editStockInfo && <EditStockModal isOpen={!!editStockInfo} onClose={() => setEditStockInfo(undefined)} editStockInfo={editStockInfo} />}
      {!!transferStockInfo && (
        <TransferStockModal isOpen={!!transferStockInfo} onClose={() => setTransferStockInfo(undefined)} transferStockInfo={transferStockInfo} />
      )}
    </>
  )
}

export default InventoryOverview
