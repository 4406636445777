import * as React from 'react'
import { useIntl } from 'react-intl'

import { DefaultCellStatusProps, PlasmicCellStatus } from './plasmic/solace_components/PlasmicCellStatus'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import * as GQL from 'generated/graphql'
import { getStateColorLabel } from 'modules/hardware/util'
import ButtonFill from './ButtonFill'
import DropdownChevronsvgIcon from './plasmic/solace_components/icons/PlasmicIcon__DropdownChevronsvg'
import { AllOrdersTableColumn, generateColumnFilterClass } from 'context/TableContext'
import { useTableContext } from 'util/hooks'

interface CellStatusProps extends DefaultCellStatusProps {
  fromElement: GQL.CylinderGroupOrderNode | GQL.CylinderGroupNode
  editable: boolean
  onOrderStatusClick?: (event: React.MouseEvent<HTMLButtonElement>, fromElement: GQL.CylinderGroupOrderNode | GQL.CylinderGroupNode) => void
}

function CellStatus_(props: CellStatusProps, ref: HTMLElementRefOf<'div'>) {
  const { fromElement, onOrderStatusClick, editable, ...plasmicProps } = props
  const t = useIntl().formatMessage

  const { visibleOrdersAllTableColumns } = useTableContext()

  const isWaitingForPayment = React.useMemo(() => {
    return (
      fromElement &&
      fromElement.__typename === 'CylinderGroupOrderNode' &&
      fromElement.paymentSetting?.method === GQL.PaymentMethods.InAdvance &&
      fromElement.paymentStatus === GQL.CylinderGroupOrderPaymentStates.NotPaid
    )
  }, [fromElement])

  const buttonLabel = React.useMemo(() => {
    if (fromElement.__typename === 'CylinderGroupNode') {
      return t({ id: 'orders.set-awaiting-order' })
    } else if (isWaitingForPayment) {
      return t({ id: 'common.waiting-for-payment' })
    } else if (fromElement.__typename === 'CylinderGroupOrderNode' && fromElement.state === GQL.CylinderGroupOrderStates.Paused) {
      return t({ id: 'orders.status.order-paused' })
    } else if (fromElement.__typename === 'CylinderGroupOrderNode' && fromElement.state === GQL.CylinderGroupOrderStates.AwaitingDelivery) {
      return t({ id: 'orders.status.awaiting-delivery' })
    } else if (fromElement.__typename === 'CylinderGroupOrderNode' && fromElement.state === GQL.CylinderGroupOrderStates.Created) {
      return t({ id: 'orders.status.order-placed' })
    } else if (fromElement.__typename === 'CylinderGroupOrderNode' && fromElement.state === GQL.CylinderGroupOrderStates.Cancelled) {
      return t({ id: 'orders.status.cancelled' })
    } else if (fromElement.__typename === 'CylinderGroupOrderNode' && fromElement.state === GQL.CylinderGroupOrderStates.Delivered) {
      return t({ id: 'common.delivered' })
    } else {
      return t({ id: 'common.unknown' })
    }
  }, [fromElement])

  return (
    <PlasmicCellStatus
      root={{ ref }}
      className={generateColumnFilterClass(AllOrdersTableColumn.STATUS, visibleOrdersAllTableColumns)}
      content={
        <>
          <ButtonFill
            data-testid='state-toggle'
            iconRight={editable}
            opaque={isWaitingForPayment}
            label={buttonLabel}
            iconR={editable && <DropdownChevronsvgIcon width={'24px'} height={'24px'} />}
            color={
              fromElement && fromElement.__typename === 'CylinderGroupOrderNode'
                ? getStateColorLabel(fromElement.state as GQL.CylinderGroupOrderStates)
                : 'orange'
            }
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              editable && props.onOrderStatusClick && props.onOrderStatusClick(e, fromElement)
            }}
          />
        </>
      }
      {...plasmicProps}
    />
  )
}

const CellStatus = React.forwardRef(CellStatus_)
export default CellStatus
