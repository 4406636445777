// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type IconSmssvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function IconSmssvgIcon(props: IconSmssvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 14 14"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M7.227.691A6.309 6.309 0 001.403 9.43l-.895 2.456a.698.698 0 00.895.895l2.03-.74A6.31 6.31 0 107.227.691zM5.539 7.937a.937.937 0 110-1.874.937.937 0 010 1.874zm2.922 0a.937.937 0 11.002-1.874.937.937 0 01-.002 1.874z"
        }
      ></path>
    </svg>
  );
}

export default IconSmssvgIcon;
/* prettier-ignore-end */
