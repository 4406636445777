import styled from 'styled-components'

interface ILabelProps {
  hasIcon?: boolean
  fontSize?: string
  marginTop?: string
  error?: string
  required?: boolean
  flex?: boolean
}

const Label = styled.label<ILabelProps>`
  display: ${props => (props.flex ? 'flex' : 'block')};
  text-align: left;
  color: ${props => (props.error ? props.theme.colors.red : props.theme.colors.gray7)};
  margin-top: ${props => props.marginTop};
  margin-bottom: 5px;
  margin-left: ${props => (props.hasIcon ? '3rem' : '0')};
  text-transform: uppercase;
  font-size: ${props => props.fontSize};

  ${props =>
    props.required &&
    `
    ::after{
      content: '  *';
      color: ${props.theme.colors.red};
      font-size: 1rem;
    }
  `}
`

Label.defaultProps = {
  fontSize: '12px',
  marginTop: '1rem',
}

export default Label
