import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Select from 'react-select'

import CornerLoader from 'components/Loader/CornerLoader'
import * as GQL from 'generated/graphql'
import { displayToast } from 'util/toasts'
import { PERCENT_REGEX } from '../util'
import { useAppContext } from 'util/hooks'
import { Control, MenuList, Option, reactSelectStyles } from 'plasmic/StyledReactSelect'
import GeneralSettings from 'plasmic/GeneralSettings'
import SettingsContentWrapper from 'plasmic/SettingsContentWrapper'
import { omit } from 'lodash'
import { TutorialInfoIcon } from 'components/Tooltip/TutorialTooltip'

interface OptionType {
  value: string
  label: string
}

export default function SettingsGeneral() {
  const [changes, setChanges] = useState(false)

  const intl = useIntl()
  const t = intl.formatMessage

  const { appContext, setAppContext } = useAppContext()
  const distributor = appContext.distributor

  const { data: usersData } = GQL.useUsers()
  const { data: productsData } = GQL.useAllProducts({ variables: { service: false } })

  const availableProducts = productsData?.availableProducts?.edges
    .map(product => product?.node as GQL.ProductNode)
    .map(product => {
      return {
        value: product.id,
        label: product.displayName,
      }
    })

  const users = usersData?.allUsers?.edges?.map(edge => edge?.node as GQL.UserNode)

  const [formData, setFormData] = useState(() => ({
    fullName: distributor?.fullName || '',
    contactPerson: {
      value: distributor?.contactPerson?.id || '',
      label: distributor?.contactPerson?.fullName || '',
    },
    standardGasType: (distributor?.standardGasType as GQL.DistributorStandardGasType) || '',
    standardCylinderGasWeight: distributor?.standardCylinderGasWeight?.toString() || '',
    defaultProduct: {
      value: distributor?.defaultProduct?.id || '',
      label: distributor?.defaultProduct ? `${distributor.defaultProduct.displayName}` : '',
    },
    defaultCurrency: {
      value: distributor?.defaultCurrency || '',
      label: distributor?.defaultCurrency || '',
    },
    routingProvider: {
      label: distributor?.routingProvider || '',
      value: distributor?.routingProvider || '',
    },
    monitorTemperature: distributor?.monitorTemperature || false,
    monitorTemperatureNonSensor: distributor?.monitorTemperatureNonSensor || false,
    temperatureThreshold: distributor?.temperatureThreshold.toString() || '0',
    defaultTax: distributor?.defaultTax || '',
    defaultTaxDisplayName: distributor?.defaultTaxDisplayName || '',
  }))

  useEffect(() => {
    setFormData({
      fullName: distributor?.fullName || '',
      contactPerson: {
        value: distributor?.contactPerson?.id || '',
        label: distributor?.contactPerson?.fullName || '',
      },
      standardGasType: (distributor?.standardGasType as GQL.DistributorStandardGasType) || '',
      standardCylinderGasWeight: distributor?.standardCylinderGasWeight?.toString() || '',
      defaultProduct: {
        value: distributor?.defaultProduct?.id || '',
        label: distributor?.defaultProduct ? `${distributor?.defaultProduct.displayName}` : '',
      },
      defaultCurrency: {
        value: distributor?.defaultCurrency || '',
        label: distributor?.defaultCurrency || '',
      },
      routingProvider: {
        label: distributor?.routingProvider || '',
        value: distributor?.routingProvider || '',
      },
      monitorTemperature: distributor?.monitorTemperature || false,
      monitorTemperatureNonSensor: distributor?.monitorTemperatureNonSensor || false,
      temperatureThreshold: distributor?.temperatureThreshold.toString() || '0',
      defaultTax: distributor?.defaultTax || '',
      defaultTaxDisplayName: distributor?.defaultTaxDisplayName || '',
    })
  }, [t, appContext, distributor])

  const [patchDistributor, { loading }] = GQL.usePatchDistributor({
    onCompleted: data => {
      setAppContext(context => ({
        ...context,
        distributor: data.patchDistributor?.distributor as GQL.DistributorNode,
      }))
      displayToast(t({ id: 'settings.update-success' }), 'success')
      setChanges(false)
    },
    onError: error => {
      displayToast(error.message, 'error')
    },
  })

  const [toggleDistributorTemperatureGroups, { loading: toggleTemperatureGroupsLoading }] = GQL.useToggleDistributorTemperatureGroups({
    update: (cache, { data: redoData }) => {
      cache.writeFragment({
        id: cache.identify({ id: distributor?.id, __typename: 'DistributorNode' }),
        fragment: GQL.DistributorInfo,
        fragmentName: 'DistributorInfo',
        data: redoData?.toggleDistributorTemperatureGroups?.distributor,
      })
    },
    onCompleted: data => {
      setAppContext(context => ({
        ...context,
        distributor: data.toggleDistributorTemperatureGroups?.distributor as GQL.DistributorNode,
      }))
    },
    onError: error => {
      displayToast(error.message, 'error')
    },
  })

  const [toggleDistributorNonSensorTemperatureGroups, { loading: toggleTemperatureGroupsNonSensorLoading }] =
    GQL.useToggleDistributorNonSensorTemperatureGroups({
      update: (cache, { data: redoData }) => {
        cache.writeFragment({
          id: cache.identify({ id: distributor?.id, __typename: 'DistributorNode' }),
          fragment: GQL.DistributorInfo,
          fragmentName: 'DistributorInfo',
          data: redoData?.toggleDistributorNonSensorTemperatureGroups?.distributor,
        })
      },
      onCompleted: data => {
        setAppContext(context => ({
          ...context,
          distributor: data.toggleDistributorNonSensorTemperatureGroups?.distributor as GQL.DistributorNode,
        }))
      },
      onError: error => {
        displayToast(error.message, 'error')
      },
    })

  async function handleSubmit() {
    if (formData.fullName.length < 3 || loading || toggleTemperatureGroupsLoading || toggleTemperatureGroupsNonSensorLoading) {
      return
    }
    patchDistributor({
      variables: {
        id: distributor?.id!,
        input: {
          ...omit(formData, 'monitorTemperature', 'monitorTemperatureNonSensor'),
          contactPerson: formData.contactPerson.value ? formData.contactPerson.value : undefined,
          defaultProduct: formData.defaultProduct.value ? formData.defaultProduct.value : undefined,
          defaultCurrency: formData.defaultCurrency.label as GQL.Currency,
          standardCylinderGasWeight: parseFloat(formData.standardCylinderGasWeight),
          temperatureThreshold: parseFloat(formData.temperatureThreshold),
          defaultTax: formData.defaultTax,
          routingProvider: formData.routingProvider.label as GQL.RoutingProvider,
        },
      },
    })
    if (formData.monitorTemperature !== distributor?.monitorTemperature) {
      await toggleDistributorTemperatureGroups({ variables: { monitoringStatus: formData.monitorTemperature } })
    }
    if (formData.monitorTemperatureNonSensor !== distributor?.monitorTemperatureNonSensor) {
      toggleDistributorNonSensorTemperatureGroups({ variables: { monitoringStatus: formData.monitorTemperatureNonSensor } })
    }
  }

  useEffect(() => {
    if (
      formData.fullName !== distributor?.fullName ||
      (formData.contactPerson.value && formData.contactPerson.value !== distributor?.contactPerson?.id) ||
      (formData.defaultProduct.value && formData.defaultProduct.value !== distributor.defaultProduct?.id) ||
      (formData.defaultCurrency.value && formData.defaultCurrency.value !== distributor.defaultCurrency) ||
      (formData.defaultTax && formData.defaultTax !== distributor.defaultTax) ||
      (formData.defaultTaxDisplayName && formData.defaultTaxDisplayName !== distributor.defaultTaxDisplayName) ||
      formData.monitorTemperature !== distributor.monitorTemperature ||
      formData.monitorTemperatureNonSensor !== distributor.monitorTemperatureNonSensor ||
      formData.temperatureThreshold !== distributor.temperatureThreshold.toString() ||
      (formData.routingProvider && formData.routingProvider.value !== distributor.routingProvider)
    ) {
      setChanges(true)
    } else {
      setChanges(false)
    }
  }, [formData, distributor])

  return (
    <SettingsContentWrapper
      settingsTitle={t({ id: 'common.general' })}
      headerButtons={<></>}
      content={
        <>
          {loading && <CornerLoader size={50} />}
          <GeneralSettings
            edited={changes}
            labelContactPerson={t({ id: 'settings.contact-person' })}
            labelDefaultProduct={t({ id: 'common.default-product' })}
            labelCurrency={t({ id: 'common.currency' })}
            labelRoutingProvider={t({ id: 'settings.routing-provider' })}
            inputCompanyName={{
              props: {
                title: t({ id: 'settings.company-name' }),
                fieldStatus: changes,
                alert: changes && formData.fullName.length < 3,
                undefinedInput: {
                  value: formData.fullName,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setFormData({ ...formData, fullName: e.target.value })
                  },
                },
              },
            }}
            selectContactPersonDiv={
              <Select
                placeholder={t({ id: 'common.select-contact-person' })}
                components={{ Option, Control, MenuList }}
                styles={reactSelectStyles}
                isMulti={false}
                onChange={event => {
                  setFormData({ ...formData, contactPerson: event })
                  setChanges(true)
                }}
                value={
                  {
                    value: formData.contactPerson.value,
                    label: formData.contactPerson.label || t({ id: 'common.select-contact-person' }),
                  } as OptionType
                }
                options={users?.map(
                  user =>
                    ({
                      value: user?.id,
                      label: user?.fullName,
                    }) as OptionType
                )}
              />
            }
            selectDefaultProductDiv={
              <Select
                placeholder={t({ id: 'common.select-default-product' })}
                components={{ Option, Control, MenuList }}
                styles={reactSelectStyles}
                isMulti={false}
                onChange={event => {
                  setFormData({ ...formData, defaultProduct: event })
                  setChanges(true)
                }}
                value={
                  {
                    value: formData.defaultProduct.value,
                    label: formData.defaultProduct.label || t({ id: 'common.select-default-product' }),
                  } as OptionType
                }
                options={availableProducts}
              />
            }
            selectCurrencyDiv={
              <Select
                placeholder={t({ id: 'common.select-currency' })}
                components={{ Option, Control, MenuList }}
                styles={reactSelectStyles}
                isMulti={false}
                onChange={event => {
                  setFormData({ ...formData, defaultCurrency: event })
                  setChanges(true)
                }}
                value={
                  {
                    value: formData.defaultCurrency.value,
                    label: formData.defaultCurrency.label || t({ id: 'common.select-currency' }),
                  } as OptionType
                }
                options={
                  Object.entries(GQL.Currency).map(([key, value]) => {
                    return { value: key as GQL.Currency, label: value }
                  }) ?? []
                }
              />
            }
            selectRoutingProvider={
              <Select
                placeholder={t({ id: 'common.select-routing-provider' })}
                components={{ Option, Control, MenuList }}
                styles={reactSelectStyles}
                isMulti={false}
                onChange={event => {
                  setFormData({ ...formData, routingProvider: event })
                  setChanges(true)
                }}
                value={
                  {
                    label: formData.routingProvider.label,
                    value: formData.routingProvider.value,
                  } as OptionType
                }
                options={
                  Object.entries(GQL.RoutingProvider).map(([key, value]) => {
                    return { label: value, value: key }
                  }) ?? []
                }
              />
            }
            inputDefaultTax={{
              props: {
                title: t({ id: 'common.default-tax' }) + ' (%)',
                fieldStatus: changes,
                alert: changes && !formData.defaultTax,
                undefinedInput: {
                  value: formData.defaultTax,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setFormData({ ...formData, defaultTax: e.target.value })
                  },
                  onBlur: () => {
                    if (!PERCENT_REGEX.test(formData.defaultTax)) {
                      setFormData({ ...formData, defaultTax: distributor?.defaultTax || '' })
                    }
                  },
                },
              },
            }}
            inputTaxDisplayName={{
              props: {
                title: t({ id: 'common.tax-display-name' }),
                fieldStatus: changes,
                alert: changes && !formData.defaultTaxDisplayName,
                undefinedInput: {
                  value: formData.defaultTaxDisplayName,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setFormData({ ...formData, defaultTaxDisplayName: e.target.value })
                  },
                },
              },
            }}
            switchShowTemperatures={{
              props: {
                isChecked: formData.monitorTemperature,
                onChange: event => setFormData({ ...formData, monitorTemperature: event }),
              },
            }}
            switchIncludeNonSensorLocations={{
              props: {
                isChecked: formData.monitorTemperatureNonSensor,
                onChange: event => {
                  if (event && !distributor?.monitorTemperature && !formData.monitorTemperature) return
                  setFormData({ ...formData, monitorTemperatureNonSensor: event })
                },
              },
            }}
            inputTemperatureThreshold={{
              props: {
                fieldStatus: changes,
                alert: changes && formData.monitorTemperature && !formData.temperatureThreshold,
                undefinedInput: {
                  value: formData.temperatureThreshold,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, temperatureThreshold: event.target.value }),
                },
              },
            }}
            iconTooltipTemperature={<TutorialInfoIcon noMargin alwaysShow content={t({ id: 'tooltips.settings.general.temperature-monitoring' })} />}
            btnCancel={{
              props: {
                label: t({ id: 'common.cancel' }),
                onClick: () =>
                  setFormData({
                    fullName: distributor?.fullName || '',
                    contactPerson: {
                      value: distributor?.contactPerson?.id || '',
                      label: distributor?.contactPerson?.fullName || '',
                    },
                    standardGasType: (distributor?.standardGasType as GQL.DistributorStandardGasType) || '',
                    standardCylinderGasWeight: distributor?.standardCylinderGasWeight?.toString() || '',
                    defaultProduct: {
                      value: distributor?.defaultProduct?.id || '',
                      label: distributor?.defaultProduct ? `${distributor?.defaultProduct.type} ${parseFloat(distributor?.defaultProduct.weight)} kg` : '',
                    },
                    defaultCurrency: {
                      value: distributor?.defaultCurrency || '',
                      label: distributor?.defaultCurrency || '',
                    },
                    routingProvider: {
                      label: distributor?.routingProvider || '',
                      value: distributor?.routingProvider || '',
                    },
                    monitorTemperature: distributor?.monitorTemperature || false,
                    monitorTemperatureNonSensor: distributor?.monitorTemperatureNonSensor || false,
                    temperatureThreshold: distributor?.temperatureThreshold.toString() || '0',
                    defaultTax: distributor?.defaultTax || '',
                    defaultTaxDisplayName: distributor?.defaultTaxDisplayName || '',
                  }),
              },
            }}
            btnSaveChanges={{
              props: {
                label: t({ id: 'common.save-changes' }),
                onClick: handleSubmit,
              },
            }}
          />
        </>
      }
    />
  )
}
