// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicFiltersInventory, DefaultFiltersInventoryProps } from './plasmic/solace_components/PlasmicFiltersInventory'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import { omit } from 'lodash'

import * as GQL from 'generated/graphql'
// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface FiltersInventoryProps extends Omit<DefaultFiltersInventoryProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultFiltersInventoryProps altogether and have
// total control over the props for your component.
export interface FiltersInventoryProps extends DefaultFiltersInventoryProps {
  propaneCount: number
  co2Count: number
  gasType: GQL.GasTypeEnum[]
  onGasTypeChange: (gasType: GQL.GasTypeEnum[]) => void
  onClearFilters: () => void
}

function FiltersInventory_(props: FiltersInventoryProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicFiltersInventory to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicFiltersInventory are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all FiltersInventoryProps here, but feel free
  // to do whatever works for you.

  const handleSelectFilterPropaneClick = () => {
    if (props.gasType.includes(GQL.GasTypeEnum.Propane)) {
      props.onGasTypeChange(props.gasType.filter((type: GQL.GasTypeEnum) => type !== GQL.GasTypeEnum.Propane))
    } else {
      props.onGasTypeChange([...props.gasType, GQL.GasTypeEnum.Propane])
    }
  }

  const handleSelectFilterCo2Click = () => {
    if (props.gasType.includes(GQL.GasTypeEnum.Co2)) {
      props.onGasTypeChange(props.gasType.filter((type: GQL.GasTypeEnum) => type !== GQL.GasTypeEnum.Co2))
    } else {
      props.onGasTypeChange([...props.gasType, GQL.GasTypeEnum.Co2])
    }
  }

  return (
    <PlasmicFiltersInventory
      root={{ ref }}
      {...omit(props, 'gasType', 'onClearFilters', 'onGasTypeChange', 'propaneCount', 'co2Count')}
      selectFilterPropane={{
        filterChecked: props.gasType.includes(GQL.GasTypeEnum.Propane),
        count: props.propaneCount,
        onClick: handleSelectFilterPropaneClick,
      }}
      selectFilterCo2={{ filterChecked: props.gasType.includes(GQL.GasTypeEnum.Co2), count: props.co2Count, onClick: handleSelectFilterCo2Click }}
      btnClearFilters={{ onClick: props.onClearFilters }}
    />
  )
}

const FiltersInventory = React.forwardRef(FiltersInventory_)
export default FiltersInventory
