import { useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { format } from 'date-fns'

import * as GQL from 'generated/graphql'
import { TutorialInfoIcon } from 'components/Tooltip/TutorialTooltip'
import NotesTab from 'plasmic/NotesTab'
import { useClickOutside } from 'util/hooks'
import { displayToast } from 'util/toasts'
import NoteActionsDropdown from './NoteActionsDropdown'
import NoteElement from 'plasmic/NoteElement'

interface Props {
  cylinderGroup: GQL.CylinderGroupNode
}

const DRIVER_NOTE_TYPE_NAME = 'General notes'
const GENERAL_NOTE_TYPE_NAME = 'Driver notes'

export default function Notes({ cylinderGroup }: Props) {
  const [newDeliveryNote, setNewDeliveryNote] = useState('')
  const [newInternalNote, setNewInternalNote] = useState('')
  const [internalNotesOpen, setInternalNotesOpen] = useState(true)
  const [openedActionsNoteId, setOpenedActionsNoteId] = useState('')

  const intl = useIntl()
  const t = intl.formatMessage

  const noteActionsRef = useRef<HTMLElement>(null)
  const deliveryNoteTextAreaRef = useRef<HTMLTextAreaElement>(null)
  const internalNoteTextAreaRef = useRef<HTMLTextAreaElement>(null)

  useClickOutside(noteActionsRef, () => setOpenedActionsNoteId(''))

  const { data: notesData, refetch: refetchNotes } = GQL.useAllCylinderGroupNotes({
    variables: {
      cylinderGroup: cylinderGroup?.id || '',
    },
  })
  const notes = useMemo(() => {
    return notesData?.allCylinderGroupNotes as GQL.CylinderGroupNoteNode[] | undefined
  }, [notesData])

  const { data: noteTypesData } = GQL.useAllCylinderGroupNoteTypes()
  const noteTypes = useMemo(() => {
    return noteTypesData?.allCylinderGroupNoteTypes as GQL.CylinderGroupNoteTypeNode[] | undefined
  }, [noteTypesData])

  const [createNote] = GQL.useCreateCylinderGroupNote({
    onCompleted: () => {
      displayToast(t({ id: 'notes.toast.create-success' }), 'success')
      refetchNotes()
    },
    onError: () => {
      displayToast(t({ id: 'customers.toasts.error-generic' }), 'error')
    },
  })

  const [deleteNote] = GQL.useDeleteCylinderGroupNote({
    onCompleted: () => {
      displayToast(t({ id: 'notes.toast.delete-success' }), 'success')
      refetchNotes()
    },
    onError: () => {
      displayToast(t({ id: 'customers.toasts.error-generic' }), 'error')
    },
  })

  const handleInternalNoteCreateClick = () => {
    if (!cylinderGroup || !noteTypes || !newInternalNote) {
      displayToast(t({ id: 'customers.toasts.error-generic' }), 'error')
      return
    }

    const noteType = noteTypes.find(noteType => noteType.name === GENERAL_NOTE_TYPE_NAME)

    if (noteType === undefined) {
      displayToast(t({ id: 'customers.toasts.error-generic' }), 'error')
      return
    }

    createNote({
      variables: {
        note: newInternalNote,
        noteType: noteType.id,
        cylinderGroup: cylinderGroup.id,
        severity: GQL.CylinderGroupNoteSeverity.Normal,
      },
    }).then(() => {
      setNewInternalNote('')

      if (internalNoteTextAreaRef.current) {
        internalNoteTextAreaRef.current.style.height = ''
      }
    })
  }

  const handleDeliveryNoteCreateClick = () => {
    if (!cylinderGroup || !noteTypes || !newDeliveryNote) {
      displayToast(t({ id: 'customers.toasts.error-generic' }), 'error')
      return
    }

    const noteType = noteTypes.find(noteType => noteType.name === DRIVER_NOTE_TYPE_NAME)

    if (noteType === undefined) {
      displayToast(t({ id: 'customers.toasts.error-generic' }), 'error')
      return
    }

    createNote({
      variables: {
        note: newDeliveryNote,
        noteType: noteType.id,
        cylinderGroup: cylinderGroup.id,
        severity: GQL.CylinderGroupNoteSeverity.Normal,
      },
    }).then(() => {
      setNewDeliveryNote('')

      if (deliveryNoteTextAreaRef.current) {
        deliveryNoteTextAreaRef.current.style.height = ''
      }
    })
  }

  const handleNewDeliveryNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.style.height = ''
    event.target.style.height = event.target.scrollHeight + 2 + 'px'
    setNewDeliveryNote(event.target.value)
  }

  const handleNewInternalNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.style.height = ''
    event.target.style.height = event.target.scrollHeight + 2 + 'px'
    setNewInternalNote(event.target.value)
  }

  return (
    <NotesTab
      deliveryInstructions={{
        'data-testid': 'notes-delivery',
        helpText: <TutorialInfoIcon content={t({ id: 'tooltips.customers.drawer.tabs.delivery-notes' })} />,
      }}
      notesDelivery={{
        inputTextArea: {
          ref: deliveryNoteTextAreaRef,
          placeholder: t({ id: 'customers.write-note' }),
          value: newDeliveryNote,
          onChange: handleNewDeliveryNoteChange,
        },
        btnCreate: {
          onClick: handleDeliveryNoteCreateClick,
        },
        notes: notes
          ?.filter(note => note?.noteType.name === DRIVER_NOTE_TYPE_NAME)
          .map(note => (
            <NoteElement
              key={note.id}
              data-testid='note'
              metadata={true}
              author={note.createdBy?.fullName || note.author?.name}
              date={format(new Date(note.createdAt), 'dd MMMM yyyy')}
              content={note.note}
              btnMoreActions={{
                ref: note.id === openedActionsNoteId ? noteActionsRef : null,
                open: note.id === openedActionsNoteId,
                actionDropdown: <NoteActionsDropdown onDeleteClick={() => deleteNote({ variables: { id: note.id } })} />,
                onClick: () => setOpenedActionsNoteId(note.id),
              }}
            />
          )),
      }}
      internalNotes={{
        'data-testid': 'notes-internal',
        closed: !internalNotesOpen,
        btnCollapse: { onClick: () => setInternalNotesOpen(current => !current) },
        helpText: <TutorialInfoIcon content={t({ id: 'tooltips.customers.drawer.tabs.internal-notes' })} />,
      }}
      notesInternal={{
        inputTextArea: {
          ref: internalNoteTextAreaRef,
          placeholder: t({ id: 'customers.write-note' }),
          value: newInternalNote,
          onChange: handleNewInternalNoteChange,
        },
        btnCreate: {
          onClick: handleInternalNoteCreateClick,
        },
        notes: notes
          ?.filter(note => note?.noteType.name === GENERAL_NOTE_TYPE_NAME)
          .map(note => (
            <NoteElement
              key={note.id}
              data-testid='note'
              metadata={true}
              author={note.createdBy?.fullName}
              date={format(new Date(note.createdAt), 'dd MMMM yyyy')}
              content={note.note}
              btnMoreActions={{
                ref: note.id === openedActionsNoteId ? noteActionsRef : null,
                open: note.id === openedActionsNoteId,
                actionDropdown: <NoteActionsDropdown onDeleteClick={() => deleteNote({ variables: { id: note.id } })} />,
                onClick: () => setOpenedActionsNoteId(note.id),
              }}
            />
          )),
      }}
    />
  )
}
