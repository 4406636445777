import { useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import SettingsUsersUserActions from '../components/SettingsUsersUserActions'
import CornerLoader from 'components/Loader/CornerLoader'
import { useClickOutside } from 'util/hooks'
import CreateUserModal from '../components/CreateUserModal'
import EditUserModal from '../components/EditUserModal'
import * as GQL from 'generated/graphql'
import CacheConfigs from 'util/cacheConfig'
import UserRow from 'plasmic/UserRow'
import ManageUsers from 'plasmic/ManageUsers'
import SettingsContentWrapper from 'plasmic/SettingsContentWrapper'
import ButtonFill from 'plasmic/ButtonFill'
import ActiveInactiveWrapper from 'plasmic/ActiveInactiveWrapper'

export default function SettingsUsers() {
  const [createUserOpen, setCreateUserOpen] = useState(false)
  const [actionsOpen, setActionsOpen] = useState<string>('')
  const [editUser, setEditUser] = useState<GQL.UserNode | undefined>(undefined)
  const ref = useRef(null)

  useClickOutside(ref, () => setActionsOpen(''), false, true)

  const intl = useIntl()
  const t = intl.formatMessage

  const { data: dataUsers, loading: loadingUsers } = GQL.useUsers(CacheConfigs.ACCURATE_ONCE)
  const activeUsers = useMemo(() => dataUsers?.allUsers?.edges?.map(edge => edge?.node as GQL.UserNode).filter(user => !user.isDeleted) || [], [dataUsers])
  const inactiveUsers = useMemo(
    () => dataUsers?.allInactiveUsers?.edges?.map(edge => edge?.node as GQL.UserNode).filter(user => !user.isDeleted) || [],
    [dataUsers]
  )

  return (
    <SettingsContentWrapper
      settingsTitle={t({ id: 'common.users' })}
      headerButtons={<ButtonFill rounded color={'blue'} label={t({ id: 'settings.create-user' })} onClick={() => setCreateUserOpen(true)} />}
      content={
        <>
          <ActiveInactiveWrapper
            titleInactive={t({ id: 'common.users.inactive' })}
            activeWrapper={
              <ManageUsers
                users={activeUsers.map(user => (
                  <UserRow
                    key={user.id}
                    data-testid='user'
                    user={[
                      user.fullName,
                      user.driver?.id && !user.driver.deleted ? ', (driver)' : null,
                      user.isDeleted && ', (user deleted)',
                      user.driver?.deleted && ', (driver deleted)',
                    ]}
                    email={user.email || 'nd'}
                    phoneNumber={user.phoneNumber || 'nd'}
                    btnMoreActions={{
                      ref: actionsOpen === user.id ? ref : null,
                      btnMoreActions: {
                        name: 'moreActions',
                        onClick: () => setActionsOpen(user.id),
                      },
                      open: actionsOpen === user.id,
                      actionDropdown: <SettingsUsersUserActions user={user} onEditUser={() => setEditUser(user)} onClose={() => setActionsOpen('')} />,
                    }}
                  />
                ))}
              />
            }
            inactiveWrapper={
              <ManageUsers
                users={inactiveUsers.map(user => (
                  <UserRow
                    key={user.id}
                    data-testid='user'
                    user={[
                      user.fullName,
                      user.driver?.id && !user.driver.deleted ? ', (driver)' : null,
                      user.isDeleted && ', (user deleted)',
                      user.driver?.deleted && ', (driver deleted)',
                    ]}
                    email={user.email || 'nd'}
                    phoneNumber={user.phoneNumber || 'nd'}
                    btnMoreActions={{
                      ref: actionsOpen === user.id ? ref : null,
                      btnMoreActions: {
                        name: 'moreActions',
                        onClick: () => setActionsOpen(user.id),
                      },
                      open: actionsOpen === user.id,
                      actionDropdown: <SettingsUsersUserActions user={user} onEditUser={() => setEditUser(user)} onClose={() => setActionsOpen('')} inactive />,
                    }}
                  />
                ))}
              />
            }
          />
          <CreateUserModal open={createUserOpen} onClose={() => setCreateUserOpen(false)} />
          <EditUserModal user={editUser} open={!!editUser} onClose={() => setEditUser(undefined)} />
          {loadingUsers && <CornerLoader size={50} />}
        </>
      }
    />
  )
}
