import { AuditLogDetail, AuditLogDomain } from '../../types'
import Parser from '../Parser'
import * as GQL from 'generated/graphql'
class GenericCreateParser extends Parser {
  public details(entry: GQL.AuditLogEntryNode): AuditLogDetail[] {
    return [this.handleUpdateDetails(entry.objectRepr)]
  }

  public domains(entry: GQL.AuditLogEntryNode): AuditLogDomain[] {
    if (!entry.objectId) return []
    return [this.createDomainItem(entry.objectId.toString(), entry.objectRepr, entry.contentType?.model)]
  }

  private handleUpdateDetails(objectRepr: string) {
    return [this.createDetailItem(objectRepr.capitalizeFirst(), 'green'), this.createDetailItem(' was created')]
  }
}

export { GenericCreateParser }
