import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import * as GQL from 'generated/graphql'
import Modal from 'components/Modal/Modal'
import ModalCreateCustomer, { CustomerInfo, OpeningHour } from 'plasmic/ModalCreateCustomer'
import 'modules/customers/components/CellTagsCss.css'
import ConfirmModal from 'components/Modal/ConfirmModal'
import { displayToast } from 'util/toasts'
import { useAppContext, useCustomerContext } from 'util/hooks'
import { handleCustomerCreateValidation } from '../util'
import ModalStickyContainer from 'plasmic/ModalStickyContainer'
import { MandatoryField, MandatoryFieldObject } from 'plasmic/MandatoryFieldsPanel'
import { isValidAddress } from 'modules/localization/utils'

interface OptionType {
  value: string
  label: string
}

interface CreateCustomerModalProps {
  isOpen: boolean
  onClose: () => void
  createCustomerCallback: (customer: GQL.CustomerNode) => void
}

export default function CreateCustomerModal({ isOpen, createCustomerCallback, onClose }: CreateCustomerModalProps) {
  const intl = useIntl()
  const t = intl.formatMessage

  const [isClosing, setIsClosing] = useState(false)
  const [configureCylinderSetup, setConfigureCylinderSetup] = useState(false)
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>({
    name: '',
    customerIdentifier: '',
    phoneNumber: '',
    address: null,
    depot: {
      value: '',
      label: '',
    },
    orderMethod: GQL.CustomerOrderMethod.Phone,
    customerDomainType: GQL.CustomerDomainType.Business,
    pricingCategory: {
      value: '',
      label: '',
    },
    tags: [] as OptionType[],
    cylinderMode: GQL.CylinderGroupMode.Standard,
    product: {
      value: '',
      label: '',
    },
    cylinderSides: [
      {
        cylinderCount: 4,
      },
      {
        cylinderCount: 4,
      },
    ],
    trigger: {
      value: '',
      label: '',
    },
    alwaysOpen: true,
    openingHours: [] as OpeningHour[],
  })

  const [mandatoryFields, setMandatoryFields] = useState<MandatoryFieldObject[]>([
    {
      visible: true,
      key: MandatoryField.CUSTOMER_NAME,
      field: t({ id: 'common.customer-name' }),
      valid: !!customerInfo.name && customerInfo.name.length >= 3,
      id: 'input-customer-name',
    },
    {
      visible: true,
      key: MandatoryField.PHONE_NUMBER,
      field: t({ id: 'common.phone-number' }),
      valid: !!customerInfo.phoneNumber,
      id: 'input-phone-number',
    },
    {
      visible: true,
      key: MandatoryField.ADDRESS,
      field: t({ id: 'common.address' }),
      valid: isValidAddress(customerInfo.address),
      id: 'input-address',
    },
    {
      visible: false,
      key: MandatoryField.PRODUCT,
      field: t({ id: 'common.cylinder-product' }),
      valid: !!customerInfo.product.value,
      id: 'select-product',
    },
    {
      visible: true,
      key: MandatoryField.DEPOT,
      field: t({ id: 'common.depot' }),
      valid: !!customerInfo.depot.value,
      id: 'select-depot',
    },
  ])

  const { customersContext } = useCustomerContext()
  const appContext = useAppContext().appContext

  const updateMandatoryFields = useCallback(() => {
    setMandatoryFields(prevMandatoryFields => {
      const newMandatoryFields = prevMandatoryFields.map(field => {
        if (field.key === MandatoryField.CUSTOMER_NAME) {
          return { ...field, valid: !!customerInfo.name && customerInfo.name.length >= 3 }
        } else if (field.key === MandatoryField.ADDRESS) {
          return {
            ...field,
            valid: isValidAddress(customerInfo.address),
          }
        } else if (field.key === MandatoryField.PRODUCT) {
          return {
            ...field,
            visible: configureCylinderSetup,
            valid: !!customerInfo.product.value,
          }
        } else if (field.key === MandatoryField.DEPOT) {
          return {
            ...field,
            valid: !!customerInfo.depot.value,
          }
        }
        return field
      })
      return newMandatoryFields
    })
  }, [customerInfo.name, customerInfo.address, customerInfo.product.value, customerInfo.depot.value, configureCylinderSetup])

  useEffect(() => {
    updateMandatoryFields()
  }, [updateMandatoryFields])

  const handleClose = () => {
    setIsClosing(false)
    setCustomerInfo({
      name: '',
      customerIdentifier: '',
      phoneNumber: '',
      address: null,
      depot: {
        value: '',
        label: '',
      },
      orderMethod: GQL.CustomerOrderMethod.Phone,
      customerDomainType: GQL.CustomerDomainType.Business,
      pricingCategory: {
        value: '',
        label: '',
      },
      tags: [] as OptionType[],
      cylinderMode: GQL.CylinderGroupMode.Standard,
      product: {
        value: '',
        label: '',
      },
      cylinderSides: [
        {
          cylinderCount: 4,
        },
        {
          cylinderCount: 4,
        },
      ],
      trigger: {
        value: '',
        label: '',
      },
      alwaysOpen: true,
      openingHours: [] as OpeningHour[],
    })
    onClose()
  }

  const [createCustomer, { loading }] = GQL.useCreateCustomer({
    onCompleted: response => {
      if (response?.createCustomer?.ok && response?.createCustomer?.customer) {
        displayToast(t({ id: 'customers.create.success' }), 'success')
        setCustomerInfo({
          name: '',
          customerIdentifier: '',
          phoneNumber: '',
          address: null,
          depot: {
            value: appContext?.depot?.id || '',
            label: appContext?.depot?.name || '',
          },
          orderMethod: GQL.CustomerOrderMethod.Phone,
          customerDomainType: GQL.CustomerDomainType.Business,
          pricingCategory: {
            value: '',
            label: '',
          },
          tags: [] as OptionType[],
          cylinderMode: GQL.CylinderGroupMode.Standard,
          product: {
            value: '',
            label: '',
          },
          cylinderSides: [
            {
              cylinderCount: 4,
            },
            {
              cylinderCount: 4,
            },
          ],
          trigger: {
            value: '',
            label: '',
          },
          alwaysOpen: true,
          openingHours: [] as OpeningHour[],
        })
        createCustomerCallback(response.createCustomer.customer as GQL.CustomerNode)
        onClose()
      } else {
        displayToast(t({ id: 'customers.create.error' }))
      }
    },
    onError: error => {
      if (error.message.includes('phone_number')) {
        displayToast(t({ id: 'customers.toasts.error-bad-phone-number' }))
      } else if (error.message.includes('Customer with that name exists')) {
        displayToast(t({ id: 'customers.create-patch-customer.error.name-exists' }))
      } else if (error.message.includes('Customer with that customer identifier exists')) {
        displayToast(t({ id: 'customers.create-patch-customer.error.identifier-exists' }))
      } else {
        displayToast(t({ id: 'customers.create.error' }))
      }
    },
  })

  const handleCreateCustomer = () => {
    createCustomer({
      variables: {
        input: {
          name: customerInfo.name,
          customerIdentifier: customerInfo.customerIdentifier,
          phoneNumber: customerInfo.phoneNumber,
          address: customerInfo.address as GQL.CreateAddressInput,
          orderMethod: customerInfo.orderMethod,
          customerDomainType: customerInfo.customerDomainType,
          depot: customerInfo.depot.value,
          tags: customerInfo.tags.map(tag => tag.value),
          priceCategory: customerInfo.pricingCategory.value,
          cylinderGroups: configureCylinderSetup
            ? [
                {
                  product: customerInfo.product.value,
                  thresholdId: customerInfo.trigger.value ? customerInfo.trigger.value : null,
                  cylinderSides: customerInfo.cylinderMode === GQL.CylinderGroupMode.Standard ? customerInfo.cylinderSides : [customerInfo.cylinderSides[0]],
                  cylinderMode: customerInfo.cylinderMode,
                },
              ]
            : null,
          alwaysOpen: customerInfo.alwaysOpen,
          openingHours: customerInfo.openingHours,
        },
      },
    })
  }

  const validateCustomer = () => {
    // workaround for phone number check
    if (mandatoryFields.some(field => field.visible && !field.valid)) {
      displayToast(t({ id: 'common.form-validation.error' }))
      return
    }
    handleCustomerCreateValidation(customerInfo, configureCylinderSetup, customersContext, handleCreateCustomer, intl)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsClosing(true)}
      stripped
      overlayStyle={{ alignItems: 'flex-start', overflow: 'auto', padding: '2rem 0rem' }}
    >
      <ModalStickyContainer
        mandatoryFieldsPanel={{
          mandatoryFieldsObject: mandatoryFields,
          btnSubmit: {
            onClick: () => validateCustomer(),
          },
        }}
        modalDiv={
          <ModalCreateCustomer
            customerInfo={customerInfo}
            setCustomerInfo={setCustomerInfo}
            configureCylinderSetup={configureCylinderSetup}
            setConfigureCylinderSetup={setConfigureCylinderSetup}
            onClose={() => setIsClosing(true)}
            loading={loading}
            handleSubmit={() => validateCustomer()}
            mandatoryFields={mandatoryFields}
            setMandatoryFields={setMandatoryFields}
          />
        }
      />

      <ConfirmModal
        title={t({ id: 'common.discard-changes' }) + '?'}
        description={t({ id: 'customers.create-update.confirm-modal.description' })}
        isOpen={isClosing}
        onRequestClose={() => handleClose()}
        onConfirm={() => {
          setIsClosing(false)
          validateCustomer()
        }}
        onAbort={() => setIsClosing(false)}
        color='green'
        abortTitle={t({ id: 'common.discard-changes' })}
        confirmTitle={t({ id: 'common.save-changes' })}
      />
    </Modal>
  )
}
