import React from 'react'
import * as GQL from 'generated/graphql'
import ActionDropdownLine from 'plasmic/ActionDropdownLine'
import { displayToast } from 'util/toasts'

interface SettingsUsersUserActionsProps {
  user: GQL.UserNode
  onEditUser: () => void
  onClose: () => void
  inactive?: boolean
}

const SettingsUsersUserActions: React.FC<SettingsUsersUserActionsProps> = ({ user, onEditUser, onClose, inactive }) => {
  const [editUser] = GQL.usePatchUser({
    refetchQueries: ['Users'],
  })

  return (
    <>
      <ActionDropdownLine
        onClick={(e: any) => {
          e.preventDefault()
          e.stopPropagation()
          onEditUser()
          onClose()
        }}
      >
        Edit User
      </ActionDropdownLine>
      <ActionDropdownLine
        onClick={() => {
          onClose()
          editUser({
            variables: {
              id: user.id,
              input: {
                isActive: !!inactive,
              },
            },
            onCompleted: () => {
              displayToast(inactive ? 'User made active!' : 'User made inactive', 'success')
            },
            onError: () => {
              displayToast(inactive ? 'There was an error while making the user active' : 'There was an error while making the user inactive', 'error')
            },
          })
        }}
      >
        {inactive ? 'Make user active' : 'Make user inactive'}
      </ActionDropdownLine>
      <ActionDropdownLine
        onClick={() => {
          onClose()
          editUser({
            variables: {
              id: user.id,
              input: {
                isDeleted: true,
                isActive: false,
              },
            },
            onCompleted: () => {
              displayToast('User Deleted!', 'success')
            },
            onError: () => {
              displayToast('There was an error while deleting the user!', 'error')
            },
          })
        }}
      >
        Delete User
      </ActionDropdownLine>
    </>
  )
}

export default SettingsUsersUserActions
