import styled from 'styled-components'
import { overloadColorProp } from 'util/style'

const Rule = styled.span<{ height?: string; color?: string }>`
  display: block;
  width: 100%;
  height: ${props => props.height};
  background-color: ${overloadColorProp};
`

Rule.defaultProps = {
  color: 'veryLightGray',
  height: '1px',
}

export default Rule
