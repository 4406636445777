import React, { ChangeEvent } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import * as GQL from 'generated/graphql'
import TopActions from 'plasmic/TopActions'
import ButtonFill from 'plasmic/ButtonFill'
import FiltersInventory from 'plasmic/FiltersInventory'
import OptimizeOnsvgIcon from 'plasmic/plasmic/solace_components/icons/PlasmicIcon__OptimizeOnsvg'

const Wrapper = styled.div`
  position: sticky;
  left: 0;
  z-index: 99;
`

interface Props {
  search: string
  propaneCount: number
  co2Count: number
  onSearchChange: (value: string) => void
  gasType: GQL.GasTypeEnum[]
  onGasTypeChange: (gasType: GQL.GasTypeEnum[]) => void
  onClearFilters: () => void
  onRightButtonClicked: () => void
}

const InventoryHeader: React.FC<Props> = props => {
  const intl = useIntl()
  const t = intl.formatMessage

  return (
    <Wrapper>
      <TopActions
        style={{ minHeight: '66px' }}
        searchField={{
          fieldPlaceholder: t({ id: 'inventory.search-input.placeholder' }),
          undefinedInput: {
            value: props.search,
            onChange: (e: ChangeEvent<HTMLInputElement>) => props.onSearchChange(e.target.value),
          },
        }}
        buttonsRight={
          <ButtonFill rounded iconRight color={'purple'} label='Configure customer assets' iconR={<OptimizeOnsvgIcon />} onClick={props.onRightButtonClicked} />
        }
      />
      <FiltersInventory
        propaneCount={props.propaneCount}
        co2Count={props.co2Count}
        gasType={props.gasType}
        onGasTypeChange={props.onGasTypeChange}
        onClearFilters={props.onClearFilters}
      />
    </Wrapper>
  )
}

export { InventoryHeader }
