import styled, { css } from 'styled-components'

interface IFlexCenter {
  horizontal?: boolean
  vertical?: boolean
  fillParent?: boolean
  direction?: 'row' | 'column'
}

export default styled.div<IFlexCenter>`
  display: flex;
  justify-content: ${props => (props.horizontal ? 'center' : 'auto')};
  align-items: ${props => (props.vertical ? 'center' : 'auto')};
  width: ${props => (props.fillParent ? '100%' : 'auto')};
  height: ${props => (props.fillParent ? '100%' : 'auto')};
  ${props =>
    props.direction &&
    css`
      flex-direction: ${props.direction};
    `}
`
