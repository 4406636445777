// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicInventoryCard, DefaultInventoryCardProps } from './plasmic/solace_components/PlasmicInventoryCard'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import { omit } from 'lodash'

import * as GQL from 'generated/graphql'
import ActionDropdownLine from './ActionDropdownLine'
import InventoryCardRowDepot from './InventoryCardRowDepot'
import InventoryCardRowVehicle from './InventoryCardRowVehicle'
import InventoryCardRowCustomer from './InventoryCardRowCustomer'
import { AdjustStock, EditStock, TransferStock } from 'modules/inventory'
import { useClickOutside } from 'util/hooks'
import { useNavigate } from 'react-router-dom'
import LabelStatusChip from './LabelStatusChip'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface InventoryCardProps extends Omit<DefaultInventoryCardProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultInventoryCardProps altogether and have
// total control over the props for your component.
export interface InventoryCardProps extends DefaultInventoryCardProps {
  productInventories: GQL.ProductInventoriesNode
  setAdjustStockInfo: (adjustStock: AdjustStock) => void
  setEditStockInfo: (editStock: EditStock) => void
  setTransferStockInfo: (transferStock: TransferStock) => void
}

interface CustomerLocationStockInfo {
  readyForExchange: number
  notEmpty: number
  lost: number
}
interface DepotStorageLocationStockInfo {
  reserved: number
  notReserved: number
}

interface StockComparisonInfo {
  totalCustomer: number
  percentageCustomer: number
  totalDepotStorage: number
  percentageDepotStorage: number
}

function InventoryCard_(props: InventoryCardProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicInventoryCard to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicInventoryCard are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all InventoryCardProps here, but feel free
  // to do whatever works for you.
  const [closed, setClosed] = React.useState(true)
  const {
    displayName: productDisplayName,
    image: productImage,
    depotStorageInventories,
    vehicleInventories,
    cylinderGroupInventories,
  } = props.productInventories
  const [moreActionsDepotTotalIndex, setMoreActionsDepotTotalIndex] = React.useState<number>()
  const [moreActionsCustomerLostIndex, setMoreActionsCustomerLostIndex] = React.useState<number>()
  const [moreActionsCustomerTotalIndex, setMoreActionsCustomerTotalIndex] = React.useState<number>()
  const [moreActionsTop, setMoreActionsTop] = React.useState(false)
  const moreActionsRef = React.useRef(null)
  const moreActionsTotalRef = React.useRef(null)
  const navigate = useNavigate()

  const cylinderGroupInfo: CustomerLocationStockInfo = React.useMemo(() => {
    return {
      readyForExchange: cylinderGroupInventories?.reduce((accumulated, inventory) => accumulated + (inventory?.amountEmpty || 0), 0) || 0,
      notEmpty: cylinderGroupInventories?.reduce((accumulated, inventory) => accumulated + (inventory?.amountNotEmpty || 0), 0) || 0,
      lost: cylinderGroupInventories?.reduce((accumulated, inventory) => accumulated + (inventory?.amountLost || 0), 0) || 0,
    }
  }, [cylinderGroupInventories])
  const depotStorageInfo: DepotStorageLocationStockInfo = React.useMemo(() => {
    return {
      reserved: depotStorageInventories?.reduce((accumulated, inventory) => accumulated + (inventory?.amountReserved || 0), 0) || 0,
      notReserved:
        depotStorageInventories?.reduce(
          (accumulated, inventory) => accumulated + ((inventory?.totalAmountOfCylinders || 0) - (inventory?.amountReserved || 0)),
          0
        ) || 0,
    }
  }, [depotStorageInventories])

  const totalCylinderGroupCylinders = React.useMemo(
    () => cylinderGroupInventories?.reduce((accumulated, inventory) => accumulated + (inventory?.totalAmountOfCylinders || 0), 0) || 0,
    [cylinderGroupInventories]
  )
  const totalDepotStorageCylinders = React.useMemo(
    () => depotStorageInventories?.reduce((accumulated, inventory) => accumulated + (inventory?.totalAmountOfCylinders || 0), 0) || 0,
    [depotStorageInventories]
  )

  const stockComparisonInfo: StockComparisonInfo = React.useMemo(() => {
    const total = totalCylinderGroupCylinders + totalDepotStorageCylinders
    return {
      totalCustomer: totalCylinderGroupCylinders,
      totalDepotStorage: totalDepotStorageCylinders,
      percentageCustomer: (totalCylinderGroupCylinders / total) * 100,
      percentageDepotStorage: (totalDepotStorageCylinders / total) * 100,
    }
  }, [totalCylinderGroupCylinders, totalDepotStorageCylinders])

  const handleAdjustStock = React.useCallback(
    (
      inventory?: GQL.InventoryNode | null,
      stockState: GQL.InventoryStockState = GQL.InventoryStockState.Empty,
      currentQuantity?: number | null,
      total?: boolean
    ) => {
      if (!props.productInventories) return
      props.setAdjustStockInfo({
        product: props.productInventories,
        inventory: inventory,
        stockState: stockState,
        currentQuantity: currentQuantity,
        adjustTotal: total,
      })
    },
    [props.setAdjustStockInfo, props.productInventories]
  )

  const handleEditStock = React.useCallback(
    (
      inventory?: GQL.InventoryNode | null,
      stockState: GQL.InventoryStockState = GQL.InventoryStockState.NotEmpty,
      currentQuantity?: number | null,
      total?: boolean
    ) => {
      if (!inventory || currentQuantity === null || currentQuantity === undefined) return
      props.setEditStockInfo({ inventory: inventory, stockState: stockState, currentQuantity: currentQuantity, adjustTotal: total })
    },
    [props.setEditStockInfo]
  )

  const handleTransferStock = React.useCallback(
    (
      fromInventory?: GQL.InventoryNode | null,
      toInventory?: GQL.InventoryNode | null,
      fromStockState: GQL.InventoryStockState = GQL.InventoryStockState.Empty,
      toStockState: GQL.InventoryStockState = GQL.InventoryStockState.Empty
    ) => {
      if (!props.productInventories) return
      props.setTransferStockInfo({
        product: props.productInventories,
        toInventory: toInventory,
        fromInventory: fromInventory,
        fromStockState: fromStockState,
        toStockState: toStockState,
      })
    },
    [props.setTransferStockInfo, props.productInventories]
  )

  const handleClosed = () => {
    setClosed(!closed)
  }

  useClickOutside(moreActionsRef, () => {
    setMoreActionsDepotTotalIndex(undefined)
    setMoreActionsCustomerLostIndex(undefined)
    setMoreActionsCustomerTotalIndex(undefined)
    setMoreActionsTop(false)
  })

  useClickOutside(moreActionsTotalRef, () => {
    setMoreActionsDepotTotalIndex(undefined)
    setMoreActionsCustomerLostIndex(undefined)
    setMoreActionsCustomerTotalIndex(undefined)
    setMoreActionsTop(false)
  })

  return (
    <PlasmicInventoryCard
      root={{ ref }}
      {...omit(props, 'productInventories', 'setEditStockInfo', 'setAdjustStockInfo', 'setTransferStockInfo')}
      closed={closed}
      headToggleOpen={{ onClick: handleClosed }}
      // Head
      productTitle={productDisplayName || ''}
      noImage={!productImage?.image}
      productImage={productImage?.image}
      customersTotalAssetsHead={totalCylinderGroupCylinders.toString()}
      stockTotalAssetsHead={totalDepotStorageCylinders.toString()}
      reservedStockHead={depotStorageInfo.notReserved.toString()}
      btnMoreActions={{
        props: {
          'data-testid': 'more-actions-head',
          onClick: () => setMoreActionsTop(true),
          open: moreActionsTop,
          ref: moreActionsTop ? moreActionsRef : null,
          actionDropdown: (
            <React.Fragment>
              <ActionDropdownLine children='Adjust stock' onClick={() => handleAdjustStock(undefined, undefined, undefined, true)} />
              <ActionDropdownLine children='Transfer stock' onClick={() => handleTransferStock()} />
            </React.Fragment>
          ),
        },
      }}
      // At customers locations
      customersReadyForExchange={cylinderGroupInfo.readyForExchange.toString()}
      customersNotEmpty={cylinderGroupInfo.notEmpty.toString()}
      customersLost={cylinderGroupInfo.lost.toString()}
      customersTotalAssets={totalCylinderGroupCylinders.toString()}
      // In stock
      reservedStock={depotStorageInfo.reserved.toString()}
      notReservedStock={depotStorageInfo.notReserved.toString()}
      stockTotalAssets={totalDepotStorageCylinders.toString()}
      // Comparison
      customersTotalAssetsComparison={stockComparisonInfo.totalCustomer.toString()}
      customersTotalAssetsPercentage={`${Math.round(stockComparisonInfo.percentageCustomer || 0)}%`}
      stockTotalAssetsComparison={stockComparisonInfo.totalDepotStorage.toString()}
      stockTotalAssetsPercentage={`${
        Math.round(stockComparisonInfo.percentageDepotStorage || 0) || 0 - Math.round(stockComparisonInfo.percentageCustomer || 0)
      }%`}
      customersTotalAssetsBar={{
        style: { width: `${stockComparisonInfo.percentageCustomer.toString()}%` },
      }}
      stockTotalAssetsBar={{
        style: { width: `${stockComparisonInfo.percentageDepotStorage || 100 - stockComparisonInfo.percentageCustomer}%` },
      }}
      rowsDepots={
        depotStorageInventories && depotStorageInventories?.length > 0 ? (
          depotStorageInventories.map((inventory, index) => (
            <InventoryCardRowDepot
              data-testid='depot-stock'
              key={inventory?.id}
              depot={inventory?.belongsTo?.name || 'Depot inventory'}
              reserved={(inventory?.amountReserved || 0).toString()}
              notReserved={((inventory?.totalAmountOfCylinders || 0) - (inventory?.amountReserved || 0)).toString()}
              total={((inventory?.amountNotEmpty || 0) + (inventory?.amountEmpty || 0) + (inventory?.amountLost || 0)).toString()}
              btnMoreActionsTotal={{
                props: {
                  'data-testid': 'more-actions-total',
                  onClick: () => setMoreActionsDepotTotalIndex(index),
                  ref: index === moreActionsDepotTotalIndex ? moreActionsRef : null,
                  open: index === moreActionsDepotTotalIndex,
                  actionDropdown: (
                    <React.Fragment>
                      <ActionDropdownLine
                        children='Adjust stock'
                        onClick={() => handleAdjustStock(inventory, GQL.InventoryStockState.Empty, undefined, true)}
                      />
                      <ActionDropdownLine
                        children='Transfer stock'
                        onClick={() => handleTransferStock(inventory, null, GQL.InventoryStockState.Empty, GQL.InventoryStockState.Empty)}
                      />
                    </React.Fragment>
                  ),
                },
              }}
            />
          ))
        ) : (
          <LabelStatusChip icon={'info'} title={'No depots'} style={{ margin: 'auto' }} />
        )
      }
      rowsVehicles={
        vehicleInventories && vehicleInventories?.length > 0 ? (
          vehicleInventories.map(inventory => (
            <InventoryCardRowVehicle
              data-testid='vehicle-stock'
              key={inventory?.id}
              vehicle={inventory?.belongsTo?.name || 'Vehicle inventory'}
              total={((inventory?.amountNotEmpty || 0) + (inventory?.amountEmpty || 0) + (inventory?.amountLost || 0)).toString()}
            />
          ))
        ) : (
          <LabelStatusChip icon={'info'} title={'No vehicles'} style={{ margin: 'auto' }} />
        )
      }
      rowsCustomers={
        cylinderGroupInventories && cylinderGroupInventories?.length > 0 ? (
          cylinderGroupInventories?.map((inventory, index) => (
            <InventoryCardRowCustomer
              data-testid='customer-stock'
              key={inventory?.id}
              customer={inventory?.belongsTo?.__typename === 'CylinderGroupNode' ? inventory?.belongsTo?.customer?.name : 'Customer inventory'}
              customerLink={{
                onClick: () => {
                  if (inventory?.belongsTo?.__typename !== 'CylinderGroupNode') return
                  navigate(`?customer=${inventory?.belongsTo?.customer.id}`)
                },
              }}
              lost={(inventory?.amountLost || 0).toString()}
              notEmpty={(inventory?.amountNotEmpty || 0).toString()}
              readyForExchange={(inventory?.amountEmpty || 0).toString()}
              total={((inventory?.amountNotEmpty || 0) + (inventory?.amountEmpty || 0) + (inventory?.amountLost || 0)).toString()}
              btnMoreActionsLost={{
                props: {
                  'data-testid': 'more-actions-lost',
                  onClick: () => setMoreActionsCustomerLostIndex(index),
                  open: index === moreActionsCustomerLostIndex,
                  ref: index === moreActionsCustomerLostIndex ? moreActionsRef : null,
                  actionDropdown: (
                    <React.Fragment>
                      <ActionDropdownLine
                        children='Adjust stock'
                        onClick={() => handleEditStock(inventory, GQL.InventoryStockState.Lost, inventory?.amountLost, false)}
                      />
                    </React.Fragment>
                  ),
                },
              }}
              btnMoreActionsTotal={{
                props: {
                  'data-testid': 'more-actions-total',
                  onClick: () => setMoreActionsCustomerTotalIndex(index),
                  open: index === moreActionsCustomerTotalIndex,
                  ref: index === moreActionsCustomerTotalIndex ? moreActionsTotalRef : null,
                  actionDropdown: (
                    <React.Fragment>
                      <ActionDropdownLine
                        children='Adjust stock'
                        onClick={() => handleEditStock(inventory, GQL.InventoryStockState.NotEmpty, inventory?.totalAmountOfCylinders, true)}
                      />
                    </React.Fragment>
                  ),
                },
              }}
            />
          ))
        ) : (
          <LabelStatusChip icon={'info'} title={'No customers'} style={{ margin: 'auto' }} />
        )
      }
    />
  )
}

const InventoryCard = React.forwardRef(InventoryCard_)
export default InventoryCard
