import React from 'react'
import styled from 'styled-components'
import { overloadColorProp } from 'util/style'
import Circle from 'components/Circle'

interface IInnerIconProps {
  margin?: string
  size?: string | number
  fixedWidth?: boolean
  color?: string
}

const InnerIcon = styled.i<IInnerIconProps>`
  color: ${overloadColorProp};
  margin: ${props => props.margin};
  font-size: ${props => props.size};

  ${props =>
    props.fixedWidth &&
    `
    width: 1em;
    display: inline-flex;
    justify-content: center;
  `};
`

interface IScIconProps {
  icon: string
  color?: string
  margin?: string
  fixedWidth?: boolean
  size?: string | number
}

export default function ScIcon({ icon, color, margin = '0', fixedWidth = false, size = '8px' }: IScIconProps) {
  const extendedIcon = 'sc ' + icon

  const overloadedSize = typeof size === 'string' ? size : size + 'px'

  return <InnerIcon fixedWidth={fixedWidth} className={extendedIcon} color={color} margin={margin} size={overloadedSize} />
}

ScIcon.GreenCircle = ({ color, margin, fixedWidth, size = '8' }: IInnerIconProps) => (
  <Circle color='green' size={(typeof size === 'string' ? parseInt(size) : size) + 10}>
    <ScIcon icon='sc-check' color={color} margin={margin} fixedWidth={fixedWidth} />
  </Circle>
)

ScIcon.OrangeAlert = ({ margin, fixedWidth, size = 16 }: IInnerIconProps) => (
  <ScIcon icon='sc-alert' color='orange' margin={margin} fixedWidth={fixedWidth} size={size} />
)
