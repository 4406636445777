import * as React from 'react'
import * as GQL from 'generated/graphql'

import { PlasmicProgressBarPercent } from './plasmic/solace_components/PlasmicProgressBarPercent'
import { SIDE_UNIT_PROPS } from 'modules/customers/consts'
import { getThresholdProps } from 'modules/customers/util'

export interface Side {
  daysRemaining: number
  activeCycleRemainingPercentage: number
  progressLabel: string
  depleting: boolean
  monitored: boolean
  hidePercent?: boolean
  progressColor?: 'gray' | 'blue' | 'red' | 'green' | 'purple' | 'orange'
  errorState?: 'noConnection' | 'noisyData' | 'zeroWeight' | 'off'
}

interface Props {
  threshold?: GQL.DistributorRefillThresholdNode | null
  side?: Side
}

function ProgressBarPercent_(
  {
    threshold,
    side = {
      daysRemaining: 10,
      activeCycleRemainingPercentage: 100,
      progressLabel: 'Unknown',
      progressColor: 'gray',
      depleting: false,
      monitored: false,
      errorState: 'noConnection',
      hidePercent: false,
    },
    ...props
  }: Props,
  ref: any
) {
  const calculateWidthPercentage = () => {
    return Math.min(Math.max(side.daysRemaining * 10, 0), 100)
  }
  const widthPercentage = calculateWidthPercentage()
  const thresholdProps = getThresholdProps({ ...SIDE_UNIT_PROPS.days }, threshold)
  const thresholdPosition = threshold ? Math.max(0, 100 - Math.min(threshold.threshold, 100) * 10) : 0
  const thresholdInfo = {
    style: { left: `${Math.min(thresholdPosition, 100)}%` },
    ...thresholdProps.triggerIco,
  }

  return (
    <PlasmicProgressBarPercent
      root={{ ref }}
      {...props}
      days={side.progressLabel}
      percent={`${side.activeCycleRemainingPercentage}%`}
      hidePercent={side.monitored ? side.hidePercent : true}
      fill={{
        props: {
          style: { width: `${widthPercentage}%` },
          depleting: !!side.depleting,
          color: side.progressColor,
        },
      }}
      trigger={!!side.monitored && !!thresholdProps.trigger}
      triggerIcon={thresholdInfo}
      errorState={side.errorState}
    />
  )
}
const ProgressBarPercent = React.forwardRef(ProgressBarPercent_)
export default ProgressBarPercent
