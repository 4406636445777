// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type SmsBubblesvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function SmsBubblesvgIcon(props: SmsBubblesvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 40.369 28.221"}
      xmlSpace={"preserve"}
      height={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M8.369 0v11.77c0 6.738-3.299 12.706-8.369 16.38.709.047 1.422.071 2.143.071a31.688 31.688 0 0015.386-3.958 21.031 21.031 0 0011.99 3.724h10.85V0h-32z"
        }
      ></path>
    </svg>
  );
}

export default SmsBubblesvgIcon;
/* prettier-ignore-end */
