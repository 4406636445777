import Button from './Button'
import Circle from './Circle'
import Collapse from './Collapse'
import { CollapseBar } from './Collapse'
import Form from './Form'
import FormField from './FormField'
import AutoComplete from './Input/AutoComplete'
import Label from './Label'
import Margin from './Margin'
import Padding from './Padding'
import Rule from './Rule'
import Table from './Table'
import Tabs from './Tabs'
import TooltipIcon from './TooltipIcon'

const Components = {
  AutoComplete,
  Button,
  Circle,
  Collapse,
  CollapseBar,
  Form,
  FormField,
  Label,
  Margin,
  Padding,
  Rule,
  Tabs,
  Table,
  TooltipIcon,
}

export default Components
