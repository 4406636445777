// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicProfileSettings, DefaultProfileSettingsProps } from './plasmic/solace_components/PlasmicProfileSettings'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

import * as p from '@plasmicapp/react-web'
import Switch from './Switch'
import InputField from './InputField'
import InputPhoneNumberWrapper from './InputPhoneNumberWrapper'
import ButtonLight from './ButtonLight'
import ButtonFill from './ButtonFill'
// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface ProfileSettingsProps extends Omit<DefaultProfileSettingsProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultProfileSettingsProps altogether and have
// total control over the props for your component.
export interface ProfileSettingsProps extends DefaultProfileSettingsProps {
  inputName?: p.Flex<typeof InputField>
  inputEmail?: p.Flex<typeof InputField>
  inputPhoneNumberWrapper?: p.Flex<typeof InputPhoneNumberWrapper>
  switchEnableTwoFactor?: p.Flex<typeof Switch>
  btnCancel?: p.Flex<typeof ButtonLight>
  btnSaveChanges?: p.Flex<typeof ButtonFill>
}

function ProfileSettings_(props: ProfileSettingsProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicProfileSettings to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicProfileSettings are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all ProfileSettingsProps here, but feel free
  // to do whatever works for you.

  return <PlasmicProfileSettings root={{ ref }} {...props} />
}

const ProfileSettings = React.forwardRef(ProfileSettings_)
export default ProfileSettings
