import React from 'react'
import ReactModal from 'react-modal'

import * as GQL from 'generated/graphql'
import { displayToast } from 'util/toasts'
import Loader from 'components/Loader'
import { modalStyle } from 'components/Modal/PlasmicModal'
import PhoneInput from 'components/Input/PhoneInput'
import ModalContact from 'plasmic/ModalContact'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

function CustomerContactModal({
  onClose,
  customer,
  contact,
  refetchQueries,
}: {
  onClose: () => void
  customer: string
  refetchQueries?: string[]
  contact?: GQL.CustomerContactNode
}) {
  const intl = useIntl()
  const t = intl.formatMessage
  const [contactInfo, setContactInfo] = React.useState<{ name: string; phone: string; email: string; position: string }>({
    name: contact && contact.name ? contact.name : '',
    phone: contact && contact.phone ? contact.phone : '',
    email: contact && contact.email ? contact.email : '',
    position: contact && contact.position ? contact.position : '',
  })

  const [createContact, { loading }] = GQL.useCreateCustomerContact({
    refetchQueries: refetchQueries ? refetchQueries : [],
    onCompleted: response => {
      onClose()
      if (!!response.createCustomerContact?.warning) {
        displayToast(t({ id: 'customers.contact.create.success-with-warning' }), 'warning')
      } else {
        displayToast('Contact created successfully', 'success')
      }
    },
    onError: err => {
      displayToast(err.message, 'error')
    },
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          allCustomerContacts(existingContacts) {
            return {
              ...existingContacts,
              edges: [...existingContacts.edges, { node: data?.createCustomerContact?.customerContact }],
            }
          },
        },
      })
    },
  })

  const [patchContact] = GQL.usePatchCustomerContact({
    refetchQueries: refetchQueries ? [...refetchQueries, 'AllCustomerContacts'] : ['AllCustomerContacts'],
    onCompleted: () => {
      displayToast('Successfully updated contact.', 'success')
    },
  })

  const { register, handleSubmit, formState, setValue, getValues, watch, setError, clearErrors } = useForm({
    mode: 'all',
    defaultValues: contactInfo,
  })

  const { errors } = formState

  const doSubmit = (data: any) => {
    if (contact) {
      patchContact({
        variables: {
          id: contact.id,
          input: {
            customer: customer,
            ...data,
            position: contactInfo.position,
          },
        },
      })
    } else {
      createContact({
        variables: {
          input: {
            customer: customer,
            ...data,
            position: contactInfo.position,
          },
        },
      })
    }
    onClose()
  }

  return (
    <ReactModal isOpen onRequestClose={onClose} style={modalStyle()}>
      <ModalContact
        btnClose={{ onClick: onClose }}
        title={contact ? 'Edit contact' : 'Create contact'}
        inputName={{
          initialValue: getValues('name'),
          props: {
            alert: !!errors?.name || !watch('name'),
            fieldStatus: true,
            ...register('name', {
              required: { value: true, message: 'This field is required.' },
              minLength: { value: 3, message: 'Minimum 3 characters.' },
            }),
            name: 'name',
            onChange: (e: any) => {
              setValue('name', e.target.value, { shouldValidate: true })
              setContactInfo({ ...contactInfo, name: e.target.value })
            },
          },
        }}
        inputPhone={{
          props: {
            alert: !!errors?.phone || !watch('phone'),
            divPhoneNumber: (
              <PhoneInput
                defaultValue={contactInfo.phone}
                error={false}
                name='phone'
                onPhoneNumberChange={(...all) => {
                  const value = all[3].replace(/\s|-/g, '')
                  setContactInfo({ ...contactInfo, phone: value })
                  setValue('phone', value, { shouldValidate: true })
                  if (!all[0]) {
                    setError('phone', { message: 'Please provide a valid phone number' })
                  } else {
                    clearErrors('phone')
                  }
                }}
                onSelectFlag={(...all) => {
                  setContactInfo({ ...contactInfo, phone: all[2].replace(/\s|-/g, '') })
                  if (!all[3]) {
                    setError('phone', { message: 'Please provide a valid phone number' })
                  } else {
                    clearErrors('phone')
                  }
                }}
                height='40px'
              />
            ),
          },
        }}
        inputEmail={{
          props: {
            alert: !!errors?.email || !watch('email'),
            fieldStatus: true,
            ...register('email', {
              required: { value: true, message: 'This field is required.' },
              minLength: { value: 3, message: 'Minimum 3 characters.' },
            }),
            name: 'email',
            onChange: (e: any) => {
              if (!EMAIL_REGEX.test(e.target.value)) {
                setError('email', { type: 'pattern', message: 'Invalid email address' })
                return
              }
              clearErrors('email')
              setValue('email', e.target.value, { shouldValidate: true })
              setContactInfo({ ...contactInfo, email: e.target.value })
            },
          },
        }}
        inputPosition={{
          props: {
            alert: !!errors?.position,
            fieldStatus: true,
            undefinedInput: {
              name: 'position',
              value: contactInfo.position,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                setContactInfo({
                  ...contactInfo,
                  position: e.target.value,
                }),
            },
          },
        }}
        btnSave={{
          onClick: () => {
            if (loading) return
            if (errors.phone) {
              displayToast(errors.phone.message as string, 'error')
              return
            }
            if (contactInfo.email === '' || contactInfo.name === '' || contactInfo.phone === '') {
              displayToast('Please fill out required fields', 'error')
              return
            }
            if (errors.email) {
              displayToast(errors.email.message as string, 'error')
              return
            }
            handleSubmit(doSubmit)()
          },
          label: loading ? <Loader color='white' /> : contact ? 'Edit contact' : 'Create contact',
          color: loading ? 'gray' : 'green',
        }}
      />
    </ReactModal>
  )
}

export default CustomerContactModal
