import * as GQL from 'generated/graphql'

export const createDynamicField = (name: string, key: string) => {
  const element = document.createElement('span')
  element.contentEditable = 'false'
  element.classList.add('dynamic-field')
  element.innerHTML = name
  element.setAttribute('data-dynamic-field', key)
  return element
}

export function updateSVG(svgString: string, color: string) {
  const updatedSVGString = svgString.replace(/currentColor/g, color)
  return new Blob([updatedSVGString], { type: 'image/svg+xml' })
}

export function bakeSvg(svgString: string, color: string) {
  if (svgString) {
    return URL.createObjectURL(updateSVG(svgString, color))
  }
  return ''
}

export const formatProductType = (type: GQL.ProductType) => {
  if (type === GQL.ProductType.Co2) return type.toUpperCase()
  return type.capitalizeFirst()
}

export const fetchProductPreviewJSON = async () => {
  const response = await fetch('https://solace-static.s3.eu-west-1.amazonaws.com/product_catalog_preview.json')
  const data = await response.json()

  if (data && data.svgPreviews) {
    return data.svgPreviews
  }

  return []
}

export const PERCENT_REGEX = /^\d{1,2}(\.\d{1,2})?$/
