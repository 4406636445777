import { DateRangePicker, Range, RangeKeyDict } from 'react-date-range'

import 'react-date-range/dist/styles.css'
import 'components/DatePicker/CalendarReactTheme.css'
import { endOfDay } from 'date-fns'

interface ExportDateRangePickerProps {
  initialRange: Range[]
  onRangeChange: React.Dispatch<React.SetStateAction<Range[]>>
}

export default function ExportDateRangePicker(props: ExportDateRangePickerProps) {
  return (
    <DateRangePicker
      ranges={props.initialRange}
      showDateDisplay={false}
      staticRanges={[]}
      inputRanges={[]}
      months={1}
      direction='horizontal'
      moveRangeOnFirstSelection={false}
      editableDateInputs={true}
      weekStartsOn={1}
      onChange={(item: RangeKeyDict) => {
        if (item.selection.startDate && item.selection.endDate) {
          props.onRangeChange([
            {
              startDate: item.selection.startDate,
              endDate: endOfDay(item.selection.endDate),
              key: 'selection',
            },
          ])
        }
      }}
      maxDate={new Date()}
      rangeColors={['#2c97de']}
    />
  )
}
