import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import * as GQL from 'generated/graphql'
import { LOCAL_STORAGE_TAB_KEY } from 'modules/customers/CustomersDrawer/CustomersDrawerInner'
import { handlePreFetchCustomerInfo } from './utils'
import { useApolloClient } from '@apollo/client'

export enum CustomerDrawerTab {
  CHART = 'chart',
  CYLINDERS = 'cylinders',
  ORDERS = 'orders',
  NOTES = 'notes',
  CUSTOMER_INFO = 'customerInfo',
  CONTACTS = 'contacts',
  SENSORS = 'sensors',
}

export enum CustomerDrawerListSource {
  READY_TO_REFILL,
  PAUSED_ORDERS,
  PLACED_ORDERS,
  AWAITING_DELIVERY_ORDERS,
  SENSOR_CUSTOMERS,
  NO_SENSOR_CUSTOMERS,
  PLANNED_DELIVERY,
  TOTAL_DELIVERIES,
  UNMANAGABLE_DELIVERIES,
  UNPLANNED_DELIVERIES,
}

interface CustomersContext {
  tab: CustomerDrawerTab
  setTab: (newTab: CustomerDrawerTab) => void
  source: CustomerDrawerListSource | undefined
  setSource: (newSource: CustomerDrawerListSource | undefined) => void
  customersContext: GQL.CustomerNode[]
  setCustomersContext: (newCustomerList: GQL.CustomerNode[]) => void
}

const CustomerContext = createContext<CustomersContext>({
  tab: (localStorage.getItem(LOCAL_STORAGE_TAB_KEY) as CustomerDrawerTab) || CustomerDrawerTab.CHART,
  setTab: () => {},
  source: CustomerDrawerListSource.PLACED_ORDERS,
  setSource: () => {},
  customersContext: [],
  setCustomersContext: () => {},
})

export const CustomerContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tab, setTab] = useState<CustomerDrawerTab>((localStorage.getItem(LOCAL_STORAGE_TAB_KEY) as CustomerDrawerTab) || CustomerDrawerTab.CHART)
  const [source, setSource] = useState<CustomerDrawerListSource>()

  const [customersContext, setCustomersContext] = useState<GQL.CustomerNode[]>([])

  const location = useLocation()
  const client = useApolloClient()

  const currentCustomerID = useMemo(() => {
    const queryParams = new URLSearchParams(location.search)
    return queryParams.get('customer')
  }, [location.search])

  const setCustomerList = useCallback((newCustomerList: GQL.CustomerNode[]) => {
    const map = new Map(newCustomerList.map(customer => [customer.id, customer]))
    setCustomersContext([...map.values()])
  }, [])

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_TAB_KEY, tab)
    if (currentCustomerID && customersContext) {
      handlePreFetchCustomerInfo(customersContext, currentCustomerID, tab, client)
    }
  }, [currentCustomerID, customersContext, client, tab])

  const contextValue: CustomersContext = {
    tab,
    setTab,
    source,
    setSource,
    customersContext,
    setCustomersContext: setCustomerList,
  }

  return <CustomerContext.Provider value={contextValue}>{children}</CustomerContext.Provider>
}

export default CustomerContext
