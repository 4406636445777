// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ScChatsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ScChatsvgIcon(props: ScChatsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 35.599 30.853"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M35.599 8.307v8.307a8.256 8.256 0 01-8.307 8.307h-7.951c-.712 0-1.424.237-2.017.712l-7.12 5.103c-.237 0-.475.119-.712.119s-.356 0-.593-.119c-.356-.237-.593-.593-.593-1.068V24.92A8.256 8.256 0 010 16.613V8.307A8.256 8.256 0 018.307 0h18.986a8.255 8.255 0 018.306 8.307zm-2.373 0c0-3.323-2.611-5.933-5.933-5.933H8.307c-3.323 0-5.933 2.611-5.933 5.933v8.307c0 3.323 2.611 5.933 5.933 5.933a2.38 2.38 0 012.373 2.373v2.492l5.221-3.679c.949-.712 2.255-1.068 3.441-1.068h7.951c3.323 0 5.933-2.611 5.933-5.933V8.307z"
        }
      ></path>

      <path
        d={
          "M21.832 11.764a.455.455 0 00-.402-.243h-3.049l1.22-4.881a.453.453 0 00-.814-.367l-4.992 7.261a.454.454 0 00.374.71h2.641l-.82 4.917a.454.454 0 00.822.332l4.992-7.261a.455.455 0 00.028-.468z"
        }
      ></path>
    </svg>
  );
}

export default ScChatsvgIcon;
/* prettier-ignore-end */
