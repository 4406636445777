import * as React from 'react'
import { PlasmicCellDeliveryWindow, DefaultCellDeliveryWindowProps } from './plasmic/solace_components/PlasmicCellDeliveryWindow'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import * as GQL from 'generated/graphql'
import { format, isSameYear } from 'date-fns'
import { useModal } from 'react-modal-hook'
import ChangeDeliveryWindowModal from 'modules/orders/components/ChangeDeliveryWindowModal'
import { parseISONullSafe } from 'util/date-fns/custom'

export interface CellDeliveryWindowProps extends DefaultCellDeliveryWindowProps {
  cylinderGroupOrder: GQL.CylinderGroupOrderNode
}

function CellDeliveryWindow_(props: CellDeliveryWindowProps, ref: HTMLElementRefOf<'div'>) {
  const { cylinderGroupOrder, ...plasmicProps } = props

  const [showChangeDeliveryWindowModal, hideChangeDeliveryWindowModal] = useModal(() => (
    <ChangeDeliveryWindowModal isOpen onRequestClose={hideChangeDeliveryWindowModal} cylinderGroupOrder={cylinderGroupOrder} />
  ))

  let content =
    format(
      parseISONullSafe(cylinderGroupOrder.firstPossibleDelivery) ?? new Date(),
      isSameYear(new Date(), new Date(cylinderGroupOrder.firstPossibleDelivery)) ? 'dd. MMM' : 'dd. MMM yy'
    ) +
    ' - ' +
    format(
      parseISONullSafe(cylinderGroupOrder.lastPossibleDelivery) ?? new Date(),
      isSameYear(new Date(), new Date(cylinderGroupOrder.lastPossibleDelivery)) ? 'dd. MMM' : 'dd. MMM yy'
    )

  return <PlasmicCellDeliveryWindow root={{ ref }} content={content} onClick={() => showChangeDeliveryWindowModal()} {...plasmicProps} />
}

const CellDeliveryWindow = React.forwardRef(CellDeliveryWindow_)
export default CellDeliveryWindow
