import * as GQL from 'generated/graphql'
import { AuditLogDetail, AuditLogDomain } from '../../types'
import Parser from '../Parser'
import { JSONChanges } from '../types'

class CylinderGroupOrderCreateParser extends Parser {
  public details(entry: GQL.AuditLogEntryNode): AuditLogDetail[] {
    if (!entry.changes) {
      return [[this.createDetailItem('Failed to parse details.')]]
    }
    const changes: JSONChanges = JSON.parse(entry.changes)
    try {
      return [
        [
          this.createDetailItem('Ordered'),
          this.createDetailItem(changes['number_of_cylinders'][1], 'green'),
          this.createDetailItem('x'),
          this.createDetailItem(`${changes['gas_weight'][1]} kg of ${changes['gas_type'][1]}`, 'green'),
        ],
      ]
    } catch (exception) {
      // Handle orders created the legacy way.
      return [
        [
          this.createDetailItem('Ordered'),
          this.createDetailItem(changes['number_of_cylinders'][1], 'green'),
          this.createDetailItem('x'),
          this.createDetailItem(`unknown cylinders`, 'green'),
        ],
      ]
    }
  }

  public domains(entry: GQL.AuditLogEntryNode): AuditLogDomain[] {
    if (!entry.objectId) return []
    return [
      this.createDomainItem('-', entry.objectRepr, entry.contentType?.model),
      this.createDomainItem('-', `Order #${this.extractOrderId(entry)}`, entry.contentType?.model),
    ]
  }

  private extractOrderId(entry: GQL.AuditLogEntryNode): string | undefined {
    return window.atob(entry.objectId!).split(':')[1]
  }
}

export { CylinderGroupOrderCreateParser }
