// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type FreezesvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function FreezesvgIcon(props: FreezesvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 23.54 27.026"}
      xmlSpace={"preserve"}
      height={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M23.377 20.013c-.2.4-.6.7-1 .7-.2 0-.4 0-.5-.1l-2-1.2c-.2-.1-.5 0-.5.3l.2 1.7c.1.5-.3 1.1-.9 1.2h-.2c-.5 0-1-.4-1-.9l-.4-3.9-2.7-1.5c-.7-.4-1.7.1-1.7 1v3.1l3.1 2.2c.4.3.6.9.3 1.4-.2.4-.6.5-.9.5-.2 0-.4-.1-.6-.2l-1.4-1c-.2-.1-.5 0-.5.3v2.4c0 .6-.5 1.1-1.1 1-.5 0-1-.6-1-1.1v-2.5c0-.3-.3-.4-.5-.3l-1.4 1c-.2.1-.4.2-.6.2-.4 0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l3.1-2.2v-3.1c0-.8-.9-1.4-1.7-1l-2.7 1.5-.4 3.9c-.1.5-.5 1-1 1h-.2c-.4-.1-.8-.6-.7-1.1l.2-1.7c0-.3-.3-.4-.5-.3l-2 1.2c-.2.1-.3.1-.5.1-.4 0-.8-.2-1-.7s0-1 .5-1.3l2-1.2c.2-.1.2-.5 0-.6l-1.6-.7c-.5-.2-.7-.7-.6-1.2.2-.7.9-1 1.5-.7l3.5 1.6 2.7-1.5c.7-.4.7-1.5 0-1.9l-2.7-1.6-3.5 1.6c-.1.1-.3.1-.4.1-.4 0-.9-.3-1-.7-.2-.5.1-1.1.6-1.3l1.6-.7c.2-.1.3-.4 0-.6l-2-1.2c-.5-.3-.7-1-.4-1.5s1-.6 1.4-.3l2 1.1c.2.1.5 0 .5-.3l-.3-1.7c-.1-.6.5-1.2 1.1-1.1.5 0 .9.5.9 1l.4 3.8 2.7 1.5c.7.4 1.7-.1 1.7-1v-3.1l-3.2-2.1c-.5-.4-.6-1.1-.1-1.6.4-.4 1-.4 1.4-.1l1.4 1c.2.2.5 0 .5-.3v-2.4c0-.6.5-1.1 1.1-1 .5 0 1 .6 1 1.1v2.3c0 .3.3.4.5.3l1.4-1c.5-.4 1.3-.2 1.6.4.2.5 0 1-.4 1.3l-3.1 2.2v3.1c0 .8.9 1.4 1.7 1l2.7-1.5.4-3.9c.1-.6.6-1 1.2-.9.5.1.9.7.9 1.2l-.2 1.7c0 .3.3.4.5.3l2-1.2c.6-.3 1.3-.1 1.5.6.2.5-.1 1-.5 1.3l-2 1.2c-.2.1-.2.5 0 .6l1.6.7c.5.2.8.8.6 1.3-.1.5-.6.7-1 .7-.1 0-.3 0-.4-.1l-3.5-1.6-2.7 1.5c-.7.4-.7 1.5 0 1.9l2.7 1.6 3.5-1.6c.6-.3 1.3 0 1.5.7.1.5-.2 1-.6 1.2l-1.6.7c-.2.1-.3.4 0 .6l2 1.2c.2 0 .5.6.3 1.1z"
        }
      ></path>
    </svg>
  );
}

export default FreezesvgIcon;
/* prettier-ignore-end */
