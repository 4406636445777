// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import Fuse from 'fuse.js'
import { useIntl } from 'react-intl'
import Select from 'react-select'

import { PlasmicModalCreateCustomer, DefaultModalCreateCustomerProps } from './plasmic/solace_components/PlasmicModalCreateCustomer'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import { displayToast } from 'util/toasts'
import { getDefaultOpeningHours } from 'modules/customers/util'
import { useAppContext, useCustomerContext } from 'util/hooks'
import * as GQL from 'generated/graphql'
import { TutorialInfoIcon } from 'components/Tooltip/TutorialTooltip'
import { Control, MenuList, Option, reactSelectStyles, IndicatorsContainer } from 'plasmic/StyledReactSelect'
import { reactSelectTagStyles } from 'plasmic/StyledReactSelectTags'
import CylinderComponent from './CylinderComponent'
import PhoneInput from 'components/Input/PhoneInput'
import { CylinderColor } from './Cylinder'
import { MandatoryField, MandatoryFieldObject } from './MandatoryFieldsPanel'
import { isValidAddress } from 'modules/localization/utils'
import AddressAutoComplete from 'components/Input/AddressAutoComplete'

interface OptionType {
  value: string
  label: string
}

export interface OpeningHour {
  weekday: GQL.CustomerStandardOpeningHoursWeekday
  opensAt: string
  closesAt: string
}

interface CylinderSides {
  cylinderCount: number
}

export interface CustomerInfoCylinderGroups {
  product: string
  thresholdId: string | null
  cylinderSides: CylinderSides[]
  cylinderMode: GQL.CylinderGroupMode
}

export interface CustomerInfo {
  name: string
  customerIdentifier: string
  phoneNumber: string
  address: GQL.AddressNode | null
  depot: OptionType
  orderMethod: GQL.CustomerOrderMethod
  customerDomainType: GQL.CustomerDomainType
  pricingCategory: OptionType
  tags: OptionType[]
  cylinderMode: GQL.CylinderGroupMode
  product: OptionType
  cylinderSides: CylinderSides[]
  trigger: OptionType
  alwaysOpen: boolean
  openingHours: OpeningHour[]
}

export interface ModalCreateCustomerProps extends DefaultModalCreateCustomerProps {
  inputName?: any
  inputId?: any
  inputPhoneNumberWrapper?: any
  addressInputForm?: any
  selectDepotDiv?: any
  tabSelectorOrderMethod?: any
  tabSelectorCustomerType?: any
  selectPricingListDiv?: any
  selectTagsDiv?: any
  toggleConfigureCylinderSetup?: any
  modifyCylinderSetup?: any
  selectProductDiv?: any
  selectAutomaticTriggerDiv?: any
  openingHoursComponent?: any
  btnCreateCustomer?: any
  inline?: boolean
  btnClose?: any

  // custom props
  loading: boolean
  customerInfo: CustomerInfo
  setCustomerInfo: React.Dispatch<React.SetStateAction<CustomerInfo>>
  configureCylinderSetup: boolean
  setConfigureCylinderSetup: React.Dispatch<React.SetStateAction<boolean>>
  handleSubmit: () => void
  mandatoryFields?: MandatoryFieldObject[]
  setMandatoryFields?: (mandatoryFields: MandatoryFieldObject[]) => void
  onClose?: () => void
}

function ModalCreateCustomer_(props: ModalCreateCustomerProps, ref: HTMLElementRefOf<'div'>) {
  const {
    customerInfo,
    setCustomerInfo,
    configureCylinderSetup,
    setConfigureCylinderSetup,
    mandatoryFields,
    setMandatoryFields,
    onClose,
    handleSubmit,
    loading,
    ...plasmicProps
  } = props

  const [formErrors, setFormErrors] = React.useState({
    isPhoneNumberInvalid: !!customerInfo?.phoneNumber || false,
    showErrors: false,
  })

  const intl = useIntl()
  const t = intl.formatMessage

  const { appContext } = useAppContext()
  const { customersContext } = useCustomerContext()

  const { data: depotData } = GQL.useAllDepots()
  const { data: tagsData } = GQL.useAllDistributorTags()
  const { data: productsData } = GQL.useAllProducts({ variables: { service: false } })
  const { data: triggerData } = GQL.useAllDistributorThresholds()

  const products = React.useMemo(() => {
    return productsData?.availableProducts?.edges.map(product => product?.node as GQL.ProductNode) || []
  }, [productsData])

  const tags = React.useMemo(() => {
    return tagsData?.allDistributorTags?.edges.map(tag => tag as GQL.DistributorTagNodeEdge) || []
  }, [tagsData])

  const depots = React.useMemo(() => {
    return depotData?.allDepots?.edges?.map(depot => depot as GQL.DepotNodeEdge) || []
  }, [depotData])

  const pricingCategories = React.useMemo(() => {
    return appContext.distributor?.priceCategories?.map(priceCategory => priceCategory as GQL.PriceCategoryNode) || []
  }, [appContext.distributor])

  const trigers = React.useMemo(() => {
    return triggerData?.allDistributorRefillThresholds?.edges.map(edge => edge?.node as GQL.DistributorRefillThresholdNode) || []
  }, [triggerData])

  React.useEffect(() => {
    // prefilling depot select with context one
    if (appContext?.depot?.id && appContext.depot?.name && !customerInfo.depot.value) {
      setCustomerInfo({
        ...customerInfo,
        depot: {
          value: appContext.depot.id,
          label: appContext.depot.name,
        },
      })
    }
  }, [appContext.depot, customerInfo])

  const changeOpeningHours = ({ day, opensAt, closesAt }: { day: GQL.CustomerStandardOpeningHoursWeekday; opensAt?: string; closesAt?: string }) => {
    if (customerInfo.openingHours.find(e => e.weekday === day)) {
      const newOpeningHours = customerInfo.openingHours.map(e => {
        if (e.weekday === day) {
          return { ...e, opensAt: opensAt || e.opensAt, closesAt: closesAt || e.closesAt }
        }
        return e
      })
      setCustomerInfo({ ...customerInfo, openingHours: newOpeningHours })
    } else {
      const defaultHours = getDefaultOpeningHours(customerInfo.openingHours)
      setCustomerInfo({
        ...customerInfo,
        openingHours: [
          ...customerInfo.openingHours,
          {
            weekday: day,
            opensAt: opensAt || defaultHours.opensAt,
            closesAt: closesAt || defaultHours.closesAt,
          },
        ],
      })
    }
  }

  // update address information
  const handleChangeAddress = (suggestion: GQL.GeocodingSuggestionNode) => {
    setCustomerInfo(customerInfo => {
      if (suggestion.placeName && suggestion.latitude && suggestion.latitude) {
        return {
          ...customerInfo,
          address: {
            firstLine: suggestion.placeName,
            longitude: suggestion.longitude,
            latitude: suggestion.latitude,
          } as GQL.AddressNode,
        }
      }
      return customerInfo
    })
  }

  const validateCustomer = () => {
    if (!customerInfo.name || customerInfo.name.length < 3) {
      displayToast(t({ id: 'customers.create.customer-name-invalid' }))
      return
    } else if (!isValidAddress(customerInfo.address)) {
      displayToast(t({ id: 'customers.create.customer-address-invalid' }))
      return
    } else if (!customerInfo.depot.value) {
      displayToast(t({ id: 'customers.create.customer-depot-missing' }))
      return
    } else if (!customerInfo.alwaysOpen && customerInfo.openingHours.length === 0) {
      displayToast(t({ id: 'customers.create.customer-opening-hours-invalid' }))
      return
    } else if (configureCylinderSetup && !customerInfo.product.value) {
      displayToast(t({ id: 'customers.create.customer-no-product' }))
      return
    }

    let similarAddressCustomers = []
    let similarPhoneCustomers = []

    const allCustomersNames = customersContext.map(customer => customer.name)

    const nameFuse = new Fuse(allCustomersNames, {
      includeScore: true,
    })
    let similarNameCustomers = nameFuse.search(customerInfo.name).filter(result => result.hasOwnProperty('score') && result.score! < 0.05)

    if (customerInfo.address) {
      const allCustomersAddress = customersContext.map(customer => customer.address)

      const addressFuse = new Fuse(allCustomersAddress, {
        includeScore: true,
      })
      similarAddressCustomers = addressFuse.search(customerInfo.address.firstLine).filter(result => result.hasOwnProperty('score') && result.score! < 0.05)
    }
    if (customerInfo.phoneNumber) {
      const allCustomersPhoneNumbers = customersContext.map(customer => customer.phoneNumber)

      const phoneFuse = new Fuse(allCustomersPhoneNumbers, {
        includeScore: true,
      })
      similarPhoneCustomers = phoneFuse.search(customerInfo.phoneNumber).filter(result => result.hasOwnProperty('score') && result.score! < 0.02)
    }

    if (similarNameCustomers.length > 0) {
      displayToast(
        t({ id: 'customers.create-patch-customer.alert.address-and-phone-number-similarity' }),
        'confirm',
        { autoClose: false },
        true,
        () => {},
        () => handleSubmit()
      )
    } else if (similarAddressCustomers.length > 0 && similarPhoneCustomers.length > 0) {
      displayToast(
        t({ id: 'customers.create-patch-customer.alert.address-and-phone-number-similarity' }),
        'confirm',
        { autoClose: false },
        true,
        () => {},
        () => handleSubmit()
      )
    } else if (similarAddressCustomers.length > 0) {
      displayToast(
        t({ id: 'customers.create-patch-customer.alert.address-similarity' }),
        'confirm',
        { autoClose: false },
        true,
        () => {},
        () => handleSubmit()
      )
    } else if (similarPhoneCustomers.length > 0) {
      displayToast(
        t({ id: 'customers.create-patch-customer.alert.phone-number-similarity' }),
        'confirm',
        { autoClose: false },
        true,
        () => {},
        () => handleSubmit()
      )
    } else {
      handleSubmit()
    }
  }

  const handleClose = () => {
    if (props.onClose) {
      props.onClose()
    }
    setCustomerInfo({
      name: '',
      customerIdentifier: '',
      phoneNumber: '',
      address: null,
      depot: {
        value: appContext?.depot?.id || '',
        label: appContext?.depot?.name || '',
      },
      orderMethod: GQL.CustomerOrderMethod.Phone,
      customerDomainType: GQL.CustomerDomainType.Business,
      pricingCategory: {
        value: '',
        label: '',
      },
      tags: [] as OptionType[],
      cylinderMode: GQL.CylinderGroupMode.Standard,
      product: {
        value: '',
        label: '',
      },
      cylinderSides: [
        {
          cylinderCount: 4,
        },
        {
          cylinderCount: 4,
        },
      ],
      trigger: {
        value: '',
        label: '',
      },
      alwaysOpen: true,
      openingHours: [] as OpeningHour[],
    })
  }

  return (
    <PlasmicModalCreateCustomer
      root={{ ref }}
      {...plasmicProps}
      btnClose={{
        onClick: () => handleClose(),
      }}
      inputName={{
        fieldStatus: true,
        alert: !customerInfo.name || customerInfo.name.length < 3,
        undefinedInput: {
          id: 'input-customer-name',
          value: customerInfo.name,
          name: 'name',
          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            setCustomerInfo({
              ...customerInfo,
              name: e.target.value,
            }),
        },
      }}
      inputId={{
        fieldStatus: !!customerInfo.customerIdentifier,
        alert: !customerInfo.customerIdentifier,
        undefinedInput: {
          value: customerInfo.customerIdentifier,
          name: 'customerIdentifier',
          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            setCustomerInfo({
              ...customerInfo,
              customerIdentifier: e.target.value,
            }),
        },
      }}
      inputPhoneNumberWrapper={{
        divPhoneNumber: (
          <PhoneInput
            id='input-phone-number'
            name='phoneNumber'
            defaultValue={customerInfo.phoneNumber}
            height='48px'
            error={false} // optional
            onPhoneNumberChange={(...all) => {
              setCustomerInfo({ ...customerInfo, phoneNumber: all[3].replace(/\s|-/g, '') })
              if (!all[0]) {
                setFormErrors({ ...formErrors, isPhoneNumberInvalid: true })
              } else {
                setFormErrors({ ...formErrors, isPhoneNumberInvalid: false })
              }
              if (!!setMandatoryFields && mandatoryFields) {
                setMandatoryFields(
                  mandatoryFields.map(el => {
                    if (el.key === MandatoryField.PHONE_NUMBER) {
                      return { ...el, valid: all[0] }
                    }
                    return el
                  })
                )
              }
            }}
            onSelectFlag={(...all) => {
              setCustomerInfo({ ...customerInfo, phoneNumber: all[2].replace(/\s|-/g, '') })
              if (!all[3]) {
                setFormErrors({ ...formErrors, isPhoneNumberInvalid: true })
              } else {
                setFormErrors({ ...formErrors, isPhoneNumberInvalid: false })
              }
              if (!!setMandatoryFields && mandatoryFields) {
                setMandatoryFields(
                  mandatoryFields.map(el => {
                    if (el.key === MandatoryField.PHONE_NUMBER) {
                      return { ...el, valid: all[3] }
                    }
                    return el
                  })
                )
              }
            }}
          />
        ),
        alert: !customerInfo.phoneNumber || (customerInfo.phoneNumber && formErrors.isPhoneNumberInvalid),
        tooltip: <TutorialInfoIcon content={t({ id: 'tooltips.settings.user-form.phone-number' })} />,
      }}
      addressInputForm={{
        mapDiv: isValidAddress(customerInfo.address) && (
          <div
            onClick={() =>
              parseFloat(customerInfo?.address?.latitude) && parseFloat(customerInfo?.address?.longitude)
                ? window.open(
                    `https://www.google.com/maps/search/?api=1&query=${parseFloat(customerInfo?.address?.latitude)},${parseFloat(
                      customerInfo?.address?.longitude
                    )}`,
                    '_blank'
                  )
                : null
            }
            style={{
              cursor: 'pointer',
              width: '100%',
              height: '215px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '1.5rem',
              color: 'rgb(255, 143, 143)',
              backgroundImage: `url('https://maps.googleapis.com/maps/api/staticmap?size=800x300&zoom=15&scale=2&map_id=7c94979620d924a7&key=AIzaSyBtD0cwYlaVLkqfRT1MPc22j0j0vv51wag&markers=size:normal%7Ccolor:red%7C${parseFloat(
                customerInfo?.address?.latitude
              )},${parseFloat(customerInfo?.address?.longitude)}')`,
              backgroundSize: '100% auto',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          />
        ),
        inputAddressWrapper: {
          alert: !isValidAddress(customerInfo.address),
          title: t({ id: 'common.address' }),
          noTooltip: true,
          divPhoneNumber: (
            <AddressAutoComplete
              id='input-address'
              name='address'
              value={customerInfo.address?.firstLine ? customerInfo.address.firstLine : ''}
              placeholder={t({ id: 'common.address' })}
              onSelect={handleChangeAddress}
            />
          ),
        },
        btnConfirm: {
          onClick: () => console.log('Validate address'),
        },
      }}
      selectDepotDiv={
        <Select
          id='select-depot'
          placeholder={t({ id: 'common.select-depot' })}
          components={{ Option, Control, MenuList, IndicatorsContainer }}
          styles={reactSelectStyles}
          isMulti={false}
          onChange={event => {
            setCustomerInfo({ ...customerInfo, depot: event })
          }}
          value={
            customerInfo.depot.value
              ? ({
                  value: customerInfo.depot.value,
                  label: customerInfo.depot.label || t({ id: 'common.select-depot' }),
                } as OptionType)
              : null
          }
          options={depots.map(depot => ({
            value: depot?.node?.id,
            label: depot?.node?.name,
          }))}
        />
      }
      tabSelectorOrderMethod={{
        'data-testid': 'order-method',
        selected: customerInfo.orderMethod.toLowerCase() as 'auto' | 'app' | 'phone',
        btnAutomatic: {
          onClick: () => setCustomerInfo({ ...customerInfo, orderMethod: GQL.CustomerOrderMethod.Auto }),
        },
        btnApp: {
          onClick: () => setCustomerInfo({ ...customerInfo, orderMethod: GQL.CustomerOrderMethod.App }),
        },
        btnPhone: {
          onClick: () => setCustomerInfo({ ...customerInfo, orderMethod: GQL.CustomerOrderMethod.Phone }),
        },
      }}
      tabSelectorCustomerType={{
        'data-testid': 'customer-type',
        selected: customerInfo.customerDomainType.toLowerCase() as 'business' | 'resident',
        btnBusiness: {
          onClick: () => setCustomerInfo({ ...customerInfo, customerDomainType: GQL.CustomerDomainType.Business }),
        },
        btnResident: {
          onClick: () => setCustomerInfo({ ...customerInfo, customerDomainType: GQL.CustomerDomainType.Resident }),
        },
      }}
      selectPricingListDiv={
        <Select
          id='select-pricing-category'
          placeholder={t({ id: 'common.default' })}
          components={{ Option, Control, MenuList }}
          styles={reactSelectStyles}
          isMulti={false}
          onChange={event => {
            setCustomerInfo({ ...customerInfo, pricingCategory: event })
          }}
          value={
            customerInfo.pricingCategory.value
              ? ({
                  value: customerInfo.pricingCategory.value,
                  label: customerInfo.pricingCategory.label,
                } as OptionType)
              : null
          }
          options={pricingCategories.map(priceCategory => ({
            value: priceCategory.id,
            label: priceCategory.name,
          }))}
        />
      }
      selectTagsDiv={
        <Select
          id='select-tags'
          placeholder={t({ id: 'common.select-tags' })}
          components={{ Option, Control, MenuList }}
          onChange={selected => {
            setCustomerInfo({ ...customerInfo, tags: selected as OptionType[] })
          }}
          isMulti
          value={customerInfo.tags}
          options={tags.map(tag => ({
            value: tag?.node?.id,
            label: tag?.node?.name,
          }))}
          styles={{
            menu: base => ({
              ...base,
              zIndex: 99,
              borderRadius: '6px',
            }),
            multiValue: base => ({
              ...base,
              backgroundColor: 'rgba(0,212,166,0.6)',
              borderRadius: '6px',
            }),
            multiValueLabel: base => ({
              ...base,
              color: 'white',
              textTransform: 'uppercase',
              fontWeight: '500',
              fontSize: '11px',
            }),
            multiValueRemove: base => ({
              ...base,
              backgroundColor: 'transparent',
              borderRadius: '6px',
              '&:hover': {
                backgroundColor: 'rgba(0,212,166,0.6)',
                color: 'white',
              },
            }),
            control: reactSelectStyles.control,
            option: reactSelectTagStyles.option,
            menuList: reactSelectTagStyles.menuList,
            singleValue: reactSelectTagStyles.singleValue,
            input: reactSelectTagStyles.input,
            noOptionsMessage: reactSelectTagStyles.noOptionsMessage,
          }}
          className='tags-multi-select'
          classNamePrefix='select'
        />
      }
      showCylinderSetup={configureCylinderSetup}
      toggleConfigureCylinderSetup={{
        on: configureCylinderSetup,
        'data-testid': 'toggle-configure-cylinders',
        onClick: () => setConfigureCylinderSetup(prev => !prev),
      }}
      selectProductDiv={
        <Select
          id='select-product'
          placeholder={t({ id: 'common.select-product' })}
          components={{ Option, Control, MenuList, IndicatorsContainer }}
          styles={reactSelectStyles}
          isMulti={false}
          onChange={event => {
            setCustomerInfo({ ...customerInfo, product: event })
          }}
          value={
            customerInfo.product.value
              ? ({
                  value: customerInfo.product.value,
                  label: customerInfo.product.label,
                } as OptionType)
              : null
          }
          options={products.map(product => ({
            value: product.id,
            label: product.displayName,
          }))}
        />
      }
      modifyCylinderSetup={{
        parallel: customerInfo.cylinderMode !== GQL.CylinderGroupMode.Standard,
        tabSelectorSetupType: {
          selected: customerInfo.cylinderMode.toLowerCase(),
          btnStandard: {
            onClick: () => setCustomerInfo({ ...customerInfo, cylinderMode: GQL.CylinderGroupMode.Standard }),
          },
          btnParallel: {
            onClick: () => setCustomerInfo({ ...customerInfo, cylinderMode: GQL.CylinderGroupMode.Parallel }),
          },
          btnInsitu: {
            onClick: () => setCustomerInfo({ ...customerInfo, cylinderMode: GQL.CylinderGroupMode.Insitu }),
          },
        },
        cylindersLeft: {
          cylinders: (
            <CylinderComponent
              side={{
                cylinderCount: customerInfo.cylinderSides[0].cylinderCount || 1,
                monitored: false,
                sensors: [],
                depleting: false,
                daysRemaining: 10,
                priority: 0,
                activeCycleRemainingPercentage: 100,
                cylinderColor: CylinderColor.BLUE,
                isFreezing: false,
              }}
            />
          ),
        },
        cylindersRight: {
          cylinders: (
            <CylinderComponent
              side={{
                cylinderCount: customerInfo.cylinderSides[1].cylinderCount || 1,
                monitored: false,
                sensors: [],
                depleting: false,
                daysRemaining: 10,
                priority: 0,
                activeCycleRemainingPercentage: 100,
                cylinderColor: CylinderColor.BLUE,
                isFreezing: false,
              }}
            />
          ),
        },
        countPickerLeft: {
          props: {
            count: customerInfo.cylinderSides[0].cylinderCount || 1,
            subtract: {
              onClick: () => {
                if (customerInfo.cylinderSides[0].cylinderCount && customerInfo.cylinderSides[0].cylinderCount > 1) {
                  customerInfo.cylinderSides[0].cylinderCount -= 1
                  setCustomerInfo({ ...customerInfo, cylinderSides: customerInfo.cylinderSides })
                }
              },
            },
            add: {
              onClick: () => {
                if (customerInfo.cylinderSides[0].cylinderCount) {
                  customerInfo.cylinderSides[0].cylinderCount += 1
                  setCustomerInfo({ ...customerInfo, cylinderSides: customerInfo.cylinderSides })
                }
              },
            },
          },
        },
        countPickerRight: {
          props: {
            count: customerInfo.cylinderSides[1].cylinderCount || 1,
            subtract: {
              onClick: () => {
                if (customerInfo.cylinderSides[1].cylinderCount && customerInfo.cylinderSides[1].cylinderCount > 1) {
                  customerInfo.cylinderSides[1].cylinderCount -= 1
                  setCustomerInfo({ ...customerInfo, cylinderSides: customerInfo.cylinderSides })
                }
              },
            },
            add: {
              onClick: () => {
                if (customerInfo.cylinderSides[1].cylinderCount) {
                  customerInfo.cylinderSides[1].cylinderCount += 1
                  setCustomerInfo({ ...customerInfo, cylinderSides: customerInfo.cylinderSides })
                }
              },
            },
          },
        },
      }}
      selectAutomaticTriggerDiv={
        <Select
          id='select-trigger'
          placeholder={t({ id: 'common.select-trigger' })}
          components={{ Option, Control, MenuList }}
          styles={reactSelectStyles}
          isMulti={false}
          isClearable
          onChange={(event, { action }) => {
            if (action === 'clear') {
              setCustomerInfo({
                ...customerInfo,
                trigger: {
                  value: '',
                  label: '',
                },
              })
            } else {
              setCustomerInfo({ ...customerInfo, trigger: event })
            }
          }}
          value={
            customerInfo.trigger.value
              ? ({
                  value: customerInfo.trigger.value,
                  label: customerInfo.trigger.label,
                } as OptionType)
              : null
          }
          options={trigers.map(trigger => ({
            value: trigger.id,
            label: trigger.name,
          }))}
        />
      }
      openingHoursComponent={{
        alwaysAvailable: customerInfo.alwaysOpen,
        always: {
          on: customerInfo.alwaysOpen,
          switchBtn: {
            onChange: (checked: boolean) => setCustomerInfo({ ...customerInfo, alwaysOpen: checked }),
          },
        },
        mon: {
          on: customerInfo.openingHours.some(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Monday),
          switchBtn: {
            isChecked: customerInfo.openingHours.some(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Monday),
            onChange: (e: boolean) => {
              if (e) {
                changeOpeningHours({ day: GQL.CustomerStandardOpeningHoursWeekday.Monday })
              } else {
                const newOpeningHours = customerInfo.openingHours.filter(openingHour => openingHour.weekday !== GQL.CustomerStandardOpeningHoursWeekday.Monday)
                setCustomerInfo({ ...customerInfo, openingHours: newOpeningHours })
              }
            },
          },
          inputToTime: {
            undefinedInput: {
              value: customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Monday)?.closesAt
                ? customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Monday)?.closesAt
                : '',
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                changeOpeningHours({ day: GQL.CustomerStandardOpeningHoursWeekday.Monday, closesAt: e.target.value }),
            },
          },
          inputFromTime: {
            undefinedInput: {
              value: customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Monday)?.opensAt
                ? customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Monday)?.opensAt
                : '',
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                changeOpeningHours({ day: GQL.CustomerStandardOpeningHoursWeekday.Monday, opensAt: e.target.value }),
            },
          },
        },
        tue: {
          on: customerInfo.openingHours.some(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Tuesday),
          switchBtn: {
            isChecked: customerInfo.openingHours.some(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Tuesday),
            onChange: (e: boolean) => {
              if (e) {
                changeOpeningHours({ day: GQL.CustomerStandardOpeningHoursWeekday.Tuesday })
              } else {
                const newOpeningHours = customerInfo.openingHours.filter(openingHour => openingHour.weekday !== GQL.CustomerStandardOpeningHoursWeekday.Tuesday)
                setCustomerInfo({ ...customerInfo, openingHours: newOpeningHours })
              }
            },
          },
          inputToTime: {
            undefinedInput: {
              value: customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Tuesday)?.closesAt
                ? customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Tuesday)?.closesAt
                : '',
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                changeOpeningHours({ day: GQL.CustomerStandardOpeningHoursWeekday.Tuesday, closesAt: e.target.value }),
            },
          },
          inputFromTime: {
            undefinedInput: {
              value: customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Tuesday)?.opensAt
                ? customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Tuesday)?.opensAt
                : '',
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                changeOpeningHours({ day: GQL.CustomerStandardOpeningHoursWeekday.Tuesday, opensAt: e.target.value }),
            },
          },
        },
        wed: {
          on: customerInfo.openingHours.some(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Wednesday),
          switchBtn: {
            isChecked: customerInfo.openingHours.some(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Wednesday),
            onChange: (e: boolean) => {
              if (e) {
                changeOpeningHours({ day: GQL.CustomerStandardOpeningHoursWeekday.Wednesday })
              } else {
                const newOpeningHours = customerInfo.openingHours.filter(
                  openingHour => openingHour.weekday !== GQL.CustomerStandardOpeningHoursWeekday.Wednesday
                )
                setCustomerInfo({ ...customerInfo, openingHours: newOpeningHours })
              }
            },
          },
          inputToTime: {
            undefinedInput: {
              value: customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Wednesday)?.closesAt
                ? customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Wednesday)?.closesAt
                : '',
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                changeOpeningHours({ day: GQL.CustomerStandardOpeningHoursWeekday.Wednesday, closesAt: e.target.value }),
            },
          },
          inputFromTime: {
            undefinedInput: {
              value: customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Wednesday)?.opensAt
                ? customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Wednesday)?.opensAt
                : '',
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                changeOpeningHours({ day: GQL.CustomerStandardOpeningHoursWeekday.Wednesday, opensAt: e.target.value }),
            },
          },
        },
        thu: {
          on: customerInfo.openingHours.some(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Thursday),
          switchBtn: {
            isChecked: customerInfo.openingHours.some(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Thursday),
            onChange: (e: boolean) => {
              if (e) {
                changeOpeningHours({ day: GQL.CustomerStandardOpeningHoursWeekday.Thursday })
              } else {
                const newOpeningHours = customerInfo.openingHours.filter(
                  openingHour => openingHour.weekday !== GQL.CustomerStandardOpeningHoursWeekday.Thursday
                )
                setCustomerInfo({ ...customerInfo, openingHours: newOpeningHours })
              }
            },
          },
          inputToTime: {
            undefinedInput: {
              value: customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Thursday)?.closesAt
                ? customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Thursday)?.closesAt
                : '',
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                changeOpeningHours({ day: GQL.CustomerStandardOpeningHoursWeekday.Thursday, closesAt: e.target.value }),
            },
          },
          inputFromTime: {
            undefinedInput: {
              value: customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Thursday)?.opensAt
                ? customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Thursday)?.opensAt
                : '',
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                changeOpeningHours({ day: GQL.CustomerStandardOpeningHoursWeekday.Thursday, opensAt: e.target.value }),
            },
          },
        },
        fri: {
          on: customerInfo.openingHours.some(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Friday),
          switchBtn: {
            isChecked: customerInfo.openingHours.some(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Friday),
            onChange: (e: boolean) => {
              if (e) {
                changeOpeningHours({ day: GQL.CustomerStandardOpeningHoursWeekday.Friday })
              } else {
                const newOpeningHours = customerInfo.openingHours.filter(openingHour => openingHour.weekday !== GQL.CustomerStandardOpeningHoursWeekday.Friday)
                setCustomerInfo({ ...customerInfo, openingHours: newOpeningHours })
              }
            },
          },
          inputToTime: {
            undefinedInput: {
              value: customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Friday)?.closesAt
                ? customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Friday)?.closesAt
                : '',
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                changeOpeningHours({ day: GQL.CustomerStandardOpeningHoursWeekday.Friday, closesAt: e.target.value }),
            },
          },
          inputFromTime: {
            undefinedInput: {
              value: customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Friday)?.opensAt
                ? customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Friday)?.opensAt
                : '',
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                changeOpeningHours({ day: GQL.CustomerStandardOpeningHoursWeekday.Friday, opensAt: e.target.value }),
            },
          },
        },
        sat: {
          on: customerInfo.openingHours.some(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Saturday),
          switchBtn: {
            isChecked: customerInfo.openingHours.some(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Saturday),
            onChange: (e: boolean) => {
              if (e) {
                changeOpeningHours({ day: GQL.CustomerStandardOpeningHoursWeekday.Saturday })
              } else {
                const newOpeningHours = customerInfo.openingHours.filter(
                  openingHour => openingHour.weekday !== GQL.CustomerStandardOpeningHoursWeekday.Saturday
                )
                setCustomerInfo({ ...customerInfo, openingHours: newOpeningHours })
              }
            },
          },
          inputToTime: {
            undefinedInput: {
              value: customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Saturday)?.closesAt
                ? customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Saturday)?.closesAt
                : '',
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                changeOpeningHours({ day: GQL.CustomerStandardOpeningHoursWeekday.Saturday, closesAt: e.target.value }),
            },
          },
          inputFromTime: {
            undefinedInput: {
              value: customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Saturday)?.opensAt
                ? customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Saturday)?.opensAt
                : '',
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                changeOpeningHours({ day: GQL.CustomerStandardOpeningHoursWeekday.Saturday, opensAt: e.target.value }),
            },
          },
        },
        sun: {
          on: customerInfo.openingHours.some(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Sunday),
          switchBtn: {
            isChecked: customerInfo.openingHours.some(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Sunday),
            onChange: (e: boolean) => {
              if (e) {
                changeOpeningHours({ day: GQL.CustomerStandardOpeningHoursWeekday.Sunday })
              } else {
                const newOpeningHours = customerInfo.openingHours.filter(openingHour => openingHour.weekday !== GQL.CustomerStandardOpeningHoursWeekday.Sunday)
                setCustomerInfo({ ...customerInfo, openingHours: newOpeningHours })
              }
            },
          },
          inputToTime: {
            undefinedInput: {
              value: customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Sunday)?.closesAt
                ? customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Sunday)?.closesAt
                : '',
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                changeOpeningHours({ day: GQL.CustomerStandardOpeningHoursWeekday.Sunday, closesAt: e.target.value }),
            },
          },
          inputFromTime: {
            undefinedInput: {
              value: customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Sunday)?.opensAt
                ? customerInfo.openingHours.find(e => e.weekday === GQL.CustomerStandardOpeningHoursWeekday.Sunday)?.opensAt
                : '',
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                changeOpeningHours({ day: GQL.CustomerStandardOpeningHoursWeekday.Sunday, opensAt: e.target.value }),
            },
          },
        },
      }}
      btnCreateCustomer={{
        onClick: () => validateCustomer(),
        loading: loading,
      }}
    />
  )
}

const ModalCreateCustomer = React.forwardRef(ModalCreateCustomer_)
export default ModalCreateCustomer
