// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type DeletesvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function DeletesvgIcon(props: DeletesvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 23 26"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M21.859 4.235h-4.104l-1.693-2.824A2.389 2.389 0 0014.01.25H8.99a2.392 2.392 0 00-2.052 1.16L5.245 4.235H1.141a.797.797 0 00-.797.797v.797c0 .44.357.797.797.797h.797L2.994 23.51a2.39 2.39 0 002.386 2.241h12.242a2.39 2.39 0 002.386-2.241l1.056-16.884h.797c.44 0 .797-.357.797-.797v-.798a.8.8 0 00-.799-.796zM8.99 2.641h5.02l.956 1.594H8.034l.956-1.594zm8.631 20.718H5.38L4.334 6.625h14.334l-1.047 16.734z"
        }
      ></path>
    </svg>
  );
}

export default DeletesvgIcon;
/* prettier-ignore-end */
