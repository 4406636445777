import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import FullLoader from 'components/Loader/FullLoader'
import CenteredErrorMessage from 'components/CenteredErrorMessage/CenteredErrorMessage'
import MainLayout from './MainLayout'
import MobileLayout from './MobileLayout'
import * as GQL from 'generated/graphql'
import * as Sentry from '@sentry/browser'
import { ServerError, useApolloClient } from '@apollo/client'
import { link } from 'apollo'
import { setContext } from '@apollo/client/link/context'
import AppContext, { IAppContext } from 'context/AppContext'
import { getLoginToken, removeLoginToken, setLoginToken } from 'util/auth'
import { getVersion, get_domain, isDev } from 'util/env'
import Button from 'components/Button'
import { displayToast } from 'util/toasts'
import { Route, Routes, useLocation } from 'react-router-dom'
import CacheConfigs from 'util/cacheConfig'
import HeaderContext from 'context/HeaderContext'
import { CustomerContextProvider } from 'context/CustomerContext'
import Unsubscribe from 'modules/unsubscribe/Unsubscribe'
import AccountSuspended from 'plasmic/AccountSuspended'
import { useIntl } from 'react-intl'
import { paymentEnabled } from 'modules/distributors/utils'
import { TableContextProvider } from 'context/TableContext'

const OrderPdf = React.lazy(() => import(/*webpackChunkName: 'OrderPdf' */ 'modules/orders/components/OrderPdf'))
const RoutePdf = React.lazy(() => import(/*webpackChunkName: 'RoutePdf' */ 'modules/deliveries/Routes/RoutePdf'))
const DeliveryNotePdf = React.lazy(() => import(/*webpackChunkName: 'RoutePdf' */ 'modules/orders/components/DeliveryNotePdf'))

interface BootstrapProps {
  setPaymentEnabled: (enabled: boolean) => void
}

const Bootstrap: React.FC<BootstrapProps> = props => {
  const intl = useIntl()
  const t = intl.formatMessage
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const token = query.get('token')
  const [appContext, setAppContext] = useState<IAppContext>({})
  const isMobile = /iPhone|iPad|iPod|Android|webOS|Windows Phone/i.test(navigator.userAgent)

  const [isSubHeaderVisible, setIsSubHeaderVisible] = useState(false)
  const [leftSubHeaderGroup, setLeftSubHeaderGroup] = useState<ReactElement>(<></>)
  const [rightSubHeaderGroup, setRightSubHeaderGroup] = useState<ReactElement>(<></>)

  if (token) {
    setLoginToken(token as string)
  }

  const setContextLink = setContext((request, previousContext) => {
    if (previousContext.noAppContext) {
      return previousContext
    }
    return {
      ...previousContext,
      headers: {
        ...previousContext.headers,
        ...(Object.keys(appContext) as Array<keyof IAppContext>)
          .filter(o => appContext[o])
          .map(c => ({ ['context-' + c]: appContext[c]?.id }))
          .reduce((obj, col) => Object.assign(col, obj), {}),
      },
    }
  })

  const client = useApolloClient()
  client.setLink(setContextLink.concat(link))

  const { data: dataMe, error: errorMe } = GQL.useMe(CacheConfigs.ACCURATE_ONCE)
  const me = useMemo(() => (dataMe?.me as GQL.UserNode) || null, [dataMe])

  const { data: dataVersions } = GQL.useGetAppVersions({
    ...CacheConfigs.ACCURATE_ONCE,
    notifyOnNetworkStatusChange: true,
    onError: () => {
      console.error('version data not available')
    },
  })

  const { data: dataDistributors, loading: loadingDistributors } = GQL.useAllDistributors({
    ...CacheConfigs.ACCURATE_ONCE,
    context: { dropDistributorContext: true },
  })
  const distributors = useMemo(() => dataDistributors?.allDistributors?.edges.map(edge => edge?.node as GQL.DistributorNode) || [], [dataDistributors])
  const [errorDistributors, setErrorDistributors] = useState(false)

  const { data: dataUsers, loading: loadingUsers } = GQL.useUsers({
    ...CacheConfigs.ACCURATE_ONCE,
    skip: !appContext.distributor,
  })

  const users = useMemo(() => dataUsers?.allUsers?.edges.map(edge => edge?.node as GQL.UserNode) || [], [dataUsers])

  const setContextDistributors = useCallback(() => {
    if (!me || distributors.length === 0) return
    const localDistributor = localStorage.getItem('distributor')
    if (localDistributor && me.isSuperuser) {
      const distributor = distributors.find(distributor => distributor.id === localDistributor) || me.primaryDistributor
      if (!distributor || distributor.id === me.primaryDistributor?.id) {
        localStorage.removeItem('distributor')
      }
      return setAppContext(context => ({ ...context, distributor: distributor }))
    } else if (localDistributor && me.distributors?.some(e => e?.id === localDistributor)) {
      return setAppContext(context => ({ ...context, distributor: me?.distributors?.find(distributor => distributor?.id === localDistributor) }))
    } else if (me.primaryDistributor) {
      return setAppContext(context => ({ ...context, distributor: me.primaryDistributor }))
    } else if (me.distributors && me.distributors.length > 0) {
      return setAppContext(context => ({ ...context, distributor: me.distributors?.find(distributor => true) }))
    }
    setErrorDistributors(true)
  }, [me, setAppContext, distributors])

  const setContextUser = useCallback(() => {
    if (!me || users.length === 0) return
    const selectedIdentity = localStorage.getItem('selected-identity')
    if (!selectedIdentity || !me?.isSuperuser) return setAppContext(context => ({ ...context, user: me }))
    const user = users.find(user => user.id === selectedIdentity) || me
    if (user.id === me.id) {
      localStorage.removeItem('selected-identity')
    }
    setAppContext(context => ({ ...context, user: user }))
  }, [me, setAppContext, users])

  useEffect(() => {
    if (!me || !appContext.distributor) return
    Sentry.setUser({
      id: me?.id,
      username: me?.username,
      email: me?.email,
      distributor: appContext.distributor?.fullName || 'No distributor discovered',
      version: getVersion(),
    })

    window._chatlio.identify(me.id, {
      name: me?.fullName || 'no name',
      email: me?.email || 'no email',
      distributor: appContext.distributor?.fullName,
      version: getVersion(),
    })

    props.setPaymentEnabled(paymentEnabled(appContext))
  }, [me, appContext, props])

  const isSuspended = useMemo(() => {
    return appContext.distributor?.isSuspended || false
  }, [appContext])

  useEffect(() => {
    if (!isDev() && dataVersions?.getLatestAppVersion?.version && !(getVersion() === dataVersions?.getLatestAppVersion?.version || getVersion() === '1337')) {
      displayToast('A new version of Solace has been released. Please refresh your browser!', 'refresh', { autoClose: false }, true)
    }
  }, [dataVersions])

  if (isSuspended) {
    return (
      <AccountSuspended
        title={t({ id: 'common.errors.account-suspended.title' })}
        text={t({ id: 'common.errors.account-suspended.message' })}
        contactMessage={t({ id: 'common.errors.account-suspended.contact-message' })}
      />
    )
  }

  // email unsubscribe link handler
  if (window.location.pathname.includes('/unsubscribe')) {
    return <Unsubscribe />
  }

  if (errorMe) {
    const networkError = errorMe.networkError as ServerError | null
    if (networkError && typeof networkError.result === 'object') {
      const resultCodeName = networkError.result.code_name

      if (resultCodeName === 'ACCESS_DENIED') {
        return (
          <CenteredErrorMessage className='none' message='No access to the system or network error'>
            <div style={{ marginTop: '2em', display: 'flex', flexDirection: 'column', height: '120px', justifyContent: 'space-between' }}>
              <Button color='green' onClick={() => window.location.reload()}>
                Reload
              </Button>
              <Button
                color='green'
                onClick={() =>
                  client.clearStore().then(() => {
                    removeLoginToken()
                    window.location.reload()
                  })
                }
              >
                Log out
              </Button>
            </div>
          </CenteredErrorMessage>
        )
      }
    }

    return <CenteredErrorMessage className='none' />
  }

  if (errorDistributors) {
    return (
      <CenteredErrorMessage message='There has been problem reaching out for your data'>
        <div style={{ marginTop: '2em', display: 'flex', flexDirection: 'column', height: '120px', justifyContent: 'space-between' }}>
          <Button color='green' onClick={() => window.location.reload()}>
            Reload
          </Button>
          <Button
            color='green'
            onClick={() =>
              client.clearStore().then(() => {
                removeLoginToken()
                window.location.reload()
              })
            }
          >
            Log out
          </Button>
        </div>
      </CenteredErrorMessage>
    )
  }

  if (!getLoginToken()) {
    const domain = window.location.protocol + '//login.' + get_domain() + (get_domain().includes('localhost') ? ':3001' : window.location.port)
    window.location.href = domain
    return null
  }

  if (!dataMe || loadingDistributors || loadingUsers) {
    return <FullLoader />
  }

  if (!me) {
    removeLoginToken()
    setAppContext({})
    return null
  }

  // Needs to be called from here otherwise other queries could be run without distributor and user context.
  if (!appContext.distributor) setContextDistributors()
  if (!appContext.user) setContextUser()

  if (isMobile) {
    return (
      <AppContext.Provider value={{ appContext, setAppContext }}>
        <MobileLayout />
      </AppContext.Provider>
    )
  }

  return (
    <AppContext.Provider value={{ appContext, setAppContext }}>
      <HeaderContext.Provider
        value={{ isSubHeaderVisible, setIsSubHeaderVisible, leftSubHeaderGroup, setLeftSubHeaderGroup, rightSubHeaderGroup, setRightSubHeaderGroup }}
      >
        <CustomerContextProvider>
          <TableContextProvider>
            <Routes>
              <Route
                path='/order/pdf/:id'
                element={
                  <React.Suspense fallback={<span>Loading....</span>}>
                    <OrderPdf />
                  </React.Suspense>
                }
              />
              <Route
                path='/route/pdf/:id'
                element={
                  <React.Suspense fallback={<span>Loading....</span>}>
                    <RoutePdf />
                  </React.Suspense>
                }
              />
              <Route
                path='/order/pdf/delivery/:id'
                element={
                  <React.Suspense fallback={<span>Loading....</span>}>
                    <DeliveryNotePdf />
                  </React.Suspense>
                }
              />
              <Route path='*' element={<MainLayout />} />
            </Routes>
          </TableContextProvider>
        </CustomerContextProvider>
      </HeaderContext.Provider>
    </AppContext.Provider>
  )
}

export default Bootstrap
