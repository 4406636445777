import React, { useState } from 'react'
import styled from 'styled-components'
import ClickAwayListener from 'react-click-away-listener'
import PlacesAutocomplete, { Suggestion as PlaceSuggestion } from 'react-places-autocomplete'
import Input from './Input'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

interface ISuggestionsProps {
  open?: boolean
  hasOutsideIcon?: boolean
}

const Suggestions = styled.div<ISuggestionsProps>`
  display: ${props => (props.open ? 'block' : 'none')};

  background: ${props => props.theme.colors.primaryDark};
  padding: 1em;
  position: absolute;
  top: 50px;
  left: 0;
  ${props =>
    props.hasOutsideIcon &&
    `
    left: 3rem;
  `}
  border: 1px solid ${props => props.theme.colors.gray2};
  right: 0;
  margin: 0;
  max-height: 45vh;
  overflow-y: auto;

  box-sizing: border-box;

  list-style: none;

  z-index: 1000;
`

const Suggestion = styled.div<{ $active: boolean }>`
  text-align: left;
  box-sizing: border-box;

  padding: 0.5em;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.blue};
  }
  ${props =>
    props.$active &&
    `
  background: #2d365a;
  border-radius: 4px;
  `};
`

// eslint-disable-next-line
const Geocoder = new window.google.maps.Geocoder()

interface IAutoCompleteProps {
  id?: string
  value?: string
  onChange: (value: string) => void
  onLatLngChange: ({ latitude, longitude, formattedAddress }: { latitude: number; longitude: number; formattedAddress?: string }) => void
  placeholder?: string
  outsideIcon?: JSX.Element | null
  address?: string
  background?: string
  borderColor?: string
  borderRadius?: string
  fontSize?: string
  name?: string
  ref?: any
  error?: string | undefined
  inputProps?: any
}

const AutoComplete = React.forwardRef(
  ({ id, value, onChange, onLatLngChange, placeholder, outsideIcon, address, name, error, inputProps, ...props }: IAutoCompleteProps, ref) => {
    const [latLngDirty, setLatLngDirty] = useState(false)
    const [open, setOpen] = useState(false)

    function handleChange(address: string) {
      onChange(address)
      setOpen(true)
      setLatLngDirty(true)
    }

    function handleSelect(address: string) {
      setOpen(false)
      onChange(address)
      geocodeAddress(address)
    }

    function geocodeAddress(address: string) {
      if (!latLngDirty) {
        return
      }
      if (!address) {
        return
      }
      setLatLngDirty(false)

      Geocoder.geocode({ address }, response => {
        if (!response || response.length === 0) {
          onChange('')
          onLatLngChange({
            latitude: 0,
            longitude: 0,
          })
          return
        }

        const result = response[0]
        const location = result.geometry.location

        onChange(result.formatted_address)
        onLatLngChange({
          latitude: location.lat(),
          longitude: location.lng(),
          formattedAddress: result.formatted_address,
        })
      })
    }

    function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
      setOpen(false)

      if (value) {
        geocodeAddress(value)
      }
    }

    function handleClickAway(suggestions: ReadonlyArray<PlaceSuggestion>) {
      if (open) {
        setOpen(false)
        if (suggestions.length > 0) {
          onChange(suggestions[0].description)
          geocodeAddress(suggestions[0].description)
        }
      }
    }

    return (
      <PlacesAutocomplete value={value} onChange={handleChange} onSelect={handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <ClickAwayListener onClickAway={e => handleClickAway(suggestions)}>
            <Wrapper>
              <Input
                id={id}
                {...getInputProps({
                  placeholder: placeholder,
                })}
                name={name}
                ref={ref}
                outsideIcon={outsideIcon}
                error={error}
                onBlur={handleBlur}
                {...inputProps}
                {...props}
              />
              <Suggestions className='autocomplete-dropdown-container' open={open} hasOutsideIcon={!!outsideIcon}>
                {suggestions.map((suggestion, i) => {
                  return (
                    <Suggestion {...getSuggestionItemProps(suggestion)} $active={suggestion.active} key={i}>
                      {suggestion.description}
                    </Suggestion>
                  )
                })}
              </Suggestions>
            </Wrapper>
          </ClickAwayListener>
        )}
      </PlacesAutocomplete>
    )
  }
)

AutoComplete.defaultProps = {
  onLatLngChange: (...arg) => arg[0],
}

export default AutoComplete
