import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { overloadColorProp } from 'util/style'

import { getIconTypeCode, IconType } from './iconHelpers'

interface IconProps extends React.DOMAttributes<HTMLElement> {
  icon: string
  title?: string
  type?: IconType
  fixedWidth?: boolean

  display?: string
  className?: string
  size?: string
  hoverColor?: string
  color?: string
  width?: string
  margin?: string

  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

const Icon: React.FC<IconProps> = forwardRef<HTMLElement, IconProps>(({ icon, className, type, title, onClick, fixedWidth }, ref) => {
  const iconTypeCode = getIconTypeCode(type)
  const fixedWidthClass = fixedWidth ? 'fa-fw' : ''
  const iconClass = `${iconTypeCode} fa-${icon}`

  return <i ref={ref} className={`${fixedWidthClass} ${iconClass} ${className}`} title={title} onClick={onClick} />
})

const StyledIcon = styled(Icon)`
  color: ${props => overloadColorProp(props)};
  font-size: ${props => props.size};
  margin: ${props => props.margin};
  display: ${props => props.display};
  width: ${props => props.width};

  ${props =>
    props.hoverColor &&
    `
      &:hover {
        color: ${overloadColorProp({
          theme: props.theme,
          color: props.hoverColor,
        })};
      }
    `}
`
StyledIcon.defaultProps = {
  color: 'inherit',
  size: '1em',
}

export default StyledIcon
