import React, { FunctionComponent, MutableRefObject, useState } from 'react'
import { useIntl } from 'react-intl'

import { UserNode } from 'generated/graphql'
import Dropdown from 'components/Dropdown/Dropdown'
import DropdownItem from 'components/Dropdown/DropdownItem'
import Icon from 'components/Icon'
import ScIcon from 'components/Icon/ScIcon'
import UserSelectModal from 'modules/users/components/UserSelectModal'

interface Props {
  open: boolean
  me: UserNode
  contextUser: UserNode
  anchor: MutableRefObject<HTMLElement | null>
  onSettingsClick: () => void
  onLogoutClick: () => void
  onLogoutEverywhereClick: () => void
  onClose: () => void
}

const MoreDropdown: FunctionComponent<Props> = props => {
  const intl = useIntl()
  const t = intl.formatMessage

  const [selectedIdentity, setSelectedIdentity] = useState<UserNode>()
  const [showIdentitySelectionModal, setShowIdentitySelectionModal] = useState(false)

  const selectIdentity = (identity: UserNode) => {
    setSelectedIdentity(identity)
    setShowIdentitySelectionModal(false)
    localStorage.setItem('selected-identity', identity.id)

    window.location.reload()
  }

  return (
    <Dropdown open={props.open} anchor={props.anchor} showCaret={false} position='bottom' align='innerEnd' borderRadius='10px' onClose={props.onClose}>
      <DropdownItem border={true}>
        Hello, {props.contextUser.firstName}! {props.contextUser.isSuperuser ? ' You are superuser!' : null}
      </DropdownItem>
      {props.me.isSuperuser && (
        <>
          <DropdownItem border={true} onClick={() => setShowIdentitySelectionModal(true)} icon={<ScIcon size='12px' icon='sc-customer' color='gray7' />}>
            {t({ id: 'identity-dropdown.change-identity' })} ({selectedIdentity ? selectedIdentity.fullName : props.contextUser.fullName})
          </DropdownItem>
          <UserSelectModal open={showIdentitySelectionModal} onClose={() => setShowIdentitySelectionModal(false)} onSelect={user => selectIdentity(user)} />
        </>
      )}
      <DropdownItem border={true} icon={<ScIcon icon='sc-settings' size='15px' color='gray7' />} onClick={props.onSettingsClick}>
        Settings
      </DropdownItem>
      <DropdownItem border={true} onClick={props.onLogoutClick} icon={<Icon type='solid' icon='arrow-right' color='gray7' size='15px' />}>
        {t({ id: 'common.logout' })}
      </DropdownItem>
      <DropdownItem onClick={props.onLogoutEverywhereClick} icon={<Icon type='solid' icon='arrow-right' color='gray7' size='15px' />}>
        {t({ id: 'common.logout-everywhere' })}
      </DropdownItem>
    </Dropdown>
  )
}

export default MoreDropdown
