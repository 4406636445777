// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { useIntl } from 'react-intl'

import { PlasmicTableGroupLabel, DefaultTableGroupLabelProps } from './plasmic/solace_components/PlasmicTableGroupLabel'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import { useCustomerContext } from 'util/hooks'
import { CustomerDrawerListSource } from 'context/CustomerContext'

export interface TableGroupLabelProps extends DefaultTableGroupLabelProps {}

function TableGroupLabel_(props: TableGroupLabelProps, ref: HTMLElementRefOf<'div'>) {
  const { source } = useCustomerContext()

  const intl = useIntl()
  const t = intl.formatMessage

  const color = React.useMemo(() => {
    if (
      source === CustomerDrawerListSource.SENSOR_CUSTOMERS ||
      source === CustomerDrawerListSource.NO_SENSOR_CUSTOMERS ||
      source === CustomerDrawerListSource.PAUSED_ORDERS
    ) {
      return 'white'
    } else if (source === CustomerDrawerListSource.AWAITING_DELIVERY_ORDERS) {
      return 'blue'
    } else if (source === CustomerDrawerListSource.READY_TO_REFILL) {
      return 'orange'
    } else if (source === CustomerDrawerListSource.PLACED_ORDERS) {
      return 'purple'
    }
    return 'white'
  }, [source])

  const label = React.useMemo(() => {
    if (source === CustomerDrawerListSource.SENSOR_CUSTOMERS) {
      return t({ id: 'customers.sensor-customers' })
    } else if (source === CustomerDrawerListSource.NO_SENSOR_CUSTOMERS) {
      return t({ id: 'customers.no-sensor-customers' })
    } else if (source === CustomerDrawerListSource.READY_TO_REFILL) {
      return t({ id: 'customers.ready-to-refill-customers' })
    } else if (source === CustomerDrawerListSource.PAUSED_ORDERS) {
      return t({ id: 'orders.status.orders-paused' })
    } else if (source === CustomerDrawerListSource.AWAITING_DELIVERY_ORDERS) {
      return t({ id: 'orders.status.awaiting-delivery' })
    } else if (source === CustomerDrawerListSource.PLANNED_DELIVERY) {
      return t({ id: 'customers.planned-delivery' })
    } else if (source === CustomerDrawerListSource.TOTAL_DELIVERIES) {
      return t({ id: 'customers.total-deliveries' })
    } else if (source === CustomerDrawerListSource.UNMANAGABLE_DELIVERIES) {
      return t({ id: 'customers.unmanagable-delivery' })
    } else if (source === CustomerDrawerListSource.UNPLANNED_DELIVERIES) {
      return t({ id: 'customers.unplanned-delivery' })
    }
    return t({ id: 'orders.status.order-placed' })
  }, [source])

  return <PlasmicTableGroupLabel root={{ ref }} {...props} label={label} color={color} />
}

const TableGroupLabel = React.forwardRef(TableGroupLabel_)
export default TableGroupLabel
