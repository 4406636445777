export const API_URL = isLocal()
  ? 'http://localhost:8020'
  : isDev()
    ? 'https://api.widar.dev.lpg.ninja'
    : isStaging()
      ? 'https://api.widar.staging.lpg.ninja'
      : isProd()
        ? 'https://api.smartcylinders.com'
        : '' // Unknown environment

function isEnv(env: string) {
  return process.env.REACT_APP_ENVIRONMENT === env
}

export function isLocal() {
  return isEnv('local')
}

export function isStaging() {
  return isEnv('staging')
}

export function isDev() {
  return isEnv('dev')
}

export function isProd() {
  return isEnv('prod')
}

export function getVersion() {
  if (isProd()) {
    return process.env.REACT_APP_CI_COMMIT_REF_NAME
  }
  return process.env.REACT_APP_CI_JOB_ID
}

export function getCustomEnvironment() {
  return process.env.REACT_APP_ENVIRONMENT || 'unknown'
}

export const get_root_domain = () => {
  const domain = window.location.hostname.split('.')
  return domain.slice(Math.max(domain.length - 2, 0), domain.length).join('.')
}

export const get_domain = () => {
  const domain = window.location.hostname.split('.')
  return domain.slice(domain.indexOf('solace') + 1).join('.')
}

export const getUrl = () => {
  return isLocal() ? 'localhost:3000' : isDev() ? 'dev.lpg.ninja' : isStaging() ? 'staging.lpg.ninja' : isProd() ? 'smartcylinders.com' : '' // Unknown environment
}

export const registerSensorAppUrl = () => {
  if (isLocal()) {
    return 'http://login.localhost:3002'
  }

  if (isDev()) {
    return 'https://register.dev.lpg.ninja/start'
  }

  if (isStaging()) {
    return 'https://register.staging.lpg.ninja/start'
  }

  return 'https://r.smartcylinders.com/start'
}
