import React, { useRef } from 'react'
import { Route, Navigate, Routes } from 'react-router-dom'
import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'

import Header from 'components/Header'
import Inventory from 'modules/inventory'
import Orders from 'modules/orders/Orders'
import Customers from 'modules/customers/Customers'
import CustomersDrawer from 'modules/customers/CustomersDrawer/CustomersDrawer'
import Deliveries from 'modules/deliveries/Deliveries'
import Settings from 'modules/settings/Settings'
import Insight from 'modules/insight/Insight'
import ContentScrollProvider from './ContentScrollProvider'

import 'react-toastify/dist/ReactToastify.css'

const StyledToastContainer = styled(ToastContainer).attrs({
  autoClose: 4000,
  position: 'top-center',
  icon: false,
  closeButton: false,
  style: {
    width: 'auto',
  },
  hideProgressBar: true,
  closeOnClick: false,
})`
  .Toastify__toast-container {
  }
  .Toastify--animate {
    animation-duration: 0.5s;
  }
  .Toastify__toast {
    cursor: inherit;
    font-family: 'Circular';
    background: rgba(255, 255, 255, 0);
    box-shadow: none;
    padding: 0;
    margin-bottom: 0.75em;
    min-height: 10px;
  }
  .Toastify__toast-body {
    padding: 0;
    margin: 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${props => props.theme.layout.small} {
    display: flex;
    flex-direction: column;
  }
`

const ContentWrapper = styled.div`
  height: 100%;
  overflow: auto;
  background-color: ${props => props.theme.colors.primaryDark};
`

function MainLayout() {
  const contentRef = useRef(null)

  return (
    <Wrapper>
      <Header />
      <ContentWrapper id='content-wrapper' ref={contentRef}>
        <span id='content-wrapper-top' />
        <ContentScrollProvider contentRef={contentRef}>
          <Routes>
            <Route path='/insight/*' element={<Insight />} />
            <Route path='/orders/*' element={<Orders />} />
            <Route path='/deliveries/*' element={<Deliveries />} />
            <Route path='/customers/*' element={<Customers />} />
            <Route path='/inventory/*' element={<Inventory />} />
            <Route path='/settings/*' element={<Settings />} />
            <Route path='*' element={<Navigate to='/insight' />} />
          </Routes>
        </ContentScrollProvider>
      </ContentWrapper>
      <CustomersDrawer />
      <StyledToastContainer />
    </Wrapper>
  )
}

export default MainLayout
