import React, { useState } from 'react'
import styled from 'styled-components'
import theme from 'themes/theme'

import DetectClickOutside from 'containers/DetectClickOutside'
import SelectMainInput from './SelectMainInput'
import { useIntl } from 'react-intl'

const SelectWrapper = styled.div<{ inline?: boolean; open?: boolean }>`
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  position: relative;

  z-index: ${props => (props.open ? '9001' : '0')};
`

interface ISelectListProps {
  open?: boolean
  hasOutsideIcon?: boolean
  left?: string | number
  right?: string | number
  background?: string
  border?: string
  borderRadius?: string
}

const SelectList = styled.ul<ISelectListProps>`
  display: ${props => (props.open ? 'block' : 'none')};

  background: ${props => props.background ?? props.theme.colors.primaryDark};
  padding: 0.5em;
  position: absolute;
  top: 0;
  ${props =>
    props.hasOutsideIcon &&
    `
    left: 3rem;
  `}
  border: ${props => props.border ?? `1px solid ${props.theme.colors.gray2}`};
  border-radius: ${props => props.borderRadius};
  left: ${props => props.left};
  right: ${props => props.right};
  margin: 0;
  max-height: 45vh;
  overflow-y: auto;
  box-sizing: border-box;
  list-style: none;
`

SelectList.defaultProps = {
  right: 0,
}
const SelectListItem = styled.li``

const DefaultInnerSelectListItem = styled.div`
  text-align: left;

  padding: 0.5em;
  cursor: pointer;

  color: ${props => props.theme.colors.gray3};

  &:hover {
    color: ${props => props.theme.colors.blue};
  }
`

export interface ISelectProps {
  className?: string
  id?: string

  searchable?: boolean
  options: {
    value: string
    label: string
    extra?: object
    render?: (isSelected: boolean) => React.ReactNode
  }[]
  placeholder?: string

  background?: string

  border?: string
  borderColor?: string
  borderRadius?: string

  inline?: boolean

  width?: string
  height?: string

  color?: string

  shadow?: string | number

  value?: string | number | undefined
  defaultValue?: string | number | undefined
  onChange: (newValue: string, extra?: object) => any
  onSearchChanged?: (newValue: string) => any

  noChoicesLabel?: string
  outsideIcon?: string | JSX.Element

  listProps?: ISelectListProps
  ref?: any
  error?: string
  forceOutsideIconSpacing?: boolean

  fontSize?: string
  loading?: boolean
}

const Select: React.FC<ISelectProps> = React.forwardRef(
  (
    {
      className,
      id,

      searchable,
      options,
      value,
      defaultValue,
      placeholder,

      inline,
      width,
      shadow,

      border,
      borderRadius,

      onChange,
      onSearchChanged,

      noChoicesLabel,
      loading,

      outsideIcon,
      forceOutsideIconSpacing,
      error,
      background,
      listProps,
      ...props
    },
    ref
  ) => {
    const [open, setOpen] = useState(false)
    const labelOfSelected = options.reduce((selected: React.ReactNode, next) => {
      if (next.value === (defaultValue || value)) {
        return next.render ? next.render(true) : next.label
      }
      return selected
    }, null)

    const hasOutsideIcon = !!outsideIcon
    const intl = useIntl()
    const t = intl.formatMessage

    return (
      <DetectClickOutside active={open} onClickOutside={() => setOpen(false)}>
        <SelectMainInput
          open={open}
          setOpen={setOpen}
          labelOfSelected={labelOfSelected}
          placeholder={placeholder}
          searchable={searchable}
          onSearchChanged={onSearchChanged}
          width={width}
          border={border}
          borderRadius={borderRadius}
          shadow={shadow}
          outsideIcon={outsideIcon}
          forceOutsideIconSpacing={forceOutsideIconSpacing}
          ref={ref}
          error={error}
          loading={loading}
          background={background}
          {...props}
        />
        <SelectWrapper id={id} inline={inline} open={open}>
          <SelectList open={open} hasOutsideIcon={hasOutsideIcon} {...listProps}>
            {options.map(option => (
              <SelectListItem
                key={option.value}
                onClick={e => {
                  onChange(option.value, option.extra)
                  setOpen(false)
                  return option.value
                }}
              >
                {option.render ? option.render(option.value === defaultValue) : <DefaultInnerSelectListItem>{option.label}</DefaultInnerSelectListItem>}
              </SelectListItem>
            ))}
            {options.length === 0 && (noChoicesLabel || t({ id: 'common.default-no-choices' }))}
          </SelectList>
        </SelectWrapper>
      </DetectClickOutside>
    )
  }
)

Select.defaultProps = {
  options: [],
  width: '100%',
  shadow: theme.shadow.default,
  border: '1px solid ' + theme.colors.gray7,
  listProps: {},
}

export default Select
