// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type UploadFilesvg2IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function UploadFilesvg2Icon(props: UploadFilesvg2IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 353.182 218.348"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M176.593 0c-26.736 0-49.849 14.077-63.464 34.994-5.342-1.69-10.923-2.76-16.806-2.76-29.133 0-52.979 22.476-55.686 50.923C17.292 90.006 0 110.97 0 136.46c0 30.989 25.198 56.187 56.187 56.187h48.163a8.026 8.026 0 008.14-7.912 8.029 8.029 0 00-8.14-8.143H56.187c-22.372 0-40.135-17.763-40.135-40.135a40.03 40.03 0 0133.487-39.634 8.032 8.032 0 006.648-8.028v-.377a40.015 40.015 0 0140.135-40.135c5.94 0 11.504 1.22 16.557 3.513a8.033 8.033 0 0010.41-3.637c10.057-19.18 30.047-32.105 53.304-32.105 23.253 0 43.247 12.929 53.304 32.105a8.027 8.027 0 0010.41 3.637c5.05-2.291 10.616-3.513 16.557-3.513a40.015 40.015 0 0140.135 40.135v.377a8.027 8.027 0 006.648 8.028 40.027 40.027 0 0133.487 39.634c0 22.372-17.76 40.135-40.132 40.135h-48.169c-4.433-.064-8.076 3.482-8.143 7.912a8.03 8.03 0 008.14 8.14h48.166c30.989 0 56.187-25.198 56.187-56.187 0-25.49-17.295-46.455-40.636-53.304-2.71-28.443-26.553-50.922-55.686-50.922-5.889 0-11.464 1.073-16.806 2.759C226.442 14.074 203.328 0 176.593 0z"
        }
        fill={"currentColor"}
        opacity={".3"}
      ></path>

      <path
        d={
          "M175.715 105.846a7.97 7.97 0 00-4.764 2.258l-48.163 48.16a8.028 8.028 0 1011.285 11.416l34.493-34.493v77.01c-.064 4.242 3.786 8.152 8.028 8.152s8.085-3.911 8.028-8.152v-77.01l34.493 34.493c3.09 3.181 8.17 3.251 11.352.161a8.03 8.03 0 00.158-11.352c-.07-.076-.149-.149-.222-.222l-48.163-48.16a8.03 8.03 0 00-6.525-2.261z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default UploadFilesvg2Icon;
/* prettier-ignore-end */
