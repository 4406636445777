import React from 'react'
import { createRoot } from 'react-dom/client'

import './index.css'
import './polyfills'
import Root from './containers/Root'
import * as Sentry from '@sentry/react'
import { getCustomEnvironment, isProd } from 'util/env'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import Modal from 'react-modal'
Modal.setAppElement('#root')

if (isProd()) {
  Sentry.init({
    dsn: 'https://5bc468566ef749eca87a6dabf3cda6e4@sentry.io/1877098',
    environment: getCustomEnvironment(),
    release: 'solace@' + (process.env.REACT_APP_CI_COMMIT_REF_NAME || 'local') + '-' + (process.env.REACT_APP_CI_JOB_ID || '0'),
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
        stickySession: true,
      }),
    ],
  })
}

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(<Root />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorkerRegistration.register()
