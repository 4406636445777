import { ReactElement, createContext } from 'react'

interface HeaderContext {
  isSubHeaderVisible: boolean
  setIsSubHeaderVisible: (isSubHeaderVisible: boolean) => void
  leftSubHeaderGroup: ReactElement
  setLeftSubHeaderGroup: (leftSubHeaderGroup: ReactElement) => void
  rightSubHeaderGroup: ReactElement
  setRightSubHeaderGroup: (rightSubHeaderGroup: ReactElement) => void
}

export default createContext<HeaderContext>({
  isSubHeaderVisible: false,
  setIsSubHeaderVisible: isSubHeaderVisible => {},
  leftSubHeaderGroup: <></>,
  setLeftSubHeaderGroup: leftSubHeaderGroup => {},
  rightSubHeaderGroup: <></>,
  setRightSubHeaderGroup: rightSubHeaderGroup => {},
})
