import styled from 'styled-components'

interface IPaddingProps {
  inline?: Boolean
  all?: string
  top?: string
  bottom?: string
  left?: string
  right?: string
}

export default styled.div<IPaddingProps>`
  display: ${props => (props.inline ? 'inline-block' : 'block')};

  ${props =>
    props.all
      ? `
    padding-top: ${props.all};
    padding-bottom: ${props.all};
    padding-left: ${props.all};
    padding-right: ${props.all};
  `
      : ''}
  ${props => (props.top ? `padding-top: ${props.top};` : '')}
  ${props => (props.bottom ? `padding-bottom: ${props.bottom};` : '')}
  ${props => (props.left ? `padding-left: ${props.left};` : '')}
  ${props => (props.right ? `padding-right: ${props.right};` : '')}
`
