// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type NotReportingsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function NotReportingsvgIcon(props: NotReportingsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 41.722 34.223"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <circle cx={"20.861"} cy={"31.06"} r={"3.163"}></circle>

        <path
          d={
            "M30.3 25.416a2.26 2.26 0 003.284.653c.939-.693 1.165-2.028.561-3.027a15.503 15.503 0 00-5.523-5.394c-1.481-.855-3.343.119-3.413 1.828v.01c-.03.737.464 1.649 1.105 2.015a10.99 10.99 0 013.986 3.915zm10.926-9.869c-3.286-4.309-7.714-7.375-12.711-8.925-1.389-.431-2.806.617-2.865 2.07l-.001.032c-.041.994.572 1.915 1.521 2.211 4.141 1.287 7.809 3.841 10.52 7.427a2.254 2.254 0 003.223.394c.964-.78 1.065-2.223.313-3.209zm-29.803 9.869a2.26 2.26 0 01-3.284.653c-.939-.693-1.165-2.028-.561-3.027a15.503 15.503 0 015.523-5.394c1.481-.855 3.343.119 3.413 1.828v.01c.03.737-.464 1.649-1.105 2.015a10.982 10.982 0 00-3.986 3.915zM.496 15.547c3.286-4.309 7.714-7.375 12.711-8.925 1.389-.431 2.806.617 2.865 2.07l.001.032c.041.994-.572 1.915-1.521 2.211-4.141 1.287-7.809 3.841-10.52 7.427a2.254 2.254 0 01-3.223.394c-.964-.78-1.064-2.223-.313-3.209zm17.957 7.095l-.795-19.414C17.588 1.515 18.965.07 20.734.003c1.769-.068 3.26 1.266 3.33 2.979.003.08.003.167 0 .246l-.794 19.414c-.053 1.288-1.174 2.291-2.504 2.24-1.263-.048-2.261-1.038-2.313-2.24z"
          }
        ></path>
      </g>
    </svg>
  );
}

export default NotReportingsvgIcon;
/* prettier-ignore-end */
