// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ExportDatasvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ExportDatasvgIcon(props: ExportDatasvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 410 410"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <path
          d={
            "M217.314 5.966C213.908 2.56 209.427.833 204.947.833s-8.961 1.703-12.367 5.133l-70 70c-6.836 6.836-6.836 17.922 0 24.758s17.922 6.836 24.758 0l40.133-40.133v214.41c0 9.66 7.84 17.5 17.5 17.5s17.5-7.84 17.5-17.5V60.591l40.133 40.133c6.836 6.836 17.922 6.836 24.758 0s6.836-17.922 0-24.758l-70-70h-.048z"
          }
        ></path>

        <path
          d={
            "M391.668 137.499c-9.66 0-17.5 7.84-17.5 17.5v190c0 16.078-13.09 29.168-29.168 29.168H65c-16.078 0-29.168-13.09-29.168-29.168v-190c0-9.66-7.84-17.5-17.5-17.5s-17.5 7.84-17.5 17.5v190c0 35.398 28.77 64.168 64.168 64.168h280c35.398 0 64.168-28.77 64.168-64.168v-190c0-9.661-7.84-17.5-17.5-17.5z"
          }
        ></path>
      </g>
    </svg>
  );
}

export default ExportDatasvgIcon;
/* prettier-ignore-end */
