/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { useIntl } from 'react-intl'
import Select from 'react-select'

import Loader from 'components/Loader'
import * as GQL from 'generated/graphql'
import { modalStyle } from 'components/Modal/PlasmicModal'
import ModalNewDriver from 'plasmic/ModalNewDriver'
import { displayToast } from 'util/toasts'
import { useAppContext } from 'util/hooks'
import PhoneInput from 'components/Input/PhoneInput'
import { Control, MenuList, Option, reactSelectStyles, IndicatorsContainer } from 'plasmic/StyledReactSelect'

interface OptionType {
  value: string
  label: string
}

const VehicleModal: React.FC<any> = ({ isOpen, onRequestClose, back }) => {
  const {
    appContext: { depot },
  } = useAppContext()

  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [phoneValid, setPhoneValid] = useState<boolean>(false)
  const [defaultVehicle, setDefaultVehicle] = useState<OptionType>({
    value: '',
    label: '',
  })
  const [defaultDepot, setDefaultDepot] = useState<OptionType>({
    value: depot?.id || '',
    label: depot?.name || '',
  })

  const { data: dataVehicles, loading: loadingVehicles } = GQL.useAllVehicles()
  const { data: dataDepots, loading: loadingDepots } = GQL.useAllDepots()
  const vehicles = dataVehicles?.allVehicles?.edges.map(edge => (edge?.node as GQL.VehicleNode) || [])

  const intl = useIntl()
  const t = intl.formatMessage

  const [createDriver] = GQL.useCreateDriver({
    onCompleted: response => {
      displayToast(t({ id: 'deliveries.drivers-vehicles-new-driver.success' }), 'success')
      onRequestClose()
    },
    onError: error => {
      error.message && displayToast(error.message)
      displayToast(t({ id: 'common.error-occurred' }))
    },
    update(cache, { data }) {
      cache.modify({
        fields: {
          allDrivers(driversRefs, { readField }) {
            const fragRef = cache.writeFragment({
              fragment: GQL.DriverInfo,
              fragmentName: 'DriverInfo',
              data: data?.createDriver?.user?.driver,
            })

            if (driversRefs.edges.some((ref: any) => readField('id', ref.node) === data?.createDriver?.user?.driver?.id)) {
              return driversRefs
            }

            return {
              ...driversRefs,
              edges: [{ node: fragRef, __typename: 'DriverNodeEdge' }, ...driversRefs.edges],
            }
          },
        },
      })
    },
  })

  const handleSubmit = () => {
    if (!firstName || !lastName) {
      displayToast(t({ id: 'deliveries.drivers-vehicles.new-driver.full-name-error' }))
    } else if (!email) {
      displayToast(t({ id: 'deliveries.drivers-vehicles.new-driver.email-error' }))
    } else if (!phone) {
      displayToast(t({ id: 'deliveries.drivers-vehicles.new-driver.phone-error' }))
    } else if (!defaultDepot || !defaultVehicle) {
      displayToast(t({ id: 'deliveries.drivers-vehicles.new-driver.depot-vehicle-error' }))
    } else {
      createDriver({
        variables: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          phoneNumber: phone,
          vehicle: defaultVehicle.value,
          depot: defaultDepot.value,
        },
      })
    }
  }
  return (
    <ReactModal id='addDriver' isOpen={isOpen} onRequestClose={onRequestClose} style={modalStyle()}>
      <ModalNewDriver
        titleHeader={{ onClick: () => back() }}
        existingUser={false}
        btnClose={{ onClick: onRequestClose }}
        inputNameFirst={{ undefinedInput: { value: firstName, onChange: (e: any) => setFirstName(e.target.value) } }}
        inputNameLast={{ undefinedInput: { value: lastName, onChange: (e: any) => setLastName(e.target.value) } }}
        inputEmail={{ undefinedInput: { value: email, onChange: (e: any) => setEmail(e.target.value) } }}
        phoneInput={
          <PhoneInput
            width='100%'
            defaultValue={''}
            error={phone.length <= 1 ? null : phoneValid ? null : { message: 'Please provide a valid phone number' }}
            format={true}
            onPhoneNumberChange={(...all) => {
              setPhone(all[3].replace(/\s|-/g, ''))
              setPhoneValid(all[0])
            }}
            onSelectFlag={(...all) => {
              setPhone(all[2].replace(/\s|-/g, ''))
              setPhoneValid(all[3])
            }}
          />
        }
        inputVehicleDiv={
          <Select
            placeholder={loadingVehicles ? <Loader color='white' /> : t({ id: 'common.select-vehicle' })}
            components={{ Option, Control, MenuList, IndicatorsContainer }}
            styles={reactSelectStyles}
            isMulti={false}
            onChange={event => setDefaultVehicle(event)}
            value={
              {
                value: defaultVehicle.value,
                label: defaultVehicle.label || t({ id: 'common.select-vehicle' }),
              } as OptionType
            }
            options={vehicles?.map(
              vehicle =>
                ({
                  value: vehicle?.id,
                  label: vehicle?.name,
                }) as OptionType
            )}
          />
        }
        inputDepotDiv={
          <Select
            placeholder={loadingDepots ? <Loader color='white' /> : t({ id: 'common.select-depot' })}
            components={{ Option, Control, MenuList, IndicatorsContainer }}
            styles={reactSelectStyles}
            isMulti={false}
            onChange={event => setDefaultDepot(event)}
            value={
              {
                value: defaultDepot.value,
                label: defaultDepot.label || t({ id: 'common.select-depot' }),
              } as OptionType
            }
            options={dataDepots?.allDepots?.edges.map(
              depot =>
                ({
                  value: depot?.node?.id,
                  label: depot?.node?.name,
                }) as OptionType
            )}
          />
        }
        btnCreateDriver={{
          onClick: () => handleSubmit(),
          color: firstName && lastName && email && phone && phoneValid && defaultDepot && defaultVehicle ? 'green' : 'gray',
        }}
      />
    </ReactModal>
  )
}

export default VehicleModal
