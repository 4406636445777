import { useState } from 'react'
import { useTheme } from 'styled-components'
import { useIntl } from 'react-intl'
import ReactModal from 'react-modal'
import { Range } from 'react-date-range'
import { subDays } from 'date-fns'

import { displayToast } from 'util/toasts'
import * as GQL from 'generated/graphql'
import ExportDateRangePicker from '../components/ExportDateRangePicker'
import { getModalStyle } from 'components/Modal/Modal'
import { downloadOrderData, generateOrderCSV, generateOrderJSON, generateOrderXLS } from '../util'
import CacheConfigs from 'util/cacheConfig'
import ModalExportData from 'plasmic/ModalExportData'

interface Filters {
  stateIn: number[]
  customerType: number[]
  search: string
  selectedTags: string[]
}

interface ExportOrdersProps {
  isOpen: boolean
  onClose: () => void
  currentFilters: Filters
}

export default function ExportOrders({ isOpen, onClose, currentFilters }: ExportOrdersProps) {
  const [filtersOn, setFiltersOn] = useState(true)
  const [format, setFormat] = useState('')
  const [exportRange, setExportRange] = useState<Range[]>([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: 'selection',
    },
  ])

  const intl = useIntl()
  const t = intl.formatMessage
  const theme = useTheme()

  const [fetchOrders, { loading }] = GQL.useAllNotPaginatedCylinderGroupOrdersLazyQuery({
    ...CacheConfigs.ACCURATE_ONCE,
    onCompleted: response => {
      if (!response) {
        displayToast(t({ id: 'orders.toasts.export-modal.fetch-failed' }), 'error')
        return
      }

      if (!response.allNotPaginatedCylinderGroupOrders || response.allNotPaginatedCylinderGroupOrders.length === 0) {
        displayToast(t({ id: 'orders.toasts.export-modal.no-data' }), 'error')
        return
      }

      if (format.toLowerCase() === '.json') {
        const JSON = generateOrderJSON(response.allNotPaginatedCylinderGroupOrders.map(order => order as GQL.CylinderGroupOrderNode))
        const blob = new Blob([JSON], { type: 'application/json' })
        downloadOrderData(blob, 'orders.json')
      } else if (format.toLowerCase() === '.csv') {
        const jsonData = generateOrderJSON(response.allNotPaginatedCylinderGroupOrders.map(order => order as GQL.CylinderGroupOrderNode))
        const csv = generateOrderCSV(JSON.parse(jsonData))
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
        downloadOrderData(blob, 'orders.csv')
      } else if (format.toLowerCase() === '.xls') {
        const jsonData = generateOrderJSON(response.allNotPaginatedCylinderGroupOrders.map(order => order as GQL.CylinderGroupOrderNode))
        const xls = generateOrderXLS(JSON.parse(jsonData))
        const blob = new Blob([xls], { type: 'application/vnd.ms-excel' })
        downloadOrderData(blob, 'orders.xls')
      }

      setFiltersOn(true)
      setFormat('')
      setExportRange([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      ])
      onClose()
    },
    onError: () => {
      displayToast(t({ id: 'orders.toasts.export-modal.fetch-failed' }), 'error')
    },
  })

  const handleExport = () => {
    if (!exportRange[0].startDate) {
      displayToast(t({ id: 'orders.toasts.export-modal.start-date-error' }), 'error')
      return
    }
    if (!format) {
      displayToast(t({ id: 'orders.toasts.export-modal.format-missing-error' }), 'error')
      return
    }

    if (filtersOn) {
      fetchOrders({
        variables: {
          search: currentFilters.search,
          customerType: currentFilters.customerType,
          stateIn: currentFilters.stateIn,
          selectedTags: currentFilters.selectedTags,
          before: exportRange[0].endDate ? exportRange[0].endDate?.toISOString().split('T')[0] : new Date().toISOString().split('T')[0],
          after: new Date(exportRange[0].startDate).toISOString().split('T')[0],
        },
      })
      return
    }
    fetchOrders({
      variables: {
        before: exportRange[0].endDate ? exportRange[0].endDate?.toISOString().split('T')[0] : new Date().toISOString().split('T')[0],
        after: new Date(exportRange[0].startDate).toISOString().split('T')[0],
      },
    })
  }

  return (
    <ReactModal isOpen={isOpen} onRequestClose={() => onClose()} style={getModalStyle({ height: 'auto', overflowY: 'auto' }, {}, false, theme, true)}>
      <ModalExportData
        toggleFilter={{
          on: filtersOn,
          root: {
            props: {
              onClick: () => setFiltersOn(prev => !prev),
            },
          },
        }}
        selectFormat={{
          props: {
            options: ['.csv', '.json', '.xls'],
            value: format,
            onChange: setFormat,
          },
        }}
        btnExport={{
          props: {
            loading: loading,
            disabled: loading,
            label: loading ? 'Preparing data. Please wait...' : 'Export data',
            onClick: () => handleExport(),
          },
        }}
        btnClose={{
          props: {
            onClick: () => onClose(),
          },
        }}
        calendarDiv={<ExportDateRangePicker initialRange={exportRange} onRangeChange={setExportRange} />}
      />
    </ReactModal>
  )
}
