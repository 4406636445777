// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type TagIconsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function TagIconsvgIcon(props: TagIconsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 73.84 73.9"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M71.34 2.5c-1.6-1.6-3.7-2.5-6-2.5h-23.5c-2.7.1-5.2 1.2-7.1 3.1L3.64 34.2c-4.7 4.8-4.9 12.3-.3 16.9l19.5 19.5c2.2 2.2 5.2 3.3 8.1 3.3 3.1 0 6.3-1.2 8.7-3.6l31.1-31.1c1.9-1.9 3-4.4 3.1-7.2V8.5c0-2.3-.9-4.4-2.5-6zM57.653 26.716c-1.409 1.479-3.311 2.184-5.213 2.184-1.902 0-3.804-.704-5.213-2.184-2.888-2.818-2.888-7.537 0-10.425 2.818-2.888 7.537-2.888 10.425 0 2.889 2.818 2.889 7.537.001 10.425z"
        }
      ></path>
    </svg>
  );
}

export default TagIconsvgIcon;
/* prettier-ignore-end */
