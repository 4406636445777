import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import * as GQL from 'generated/graphql'
import CacheConfigs from 'util/cacheConfig'
import InventoryHistory from 'plasmic/InventoryHistory'
import { useDebounce } from 'util/hooks'

const Wrapper = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
`

const InventoryAdjustmentsHistory: React.FC = () => {
  const PAGE_SIZE = 16
  const SELECT_OPTIONS_SIZE = 150

  const [searchProduct, setSearchProduct] = useState('')
  const [loadingFetchMoreAdjustments, setLoadingFetchMoreAdjustments] = useState(false)
  const debouncedSearchProduct = useDebounce(searchProduct, 500)

  const {
    data: dataProducts,
    loading: loadingProducts,
    fetchMore: fetchMoreProducts,
  } = GQL.useAllProducts({
    ...CacheConfigs.ACCURATE_ONCE,
    variables: { first: SELECT_OPTIONS_SIZE, search: debouncedSearchProduct, service: false },
  })
  const products = useMemo(() => dataProducts?.availableProducts?.edges.map(edge => edge?.node as GQL.ProductNode) || [], [dataProducts])
  const [selectedProductId, setSelectedProductId] = useState<string | null>(null)

  const {
    data: dataInventories,
    loading: loadingInventories,
    fetchMore: fetchMoreInventories,
  } = GQL.useAllInventories({
    ...CacheConfigs.ACCURATE_ONCE,
    skip: !selectedProductId,
    variables: { first: SELECT_OPTIONS_SIZE, productId: selectedProductId, forDepot: true },
  })
  const inventories = useMemo(() => dataInventories?.allInventories?.edges.map(edge => edge?.node as GQL.InventoryNode) || [], [dataInventories])
  const inventoriesPageInfo = useMemo(() => dataInventories?.allInventories?.pageInfo || { hasNextPage: false, endCursor: '' }, [dataInventories])
  const [selectedInventoryId, setSelectedInventoryId] = useState<string | null>(null)

  const {
    data: dataInventoryAdjustments,
    loading: loadingInventoryAdjustments,
    fetchMore: fetchMoreInventoryAdjustments,
  } = GQL.useAllInventoryAdjustments({
    ...CacheConfigs.ACCURATE_ONCE,
    skip: !selectedInventoryId,
    variables: {
      first: PAGE_SIZE,
      inventory: selectedInventoryId,
    },
  })
  const inventoryAdjustments = useMemo(
    () => dataInventoryAdjustments?.allInventoryAdjustments?.edges.map(edge => edge?.node as GQL.InventoryAdjustmentNode) || [],
    [dataInventoryAdjustments]
  )
  const inventoryAdjustmentsPageInfo = useMemo(
    () => dataInventoryAdjustments?.allInventoryAdjustments?.pageInfo || { hasNextPage: false, endCursor: '' },
    [dataInventoryAdjustments]
  )

  useCallback(() => {
    fetchMoreProducts({
      variables: {
        first: SELECT_OPTIONS_SIZE,
        search: debouncedSearchProduct,
        service: false,
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) return prev
        return {
          ...(fetchMoreResult as GQL.AllProducts),
        }
      },
    })
  }, [fetchMoreProducts, debouncedSearchProduct])

  useCallback(() => {
    if (inventories.length >= (dataInventories?.allInventories?.totalCount || 0) || !selectedProductId) return
    fetchMoreInventories({
      variables: {
        after: inventoriesPageInfo.endCursor,
        first: SELECT_OPTIONS_SIZE,
        product: selectedProductId,
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) return prev
        return {
          ...(fetchMoreResult as GQL.AllInventories),
        }
      },
    })
  }, [fetchMoreInventories, inventories.length, dataInventories?.allInventories?.totalCount, inventoriesPageInfo.endCursor, selectedProductId])

  const loadMoreInventoryAdjustments = useCallback(() => {
    if (
      inventoryAdjustments.length >= (dataInventoryAdjustments?.allInventoryAdjustments?.totalCount || 0) ||
      !selectedInventoryId ||
      loadingFetchMoreAdjustments
    )
      return
    setLoadingFetchMoreAdjustments(true)
    fetchMoreInventoryAdjustments({
      variables: {
        after: inventoryAdjustmentsPageInfo.endCursor,
        first: PAGE_SIZE,
        inventory: selectedInventoryId,
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) return prev
        return {
          ...fetchMoreResult,
          allInventoryAdjustments: {
            ...fetchMoreResult.allInventoryAdjustments,
            edges: [...(prev.allInventoryAdjustments?.edges || []), ...fetchMoreResult?.allInventoryAdjustments?.edges!],
          },
        } as GQL.AllProductInventories
      },
    }).finally(() => setLoadingFetchMoreAdjustments(false))
  }, [
    fetchMoreInventoryAdjustments,
    inventoryAdjustments.length,
    dataInventoryAdjustments?.allInventoryAdjustments?.totalCount,
    inventoryAdjustmentsPageInfo.endCursor,
    selectedInventoryId,
    loadingFetchMoreAdjustments,
  ])

  return (
    <Wrapper>
      <InventoryHistory
        adjustments={inventoryAdjustments}
        adjustmentsTotalCount={dataInventoryAdjustments?.allInventoryAdjustments?.totalCount || 0}
        setSearchProduct={setSearchProduct}
        loadMoreAdjustments={loadMoreInventoryAdjustments}
        selectedProductId={selectedProductId}
        selectedInventoryId={selectedInventoryId}
        setSelectedProductId={setSelectedProductId}
        setSelectedInventoryId={setSelectedInventoryId}
        loadingProducts={loadingProducts}
        loadingInventories={loadingInventories}
        loadingAdjustments={loadingInventoryAdjustments || loadingFetchMoreAdjustments}
        products={products}
        inventories={inventories}
      />
    </Wrapper>
  )
}

export default InventoryAdjustmentsHistory
