import React from 'react'
import styled from 'styled-components'
import Tooltip from 'components/Tooltip/Tooltip'
import C from 'components'
import Text from 'components/Text'

const Wrapper = styled.span`
  display: inline-block;
  margin-left: 0.5rem;
`

function TooltipIcon({ tooltip }) {
  return (
    <Tooltip content={tooltip} arrow={false}>
      <Wrapper>
        <C.Circle size={14} color='gray2'>
          <Text color='primary' fontSize='8px'>
            <i className='fa fa-question'></i>
          </Text>
        </C.Circle>
      </Wrapper>
    </Tooltip>
  )
}

TooltipIcon.defaultProps = {}

export default TooltipIcon
