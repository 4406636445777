import React from 'react'
import styled from 'styled-components'
import Dialog from 'components/Dialog'

interface ILayoutProps {
  borderRadius?: string
}

const Layout = styled.div<ILayoutProps>`
  overflow: hidden;
  background: ${props => props.theme.colors.primaryLight};
  border-radius: ${props => props.borderRadius || '6px'};
`

interface DropdownProps {
  open: boolean
  anchor: React.MutableRefObject<HTMLElement | null>
  position?: 'top' | 'left' | 'bottom' | 'right'
  align?: 'innerStart' | 'outerStart' | 'innerEnd' | 'outerEnd' | 'center'
  showCaret?: boolean
  onClick?: () => void
  onClose?: () => void
  borderRadius?: string
  children?: React.ReactNode
}

const Dropdown: React.FC<DropdownProps> = ({ children, position, align, open, anchor, showCaret, onClick, onClose = () => {}, borderRadius }) => {
  return (
    <Dialog anchor={anchor} open={open} position={position} align={align} showCaret={showCaret} onClose={onClose}>
      <Layout onClick={onClick} borderRadius={borderRadius}>
        {children}
      </Layout>
    </Dialog>
  )
}

export default Dropdown
