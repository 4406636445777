// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ChecksvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ChecksvgIcon(props: ChecksvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 37 27"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fill={"currentColor"}
        d={
          "M32.284.42L14.021 18.683 4.716 9.377a1.156 1.156 0 00-1.634 0L.359 12.1a1.156 1.156 0 000 1.634L13.204 26.58a1.156 1.156 0 001.634 0L36.641 4.777a1.156 1.156 0 000-1.634L33.918.42a1.156 1.156 0 00-1.634 0z"
        }
      ></path>
    </svg>
  );
}

export default ChecksvgIcon;
/* prettier-ignore-end */
