// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type LockedsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function LockedsvgIcon(props: LockedsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 22.543 25.763"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M11.271 20.732c-.78 0-1.409-.629-1.409-1.409v-3.22c0-.78.629-1.409 1.409-1.409s1.409.629 1.409 1.409v3.22c0 .78-.629 1.409-1.409 1.409zm11.272-8.655v11.271a2.416 2.416 0 01-2.415 2.415H2.415A2.415 2.415 0 010 23.348V12.077a2.416 2.416 0 012.415-2.415h1.61V7.246c0-4 3.261-7.256 7.266-7.246s7.226 3.311 7.226 7.316v2.345h1.61a2.416 2.416 0 012.416 2.416zM6.441 9.661h9.661V7.246c0-2.662-2.169-4.831-4.831-4.831s-4.83 2.169-4.83 4.831v2.415zm13.687 2.416H2.415v11.271h17.712V12.077z"
        }
      ></path>
    </svg>
  );
}

export default LockedsvgIcon;
/* prettier-ignore-end */
