// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type IconCallsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function IconCallsvgIcon(props: IconCallsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 14 14"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M.405 1.778c-.507 1.145 1.552 5.625 3.879 7.939 2.313 2.327 6.794 4.386 7.939 3.879 1.233-.546 1.497-1.726 1.444-2.272-.053-.546-2.853-2.219-3.558-2.536-.705-.318-1.515.704-1.902 1.144-.405.423-1.955-.863-2.624-1.515-.652-.669-1.938-2.219-1.515-2.624.44-.387 1.462-1.198 1.145-1.902C4.896 3.186 3.223.386 2.677.333 2.131.28.951.545.405 1.778z"
        }
      ></path>
    </svg>
  );
}

export default IconCallsvgIcon;
/* prettier-ignore-end */
