import React, { FunctionComponent, PropsWithChildren } from 'react'
import { useDrop } from 'react-dnd'
import styled from 'styled-components'

import DateCard, { Props as DateCardProps } from './DateCard'

const ActiveDropDateCard = styled(DateCard)`
  scale: 0.98;
  border-bottom: solid;
  border-width: 4px;
  border-color: #107e74;
`

interface Props extends DateCardProps {
  accept: string[]
  onItemDrop: (item: any, monitor: any) => void
  onClick?: () => void
}

const DroppableDateCard: FunctionComponent<PropsWithChildren<Props>> = props => {
  const [{ isOver, canDrop }, drop] = useDrop<any, any, any>({
    accept: props.accept,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: props.onItemDrop,
  })

  return isOver && canDrop ? (
    <ActiveDropDateCard ref={drop} {...props}>
      {props.children}
    </ActiveDropDateCard>
  ) : (
    <DateCard ref={drop} {...props}>
      {props.children}
    </DateCard>
  )
}

export default DroppableDateCard
