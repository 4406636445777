// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type BottleIconOrdersvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function BottleIconOrdersvgIcon(props: BottleIconOrdersvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 27.363 26.367"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <path
          d={
            "M2.682 10.501C1.203 10.501 0 11.704 0 13.183s1.203 2.682 2.682 2.682 2.682-1.203 2.682-2.682-1.203-2.682-2.682-2.682zm0 10.502C1.203 21.003 0 22.205 0 23.685s1.203 2.682 2.682 2.682 2.682-1.203 2.682-2.682-1.203-2.682-2.682-2.682zM2.682 0C1.203 0 0 1.203 0 2.682s1.203 2.682 2.682 2.682 2.682-1.203 2.682-2.682S4.161 0 2.682 0zM25.66 12.407H10.612a.777.777 0 100 1.554H25.66a.777.777 0 100-1.554z"
          }
        ></path>

        <path
          d={
            "M25.66 14.887H10.612a1.704 1.704 0 010-3.407H25.66c.939 0 1.703.764 1.703 1.703s-.764 1.704-1.703 1.704zm0-12.805H10.612a.777.777 0 100 1.554H25.66a.777.777 0 100-1.554z"
          }
        ></path>

        <path
          d={
            "M25.66 4.562H10.612c-.939 0-1.703-.764-1.703-1.703s.764-1.703 1.703-1.703H25.66c.939 0 1.703.764 1.703 1.703s-.764 1.703-1.703 1.703zm0 18.188H10.612a.777.777 0 100 1.554H25.66a.777.777 0 100-1.554z"
          }
        ></path>

        <path
          d={
            "M25.66 25.23H10.612c-.939 0-1.703-.764-1.703-1.703s.764-1.703 1.703-1.703H25.66c.939 0 1.703.764 1.703 1.703 0 .939-.764 1.703-1.703 1.703z"
          }
        ></path>
      </g>
    </svg>
  );
}

export default BottleIconOrdersvgIcon;
/* prettier-ignore-end */
