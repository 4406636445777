import React from 'react'
import { useIntl } from 'react-intl'
import Modal from 'components/Modal/Modal'
import * as GQL from 'generated/graphql'
import { displayToast } from 'util/toasts'
import ModalNewTrigger from 'plasmic/ModalNewTrigger'

interface EditTriggerModalProps {
  open: boolean
  onClose: () => void
  trigger?: GQL.DistributorRefillThresholdNode
}

const EditTriggerModal: React.FC<EditTriggerModalProps> = ({ trigger, open, onClose }) => {
  const intl = useIntl()
  const t = intl.formatMessage

  const [patchDistributorRefillThreshold] = GQL.usePatchDistributorRefillThreshold({
    refetchQueries: ['AllDistributorThresholds'],
    onCompleted: () => {
      displayToast(t({ id: 'edit-trigger.toasts.success' }), 'success')
      onClose()
    },
    onError: () => {
      displayToast(t({ id: 'edit-trigger.toasts.error' }))
    },
  })

  function handleSubmit(data: GQL.PatchDistributorRefillThresholdInput) {
    patchDistributorRefillThreshold({
      variables: {
        id: trigger?.id!,
        input: {
          ...data,
        },
      },
    })
  }

  return (
    <Modal isOpen={open} onRequestClose={onClose} stripped>
      <ModalNewTrigger title={t({ id: 'settings.edit-trigger' })} trigger={trigger} onClose={onClose} onSubmit={handleSubmit} />
    </Modal>
  )
}

export default EditTriggerModal
