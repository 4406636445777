import TutorialTooltip from 'components/Tooltip/TutorialTooltip'
import React, { useState } from 'react'
import styled from 'styled-components'

const Layout = styled.div`
  width: inherit;
`

const Bar = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 2px solid ${props => props.theme.colors.gray4};
  position: relative;
  box-sizing: border-box;
`

const Tab = styled.div<any>`
  max-width: ${props => 100 / props.tabCount}%;
  width: 120px;
  text-align: center;
  padding: 1rem 0;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  color: ${props => (props.active ? props.theme.colors.lightBlue : 'gray')};
  font-size: 1rem;
  overflow: visible;
  :after {
    display: block;
    content: '';
    opacity: ${props => (props.active ? '1.0' : '0.0')};
    position: absolute;
    bottom: -2px;
    z-index: 1;
    left: 0;
    right: 0;
    height: 5px;
    background-color: ${props => props.theme.colors.lightBlue};
  }
  :hover {
    filter: brightness(${props => (props.active ? '1.0' : '1.2')});
  }
  :hover:after {
    opacity: ${props => (props.active ? '1.0' : '0.3')};
  }
`

function Tabs({
  initialActiveTab,
  tabs,
}: {
  tabs: {
    id: string
    label: string
    render: () => JSX.Element
    tutorialTooltip?: string | JSX.Element
  }[]
  initialActiveTab: any
}) {
  const [activeTab, setActiveTab] = useState(() => initialActiveTab || (tabs && tabs.length > 0 ? tabs[0].id : null))

  const tabToRender = tabs.find(tab => tab.id === activeTab)

  if (!tabToRender) {
    return null
  }

  return (
    <Layout>
      <Bar>
        {tabs.map(tab => (
          <TutorialTooltip content={tab.tutorialTooltip} key={tab.id}>
            <Tab active={activeTab === tab.id} onClick={() => setActiveTab(tab.id)} tabCount={tabs.length}>
              {tab.label}
            </Tab>
          </TutorialTooltip>
        ))}
      </Bar>
      {tabToRender.render()}
    </Layout>
  )
}

Tabs.defaultProps = {}

export default Tabs
