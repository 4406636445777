import * as React from 'react'
import { PlasmicCellTags } from 'plasmic/plasmic/solace_components/PlasmicCellTags'
import Select, { MultiValue, components } from 'react-select'
import { Control, Option, reactSelectTagStyles } from 'plasmic/StyledReactSelectTags'
import PlussvgIcon from 'plasmic/plasmic/solace_components/icons/PlasmicIcon__Plussvg'
import { SetStateAction, useEffect, useState } from 'react'
import './CellTagsCss.css'

interface CustomerTag {
  id: string
  name: string
}

interface OptionType {
  value: string
  label: string
}

function CellTags_(props: any, ref: any) {
  const { tagOptions, customerTags, onTagOptionsCreate, onSelectedCustomerTagsChange } = props
  const [inputString, setInputString] = useState('')
  const [customerTagsAsOptions, setCustomerTagsAsOptions] = useState<OptionType[]>([])

  // Initialize customerTagsAsOptions with the initial customerTags state
  useEffect(() => {
    const initialCustomerTagsAsOptions: OptionType[] = customerTags.map((tag: CustomerTag) => ({
      value: tag.id,
      label: tag.name,
    }))

    // Sort the initial options alphabetically by label
    initialCustomerTagsAsOptions.sort((a, b) => a.label.localeCompare(b.label, 'en', { sensitivity: 'base' }))

    setCustomerTagsAsOptions(initialCustomerTagsAsOptions)
  }, [customerTags])

  function handleSelect(newValue: MultiValue<OptionType>) {
    // Pass the list of ids to parent component
    onSelectedCustomerTagsChange(newValue.map((tag: OptionType) => tag.value))

    // Update the customerTagsAsOptions state
    const updatedCustomerTagsAsOptions: OptionType[] = newValue.map((tag: OptionType) => ({
      value: tag.value,
      label: tag.label,
    }))

    // Sort the updated options alphabetically by label
    updatedCustomerTagsAsOptions.sort((a, b) => a.label.localeCompare(b.label, 'en', { sensitivity: 'base' }))

    setCustomerTagsAsOptions(updatedCustomerTagsAsOptions)
  }

  function handleInput(data: SetStateAction<string>) {
    setInputString(data)
  }

  const MenuList = (props: any) => {
    const { children, newTagLabel, maxHeight, innerRef, focusedOption, innerProps, ...menuListProps } = props

    return (
      <components.MenuList {...menuListProps}>
        {children}
        {newTagLabel.length > 0 && (
          <div
            id='select_btnNewTag'
            style={{
              padding: '16px',
              display: 'flex',
              columnGap: '6px',
              alignItems: 'center',
              backgroundColor: '#181d36',
              color: '#2C97DE',
              cursor: 'pointer',
              borderTop: '1px solid rgba(225,225,225,0.1)',
            }}
            onMouseEnter={e => {
              e.currentTarget.style.backgroundColor = '#1D2945'
            }}
            onMouseLeave={e => {
              e.currentTarget.style.backgroundColor = '#181d36'
            }}
            onClick={() => {
              if (newTagLabel) {
                handleInput('') // Clear input after creating new tag
                const newTag = { value: newTagLabel, label: newTagLabel }
                onTagOptionsCreate([...tagOptions, newTag])
              }
            }}
          >
            <PlussvgIcon color='#2C97DE' /> {newTagLabel}
          </div>
        )}
      </components.MenuList>
    )
  }
  return (
    <PlasmicCellTags
      root={{ ref }}
      {...props}
      content={
        <Select
          value={customerTagsAsOptions}
          isMulti
          name='tags'
          onChange={handleSelect}
          onInputChange={handleInput}
          options={tagOptions}
          placeholder=''
          components={{
            Option,
            Control,
            MenuList: props => <MenuList newTagLabel={inputString} {...props} />,
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
          }}
          noOptionsMessage={() => ''}
          styles={{
            menu: base => ({
              ...base,
              zIndex: 99,
              borderRadius: '6px',
            }),
            multiValue: base => ({
              ...base,
              backgroundColor: 'rgba(0,212,166,0.6)',
              borderRadius: '6px',
            }),
            multiValueLabel: base => ({
              ...base,
              color: 'white',
              textTransform: 'uppercase',
              fontWeight: '500',
              fontSize: '11px',
            }),
            multiValueRemove: base => ({
              ...base,
              backgroundColor: 'transparent',
              borderRadius: '6px',
              '&:hover': {
                backgroundColor: 'rgba(0,212,166,0.6)',
                color: 'white',
              },
            }),
            control: reactSelectTagStyles.control,
            option: reactSelectTagStyles.option,
            menuList: reactSelectTagStyles.menuList,
            singleValue: reactSelectTagStyles.singleValue,
            input: reactSelectTagStyles.input,
            noOptionsMessage: reactSelectTagStyles.noOptionsMessage,
          }}
          className='tags-multi-select'
          classNamePrefix='select'
          isClearable={false}
        />
      }
    />
  )
}
const CellTags = React.forwardRef(CellTags_)
export default CellTags
