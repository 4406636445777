// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type LocksvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function LocksvgIcon(props: LocksvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 28.985 25.764"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M21.754 0c-4-.01-7.261 3.246-7.261 7.246v2.415H2.415A2.417 2.417 0 000 12.077v11.272a2.416 2.416 0 002.415 2.415h17.713a2.416 2.416 0 002.415-2.415V12.077a2.416 2.416 0 00-2.415-2.415h-3.221V7.307c0-2.657 2.118-4.866 4.78-4.891a4.834 4.834 0 014.881 4.831v2.818c0 .669.538 1.208 1.208 1.208.669 0 1.208-.538 1.208-1.208V7.317C28.985 3.311 25.764.01 21.754 0zm-1.626 12.077v11.272H2.415V12.077h17.713zm-8.806 8.655c-.78 0-1.409-.629-1.409-1.409v-3.221c0-.78.629-1.409 1.409-1.409s1.409.629 1.409 1.409v3.221c0 .78-.629 1.409-1.409 1.409z"
        }
      ></path>
    </svg>
  );
}

export default LocksvgIcon;
/* prettier-ignore-end */
