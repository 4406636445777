import React from 'react'
import { useIntl } from 'react-intl'
import Modal from 'components/Modal/Modal'
import * as GQL from 'generated/graphql'
import { displayToast } from 'util/toasts'
import { useAppContext } from 'util/hooks'
import ModalNewTrigger from 'plasmic/ModalNewTrigger'

interface CreateTriggerProps {
  open: boolean
  onClose: () => void
  callback?: () => void
}

const CreateTriggerModal: React.FC<CreateTriggerProps> = ({ open, onClose, callback }) => {
  const intl = useIntl()
  const t = intl.formatMessage

  const { appContext } = useAppContext()
  const distributor = appContext.distributor

  const [createDistributorRefillThreshold] = GQL.useCreateDistributorRefillThreshold({
    refetchQueries: ['AllDistributorThresholds'],
    onCompleted: () => {
      displayToast(t({ id: 'create-trigger.toasts.success' }), 'success')
      if (callback) {
        callback()
      }
      onClose()
    },
    onError: () => {
      displayToast(t({ id: 'create-trigger.toasts.error' }))
    },
  })

  function handleSubmit(data: Omit<GQL.CreateDistributorRefillThresholdInput, 'distributor'>) {
    if (data.unit === GQL.DistributorRefillThresholdUnit.Percent && (data.threshold >= 100 || (data?.alertThreshold && data?.alertThreshold >= 100))) {
      displayToast(t({ id: 'customers.customer-info.triggers.create.percentage-trigger.error' }))
      return
    }
    createDistributorRefillThreshold({
      variables: {
        input: {
          ...data,
          distributor: distributor?.id!,
        },
      },
    })
  }

  return (
    <Modal isOpen={open} onRequestClose={onClose} stripped>
      <ModalNewTrigger title={t({ id: 'settings.create-trigger' })} onClose={onClose} onSubmit={handleSubmit} />
    </Modal>
  )
}

export default CreateTriggerModal
