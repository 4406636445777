import React from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'

const Layout = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 0 12px;

  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;

  color: white;
  background-color: #1c213b;
  border-radius: 6px;
  height: 52px;
  opacity: 0.5;
  &:hover {
    opacity: 0.8;
  }

  user-select: none;
`

interface DriverDatesPanelDateTileProps {
  date: Date
}

const DriverDatesPanelDateTile: React.FC<DriverDatesPanelDateTileProps> = ({ date }) => {
  return <Layout>{format(date, 'EEE d.')}</Layout>
}

export default DriverDatesPanelDateTile
