import styled from 'styled-components'

const InlineTip = styled.span`
  display: inline-block;
  padding: 0px 10px;
  border-radius: 20px;
  background-color: hsla(0, 0%, 100%, 0.05);
  color: hsla(0, 0%, 100%, 0.5);
  font-size: 11px;
  line-height: 26px;
  font-weight: 400;
`

export default InlineTip
