import React from 'react'
import styled from 'styled-components'

interface ILayoutProps {
  border?: boolean
  height?: string
  width?: string
  hasIcon: boolean
}

const Layout = styled.div<ILayoutProps>`
  width: ${props => props.width || '300px'};
  display: grid;

  grid-template-columns: ${props => (props.hasIcon ? '33px 1fr' : '1fr')};
  padding-left: 1rem;
  padding-right: 1rem;
  box-sizing: border-box;
  height: ${props => props.height || '50px'};
  align-items: center;

  border-bottom: 1px solid ${props => (props.border ? props.theme.colors.gray1 : 'transparent')};

  cursor: pointer;

  :hover {
    color: ${props => props.theme.colors.gray8};
  }
`

const IconWrapper = styled.div``

const TextWrapper = styled.div`
  font-size: 1rem;
`

interface DropdownItemProps {
  icon?: React.ReactNode
  border?: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => any
  height?: string
  width?: string
  children?: React.ReactNode
}

const DropdownItem: React.FC<DropdownItemProps> = ({ icon, children, border, onClick, height, width }) => {
  return (
    <Layout hasIcon={!!icon} border={border} onClick={onClick} height={height} width={width}>
      {!!icon && <IconWrapper>{icon}</IconWrapper>}
      <TextWrapper>{children}</TextWrapper>
    </Layout>
  )
}

export default DropdownItem
