import nb from './translations/nb.json'
import en from './translations/en.json'
import sv from './translations/sv.json'

interface IObjectKeys {
  [key: string]: any
}

const messages: IObjectKeys = {
  nb,
  en,
  sv,
  'nb-NO': en,
  'nn-NO': en,
  'en-GB': en,
  'en-US': en,
  'sv-SE': sv,
}

export default messages
