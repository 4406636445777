// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import * as p from '@plasmicapp/react-web'
import { DefaultCustomerInfoTabProps, PlasmicCustomerInfoTab } from './plasmic/solace_components/PlasmicCustomerInfoTab'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import SidebarAccordion from './SidebarAccordion' // plasmic-import: giLJ3HDzLh/component
import ButtonRoundIcon from './ButtonRoundIcon'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface CustomerInfoTabProps extends Omit<DefaultCustomerInfoTabProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultCustomerInfoTabProps altogether and have
// total control over the props for your component.
export interface CustomerInfoTabProps extends DefaultCustomerInfoTabProps {
  customerInfo?: any
  tabSelectorOrderMethod?: any
  selectAutomaticTrigger?: any
  openingHoursComponent?: any
  customerInfoInfo?: any
  customerInfoOrderMethod?: any
  customerInfoAutoTriggers?: any
  customerInfoAvailable?: any
  paymentOptions?: any
  atCustomerLocation?: p.Flex<typeof SidebarAccordion>
  btnAddProduct?: p.Flex<typeof ButtonRoundIcon>
}

function CustomerInfoTab_(props: CustomerInfoTabProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicCustomerInfoTab to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicCustomerInfoTab are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all CustomerInfoTabProps here, but feel free
  // to do whatever works for you.

  return <PlasmicCustomerInfoTab root={{ ref }} {...props} />
}

const CustomerInfoTab = React.forwardRef(CustomerInfoTab_)
export default CustomerInfoTab
