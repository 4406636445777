import * as GQL from 'generated/graphql'
import { displayToast } from 'util/toasts'

export function useUpdateCustomerField() {
  const [mutate, ...rest] = GQL.usePatchCustomer({
    onCompleted: () => {
      displayToast('Success', 'success', { toastId: null, autoClose: 300 })
    },
    onError: error => {
      console.log(error)
      displayToast('Error occured, please try again', 'error')
    },
  })

  function handleUpdate(id: string, field: string, value: string | string[], customer: object = {}) {
    mutate({
      variables: {
        id,
        input: {
          [field]: value,
        },
      },
    })
  }

  return {
    handleUpdate,
    ...rest,
  }
}
