import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import * as GQL from 'generated/graphql'
import CustomerModalDraft from 'plasmic/CustomerModalDraft'
import Loader from 'components/Loader'
import { CenteredLoader } from 'components/Loader/LoaderWrappers'
import CylindersTab from './CylindersTab'
import CustomerInfo from './CustomerInfo'
import Contacts from './Contacts'
import Sensors from './Sensors'
import Chart from './Chart'
import ChartInfo from './ChartInfo'
import { useClickOutside, useCustomerContext } from 'util/hooks'
import { TutorialInfoIcon } from 'components/Tooltip/TutorialTooltip'
import ChartEmptyState from 'plasmic/ChartEmptyState'
import ChartSensorTab from 'plasmic/ChartSensorTab'
import ActionDropdownLine from 'plasmic/ActionDropdownLine'
import DeleteCustomerConfirmationModal from '../components/DeleteCustomerConfirmationModal'
import Orders from './Orders'
import { VerifyExchangeModal, VerifyExchangeStock } from 'modules/inventory'
import Notes from './Notes'
import { CustomerDrawerTab } from 'context/CustomerContext'
import { handlePriorityCylinderSideSorting } from 'modules/hardware/util'

export const LOCAL_STORAGE_TAB_KEY = 'customer-modal-tab'

// The values are switched because the customer app has been using `General notes`
// instead of `Driver notes`, thus causing all notes to be switched.

interface Props {
  customerId: string
  onClose?: (e: MouseEvent) => any
  noHead?: boolean
}

const CustomersDrawerInner: React.FC<Props> = props => {
  const intl = useIntl()
  const t = intl.formatMessage

  const [moreActionsHeaderOpen, setMoreActionsHeaderOpen] = useState(false)
  const [deleteCustomerModalOpen, setDeleteCustomerModalOpen] = useState(false)
  const [chartSensors, setChartSensors] = useState<{ serial: string; activeCycle: GQL.CycleNode }[]>([])
  const [selectedChartSensor, setSelectedChartSensor] = useState<{ serial: string; activeCycle: GQL.CycleNode }>()
  const [verifyExchangeInfo, setVerifyExchangeInfo] = useState<VerifyExchangeStock>()

  const moreActionsHeaderRef = useRef<HTMLElement>(null)
  const { tab, setTab } = useCustomerContext()

  useClickOutside(moreActionsHeaderRef, () => setMoreActionsHeaderOpen(false))

  const { data: cylinderGroups, loading: loadingCylinderGroups } = GQL.useAllCylinderGroups({
    variables: {
      customer: props.customerId,
    },
  })

  const cylinderGroup = useMemo(() => {
    return cylinderGroups?.allCylinderGroups?.edges?.map(e => e?.node).find(cg => true) as GQL.CylinderGroupNode | undefined
  }, [cylinderGroups])

  const customer = cylinderGroup?.customer

  useEffect(() => {
    if (!cylinderGroup || !cylinderGroup.cylinderSides) {
      return
    }

    const chartSensors: { serial: string; activeCycle: GQL.CycleNode }[] = []
    const cylinderSides = handlePriorityCylinderSideSorting(
      cylinderGroup.cylinderSides.filter(
        cylinderSide => cylinderSide && cylinderSide.sensors && cylinderSide.activeCycle?.lastCalculation
      ) as GQL.CylinderGroupSideNode[]
    )
    for (const cylinderSide of cylinderSides) {
      for (const sensor of cylinderSide.sensors!) {
        if (!sensor) {
          continue
        }
        chartSensors.push({ serial: sensor.serialNumber, activeCycle: cylinderSide.activeCycle! })
      }
    }

    setChartSensors(chartSensors)
    setSelectedChartSensor(chartSensors[0])
  }, [cylinderGroup])

  return (
    <>
      {loadingCylinderGroups ? (
        <CenteredLoader style={{ height: '100%' }} sizeAuto>
          <Loader color='gray' size={50} />
        </CenteredLoader>
      ) : (
        cylinderGroup &&
        customer && (
          <CustomerModalDraft
            id={customer.customerIdentifier && '#' + customer.customerIdentifier}
            customer={customer?.name}
            noHead={props.noHead}
            tab={tab}
            tabChart={{ onClick: () => setTab(CustomerDrawerTab.CHART) }}
            tabCylinders={{ onClick: () => setTab(CustomerDrawerTab.CYLINDERS) }}
            tabOrders={{ onClick: () => setTab(CustomerDrawerTab.ORDERS) }}
            tabNotes={{ onClick: () => setTab(CustomerDrawerTab.NOTES) }}
            tabCustomerInfo={{ onClick: () => setTab(CustomerDrawerTab.CUSTOMER_INFO) }}
            tabContacts={{ onClick: () => setTab(CustomerDrawerTab.CONTACTS) }}
            tabSensors={{ onClick: () => setTab(CustomerDrawerTab.SENSORS) }}
            contentChart={
              tab === CustomerDrawerTab.CHART &&
              (chartSensors.length > 0 ? undefined : (
                <ChartEmptyState title={t({ id: 'customers.chart.connect-sensor-title' })} subtitle={t({ id: 'customers.chart.connect-sensor-subtitle' })} />
              ))
            }
            chart={
              tab === CustomerDrawerTab.CHART && {
                props: {
                  helpText: <TutorialInfoIcon content={t({ id: 'tooltips.customers.drawer.tabs.chart' })} />,
                },
              }
            }
            chartToolbar={
              tab === CustomerDrawerTab.CHART && {
                sensorTabs: chartSensors.map(sensor => (
                  <ChartSensorTab
                    serial={sensor.serial}
                    active={sensor.serial === selectedChartSensor?.serial}
                    onClick={() => setSelectedChartSensor(sensor)}
                  />
                )),
              }
            }
            chartComponent={
              tab === CustomerDrawerTab.CHART && {
                render: () =>
                  selectedChartSensor ? (
                    <Chart
                      sensorSerialNumber={selectedChartSensor.serial}
                      calculationId={selectedChartSensor.activeCycle.lastCalculation}
                      activeCycle={selectedChartSensor.activeCycle}
                      cylinderGroup={cylinderGroup}
                    />
                  ) : (
                    <ChartEmptyState title={t({ id: 'customers.chart.select-sensor-title' })} subtitle={t({ id: 'customers.chart.select-sensor-subtitle' })} />
                  ),
              }
            }
            chartInfo={
              tab === CustomerDrawerTab.CHART && {
                render: () => (selectedChartSensor ? <ChartInfo activeCycle={selectedChartSensor.activeCycle} cylinderGroup={cylinderGroup} /> : undefined),
              }
            }
            contentCylinders={tab === CustomerDrawerTab.CYLINDERS && <CylindersTab cylinderGroup={cylinderGroup} />}
            contentCustomerInfo={tab === CustomerDrawerTab.CUSTOMER_INFO && <CustomerInfo cylinderGroup={cylinderGroup} key={cylinderGroup.id} />}
            contentContacts={tab === CustomerDrawerTab.CONTACTS && <Contacts cylinderGroup={cylinderGroup} key={cylinderGroup.id} />}
            contentSensors={tab === CustomerDrawerTab.SENSORS && <Sensors cylinderGroup={cylinderGroup} key={cylinderGroup.id} />}
            contentOrders={tab === CustomerDrawerTab.ORDERS && <Orders cylinderGroup={cylinderGroup} setVerifyExchangeInfo={setVerifyExchangeInfo} />}
            contentNotes={tab === CustomerDrawerTab.NOTES && <Notes cylinderGroup={cylinderGroup} key={cylinderGroup.id} />}
            btnMoreActionsHeader={{
              ref: moreActionsHeaderRef,
              btnMoreActions: {
                onClick: () => setMoreActionsHeaderOpen(prev => !prev),
              },
              open: moreActionsHeaderOpen,
              actionDropdown: <ActionDropdownLine children={t({ id: 'customers.delete-modal.title' })} onClick={() => setDeleteCustomerModalOpen(true)} />,
            }}
            close={{ onClick: props.onClose }}
          />
        )
      )}
      {customer && (
        <DeleteCustomerConfirmationModal
          isOpen={deleteCustomerModalOpen}
          onRequestClose={() => setDeleteCustomerModalOpen(false)}
          onClose={() => setDeleteCustomerModalOpen(false)}
          customer={customer}
        />
      )}
      {!!verifyExchangeInfo && (
        <VerifyExchangeModal isOpen={!!verifyExchangeInfo} onClose={() => setVerifyExchangeInfo(undefined)} verifyExchangeInfo={verifyExchangeInfo} />
      )}
    </>
  )
}

export default CustomersDrawerInner
