// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type CopyToAllsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function CopyToAllsvgIcon(props: CopyToAllsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 25 25"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <path
          d={
            "M21.453 4.485H7.566a3.084 3.084 0 00-3.08 3.08v13.888a3.084 3.084 0 003.08 3.08h6.944a.911.911 0 000-1.822H7.566a1.26 1.26 0 01-1.258-1.258V7.565a1.26 1.26 0 011.258-1.258h13.888a1.26 1.26 0 011.258 1.258v6.944a.911.911 0 001.822 0V7.565a3.084 3.084 0 00-3.081-3.08z"
          }
        ></path>

        <path
          d={
            "M18.346 1.378a.911.911 0 00-.911-.911H3.547a3.084 3.084 0 00-3.08 3.08v13.888a.911.911 0 001.822 0V3.546a1.26 1.26 0 011.258-1.258h13.888a.91.91 0 00.911-.91zm5.276 16.777a.911.911 0 00-.911.911v2.356l-7.557-7.557a.911.911 0 10-1.289 1.289l7.557 7.557h-2.356a.911.911 0 000 1.822h4.556c.03 0 .06-.002.09-.005.014-.001.027-.004.04-.006l.048-.007.046-.011a.611.611 0 00.085-.025c.013-.005.027-.009.04-.015l.041-.019.04-.019.039-.023.038-.023.043-.032a.91.91 0 00.157-.149l.022-.029.031-.042.023-.038c.008-.013.016-.025.023-.039l.019-.04.019-.041.015-.04c.005-.015.011-.029.015-.044l.01-.041a.619.619 0 00.011-.046l.007-.047c.002-.014.005-.027.006-.041a.905.905 0 00.005-.09v-4.556a.913.913 0 00-.913-.91z"
          }
        ></path>
      </g>
    </svg>
  );
}

export default CopyToAllsvgIcon;
/* prettier-ignore-end */
