import React from 'react'
import { useIntl } from 'react-intl'
import ModalConfirm from 'plasmic/ModalConfirm'

import * as GQL from 'generated/graphql'
import { displayToast } from 'util/toasts'
import Modal from 'components/Modal/Modal'

interface Props {
  isOpen: boolean
  onRequestClose: () => void
  onClose: (event: MouseEvent) => void
  customer: GQL.CustomerNode
}

const DeleteCustomerConfirmationModal: React.FC<Props> = props => {
  const intl = useIntl()
  const t = intl.formatMessage

  const [deleteCustomer] = GQL.useDeleteCustomer({
    // TODO: update cache
    refetchQueries: ['AllSensorCustomers', 'AllNoSensorCustomers'],
    onCompleted: () => {
      displayToast(t({ id: 'customers.toast.delete.success' }), 'success')
    },
    onError: () => {
      displayToast(t({ id: 'customers.toast.delete.error' }), 'error')
    },
  })

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.onRequestClose} stripped>
      <ModalConfirm
        btnClose={{ onClick: props.onRequestClose }}
        btnCancel={{ onClick: props.onRequestClose }}
        title={t({ id: 'customers.delete-modal.title' })}
        description={t({ id: 'customers.delete-modal.body' }, { customer: props.customer.name })}
        btnColor='red'
        btnConfirm={{
          onClick: (event: MouseEvent) => {
            deleteCustomer({
              variables: {
                id: props.customer.id,
              },
            })
            props.onRequestClose()
            props.onClose(event)
          },
        }}
      />
    </Modal>
  )
}

export default DeleteCustomerConfirmationModal
