// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ToastWarningsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ToastWarningsvgIcon(props: ToastWarningsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 32.727 32.727"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M16.697 0C7.66 0 .333 7.326.333 16.364c0 9.037 7.326 16.364 16.364 16.364 9.037 0 16.364-7.326 16.364-16.364S25.734 0 16.697 0zm0 25.036a2.168 2.168 0 110-4.337 2.168 2.168 0 010 4.337zM18.6 10.762c-.5 2.828-.898 5.683-1.294 8.721-.08.319-1.163.346-1.217 0a190.283 190.283 0 00-1.322-8.721c-.159-1.006-.238-1.482-.238-1.933 0-.634.448-1.138 2.168-1.138h.026c1.639 0 2.142.503 2.142 1.138 0 .451-.106.927-.265 1.933z"
        }
      ></path>
    </svg>
  );
}

export default ToastWarningsvgIcon;
/* prettier-ignore-end */
