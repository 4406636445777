// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ModeMoonLightsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ModeMoonLightsvgIcon(props: ModeMoonLightsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 453 467"}
      xmlSpace={"preserve"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M441.484 367.077c-46.199 62.766-119.46 99.633-197.63 99.633-3.734 0-7.465-.234-11.199-.469-23.332-.934-45.965-5.367-67.434-12.832-89.598-31.031-157.27-113.4-164.27-209.77-7.004-102.43 52.031-198.1 147.46-238.23 24.734-10.266 37.332-2.801 42.699 2.566 5.133 5.133 12.367 17.5 2.098 41.066-10.5 24.266-15.633 49.934-15.633 76.531.234 47.598 18.898 91.699 49.234 125.54 34.301 38.5 83.535 63.465 137.9 65.801 14.699.699 29.398-.469 43.867-3.035 26.133-4.668 36.633 5.133 40.367 11.434 3.965 6.301 8.164 20.066-7.469 41.766l.01-.001z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ModeMoonLightsvgIcon;
/* prettier-ignore-end */
