// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ScAppssvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ScAppssvgIcon(props: ScAppssvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 27 27"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M12.842 11.431L4.324 7.42c-1.183-.557-1.183-2.24 0-2.797L12.842.612c.417-.196.9-.196 1.317 0l8.517 4.011c1.183.557 1.183 2.24 0 2.797l-8.517 4.011c-.418.196-.9.196-1.317 0zM2.506 8.505l9.296 4.378c.542.255.887.8.887 1.398v10.707a1.545 1.545 0 01-2.204 1.398l-9.296-4.378a1.545 1.545 0 01-.887-1.398V9.903a1.545 1.545 0 012.204-1.398zm23.305 13.503l-9.296 4.378a1.545 1.545 0 01-2.204-1.398V14.281c0-.599.346-1.143.887-1.398l9.296-4.378a1.545 1.545 0 012.204 1.398V20.61c0 .599-.345 1.143-.887 1.398z"
        }
      ></path>
    </svg>
  );
}

export default ScAppssvgIcon;
/* prettier-ignore-end */
