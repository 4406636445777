/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import * as GQL from 'generated/graphql'
import Modal from 'components/Modal/Modal'

const Layout = styled.div`
  max-height: 650px;
  overflow-y: auto;
`

const UsersList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 1rem;
`

const ListItem = styled.li`
  width: 250px;
  padding: 0.75rem;

  :hover {
    cursor: pointer;
    color: ${props => props.theme.colors.gray8};
  }
`

const ListItem__Clear = styled(ListItem)`
  border: 1px solid ${props => props.theme.colors.gray1};
  background-color: ${props => props.theme.colors.gray1};
  border-radius: 10px;
  color: white;
  text-align: center;
`

interface Props {
  open: boolean
  onClose: () => void
  onSelect: (user: GQL.UserNode) => void
}

const UserSelectModal: React.FC<Props> = props => {
  const t = useIntl().formatMessage

  const users = GQL.useUsers().data?.allUsers?.edges?.map(edge => edge?.node as GQL.UserNode)

  const clearSelectedIdentity = () => {
    localStorage.removeItem('selected-identity')
    window.location.reload()
  }

  return (
    <Modal isOpen={props.open} onRequestClose={props.onClose} title={t({ id: 'user-select-modal.title' })} contentStyle={{ backgroundColor: '#242C48' }}>
      <Layout>
        <UsersList>
          {localStorage.getItem('selected-identity') && (
            <ListItem__Clear onClick={clearSelectedIdentity}>{t({ id: 'user-select-modal.clear-selected-user' })}</ListItem__Clear>
          )}
          {users?.map(user => (
            <ListItem
              key={user.id!}
              onClick={() => {
                props.onSelect(user)
              }}
            >
              {user.fullName}
            </ListItem>
          ))}
        </UsersList>
      </Layout>
    </Modal>
  )
}

export default UserSelectModal
