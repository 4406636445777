import React from 'react'
import styled from 'styled-components'
import { overloadColorProp } from 'util/style'
import Lottie from 'lottie-react'
import animationData from './sc-loader.json'

type ILoaderProps = {
  className?: string
  size?: number
  thickness?: number
  color?: string
  inline?: boolean
  margin?: string
}

const InnerLoader: React.FC<ILoaderProps> = ({ className, size }) => {
  return (
    <div className={className}>
      <Lottie animationData={animationData} style={{ height: size?.toString(), width: size?.toString() }} />
    </div>
  )
}

/**
 * Loader which is plain without centering
 * @param {size} number is a number in pixels of height and width of the Loader (square), default 32.
 * @param {color} string color of the loader, can me name from theme or simple color code or name, default gray6
 * @param {inline} boolean  if true, loader will be inline-block, default false
 */
const Loader = styled(InnerLoader)<ILoaderProps>`
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  height: ${props => props.size || 32}px;
  width: ${props => props.size || 32}px;
  fill: ${props => overloadColorProp(props)};
  margin: ${props => props.margin};
`

export default Loader
