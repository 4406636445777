// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ChevronFillsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ChevronFillsvgIcon(props: ChevronFillsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 18.726 10.701"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M18.331 2.289l-8.02 8.02a1.34 1.34 0 01-1.896 0l-8.02-8.02C-.449 1.444.149 0 1.343 0h16.039c1.195 0 1.793 1.444.949 2.289z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ChevronFillsvgIcon;
/* prettier-ignore-end */
