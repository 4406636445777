import { AuditLogDetail, AuditLogDomain } from '../../types'
import Parser from '../Parser'
import { JSONChanges } from '../types'
import * as GQL from 'generated/graphql'

class GenericUpdateParser extends Parser {
  public details(entry: GQL.AuditLogEntryNode): AuditLogDetail[] {
    if (!entry.changes) {
      return [[this.createDetailItem('Failed to parse details.')]]
    }
    const json: JSONChanges = JSON.parse(entry.changes)
    return this.handleUpdateDetails(json)
  }

  public domains(entry: GQL.AuditLogEntryNode): AuditLogDomain[] {
    if (!entry.objectId) return []
    return [this.createDomainItem('-', entry.objectRepr, entry.contentType?.model)]
  }

  private handleUpdateDetails(json: JSONChanges) {
    return Object.entries(json).map(([key, value]) => [
      this.createDetailItem(`${key.capitalizeFirst()} from`),
      this.createDetailItem(value[0], 'red'),
      this.createDetailItem('to'),
      this.createDetailItem(value[1], 'green'),
    ])
  }
}

export { GenericUpdateParser }
