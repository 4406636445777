import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import _ from 'lodash'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'

import * as GQL from 'generated/graphql'
import {
  CylinderGroupSorting,
  extractDetailsForSimplifiedCalculation,
  getCylinderGroupSortingIcon,
  handleCylinderGroupSorting,
  updateCylinderGroupCacheOnCreate,
  updateCylinderGroupCacheOnPatch,
} from './util'
import { displayToast } from 'util/toasts'
import SensorCalculationServiceContext from 'context/SensorCalculationServiceContext'
import { SimplifiedCalculation } from 'services/sensorCalculation'
import { useAppContext, useCustomerContext, useDebounce } from 'util/hooks'
import { createSortStringFromMapping } from 'util/sort'
import FlexTableWrapper from 'plasmic/FlexTableWrapper'
import CustomersHeader from './components/CustomersHeader'
import CustomerOrderMethodMenu from './components/CustomerOrderMethodMenu'
import CustomersTable from './CustomersTable'
import CacheConfigs from 'util/cacheConfig'
import CornerLoader from 'components/Loader/CornerLoader'
import { CustomerDrawerListSource } from 'context/CustomerContext'
import { CustomerTableColumn } from 'context/TableContext'
import MassCommunicationModal from './components/MassCommunicationModal'

export enum Status {
  EmptyCylinders,
  OrderedCylinders,
  AwaitingDelivery,
}

const PAGE_SIZE_SENSOR_CUSTOMERS = 16
const PAGE_SIZE_NO_SENSOR_CUSTOMERS = 10

const CustomersOverview = () => {
  const intl = useIntl()
  const t = intl.formatMessage

  const contentWrapperTop = document.getElementById('content-wrapper-top')

  const calculationService = useContext(SensorCalculationServiceContext)

  const navigate = useNavigate()
  const location = useLocation()

  const { appContext } = useAppContext()
  const { setSource, setCustomersContext } = useCustomerContext()

  const [sensorCustomersSort, setSensorCustomersSort] = useState<CylinderGroupSorting>({ estimatedEmpty: 1 })
  const [noSensorCustomersSort, setNoSensorCustomersSort] = useState<CylinderGroupSorting>({ name: 1 })

  const [sensorCustomersMoreLoading, setSensorCustomersMoreLoading] = useState(false)
  const [noSensorCustomersMoreLoading, setNoSensorCustomersMoreLoading] = useState(false)

  const [search, setSearch] = useState('')
  const [customerType, setCustomerType] = useState<GQL.CustomerDomainType[]>([])
  const [gasType, setGasType] = useState<GQL.GasTypeEnum[]>([])
  const [setupStyle, setSetupStyle] = useState<GQL.CylinderGroupMode[]>([])
  const [status, setStatus] = useState<Status[]>([])
  const [selectedFilterTags, setselectedFilterTags] = useState<string[]>([]) // selected filter Tags

  const [orderMethod, setOrderMethod] = useState<GQL.CustomerOrderMethod | null>(null)
  const [sensorsCalculations, setSensorsCalculations] = useState<{ [key: string]: SimplifiedCalculation }>({})

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [callback, setCallback] = useState<((method: GQL.CustomerOrderMethod) => void) | null>(null)

  const sensorCustomersSortString = createSortStringFromMapping(sensorCustomersSort)
  const noSensorCustomersSortString = createSortStringFromMapping(noSensorCustomersSort)

  const [isMessagingModalOpen, setIsMessagingModalOpen] = useState(false)
  const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set())

  const debouncedSearch = useDebounce(search, 500)
  const client = useApolloClient()

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])

  const queryVariables = useMemo(() => {
    return {
      search: debouncedSearch,
      customerType: customerType,
      gasType: gasType.map(type => type.toLowerCase()),
      cylinderMode: setupStyle,
      emptyCylinders: status.includes(Status.EmptyCylinders) || undefined,
      orderedCylinders: status.includes(Status.OrderedCylinders) || undefined,
      awaitingDelivery: status.includes(Status.AwaitingDelivery) || undefined,
      tags: selectedFilterTags,
    }
  }, [customerType, debouncedSearch, gasType, selectedFilterTags, setupStyle, status])

  const {
    loading: sensorCustomersDataLoading,
    data: sensorCustomersData,
    error: sensorCustomersDataError,
    fetchMore: fetchMoreSensorCustomersData,
  } = GQL.useAllSensorCustomersCancellable(
    {
      ...queryVariables,
      after: null,
      withSensors: true,
      orderBy: sensorCustomersSortString,
      first: PAGE_SIZE_SENSOR_CUSTOMERS,
    },
    [appContext.depot],
    CacheConfigs.ACCURATE_FREQUENT.fetchPolicy,
    CacheConfigs.ACCURATE_FREQUENT.nextFetchPolicy,
    CacheConfigs.ACCURATE_FREQUENT.pollInterval
  )

  const {
    loading: noSensorCustomersDataLoading,
    data: noSensorCustomersData,
    error: noSensorCustomersDataError,
    fetchMore: fetchMoreNoSensorCustomersData,
  } = GQL.useAllNoSensorCustomersCancellable(
    { ...queryVariables, first: PAGE_SIZE_NO_SENSOR_CUSTOMERS, after: null, withSensors: false, orderBy: noSensorCustomersSortString },
    [appContext.depot],
    CacheConfigs.ACCURATE_FREQUENT.fetchPolicy,
    CacheConfigs.ACCURATE_FREQUENT.nextFetchPolicy,
    CacheConfigs.ACCURATE_FREQUENT.pollInterval
  )

  const [patchCustomer] = GQL.usePatchCustomerLimited({
    onError: error => {
      if (error.message.includes('Customer with that name exists.')) {
        displayToast(t({ id: 'customers.patch.error.duplicate.name' }), 'error')
      } else {
        displayToast(t({ id: 'customers.update.error' }), 'error')
      }
    },
    update(_, { data }) {
      if (!data?.patchCustomerLimited?.customer || !data?.patchCustomerLimited?.customer.cylinderGroups) {
        displayToast(t({ id: 'customers.update.error' }), 'error')
        return
      }
      for (const cylinderGroup of data.patchCustomerLimited.customer.cylinderGroups) {
        updateCylinderGroupCacheOnPatch(
          cylinderGroup as GQL.CylinderGroupNode,
          client,
          {
            ...queryVariables,
            after: null,
            withSensors: true,
            orderBy: sensorCustomersSortString,
            first: PAGE_SIZE_SENSOR_CUSTOMERS,
          },
          {
            ...queryVariables,
            after: null,
            withSensors: false,
            orderBy: noSensorCustomersSortString,
            first: PAGE_SIZE_NO_SENSOR_CUSTOMERS,
          }
        )
      }
    },
  })

  const sensorCustomersPageInfo = useMemo(() => {
    return sensorCustomersData?.allSensorCylinderGroups?.pageInfo || { hasNextPage: false, endCursor: '' }
  }, [sensorCustomersData])
  const sensorCustomersFiltersInfo = useMemo(() => {
    return sensorCustomersData?.allSensorCylinderGroups?.filtersInfo as GQL.CylinderGroupFiltersInfo | undefined
  }, [sensorCustomersData])
  const sensorCustomersCylinderGroups = useMemo(() => {
    return sensorCustomersData?.allSensorCylinderGroups?.edges.map(edge => edge?.node as GQL.CylinderGroupNode) || []
  }, [sensorCustomersData])
  const noSensorCustomersPageInfo = useMemo(() => {
    return noSensorCustomersData?.allNoSensorCylinderGroups?.pageInfo || { hasNextPage: false, endCursor: '' }
  }, [noSensorCustomersData])
  const noSensorCustomersFiltersInfo = useMemo(() => {
    return noSensorCustomersData?.allNoSensorCylinderGroups?.filtersInfo as GQL.CylinderGroupFiltersInfo | undefined
  }, [noSensorCustomersData])
  const noSensorCustomersCylinderGroups = useMemo(() => {
    return noSensorCustomersData?.allNoSensorCylinderGroups?.edges.map(edge => edge?.node as GQL.CylinderGroupNode) || []
  }, [noSensorCustomersData])

  const filtersInfo: GQL.CylinderGroupFiltersInfo = useMemo(() => {
    return {
      businessCustomersCount: _.sum([sensorCustomersFiltersInfo?.businessCustomersCount, noSensorCustomersFiltersInfo?.businessCustomersCount]),
      residentCustomersCount: _.sum([sensorCustomersFiltersInfo?.residentCustomersCount, noSensorCustomersFiltersInfo?.residentCustomersCount]),
      propaneCount: _.sum([sensorCustomersFiltersInfo?.propaneCount, noSensorCustomersFiltersInfo?.propaneCount]),
      co2Count: _.sum([sensorCustomersFiltersInfo?.co2Count, noSensorCustomersFiltersInfo?.co2Count]),
      standardSetupCount: _.sum([sensorCustomersFiltersInfo?.standardSetupCount, noSensorCustomersFiltersInfo?.standardSetupCount]),
      parallelSetupCount: _.sum([sensorCustomersFiltersInfo?.parallelSetupCount, noSensorCustomersFiltersInfo?.parallelSetupCount]),
      insituSetupCount: _.sum([sensorCustomersFiltersInfo?.insituSetupCount, noSensorCustomersFiltersInfo?.insituSetupCount]),
      emptyCylindersCount: _.sum([sensorCustomersFiltersInfo?.emptyCylindersCount, noSensorCustomersFiltersInfo?.emptyCylindersCount]),
      orderedCylindersCount: _.sum([sensorCustomersFiltersInfo?.orderedCylindersCount, noSensorCustomersFiltersInfo?.orderedCylindersCount]),
      awaitingDeliveryCount: _.sum([sensorCustomersFiltersInfo?.awaitingDeliveryCount, noSensorCustomersFiltersInfo?.awaitingDeliveryCount]),
    }
  }, [sensorCustomersFiltersInfo, noSensorCustomersFiltersInfo])

  const sensorCustomers = useMemo(
    () => sensorCustomersData?.allSensorCylinderGroups?.edges?.map(edge => edge?.node?.customer as GQL.CustomerNode) || [],
    [sensorCustomersData]
  )
  const noSensorCustomers = useMemo(
    () => noSensorCustomersData?.allNoSensorCylinderGroups?.edges?.map(edge => edge?.node?.customer as GQL.CustomerNode) || [],
    [noSensorCustomersData]
  )

  useEffect(() => {
    const fetchSimplifiedCalculations = async () => {
      const sensorsCalculations: { [key: string]: SimplifiedCalculation } = {}

      // Fetch simplified calculations only for sensor customers because other customers don't have chart column
      for (const cylinderGroup of sensorCustomersCylinderGroups) {
        const detailsForSimplifiedCalculation = extractDetailsForSimplifiedCalculation(cylinderGroup)
        if (!detailsForSimplifiedCalculation.sensorSerialNumber || !detailsForSimplifiedCalculation.calculationId) {
          continue
        }

        try {
          sensorsCalculations[detailsForSimplifiedCalculation.sensorSerialNumber] = await calculationService.fetchSimplified(
            detailsForSimplifiedCalculation.sensorSerialNumber,
            detailsForSimplifiedCalculation.calculationId
          )
        } catch {}
      }

      setSensorsCalculations(sensorsCalculations)
    }

    fetchSimplifiedCalculations()
  }, [calculationService, sensorCustomersCylinderGroups])

  const handleLoadMoreSensorCustomers = useCallback(() => {
    if (sensorCustomersCylinderGroups.length >= (sensorCustomersData?.allSensorCylinderGroups?.totalCount || 0) || sensorCustomersMoreLoading) return

    setSensorCustomersMoreLoading(true)

    fetchMoreSensorCustomersData({
      variables: {
        first: PAGE_SIZE_SENSOR_CUSTOMERS,
        after: sensorCustomersPageInfo.endCursor,
        withSensors: true,
        orderBy: sensorCustomersSortString,
        ...queryVariables,
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) return prev
        return {
          ...fetchMoreResult,
          allSensorCylinderGroups: {
            ...fetchMoreResult?.allSensorCylinderGroups,
            edges: [
              ...(prev?.allSensorCylinderGroups?.edges ? prev.allSensorCylinderGroups.edges : []),
              ...(fetchMoreResult?.allSensorCylinderGroups?.edges ? fetchMoreResult.allSensorCylinderGroups.edges : []),
            ],
          },
        } as GQL.AllSensorCustomers
      },
    }).finally(() => {
      setSensorCustomersMoreLoading(false)
    })
  }, [
    sensorCustomersCylinderGroups.length,
    sensorCustomersData?.allSensorCylinderGroups?.totalCount,
    sensorCustomersMoreLoading,
    fetchMoreSensorCustomersData,
    sensorCustomersPageInfo.endCursor,
    sensorCustomersSortString,
    queryVariables,
  ])

  const handleLoadMoreNoSensorCustomers = useCallback(() => {
    if (noSensorCustomersCylinderGroups.length >= (noSensorCustomersData?.allNoSensorCylinderGroups?.totalCount || 0) || noSensorCustomersDataLoading) return

    setNoSensorCustomersMoreLoading(true)

    fetchMoreNoSensorCustomersData({
      variables: {
        first: PAGE_SIZE_NO_SENSOR_CUSTOMERS,
        after: noSensorCustomersPageInfo.endCursor,
        withSensors: false,
        orderBy: noSensorCustomersSortString,
        ...queryVariables,
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) return prev
        return {
          ...fetchMoreResult,
          allNoSensorCylinderGroups: {
            ...fetchMoreResult?.allNoSensorCylinderGroups,
            edges: [
              ...(prev?.allNoSensorCylinderGroups?.edges ? prev.allNoSensorCylinderGroups.edges : []),
              ...(fetchMoreResult?.allNoSensorCylinderGroups?.edges ? fetchMoreResult.allNoSensorCylinderGroups.edges : []),
            ],
          },
        } as GQL.AllNoSensorCustomers
      },
    }).finally(() => {
      setNoSensorCustomersMoreLoading(false)
    })
  }, [
    noSensorCustomersCylinderGroups.length,
    noSensorCustomersData?.allNoSensorCylinderGroups?.totalCount,
    noSensorCustomersDataLoading,
    fetchMoreNoSensorCustomersData,
    noSensorCustomersPageInfo.endCursor,
    noSensorCustomersSortString,
    queryVariables,
  ])

  const handleClearFilters = () => {
    setCustomerType([])
    setGasType([])
    setSetupStyle([])
    setStatus([])
    setselectedFilterTags([])
  }

  const handleSensorCustomersSortClick = (sortKey: string) => handleCylinderGroupSorting(sortKey, setSensorCustomersSort, 'estimatedEmpty')

  const handleNoSensorCustomersSortIcon = (sortKey: string) => handleCylinderGroupSorting(sortKey, setNoSensorCustomersSort)

  const getSensorCustomersSortIcon = (sortKey: string) => getCylinderGroupSortingIcon(sortKey, sensorCustomersSort)

  const getOtherCustomersSortIcon = (sortKey: string) => getCylinderGroupSortingIcon(sortKey, noSensorCustomersSort)

  const handleRowClick = (customer: GQL.CustomerNode, sensor: boolean) => {
    setSource(sensor ? CustomerDrawerListSource.SENSOR_CUSTOMERS : CustomerDrawerListSource.NO_SENSOR_CUSTOMERS)
    setCustomersContext(sensor ? sensorCustomers : noSensorCustomers)
    navigate(`?customer=${customer.id}`)
  }

  useEffect(() => {
    const customerParam = queryParams.get('customer')
    if (!!customerParam) {
      const isSensorCustomer = sensorCustomers.some(customer => customer.id === customerParam)
      if (isSensorCustomer) {
        setSource(CustomerDrawerListSource.SENSOR_CUSTOMERS)
        setCustomersContext(sensorCustomers)
        return
      }
      setSource(CustomerDrawerListSource.NO_SENSOR_CUSTOMERS)
      setCustomersContext(noSensorCustomers)
    }
  }, [noSensorCustomers, queryParams, sensorCustomers, setCustomersContext, setSource])

  const handleOrderMethodClick = (target: any, cylinderGroup: GQL.CylinderGroupNode) => {
    setAnchorEl(target)
    setOrderMethod(cylinderGroup.customer.orderMethod)
    setCallback(() => (orderMethod: GQL.CustomerOrderMethod) => {
      setAnchorEl(null)
      patchCustomer({
        variables: {
          id: cylinderGroup.customer.id,
          input: {
            orderMethod: orderMethod,
          },
        },
      })
    })
  }

  // HandleMethod for when the customer tags is changed
  const handleCustomerTagsChangeMethod = (newSelectedTagValues: string[], cylinderGroup: GQL.CylinderGroupNode) => {
    patchCustomer({
      variables: {
        id: cylinderGroup.customer.id,
        input: {
          tags: newSelectedTagValues,
        },
      },
    })
  }

  const loading = useMemo(
    () => noSensorCustomersDataLoading || noSensorCustomersMoreLoading || sensorCustomersDataLoading || sensorCustomersMoreLoading,
    [noSensorCustomersDataLoading, noSensorCustomersMoreLoading, sensorCustomersDataLoading, sensorCustomersMoreLoading]
  )

  const createCustomerCallback = async (customer: GQL.CustomerNode) => {
    if (!customer.cylinderGroups) {
      return
    }
    for (const cylinderGroup of customer.cylinderGroups) {
      await updateCylinderGroupCacheOnCreate(cylinderGroup as GQL.CylinderGroupNode, client, {
        ...queryVariables,
        after: null,
        withSensors: false,
        orderBy: noSensorCustomersSortString,
        first: PAGE_SIZE_NO_SENSOR_CUSTOMERS,
      })
    }
  }

  const deselectAllIds = useCallback(() => {
    setSelectedIds(new Set())
  }, [setSelectedIds])

  const openMessagesModal = useCallback(() => setIsMessagingModalOpen(true), [setIsMessagingModalOpen])

  // -------- TAGS ------- //

  // Used for values for tagFilter in header, and the options for the tags-field on customer row

  // Query for all tags owned by this distributor
  const { data } = GQL.useAllDistributorTags()

  // map query result into list of DistributorTagNode
  const distributorTags = data?.allDistributorTags?.edges.map(tag => tag?.node as GQL.DistributorTagNode) || []

  // Map the DistributorTagNode into select options. Passed as prop down to CustomersTable components
  const distributorTagOptions = distributorTags.map(tag => ({
    value: tag.id,
    label: tag.name,
  }))

  // Refetch Tag options after mutating
  const [createDistributorTag] = GQL.useCreateDistributorTag({
    refetchQueries: ['AllDistributorTags'], // TODO: cache update
  })

  // Handle creation of new tag options
  function handleNewTagOptionCreateMutation(newValues: string[]) {
    newValues.forEach(newTagString => {
      createDistributorTag({
        variables: {
          input: {
            name: newTagString,
            distributor: appContext.distributor?.id!,
          },
        },
      })
    })
  }

  const error = () => sensorCustomersDataError || noSensorCustomersDataError

  return error() ? null : (
    <>
      {/* Fix for https://smartcylinders.atlassian.net/browse/SW-324 */}
      {contentWrapperTop &&
        createPortal(
          <CustomersHeader
            businessCustomersCount={filtersInfo.businessCustomersCount || 0}
            residentCustomersCount={filtersInfo.residentCustomersCount || 0}
            propaneCount={filtersInfo.propaneCount || 0}
            co2Count={filtersInfo.co2Count || 0}
            standardSetupCount={filtersInfo.standardSetupCount || 0}
            parallelSetupCount={filtersInfo.parallelSetupCount || 0}
            insituSetupCount={filtersInfo.insituSetupCount || 0}
            emptyCylindersCount={filtersInfo.emptyCylindersCount || 0}
            orderedCylindersCount={filtersInfo.orderedCylindersCount || 0}
            awaitingDeliveryCount={filtersInfo.awaitingDeliveryCount || 0}
            search={search}
            customerType={customerType}
            gasType={gasType}
            setupStyle={setupStyle}
            status={status}
            availableFilterTags={distributorTags}
            selectedFilterTags={selectedFilterTags}
            onSearchChange={setSearch}
            onCustomerTypeChange={setCustomerType}
            onGasTypeChange={setGasType}
            onSetupStyleChange={setSetupStyle}
            onStatusChange={setStatus}
            onFilterTagsChange={setselectedFilterTags}
            onClearFilters={handleClearFilters}
            createCustomerCallback={createCustomerCallback}
          />,
          contentWrapperTop
        )}
      {loading && <CornerLoader size={65} topAdjust={'-30px'} />}
      <FlexTableWrapper
        includeBulkActionBar
        showBulkActionBar={selectedIds.size > 0}
        bulkActionBar={{
          collapsed: selectedIds.size === 0,
          selectedCount: selectedIds.size,
          btnDeselectAll: { props: { onClick: () => deselectAllIds() } },
          btnSendMessages: { props: { onClick: () => openMessagesModal() } },
        }}
        tables={
          <>
            <CustomersTable
              label={t({ id: 'customers.sensor-customers' })}
              cylinderGroups={sensorCustomersCylinderGroups}
              sensorsCalculations={sensorsCalculations}
              totalCount={sensorCustomersData?.allSensorCylinderGroups?.totalCount || 0}
              loading={sensorCustomersDataLoading && (!sensorCustomersCylinderGroups || sensorCustomersCylinderGroups.length < 1)}
              loadingMore={sensorCustomersMoreLoading}
              onSortClick={handleSensorCustomersSortClick}
              getSortIcon={getSensorCustomersSortIcon}
              onRowClick={customer => handleRowClick(customer, true)}
              onOrderMethodClick={handleOrderMethodClick}
              onCustomerTagsChange={handleCustomerTagsChangeMethod}
              onLoadMoreClick={handleLoadMoreSensorCustomers}
              distributorTagOptions={distributorTagOptions}
              onDistributorTagCreation={handleNewTagOptionCreateMutation}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
            />
            <CustomersTable
              label={t({ id: 'customers.other-customers' })}
              cylinderGroups={noSensorCustomersCylinderGroups}
              totalCount={noSensorCustomersData?.allNoSensorCylinderGroups?.totalCount || 0}
              loading={noSensorCustomersDataLoading && (!noSensorCustomersCylinderGroups || noSensorCustomersCylinderGroups.length < 1)}
              loadingMore={noSensorCustomersMoreLoading}
              onSortClick={handleNoSensorCustomersSortIcon}
              getSortIcon={getOtherCustomersSortIcon}
              onRowClick={customer => handleRowClick(customer, false)}
              onOrderMethodClick={handleOrderMethodClick}
              onCustomerTagsChange={handleCustomerTagsChangeMethod}
              onLoadMoreClick={handleLoadMoreNoSensorCustomers}
              distributorTagOptions={distributorTagOptions}
              onDistributorTagCreation={handleNewTagOptionCreateMutation}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              visibleColumns={[
                CustomerTableColumn.CUSTOMER_ID,
                CustomerTableColumn.ADDRESS,
                CustomerTableColumn.CYLINDER_SETUP,
                CustomerTableColumn.TAGS,
                CustomerTableColumn.ORDER_METHOD,
              ]}
            />
          </>
        }
      />
      <CustomerOrderMethodMenu
        anchorElement={anchorEl}
        currentMethod={orderMethod}
        onClose={() => {
          setAnchorEl(null)
        }}
        callback={callback}
      />
      {isMessagingModalOpen && (
        <MassCommunicationModal
          isOpen={isMessagingModalOpen}
          onClose={() => setIsMessagingModalOpen(false)}
          customerIds={[...selectedIds]}
          setCustomerIds={setSelectedIds}
        />
      )}
    </>
  )
}

export default CustomersOverview
