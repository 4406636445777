import React from 'react'

const SimpleClock = props => (
  <svg viewBox='0 0 18.8 18.8' {...props}>
    <path
      d='M9.4,0C4.2,0,0,4.2,0,9.4c0,5.2,4.2,9.4,9.4,9.4s9.4-4.2,9.4-9.4C18.8,4.2,14.6,0,9.4,0z M13.5,9.8c0,0.3-0.3,0.6-0.6,0.6H9
	c-0.3,0-0.6-0.3-0.6-0.6v-5c0-0.3,0.3-0.6,0.6-0.6h0.8c0.3,0,0.6,0.3,0.6,0.6v3.5h2.5c0.3,0,0.6,0.3,0.6,0.6V9.8z'
    />
  </svg>
)

export default SimpleClock
