import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import Modal, { ModalProps } from 'components/Modal/Modal'
import FormField from 'components/FormField'
import Label from 'components/Label'
import { useForm, Controller } from 'react-hook-form'
import Form from 'components/Form'
import SelectVehicle from 'modules/vehicles/components/SelectVehicle'
import Button from 'components/Button'
import Margin from 'components/Margin'
import { format } from 'date-fns'
import * as GQL from 'generated/graphql'

interface IChangeVehicleModalProps extends ModalProps {
  date: Date
  route: any
  submit: () => void
}

interface FormData {
  vehicle: string
}

const ChangeVehicleModal: React.FC<IChangeVehicleModalProps> = ({ date, route, submit, onRequestClose, ...props }) => {
  const intl = useIntl()
  const t = intl.formatMessage

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<FormData>()

  const [createDriverException] = GQL.useCreateDriverExceptions({
    refetchQueries: ['Routes'],
  })

  const [patchDriverException] = GQL.usePatchDriverExceptions({
    refetchQueries: ['Routes'],
  })

  function onSubmit(data: FormData) {
    submit()

    if (route.exceptionId) {
      patchDriverException({
        variables: {
          id: route.exceptionId,
          input: {
            defaultVehicle: data.vehicle,
            vacated: false,
          },
        },
      })
    } else {
      createDriverException({
        variables: {
          input: {
            defaultVehicle: data.vehicle,
            driver: route.driverId,
            onDate: format(date, 'yyyy-MM-dd'),
            vacated: false,
          },
        },
      })
    }
    onRequestClose()
  }

  return (
    <Modal title={format(date, 'EEEE, dd. LLLL') + ' (' + route.user.fullName + ')'} onRequestClose={onRequestClose} {...props} contentStyle={{ width: 500 }}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField>
          <Label>{t({ id: 'common.vehicle', defaultMessage: 'Vehicle' })}</Label>
          <Controller
            render={({ field: { value } }) => (
              <SelectVehicle
                defaultValue={value}
                onChange={newVehicle => {
                  setValue('vehicle', newVehicle)
                }}
                error={errors?.vehicle?.message as string}
                listProps={{ left: 0 }}
              />
            )}
            name='vehicle'
            defaultValue={route?.routeVehicle?.id}
            control={control}
            rules={{
              required: {
                value: true,
                message: t({ id: 'common.vehicle' }),
              },
            }}
          />
        </FormField>
        <Margin top='1rem'>
          <Button color='green' width='100%' borderRadius='0px'>
            <FormattedMessage id='common.save-changes' />
          </Button>
        </Margin>
      </Form>
    </Modal>
  )
}

export default ChangeVehicleModal
