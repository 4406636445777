import Icon from 'components/Icon'
import { useIntl } from 'react-intl'
import Modal, { ModalProps } from 'components/Modal/Modal'
import React, { useState } from 'react'
import styled from 'styled-components'
import NewUserAsDriverModal from 'modules/settings/components/NewUserAsDriverModal'
import AddExistingUserAsDriverModal from 'modules/settings/components/AddExistingUserAsDriverModal'

const SelectTiles = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin-top: 3rem;
  gap: 1rem;
`

const SelectTile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  background-color: #262d46;
  border-radius: 8px;
  width: 100%;
  height: 140px;
  cursor: pointer;

  :hover {
    filter: brightness(1.1);
  }
`

const SelectTitle = styled.div`
  text-align: center;
`

const SelectIcon = styled.div`
  margin-bottom: 0.5rem;
`

interface AddDriverAndOrUserModalProps extends ModalProps {
  onRequestClose: any
}

const WIZARD_STATE_UNDECIDED = 0
const WIZARD_STATE_ADD_EXISTING = 1
const WIZARD_STATE_ADD_NEW = 2

const AddDriverAndOrUserModal: React.FC<AddDriverAndOrUserModalProps> = ({ onRequestClose, ...props }) => {
  const intl = useIntl()
  const t = intl.formatMessage

  const [wizardState, setWizardState] = useState(WIZARD_STATE_UNDECIDED)

  const onRequestCloseInternal = () => {
    onRequestClose()
    setWizardState(0)
  }
  const back = () => {
    setWizardState(WIZARD_STATE_UNDECIDED)
  }

  if (wizardState === WIZARD_STATE_ADD_NEW) {
    return <NewUserAsDriverModal onRequestClose={onRequestCloseInternal} isOpen={props.isOpen} back={back} />
  }
  if (wizardState === WIZARD_STATE_ADD_EXISTING) {
    return <AddExistingUserAsDriverModal onRequestClose={onRequestCloseInternal} isOpen={props.isOpen} back={back} />
  }

  return (
    <Modal
      id='addDriver'
      {...props}
      title={t({ id: 'add-driver-and-or-user-modal.title' })}
      contentStyle={{ overflowY: 'visible', width: '450px', boxSizing: 'border-box', backgroundColor: '#242C48' }}
      onRequestClose={onRequestCloseInternal}
    >
      {wizardState === WIZARD_STATE_UNDECIDED && (
        <SelectTiles>
          <SelectTile onClick={() => setWizardState(WIZARD_STATE_ADD_EXISTING)} data-testid='driver-use-existing-user'>
            <SelectIcon>
              <Icon icon='search' size='21px' />
            </SelectIcon>
            <SelectTitle>{t({ id: 'add-driver-and-or-user-modal.find-existing' })}</SelectTitle>
          </SelectTile>
          <SelectTile onClick={() => setWizardState(WIZARD_STATE_ADD_NEW)} data-testid='driver-add-new-user'>
            <SelectIcon>
              <Icon icon='plus' size='21px' />
            </SelectIcon>
            <SelectTitle>{t({ id: 'add-driver-and-or-user-modal.new-user' })}</SelectTitle>
          </SelectTile>
        </SelectTiles>
      )}
    </Modal>
  )
}

export default AddDriverAndOrUserModal
