// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type CalEditHourssvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function CalEditHourssvgIcon(props: CalEditHourssvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 18.753 18.753"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M9.377 0C4.194 0 0 4.194 0 9.377s4.194 9.377 9.377 9.377 9.377-4.194 9.377-9.377S14.559 0 9.377 0zm4.167 9.786a.634.634 0 01-.632.632H8.968a.634.634 0 01-.632-.632V4.8c0-.348.285-.632.632-.632h.818c.348 0 .632.285.632.632v3.534h2.493c.348 0 .632.285.632.632v.82z"
        }
      ></path>
    </svg>
  );
}

export default CalEditHourssvgIcon;
/* prettier-ignore-end */
