/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react'
import * as GQL from 'generated/graphql'
import ReactModal from 'react-modal'
import ModalNewVehicle from 'plasmic/ModalNewVehicle'
import { modalStyle } from 'components/Modal/PlasmicModal'
import Select__Option from 'plasmic/Select__Option'
import { displayToast } from 'util/toasts'
import InputField from 'plasmic/InputField'

interface ICreateVehicleModalProps {
  isOpen: boolean
  onRequestClose: () => void
  data: any
  depots: any
}

const VehicleModal: React.FC<ICreateVehicleModalProps> = ({ isOpen, onRequestClose, data, depots }) => {
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [depot, setDepot] = useState<string>('')
  const [capacity, setCapacity] = useState<number>(0)
  const [vehicleType, setVehicleType] = useState<GQL.VehicleVehicleType>(GQL.VehicleVehicleType.Truck)

  useEffect(() => {
    setName(data.name || '')
    setDescription(data.description || '')
    setDepot(data.depot || '')
    setCapacity(data.capacity || 0)
    setVehicleType(data.vehicleType || GQL.VehicleVehicleType.Truck)
  }, [data.name, data.description, data.depot, data.capacity, data.vehicleType, data.create])

  const [createVehicle] = GQL.useCreateVehicle({
    onCompleted: () => {
      onRequestClose()
      displayToast('New vehicle has been created!', 'success')
    },
    onError: error => {
      error.message && displayToast(error.message)
      displayToast('Error occured! Try again!')
    },
    // Update cache by updating the bootstrap query vehicle data
    update(cache, { data }) {
      cache.modify({
        fields: {
          allVehicles(vehicleRefs, { readField }) {
            const fragRef = cache.writeFragment({
              fragment: GQL.VehicleInfo,
              fragmentName: 'VehicleInfo',
              data: data?.createVehicle?.vehicle,
            })

            if (vehicleRefs.edges.some((ref: any) => readField('id', ref.node) === data?.createVehicle?.vehicle?.id)) {
              return vehicleRefs
            }

            return {
              ...vehicleRefs,
              edges: [{ node: fragRef, __typename: 'VehicleNodeEdge' }, ...vehicleRefs.edges],
            }
          },
        },
      })
    },
  })
  const [patchVehicle] = GQL.usePatchVehicle({
    onCompleted: () => {
      onRequestClose()
      displayToast('Edited Succesfully!', 'success')
    },
    onError: error => {
      error.message && displayToast(error.message)
      displayToast('Error occured! Try again!')
    },
  })

  function handleSubmit() {
    if (name && description && depot && capacity && vehicleType) {
      if (data.create) {
        createVehicle({
          variables: {
            input: {
              vehicleType: vehicleType as GQL.VehicleVehicleType,
              depot: depot,
              name: name,
              registrationNumber: description,
              capacity: capacity,
            },
          },
        })
      } else {
        patchVehicle({
          variables: {
            id: data.id,
            input: {
              vehicleType: vehicleType as GQL.VehicleVehicleType,
              depot: depot,
              name: name,
              registrationNumber: description,
              capacity: capacity,
            },
          },
        })
      }
    } else {
      displayToast('Please fill all the fields!')
    }
  }

  return (
    <ReactModal id='new_vehicle' isOpen={isOpen} onRequestClose={onRequestClose} style={modalStyle()}>
      <ModalNewVehicle
        title={data.create ? 'Create New Vehicle' : 'Edit Vehicle'}
        btnClose={{ onClick: onRequestClose }}
        inputName={{ undefinedInput: { value: name, name: 'name', onChange: (e: any) => setName(e.target.value) } }}
        inputDescription={{ undefinedInput: { value: description, name: 'description', onChange: (e: any) => setDescription(e.target.value) } }}
        inputCapacity={
          <InputField
            title='Capacity (in kg)'
            fieldType='number'
            helpingText
            smallField
            helpText='kg'
            undefinedInput={{ value: capacity === 0 ? '' : capacity, onChange: (e: any) => setCapacity(e.target.value), name: 'capacity' }}
          />
        }
        selectDepot={{
          props: {
            label: 'select depot',
            'data-testid': 'depot-select',
            value: depot,
            onChange: (e: any) => {
              setDepot(e)
            },
            children: depots?.allDepots?.edges.map((depot: any) => (
              <Select__Option key={depot?.node?.id} value={depot?.node?.id}>
                {depot?.node?.name}
              </Select__Option>
            )),
          },
        }}
        selectVehicleType={{
          props: {
            label: 'Select vehicle type',
            name: 'vehicleType',
            onChange: (e: any) => {
              setVehicleType(e)
            },
            value: vehicleType,
          },
        }}
        btnCreateVehicle={{ onClick: () => handleSubmit(), color: name && description && depot && capacity && vehicleType ? 'green' : 'gray' }}
      />
    </ReactModal>
  )
}

export default VehicleModal
