// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type UserFilledMissingsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function UserFilledMissingsvgIcon(props: UserFilledMissingsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 103.623 86.397"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M38.409 43.908c12.105 0 21.954-9.848 21.954-21.954S50.515 0 38.409 0 16.455 9.849 16.455 21.954s9.849 21.954 21.954 21.954zm38.319 35.361c-3.21-18.689-19.326-32.254-38.319-32.254S3.3 60.58.09 79.269a6.114 6.114 0 001.347 4.99 6.004 6.004 0 004.6 2.138h64.745a6.002 6.002 0 004.6-2.138 6.117 6.117 0 001.346-4.99zm15.633-44.957l9.819-9.819a4.926 4.926 0 10-6.967-6.967l-9.819 9.819-9.819-9.819a4.926 4.926 0 10-6.967 6.967l9.819 9.819-9.819 9.819a4.926 4.926 0 006.966 6.967l9.819-9.819 9.819 9.819c.962.962 2.223 1.443 3.483 1.443s2.521-.481 3.483-1.443a4.926 4.926 0 000-6.967l-9.817-9.819z"
        }
      ></path>
    </svg>
  );
}

export default UserFilledMissingsvgIcon;
/* prettier-ignore-end */
