import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import CornerLoader from 'components/Loader/CornerLoader'
import * as GQL from 'generated/graphql'
import { displayToast } from 'util/toasts'
import { useAppContext } from 'util/hooks'
import PhoneInput from 'components/Input/PhoneInput'
import SettingsContentWrapper from 'plasmic/SettingsContentWrapper'
import ProfileSettings from 'plasmic/ProfileSettings'
import { EMAIL_REGEX } from 'modules/users/consts'

export default function SettingsProfile() {
  const [changes, setChanges] = useState<boolean>(false)
  const [formErrors, setFormErrors] = useState({
    phoneNumber: false,
  })
  const intl = useIntl()
  const t = intl.formatMessage

  const { appContext } = useAppContext()
  const user = appContext.user

  const [formData, setFormData] = useState(() => ({
    name: user?.firstName && user.lastName ? user?.firstName + ' ' + user?.lastName : '',
    email: user?.email,
    phoneNumber: user?.phoneNumber,
    multifactorAuthenticationEnabled: user?.multifactorAuthenticationEnabled || false,
  }))

  const [patchUser, { loading }] = GQL.usePatchUser({
    onCompleted: () => {
      displayToast(t({ id: 'settings.update-success' }), 'success')
      setChanges(false)
    },
    onError: () => {
      if (formData.multifactorAuthenticationEnabled && !formData.phoneNumber) {
        return displayToast(t({ id: 'settings.profile.error.tfa-no-phone-number' }))
      }
      displayToast(t({ id: 'settings.profile.error.generic' }))
    },
    update(cache, { data }) {
      cache.writeFragment({
        id: cache.identify({ id: data?.patchUser?.user?.id, __typename: 'UserNode' }),
        fragment: GQL.UserInfo,
        fragmentName: 'UserInfo',
        data: data?.patchUser?.user,
      })
    },
  })

  function handleSubmit() {
    patchUser({
      variables: {
        id: user?.id!,
        input: {
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          firstName: formData.name.split(' ').slice(0, 1).join(''),
          lastName: formData.name.split(' ').slice(1).join(' '),
          multifactorAuthenticationEnabled: formData.multifactorAuthenticationEnabled,
        },
      },
    })
  }

  useEffect(() => {
    if (
      formData.email !== user?.email ||
      formData.name.trim() !== user?.fullName ||
      formData.phoneNumber !== user?.phoneNumber ||
      formData.multifactorAuthenticationEnabled !== user.multifactorAuthenticationEnabled
    ) {
      setChanges(true)
    } else {
      setChanges(false)
    }
  }, [formData, user])

  return (
    <SettingsContentWrapper
      settingsTitle={t({ id: 'settings.profile' })}
      headerButtons={<></>}
      content={
        <>
          {loading && <CornerLoader size={50} />}
          <ProfileSettings
            edited={changes}
            inputName={{
              props: {
                fieldStatus: changes,
                alert: changes && formData.name.length < 3,
                undefinedInput: {
                  name: 'name',
                  value: formData.name,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setFormData({ ...formData, name: e.target.value })
                  },
                },
              },
            }}
            inputEmail={{
              props: {
                fieldStatus: changes,
                alert: changes && (!formData.email || !EMAIL_REGEX.test(formData.email)),
                undefinedInput: {
                  value: formData.email,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setFormData({ ...formData, email: e.target.value })
                  },
                },
              },
            }}
            inputPhoneNumberWrapper={{
              props: {
                fieldStatus: changes,
                alert: !formData.phoneNumber || (formData.phoneNumber && formErrors.phoneNumber),
                hideFieldStatus: !changes,
                divPhoneNumber: (
                  <PhoneInput
                    name='phoneNumber'
                    defaultValue={formData.phoneNumber}
                    value={formData.phoneNumber}
                    height='48px'
                    error={false} // optional
                    onPhoneNumberChange={(...all) => {
                      setFormData({ ...formData, phoneNumber: all[3].replace(/\s|-/g, '') })
                      if (!all[0]) {
                        setFormErrors({ ...formErrors, phoneNumber: true })
                      } else {
                        setFormErrors({ ...formErrors, phoneNumber: false })
                      }
                    }}
                    onSelectFlag={(...all) => {
                      setFormData({ ...formData, phoneNumber: all[2].replace(/\s|-/g, '') })
                      if (!all[3]) {
                        setFormErrors({ ...formErrors, phoneNumber: true })
                      } else {
                        setFormErrors({ ...formErrors, phoneNumber: false })
                      }
                    }}
                  />
                ),
              },
            }}
            switchEnableTwoFactor={{
              props: {
                name: 'enableMultifactorAuthentication',
                isChecked: formData.multifactorAuthenticationEnabled,
                onChange: event => setFormData({ ...formData, multifactorAuthenticationEnabled: event }),
              },
            }}
            btnCancel={{
              props: {
                label: t({ id: 'common.cancel' }),
                onClick: () =>
                  setFormData({
                    name: user?.firstName && user.lastName ? user?.firstName + ' ' + user?.lastName : '',
                    email: user?.email,
                    phoneNumber: user?.phoneNumber,
                    multifactorAuthenticationEnabled: user?.multifactorAuthenticationEnabled || false,
                  }),
              },
            }}
            btnSaveChanges={{
              props: {
                label: t({ id: 'common.save-changes' }),
                onClick: handleSubmit,
              },
            }}
          />
        </>
      }
    />
  )
}
