import React from 'react'
import styled from 'styled-components'
import Text from 'components/Text'
import * as GQL from 'generated/graphql'

const Cell = styled.div`
  justify-content: center;
`

interface ICylinderGroupCylinderSetupCellProps {
  cylinderGroup: GQL.CylinderGroupNode
}

function CylinderGroupCylinderSetupCell({ cylinderGroup }: ICylinderGroupCylinderSetupCellProps) {
  const sides = cylinderGroup.cylinderSides || []

  const gasType = cylinderGroup.gasType!.toLowerCase().capitalizeFirst()
  const gasWeight = parseFloat(cylinderGroup.product?.weight) || 0

  return (
    <Cell>
      {!cylinderGroup.numberOfSides && (
        <Text>
          <i className='far fa-empty-set'></i>
        </Text>
      )}
      {sides.length > 0 && (
        <>
          <Text fontWeight='bold'>{sides.map(s => s?.cylinderCount).join(' x ')}</Text>
          &nbsp; x {parseFloat(gasWeight.toString())} kg {gasType}
        </>
      )}
    </Cell>
  )
}

export default CylinderGroupCylinderSetupCell
