// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type CrosssvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function CrosssvgIcon(props: CrosssvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 27 27.001"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M17.492 13.5l9.034-9.034a1.341 1.341 0 000-1.894L24.427.473a1.341 1.341 0 00-1.894 0L13.5 9.508 4.466.475a1.341 1.341 0 00-1.894 0L.474 2.573a1.341 1.341 0 000 1.894L9.508 13.5.474 22.534a1.341 1.341 0 000 1.894l2.099 2.099c.522.522 1.37.522 1.894 0l9.033-9.035 9.034 9.034c.522.522 1.37.522 1.894 0l2.099-2.099a1.341 1.341 0 000-1.894L17.492 13.5z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default CrosssvgIcon;
/* prettier-ignore-end */
