// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import { useState, useMemo, forwardRef, useCallback, useEffect } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import * as GQL from 'generated/graphql'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import CustomersDrawerInner from 'modules/customers/CustomersDrawer/CustomersDrawerInner'
import { Control, MenuList, Option, reactSelectStylesMobile } from './StyledReactSelect'
import { useApolloClient } from '@apollo/client'

import { PlasmicMobileLimited, DefaultMobileLimitedProps } from './plasmic/solace_components/PlasmicMobileLimited'
import Loader from 'components/Loader'
import { CenteredLoader } from 'components/Loader/LoaderWrappers'
import { useLocation } from 'react-router-dom'
import { useAppContext, useDebounce } from 'util/hooks'
import FlexTableWrapper from './FlexTableWrapper'
import FlexTable from './FlexTable'
import TableRow from './TableRow'
import CellCustomer from './CellCustomer'
import CellAddress from './CellAddress'
import CellCylinderSetup from './CellCylinderSetup'
import CellStatus from './CellStatus'
import ButtonFill from './ButtonFill'
import CustomersTable from 'modules/customers/CustomersTable'
import { Status } from 'modules/customers/CustomersOverview'
import CacheConfigs from 'util/cacheConfig'
import { CylinderGroupSorting, getCylinderGroupSortingIcon } from 'modules/customers/util'
import { SimplifiedCalculation } from 'services/sensorCalculation'
import { useUpdateCustomerField } from 'modules/customers/hooks'
import { createSortStringFromMapping } from 'util/sort'
import CustomerOrderMethodMenu from 'modules/customers/components/CustomerOrderMethodMenu'
import { getUrl, registerSensorAppUrl } from 'util/env'
import { removeLoginToken } from 'util/auth'
import { CustomerTableColumn } from 'context/TableContext'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface MobileLimitedProps extends Omit<DefaultMobileLimitedProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultMobileLimitedProps altogether and have
// total control over the props for your component.
export interface MobileLimitedProps extends DefaultMobileLimitedProps {}

interface OptionType {
  value: string
  label: string
}

const LoaderWrapper = styled(CenteredLoader)`
  margin: 0px;
`

const StyledPlasmicMobileLimited = styled(PlasmicMobileLimited)`
  height: 100dvh;

  .css-1nmdiq5-menu {
    background-color: rgb(24, 29, 54) !important;
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  z-index: 0;

  .customer-modal-tab-wrapper {
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .customer-modal-tab-wrapper::-webkit-scrollbar {
    display: none;
  }
`

const CustomerSelector = styled(Select)`
  width: 100%;
`

const PAGE_SIZE = 10

type Pages = 'overview' | 'customers'

function MobileLimited_(props: MobileLimitedProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicMobileLimited to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicMobileLimited are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all MobileLimitedProps here, but feel free
  // to do whatever works for you.
  // const customer = new URLSearchParams(location.search).get('customer')
  const intl = useIntl()
  const t = intl.formatMessage
  const location = useLocation()
  const { appContext } = useAppContext()
  const apolloClient = useApolloClient()
  const customerURLId = new URLSearchParams(location.search).get('customer')
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(customerURLId || null)
  const [search, setSearch] = useState('')
  const [menuOpen, setMenuOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState<Pages>('overview')
  const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set())
  const debouncedSearch = useDebounce(search, 500)
  const {
    data: dataCustomers,
    loading: loadingCustomers,
    fetchMore: fetchMoreCustomers,
  } = GQL.useAllCustomers({ variables: { after: null, first: PAGE_SIZE, search: debouncedSearch } })

  const customers = useMemo(() => dataCustomers?.allCustomers?.edges.map(edge => edge?.node as GQL.CustomerNode) || [], [dataCustomers])
  const [customer, setCustomer] = useState<GQL.CustomerNode | null>(null)

  const [sensorCustomersMoreLoading, setSensorCustomersMoreLoading] = useState(false)
  const [otherCustomersMoreLoading, setOtherCustomersMoreLoading] = useState(false)
  const [sensorCustomersSort, setSensorCustomersSort] = useState<CylinderGroupSorting>({ estimatedEmpty: 1 })
  const [otherCustomersSort, setOtherCustomersSort] = useState<CylinderGroupSorting>({ name: 1 })

  const getSensorCustomersSortIcon = (sortKey: string) => getCylinderGroupSortingIcon(sortKey, sensorCustomersSort)
  const getOtherCustomersSortIcon = (sortKey: string) => getCylinderGroupSortingIcon(sortKey, otherCustomersSort)

  const [sensorsCalculations, setSensorsCalculations] = useState<{ [key: string]: SimplifiedCalculation }>({})

  const { handleUpdate } = useUpdateCustomerField()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [orderMethod, setOrderMethod] = useState<GQL.CustomerOrderMethod | null>(null)
  const [callback, setCallback] = useState<((method: GQL.CustomerOrderMethod) => void) | null>(null)

  const sensorCustomersSortString = createSortStringFromMapping(sensorCustomersSort)
  const otherCustomersSortString = createSortStringFromMapping(otherCustomersSort)

  const handleChangePage = (page: Pages) => {
    setCurrentPage(page)
    setMenuOpen(false)
  }

  const handleOrderMethodClick = (target: any, cylinderGroup: GQL.CylinderGroupNode) => {
    setAnchorEl(target)
    setOrderMethod(cylinderGroup.customer.orderMethod)
    setCallback(() => (orderMethod: GQL.CustomerOrderMethod) => {
      setAnchorEl(null)
      handleUpdate(cylinderGroup.customer.id, 'orderMethod', orderMethod, cylinderGroup.customer)
    })
  }

  // -------- TAGS ------- //

  // Used for values for tagFilter in header, and the options for the tags-field on customer row

  // Query for all tags owned by this distributor
  const { data } = GQL.useAllDistributorTags(CacheConfigs.DEFAULT)

  // map query result into list of DistributorTagNode
  const distributorTags = data?.allDistributorTags?.edges.map(tag => tag?.node as GQL.DistributorTagNode) || []

  // Map the DistributorTagNode into select options. Passed as prop down to CustomersTable components
  const distributorTagOptions = distributorTags.map(tag => ({
    value: tag.id,
    label: tag.name,
  }))

  // Refetch Tag options after mutating
  const [createDistributorTag] = GQL.useCreateDistributorTag({
    refetchQueries: ['AllDistributorTags'],
  })

  // Handle creation of new tag options
  function handleNewTagOptionCreateMutation(newValues: string[]) {
    newValues.forEach(newTagString => {
      createDistributorTag({
        variables: {
          input: {
            name: newTagString,
            distributor: appContext.distributor?.id!,
          },
        },
      })
    })
  }

  // HandleMethod for when the customer tags is changed
  const handleCustomerTagsChangeMethod = (newSelectedTagValues: string[], cylinderGroup: GQL.CylinderGroupNode) => {
    handleUpdate(cylinderGroup.customer.id, 'tags', newSelectedTagValues, cylinderGroup.customer)
  }

  const {
    loading: sensorCustomersDataLoading,
    data: sensorCustomersData,
    error: sensorCustomersDataError,
    fetchMore: fetchMoreSensorCustomersData,
  } = GQL.useAllCylinderGroupsWithFiltersInfoCancellable(
    {
      after: null,
      first: PAGE_SIZE,
      search: debouncedSearch,
      orderBy: sensorCustomersSortString,
      customerType: [],
      gasType: [],
      cylinderMode: [],
      /*tags: selectedFilterTags,*/
      withSensors: true,
    },
    [appContext.depot],
    CacheConfigs.ACCURATE_FREQUENT.fetchPolicy,
    CacheConfigs.ACCURATE_FREQUENT.nextFetchPolicy,
    CacheConfigs.ACCURATE_FREQUENT.pollInterval
  )

  const {
    loading: otherCustomersDataLoading,
    data: otherCustomersData,
    fetchMore: fetchMoreOtherCustomersData,
  } = GQL.useAllCylinderGroupsWithFiltersInfoCancellable(
    {
      after: null,
      first: PAGE_SIZE,
      search: debouncedSearch,
      orderBy: otherCustomersSortString,
      customerType: [],
      gasType: [],
      cylinderMode: [],
      /*tags: selectedFilterTags,*/
      withSensors: false,
    },
    [appContext.depot],
    CacheConfigs.ACCURATE_FREQUENT.fetchPolicy,
    CacheConfigs.ACCURATE_FREQUENT.nextFetchPolicy,
    CacheConfigs.ACCURATE_FREQUENT.pollInterval
  )

  const sensorCustomersPageInfo = useMemo(() => {
    return sensorCustomersData?.allCylinderGroups?.pageInfo || { hasNextPage: false, endCursor: '' }
  }, [sensorCustomersData])
  const sensorCustomersFiltersInfo = useMemo(() => {
    return sensorCustomersData?.allCylinderGroups?.filtersInfo as GQL.CylinderGroupFiltersInfo | undefined
  }, [sensorCustomersData])
  const sensorCustomersCylinderGroups = useMemo(() => {
    return sensorCustomersData?.allCylinderGroups?.edges.map(edge => edge?.node as GQL.CylinderGroupNode) || []
  }, [sensorCustomersData])
  const otherCustomersPageInfo = useMemo(() => {
    return otherCustomersData?.allCylinderGroups?.pageInfo || { hasNextPage: false, endCursor: '' }
  }, [otherCustomersData])
  const otherCustomersFiltersInfo = useMemo(() => {
    return otherCustomersData?.allCylinderGroups?.filtersInfo as GQL.CylinderGroupFiltersInfo | undefined
  }, [otherCustomersData])
  const otherCustomersCylinderGroups = useMemo(() => {
    return otherCustomersData?.allCylinderGroups?.edges.map(edge => edge?.node as GQL.CylinderGroupNode) || []
  }, [otherCustomersData])

  useEffect(() => {
    if (customers.length === 0 || customer?.id === selectedCustomerId) return
    if (!selectedCustomerId) {
      setSelectedCustomerId(customers[0].id)
      return
    }
    setCustomer(customers.find(customer => customer.id === selectedCustomerId) || null)
    setCurrentPage('customers')
  }, [selectedCustomerId])

  const handleLoadMoreSensorCustomers = useCallback(() => {
    if (sensorCustomersCylinderGroups.length >= (sensorCustomersData?.allCylinderGroups?.totalCount || 0) || sensorCustomersMoreLoading) return

    setSensorCustomersMoreLoading(true)
    fetchMoreSensorCustomersData({
      variables: {
        after: sensorCustomersPageInfo.endCursor,
        first: PAGE_SIZE,
        search: debouncedSearch,
        customerType: [],
        gasType: [],
        cylinderMode: [],
        /*tags: selectedFilterTags,*/
        orderBy: sensorCustomersSortString,
        withSensors: true,
      },
      updateQuery(prev: any, { fetchMoreResult }) {
        if (!fetchMoreResult) return prev
        return {
          ...fetchMoreResult,
          allCylinderGroups: {
            ...fetchMoreResult?.allCylinderGroups,
            edges: [...(prev.allCylinderGroups?.edges || []), ...fetchMoreResult?.allCylinderGroups?.edges!],
          },
        } as GQL.AllCylinderGroupsWithFiltersInfo
      },
    }).finally(() => {
      setSensorCustomersMoreLoading(false)
    })
  }, [
    fetchMoreSensorCustomersData,
    debouncedSearch,
    /*selectedFilterTags,*/
    sensorCustomersSortString,
    sensorCustomersPageInfo.endCursor,
    sensorCustomersData?.allCylinderGroups?.totalCount,
    sensorCustomersCylinderGroups.length,
    sensorCustomersMoreLoading,
  ])

  const handleLoadMoreOtherCustomers = useCallback(() => {
    if (otherCustomersCylinderGroups.length >= (otherCustomersData?.allCylinderGroups?.totalCount || 0) || otherCustomersMoreLoading) return

    setOtherCustomersMoreLoading(true)

    fetchMoreOtherCustomersData({
      variables: {
        after: otherCustomersPageInfo.endCursor,
        first: PAGE_SIZE,
        search: debouncedSearch,
        customerType: [],
        gasType: [],
        cylinderMode: [],
        /*tags: selectedFilterTags,*/
        orderBy: otherCustomersSortString,
        withSensors: false,
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) return prev
        return {
          ...fetchMoreResult,
          allCylinderGroups: {
            ...fetchMoreResult?.allCylinderGroups,
            edges: [...(prev.allCylinderGroups?.edges || []), ...fetchMoreResult?.allCylinderGroups?.edges!],
          },
        } as GQL.AllCylinderGroupsWithFiltersInfo
      },
    }).finally(() => {
      setOtherCustomersMoreLoading(false)
    })
  }, [
    fetchMoreOtherCustomersData,
    debouncedSearch,
    /*selectedFilterTags,*/
    otherCustomersSortString,
    otherCustomersPageInfo.endCursor,
    otherCustomersData?.allCylinderGroups?.totalCount,
    otherCustomersCylinderGroups.length,
    otherCustomersMoreLoading,
  ])

  useCallback(() => {
    if (!dataCustomers?.allCustomers?.pageInfo.hasNextPage) return
    fetchMoreCustomers({
      variables: {
        after: dataCustomers?.allCustomers?.pageInfo.endCursor,
        first: PAGE_SIZE,
        search: debouncedSearch,
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) return prev
        return {
          ...fetchMoreResult,
        } as GQL.AllCustomers
      },
    })
  }, [fetchMoreCustomers, debouncedSearch, dataCustomers?.allCustomers?.pageInfo])

  const handleLogoutClick = () => {
    apolloClient.clearStore().then(() => {
      removeLoginToken()
      window.location.reload()
    })
  }

  const handleRowClick = (customer: GQL.CustomerNode) => {
    setCustomer(customer)
    setSelectedCustomerId(customer.id)
    setCurrentPage('customers')
  }

  return (
    <StyledPlasmicMobileLimited
      root={{ ref }}
      {...props}
      navbarMobile={{
        selectCustomerDiv: (
          <CustomerSelector
            placeholder={
              loadingCustomers ? (
                <LoaderWrapper>
                  <Loader color='white' />
                </LoaderWrapper>
              ) : (
                'Select a customer...'
              )
            }
            components={{ Option, Control, MenuList }}
            styles={reactSelectStylesMobile}
            isMulti={false}
            onChange={(event: any) => setSelectedCustomerId(event?.value || null)}
            onInputChange={event => setSearch(event)}
            value={
              {
                value: customer?.id || '',
                label: customer?.name || 'Select customer',
              } as OptionType
            }
            options={customers.map(
              customer =>
                ({
                  value: customer.id,
                  label: customer.name,
                }) as OptionType
            )}
          />
        ),
        navMenuMobile: {
          open: menuOpen,
          btnNavMenu: {
            onClick: () => {
              setMenuOpen(!menuOpen)
            },
          },

          navCustomerList: {
            onClick: () => handleChangePage('overview'),
          },
          navCustomerInfo: {
            onClick: () => handleChangePage('customers'),
          },

          navRegister: {
            onClick: () => window.open(registerSensorAppUrl(), '_blank'),
          },
          navDelivery: {
            onClick: () => window.open(`https://drivers.${getUrl()}`, '_blank'),
          },
          navLogout: {
            onClick: () => handleLogoutClick(),
          },
        },
      }}
      content={
        <>
          {currentPage === 'customers' ? (
            <ContentWrapper>
              <CustomersDrawerInner customerId={customer?.id || ''} noHead={true} />
            </ContentWrapper>
          ) : currentPage === 'overview' ? (
            <>
              <FlexTableWrapper
                style={{ width: '100%', marginBottom: '500px' }}
                tables={
                  <>
                    <CustomersTable
                      label={t({ id: 'customers.sensor-customers' })}
                      cylinderGroups={sensorCustomersCylinderGroups}
                      sensorsCalculations={sensorsCalculations}
                      totalCount={sensorCustomersData?.allCylinderGroups?.totalCount || 0}
                      loading={sensorCustomersDataLoading && (!sensorCustomersCylinderGroups || sensorCustomersCylinderGroups.length < 1)}
                      loadingMore={sensorCustomersMoreLoading}
                      onSortClick={() => console.log('Sort click!')}
                      getSortIcon={getSensorCustomersSortIcon}
                      onRowClick={(customer: GQL.CustomerNode) => handleRowClick(customer)}
                      onOrderMethodClick={handleOrderMethodClick}
                      onCustomerTagsChange={handleCustomerTagsChangeMethod}
                      onLoadMoreClick={handleLoadMoreSensorCustomers}
                      distributorTagOptions={distributorTagOptions}
                      onDistributorTagCreation={handleNewTagOptionCreateMutation}
                      selectedIds={selectedIds}
                      setSelectedIds={setSelectedIds}
                      noSorting={true}
                    />
                    <CustomersTable
                      label={t({ id: 'customers.other-customers' })}
                      cylinderGroups={otherCustomersCylinderGroups}
                      totalCount={otherCustomersData?.allCylinderGroups?.totalCount || 0}
                      loading={otherCustomersDataLoading && (!otherCustomersCylinderGroups || otherCustomersCylinderGroups.length < 1)}
                      loadingMore={otherCustomersMoreLoading}
                      onSortClick={() => console.log('Sort click!')}
                      getSortIcon={getOtherCustomersSortIcon}
                      onRowClick={(customer: GQL.CustomerNode) => handleRowClick(customer)}
                      onOrderMethodClick={handleOrderMethodClick}
                      onCustomerTagsChange={handleCustomerTagsChangeMethod}
                      onLoadMoreClick={handleLoadMoreOtherCustomers}
                      distributorTagOptions={distributorTagOptions}
                      onDistributorTagCreation={handleNewTagOptionCreateMutation}
                      selectedIds={selectedIds}
                      setSelectedIds={setSelectedIds}
                      visibleColumns={[
                        CustomerTableColumn.ADDRESS,
                        CustomerTableColumn.CYLINDER_SETUP,
                        CustomerTableColumn.TAGS,
                        CustomerTableColumn.ORDER_METHOD,
                      ]}
                      noSorting={true}
                    />
                  </>
                }
              />
              <CustomerOrderMethodMenu
                anchorElement={anchorEl}
                currentMethod={orderMethod}
                onClose={() => {
                  setAnchorEl(null)
                }}
                callback={callback}
              />
            </>
          ) : null}
        </>
      }
    />
  )
}

const MobileLimited = forwardRef(MobileLimited_)
export default MobileLimited
