import * as React from 'react'
import { PlasmicTableHeaderCell, DefaultTableHeaderCellProps } from './plasmic/solace_components/PlasmicTableHeaderCell'
import { HTMLElementRefOf, SingleChoiceArg } from '@plasmicapp/react-web'

interface TableHeaderCellProps extends DefaultTableHeaderCellProps {
  title?: string
  sorting?: SingleChoiceArg<'upActive' | 'downActive'>
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void
}

function TableHeaderCell_(props: TableHeaderCellProps, ref: HTMLElementRefOf<'div'>) {
  return <PlasmicTableHeaderCell root={{ ref }} {...props} />
}

const TableHeaderCell = React.forwardRef(TableHeaderCell_)
export default TableHeaderCell
