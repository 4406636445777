// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicCellCustomer, DefaultCellCustomerProps } from './plasmic/solace_components/PlasmicCellCustomer'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import TutorialTooltip from 'components/Tooltip/TutorialTooltip'
import { useIntl } from 'react-intl'
import { omit } from 'lodash'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface CellCustomerProps extends Omit<DefaultCellCustomerProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultCellCustomerProps altogether and have
// total control over the props for your component.
interface CellCustomerProps extends DefaultCellCustomerProps {
  onClick?: any
  style?: any
  color?: any
  temperature?: string
}

function CellCustomer_(props: CellCustomerProps, ref: HTMLElementRefOf<'div'>) {
  const intl = useIntl()
  const t = intl.formatMessage

  return (
    <PlasmicCellCustomer
      root={{ ref }}
      {...omit(props, 'temperature')}
      deliveryWindowTooltip={{
        wrap: content => <TutorialTooltip content={t({ id: 'alerts.delivery-date-exceeded' })}>{content}</TutorialTooltip>,
      }}
      contactTooltip={{
        wrap: content => <TutorialTooltip content={t({ id: 'alerts.contact-person-missing' })}>{content}</TutorialTooltip>,
      }}
      paymentTooltip={{
        wrap: content => <TutorialTooltip content={t({ id: 'alerts.payment-due-exceeded' })}>{content}</TutorialTooltip>,
      }}
      freezeTooltip={{
        wrap: content => <TutorialTooltip content={t({ id: 'alerts.freezing-temperature' }, { temperature: props.temperature })}>{content}</TutorialTooltip>,
      }}
    />
  )
}

const CellCustomer = React.forwardRef(CellCustomer_)
export default CellCustomer
