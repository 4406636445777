import Papa from 'papaparse'
import { differenceInCalendarDays, differenceInDays, differenceInHours, isBefore, parseISO } from 'date-fns'
import * as XLSX from 'xlsx'
import { ApolloClient } from '@apollo/client'
import { IntlShape } from 'react-intl'
import Fuse from 'fuse.js'

import * as GQL from 'generated/graphql'
import { InputImportCustomer } from 'generated/graphql'
import { displayToast } from '../../util/toasts'
import { CYLINDER_COLORS, DAYS_NOT_REPORTING_THRESHOLD, SIDE_REMAINING_UNIT, WEEKDAYS } from './consts'
import { enumToStringFormatted } from 'util/utils'
import { CustomerInfo } from 'plasmic/ModalCreateCustomer'
import { CustomerTableColumn } from 'context/TableContext'
import { isValidAddress } from 'modules/localization/utils'
import { getRemainingGas } from 'modules/hardware/util'

export interface AllCylinderGroupsQueryVariables {
  search?: string
  customerType?: GQL.CustomerDomainType[]
  gasType?: string[]
  cylinderMode?: GQL.CylinderGroupMode[]
  emptyCylinders?: true | undefined
  orderedCylinders?: true | undefined
  awaitingDelivery?: true | undefined
  tags?: string[]
  after?: string | null | undefined
  withSensors?: boolean
  orderBy?: string | undefined
  customer?: string | undefined
  first: number
}

export function updateCylinderGroupCacheOnCreate(
  createdCylinderGroup: GQL.CylinderGroupNode,
  client: ApolloClient<object>,
  noSensorCustomersQueryVariables: AllCylinderGroupsQueryVariables
) {
  // it needs to be filtered to avoid passing key: undefined variable (so it will mismatch original query)
  const filteredNoSensorCustomersQueryVariables = Object.fromEntries(
    Object.entries(noSensorCustomersQueryVariables).filter(([_, value]) => value !== undefined)
  )

  // update No Sensor Customer list query cache (newly created customer is definitely not a sensor one)
  client.cache.updateQuery({ query: GQL.AllNoSensorCustomersDocument, variables: filteredNoSensorCustomersQueryVariables }, oldData => {
    if (oldData) {
      // TODO: change sorting to use frontend so it could be sorted properly now it goes on top
      const updatedNoSensorCylinderGroupEdges = [
        { __typename: 'CylinderGroupNodeEdge', node: createdCylinderGroup },
        ...(oldData?.allNoSensorCylinderGroups?.edges ? oldData.allNoSensorCylinderGroups.edges : []),
      ]
      return {
        ...oldData,
        allNoSensorCylinderGroups: {
          ...oldData.allNoSensorCylinderGroups,
          totalCount: (oldData?.allNoSensorCylinderGroups?.totalCount || 0) + 1,
          edges: updatedNoSensorCylinderGroupEdges,
        },
      }
    }
  })
}

export function updateCylinderGroupCacheOnPatch(
  editedCylinderGroup: GQL.CylinderGroupNode,
  client: ApolloClient<object>,
  sensorCustomersQueryVariables: AllCylinderGroupsQueryVariables,
  noSensorCustomersQueryVariables: AllCylinderGroupsQueryVariables
) {
  // it needs to be filtered to avoid passing key: undefined variable (so it will mismatch original query)
  const filteredSensorCustomersQueryVariables = Object.fromEntries(Object.entries(sensorCustomersQueryVariables).filter(([_, value]) => value !== undefined))
  const filteredNoSensorCustomersQueryVariables = Object.fromEntries(
    Object.entries(noSensorCustomersQueryVariables).filter(([_, value]) => value !== undefined)
  )

  // update Sensor Customer list query cache
  client.cache.updateQuery({ query: GQL.AllSensorCustomersDocument, variables: filteredSensorCustomersQueryVariables }, oldData => {
    if (oldData) {
      // TODO: change sorting to use frontend so it could be sorted properly now it goes on top
      const prevEdges = oldData?.allSensorCylinderGroups?.edges ? oldData.allSensorCylinderGroups.edges : ([] as GQL.CylinderGroupNodeEdge[])
      const updatedEdges = prevEdges.map((edge: GQL.CylinderGroupNodeEdge) => {
        if (edge?.node?.id === editedCylinderGroup.id) {
          return {
            node: {
              ...editedCylinderGroup,
            },
            __typename: 'CylinderGroupNodeEdge',
          }
        }
        return edge
      })
      return {
        ...oldData,
        allSensorCylinderGroups: {
          ...oldData.allSensorCylinderGroups,
          edges: updatedEdges,
        },
      }
    }
  })

  // update No Sensor Customer list query cache
  client.cache.updateQuery({ query: GQL.AllNoSensorCustomersDocument, variables: filteredNoSensorCustomersQueryVariables }, oldData => {
    if (oldData) {
      // TODO: change sorting to use frontend so it could be sorted properly now it goes on top
      const prevEdges = oldData?.allNoSensorCylinderGroups?.edges ? oldData.allNoSensorCylinderGroups.edges : ([] as GQL.CylinderGroupNodeEdge[])
      const updatedEdges = prevEdges.map((edge: GQL.CylinderGroupNodeEdge) => {
        if (edge?.node?.id === editedCylinderGroup.id) {
          return {
            node: {
              ...editedCylinderGroup,
            },
            __typename: 'CylinderGroupNodeEdge',
          }
        }
        return edge
      })
      return {
        ...oldData,
        allNoSensorCylinderGroups: {
          ...oldData.allNoSensorCylinderGroups,
          edges: updatedEdges,
        },
      }
    }
  })
}

export function updateSensorCacheOnPatch(editedSensor: GQL.SensorNode, client: ApolloClient<object>, editedCylinderGroup?: GQL.CylinderGroupNode) {
  if (editedCylinderGroup) {
    const updatedCylinderSides = editedCylinderGroup?.cylinderSides?.map(side => {
      if (side?.sensors && side.sensors.length > 0) {
        const updatedSensors = side?.sensors?.map(sideSensor => {
          if (sideSensor?.id === editedSensor?.id) {
            return { ...sideSensor, status: editedSensor?.status === GQL.SensorStatus.Off ? GQL.SensorStatus.On : GQL.SensorStatus.Off } as GQL.SensorNode
          }
          return sideSensor
        })
        return { ...side, sensors: updatedSensors }
      }
      return side
    })
    const cylinderGroup = { ...editedCylinderGroup, cylinderSides: updatedCylinderSides }
    client.cache.updateQuery({ query: GQL.AllCylinderGroupsDocument, variables: { first: 50, customer: cylinderGroup.customer.id } }, oldData => {
      if (oldData) {
        const prevEdges = oldData?.allCylinderGroups?.edges ? oldData.allCylinderGroups.edges : ([] as GQL.CylinderGroupNodeEdge[])
        const updatedEdges = prevEdges.map((edge: GQL.CylinderGroupNodeEdge) => {
          if (edge?.node?.id === cylinderGroup.id) {
            return {
              node: {
                ...cylinderGroup,
              },
              __typename: 'CylinderGroupNodeEdge',
            }
          }
          return edge
        })
        return {
          ...oldData,
          allCylinderGroups: {
            ...oldData.allCylinderGroups,
            edges: updatedEdges,
          },
        }
      }
    })
  }

  const existingSensorNode = client.cache.readFragment({
    id: client.cache.identify({ id: editedSensor?.id, __typename: 'SensorNode' }),
    fragment: GQL.SensorInfo,
    fragmentName: 'SensorInfo',
  })

  if (existingSensorNode) {
    const updatedSensorNode = {
      ...existingSensorNode,
      status: editedSensor?.status === GQL.SensorStatus.Off ? GQL.SensorStatus.On : GQL.SensorStatus.Off,
    }
    client.cache.writeFragment({
      id: client.cache.identify({ id: editedSensor?.id, __typename: 'SensorNode' }),
      fragment: GQL.SensorInfo,
      fragmentName: 'SensorInfo',
      data: updatedSensorNode,
    })
  }
}

export function updateCylinderGroupCacheOnDelete(
  removedCylinderGroup: GQL.CylinderGroupNode,
  client: ApolloClient<object>,
  sensorCustomersQueryVariables: AllCylinderGroupsQueryVariables,
  noSensorCustomersQueryVariables: AllCylinderGroupsQueryVariables
) {
  // it needs to be filtered to avoid passing key: undefined variable (so it will mismatch original query)
  const filteredSensorCustomersQueryVariables = Object.fromEntries(Object.entries(sensorCustomersQueryVariables).filter(([_, value]) => value !== undefined))
  const filteredNoSensorCustomersQueryVariables = Object.fromEntries(
    Object.entries(noSensorCustomersQueryVariables).filter(([_, value]) => value !== undefined)
  )

  // update Sensor Customer list query cache
  client.cache.updateQuery({ query: GQL.AllSensorCustomersDocument, variables: filteredSensorCustomersQueryVariables }, oldData => {
    if (oldData) {
      // TODO: change sorting to use frontend so it could be sorted properly now it goes on top
      const prevEdges = oldData?.allSensorCylinderGroups?.edges ? oldData.allSensorCylinderGroups.edges : ([] as GQL.CylinderGroupNodeEdge[])
      const updatedEdges = prevEdges.filter((edge: GQL.CylinderGroupNodeEdge) => edge?.node?.id !== removedCylinderGroup.id)
      return {
        ...oldData,
        allSensorCylinderGroups: {
          ...oldData.allSensorCylinderGroups,
          edges: updatedEdges,
        },
      }
    }
  })

  // update No Sensor Customer list query cache
  client.cache.updateQuery({ query: GQL.AllNoSensorCustomersDocument, variables: filteredNoSensorCustomersQueryVariables }, oldData => {
    if (oldData) {
      // TODO: change sorting to use frontend so it could be sorted properly now it goes on top
      const prevEdges = oldData?.allNoSensorCylinderGroups?.edges ? oldData.allNoSensorCylinderGroups.edges : ([] as GQL.CylinderGroupNodeEdge[])
      const updatedEdges = prevEdges.filter((edge: GQL.CylinderGroupNodeEdge) => edge?.node?.id !== removedCylinderGroup.id)
      return {
        ...oldData,
        allNoSensorCylinderGroups: {
          ...oldData.allNoSensorCylinderGroups,
          edges: updatedEdges,
        },
      }
    }
  })
}

export const isSideOff = (side: GQL.CylinderGroupSideNode): boolean => {
  return side?.sensors ? side.sensors.some(sensor => sensor?.status === GQL.SensorStatus.Off) : false
}

export const isMonitored = (side: GQL.CylinderGroupSideNode): boolean => {
  const sensors = side.sensors
  const hasSensor = sensors && sensors.length! > 0 ? sensors.find(el => true) : null
  return !!hasSensor
}

export const isReporting = (side: GQL.CylinderGroupSideNode): boolean => {
  if (!side?.activeCycle || !side?.activeCycle?.last || !side?.activeCycle?.updatedAt) {
    return false
  }

  const daysSinceReporting = differenceInCalendarDays(new Date(), parseISO(side.activeCycle.last || side.activeCycle.updatedAt))
  return daysSinceReporting <= DAYS_NOT_REPORTING_THRESHOLD
}

export function getCylinderGroupEstimatedEmptyStatus(cylinderGroup: GQL.CylinderGroupNode) {
  if (!cylinderGroup) {
    return null
  }

  if (cylinderGroup.cylinderSides?.some(side => side?.estimatedEmpty === null)) {
    return [Infinity, Infinity]
  }

  // Sort on status first. Empty sides always come first, after that, sort on estimatedEmpty
  const sortedCylinderSides = [...cylinderGroup.cylinderSides!].sort((a, b) => {
    if (a?.estimatedEmpty) {
      return -1
    }

    if (b?.estimatedEmpty) {
      return 1
    }

    const aDate = parseISO(a?.estimatedEmpty)
    const bDate = parseISO(b?.estimatedEmpty)

    return isBefore(aDate, bDate) ? -1 : 1
  })

  const now = new Date()

  return sortedCylinderSides.map(side => (side?.estimatedEmpty ? differenceInCalendarDays(parseISO(side.estimatedEmpty), now) : Infinity))
}

export const getSensorStatus = (sensor: GQL.SensorNode, cycle?: GQL.Maybe<GQL.CycleNode>) => {
  const lastTransmission = cycle && (cycle.last || cycle?.updatedAt) ? parseISO(cycle.last || cycle.updatedAt) : null
  const isRecent = (date: Date) => differenceInHours(new Date(), date) < 80
  const isConnected = !!(lastTransmission && isRecent(lastTransmission))

  const connectionStatus = isConnected ? 'Connected' : 'Disconnected'

  const getCalibrationStatus = () => {
    if (
      isConnected &&
      sensor.installedAt &&
      isRecent(parseISO(sensor.installedAt)) &&
      ![GQL.CycleState.Depleting, GQL.CycleState.Idling, GQL.CycleState.Empty, GQL.CycleState.Full].includes(cycle?.state!)
    ) {
      return ' - Calibrating'
    }
    return ''
  }

  return connectionStatus + getCalibrationStatus()
}

interface IOpeningHours {
  opensAt: string
  closesAt: string
}

export const getDefaultOpeningHours = (openingHours: IOpeningHours[]) => {
  let opensAt: string | undefined, closesAt: string | undefined
  const defaultOpensAt = '07:00'
  const defaultClosesAt = '15:00'
  if (openingHours.length > 0) {
    const previousDay = openingHours.at(-1)
    opensAt = previousDay?.opensAt
    closesAt = previousDay?.closesAt
  }
  return {
    opensAt: opensAt || defaultOpensAt,
    closesAt: closesAt || defaultClosesAt,
  }
}

export const getClosedDays = (cylinderGroup?: GQL.CylinderGroupNode) => {
  // 0 - Sunday
  // 1 - Monday
  // ...
  if (!cylinderGroup) return [0, 6]
  if (cylinderGroup.customer.alwaysOpen) {
    return []
  } else if (cylinderGroup.customer.standardOpeningHours) {
    const weekDays = [...Array(7).keys()]
    const standardOpeningDays = cylinderGroup.customer.standardOpeningHours
      .map(openingHour => openingHour as GQL.CustomerStandardOpeningHoursNode)
      .map(standardOpeningHour => WEEKDAYS[standardOpeningHour.weekday])

    return weekDays.filter(day => !standardOpeningDays.includes(day))
  }

  return [0, 6]
}

export const extractDetailsForSimplifiedCalculation = (cylinderGroup: GQL.CylinderGroupNode) => {
  let side = cylinderGroup.cylinderSides?.find(cylinderSide => cylinderSide?.sensors?.length)

  if (cylinderGroup.activeSideId && cylinderGroup.cylinderSides?.length && cylinderGroup.cylinderSides.length > 1) {
    const activeSides = cylinderGroup.cylinderSides.filter(cylinderSide => cylinderSide?.id === cylinderGroup.activeSideId)

    if (activeSides.length > 0) {
      if (activeSides[0]?.sensors?.length) {
        side = activeSides[0]
      }
    }
  }

  return {
    state: side?.activeCycle?.state || null,
    sensorSerialNumber: side?.sensors?.length ? side.sensors[0]?.serialNumber : null,
    calculationId: side?.activeCycle?.lastCalculation || null,
  }
}

export interface CylinderGroupSorting {
  [key: string]: number
}

export const getCylinderGroupSortingIcon = (sortKey: string, sort: CylinderGroupSorting) => {
  if (sort[sortKey]) {
    switch (sort[sortKey]) {
      case 1:
        return 'downActive'
      case -1:
        return 'upActive'

      case 0:
        return
    }
  }
  return
}
export const handleCylinderGroupSorting = (
  sortKey: string,
  setSort: React.Dispatch<React.SetStateAction<CylinderGroupSorting>>,
  defaultName: string = 'name'
) => {
  setSort(currentSort => {
    let newSortValue = 0

    switch (currentSort[sortKey]) {
      case 1:
        newSortValue = -1
        break
      case -1:
        newSortValue = 0
        break
      default:
        newSortValue = 1
        break
    }

    const newSort = {
      [sortKey]: newSortValue,
    }

    if (newSortValue === 0) {
      newSort[defaultName] = 1
    }

    return newSort
  })
}
export const getThresholdProps = (unit: SIDE_REMAINING_UNIT, threshold?: GQL.DistributorRefillThresholdNode | null) => {
  if (threshold) {
    return {
      trigger: threshold?.unit === unit.value,
      triggerIco: { triggerName: threshold?.name, triggerText: `An order will automatically be generated at ${threshold?.threshold} ${unit.unit}` },
    }
  }
  return {
    trigger: null,
    triggerIco: null,
  }
}

export interface CylinderGroupSideProps {
  sensors: GQL.SensorNode[]
  cylinderCount: number
  depleting: boolean
  daysRemaining: number | null
  activeCycleRemainingPercentage: number
  monitored: boolean
  progressLabel: string
  progressColor: 'gray' | 'red' | 'blue' | 'orange' | 'green' | 'purple' | undefined
  cylinderColor: 'gray' | 'red' | 'blue' | 'orange' | 'green' | 'purple' | 'freezeout' | undefined
  errorState: 'noConnection' | 'noisyData' | 'zeroWeight' | 'off' | undefined
  hidePercent: boolean
  isFreezing: boolean
}

export const getCylinderGroupSideProps = (side: GQL.CylinderGroupSideNode) => {
  const props: CylinderGroupSideProps = {
    sensors: side.sensors as GQL.SensorNode[],
    cylinderCount: side.cylinderCount,
    depleting: false,
    daysRemaining: side?.activeCycle?.estimatedEmpty ? getCylinderSideEstimatedDaysRemaining(side.activeCycle.estimatedEmpty) : null,
    activeCycleRemainingPercentage: getRemainingGas(side?.activeCycle).percentage || 0,
    monitored: isMonitored(side),
    progressLabel: '',
    progressColor: undefined,
    cylinderColor: undefined,
    errorState: undefined,
    hidePercent:
      !side.activeCycle ||
      !isMonitored(side) ||
      isSideOff(side) ||
      (isMonitored(side) && ![GQL.CycleState.Empty, GQL.CycleState.Depleting, GQL.CycleState.Full].includes(side.activeCycle?.state as GQL.CycleState)),
    isFreezing: side.activeCycle?.isFreezing || false,
  }

  // current hierarchy: off > no data > not reporting > else

  if (isSideOff(side)) {
    props.activeCycleRemainingPercentage = 100
    props.daysRemaining = 10
    props.progressLabel = 'Off'
    props.errorState = 'off'
    props.progressColor = props.cylinderColor = CYLINDER_COLORS.gray as 'gray'
    return props
  }

  if (!side.activeCycle) {
    props.progressLabel = 'No data'
    props.progressColor = props.cylinderColor = CYLINDER_COLORS.gray as 'gray'
    props.activeCycleRemainingPercentage = 100
    props.daysRemaining = 10
    return props
  }

  if (side?.activeCycle?.state === GQL.CycleState.Depleting) {
    props.depleting = true
    props.progressLabel = ''
    props.progressColor = props.cylinderColor = undefined
    if (!props.monitored) {
      props.activeCycleRemainingPercentage = 100
      props.progressColor = props.cylinderColor = CYLINDER_COLORS.gray as 'gray'
    }
  }

  if (props.monitored && !isReporting(side)) {
    props.progressLabel = 'Not reporting'
    props.daysRemaining = 10
    props.progressColor = props.cylinderColor = CYLINDER_COLORS.red as 'red'
    props.errorState = 'noConnection'
  } else if (side.activeCycle.state) {
    switch (side.activeCycle.state) {
      case GQL.CycleState.NotInUse:
      case GQL.CycleState.Unknown:
        props.activeCycleRemainingPercentage = 100
        props.daysRemaining = 10
        props.progressLabel = enumToStringFormatted(side.activeCycle.state)
        props.progressColor = props.cylinderColor = CYLINDER_COLORS.gray as 'gray'
        break
      case GQL.CycleState.Error:
        props.hidePercent = true
        props.activeCycleRemainingPercentage = 100
        props.daysRemaining = 10
        props.progressLabel = 'Sensor problem'
        props.progressColor = props.cylinderColor = CYLINDER_COLORS.red as 'red'
        break
      case GQL.CycleState.Full:
        if (!isMonitored(side)) {
          props.activeCycleRemainingPercentage = 100
        }

        props.daysRemaining = 10
        props.progressLabel = 'Full'
        props.progressColor = props.cylinderColor = CYLINDER_COLORS.blue as 'blue'
        break
      case GQL.CycleState.Empty:
        props.daysRemaining = 0
        //side.activeCycle.remainingPercentage = 0; //Hard set the percent to zero if empty (dont do this?)
        props.cylinderColor = CYLINDER_COLORS.red as 'red'
        props.progressLabel = 'Empty'
        break
      case GQL.CycleState.Idling:
        props.activeCycleRemainingPercentage = 100
        props.daysRemaining = 10
        props.progressLabel = 'Initializing...'
        props.progressColor = props.cylinderColor = CYLINDER_COLORS.orange as 'orange'
        props.depleting = true
        break
      case GQL.CycleState.Init:
        props.activeCycleRemainingPercentage = 100
        props.daysRemaining = 10
        props.progressLabel = 'Calibrating...'
        props.progressColor = props.cylinderColor = CYLINDER_COLORS.green as 'green'
        props.depleting = true
        break
      default:
        if (side.activeCycle.currentWeight && side.activeCycle.tareWeight && side.activeCycle.currentWeight < side.activeCycle.tareWeight / 2) {
          props.progressLabel = 'Zero weight'
          props.errorState = 'zeroWeight'
          props.progressColor = props.cylinderColor = CYLINDER_COLORS.gray as 'gray'
          props.daysRemaining = 10
        } else if (props.daysRemaining === null) {
          props.progressLabel = 'No estimation'
          props.progressColor = props.cylinderColor = CYLINDER_COLORS.gray as 'gray'
        } else if (props.daysRemaining === 1) {
          props.progressLabel = '1 day'
          props.daysRemaining = 1
        } else if (props.daysRemaining <= 0) {
          props.progressLabel = 'Soon'
          props.daysRemaining = 1
        } else if (props.daysRemaining > 10) {
          props.progressLabel = 'Over 10 days'
        } else {
          props.progressLabel = `${props.daysRemaining} days`
        }
    }
  } else {
    // in case active cycle has no state
    if (props.monitored && side.activeCycle && side.activeCycle.updatedAt <= new Date().getDate() - DAYS_NOT_REPORTING_THRESHOLD) {
      props.progressLabel = 'Not reporting'
      props.daysRemaining = 10
      props.progressColor = props.cylinderColor = CYLINDER_COLORS.red as 'red'
      props.errorState = 'noConnection'
    } else if (side.activeCycle?.currentWeight && side.activeCycle?.tareWeight && side.activeCycle.currentWeight < side.activeCycle.tareWeight / 2) {
      props.progressLabel = 'Zero weight'
      props.errorState = 'zeroWeight'
      props.progressColor = props.cylinderColor = CYLINDER_COLORS.gray as 'gray'
      props.daysRemaining = 10
    } else if (props.daysRemaining === null) {
      props.progressLabel = 'No estimation'
      props.progressColor = props.cylinderColor = CYLINDER_COLORS.gray as 'gray'
    } else if (props.daysRemaining === 1) {
      props.progressLabel = '1 day'
      props.daysRemaining = 1
    } else if (props.daysRemaining <= 0) {
      props.progressLabel = 'Soon'
      props.daysRemaining = 1
    } else if (props.daysRemaining > 10) {
      props.progressLabel = 'Over 10 days'
    } else {
      props.progressLabel = `${props.daysRemaining} days`
    }
  }
  if (side.activeCycle.isFreezing) {
    props.cylinderColor = CYLINDER_COLORS.freezeout as 'freezeout'
  }
  return props
}

export const getCylinderSideEstimatedDaysRemaining = (estimatedEmptyDate: Date) => {
  return differenceInDays(new Date(estimatedEmptyDate), new Date())
}

export const getLatestEstimatedEmptyDate = (cylinderGroup?: GQL.CylinderGroupNode) => {
  // check if customer has any sensors
  if (cylinderGroup?.cylinderSides?.some(cylinderSide => cylinderSide?.sensors?.some(sensor => Array.isArray(sensor) && sensor.length > 0))) {
    return cylinderGroup.cylinderSides?.some(e => e?.activeCycle?.state === GQL.CycleState.Full && !e?.activeCycle?.estimatedEmpty)
      ? null
      : cylinderGroup.cylinderSides?.some(e => e?.activeCycle?.estimatedEmpty)
        ? cylinderGroup.cylinderSides?.reduce((acc, curr) => {
            if (curr?.activeCycle?.estimatedEmpty && parseISO(curr?.activeCycle?.estimatedEmpty) > acc) {
              return parseISO(curr.activeCycle?.estimatedEmpty)
            } else {
              return acc
            }
          }, new Date())
        : null
  }
  return null
}

export const EXPORT_FILE_HEADERS = ['Name', 'Customer identifier', 'Address', 'Phone number', 'Depot', 'Latitude', 'Longitude', 'Order Method']

export function compareExportFileHeaders(testedArray: string[]) {
  if (testedArray.length !== EXPORT_FILE_HEADERS.length) {
    return false
  }

  return testedArray.every((value, index) => value.toLowerCase() === EXPORT_FILE_HEADERS[index].toLowerCase())
}

export const processCSV = (file: File): Promise<InputImportCustomer[]> => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: results => {
        const headers = results.meta.fields
        if (!headers) {
          displayToast('CSV file you provided not matching our template.')
          reject('No headers')
          return
        }
        if (compareExportFileHeaders(headers)) {
          const data: Record<string, string>[] = results.data as Record<string, string>[]
          const transformedData: InputImportCustomer[] = data.map(row => {
            return {
              address: row['Address'],
              customer_identifier: row['customer_identifier'] || '', // optional
              depot: row['Depot'],
              latitude: row['Latitude'] || '', // optional
              longitude: row['Longitude'] || '', // optional
              name: row['Name'],
              phoneNumber: row['Phone number'] || '', // optional
              orderMethod: row['Order Method'],
            }
          })
          resolve(transformedData)
        } else {
          displayToast('CSV file you provided not matching our template.')
          reject('Invalid headers')
        }
      },
      error: error => {
        displayToast('Error while parsing CSV file.')
        reject(error)
      },
    })
  })
}

export const getEstimatedEmptyTooltip = (side: GQL.CylinderGroupSideNode, props: CylinderGroupSideProps) => {
  const cycle = side.activeCycle
  const errorLabels = ['Not reporting', 'No data', 'Off', 'Sensor problem', 'Zero weight', 'Initializing...', 'Calibrating...', 'No estimation', 'Not in use']
  return cycle?.estimatedEmpty && !errorLabels.includes(props.progressLabel) ? new Date(cycle.estimatedEmpty).toLocaleDateString() : undefined
}

export const processXLSX = (file: File): Promise<InputImportCustomer[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = e => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer)
      const workbook = XLSX.read(data, { type: 'array' })
      const worksheet = workbook.Sheets[workbook.SheetNames[0]]
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        raw: false,
        defval: '',
        blankrows: false,
      })
      const headers = jsonData[0] as string[]
      if (!headers) {
        displayToast('XLSX file you provided not matching our template.')
        reject('No headers')
        return
      }

      if (compareExportFileHeaders(headers)) {
        const transformedData: InputImportCustomer[] = jsonData.slice(1).map((row: any) => {
          return {
            name: row[0],
            customerIdentifier: row[1], // optional
            address: row[2],
            phoneNumber: row[3] || '', // optional
            depot: row[4],
            longitude: row[5] || '', // optional
            latitude: row[6] || '', // optional
            orderMethod: row[7],
          }
        })
        resolve(transformedData)
      } else {
        displayToast('XLSX file you provided not matching our template.')
        reject('Invalid headers')
      }
    }

    reader.onerror = error => {
      displayToast('Error while reading XLSX file.')
      reject(error)
    }

    reader.readAsArrayBuffer(file)
  })
}

export const isPaymentProviderActive = (provider: GQL.PaymentProviders, distributor: GQL.DistributorNode | null | undefined) => {
  if (!distributor || !distributor.paymentSetting) return false

  if (provider === GQL.PaymentProviders.Stripe) {
    return distributor.paymentSetting.stripe
  } else if (provider === GQL.PaymentProviders.Visma) {
    return distributor.paymentSetting.visma
  }
  return false
}

export const isPaymentMethodActive = (method: GQL.PaymentMethods, provider: GQL.PaymentProviders, distributor: GQL.DistributorNode | null | undefined) => {
  if (!distributor || !distributor.paymentSetting) return false

  if (provider === GQL.PaymentProviders.Stripe) {
    if (method === GQL.PaymentMethods.InAdvance) {
      return distributor.paymentSetting.stripe?.paymentAlternatives?.inAdvance
    } else if (method === GQL.PaymentMethods.OnDelivery) {
      return distributor.paymentSetting.stripe?.paymentAlternatives?.onDelivery
    } else if (method === GQL.PaymentMethods.Credit) {
      return distributor.paymentSetting.stripe?.paymentAlternatives?.credit
    }
  } else if (provider === GQL.PaymentProviders.Visma) {
    if (method === GQL.PaymentMethods.Credit) {
      return distributor.paymentSetting.visma?.paymentAlternatives?.credit
    }
  }
  return false
}

export const handleCustomerCreateValidation = (
  customerInfo: CustomerInfo,
  configureCylinderSetup: boolean,
  customersContext: GQL.CustomerNode[],
  handleCreateCustomer: () => void,
  intl: IntlShape
): void => {
  const t = intl.formatMessage

  if (!customerInfo.name || customerInfo.name.length < 3) {
    displayToast(t({ id: 'customers.create.customer-name-invalid' }))
    return
  } else if (!isValidAddress(customerInfo.address)) {
    displayToast(t({ id: 'customers.create.customer-address-invalid' }))
    return
  } else if (!customerInfo.depot.value) {
    displayToast(t({ id: 'customers.create.customer-depot-missing' }))
    return
  } else if (!customerInfo.alwaysOpen && customerInfo.openingHours.length === 0) {
    displayToast(t({ id: 'customers.create.customer-opening-hours-invalid' }))
    return
  } else if (configureCylinderSetup && !customerInfo.product.value) {
    displayToast(t({ id: 'customers.create.customer-no-product' }))
    return
  }

  let similarAddressCustomers = []
  let similarPhoneCustomers = []

  const allCustomersNames = customersContext.map(customer => customer.name)

  const nameFuse = new Fuse(allCustomersNames, {
    includeScore: true,
  })
  let similarNameCustomers = nameFuse.search(customerInfo.name).filter(result => result.hasOwnProperty('score') && result.score! < 0.05)

  if (customerInfo.address) {
    const allCustomersAddress = customersContext.map(customer => customer.address)

    const addressFuse = new Fuse(allCustomersAddress, {
      includeScore: true,
    })
    similarAddressCustomers = addressFuse.search(customerInfo.address.firstLine).filter(result => result.hasOwnProperty('score') && result.score! < 0.05)
  }
  if (customerInfo.phoneNumber) {
    const allCustomersPhoneNumbers = customersContext.map(customer => customer.phoneNumber)

    const phoneFuse = new Fuse(allCustomersPhoneNumbers, {
      includeScore: true,
    })
    similarPhoneCustomers = phoneFuse.search(customerInfo.phoneNumber).filter(result => result.hasOwnProperty('score') && result.score! < 0.02)
  }
  if (similarNameCustomers.length > 0) {
    displayToast(
      t({ id: 'customers.create-patch-customer.alert.address-and-phone-number-similarity' }),
      'confirm',
      { autoClose: false },
      true,
      () => {},
      () => handleCreateCustomer()
    )
  } else if (similarAddressCustomers.length > 0 && similarPhoneCustomers.length > 0) {
    displayToast(
      t({ id: 'customers.create-patch-customer.alert.address-and-phone-number-similarity' }),
      'confirm',
      { autoClose: false },
      true,
      () => {},
      () => handleCreateCustomer()
    )
  } else if (similarAddressCustomers.length > 0) {
    displayToast(
      t({ id: 'customers.create-patch-customer.alert.address-similarity' }),
      'confirm',
      { autoClose: false },
      true,
      () => {},
      () => {
        return true
      }
    )
  } else if (similarPhoneCustomers.length > 0) {
    displayToast(
      t({ id: 'customers.create-patch-customer.alert.phone-number-similarity' }),
      'confirm',
      { autoClose: false },
      true,
      () => {},
      () => handleCreateCustomer()
    )
  } else {
    handleCreateCustomer()
  }
}

export const isSensorCustomer = (customer: GQL.CustomerNode): boolean => {
  return customer?.cylinderGroups
    ? customer.cylinderGroups.some(cylinderGroup =>
        cylinderGroup?.cylinderSides ? (cylinderGroup.cylinderSides.flatMap(cylinderSide => cylinderSide?.sensors) || []).length > 0 : false
      )
    : false
}

export const translateCustomerColumn = (column: CustomerTableColumn, intl: IntlShape): string => {
  const t = intl.formatMessage

  if (column === CustomerTableColumn.ADDRESS) {
    return t({ id: 'common.address' })
  } else if (column === CustomerTableColumn.CUSTOMER_ID) {
    return t({ id: 'common.customer-id' })
  } else if (column === CustomerTableColumn.CHART) {
    return t({ id: 'common.chart' })
  } else if (column === CustomerTableColumn.CYLINDER_SETUP) {
    return t({ id: 'common.cylinder-setup' })
  } else if (column === CustomerTableColumn.ESTIMATED_EMPTY) {
    return t({ id: 'common.estimated-empty' })
  } else if (column === CustomerTableColumn.ORDER_METHOD) {
    return t({ id: 'common.order-method' })
  } else if (column === CustomerTableColumn.TAGS) {
    return t({ id: 'common.tags' })
  }

  return column
}
