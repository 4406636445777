import * as React from 'react'
import { PlasmicFlexTable, DefaultFlexTableProps } from './plasmic/solace_components/PlasmicFlexTable'
import { HTMLElementRefOf, Flex } from '@plasmicapp/react-web'
import TableHeaderCell from './TableHeaderCell'
import Checkbox from './Checkbox'
import { TutorialInfoIcon } from 'components/Tooltip/TutorialTooltip'
import { FormattedMessage } from 'react-intl'
import TooltipEstimatedEmpty from './TooltipEstimatedEmpty'

interface FlexTableProps extends DefaultFlexTableProps {
  visibleColumns?: any
  rows?: Flex<'div'>
  head?: Flex<'div'>
  headCustomerId?: any
  headCustomerIdParent?: any
  headDateOrderedParent?: any
  headPriceParent?: any
  headAddressParent?: any
  headProductParent?: any
  headOrderedByParent?: any
  headDownloadOrderParent?: any
  headViewImagesParent?: any
  headOrderIdParent?: any
  headDateDeliveredParent?: any
  headCommentParent?: any
  headStatusParent?: any
  headCylinderSetupParent?: any
  headEstimatedEmptyParent?: any
  headInventoryParent?: any
  headOrderMethodParent?: any
  headChartParent?: any
  headTagsParent?: any
  headAddress?: Flex<typeof TableHeaderCell>
  headCylinderSetup?: Flex<typeof TableHeaderCell>
  headProduct?: Flex<typeof TableHeaderCell>
  headStatus?: Flex<typeof TableHeaderCell>
  headPayment?: Flex<typeof TableHeaderCell>
  headEstimatedEmpty?: Flex<typeof TableHeaderCell>
  headTags?: Flex<typeof TableHeaderCell>
  headChart?: Flex<typeof TableHeaderCell>
  headOrderedBy?: Flex<typeof TableHeaderCell>
  headDateOrdered?: Flex<typeof TableHeaderCell>
  headDateOrderedTitle?: Flex<'div'>
  headDeliveryWindow?: Flex<typeof TableHeaderCell>
  headEstimatedEmptyInfo?: Flex<'div'>
  headInventory?: Flex<typeof TableHeaderCell>
  headOrderMethod?: Flex<typeof TableHeaderCell>
  headPhoneNumber?: Flex<typeof TableHeaderCell>
  headPrice?: Flex<typeof TableHeaderCell>
  headOrderMethodInfo?: Flex<'div'>
  checkboxWrapper?: Flex<'div'>
  checkbox?: Flex<typeof Checkbox>
  style?: any
  sticky?: any
  id?: any
}

function FlexTable_(props: FlexTableProps, ref: HTMLElementRefOf<'div'>) {
  return (
    <PlasmicFlexTable
      root={{ ref }}
      {...props}
      headOrderMethodInfo={
        <TutorialInfoIcon
          content={
            <FormattedMessage
              id='tooltips.customers.order_method'
              values={{
                b: chunks => (
                  <b>
                    <u>{chunks}</u>
                  </b>
                ),
                br: <br />,
              }}
            />
          }
        />
      }
      headEstimatedEmptyInfo={<TutorialInfoIcon interactive appendTo={document.body} content={<TooltipEstimatedEmpty />} />}
      headChartInfo={<TutorialInfoIcon content={<FormattedMessage id='tooltips.customers.chart' />} />}
    />
  )
}

const FlexTable = React.forwardRef(FlexTable_)
export default FlexTable
