import React, { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import {
  FilterTagObjectInfo,
  CylinderGroupOrderFiltersInfo,
  CylinderGroupOrderNode,
  AllCylinderGroupsWithFiltersInfo,
  Maybe,
  useAllOrdersPageCancellable,
} from 'generated/graphql'
import { NoHits, translateOrdersAllColumn } from './util'
import { useDebounce, useTableContext } from '../../util/hooks'
import AllOrdersTable from 'plasmic/AllOrdersTable'
import OrdersTable from './AllOrdersTable/AllOrdersTable'
import { CenteredLoader } from '../../components/Loader/LoaderWrappers'
import Loader from '../../components/Loader'
import ExportOrders from './AllOrdersTable/ExportOrders'
import CacheConfigs from 'util/cacheConfig'

import LabelStatusChip from '../../plasmic/LabelStatusChip'
import ButtonFilterDropdownRowCustom from 'plasmic/ButtonFilterDropdownRowCustom'
import ButtonFilterDropdown from 'plasmic/ButtonFilterDropdown'
import EyeInvisiblesvgIcon from 'plasmic/plasmic/solace_components/icons/PlasmicIcon__EyeInvisiblesvg'
import EyesvgIcon from 'plasmic/plasmic/solace_components/icons/PlasmicIcon__Eyesvg'
import { AllOrdersTableColumn } from 'context/TableContext'

const PAGE_SIZE = 25
const MANAGE_TAG_PATH = '/settings/tags'

export default function AllOrders() {
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [stateIn, setStateIn] = useState<number[]>([])
  const [customerType, setCustomerType] = useState<number[]>([])
  const [isExportModalOpen, setIsExportModalOpen] = useState(false)
  const [ordersMoreLoading, setOrdersMoreLoading] = useState(false)

  // Used to provide a list of selected tag names
  const [selectedFilterTagsStringList, setSelectedFilterTagsStringList] = useState<string[]>([])

  const intl = useIntl()
  const t = intl.formatMessage

  const { visibleOrdersAllTableColumns, setVisibleOrdersAllTableColumns } = useTableContext()

  const debouncedSearch = useDebounce(search, 500)

  const {
    data,
    loading,
    fetchMore: fetchMoreOrders,
  } = useAllOrdersPageCancellable(
    {
      after: null,
      search: debouncedSearch,
      first: PAGE_SIZE,
      stateIn: stateIn,
      customerType: customerType,
      selectedTags: selectedFilterTagsStringList,
      orderBy: '-createdAt',
    },
    [],
    CacheConfigs.ACCURATE_ONCE.fetchPolicy
  )

  const ordersPageInfo = useMemo(() => {
    return data?.allOrdersPage?.pageInfo || { hasNextPage: false, endCursor: '' }
  }, [data])
  const ordersFiltersInfo = useMemo(() => {
    return data?.allOrdersPage?.filtersInfo as CylinderGroupOrderFiltersInfo | undefined
  }, [data])
  const allOrders = useMemo(() => {
    return data?.allOrdersPage?.edges.map(edge => edge?.node as CylinderGroupOrderNode) || []
  }, [data])

  const handleFetchMoreOrders = useCallback(() => {
    if ((ordersFiltersInfo?.allOrdersCount && allOrders.length >= ordersFiltersInfo?.allOrdersCount) || ordersMoreLoading) return
    setOrdersMoreLoading(true)

    fetchMoreOrders({
      variables: {
        after: ordersPageInfo.endCursor,
        first: PAGE_SIZE,
        search: debouncedSearch,
        stateIn: stateIn,
        customerType: customerType,
        selectedTags: selectedFilterTagsStringList,
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) return prev
        return {
          ...fetchMoreResult,
          allOrdersPage: {
            ...fetchMoreResult?.allOrdersPage,
            edges: [...(prev.allOrdersPage?.edges || []), ...fetchMoreResult?.allOrdersPage?.edges!],
          },
        } as AllCylinderGroupsWithFiltersInfo
      },
    }).finally(() => {
      setOrdersMoreLoading(false)
    })
  }, [
    fetchMoreOrders,
    debouncedSearch,
    stateIn,
    ordersPageInfo.endCursor,
    allOrders.length,
    customerType,
    ordersFiltersInfo?.allOrdersCount,
    ordersMoreLoading,
    selectedFilterTagsStringList,
  ])

  const handleStateIn = (status: number) => {
    stateIn.includes(status) ? setStateIn(stateIn.filter(s => s !== status)) : setStateIn([...stateIn, status])
  }

  const handleCustomerType = (customerTypeProp: number) => {
    customerType.includes(customerTypeProp)
      ? setCustomerType(customerType.filter(s => s !== customerTypeProp))
      : setCustomerType([...customerType, customerTypeProp])
  }

  // Function to handle tag selection
  const handleFilterTagSelection = (tagName: string) => {
    if (selectedFilterTagsStringList.includes(tagName)) {
      setSelectedFilterTagsStringList(selectedFilterTagsStringList.filter(tag => tag !== tagName))
    } else {
      setSelectedFilterTagsStringList([...selectedFilterTagsStringList, tagName])
    }
  }

  return (
    <>
      <ExportOrders
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        currentFilters={{
          stateIn: stateIn,
          search: search,
          customerType: customerType,
          selectedTags: selectedFilterTagsStringList,
        }}
      />
      <AllOrdersTable
        btnExportData={{
          onClick: () => setIsExportModalOpen(true),
        }}
        topActions={{
          props: {
            searchField: {
              value: search,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
              fieldPlaceholder: t({ id: 'order.archive.search-input.placeholder' }),
            },
          },
        }}
        filtersArchive={{
          props: {
            selectFilterOrderPlaced: {
              filterChecked: stateIn.includes(1),
              count: ordersFiltersInfo?.createdOrdersCount || 0,
              onClick: () => handleStateIn(1),
            },
            selectFilterAwaitingDelivery: {
              filterChecked: stateIn.includes(2),
              count: ordersFiltersInfo?.awaitingDeliveryOrdersCount || 0,
              onClick: () => handleStateIn(2),
            },
            selectFilterOrderDelivered: {
              filterChecked: stateIn.includes(3),
              count: ordersFiltersInfo?.deliveredOrdersCount || 0,
              onClick: () => handleStateIn(3),
            },
            selectFilterPaused: {
              filterChecked: stateIn.includes(6),
              count: ordersFiltersInfo?.pausedOrdersCount || 0,
              onClick: () => handleStateIn(6),
            },
            selectFilterCancelled: {
              filterChecked: stateIn.includes(4),
              count: ordersFiltersInfo?.cancelledOrdersCount || 0,
              onClick: () => handleStateIn(4),
            },
            selectFilterBusiness: {
              filterChecked: customerType.includes(1),
              count: ordersFiltersInfo?.businessOrdersCount || 0,
              onClick: () => handleCustomerType(1),
            },
            selectFilterResident: {
              filterChecked: customerType.includes(2),
              count: ordersFiltersInfo?.residentOrdersCount || 0,
              onClick: () => handleCustomerType(2),
            },
            filterTags: {
              noTags: !ordersFiltersInfo?.tagList || ordersFiltersInfo.tagList.length === 0,
              manageFilterButton: true,
              labelManageFilter: t({ id: 'settings.tags.manage' }),
              btnManageFilter: { onClick: () => navigate(MANAGE_TAG_PATH) },
              activeState: selectedFilterTagsStringList.length > 0,
              dropdown: (
                <>
                  {ordersFiltersInfo?.tagList?.map((tag: Maybe<FilterTagObjectInfo>) => (
                    <ButtonFilterDropdownRowCustom
                      key={tag?.name}
                      checkbox={{
                        isChecked: selectedFilterTagsStringList.includes(tag?.name || ''),
                        onChange: () => handleFilterTagSelection(tag?.name || ''),
                        children: tag?.name,
                      }}
                      count={tag?.count || '0'}
                    />
                  ))}
                </>
              ),
            },
            btnClearFilters: {
              onClick: () => {
                setStateIn([])
                setCustomerType([])
                setSelectedFilterTagsStringList([])
                setSearch('')
              },
            },
            hide: (
              <ButtonFilterDropdown
                align={'right'}
                title={t({ id: 'common.hide' }).capitalizeFirst()}
                icon={<EyeInvisiblesvgIcon />}
                manageFilterButton
                labelManageFilter={t({ id: 'common.show-all' })}
                manageFilterIcon={<EyesvgIcon />}
                btnManageFilter={{ onClick: () => setVisibleOrdersAllTableColumns(Object.values(AllOrdersTableColumn)) }}
                activeState={visibleOrdersAllTableColumns.length !== Object.values(AllOrdersTableColumn).length}
                dropdown={
                  <>
                    {Object.values(AllOrdersTableColumn).map(column => (
                      <ButtonFilterDropdownRowCustom
                        hideCount
                        key={`${column}column`}
                        checkbox={{
                          type: 'visibility',
                          isChecked: !visibleOrdersAllTableColumns.includes(column),
                          onChange: (checked: boolean) =>
                            setVisibleOrdersAllTableColumns(
                              checked ? visibleOrdersAllTableColumns.filter(el => el !== column) : [...new Set([...visibleOrdersAllTableColumns, column])]
                            ),
                          children: translateOrdersAllColumn(column, intl),
                        }}
                      />
                    ))}
                  </>
                }
              />
            ),
          },
        }}
        flexTableWrapper={{
          props: {
            tables: (
              <>
                <OrdersTable
                  count={allOrders.length || 0}
                  allCount={ordersFiltersInfo?.allOrdersCount || 0}
                  cylinderGroupOrders={allOrders as CylinderGroupOrderNode[]}
                  isLoading={ordersMoreLoading}
                  loadMore={handleFetchMoreOrders}
                />
                {!loading && allOrders.length === 0 && !search && stateIn.length === 0 && customerType.length === 0 && (
                  <NoHits>
                    <LabelStatusChip icon='check' title='No Orders' />
                  </NoHits>
                )}
                {!loading && allOrders.length === 0 && (search || stateIn.length !== 0 || customerType.length !== 0) && (
                  <NoHits>
                    <LabelStatusChip icon='error' title='No hits' />
                  </NoHits>
                )}
                {loading && allOrders.length === 0 && (
                  <CenteredLoader sizeAuto>
                    <Loader color='gray6' size={50} />
                  </CenteredLoader>
                )}
              </>
            ),
          },
        }}
      />
    </>
  )
}
