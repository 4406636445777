// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ArrowRightsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ArrowRightsvgIcon(props: ArrowRightsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 16.333 29.667"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M15.894 13.772L2.561.439A1.5 1.5 0 10.44 2.56l12.272 12.272L.439 27.106a1.5 1.5 0 102.122 2.121l13.333-13.333c.293-.293.439-.677.439-1.061 0-.384-.146-.768-.439-1.061z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ArrowRightsvgIcon;
/* prettier-ignore-end */
