// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type TriangleIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function TriangleIcon(props: TriangleIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 29.316 25.849"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M13.155.868L.235 23.245c-.668 1.157.167 2.604 1.503 2.604h25.839c1.336 0 2.172-1.447 1.503-2.604L16.161.868c-.668-1.157-2.338-1.157-3.006 0z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default TriangleIcon;
/* prettier-ignore-end */
