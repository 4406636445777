// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */

import * as React from "react";
import { createUseScreenVariants } from "@plasmicapp/react-web";

export type ActiveModulesValue = "payment";
export const ActiveModulesContext = React.createContext<
  ActiveModulesValue | undefined
>("PLEASE_RENDER_INSIDE_PROVIDER" as any);

export function useActiveModules() {
  return React.useContext(ActiveModulesContext);
}

export default ActiveModulesContext;
/* prettier-ignore-end */
