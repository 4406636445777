// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type AmexOutlinesvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function AmexOutlinesvgIcon(props: AmexOutlinesvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      data-name={"Payment Icons"}
      viewBox={"0 0 38 38"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M33 8a4 4 0 014 4v14a4 4 0 01-4 4H5a4 4 0 01-4-4V12a4 4 0 014-4h28m0-1H5a5 5 0 00-5 5v14a5 5 0 005 5h28a5 5 0 005-5V12a5 5 0 00-5-5z"
        }
      ></path>

      <path
        d={
          "M18.66 16.5H18l-1.49 3.19L15 16.5h-2v4.23l-1.92-4.23h-1.9L7 21.5h1.49l.45-1.11h2.34l.48 1.11h2.49v-3.61l1.67 3.61h1.21l1.53-3.31v3.31H20v-5h-1.34zm-9.25 2.75l.67-1.66.71 1.66zM31 16.5h-1.76L27.82 18l-1.39-1.5h-5.51v5h5.4l1.48-1.58 1.44 1.58H31L28.69 19zM25 21v-.65h-2.79v-.79H25v-1.13h-2.79v-.79H25v-.75L26.93 19z"
        }
      ></path>
    </svg>
  );
}

export default AmexOutlinesvgIcon;
/* prettier-ignore-end */
