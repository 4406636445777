import Margin from 'components/Margin'
import React, { forwardRef, MouseEvent } from 'react'
import styled from 'styled-components'
import { overloadColorProp } from 'util/style'
import { IOutlinedButton } from './OutlinedButton'

interface IIconWrapperProps {
  size?: number | string
  position?: string
  background?: string
  type?: string
}

const IconWrapper = styled.div<IIconWrapperProps>`
  display: inline-flex;
  width: ${props => props.size};
  height: ${props => props.size};

  justify-content: center;
  align-items: center;

  position: absolute;
  ${props => (props.position === 'left' ? 'left:0;' : 'right: 0;')}
`

interface IButtonProps {
  className?: string
  type?: 'button' | 'submit' | 'reset'
  height?: string
  children?: React.ReactNode

  icon?: string | React.ReactNode
  iconBackground?: string
  iconPosition?: string
  onClick?: (event: MouseEvent<HTMLButtonElement>) => any

  borderRadius?: string
  primary?: boolean
  success?: boolean
  error?: boolean

  color?: string

  textTransform?: string
  textColor?: string
  fontSize?: string
  fontWeight?: string | number

  width?: string
  maxWidth?: string
  reset?: boolean
  disabled?: boolean

  position?: string
  bottom?: string
  active?: boolean
  zIndex?: number
}

const StyledButton = styled.button<IButtonProps>`
  outline: none;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  -webkit-font-smoothing: antialised;
  border-radius: ${props => props.borderRadius};
  background-color: #fff;
  color: ${props => props.theme.colors.gray7};
  background-color: ${overloadColorProp};
  color: ${props => overloadColorProp(props, 'textColor')};
  ${props =>
    props.primary &&
    `
    background-color: ${props.theme.colors.primary};
    color: white;
  `}
  ${props =>
    props.success &&
    `
    background-color: ${props.theme.colors.green};
    color: white;
  `}
  ${props =>
    props.error &&
    `
    background-color: ${props.theme.colors.red};
    color: white;
  `}
  text-transform: ${props => props.textTransform};
  font-family: 'Proxima Nova', sans-serif;
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  ${props => (props.icon ? (props.iconPosition === 'left' ? 'padding: 0 1em 0 0;' : 'padding: 0 0 0 1em;') : 'padding: 0 2em;')}
  width: ${props => props.width};
  max-width: ${props => props.maxWidth};
  height: ${props => props.height};

  cursor: pointer;
  ${props => (props.position ? `position: ${props.position};` : 'position: relative;')}

  ${props =>
    props.bottom &&
    `
    bottom: ${props.bottom};
  `}

  appearance: none;
  overflow: hidden;
  user-select: none;

  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(0.9);
  }

  ${props =>
    props.reset &&
    `
    background-color: inherit;
    color: inherit;
    border: none;
    border-radius: inherit;
    box-shadow: none;
  `}

  /* Disabled buttons */
  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    filter: grayscale(100%) contrast(70%);
    :hover{
      filter: grayscale(100%) contrast(70%);
    }
  `}

  &:disabled {
    position: relative;
    bottom: 0;
    filter: none;
    background-color: ${props => props.theme.colors.primaryLighter};
    color: rgba(255, 255, 255, 0.5);
  }

  ${props =>
    props.zIndex &&
    `
    z-index: ${props.zIndex};
  `}
`

StyledButton.defaultProps = {
  primary: false,
  width: '300px',
  maxWidth: '100%',
  height: '50px',
  type: 'submit',
  fontWeight: 600,
  fontSize: '12px',
  iconPosition: 'left',
  iconBackground: 'rgba(0, 0, 0, 0.3)',
  disabled: false,
  textColor: 'white',
  textTransform: 'uppercase',
  borderRadius: '4px',
}

interface ButtonTypes {
  Outlined?: React.FC<IOutlinedButton>
  Tabs?: React.FC
  Tab?: React.FC
  Light?: React.FC
}

const Button = forwardRef<HTMLButtonElement, IButtonProps & ButtonTypes>(
  ({ className, children, type, icon, height, iconBackground, iconPosition, onClick, active, disabled, zIndex, ...props }, ref) => {
    return (
      <StyledButton ref={ref} className={className} type={type} onClick={onClick} height={height} disabled={disabled} zIndex={zIndex} {...props}>
        {icon && iconPosition === 'left' ? (
          <IconWrapper size={height} background={iconBackground} position={iconPosition}>
            {icon}
          </IconWrapper>
        ) : null}
        {icon && !iconPosition && <Margin right='1rem'>{icon}</Margin>}
        {children}
        {icon && iconPosition === 'right' ? (
          <IconWrapper size={height} background={iconBackground} position={iconPosition}>
            {icon}
          </IconWrapper>
        ) : null}
      </StyledButton>
    )
  }
)

export default Button
