import React from 'react'
import * as GQL from 'generated/graphql'
import styled from 'styled-components'

const Cell = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.5em 0.5em;
`
interface IBottleProps {
  color?: string
}

const Bottle = styled.div<IBottleProps>`
  position: relative;
  width: 3px;
  height: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
  border-radius: 500px;
  background-color: ${props => props.color || 'white'};
  background-repeat: repeat;
  margin: 1px;
`

interface StockCellProps {
  cylinderGroup: GQL.CylinderGroupNode
}

const StockCell: React.FC<StockCellProps> = ({ cylinderGroup }) => {
  const stock =
    cylinderGroup.stock ||
    ({
      currentEmpty: 0,
      currentFull: 0,
    } as Pick<GQL.StockNode, 'currentFull' | 'currentEmpty'>)
  const sides = cylinderGroup.cylinderSides?.filter(side => !!side?.activeCycle) || []

  /*
    if (!stock) {
        return <Cell><i>No stock</i></Cell>
    }
    */
  const cycleEmpty = sides.filter(side => [GQL.CycleState.Empty].includes(side?.activeCycle?.state!)).reduce((a, b) => b?.cylinderCount! + a, 0)
  const cycleNotEmpty = sides.filter(side => ![GQL.CycleState.Empty].includes(side?.activeCycle?.state!)).reduce((a, b) => b?.cylinderCount! + a, 0)

  const unknown = cylinderGroup.cylinderSides?.filter(side => !!!side?.activeCycle).reduce((a, b) => a + b?.cylinderCount!, 0) || 0

  return (
    <Cell>
      <div style={{ marginRight: '0', color: '#fff', minWidth: '1.5em', textAlign: 'left' }}>{cycleEmpty + Math.max(stock.currentEmpty)}</div>
      <div
        style={{
          overflow: 'hidden',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-end',
          position: 'relative',
          alignItems: 'center',
        }}
      >
        {[...Array(cycleEmpty + Math.max(stock.currentEmpty, 0)).keys()].map(i => (
          <div key={i}>
            <Bottle color={'#181D36'} />
          </div>
        ))}
        {[...Array(unknown).keys()].map(i => (
          <div key={i}>
            <Bottle color={'rgba(255, 255, 255, 0.288)'} />
          </div>
        ))}
        {[...Array(cycleNotEmpty + Math.max(stock.currentFull, 0)).keys()].map(i => (
          <div key={i}>
            <Bottle color={'#2c97de'} />
          </div>
        ))}
      </div>
      <div style={{ marginLeft: '0', color: '#fff', minWidth: '1.5em', textAlign: 'right' }}>{cycleNotEmpty + stock.currentFull + unknown}</div>
    </Cell>
  )
}

export default StockCell
