// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type InventoryInStocksvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function InventoryInStocksvgIcon(props: InventoryInStocksvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 18.459 18.431"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M7.588.436l-5.924 3.39A3.306 3.306 0 000 6.695v10.532c0 .665.539 1.204 1.204 1.204h2.668c.665 0 1.204-.539 1.204-1.204v-6.91c0-.436.353-.789.789-.789h6.728c.436 0 .789.353.789.789v6.91c0 .665.539 1.204 1.204 1.204h2.668c.665 0 1.204-.539 1.204-1.204V6.695c0-1.185-.635-2.28-1.664-2.869L10.87.436a3.308 3.308 0 00-3.282 0z"
        }
      ></path>
    </svg>
  );
}

export default InventoryInStocksvgIcon;
/* prettier-ignore-end */
