import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import * as GQL from 'generated/graphql'

import { format } from 'date-fns'
import RoutesMapDrawer from '../Routes/RoutesMapDrawer'
import FlexTable from 'plasmic/FlexTable'
import TableRow from 'plasmic/TableRow'
import CellCustomer from 'plasmic/CellCustomer'
import TableHeadTitle from 'plasmic/TableHeadTitle'
import FlexTableWrapper from 'plasmic/FlexTableWrapper'
import CellDistance from 'plasmic/CellDistance'
import CellProgress from 'plasmic/CellProgress'
import CellDriver from 'plasmic/CellDriver'
import CellVehicle from 'plasmic/CellVehicle'
import CellCylinders from 'plasmic/CellCylinders'
import CellWindow from 'plasmic/CellWindow'
import RouteTable from 'plasmic/RouteTable'
import ScrollIndicator from 'plasmic/ScrollIndicator'
import Loader from 'components/Loader'
import { CenteredLoader } from 'components/Loader/LoaderWrappers'

const ProgressContainer = styled.div<{ progress: number }>`
  border: 1px solid rgb(0, 212, 166, 0.3);
  border-radius: 4px;
  display: flex;
  width: 100px;
  justify-content: flex-start;
  height: 30px;
  position: relative;

  &:after {
    content: '';
    width: ${props => props.progress}%;
    background: rgb(0, 212, 166, 0.3);
    display: block;
    font-style: normal;
    padding: 0.5em;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    box-sizing: border-box;
    overflow: visible;
    white-space: nowrap;
  }

  span {
    position: absolute;
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
`

const RouteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`

const Back = styled.div`
  display: flex;
  font-size: 24px;
  /* margin-bottom: 2em; */
  padding: 1rem;
  cursor: pointer;
  width: 150px;
`

interface IRoute {
  route: GQL.RouteNode
  routes: GQL.RouteNode[]
  drivers: GQL.DriverNode[]
  back?: () => void
}

export const Route = ({ route, routes, drivers, back }: IRoute) => {
  return (
    <RouteWrapper>
      {back && <Back onClick={() => back()}> &#8592; Go back</Back>}
      <RoutesMapDrawer routes={[route as GQL.RouteNode]} />
      <RouteTable drivers={drivers} routes={routes as GQL.RouteNode[]} route={route as GQL.RouteNode} />
    </RouteWrapper>
  )
}

interface IPlannedRoutes {
  drivers: GQL.DriverNode[]
}

const PAGE_SIZE = 15

const PlannedRoutes: React.FC<IPlannedRoutes> = ({ drivers }) => {
  const [route, setRoute] = useState<GQL.RouteNode>()
  const [loadFetchMore, setLoadFetchMore] = useState(false)

  const {
    data: upcomingRoutes,
    loading: loadingUpcomingRoutes,
    fetchMore,
  } = GQL.useAllRoutesFooter({
    variables: {
      statusNotIn: [GQL.RouteStatus.Completed.toLowerCase(), GQL.RouteStatus.Cancelled.toLowerCase()],
      dateGte: format(new Date(), 'yyyy-MM-dd'),
      first: PAGE_SIZE,
    },
  })

  const routes = useMemo(() => {
    return upcomingRoutes?.allRoutesFooter?.edges?.map(edge => edge?.node as GQL.RouteNode) || []
  }, [upcomingRoutes])

  const pageInfo = useMemo(() => {
    return upcomingRoutes?.allRoutesFooter?.pageInfo
  }, [upcomingRoutes])

  if (route) {
    return <Route drivers={drivers} routes={routes} route={route} back={() => setRoute(undefined)} />
  }

  return (
    <FlexTableWrapper
      tables={
        <FlexTable
          noCheckbox
          noSorting
          children={<TableHeadTitle title='Scheduled Routes' count={`(${upcomingRoutes?.allRoutesFooter?.totalCount || routes.length})`} />}
          rows={
            <>
              {loadingUpcomingRoutes ? (
                <CenteredLoader sizeAuto>
                  <Loader color='gray6' size={32} />
                </CenteredLoader>
              ) : (
                routes.map((r, num) => {
                  const distance = r.totalDrivingDistance !== null ? `${Math.round(r.totalDrivingDistance! / 1000)} km` : 'N/A'

                  return (
                    <TableRow
                      noCheckbox
                      key={r.id + 'TableRowCustomers' + num}
                      sticky={<CellCustomer name={r.date} onClick={() => setRoute(r)} />}
                      scrollCells={
                        <>
                          <CellDriver content={r.driver?.user.fullName} />

                          <CellVehicle content={`${r.vehicle?.name} (${r.vehicle?.registrationNumber})`} />
                          <CellDistance content={distance} />
                          <CellCylinders content={r.totalCylindersToDeliver} />
                          <CellProgress
                            content={
                              <ProgressContainer progress={r.driverTimePercentageEffort || 0}>
                                <span>{r.driverTimePercentageEffort || 0} %</span>
                              </ProgressContainer>
                            }
                          />
                          <CellProgress
                            content={
                              <ProgressContainer progress={r.percentageLoad || 0}>
                                <span>{r.percentageLoad || 0} %</span>
                              </ProgressContainer>
                            }
                          />
                          <CellWindow content={`${format(new Date(r.startTime), 'HH:mm')} - ${format(new Date(r.endTime), 'HH:mm')}`} />
                        </>
                      }
                    />
                  )
                })
              )}

              {!loadingUpcomingRoutes && (
                <ScrollIndicator
                  tableRow
                  loaded={routes.length.toString()}
                  total={(upcomingRoutes?.allRoutesFooter?.totalCount || routes.length).toString()}
                  btnLoadMore={{
                    ...(loadFetchMore && { children: <Loader color='white' /> }),
                    onClick: () => {
                      if (pageInfo?.hasNextPage && pageInfo?.endCursor) {
                        setLoadFetchMore(true)
                        fetchMore({
                          variables: {
                            first: PAGE_SIZE,
                            after: pageInfo.endCursor,
                          },
                          updateQuery(prev, { fetchMoreResult }) {
                            if (!fetchMoreResult) return prev
                            return {
                              ...fetchMoreResult,
                              allRoutesFooter: {
                                ...fetchMoreResult?.allRoutesFooter,
                                edges: [
                                  ...(prev?.allRoutesFooter?.edges ? prev.allRoutesFooter.edges : []),
                                  ...(fetchMoreResult?.allRoutesFooter?.edges ? fetchMoreResult.allRoutesFooter.edges : []),
                                ],
                              },
                            } as GQL.AllRoutesFooter
                          },
                        }).finally(() => {
                          setLoadFetchMore(false)
                        })
                      }
                    },
                  }}
                />
              )}
            </>
          }
          visibleColumns={['vehicle', 'driver', 'distance', 'time', 'capacity', 'cylinders', 'window']}
        />
      }
    />
  )
}

export default PlannedRoutes
