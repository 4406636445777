import React from 'react'
import { useIntl } from 'react-intl'

import * as GQL from 'generated/graphql'
import Modal from 'components/Modal/Modal'
import ModalConfirm from 'plasmic/ModalConfirm'
import { displayToast } from 'util/toasts'

interface Props {
  open: boolean
  product: GQL.ProductNode
  isService?: boolean
  onClose: () => void
}

const ChangeProductStatusModal: React.FC<Props> = props => {
  const intl = useIntl()
  const t = intl.formatMessage

  const [patchProduct] = GQL.usePatchProduct({
    refetchQueries: ['AllProducts'],
    onCompleted: () => {
      displayToast(t({ id: props.isService ? 'settings.services.change.status.success' : 'settings.products.change.status.success' }), 'success')
    },
    onError: () => {
      displayToast(t({ id: props.isService ? 'settings.services.change.status.error' : 'settings.products.change.status.error' }))
    },
  })

  const handlePatchProduct = () => {
    if (!props.product) return
    patchProduct({
      variables: {
        id: props.product.id,
        input: {
          status: props.product.status === GQL.ProductStatus.Active ? GQL.ProductStatus.Inactive : GQL.ProductStatus.Active,
        },
      },
    })
    props.onClose()
  }

  return (
    <Modal
      isOpen={props.open}
      onRequestClose={() => props.onClose()}
      stripped
      contentStyle={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      overlayStyle={{ alignItems: 'flex-start', overflow: 'auto', padding: '2rem 0rem' }}
    >
      <ModalConfirm
        title={t({ id: props.isService ? 'settings.services.change.status.modal.title' : 'settings.products.change.status.modal.title' })}
        description={t(
          { id: props.isService ? 'settings.services.change.status.modal.description' : 'settings.products.change.status.modal.description' },
          {
            product: props.product.displayName,
            status:
              props.product?.status === GQL.ProductStatus.Active ? GQL.ProductStatus.Inactive.capitalizeFirst() : GQL.ProductStatus.Active.capitalizeFirst(),
          }
        )}
        btnConfirm={{
          props: {
            onClick: () => handlePatchProduct(),
          },
        }}
        btnCancel={{
          props: {
            onClick: () => props.onClose(),
          },
        }}
        btnClose={{
          props: {
            onClick: () => props.onClose(),
          },
        }}
        btnColor={props.product?.status === GQL.ProductStatus.Inactive ? 'green' : 'red'}
      />
    </Modal>
  )
}

export default ChangeProductStatusModal
