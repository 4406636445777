// eslint-disable-next-line
import { useState, useRef, useEffect } from 'react'
import { OperationVariables, useApolloClient } from '@apollo/client'
import * as Apollo from '@apollo/client'
import _ from 'lodash'

interface CancellableReturn<T, TVariables> {
  data?: T
  error: Apollo.ApolloError | null
  loading: boolean
  clearError: () => void
  refetch: () => void
  fetchMore: (fetchMoreOptions: Apollo.FetchMoreQueryOptions<TVariables, T> & Apollo.FetchMoreOptions<T, TVariables>) => Promise<Apollo.ApolloQueryResult<T>>
}

export const useCancellableQuery = <T, TVariables extends OperationVariables>(
  query: Apollo.DocumentNode,
  variables: TVariables,
  deps: Array<any>,
  fetchPolicy?: Apollo.WatchQueryFetchPolicy,
  nextFetchPolicy?: Apollo.WatchQueryFetchPolicy,
  pollInterval?: number
): CancellableReturn<T, TVariables> => {
  const observable = useRef<Apollo.ObservableQuery<T, TVariables> | null>(null)

  const apolloClient = useApolloClient()
  const [vars, setVars] = useState<TVariables>(variables)
  const [data, setData] = useState<T>()
  const [error, setError] = useState<Apollo.ApolloError | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [reload, setReload] = useState<boolean>(true)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(
    () => {
      setLoading(true)

      const watchedQuery = apolloClient.watchQuery<T, TVariables>({
        query,
        variables,
        pollInterval,
        fetchPolicy: fetchPolicy, // cache-first
        nextFetchPolicy: nextFetchPolicy, //'cache-first'
        context: {
          queryDeduplication: false,
        },
      })
      observable.current = watchedQuery

      const sub = watchedQuery.subscribe({
        next(x) {
          if (!x.partial) {
            setData(x.data)
            setError(null)
            setLoading(x.loading)
          }
        },
        error(err) {
          setError(err)
          setLoading(false)
        },
        complete() {
          setLoading(false)
        },
      })

      return () => {
        sub.unsubscribe()
      }
    },
    deps.concat([reload, vars])
  )

  useEffect(() => {
    !_.isEqual(variables, vars) && setVars(variables)
  }, [variables])

  return {
    data,
    error,
    loading,
    clearError: () => setError(null),
    refetch: () => {
      setReload(val => !val)
      //observable.current?.refetch(variables),
    },
    fetchMore: (fetchMoreOptions: Apollo.FetchMoreQueryOptions<TVariables, T> & Apollo.FetchMoreOptions<T, TVariables>) => {
      return (
        observable.current?.fetchMore(fetchMoreOptions) ||
        new Promise<Apollo.ApolloQueryResult<T>>((resolve, reject) => {
          reject('Missing')
        })
      )
    },
  }
}
