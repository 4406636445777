import React, { useRef, useState } from 'react'
import { PlasmicModalUpload } from './plasmic/solace_components/PlasmicModalUpload'
import { displayToast } from '../util/toasts'
import { useIntl } from 'react-intl'

interface ModalUploadProps {
  btnDownloadTemplate?: any
  btnOpenUploadDialog?: any
  onUpload: (file: File) => void
  validFileTypes: string[]
  onClose: () => void
}

function ModalUpload_(props: ModalUploadProps, ref: React.Ref<any>) {
  const intl = useIntl()
  const t = intl.formatMessage

  const [labelUpload, setLabelUpload] = useState<string>(t({ id: 'common.upload.label' }, { maxMB: 4 }))
  const [dragOver, setDragOver] = useState<boolean>(false)
  const [notAllowed, setNotAllowed] = useState<boolean>(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleUploadClick = () => {
    fileInputRef.current!.click() // Trigger the file input click event
  }

  function handleDragOver(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault()
    setDragOver(true)

    const isFileValid = Array.from(event.dataTransfer.items).some(item => props.validFileTypes.includes(item.type))

    if (!isFileValid) {
      event.dataTransfer.dropEffect = 'none' // Disallow the drop
      setLabelUpload(t({ id: 'common.upload.error.wrong-format' }, { allowedFormats: '.CSV, XLS and .XLSX' }))
      setNotAllowed(true)
      displayToast(t({ id: 'common.upload.error.wrong-format' }, { allowedFormats: '.CSV, XLS and .XLSX' }))
      return
    }
    setLabelUpload(t({ id: 'common.upload.label' }, { maxMB: 4 }))
  }

  function handleLeave(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault()
    setDragOver(false)
    setNotAllowed(false)
    setLabelUpload(t({ id: 'common.upload.label' }, { maxMB: 4 }))
  }

  function handleDrop(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault()
    if (event.dataTransfer.files) {
      const file = event.dataTransfer.files[0]
      props.onUpload(file)
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]
      props.onUpload(file)
    }
  }

  return (
    <>
      <PlasmicModalUpload
        root={{ ref }}
        btnClose={{
          props: {
            onClick: () => props.onClose(),
          },
        }}
        btnDownloadTemplate={props.btnDownloadTemplate}
        btnOpenUploadDialog={{
          props: {
            dropActive: dragOver && !notAllowed,
            notAllowed: notAllowed,
            labelUpload: labelUpload,
            onDragOver: (e: React.DragEvent<HTMLDivElement>) => handleDragOver(e),
            onDrop: (e: React.DragEvent<HTMLDivElement>) => handleDrop(e),
            onDragLeave: (e: React.DragEvent<HTMLDivElement>) => handleLeave(e),
            onClick: () => handleUploadClick(),
          },
        }}
      />
      <input type='file' accept='.csv, .xls, .xlsx' style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileChange} />
    </>
  )
}

const ModalUpload = React.forwardRef(ModalUpload_)

export default ModalUpload
