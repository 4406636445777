import React from 'react'
import * as GQL from 'generated/graphql'

export interface IAppContext {
  depot?: GQL.DepotNode | null
  distributor?: GQL.DistributorNode | null
  user?: GQL.UserNode | null
}

interface IDepotContext {
  appContext: IAppContext
  setAppContext: (appContext: IAppContext | ((context: IAppContext) => IAppContext)) => void
}

const AppContext = React.createContext<IDepotContext>({
  appContext: {},
  setAppContext: (appContext: IAppContext | ((context: IAppContext) => IAppContext)) => {},
})

export default AppContext
