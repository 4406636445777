// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ToastInfosvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ToastInfosvgIcon(props: ToastInfosvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 32.727 32.727"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M16.364 0C7.326 0 0 7.326 0 16.364c0 9.037 7.326 16.364 16.364 16.364s16.364-7.326 16.364-16.364C32.727 7.326 25.401 0 16.364 0zm2.167 24.282a.528.528 0 01-.528.528h-3.279a.528.528 0 01-.528-.528v-.278c0-.292.237-.528.528-.528h.18v-8.592h-.18a.528.528 0 01-.528-.528v-.278c0-.292.237-.528.528-.528h2.571c.292 0 .528.237.528.528v9.399h.18c.292 0 .528.237.528.528v.277zm-2.167-12.03a2.168 2.168 0 110-4.337 2.168 2.168 0 010 4.337z"
        }
      ></path>
    </svg>
  );
}

export default ToastInfosvgIcon;
/* prettier-ignore-end */
