import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { CustomerDomainType, CylinderGroupMode, DistributorTagNode, GasTypeEnum } from 'generated/graphql'
import { Status } from 'modules/customers/Customers'
import { PlasmicFilters } from './plasmic/solace_components/PlasmicFilters'
import ButtonFilterDropdownRowCustom from './ButtonFilterDropdownRowCustom'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
interface Filters {
  [key: string]: boolean
}

const MANAGE_TAG_PATH = '/settings/tags'

const Filters: React.ForwardRefRenderFunction<any, any> = (props, ref) => {
  const intl = useIntl()
  const t = intl.formatMessage
  const navigate = useNavigate()
  const { state } = useLocation()

  const { availableFilterTags, onFilterTagsChange, selectedFilterTags } = props
  const [selectedFilterTagsFilter, setselectedFilterTagsFilter] = useState(() => {
    // Initialize selectedFilterTagsFilter with the values from selectedFilterTags
    return availableFilterTags.map((tag: DistributorTagNode) => selectedFilterTags.includes(tag.name))
  })

  const [initialSelectedTag, setInitialSelectedTag] = useState(state?.initialSelectedTag)

  const handleSelectFilterBusinessClick = () => {
    if (props.customerType.includes(CustomerDomainType.Business)) {
      props.onCustomerTypeChange(props.customerType.filter((type: CustomerDomainType) => type !== CustomerDomainType.Business))
    } else {
      props.onCustomerTypeChange([...props.customerType, CustomerDomainType.Business])
    }
  }

  const handleSelectFilterResidentClick = () => {
    if (props.customerType.includes(CustomerDomainType.Resident)) {
      props.onCustomerTypeChange(props.customerType.filter((type: CustomerDomainType) => type !== CustomerDomainType.Resident))
    } else {
      props.onCustomerTypeChange([...props.customerType, CustomerDomainType.Resident])
    }
  }

  const handleSelectFilterPropaneClick = () => {
    if (props.gasType.includes(GasTypeEnum.Propane)) {
      props.onGasTypeChange(props.gasType.filter((type: GasTypeEnum) => type !== GasTypeEnum.Propane))
    } else {
      props.onGasTypeChange([...props.gasType, GasTypeEnum.Propane])
    }
  }

  const handleSelectFilterCo2Click = () => {
    if (props.gasType.includes(GasTypeEnum.Co2)) {
      props.onGasTypeChange(props.gasType.filter((type: GasTypeEnum) => type !== GasTypeEnum.Co2))
    } else {
      props.onGasTypeChange([...props.gasType, GasTypeEnum.Co2])
    }
  }

  const handleSelectFilterStandardClick = () => {
    if (props.setupStyle.includes(CylinderGroupMode.Standard)) {
      props.onSetupStyleChange(props.setupStyle.filter((style: CylinderGroupMode) => style !== CylinderGroupMode.Standard))
    } else {
      props.onSetupStyleChange([...props.setupStyle, CylinderGroupMode.Standard])
    }
  }

  const handleSelectFilterParallelClick = () => {
    if (props.setupStyle.includes(CylinderGroupMode.Parallel)) {
      props.onSetupStyleChange(props.setupStyle.filter((style: CylinderGroupMode) => style !== CylinderGroupMode.Parallel))
    } else {
      props.onSetupStyleChange([...props.setupStyle, CylinderGroupMode.Parallel])
    }
  }

  const handleSelectFilterInsituClick = () => {
    if (props.setupStyle.includes(CylinderGroupMode.Insitu)) {
      props.onSetupStyleChange(props.setupStyle.filter((style: CylinderGroupMode) => style !== CylinderGroupMode.Insitu))
    } else {
      props.onSetupStyleChange([...props.setupStyle, CylinderGroupMode.Insitu])
    }
  }

  const handleSelectFilterEmptyCylindersClick = () => {
    if (props.status.includes(Status.EmptyCylinders)) {
      props.onStatusChange(props.status.filter((status: Status) => status !== Status.EmptyCylinders))
    } else {
      props.onStatusChange([...props.status, Status.EmptyCylinders])
    }
  }

  const handleSelectFilterOrderedCylindersClick = () => {
    if (props.status.includes(Status.OrderedCylinders)) {
      props.onStatusChange(props.status.filter((status: Status) => status !== Status.OrderedCylinders))
    } else {
      props.onStatusChange([...props.status, Status.OrderedCylinders])
    }
  }

  const handleSelectFilterAwaitingDeliveryClick = () => {
    if (props.status.includes(Status.AwaitingDelivery)) {
      props.onStatusChange(props.status.filter((status: Status) => status !== Status.AwaitingDelivery))
    } else {
      props.onStatusChange([...props.status, Status.AwaitingDelivery])
    }
  }

  useEffect(() => {
    // Handle initialSelectedTag when it exists
    if (initialSelectedTag) {
      const index = availableFilterTags.findIndex((tag: DistributorTagNode) => tag.name === initialSelectedTag)
      if (index !== -1) {
        const newselectedFilterTags = availableFilterTags.map((_: DistributorTagNode, idx: number) => idx === index)
        setselectedFilterTagsFilter(newselectedFilterTags)

        // Update selectedTagNames based on the newselectedFilterTags
        const updatedSelectedTagNames = availableFilterTags
          .filter((_: DistributorTagNode, idx: number) => newselectedFilterTags[idx])
          .map((tag: DistributorTagNode) => tag.name)

        onFilterTagsChange(updatedSelectedTagNames)
        // Clear initialSelectedTag after handling it
        setInitialSelectedTag(null)
      }
    } else {
      // Reset selectedFilterTagsFilter based on the current selectedFilterTags
      setselectedFilterTagsFilter(availableFilterTags.map((tag: DistributorTagNode) => selectedFilterTags.includes(tag.name)))
    }
  }, [initialSelectedTag, availableFilterTags, onFilterTagsChange, selectedFilterTags])

  const handleOnFilterTagsChange = (idx: number) => (value: boolean) => {
    const newselectedFilterTagsFilter = [...selectedFilterTagsFilter]
    newselectedFilterTagsFilter[idx] = value
    setselectedFilterTagsFilter(newselectedFilterTagsFilter)

    // Update selectedTagNames based on the newselectedFilterTagsFilter
    const updatedSelectedTagNames = availableFilterTags
      .filter((_: DistributorTagNode, idx: number) => newselectedFilterTagsFilter[idx])
      .map((tag: DistributorTagNode) => tag.name)

    onFilterTagsChange(updatedSelectedTagNames)
  }

  return (
    <PlasmicFilters
      root={ref}
      {...props}
      selectFilterBusiness={{
        filterChecked: props.customerType.includes(CustomerDomainType.Business),
        count: props.businessCustomersCount,
        onClick: handleSelectFilterBusinessClick,
      }}
      selectFilterResident={{
        filterChecked: props.customerType.includes(CustomerDomainType.Resident),
        count: props.residentCustomersCount,
        onClick: handleSelectFilterResidentClick,
      }}
      selectFilterPropane={{ filterChecked: props.gasType.includes(GasTypeEnum.Propane), count: props.propaneCount, onClick: handleSelectFilterPropaneClick }}
      selectFilterCo2={{ filterChecked: props.gasType.includes(GasTypeEnum.Co2), count: props.co2Count, onClick: handleSelectFilterCo2Click }}
      selectFilterStandard={{
        filterChecked: props.setupStyle.includes(CylinderGroupMode.Standard),
        count: props.standardSetupCount,
        onClick: handleSelectFilterStandardClick,
      }}
      selectFilterParallel={{
        filterChecked: props.setupStyle.includes(CylinderGroupMode.Parallel),
        count: props.parallelSetupCount,
        onClick: handleSelectFilterParallelClick,
      }}
      selectFilterInsitu={{
        filterChecked: props.setupStyle.includes(CylinderGroupMode.Insitu),
        count: props.insituSetupCount,
        onClick: handleSelectFilterInsituClick,
      }}
      selectFilterEmptyCylinders={{
        filterChecked: props.status.includes(Status.EmptyCylinders),
        count: props.emptyCylindersCount,
        onClick: handleSelectFilterEmptyCylindersClick,
      }}
      selectFilterOrderedCylinders={{
        filterChecked: props.status.includes(Status.OrderedCylinders),
        count: props.orderedCylindersCount,
        onClick: handleSelectFilterOrderedCylindersClick,
      }}
      selectFilterAwaitingDelivery={{
        filterChecked: props.status.includes(Status.AwaitingDelivery),
        count: props.awaitingDeliveryCount,
        onClick: handleSelectFilterAwaitingDeliveryClick,
      }}
      filterTags={{
        noTags: props.availableFilterTags.length === 0,
        manageFilterButton: true,
        labelManageFilter: t({ id: 'settings.tags.manage' }),
        btnManageFilter: { onClick: () => navigate(MANAGE_TAG_PATH) },
        activeState: selectedFilterTags.length > 0,
        dropdown: (
          <>
            {props.availableFilterTags?.map((tag: DistributorTagNode, idx: number) => (
              <ButtonFilterDropdownRowCustom
                key={tag.name}
                checkbox={{
                  isChecked: selectedFilterTags.includes(tag.name),
                  onChange: handleOnFilterTagsChange(idx),
                  children: tag.name,
                }}
                count={tag?.customerCount || '0'}
              />
            ))}
          </>
        ),
      }}
      btnClearFilters={{ onClick: props.onClearFilters }}
    />
  )
}

export default React.forwardRef(Filters)
