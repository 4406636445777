// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type IconEmailsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function IconEmailsvgIcon(props: IconEmailsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 16 12"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M1.352.917c-.154 0-.3.032-.434.086L7.554 6.8c.287.25.592.25.88 0l6.648-5.798a1.178 1.178 0 00-.434-.086H1.352zM.191 1.925a1.281 1.281 0 00-.012.165v7.82c0 .65.523 1.174 1.173 1.174h13.295A1.17 1.17 0 0015.82 9.91V2.09c0-.056-.005-.111-.012-.165L9.203 7.687a1.848 1.848 0 01-2.42 0L.191 1.925z"
        }
      ></path>
    </svg>
  );
}

export default IconEmailsvgIcon;
/* prettier-ignore-end */
