// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicTooltipEstimatedEmpty, DefaultTooltipEstimatedEmptyProps } from './plasmic/solace_components/PlasmicTooltipEstimatedEmpty'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface TooltipEstimatedEmptyProps extends Omit<DefaultTooltipEstimatedEmptyProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultTooltipEstimatedEmptyProps altogether and have
// total control over the props for your component.
export interface TooltipEstimatedEmptyProps extends DefaultTooltipEstimatedEmptyProps {}

function TooltipEstimatedEmpty_(props: TooltipEstimatedEmptyProps, ref: HTMLElementRefOf<'div'>) {
  const [toggleSensor, setToggleSensor] = React.useState(true)
  const [toggleDepleting, setToggleDepleting] = React.useState(true)

  const dummySide = {
    daysRemaining: 8,
    progressLabel: 'x days left',
    activeCycleRemainingPercentage: 50,
    depleting: toggleDepleting,
    monitored: toggleSensor,
    percentageLabel: 'y %',
  }

  return (
    <PlasmicTooltipEstimatedEmpty
      root={{ ref }}
      {...props}
      progressBarPercent={{
        hidePercent: !toggleSensor,
        side: dummySide,
      }}
      toggleSensor={{
        right: toggleSensor,
        toggleLeft: { onClick: () => setToggleSensor(false) },
        toggleRight: { onClick: () => setToggleSensor(true) },
      }}
      toggleDepleting={{
        right: toggleDepleting,
        toggleLeft: { onClick: () => setToggleDepleting(false) },
        toggleRight: { onClick: () => setToggleDepleting(true) },
      }}
    />
  )
}

const TooltipEstimatedEmpty = React.forwardRef(TooltipEstimatedEmpty_)
export default TooltipEstimatedEmpty
