import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Modal from 'components/Modal/Modal'
import ModalCreateNewTag from 'plasmic/ModalCreateNewTag'
import Loader from 'components/Loader'

interface TagModalProps {
  data?: any
  isOpen: boolean
  loading?: boolean
  onSubmit: (data: DistributorTagFormData) => void
  onRequestClose: () => void
}

export interface DistributorTagFormData {
  name: string
  id?: string
}

const TagModal: React.FC<TagModalProps> = ({ data, isOpen, loading = false, onSubmit, onRequestClose }) => {
  const intl = useIntl()
  const t = intl.formatMessage

  const [distributorTagData, setDistributorTagData] = useState({
    name: data?.name || '',
    id: data?.id || '',
  })

  const [formErrors, setFormErrors] = useState({
    name: (data?.name && data?.name?.length < 3) || false,
    showErrors: false,
  })

  const handleSubmit = () => {
    setFormErrors({ ...formErrors, showErrors: false })
    if (formErrors.name || distributorTagData.name.length < 3) {
      setFormErrors({ ...formErrors, name: true, showErrors: true })
      return
    }
    onSubmit({ ...distributorTagData })
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      stripped
      overlayStyle={{ alignItems: 'flex-start', overflow: 'auto', padding: '2rem 0rem' }}
      contentStyle={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <ModalCreateNewTag
        btnClose={{
          props: {
            onClick: () => onRequestClose(),
          },
        }}
        title={t({ id: data?.create ? 'settings.tags.create' : 'settings.tags.patch' }) + (data?.name ? `: ${data.name}` : '')}
        inputTag={{
          props: {
            alert: distributorTagData.name?.length < 3,
            error: formErrors.showErrors && formErrors.name,
            fieldValue: distributorTagData.name,
            undefinedInput: {
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                const newName = e.target.value
                setDistributorTagData({ ...distributorTagData, name: newName })
                if (newName.length < 3) {
                  setFormErrors({ ...formErrors, name: true })
                } else {
                  setFormErrors({ ...formErrors, name: false })
                }
              },
            },
          },
        }}
        btnCreateTag={{
          props: {
            label: loading ? <Loader color='white' /> : <FormattedMessage id={data?.create ? 'settings.tags.save-new' : 'settings.tags.save-edit'} />,
            root: {
              onClick: handleSubmit,
            },
          },
        }}
      />
    </Modal>
  )
}

export default TagModal
