import React, { FunctionComponent, MutableRefObject } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { ReactComponent as AppDriver } from 'assets/img/app_driver.svg'
import { ReactComponent as AppRegister } from 'assets/img/app_register.svg'
import { ReactComponent as AppCustomer } from 'assets/img/app_customer.svg'
import { ReactComponent as AppResource } from 'assets/img/app_resource.svg'
import { ReactComponent as ScCopy } from 'assets/img/sc-copy.svg'
import { ReactComponent as ArrowRight } from 'assets/arrow-right.svg'

import { DistributorNode } from 'generated/graphql'
import { getUrl } from 'util/env'
import Dropdown from 'components/Dropdown/Dropdown'
import Tooltip from 'components/Tooltip'
import TutorialTooltip from 'components/Tooltip/TutorialTooltip'
import InlineTip from 'components/Tooltip/InlineTip'

const Apps = styled.div`
  border-radius: 20px;
  width: 560px;
`

const AppsHeadline = styled.div`
  padding: 1em;
  background-color: rgba(17, 24, 48, 0.5);
  font-size: 16px;
  color: #fff;
`

const AppsList = styled.div`
  display: flex;
  flex-direction: column;
`

const AppItem = styled.a`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 50px 20px 30px;
  transition: background-color 200ms ease;
  color: hsla(0, 0%, 100%, 0.5);
  border-bottom: 1px solid ${props => props.theme.colors.primaryDarker};

  &:hover {
    background-color: ${props => props.theme.colors.primaryLighter};
  }
`

const ScCopyIcon = styled(ScCopy)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  fill: #2c97de;
  opacity: 0.8;
  margin-right: 20px;
`

const AppDriverIcon = styled(AppDriver)`
  fill: #9900ea;
  width: 25px;
  margin-right: 20px;
  height: 25px;
`

const AppRegisterIcon = styled(AppRegister)`
  fill: #ffb440;
  width: 25px;
  margin-right: 20px;
  height: 25px;
`

const AppResourceIcon = styled(AppResource)`
  fill: #2c97de;
  width: 25px;
  margin-right: 20px;
  height: 25px;
`

const AppCustomerIcon = styled(AppCustomer)`
  fill: #00d4a6;
  width: 25px;
  margin-right: 20px;
  height: 25px;
`

const AppNameHeader = styled.div`
  color: #ffffff;
  font-size: 17px;
  line-height: 30px;
`

const AppRightWrapper = styled.div`
  display: flex;
  width: auto;
  margin-right: -20px;
  margin-left: auto;
`

const ArrowRightIcon = styled(ArrowRight)`
  fill: #ffffff;
  opacity: 0.3;
  margin-right: 0px;
  width: 12px;
  height: 22px;
`

const BlueText = styled.span`
  color: ${props => props.theme.colors.lightBlue};
  font-weight: 500;
  text-decoration: none;
`

interface Props {
  open: boolean
  distributor: DistributorNode
  anchor: MutableRefObject<HTMLElement | null>
  onClose: () => void
}

const AppsDropdown: FunctionComponent<Props> = props => {
  const intl = useIntl()
  const t = intl.formatMessage

  return (
    <Dropdown open={props.open} anchor={props.anchor} position='bottom' align='innerEnd' showCaret={false} borderRadius='10px' onClose={props.onClose}>
      <Apps>
        <AppsHeadline>Smart Cylinders Apps</AppsHeadline>
        <AppsList>
          <TutorialTooltip content={t({ id: 'tooltips.header.apps.register_app' })}>
            <AppItem href={'https://register.' + getUrl()} target='_new'>
              <AppRegisterIcon />
              <div>
                <AppNameHeader>Register Sensor App</AppNameHeader>
                <div>register.{getUrl()}</div>
              </div>

              <AppRightWrapper>
                <Tooltip content='Copy link' delay={0} arrow={false} placement='top'>
                  <ScCopyIcon
                    onClick={e => {
                      e.preventDefault()
                      navigator.clipboard.writeText('https://register.' + getUrl())
                    }}
                  />
                </Tooltip>
                <ArrowRightIcon />
              </AppRightWrapper>
            </AppItem>
          </TutorialTooltip>
          <TutorialTooltip content={t({ id: 'tooltips.header.apps.drivers_app' })}>
            <AppItem href={'https://drivers.' + getUrl()} target='_new'>
              <AppDriverIcon />
              <div>
                <AppNameHeader>Drivers App</AppNameHeader>
                <div>drivers.{getUrl()}</div>
              </div>
              <AppRightWrapper>
                <Tooltip content='Copy link' delay={0} arrow={false} placement='top'>
                  <ScCopyIcon
                    onClick={e => {
                      e.preventDefault()
                      navigator.clipboard.writeText('https://drivers.' + getUrl())
                    }}
                  />
                </Tooltip>
                <ArrowRightIcon />
              </AppRightWrapper>
            </AppItem>
          </TutorialTooltip>
          {props.distributor.appHost && (
            <TutorialTooltip content={t({ id: 'tooltips.header.apps.customers_app' })}>
              <AppItem href={'https://' + props.distributor.appHost} target='_new'>
                <AppCustomerIcon />
                <div>
                  <AppNameHeader>Customers App</AppNameHeader>
                  <div>{props.distributor.appHost}</div>
                </div>
                <AppRightWrapper>
                  <Tooltip content='Copy link' delay={0} arrow={false} placement='top'>
                    <ScCopyIcon
                      onClick={e => {
                        e.preventDefault()
                        navigator.clipboard.writeText('https://' + props.distributor.appHost)
                      }}
                    />
                  </Tooltip>
                  <ArrowRightIcon />
                </AppRightWrapper>
              </AppItem>
            </TutorialTooltip>
          )}
          <TutorialTooltip content={t({ id: 'tooltips.header.apps.resource_center' })}>
            <AppItem href='https://www.smartcylinders.com/resource-center' target='_new'>
              <AppResourceIcon />
              <div>
                <AppNameHeader>
                  Resource Center{' '}
                  <InlineTip>
                    Password: <BlueText>solace</BlueText>
                  </InlineTip>
                </AppNameHeader>
                <div>smartcylinders.com/resource-center</div>
              </div>
              <AppRightWrapper>
                <Tooltip content='Copy link' delay={0} arrow={false} placement='top'>
                  <ScCopyIcon
                    onClick={e => {
                      e.preventDefault()
                      navigator.clipboard.writeText('https://www.smartcylinders.com/resource-center')
                    }}
                  />
                </Tooltip>
                <ArrowRightIcon />
              </AppRightWrapper>
            </AppItem>
          </TutorialTooltip>
        </AppsList>
      </Apps>
    </Dropdown>
  )
}

export default AppsDropdown
