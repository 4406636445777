import React from 'react'
import isString from 'lodash/isString'
import ScIcon from 'components/Icon/ScIcon'
import Icon from 'components/Icon/index'

export function overloadIcon(icon, props = {}) {
  if (isString(icon)) {
    if (icon.includes('sc')) {
      return <ScIcon icon={icon} {...props} />
    } else if (icon.includes('fa')) {
      return <Icon icon={icon} {...props} />
    }
  } else {
    return icon
  }
}
