// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicModalNewTrigger, DefaultModalNewTriggerProps } from './plasmic/solace_components/PlasmicModalNewTrigger'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import * as GQL from 'generated/graphql'
import { omit } from 'lodash'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface ModalNewTriggerProps extends Omit<DefaultModalNewTriggerProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultModalNewTriggerProps altogether and have
// total control over the props for your component.

// export interface AlertTriggerValues {
//   name: string
//   threshold: number
//   alertThreshold: number
//   unit: GQL.DistributorRefillThresholdUnit
// }

export interface ModalNewTriggerProps extends DefaultModalNewTriggerProps {
  trigger?: GQL.DistributorRefillThresholdNode
  title?: string
  onClose: () => void
  onSubmit:
    | ((data: Omit<GQL.CreateDistributorRefillThresholdInput, 'distributor'>) => void)
    | ((data: Omit<GQL.PatchDistributorRefillThresholdInput, 'distributor'>) => void)
}

function ModalNewTrigger_(props: ModalNewTriggerProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicModalNewTrigger to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicModalNewTrigger are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all ModalNewTriggerProps here, but feel free
  // to do whatever works for you.
  const [name, setName] = React.useState(props.trigger?.name || '')
  const [orderAt, setOrderAt] = React.useState(props.trigger?.threshold.toString() || '20')
  const [alertAt, setAlertAt] = React.useState(props.trigger?.alertThreshold.toString() || '10')
  const [unit, setUnit] = React.useState<GQL.DistributorRefillThresholdUnit>(props.trigger?.unit || GQL.DistributorRefillThresholdUnit.Percent)

  const helpTextMap = {
    [GQL.DistributorRefillThresholdUnit.Days]: 'd',
    [GQL.DistributorRefillThresholdUnit.Percent]: '%',
    [GQL.DistributorRefillThresholdUnit.Kilogram]: 'kg',
    [GQL.DistributorRefillThresholdUnit.Liter]: 'L',
  } as const

  return (
    <PlasmicModalNewTrigger
      root={{ ref }}
      inputTriggerName={{
        undefinedInput: {
          name: 'inputTriggerName',
          value: name,
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value),
        },
      }}
      automatedTriggerRow={{
        triggerType: {
          value: unit,
          options: Object.entries(GQL.DistributorRefillThresholdUnit).map(([key, value]) => ({ label: `Remaining ${key}`, value: value })),
          onChange: (event: GQL.DistributorRefillThresholdUnit) => setUnit(event),
        },
        triggerAlertValueInput: {
          helpText: helpTextMap[unit],
          undefinedInput: {
            value: alertAt,
            type: 'number',
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setAlertAt(event.target.value ? Math.abs(parseFloat(event.target.value)).toString() : ''),
          },
        },
        triggerOrderValueInput: {
          helpText: helpTextMap[unit],
          undefinedInput: {
            value: orderAt,
            type: 'number',
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setOrderAt(event.target.value ? Math.abs(parseFloat(event.target.value)).toString() : ''),
          },
        },
        rowMoreActions: {
          render: () => null,
        },
      }}
      btnCreateTrigger={{
        props: {
          onClick: () => props.onSubmit({ name: name, threshold: parseInt(orderAt), alertThreshold: parseInt(alertAt), unit: unit }),
        },
      }}
      btnClose={{ props: { onClick: () => props.onClose() } }}
      {...omit(props, 'trigger', 'onClose', 'onSubmit')}
    />
  )
}

const ModalNewTrigger = React.forwardRef(ModalNewTrigger_)
export default ModalNewTrigger
