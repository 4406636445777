// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ClearCellsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ClearCellsvgIcon(props: ClearCellsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 45.427 30.2"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M39.927 0h-25.08a5.522 5.522 0 00-3.94 1.66l-9.34 9.61a5.463 5.463 0 000 7.66l9.34 9.61a5.522 5.522 0 003.94 1.66h25.08a5.5 5.5 0 005.5-5.5V5.5a5.5 5.5 0 00-5.5-5.5zm-9.17 17.92a1.503 1.503 0 01-2.12 2.13l-2.824-2.82-2.83 2.82a1.503 1.503 0 01-2.12-2.13l2.824-2.82-2.82-2.82a1.503 1.503 0 012.12-2.13l2.83 2.83 2.82-2.83a1.503 1.503 0 012.12 2.13l-2.82 2.82 2.82 2.82z"
        }
      ></path>
    </svg>
  );
}

export default ClearCellsvgIcon;
/* prettier-ignore-end */
