// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type DisabledsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function DisabledsvgIcon(props: DisabledsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 50 50"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M25.001 0C11.215 0 0 11.215 0 25.001 0 38.785 11.215 50 25.001 50 38.785 50 50 38.785 50 25.001 50 11.215 38.785 0 25.001 0zM6.024 25.001C6.024 14.537 14.536 6.025 25 6.025c4.147 0 7.975 1.352 11.1 3.618L9.642 36.101c-2.265-3.126-3.618-6.954-3.618-11.1zm18.977 18.975c-4.147 0-7.975-1.352-11.1-3.617l26.458-26.458c2.265 3.125 3.617 6.953 3.617 11.1 0 10.462-8.513 18.975-18.975 18.975z"
        }
      ></path>
    </svg>
  );
}

export default DisabledsvgIcon;
/* prettier-ignore-end */
