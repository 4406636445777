import { useMemo, useState } from 'react'
import Loader from 'components/Loader/Loader'
import * as GQL from 'generated/graphql'

import FlexTable from 'plasmic/FlexTable'
import TableRow from 'plasmic/TableRow'
import CellCustomer from 'plasmic/CellCustomer'
import CellAddress from 'plasmic/CellAddress'
import CellEstimatedEmpty from 'plasmic/CellEstimatedEmpty'
import TableHeadTitle from 'plasmic/TableHeadTitle'
import EstimatedEmpty from 'components/Table/Cells/EstimatedEmpty'
import FlexTableWrapper from 'plasmic/FlexTableWrapper'
import CellProduct from 'plasmic/CellProduct'
import CellStatus from 'plasmic/CellStatus'
import { useNavigate } from 'react-router-dom'
import { ProductLabel } from 'modules/orders/util'
import { useCustomerContext } from 'util/hooks'
import { CustomerDrawerListSource } from 'context/CustomerContext'
import { ORDER_ROUTE_CATEGORY } from '../consts'
import ScrollIndicator from 'plasmic/ScrollIndicator'
import { CenteredLoader } from 'components/Loader/LoaderWrappers'

export const getStatusString = (status: GQL.CylinderGroupOrderStates) => {
  return status.replace(/_/g, ' ').capitalizeFirst()
}

const PAGE_SIZE = 15

const UnplannedDeliveries = () => {
  const [loadFetchMore, setLoadFetchMore] = useState(false)

  const navigate = useNavigate()

  const { setSource, setCustomersContext } = useCustomerContext()

  const {
    data: ordersData,
    loading: loadingOrdersData,
    fetchMore,
  } = GQL.useAllOrdersRouteFooter({
    variables: {
      first: PAGE_SIZE,
      routeCategory: ORDER_ROUTE_CATEGORY.UNPLANNED,
    },
  })

  const orders = useMemo(() => {
    return ordersData?.allCylinderGroupOrders?.edges.map(edge => edge?.node as GQL.CylinderGroupOrderNode) || []
  }, [ordersData])

  const pageInfo = useMemo(() => {
    return ordersData?.allCylinderGroupOrders?.pageInfo
  }, [ordersData])

  return (
    <FlexTableWrapper
      tables={
        <FlexTable
          noCheckbox
          noSorting
          children={<TableHeadTitle title='Unplanned Orders' count={`(${ordersData?.allCylinderGroupOrders?.totalCount || orders.length})`} />}
          rows={
            <>
              {loadingOrdersData ? (
                <CenteredLoader sizeAuto>
                  <Loader color='gray6' size={32} />
                </CenteredLoader>
              ) : (
                orders.map((order, num) => (
                  <TableRow
                    noCheckbox
                    key={order.cylinderGroup.id + 'TableRowCustomers' + num}
                    sticky={
                      <CellCustomer
                        onClick={() => {
                          setSource(CustomerDrawerListSource.UNPLANNED_DELIVERIES)
                          setCustomersContext(orders?.map(order => order.cylinderGroup.customer) || [])
                          navigate(`?customer=${order.cylinderGroup?.customer.id}`)
                        }}
                        name={order.cylinderGroup?.customer.name}
                      />
                    }
                    scrollCells={
                      <>
                        <CellProduct contentWrapper={<ProductLabel cylinderGroupOrder={order} />} />
                        <CellAddress noHover address={order.cylinderGroup.customer.address.firstLine} />
                        <CellStatus fromElement={order} editable={false} />
                        <CellEstimatedEmpty content={<EstimatedEmpty cylinderGroup={order.cylinderGroup} displayThreshold={false} />} />
                      </>
                    }
                  />
                ))
              )}
              {!loadingOrdersData && (
                <ScrollIndicator
                  tableRow
                  loaded={orders.length.toString()}
                  total={(ordersData?.allCylinderGroupOrders?.totalCount || orders.length).toString()}
                  btnLoadMore={{
                    ...(loadFetchMore && { children: <Loader color='white' /> }),
                    onClick: () => {
                      if (pageInfo?.hasNextPage && pageInfo?.endCursor) {
                        setLoadFetchMore(true)
                        fetchMore({
                          variables: {
                            first: PAGE_SIZE,
                            routeCategory: ORDER_ROUTE_CATEGORY.UNPLANNED,
                            after: pageInfo.endCursor,
                          },
                          updateQuery(prev, { fetchMoreResult }) {
                            if (!fetchMoreResult) return prev
                            return {
                              ...fetchMoreResult,
                              allOrdersRouteFooter: {
                                ...fetchMoreResult?.allCylinderGroupOrders,
                                edges: [
                                  ...(prev?.allCylinderGroupOrders?.edges ? prev.allCylinderGroupOrders.edges : []),
                                  ...(fetchMoreResult?.allCylinderGroupOrders?.edges ? fetchMoreResult.allCylinderGroupOrders.edges : []),
                                ],
                              },
                            } as GQL.AllOrdersRouteFooter
                          },
                        }).finally(() => {
                          setLoadFetchMore(false)
                        })
                      }
                    },
                  }}
                />
              )}
            </>
          }
          visibleColumns={['address', 'product', 'estimatedEmpty', 'status']}
        />
      }
    />
  )
}

export default UnplannedDeliveries
