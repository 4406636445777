import React from 'react'
import { useIntl } from 'react-intl'

import TopActions from 'plasmic/TopActions'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: sticky;
  left: 0;
`

interface Props {
  search: string
  onSearchChanged: (value: string) => any
}

const ContactSignupsHeader: React.FC<Props> = props => {
  const intl = useIntl()
  const t = intl.formatMessage

  return (
    <Wrapper>
      <TopActions
        style={{ minHeight: '66px' }}
        searchField={{
          fieldPlaceholder: t({ id: 'customers.contact-signups.search-input.placeholder' }),
          undefinedInput: {
            onChange: (e: any) => props.onSearchChanged(e.target.value),
            value: props.search,
          },
        }}
        buttonsRight={false}
      />
    </Wrapper>
  )
}

export default ContactSignupsHeader
