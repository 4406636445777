import { AuditLogDetail, AuditLogDetailItem, AuditLogDomain } from '../types'
import * as GQL from 'generated/graphql'

abstract class Parser {
  public createDetailItem(value: string, color?: string): AuditLogDetailItem {
    if (!color) {
      return { string: value }
    }
    return { string: value, color: color }
  }

  public createDomainItem(objectId: string, objectRepr: string, type?: string | null): AuditLogDomain {
    if (!type) {
      return { id: objectId, name: objectRepr }
    }
    return { id: objectId, name: objectRepr, type: type }
  }

  public abstract details(entry: GQL.AuditLogEntryNode): AuditLogDetail[]

  public abstract domains(entry: GQL.AuditLogEntryNode): AuditLogDomain[]
}

export default Parser
