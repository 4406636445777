import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Map, { IMarker, IPolyline } from 'modules/localization/components/Map'
import { Polyline } from '@react-google-maps/api'
import * as GQL from 'generated/graphql'
import iconDepot from 'assets/pin-depot.svg'

const Layout = styled.div``

interface RoutesMapDrawerProps {
  routes: GQL.RouteNode[]
}

//const icons = [pinOne, pinTwo, pinThree, pinFour, pinFive, pinSix]
const colors = ['2B97DE', 'FDAB3D', 'DD2C74', '2CDD91', '7C7A82', '9442F4']

const directionsService = new google.maps.DirectionsService()

interface IMapRoute {
  route: GQL.RouteNode
  i: number
}

const MapRoute = ({ route, i }: IMapRoute) => {
  const [polyline, setPolyline] = useState<IPolyline | null>(null)

  useEffect(() => {
    if (!route.depot) {
      return
    }
    directionsService.route(
      {
        origin: { lat: parseFloat(route.depot.address.latitude), lng: parseFloat(route.depot.address.longitude) },
        destination: {
          lat: parseFloat(route.depot.address.latitude),
          lng: parseFloat(route.depot.address.longitude),
        },
        waypoints: route.stops?.map(stop => ({
          location: new google.maps.LatLng({
            lat: stop?.latitude!,
            lng: stop?.longitude!,
          }),
        })),
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK && result) {
          setPolyline({
            path: result.routes[0].overview_path,
            options: {
              strokeColor: '#' + colors[i % colors.length],
            },
          })
        }
      }
    )
  }, [route, i])

  if (polyline === null) {
    return null
  }
  return <Polyline path={polyline.path} options={polyline.options} />
}

const RoutesMapDrawer: React.FC<RoutesMapDrawerProps> = ({ routes }) => {
  const markers = [
    ...routes
      .filter(route => route.depot)
      .map<IMarker>((route, i) => ({
        longitude: parseFloat(route.depot?.address.longitude),
        latitude: parseFloat(route.depot?.address.latitude),
        //icon: 'data:image/svg+xml;utf-8, <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 15 13" style="enable-background:new 0 0 15 13;" xml:space="preserve"><path fill="%23'+colors[i%6]+'" d="M0.623,12.812c-0.194,0-0.352-0.158-0.352-0.352V2.374c0-0.159,0.107-0.299,0.261-0.34L7.409,0.2  C7.439,0.192,7.47,0.188,7.5,0.188c0.03,0,0.061,0.004,0.091,0.012l6.876,1.834c0.154,0.041,0.261,0.181,0.261,0.34V12.46  c0,0.194-0.158,0.352-0.352,0.352H0.623z M11.626,4.772c0.194,0,0.352,0.158,0.352,0.352v6.983h2.046V2.645L7.5,0.905l-6.524,1.74  v9.463h2.046V5.124c0-0.194,0.158-0.352,0.352-0.352H11.626z M3.726,12.108h7.548V9.144H3.726V12.108z M3.726,8.44h7.548V7.311  H3.726V8.44z M3.726,6.606h7.548V5.477H3.726V6.606z"/></svg>',
        icon: iconDepot,
      })),
    ...routes.flatMap<IMarker>(
      (route: GQL.RouteNode, i) =>
        route.stops
          ?.filter(s => !!s?.customer?.address.latitude)
          .map(stop => ({
            longitude: parseFloat(stop?.customer?.address.longitude),
            latitude: parseFloat(stop?.customer?.address.latitude),
            //icon: colors[i % icons.length],
            icon:
              'data:image/svg+xml;utf-8, <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 40 48.3"><style type="text/css">.st0{fill:%23191E2F;} .st2{font-family:\'ProximaNova-Semibold\';} .st3{font-size:16px;} </style><path fill="%23' +
              colors[i % 6] +
              '" d="M40,20C40,9,31,0,20,0S0,9,0,20c0,5.3,2.1,10.1,5.4,13.6l0,0L20,48.3l14.6-14.6l0,0C37.9,30.1,40,25.3,40,20z" /><circle fill="%23191E2F" cx="20" cy="20" r="16" /><text fill="%23' +
              colors[i % 6] +
              '" text-anchor="middle" transform="matrix(1 0 0 1 20.1445 24.1496)" className="st2 st3" style="font-size: 16px;">' +
              stop?.orderInRoute +
              '</text></svg>',
          })) as IMarker[]
    ),
  ]

  return (
    <Layout>
      <Map mapStyle={{ height: '350px' }} options={{ gestureHandling: 'cooperative' }} markers={markers}>
        {routes.map((r, i) => (
          <MapRoute key={r.id} route={r} i={i} />
        ))}
      </Map>
    </Layout>
  )
}

export default RoutesMapDrawer
