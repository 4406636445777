import React, { useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import HeaderContext from 'context/HeaderContext'
import SubnavButton from 'plasmic/SubnavButton'
import TutorialTooltip from 'components/Tooltip/TutorialTooltip'
import ActiveOrders from './ActiveOrders'
import AllOrders from './AllOrders'

const ACTIVE_ORDERS_PATH = '/orders/active'
const ALL_ORDERS_PATH = '/orders/all'

const Wrapper = styled.div`
  height: 100%;
`

const Orders: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const intl = useIntl()
  const t = intl.formatMessage

  const headerContext = useContext(HeaderContext)

  useEffect(() => {
    headerContext.setIsSubHeaderVisible(true)
    headerContext.setLeftSubHeaderGroup(
      <>
        <TutorialTooltip content={t({ id: 'tooltips.header.orders.orders' })}>
          <SubnavButton active={location.pathname === ACTIVE_ORDERS_PATH} title={t({ id: 'common.active' })} onClick={() => navigate(ACTIVE_ORDERS_PATH)} />
        </TutorialTooltip>
        <TutorialTooltip content={t({ id: 'tooltips.header.orders.archive' })}>
          <SubnavButton active={location.pathname === ALL_ORDERS_PATH} title={t({ id: 'common.all' })} onClick={() => navigate(ALL_ORDERS_PATH)} />
        </TutorialTooltip>
      </>
    )
    headerContext.setRightSubHeaderGroup(<></>)

    return () => {
      headerContext.setIsSubHeaderVisible(false)
      headerContext.setLeftSubHeaderGroup(<></>)
      headerContext.setRightSubHeaderGroup(<></>)
    }
    // eslint-disable-next-line
  }, [t, location, navigate])

  return (
    <>
      <Wrapper>
        <Routes>
          <Route path='/active' element={<ActiveOrders />} />
          <Route path='/all' element={<AllOrders />} />
          <Route path='/' element={<Navigate to='/orders/active' />} />
        </Routes>
      </Wrapper>
    </>
  )
}

export default Orders
