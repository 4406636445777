// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type OrderStatus01SvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function OrderStatus01SvgIcon(props: OrderStatus01SvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 140.538 119.346"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M118.408 0H22.13C9.908 0 0 9.908 0 22.13v75.086c0 12.222 9.908 22.13 22.13 22.13h96.277c12.222 0 22.13-9.908 22.13-22.13V22.13C140.538 9.908 130.63 0 118.408 0zM70.267 65.594c-23.414 0-42.464-19.05-42.464-42.468a5.988 5.988 0 0111.974 0c0 16.81 13.676 30.49 30.49 30.49s30.495-13.676 30.495-30.49a5.988 5.988 0 0111.974 0c-.001 23.419-19.051 42.468-42.469 42.468z"
        }
      ></path>
    </svg>
  );
}

export default OrderStatus01SvgIcon;
/* prettier-ignore-end */
