// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type LightboxArrowRightsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function LightboxArrowRightsvgIcon(
  props: LightboxArrowRightsvgIconProps
) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 36.297 62.107"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M4.536 61.814L.293 57.571a.999.999 0 010-1.414L24.69 31.761a.999.999 0 000-1.414L.293 5.95a.999.999 0 010-1.414L4.536.293a.999.999 0 011.414 0l30.054 30.054a.999.999 0 010 1.414L5.95 61.814a.999.999 0 01-1.414 0z"
        }
      ></path>
    </svg>
  );
}

export default LightboxArrowRightsvgIcon;
/* prettier-ignore-end */
